import React, { useState } from 'react';
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Typography } from '@mui/material';
import OrgIcon from '../../../../assets/home/profile_assets/orglogo.svg';
import favorIcon from '../../../../assets/home/Favi.svg';
import ExternalIcons from '../../../../assets/home/external-link.svg'
import CoverPicIcon from '../../../../assets/home/profile_assets/cover_pic.svg'
import { PersonAddAlt, PersonAddAlt1, TaskAlt, ThumbUpAlt } from '@mui/icons-material';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { useDispatch } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { getOrgFollowCount, getSuggestedOrgs } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import orgfollowicon from '../../../../assets-new/medxpedia-icons/hospitalfollowicon.svg'

const MoreSuggestOrgs = ({ item, index, handleOrgFavourite }) => {

    const [ind, setInd] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const navigate = useNavigate()
    const [loadingMore, setLoadingMore] = useState(false);
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    const patientId = localstore.getPatientId()
    const [offset, setOffset] = useState(0);
    const limit = 25


    const handleFollow = (item, index) => {
        setInd(index);
        setLoading(true);
        axios.post(`${URL.followOrganisation}${patientId}`, item)
            .then(res => {
                setAlertopen(true);
                setAlertMessage('Started Following');
                setAlertstatus('success');
                setLoading(true);
                setTimeout(() => {
                    handleOrgFavourite(index);
                }, 3000);
                dispatch(getOrgFollowCount(patientId));
                // window.location.reload();

            })
            .catch(err => {
                setAlertopen(true);
                setLoading(true);
                setAlertMessage(err.message);
                // console.log('res error', err);
                setAlertstatus('error');
            })
            .finally(() => {
                setTimeout(() => {
                    setAlertopen(false);
                }, 2000);
                setTimeout(() => {
                    setLoading(false);
                    setInd('');
                }, 3000);
                dispatch(getSuggestedOrgs(limit, offset, patientId));
            })

    }
    return (
        <Box mt={1}>
            <Card
                sx={{
                    width: { xs: "195px", sm: "200px", md: "219px" },
                    height: { xs: "260px", sm: "260px", md: "240px" },
                    borderRadius: "10px 10px 0px 0px",
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    {item.coverPhoto?.length > 0 ? (
                        <Box sx={{ width: "100%", height: 52 }}>
                            <img
                                src={item.coverPhoto}
                                alt="coverPhoto"
                                width={"100%"}
                                height={62}
                                style={{ objectFit: "cover" }}
                            />{" "}
                        </Box>
                    ) : (
                        // <Avatar alt={"doc"} src={item.orgPageImage} sx={{ marginTop: '20px', height: '45px', width: '45px' }} variant='square' />
                        <Box sx={{ width: "100%", height: 52 }}>
                            <img
                                src={CoverPicIcon}
                                alt="hello"
                                width={"100%"}
                                height={65}
                                style={{ objectFit: "cover" }}
                            />
                        </Box>
                    )}
                    {item.orgPageImage?.length > 0 ? (
                        <Box
                            mt={2}
                            sx={{
                                width: 50,
                                height: 50,
                                position: "absolute",
                                top: "15px",
                            }}
                        >
                            <img
                                src={item.orgPageImage}
                                alt="hello"
                                width={50}
                                height={50}
                                style={{ objectFit: "cover", borderRadius: "5px" }}
                            />{" "}
                        </Box>
                    ) : (
                        <Box
                            mt={2}
                            sx={{
                                backgroundColor: "#A8ECFF",
                                width: 50,
                                height: 50,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "10px",
                                p: "5px",
                                position: "absolute",
                                top: "15px",
                            }}
                        >
                            <img src={OrgIcon} alt="hello" width={38} height={38} />{" "}
                        </Box>
                    )}
                </Box>

                <Box sx={{ textAlign: "center", mt: 5 }}>
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => navigate(`/org/${item.id}`)}
                    >
                        {item.pageName?.length > 16 ? (
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: { xs: 12, sm: 12, md: 14 },
                                }}
                            >
                                {item.pageName.slice(0, 15)}..
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: { xs: 12, sm: 12, md: 14 },

                                }}
                            >
                                {item.pageName}
                            </Typography>
                        )}
                    </div>
                    {item.organisation?.identifier[0]?.type?.text?.length > 0 ? (
                        <Typography>
                            {item.organisation.identifier[0].type.text.length > 18 ? (
                                <Typography
                                    sx={{ fontSize: { xs: 13, sm: 12, md: 13 }, mt: 1 }}
                                >
                                    {item.organisation.identifier[0].type.text.slice(0, 17)}..
                                </Typography>
                            ) : (
                                <Typography
                                    sx={{ fontSize: { xs: 13, sm: 12, md: 13 }, mt: 1 }}
                                >
                                    {item.organisation.identifier[0].type.text}
                                </Typography>
                            )}
                        </Typography>
                    ) : (
                        <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 }, mt: 1 }}>
                            N/A
                        </Typography>
                    )}

                    {item.organisation?.type &&
                        item.organisation?.type[0]?.coding[0].display?.length > 0 ? (
                        <Typography>
                            {item.organisation.type[0].coding[0].display.length > 19 ? (
                                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                                    {item.organisation.type[0].coding[0].display.slice(0, 18)}..
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                                    {item.organisation.type[0].coding[0].display}
                                </Typography>
                            )}
                        </Typography>
                    ) : (
                        <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                            N/A
                        </Typography>
                    )}

                    {item.organisation?.address[0]?.city?.length > 0 ? (
                        <Typography >
                            {item.organisation.address[0].city?.length > 10 ? (
                                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 14 } }}>
                                    {item.organisation.address[0].city.slice(0, 9)}..
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 14 } }}>
                                    {item.organisation.address[0].city}
                                </Typography>
                            )}
                        </Typography>
                    ) : (
                        <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                            N/A
                        </Typography>
                    )}
                    <Grid container mt={"29px"}>

                        <Grid xs={6} sm={6} md={6}>
                            <Button
                                variant="contained"
                                sx={{ width: '109px', bgcolor: '#88868D', "&:hover": { bgcolor: '#88868D' }, borderRadius: 0, '.css-1d6wzja-MuiButton-startIcon>*:nth-of-type(1)': { fontSize: '16px' } }}
                                startIcon={
                                    loading ? (
                                        <TaskAlt />
                                    ) : (
                                        <img src={orgfollowicon} alt="org" width={12} height={12} />
                                    )
                                }
                                onClick={() => {
                                    if (loading === false) {
                                        handleFollow(item, index);
                                    }
                                }}
                            >
                                {index === ind && loading === true ? (
                                    <Box>
                                        <Typography sx={{ fontSize: { xs: 12, sm: 12, md: 12 } }}>
                                            Connected
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Typography sx={{ fontSize: { xs: 12, sm: 12, md: 12 } }}>
                                            Follow
                                        </Typography>
                                    </Box>
                                )}
                            </Button>
                        </Grid>
                        <Grid
                            xs={5.7}
                            sm={5.7}
                            md={6}
                            onClick={() => navigate(`/org/${item.id}`)}
                        >
                            <Button
                                sx={{ color: 'white', width: '110px', borderRadius: 0, }}
                                variant="contained"
                            >
                                <Typography
                                    sx={{ fontSize: { xs: 13, sm: "13px", md: "12px" } }}
                                >
                                    View Page
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    );
}

export default MoreSuggestOrgs