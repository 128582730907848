import React, { useRef } from 'react'
import { Security } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import URL from '../../../../utility/apiurl'
import axios from 'axios'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { localstore } from '../../../../localStore/localStore'
import PinInputField from '../../../atoms/TextFields/PinInputField'
import NewConfirmResetPin from './NewConfirmResetPin'

function NewResetPin(props) {
    const consentPinToken = useSelector((state) => state.abha.consentPinToken)
    // console.log(consentPinToken, "qqrrrrrrrrrrr")

    const { handleClose } = props
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const [pin, setPin] = useState(['', '', '', '']);
    const [confirmPin, setConfirmPin] = useState(['', '', '', '']);
    const otpFieldsRef = useRef([]);
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const token = localstore.getToken()
    const handleInput = (index, value) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value.length === 1 && index < pin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertmessage('');
        } else if (value !== '') {
            setAlertmessage('Please enter 4 digits number only');
        }
    };


    const handleBackspace = (index) => {
        if (pin[index] !== '') {
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertmessage('')

    };


    const handleSubmitConsent = async () => {

        const pinValue = pin.join('');
        const confirmPinValue = confirmPin.join('');

        if (pinValue === confirmPinValue) {
            let data = {
                pin: pinValue,
            };


            setLoading(true)
            await axios.put(`${URL.consents}resetPin/${primaryId}`, data, {
                headers: {
                    "X-AUTH-TOKEN": `${consentPinToken}`,
                    "Authorization": `Bearer ${token}`,

                }
            }).then(res => {
                console.log(res, "setnewpin")
                if (res.status === 200 || res.status === 201) {
                    // Set a success alert message and status
                    setAlertmessage('reset Pin Successfully!');
                    setAlertstatus('success');
                }
                // window.location.reload();
                // handleClose()
                // setPin('')
                // props.setAlertmessage('')
                // props.setShow(1)
                handleClosedone()
            }).catch(err => {
                setAlertmessage(err.message);
                setAlertstatus('error');
            }).finally(() => {
                setLoading(false)
            })
        } else {
            setAlertmessage("PIN and Confirm PIN don't match");
            setAlertstatus('error');
        }
    }
    const handleClosedone = () => {
        handleClose();
        setPin(['', '', '', '']);
        setConfirmPin(['', '', '', '']);
        props.setAlertMessage('');
        props.setShow(1);
    };
    return (
        <div>  <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                    <Security />
                    <Typography sx={{ fontWeight: 500, fontSize: 17 }}>Set Security PIN</Typography>
                </Box>
                <Button sx={{ height: "25px", color: '#B48FE4', borderRadius: "15px" }} variant='outlined' onClick={handleClosedone}>Done</Button>
            </DialogTitle>
            <Divider />

            <DialogContent >
                <Card sx={{ textAlign: 'center', p: "40px 0px", }}>
                    <Typography sx={{ fontWeight: 600 }}>Set New Consent PIN</Typography>
                    <Box mt={3}>
                        <div style={{ display: 'flex', gap: '20px', textAlign: 'center', justifyContent: 'center' }}>
                            {pin.map((value, index) => (

                                <PinInputField
                                    type="text"
                                    maxLength="1"
                                    value={value}
                                    key={index}
                                    onChange={(e) => handleInput(index, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            handleBackspace(index);
                                        }
                                    }}
                                    ref={(ref) => {
                                        otpFieldsRef.current[index] = ref;
                                    }} />
                            ))}
                        </div>
                    </Box>

                    <Box mt={3}>
                        <NewConfirmResetPin pin={confirmPin} setPin={setConfirmPin} setAlertmessage={setAlertmessage} />
                    </Box>
                    {alertmessage && (
                        <Typography sx={{ color: alertstatus === 'success' ? 'green' : 'red', mt: 1, fontSize: '14px' }}>{alertmessage}</Typography>
                    )}

                </Card>
                <Typography sx={{ color: 'black', ml: 1, mt: 1, fontSize: '14px' }} component={"p"}>Remember this 4 Digit-pin to secure you Health records and approve any request</Typography>

            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button sx={{ bgcolor: '#9560D9', width: '120px', borderRadius: "10px", m: "10px 0px" }} onClick={handleSubmitConsent} variant='contained'
                    disabled={pin.length < 4}
                    startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                >Submit</Button>
            </DialogActions>
        </></div>
    )
}

export default NewResetPin