import React from 'react'
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import { ArrowForwardIos, Close, Vaccines } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import diagnosticIcon from '../../../../assets-new/carekit-icons/diagnostic.svg'

const DiagnosticCard = (props) => {
    return (
        <Paper elevation={3} sx={{ mt: 2, p: "10px 15px", borderRadius: '10px', cursor: 'pointer' }} onClick={props.onClick}>
            <Grid container>
                <Grid item xs={5.5} sm={5.5} md={5.5}>
                    <Typography sx={{ fontSize: 14 }}>12 Jan 2023</Typography>
                    <Typography sx={{ fontWeight: 500, mt: '5px' }}>{props.title}</Typography>
                    <Typography sx={{ fontSize: 14, mt: '5px' }}>Ref. Range :12 - 14 mg/ dL</Typography>
                </Grid>
                <Grid item xs={5} sm={5} md={5}>
                    <Typography sx={{ fontSize: 14 }}>Result</Typography>
                    <Typography sx={{ fontSize: 14, mt: '5px' }}>12 mg/ dL</Typography>
                </Grid>
                <Grid item xs={1.5} sm={1.5} md={1.5}>
                    <Typography sx={{ fontSize: 14 }}>{props.type}</Typography>
                    <IconButton sx={{ bgcolor: '#F5F6FD', mt: '5px' }}><ArrowForwardIos /></IconButton>
                </Grid>
            </Grid>
        </Paper>
    )
}

function DiagnosticReports() {
    const navigate = useNavigate()
    return (
        <Box sx={{ m: 1, mb: { xs: 10, sm: 0, md: 2 } }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <img src={diagnosticIcon} alt="diagnostic" />
                    <Typography sx={{ fontWeight: 500, fontSize: 17 }}>Diagnostic Reports</Typography>
                </Box>
                <IconButton onClick={() => navigate(-1)}>  <Close sx={{ color: 'red' }} /></IconButton>
            </Box>
            <DiagnosticCard title="Heamoglobin" type="Normal" />
            <DiagnosticCard title="Dengue Fever IgG" type="Positive" />
            <DiagnosticCard title="Dengue Fever IgM" type="Positive" />
            <DiagnosticCard title="Dengue NS 1 Antigen" type="Positive" />
            <DiagnosticCard title="Platelet count" type="Low" onClick={() => navigate('/carechart/platelet')} />

        </Box>
    )
}

export default DiagnosticReports