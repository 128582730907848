import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Security } from '@mui/icons-material';
import { Box, Card, CircularProgress, Divider, Typography } from '@mui/material';
import { useState } from 'react';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import CreatePinSuccess from './CreatePinSuccess';
import ReEnterConsentPin from './Create-RePin';
import PinInputField from '../../../atoms/TextFields/PinInputField';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});
function CreateConsentPin(props) {
    const phraddress = props?.mobiledata?.id

    const [t, i18n] = useTranslation("global");
    const { open, handleClose, setOpen } = props
    const [show, setShow] = useState(1)
    // const [phraddress, setPhraddress] = useState('');
    const [phdState, setPhdState] = useState(null);
    const [abhaid, setAbhaId] = useState()
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const [pincode, setPincode] = useState('')
    const [pin, setPin] = useState(['', '', '', '']);
    const otpFieldsRef = React.useRef([]);
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const csrftoken = localstore.getCsrfToken()
    const handleInput = (index, value) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value.length === 1 && index < pin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertMessage('');
        } else if (value !== '') {
            setAlertMessage('Please enter 4 digits number only');
        }
    };

    const handleBackspace = (index) => {
        if (pin[index] !== '') {
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertMessage('');
    };

    const ibm = useSelector((state) => state.medx.ibmpdata)
    // console.log(ibm, "consentIbm")
    const primaryId = localstore.getPrimaryId()
    const handleSubmitConsent = async () => {
        // setShow(2)
        setLoading(true)
        let data = {
            pin: pin.join('')
        }
        await axios.post(`${URL.consents}create/consentPin/${primaryId}/${phraddress}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        }

        ).then(res => {
            // console.log(res, "resssss")
            if (res.status === 200 || res.status === 201) {
                // Set a success alert message and status
                setAlertMessage('Created Pin Successfully!');
                setAlertstatus('success');
                setPincode('reset')
            }
            setShow(2)
            // dispatch(getAbhaMobile(primaryId, token))

        }).catch(err => {

            // Handle the error and set an error alert message and status
            if (err.response && err.response.data && err.response.status === 400) {
                const responseText = err.response.data.message;
                const responseArray = JSON.parse(responseText.split(': ')[1]);

                const errorMessage = responseArray[0].error.message;
                setAlertMessage(errorMessage);
                setAlertstatus('error');
            } else {
                setAlertMessage(err.message);
                setAlertstatus('error');
            }
        }).finally(() => {
            setLoading(false)
        })
        setPin(['', '', '', ''])

    }

    const handleCancel = () => {
        setPin(['', '', '', ''])
        setAlertMessage('')
        handleClose()
    }
    return (
        <div><Dialog
            open={open}
            fullWidth
            maxWidth="sm"
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description" >
            {show === 1 ? (
                <>
                    <DialogTitle
                        sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                            <Security />
                            <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>{t("setsecuritypin")}</Typography>
                        </Box>
                        <Button
                            sx={{
                                height: "25px",
                                color: "#B48FE4",
                                borderRadius: "15px",
                            }}
                            variant="outlined"
                            onClick={handleCancel}
                        >
                            {t("cancel")}
                        </Button>
                    </DialogTitle>
                    <Divider />

                    <DialogContent>
                        <Card sx={{ textAlign: "center", mt: 1, p: "40px 0px" }}>
                            <Typography sx={{ fontWeight: 500 }}>
                                {t("createconsentpin")}
                            </Typography>
                            <Box mt={3}>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "20px",
                                        textAlign: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {pin.map((value, index) => (
                                        <PinInputField
                                            type="text"
                                            maxLength="1"
                                            key={index}
                                            value={value}
                                            onChange={(e) => handleInput(index, e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Backspace") {
                                                    handleBackspace(index);
                                                }
                                            }}
                                            ref={(ref) => {
                                                otpFieldsRef.current[index] = ref;
                                            }}
                                        />
                                    ))}
                                </div>
                            </Box>
                            {alertmessage && (
                                <Typography
                                    sx={{
                                        color: alertstatus === "success" ? "green" : "red",
                                        mt: 1, fontSize: '14px'
                                    }}
                                >
                                    {alertmessage}
                                </Typography>
                            )}
                        </Card>
                        <Typography
                            sx={{ ml: 1, mt: 1, fontSize: 14 }}
                            component={"p"}
                        >
                            Remember this 4 Digit-pin to secure you Health records and
                            approve any request
                        </Typography>
                        <Box mt={2} sx={{ textAlign: "center" }}>
                            <Button
                                sx={{
                                    bgcolor: "#9560D9",
                                    width: "120px",
                                    borderRadius: "10px",
                                }}
                                onClick={handleSubmitConsent}
                                variant="contained"
                                disabled={pin.length < 4}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                Submit
                            </Button>
                        </Box>
                    </DialogContent>
                </>
            ) : show === 2 ? (
                <ReEnterConsentPin
                    phraddress={phraddress}
                    setShow={setShow}
                    handleClose={handleClose}
                    setAlertMessage={setAlertMessage}
                />
            ) : show === 3 ? (
                <CreatePinSuccess
                    setPin={setPin}
                    setShow={setShow}
                    handleClose={handleClose}
                    setAlertMessage={setAlertMessage}
                />
            ) : null}
        </Dialog>
        </div >
    );
}

export default CreateConsentPin

