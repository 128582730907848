import React from 'react'
import { Avatar, Box, Button, Card, CircularProgress, Grid, IconButton, MenuItem, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { useState } from 'react'
import URL, { OtpPublicKey } from '../../../../utility/apiurl';
import { useDispatch, useSelector } from 'react-redux';
import ProfileIcon from '../../../../assets/home/profile_assets/Userprofilelogo1.svg'
import TextInputField from '../../../atoms/TextFields/TextInputField';
import _ from 'lodash';
import { useEffect } from 'react';
import { getDistricts, getStates } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import EditImg from '../../../../assets/home/Icon feather-edit-4.svg'
import { ArrowBackIos } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import AdornmentTextField from '../../../atoms/TextFields/AdornmentTextField';
import JSEncrypt from 'jsencrypt';

function Registration(props) {
    const [register, setRegister] = useState({
        address: '',
        pinCode: '',
    })
    // const [token, setToken] = useState('');
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [age, setAge] = useState('');
    const [gender, setGender] = useState('');
    const [key, setKey] = useState('');
    const [stat, setStat] = useState('')
    const [district, setDistrict] = useState('')
    const [statecode, setStatecode] = useState('');
    const [districtcode, setDistrictcode] = useState('');
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);

    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    const sessionId = result.sessionId
    const mobileNumber = result.mobile
    const EmailAddress = result.email
    const Email = result.Email
    const [mobile, setMobile] = useState(true)
    const [email, setEmail] = useState(EmailAddress !== null ? EmailAddress : email);
    const [phone, setPhone] = useState(mobileNumber !== null ? mobileNumber : phone);
    let states = useSelector(state => state.abha.states);
    // console.log(result, "states")
    let districts = useSelector(state => state.abha.districts);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = OtpPublicKey
    // console.log(ibmid, "abcdef")
    useEffect(() => {
        dispatch(getStates(token))
    }, []);
    let ibm = useSelector((state) => state.medx.ibmpdata);
    console.log(ibm, "registraionpage")
    const handleChangePhone = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setPhone(inputValue.slice(0, 10));
        setAlertMessage('')
    }

    const handleOnChangeRegister = (e) => {
        setRegister((prev) => {
            return { ...prev, [e.target.name]: e.target.value };
        });
        setAlertMessage('');

    }

    const handleOnChangePincode = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        const pincode = inputValue.slice(0, 6);
        setRegister((prevRegister) => ({
            ...prevRegister,
            pinCode: pincode
        }));
        setAlertMessage('');

    }

    useEffect(() => {
        if (ibm) {
            setAge(ibm.birthDate);
            setGender(ibm.gender);
            setName(`${ibm?.name?.[0]?.given?.[0] || ''} ${ibm?.name?.[0]?.family || ''}`);
        }
    }, [ibm]);

    const handleState = (e) => {
        setStat(e.name);
        setStatecode(e.code);
        dispatch(getDistricts(e.code, token));
        setDistrict('');

    }
    const handleDistrict = (e) => {
        setDistrict(e.name);
        setDistrictcode(e.code);
    };


    const handleOnConfirm = async (e) => {
        // console.log("hello")
        // props.setShow(6)
        setLoading(true)
        e.preventDefault();
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(publicKey)
        const encryptphone = encrypt.encrypt(phone)
        const encryptemail = encrypt.encrypt(email)

        setLoading(true);
        if (register.address.length > 0 && statecode && district && register.pinCode) {

            let data1 = {
                // 'mobile': encryptphone.replace(/\n/g, '').replace(/\r/g, ''),
                'mobile': phone,
                'mobileOrAadhaar': 'mobile',
                'mobileOrEmail': 'email',
                'sessionId': sessionId,
                'first': ibm?.name[0].given[0],
                'middle': '',
                'last': ibm?.name[0]?.family,
                'date': age.split('-')[2],
                'month': age.split('-')[1],
                'year': age.split('-')[0],
                'gender': _.capitalize(ibm?.gender.slice(0, 1)),
                'stateCode': statecode,
                'districtCode': districtcode,
                'address': register.address,
                'email': email,
                'pinCode': register.pinCode,

            };
            let data2 = {
                'mobile': phone,
                'mobileOrAadhaar': 'mobile',
                'mobileOrEmail': 'email',
                'sessionId': sessionId,
                'first': ibm?.name[0].given[0],
                'middle': '',
                'last': ibm?.name[0]?.family,
                'date': age.split('-')[2],
                'month': age.split('-')[1],
                'year': age.split('-')[0],
                'gender': _.capitalize(ibm?.gender.slice(0, 1)),
                'stateCode': statecode,
                'districtCode': districtcode,
                'address': register.address,
                'email': email === undefined ? '' : email,
                'pinCode': register.pinCode,

            };
            // let value = email ? "email" : phone ? "mobile" : "";
            // console.log(value, "valu")
            let value = Email === true ? 'email' : 'mobile';
            let data = Email === true ? data1 : data2;
            await axios.post(`${URL.abha}${value}/registration/details/${primaryId}`, data, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {
                    console.log(res.data, 'success');
                    setAlertMessage('Data registered successfully!');
                    setAlertstatus('success');

                    props.setShow(6)
                })
                .catch(err => {
                    // console.log(err.message);
                    if (err.response && err.response.data && err.response.status === 400) {
                        const responseText = err.response.data.message;
                        const responseArray = JSON.parse(responseText.split(': ')[1]);
                        const errorMessage = responseArray[0].error.message;
                        setAlertMessage(errorMessage);
                        setAlertstatus('error');
                    } else {
                        setAlertMessage(err.message);
                        setAlertstatus('error');
                    }
                })
                .finally(() => { setLoading(false); });
        } else {
            setAlertMessage('Please enter the mandatory fields *');
            setAlertstatus('error');
            setLoading(false);
        }
    };
    const handleEdit = () => {
        props.setShow(5)
    }

    return (
        <div>
            <Box >
                {/* <Typography sx={{ fontStyle: 'inherit', fontSize: { xs: 14, sm: 16, md: 18 }, ml: 1, fontWeight: 'bold' }} varaint="h1"  >
                    Verify Registration Details
                </Typography> */}
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: 2,
                        mt: { xs: "10px", sm: "15px", md: "20px" },
                    }}
                >
                    {/* <IconButton onClick={() => props.setShow(3)}><ArrowBackIos sx={{ color: 'black' }} /></IconButton> */}
                    <Typography sx={{ fontSize: { md: "17px" }, fontWeight: 600, color: '#007DCD' }}>
                        Verify your health ID Registration Details
                    </Typography>
                </Box>
            </Box>

            <Paper elevation={3} sx={{ p: 3, mt: 1.5, display: "flex", justifyContent: "center" }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Grid container alignItems={"center"} sx={{ border: '1px solid whitesmoke', p: "5px", borderRadius: "10px" }}>
                        <Grid item xs={4} sm={3} md={2}>
                            <Box>
                                <Avatar
                                    sx={{ width: "40px", height: "40px", ml: 1 }}
                                    variant="rounded"
                                >
                                    <img
                                        src={ibm.photo?.[0]?.url ? ibm.photo[0].url : ProfileIcon}
                                        alt="media"
                                        style={{
                                            objectFit: "cover",
                                            width: "100%",
                                            height: "100%",
                                        }}
                                    />
                                </Avatar>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={7} md={8}>
                            <Typography
                                sx={{ fontSize: { xs: 16, sm: 16, md: 16 }, fontWeight: 500 }}
                            >
                                {name}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Typography sx={{ fontSize: { xs: 14, md: 14 } }}>
                                    DOB: {age}
                                </Typography>
                                <Typography sx={{ fontSize: { xs: 14, md: 14 }, ml: 1 }}>
                                    Sex: {gender}
                                </Typography>
                            </Box>
                        </Grid>
                        <Grid item md={2}>
                            <IconButton onClick={handleEdit}>
                                <img src={EditImg} alt="register" />
                            </IconButton>
                        </Grid>
                    </Grid>
                    <Box mt={2}>
                        {/* <TextInputField
                            width="450px"
                            placeholder={t("phnumoptinal")}
                            type="text"
                            name="phone"
                            border="none"
                            value={phone}
                            onChange={handleChangePhone}
                        /> */}
                        <AdornmentTextField width="450px"
                            placeholder={t("phnumoptinal")}
                            type="text"
                            name="phone"
                            border="none"
                            value={phone}
                            onChange={handleChangePhone} />
                    </Box>
                    <Box mt={2}>
                        <TextInputField
                            width="450px"
                            placeholder={t("emailoptnal")}
                            type="email"
                            name="email"
                            border="none"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                    </Box>
                    <Box sx={{ mt: 1 }}>
                        <Typography ml={1}>{t("address")}</Typography>
                        <TextInputField
                            width="450px"
                            placeholder={t("add1")}
                            name="address"
                            border="none"
                            value={register.address}
                            onChange={handleOnChangeRegister}
                        />
                    </Box>
                    <Box>
                        <TextInputField
                            width="450px"
                            placeholder={t("pincode")}
                            mt={2}
                            name="pinCode"
                            border="none"
                            value={register.pinCode}
                            onChange={handleOnChangePincode}
                        />
                    </Box>
                    <TextField
                        sx={{
                            ".MuiInputBase-root": {
                                borderRadius: 2,
                                backgroundColor: "whitesmoke",
                                mt: 2,
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        }}
                        placeholder={t("relation")}
                        size="small"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setStat(e.target.value)}
                        value={stat}
                        name={"stat"}
                        autoFocus
                        autoComplete="off"
                        select
                        SelectProps={{
                            displayEmpty: true,
                            renderValue: (selected) =>
                                selected === "" ? t("state") : selected,
                        }}
                    >
                        <MenuItem value="">{t("selstate")}</MenuItem>
                        {states.map((state, i) => (
                            <MenuItem
                                key={i}
                                value={state.name}
                                onClick={() => handleState(state)}
                            >
                                {state.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        sx={{
                            ".MuiInputBase-root": {
                                borderRadius: 2,
                                backgroundColor: "whitesmoke",
                                mt: 2,
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                        }}
                        placeholder="Relation"
                        size="small"
                        fullWidth
                        variant="outlined"
                        onChange={(e) => setDistrict(e.target.value)}
                        value={district}
                        name={"district"}
                        autoFocus
                        autoComplete="off"
                        select
                        SelectProps={{
                            displayEmpty: true,
                            renderValue: (selected) =>
                                selected === "" ? t("district") : selected,
                        }}
                    >
                        <MenuItem value="">{t("seldis")} </MenuItem>
                        {districts.map((district, i) => (
                            <MenuItem
                                key={i}
                                value={district.name}
                                onClick={() => handleDistrict(district)}
                            >
                                {district.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )}
                    <Box mt={4} sx={{ mb: { md: 0, sm: 8, xs: 8 } }}>
                        <Button
                            variant="contained"
                            sx={{ width: "150px", height: "35px", borderRadius: 2 }}
                            onClick={handleOnConfirm}
                            disabled={
                                !register.address || !register.pinCode || !stat || !district
                            }
                            startIcon={
                                loading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : null
                            }
                        >
                            {t("next")}
                        </Button>
                    </Box>
                </Box>
            </Paper>
            <Box mt={1}></Box>
        </div >
    );
}

export default Registration;