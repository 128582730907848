import React, { useRef } from 'react'
import { Typography } from '@mui/material'
import PinInputField from '../../../atoms/TextFields/PinInputField';

function NewConfirmResetPin({ pin, setPin, setAlertmessage }) {
    const otpFieldsRef = useRef([]);

    const handleInput = (index, value) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value.length === 1 && index < pin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertmessage('');
        } else if (value !== '') {
            setAlertmessage('Please enter 4 digits number only');
        }
    };


    const handleBackspace = (index) => {
        if (pin[index] !== '') {
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertmessage('')

    };
    return (
        <div>
            <Typography sx={{ fontWeight: 600 }}>Confirm Consent PIN</Typography>
            <div style={{ display: 'flex', gap: '20px', marginTop: '20px', textAlign: 'center', justifyContent: 'center' }}>
                {pin.map((value, index) => (

                    <PinInputField
                        type="text"
                        maxLength="1"
                        value={value}
                        key={index}
                        onChange={(e) => handleInput(index, e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Backspace') {
                                handleBackspace(index);
                            }
                        }}
                        ref={(ref) => {
                            otpFieldsRef.current[index] = ref;
                        }} />
                ))}
            </div>
        </div >
    )
}

export default NewConfirmResetPin