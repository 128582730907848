import { Box } from '@mui/material';
import React from 'react'

const Video = ({ docFeed }) => {
    const videoUrl = docFeed.sourceLink

    return (
        <Box p={"10px"}>
            <video controls width="100%" height="400">
                <source src={videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
            </video>
        </Box>
    );
}


export default Video