import React, { useEffect } from "react";
import SearchBar from "../../../atoms/Searchbar";
import { Box, Button, Grid, Typography } from "@mui/material";
import { getmedicalrecords } from "../../../../redux/Actions/recordActions";
import { localstore } from "../../../../localStore/localStore";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { getAbhaMobile } from "../../../../redux/Actions/Actions";
import filterIcon from '../../../../assets-new/filter-Icon.svg'
import MedicalRecordsData from "./MedicalRecordsdata";
import { useNavigate } from "react-router-dom";
import { AddBox } from "@mui/icons-material";
import searchblue from '../../../../assets-new/services-icons/search-Icon-blue.svg'


const MedicalRecords = () => {
    const token = localstore.getToken()

    const primaryId = localstore.getPrimaryId()
    const patientId = localstore.getPatientId()
    const [searchValue, setSearchValue] = useState('');
    const [links, setLinks] = useState([])
    const navigate = useNavigate()
    const mobiledata = useSelector((state) => state.abha.mobileprofile)
    // console.log(mobiledata, 'mobiel')
    const dispatch = useDispatch()
    let medicaldata = useSelector(stat => stat?.records?.medicalrecords);
    // console.log(medicaldata, "medicals")

    const searchOnChangeHandler = (e) => {
        // const searchTerm = e.target.value.toLowerCase();
        // setSearchValue(searchTerm);

        // const filtered = medicaldata.hiTypes.filter(link => {
        //     const hipName = link.toLowerCase();
        //     return hipName.includes(searchTerm);
        // });
        // setLinks(filtered);
    };


    useEffect(() => {
        dispatch(getmedicalrecords(token, primaryId));

    }, [token, primaryId,])
    // console.log(data, 'ddddddddddddddddddddddd')
    useEffect(() => {
        dispatch(getAbhaMobile(primaryId, token))
    }, [])
    const [t, i18n] = useTranslation("global");



    // const data = [
    //     {
    //         patientCons: t("records.Patientconsultation"),
    //         date: "12-Mar-2023 | 3:00 PM",
    //         hospital: t("records.parmithahospital"),
    //         doctor: t("records.doctor1"),
    //         prescription: t("records.prescription"),
    //     },
    //     {
    //         patientCons: t("records.Patientconsultation"),
    //         date: "26-Mar-2023 | 5:00 PM",
    //         hospital: t("records.pallavihospital"),
    //         doctor: t("records.doctor2"),
    //         prescription: t("records.prescription1"),
    //     },
    //     {
    //         patientCons: t("records.Patientconsultation"),
    //         date: "26-Mar-2023 | 5:00 PM",
    //         hospital: t("records.pallavihospital"),
    //         doctor: t("records.doctor3"),
    //         prescription: t("records.prescription1"),
    //     },
    // ];

    return (
        <Box m={2}>
            <Typography sx={{ mt: { xs: 0, sm: 1, md: "12px" }, fontSize: '17px', fontWeight: 600 }}> Linked Medical Records </Typography>
            <Box sx={{ mt: { xs: 2, sm: 3, md: 3 } }}>
                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={2} sm={1} md={1}  >
                        <img src={filterIcon} alt="filter" />
                    </Grid>
                    <Grid item xs={8} sm={9} md={8} sx={{
                        '.MuiTextField-root': {
                            width: '100%'
                        }
                    }}
                    >
                        <SearchBar placeholder="Search Medical Records" value={searchValue} name='searchValue'
                            onChange={searchOnChangeHandler} icon={searchblue} />
                    </Grid>

                </Grid>

                {
                    medicaldata?.length > 0 ? (
                        medicaldata?.map((each, ind) => {
                            return (
                                <Grid sx={{ marginTop: '10px' }} key={ind}>
                                    <MedicalRecordsData item={each} />
                                </Grid>
                            )
                        })) : <Typography sx={{ textAlign: 'center', fontSize: '14px', mt: 10 }}>No Records Available</Typography>
                }

            </Box>
        </Box >
    )
}
export default MedicalRecords;