import React, { useEffect, useState } from 'react'
import URL from '../../../../utility/apiurl'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { localstore } from '../../../../localStore/localStore'
import { AppBar, Box, Button, Card, CardContent, Checkbox, FormControlLabel, Grid, IconButton, Typography } from '@mui/material'
// import hospitalIcon from '../../../../assets/home/consent_module/hospital.svg'
import medxpertsIcon from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import expiredIcon from '../../../../assets/home/consent_module/expired.svg'
import filterIcon from '../../../../assets-new/filter-Icon.svg'
import { ArrowForwardIos, CheckCircle, Pending, RemoveCircleOutline } from '@mui/icons-material'
import { debounce } from 'lodash'
import moment from 'moment'
import RevokeDialog from './RevokeDialog'


const GrantedCardData = ({ item, primaryId, token }) => {
    // console.log(item, "grantitemsssssss")
    const dateObject = moment(item?.createdAt);
    const date = dateObject.format('DD-MMM-YYYY')
    const time = dateObject.format('hh:mm A');
    const [hospital, setHospital] = useState('')

    const debouncedAxiosCall = debounce((data) => {
        axios.get(`${URL.consents}providers/${data}/${primaryId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setHospital(res.data)
        }).catch(err => {
            console.log(err.message)
        })
    }, 2000);

    useEffect(() => {
        if (item && item?.hiu && item?.hiu?.id) {
            // setLoading(true); // Set loading to true when making the API request
            debouncedAxiosCall(item?.hiu?.id);
        }

        // Cancel API call when component unmounts
        return () => {
            debouncedAxiosCall.cancel();
        };
    }, [item]);


    const StatusIcons = (type) => {

        if (type === 'DENIED') {
            return <IconButton><RemoveCircleOutline sx={{ color: 'red', mt: '5px', mr: "5px" }} /></IconButton>;
        }
        if (type === 'EXPIRED') {
            return <img src={expiredIcon} alt="expired" style={{ marginTop: "5px", marginLeft: '10px' }} />;
        }
        if (type === 'REQUESTED') {
            return <IconButton><Pending sx={{ color: '#247FEB', ml: "3px" }} /></IconButton>;
        }
    }
    const StatusButtons = (type) => {
        if (type === 'GRANTED') {
            return <RevokeDialog item={item} />

        }
        if (type === 'DENIED') {
            return <Typography sx={{ fontSize: { xs: '10px', md: '15px', sm: '14px' } }} >
                {item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase() : ''}
            </Typography>
        }
        if (type === 'EXPIRED') {
            return <Typography sx={{ fontSize: { xs: '10px', md: '15px', sm: '14px' } }} >
                {item.status ? item.status.charAt(0).toUpperCase() + item.status.slice(1).toLowerCase() : ''}
            </Typography>
        }
        if (type === 'REQUESTED') {
            return <Typography sx={{ fontSize: { xs: '10px', md: '15px', sm: '14px' } }} >
                Pending
            </Typography>
        }
    }
    return (
        // <CardContent >
        <Box sx={{ p: "10px 20px" }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {item?.requester?.name ?
                    <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                        {item?.requester?.name}
                    </Typography>
                    : <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                        Health Locker
                    </Typography>
                }
                <Typography sx={{ fontSize: { xs: '11px', sm: '14px', md: '14px' } }} >
                    {date} | {time}
                </Typography>
            </Grid>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', }}>
                    {hospital.orgImg ?
                        <img src={hospital.orgImg} alt="orgimg" width={45} height={45} style={{ borderRadius: '5px', marginLeft: '12px' }} /> : <img src={medxpertsIcon} alt="hospital" style={{ width: 50, height: 50 }} />}
                    <Box>
                        {hospital?.identifier?.name ?
                            <Typography sx={{ fontSize: { xs: '14px', sm: '14px', md: '14px' }, fontWeight: 500 }}>{hospital?.identifier?.name}</Typography> : <Typography sx={{ fontSize: { xs: '13px', sm: '18px', md: '17px' }, fontWeight: 500 }}>Medxperts Health Locker</Typography>}

                        <Typography sx={{ mt: "5px", fontSize: { xs: '13px', sm: '13px', md: '13px' } }}>{item?.purpose?.text}</Typography>
                    </Box>
                </Box>
                <Box sx={{ mr: "5px", display: 'flex', alignItems: 'center' }}>
                    <Box >{StatusIcons(item?.status)}</Box>
                    <Box>{StatusButtons(item.status)}</Box>

                </Box>
            </Box>

        </Box>
        // </CardContent>
    )
}

function ConsentHistory({ allData }) {
    const data = allData
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    // const [data, setData] = useState()
    const [authorize, setAuthorize] = useState()
    const [locker, setLocker] = useState()
    const [subscribe, setSubscribe] = useState()
    const [collapsed, setCollapsed] = useState(true)
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const dispatch = useDispatch()
    const list = ['GRANTED', 'DENIED', 'EXPIRED', 'REVOKED']
    const [showlist, setShowlist] = useState(null)
    // console.log(data, 'consentsssssssssssssss')
    // useEffect(() => {
    //     axios.get(`${URL.consents}health-locker/request/${primaryId}/${abhadata?.AbhaAddress}?status=ALL`, {
    //         headers: { Authorization: `Bearer ${token}` }
    //     }).then(res => {
    //         console.log(res, "consnentssssss")

    //         setData(res?.data || {})
    //     })
    // }, [abhadata])

    const handleOnCheckbox = (value) => {
        if (showlist === value) {
            // If the same checkbox is clicked again, uncheck it
            setShowlist(null);
        } else {
            // If a different checkbox is clicked, check it and uncheck others
            setShowlist(value);
        }
    }
    // console.log(showlist, "show34")
    const handleOnCollapsed = () => {
        setCollapsed(!collapsed)
    }
    return (
        <div>
            <Card sx={{
                bgcolor: 'white', color: 'black', width: collapsed ? '130px' : "auto", textAlign: 'right',// Adjust the width based on collapsed state
                borderRadius: 2
            }}  >
                <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: { xs: 'space-evenly', md: 'start' }, p: { xs: 0, md: '0px 20px' }, }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, p: 1, cursor: 'pointer' }} onClick={handleOnCollapsed}>
                        <img src={filterIcon} alt="filter" width={20} height={20} />
                        < Typography >
                            Filter
                        </Typography>
                    </Box>
                    {/* <IconButton > <ArrowForwardIos sx={{ color: 'black', fontSize: 20 }} /></IconButton> */}
                    {list.map((e, index) => (
                        !collapsed &&
                        <Grid item key={index} ml={2}>
                            <FormControlLabel
                                control={<Checkbox checked={showlist === e} color="secondary" />}
                                label={<Typography sx={{ fontSize: 14 }}>{e.charAt(0).toUpperCase() + e.slice(1).toLowerCase()}</Typography>}
                                onChange={() => handleOnCheckbox(e)}
                            />
                        </Grid>
                    ))}

                </Grid>
            </Card>
            {/* {!collapsed && */}
            < Box >
                {
                    data ?
                        <Box sx={{
                            mb: { xs: 10, sm: 10, md: "30px" },
                            // overflowY: 'scroll', height: '381px', '&::-webkit-scrollbar': {
                            //     display: 'none',
                            // },
                        }}>
                            <div>
                                {data && Object.keys(data).map((key) => (
                                    <>
                                        {
                                            key === 'consents' || key === 'subscriptions' || key === 'authorizations' ?
                                                <div key={key}>
                                                    {/* <h3 style={{ marginTop: '10px', marginLeft: '10px' }}>{key}</h3> */}

                                                    <div>
                                                        {data[key]?.requests.map((item, index) => (
                                                            <>
                                                                {(!showlist || showlist === item.status) &&
                                                                    < Card key={index} sx={{
                                                                        width: { xs: '100%', sm: "100%", md: "100%" }, boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)', mt: 2,
                                                                    }}>
                                                                        <GrantedCardData item={item} primaryId={primaryId} token={token} />
                                                                    </Card >}
                                                            </>
                                                        ))}
                                                    </div>
                                                </div > : ''}
                                        {
                                            key === "lockerSetups" ?
                                                <div key={key}>
                                                    {/* <h3 style={{ marginTop: '10px', marginLeft: '10px' }}>{key}</h3> */}

                                                    <div>
                                                        {data[key]?.requests.map((item, index) => (
                                                            <>

                                                                < Card key={index}
                                                                    sx={{
                                                                        width: { xs: '100%', sm: "100%", md: "100%" }, boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)', mt: 2,
                                                                    }}>
                                                                    {item.authorization ? (
                                                                        <>
                                                                            {(!showlist || showlist === item.status) &&

                                                                                <GrantedCardData item={item.authorization} primaryId={primaryId} token={token} />

                                                                            } </>) : null}
                                                                </Card>
                                                                < Card
                                                                    sx={{
                                                                        width: { xs: '100%', sm: "100%", md: "100%" }, boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)', mt: 2,
                                                                    }}>
                                                                    {item.subscription ? (
                                                                        <>
                                                                            {(!showlist || showlist === item.status) &&

                                                                                <GrantedCardData item={item.subscription} primaryId={primaryId} token={token} />
                                                                            } </>) : null}
                                                                </Card>
                                                            </>
                                                        ))}
                                                    </div>
                                                </div > : null}
                                    </>
                                ))}
                            </div>
                        </Box >
                        : <Typography
                            sx={{ textAlign: 'center', fontSize: { xs: 13, sm: 15, md: 15 }, mt: 3 }}
                        >
                            No  Records Available
                        </Typography>
                }
            </Box >
            {/* } */}
        </div >
    )
}

export default ConsentHistory