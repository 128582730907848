import React, { useState } from 'react';
import { AppBar, Box, Card, Toolbar, Typography, useMediaQuery } from '@mui/material';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom'
import ServicesIcon from '../../assets-new/menubar-icons/servicesicon.svg'
import RecordsIcon from '../../assets-new/menubar-icons/recordsicon.svg'
import MedxpediaIcon from '../../assets-new/menubar-icons/medxpedia-active.svg'
import CareKitIcon from '../../assets-new/menubar-icons/carechart.svg'
import AccountIcon from '../../assets-new/menubar-icons/account.svg'
import MedxpertIcon from '../../assets-new/menubar-icons/medpedia.svg'
import servicesActive from '../../assets-new/menubar-icons/services-active.svg'
import recordsActive from '../../assets-new/menubar-icons/recordsactive.svg'
import carechartActive from '../../assets-new/menubar-icons/carechartActive.svg'
import accountActive from '../../assets-new/menubar-icons/accountactive.svg'
import { useTranslation } from "react-i18next";
import './Menubar.css'

const TabItem = (props) => (
    <Box sx={{
        display: 'flex', gap: 1, alignItems: 'center', justifyContent: 'center', p: 1, borderRadius: "10px", cursor: 'pointer', bgcolor: props.bgcolor, color: props.active ? 'white' : 'black', width: "125px", height: '42px'// Set text color based on active state
    }} onClick={props.onClick}>
        <img src={props.icon} alt="account" />
        <Typography sx={{ fontSize: 14 }}>{props.title}</Typography>
    </Box>
)
const MobileTabItem = (props) => (
    <Link to={props.to} >
        <Box
            sx={{
                borderBottom: props.borderBottom,
                textAlign: 'center'
            }}
            onClick={props.onClick}
        >
            <img src={props.icon} alt="account" />
            <Typography sx={{ fontSize: { xs: "11px !important", sm: "14px !important" }, color: "black", fontWeight: 600 }}>{props.title}</Typography>
        </Box>
    </Link >
)

function Menubar() {
    const [t, i18n] = useTranslation("global");

    const navigate = useNavigate();
    const location = useLocation();
    const [open, setOpen] = useState(false)
    const [activeTab, setActiveTab] = useState(1); // State to track active tab

    const handleTabClick = (index, path) => {
        setActiveTab(index);
        navigate(path);
    };
    const itemsList = [
        {
            text: t("profileTabs.services"),

            icon: <img src={ServicesIcon} alt='services' />,
            path: "/services",
        },
        {
            text: t("profileTabs.records"),
            icon: <img src={RecordsIcon} alt='services' />,
            path: "/records",
        },
        {
            text: t("profileTabs.medxperts"),
            icon: <img src={MedxpertIcon} alt='services' />,
            path: "/",
        },
        {
            text: t("profileTabs.carekit"),
            icon: <img src={CareKitIcon} alt='services' />,
            path: "/carechart",
        },
        {
            text: t("profileTabs.payments"),
            icon: <img src={AccountIcon} alt='services' />,
            path: "/account",
        },
    ];


    const isActive = ['/', '/livehealth', '/doctors', '/medlearn', '/offers', '/consulted', '/favorites', '/moredoctors', '/moreorgs', '/providers', 'doctor/:id', 'org/:id', '/healthproblems', '/heartfailure'];
    const isMobileOrTabletView = useMediaQuery('(min-width: 960px)');

    return (
        <>
            {isMobileOrTabletView && (
                <Box sx={{
                    position: 'fixed',
                    zIndex: 1000,
                    top: "81px",
                    left: 0,
                    right: 0,
                }} >
                    <Card sx={{
                        p: "5px 250px",
                        display: { md: 'flex', sm: 'none', xs: "none" },
                        justifyContent: 'space-evenly',
                        alignItems: 'center',
                    }}>
                        <TabItem icon={location.pathname.includes('/services') || location.pathname.includes('/services/consultation') ? servicesActive : ServicesIcon} title="Services" bgcolor={location.pathname.includes('/services') ? '#205072' : 'initial' || location.pathname.includes('/services/consultation')} onClick={() => navigate('/services')} active={location.pathname.includes('/services') || location.pathname.includes('/services/consultations')} />
                        <TabItem icon={location.pathname.includes('/records') || location.pathname.includes('/records/medicalrecords') ? recordsActive : RecordsIcon} title="Records" bgcolor={location.pathname.includes('/records') || location.pathname.includes('/records/medicalrecords') ? '#205072' : 'initial'} onClick={() => navigate('/records')} active={location.pathname.includes('/records') || location.pathname.includes('/records/medicalrecords')} />
                        <Box sx={{ textAlign: 'center', cursor: 'pointer', bgcolor: isActive.includes(location.pathname) ? '#205072' : 'initial', borderRadius: "10px", p: "5px", height: '45px', width: '75px' }} onClick={() => navigate('/')} >
                            <img src={isActive.includes(location.pathname) ? MedxpediaIcon : MedxpertIcon} alt="medxpedia" width={20} height={20} />
                            <Typography sx={{
                                fontSize: 11, color: isActive.includes(location.pathname) ? 'white' : 'black',
                            }}>MedXpedia</Typography>
                        </Box>
                        <TabItem icon={location.pathname.includes('/carechart') || location.pathname.includes('/carechart/caredashboard') ? carechartActive : CareKitIcon} title="Care 360" bgcolor={location.pathname.includes('/carechart') || location.pathname.includes('/carechart/caredashboard') ? '#205072' : 'initial'} onClick={() => navigate('/carechart')} active={location.pathname.includes('/carechart') || location.pathname.includes('/records/caredashboard')} />
                        <TabItem icon={location.pathname === '/account' ? accountActive : AccountIcon} title="Account" bgcolor={location.pathname === '/account' ? '#205072' : 'initial'} onClick={() => navigate('/account')} active={location.pathname === '/account'} />
                    </Card >
                </Box >
            )}


            <AppBar position="fixed" sx={{ backgroundColor: 'white', color: 'black', display: { xs: 'flex', sm: 'flex', md: 'none' }, bottom: 0, top: 'auto' }}>
                <Toolbar className="toolbar">
                    <div className='navlinks' >

                        <MobileTabItem icon={ServicesIcon} title="Services" borderBottom={location.pathname.includes('/services') ? '3px solid #205072' : 'none' || location.pathname.includes('/services/consultation')} to={'/services'} />
                        <MobileTabItem icon={RecordsIcon} title="Records" borderBottom={location.pathname.includes('/records') || location.pathname.includes('/records/medicalrecords') ? '3px solid #205072' : 'initial'} to={'/records'} />
                        <Link to={'/'} >
                            <Box sx={{ textAlign: 'center', cursor: 'pointer', borderBottom: isActive.includes(location.pathname) ? '3px solid #205072' : 'initial', }}  >
                                <img src={MedxpertIcon} alt="medxpedia" />
                                <Typography sx={{
                                    fontSize: { xs: "11px !important", sm: "14px !important" }, fontWeight: 600, color: isActive.includes(location.pathname) ? 'black' : 'initial',
                                }}>MedXpedia</Typography>
                            </Box>
                        </Link >
                        <MobileTabItem icon={CareKitIcon} title="Care 360" borderBottom={location.pathname.includes('/carechart') || location.pathname.includes('/carechart/caredashboard') ? '3px solid #205072' : 'initial'} to={'/carechart'} active={location.pathname.includes('/carechart') || location.pathname.includes('/carechart/caredashboard')} />
                        <MobileTabItem icon={AccountIcon} title="Account" borderBottom={location.pathname === '/account' ? '3px solid #205072' : 'initial'} to={'/account'} active={location.pathname === '/account'} />
                        {/* {
                            itemsList.map((e, i) => (
                                <>
                                    <Link to={e.path} key={i} >
                                        <Box
                                            sx={{
                                                borderBottom: location.pathname.includes(e.path) ? '2px solid red' : 'initial',
                                            }}
                                            onClick={() => handleTabClick(i + 1, e.path)}
                                        >
                                            {e.icon}
                                            <Typography sx={{ fontSize: { xs: "11px !important", sm: "14px !important" }, color: "black", fontWeight: 700 }}>{e.text}</Typography>
                                        </Box>
                                    </Link >
                                </>
                            ))
                        } */}
                    </div >
                </Toolbar >
            </AppBar >
        </>
    );
}

export default Menubar;
