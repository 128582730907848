
import React, { useEffect } from 'react'
import axios from 'axios';
import { Button, Typography, Checkbox, Box, Card, CircularProgress } from '@mui/material';
import medxpertsIcon from '../../../../assets/home/medxpertspassion.svg'
import { RemoveCircleOutline, TaskAlt } from '@mui/icons-material';
import InformationType from './InformationType';
import InformationSource from './InformationSource';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import { getHealthStatus } from '../../../../redux/Actions/consentActions';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getLinkedProviders } from '../../../../redux/Actions/recordActions';

function HealthLocker1(props) {
    const { requestdata, setShow } = props
    const [open, setOpen] = useState(false)
    const [open1, setOpen1] = useState(false)
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [state, setState] = useState('');
    const primaryId = localstore.getPrimaryId()
    const token = localstore.getToken()
    const csrftoken = localstore.getCsrfToken()
    const [links, setLinks] = useState([])
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const dispatch = useDispatch()
    const healthstatus = useSelector((state) => state.consent.healthstatus)
    let linkeddata = useSelector(stat => stat.records.linkedproviders);
    const [selectedHospitals, setSelectedHospitals] = useState([]);
    const [selectedRecords, setSelectedRecords] = useState({});
    const [isCheckedState, setIsCheckedState] = useState({});
    const [isVisible, setisvisible] = useState(true);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);


    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [unselectedCheckboxes, setUnselectedCheckboxes] = useState([]);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()

    const handleDropdownSelect = (selectedItems) => {
        setSelectedCheckboxes(selectedItems);
    };
    const handleDropdownUnSelect = (selectedItems) => {
        setUnselectedCheckboxes(selectedItems);
    };
    useEffect(() => {
        if (token && primaryId) {
            dispatch(getLinkedProviders(token, primaryId));
        }
    }, [token, primaryId])


    const handleApprove = async () => {
        // console.log('-------->>>>handleapprove');
        // setLoading(true)
        // let data = {
        //     patientId: requestdata?.authorization?.patientId,
        //     subrequestId: requestdata?.subscription?.id,
        //     includedHiTypes: selectedCheckboxes,
        //     excludedHiTypes: unselectedCheckboxes,
        //     subscriptionIncludedPeriod: {
        //         from: requestdata?.subscription?.period?.from,
        //         to: requestdata?.subscription?.period?.to,
        //     },
        //     subscriptionExcludedPeriod: {
        //         from: '2054-03-16T09:30:07.7535819',
        //         to: '2054-03-17T00:00:00',
        //     },
        //     hiu: {
        //         id: 'MEDXPERTS_LOCKER',
        //         name: 'MEDXPERTS',
        //     },
        //     autoApprovalIncludedPeriod: {
        //         from: requestdata?.subscription?.period?.from,
        //         to: requestdata?.subscription?.period?.to,
        //     },
        //     autoApprovalExcludedPeriod: {
        //         from: '2054-03-16T09:30:07.7535819',
        //         to: '2054-03-17T00:00:00',
        //     },
        //     authrequestId: requestdata?.authorization?.requestId,
        // };
        // // console.log("-----HealthLockerData", data)
        // // if (reqeuestdata?.authorization?.requester?.id === 'MEDXPERTS_LOCKER') {
        // await axios
        //     .post(`${URL.consents}health-locker/request/approval/${primaryId}`, data, {
        //         headers: {
        //             'Authorization': `Bearer ${token}`
        //         }
        //     })
        //     .then(async res => {
        //         console.log(res.data, 'post api data');
        //         setAlertmessage('Health-locker approval Successfull');
        //         setAlertstatus('success');
        //         dispatch(getHealthStatus(abhadata?.AbhaAddress, primaryId, token));
        //         setState('');
        //         navigate('/services')
        //         setShow(1)
        //     })
        //     .catch(err => {
        //         console.log(err, 'error in post api');
        //         setAlertmessage(err.message);
        //         setAlertstatus('error');
        //     }).finally(() => {
        //         setLoading(false)
        //     })
        // // } else {
        // //     console.log('-----');
        // // }
        const patientId = requestdata?.subscription?.patient.id;
        const subrequestId = requestdata?.subscription?.id;
        const hiuId = requestdata?.subscription?.hiu.id;
        const authRequestId = requestdata?.authorization?.requestId;
        const requestBody = {
            patientId,
            subrequestId,
            includedSources: selectedHospitals
                .filter((hospital) => hospital?.categories?.length > 0)
                .map((hospital) => ({
                    hiTypes: selectedCheckboxes,
                    purpose: {
                        text: requestdata?.subscription?.purpose.text,
                        code: requestdata?.subscription?.purpose.code,
                        refUri: requestdata?.subscription?.purpose.refUri,
                    },
                    hip: {
                        id: hospital?.hip?.id,
                        name: hospital?.hip?.name,
                    },
                    categories: hospital?.categories,
                    period: {
                        from: new Date(hospital?.period?.from).toISOString(),
                        to: new Date(hospital?.period?.to).toISOString(),
                    },
                })),
            hiu: {
                id: requestdata?.subscription?.id,
                name: requestdata?.subscription?.name,
            },
            excludedSources: [],
            authrequestId: authRequestId,
        };
        console.log('requestbody', requestBody);
        setLoading(true);
        if (requestdata.authorization.requester.id === 'MEDXPERTS_LOCKER' && requestdata !== undefined) {
            await axios
                .post(`${URL.consents}health-locker/request/V2/approval/${primaryId}`, requestBody, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    console.log(res.data, 'post api ApproveResponse');
                    setAlertmessage('Health-locker approval Successfull');
                    setAlertstatus('success');
                    dispatch(getHealthStatus(abhadata?.AbhaAddress, primaryId, token));

                })
                .catch(err => {
                    console.log(err, 'error in post api');
                    setAlertmessage(err.message);
                    setAlertstatus('error');


                });
        } else {
            setLoading(false);
        }
    };







    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box sx={{ display: "flex", alignItems: 'center', gap: 2, mt: 1 }}>
                    <TaskAlt sx={{ fontSize: '35px', color: '#39A500' }} />
                    <Box>
                        <Typography sx={{ fontWeight: 600, fontSize: '17px' }}>
                            Health Locker Access Request

                        </Typography>
                        {/* <Typography variant="h6" sx={{ fontWeight: 600 }}>
                            Access Request
                        </Typography> */}
                    </Box>
                </Box>
            </Box>

            <Card sx={{ p: 2, mt: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: "-10px" }}>
                    <img src={medxpertsIcon} alt="medxperts" width={"160px"} height={"50px"} />
                    <Box>
                        <Typography sx={{ color: 'black', fontSize: { xs: 14, sm: 15, md: 16 } }}>
                            Self Requested
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 15 } }}>
                            Exp.Date 21-Mar-2024
                        </Typography>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                    <Checkbox defaultChecked color="secondary" />
                    <Typography sx={{ fontSize: { xs: "12px", sm: 14, md: 14 } }}>Allowed Medxperts to automatically Fetch and store my Health Records (subscription)</Typography>
                </Box>

                <Typography sx={{ color: 'black', mt: 1, fontSize: { xs: 14, sm: 15, md: 16 } }}>Information Type Requested</Typography>

                <InformationType open={open} setOpen={setOpen} selectedCheckboxes={selectedCheckboxes} onSelectedItemsChange={handleDropdownSelect} onUnSelectedItemsChange={handleDropdownUnSelect}
                />

                <Typography sx={{ color: 'black', mt: 2, fontSize: { xs: 14, sm: 15, md: 16 } }}>Information Source</Typography>
                {/* <InformationSource open={open1} setOpen={setOpen1} hospitals={hospitals} /> */}
                <InformationSource open={open1} setOpen={setOpen1} requestdata={requestdata} linkeddata={linkeddata} selectedHospitals={selectedHospitals} setSelectedHospitals={setSelectedHospitals} />

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                    <Checkbox defaultChecked color='secondary' />
                    <Typography sx={{ fontSize: { xs: "12px", sm: 14, md: 14 }, }}>Automatically grant Consent request for MedXperts (consent auto-approval)</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                    <Checkbox defaultChecked color='secondary' />
                    <Typography sx={{ fontSize: { xs: "12px", sm: 14, md: 14 }, }}>Allow Health Records from MedXperts to be linked  authorise MedXperts as HIP</Typography>
                </Box>

                <Box sx={{ display: 'flex', justifyContent: "end", gap: 2, mt: 2, }}>
                    <Button variant='outlined' sx={{ color: 'red', borderRadius: 2, border: '1px solid red' }} startIcon={<RemoveCircleOutline sx={{ color: 'red' }} />}>Reject</Button>
                    <Button onClick={handleApprove} startIcon={loading ? <CircularProgress sx={{ color: "white" }} size={20} /> : <TaskAlt />} variant="contained" sx={{ bgcolor: '#247FEA', gap: 1, width: "120px", borderRadius: 2 }}>
                        Approve
                    </Button>
                </Box>
            </Card>
        </div>
    )
}

export default HealthLocker1