import React, { useEffect } from 'react'
import { useState } from 'react'
import { Box, Button, Card, CircularProgress, IconButton, Paper, Typography } from '@mui/material'
import axios from 'axios';
import URL, { AadharPublicKey } from '../../../../utility/apiurl';
import { useDispatch, useSelector } from 'react-redux';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { useTranslation } from "react-i18next";
import JSEncrypt from 'jsencrypt';
import { ArrowBackIos } from '@mui/icons-material';

function AadharStep4(props) {
    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(59);
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const result = useSelector((state) => state.abha.abhaAadhar)
    const mobileNumber = useSelector((state) => state.abha.ndhmAadhar)
    const [t, i18n] = useTranslation("global");

    // console.log(mobileNumber, "aaaaaaaaaa")
    let abhadata = useSelector(state => state.abha.abhaprofile);
    const ibm = useSelector(state => state.medx.ibmpdata)
    const dispatch = useDispatch()



    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));

    }

    const token = localstore.getToken();
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = AadharPublicKey

    const handleSubmit = async () => {
        setLoading(true)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(publicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let d = {
            'otp': encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            'txnId': result.txnId,
            mobileOrAadhaar: 'aadhaar',
        };
        setLoading(true);
        await axios.post(`${URL.abha}v2/verifyMobileOtp/${primaryId}`, d, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            console.log(res.data);
            handleSkip(res.data.txnId);
            setAlertMessage(t("otpversuccesfully"));
            setAlertstatus('success');
        }).catch(err => {
            console.log(err.message);
            setAlertMessage(t("otpenterincrt"));
            setAlertstatus('error');
        }).finally(() => { setLoading(false); });
    };

    const handleLink = async (jwtResponsetoken) => {
        let d = {};
        try {
            const response = await axios.post(
                `${URL.abha}link/phrAddress/${primaryId}`,
                d,
                {
                    headers: {
                        'X-Token': `Bearer ${jwtResponsetoken}`,
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log(response.data, 'linking successful');
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryId,
                    token,
                ),
            );
            dispatch(getAbhaProfile(primaryId, token));
            setAlertMessage(t("dataregsuccessfully"));
            setAlertstatus('success');
            props.setShow(7)
        } catch (error) {
            console.log(error.message, 'in linking');
            setAlertMessage(error.message);
            setAlertstatus('error');
        }
    };


    const handleSkip = async (transactionId) => {
        let data = {
            txnId: transactionId,
        };
        await axios.post(`${URL.abha}v2/createHealthIdByAdhaar/${primaryId}`, data, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async res => {
                console.log('------------->>>> createHealthId Res', res);
                let healthid = res.data.healthId;
                let phrAddress = res.data.HealthId;
                let HealthNumber = res.data.healthIdNumber;
                let healthToken1 = res.data.sessionId;
                let jwttoken = res.data.token
                let data3 = res.data;
                let details = {
                    sessionId: healthToken1,
                    mobile: mobileNumber,
                }
                dispatch({
                    type: 'AADHARSTEP4_SESSIONID',
                    payload: details
                })


                dispatch({
                    type: "AADHARSTEP4_PHRADDRESS2",
                    payload: phrAddress
                })

                axios
                    .get(`${URL.abha}HealthIdNumber/${primaryId}`, {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                    .then(res => {
                        var HealthIdNumber1 = res.data.healthIdNumber;
                        const xtoken = res.data.token
                        const adhardetails = {
                            healthIdNumber: HealthIdNumber1,
                            xtoken: xtoken,
                            aadhartoken: true,
                        }
                        dispatch({
                            type: "AADHARSTEP4_TOKEN_HIN",
                            payload: adhardetails
                        })
                        dispatch({
                            type: "AADHARSTEP4_PHRADDRESS1",
                            payload: healthid
                        })
                        if (healthid && HealthNumber) {
                            dispatch(getAbhaProfile(primaryId, token))

                            let ibmp = ibm?.identifier
                                ? { ...ibm }
                                : { ...ibm, identifier: [] };
                            let da = {
                                ...ibmp,
                                identifier: [
                                    ...ibmp.identifier,
                                    {
                                        system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                        value: HealthNumber,
                                        type: {
                                            coding: [
                                                {
                                                    code: 'HIN',
                                                },
                                            ],
                                        },
                                    },
                                ],
                            };
                            // if (healthid) {
                            da.identifier.push({
                                system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                value: healthid,
                                type: {
                                    coding: [
                                        {
                                            code: 'ABHA', // or any other code you want to use for Healthid
                                        },
                                    ],
                                },
                            });
                            // } else {
                            //     da.identifier.push({
                            //         system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                            //         value: phrAddress,
                            //         type: {
                            //             coding: [
                            //                 {
                            //                     code: 'ABHA', // or any other code you want to use for Healthid
                            //                 },
                            //             ],
                            //         },
                            //     });
                            // }


                            axios
                                .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                    },
                                })
                                .then(res => {
                                    // handleLink(jwttoken, healthid)
                                    dispatch(
                                        getPatientdatafromibm(
                                            'Patient',
                                            primaryId,
                                            token,
                                        ),
                                    );
                                    props.setShow(7)
                                })
                                .catch(err => {
                                    console.log(err.message, 'error in update');
                                })
                        } else {
                            props.setShow(13)
                        }
                    }).catch(err => {
                        console.log(err, 'Get HealthIdNUmber');
                    });
            }).catch(err => {
                console.log(err, 'error before updated');
            }).finally(() => {
                setLoading(false)
            })
    };


    let formattedMobileNumber = '';
    if (typeof mobileNumber === 'string') {
        formattedMobileNumber = mobileNumber.slice(-4);
    }

    const handleBack = (e) => {
        props.setShow(3)
    }
    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: { xs: 1, sm: 2, md: 2 },
                    mt: { xs: '5px', sm: '10px', md: "10px" }
                }}
            >
                <IconButton onClick={() => props.setShow(3)}>
                    <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                </IconButton>
                <Typography
                    sx={{
                        fontSize: { xs: 17, sm: "17px", md: "17px" },
                        fontWeight: "500",
                        color: "#007DCD",
                    }}
                >
                    Change Mobile Number
                </Typography>
            </Box>
            <Paper elevation={3} sx={{ mt: { xs: "5px", sm: '5px', md: "5px" }, p: 3 }}>
                <Box>
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center", // Center-align horizontally
                            justifyContent: "center", // Center-align vertically
                            minHeight: "100%", // Ensure full height of the card
                            textAlign: "center", // Center-align text within the card
                        }}
                    >
                        <Typography
                            sx={{ fontSize: { xs: 14, md: 16 }, fontWeight: 500 }}
                        >
                            {t("verifyotp")}
                        </Typography>

                        <Box>
                            <Box mt={2}>
                                <TextInputField
                                    width="350px"
                                    name="otp"
                                    placeholder={t("credentialsData.signupmobiledata.enterotp")}
                                    border="none"
                                    value={otp}
                                    onChange={handleOnChangeOtp}
                                />
                            </Box>
                            {alertmessage && (
                                <Typography
                                    sx={{
                                        color: alertstatus === "success" ? "green" : "red",
                                        ml: 1,
                                        mt: 1,
                                    }}
                                >
                                    {alertmessage}
                                </Typography>
                            )}
                            {/* {
                                ? <Typography sx={{ color: 'red' }}>{error.number}</Typography> : null
                            } */}
                            {/* <Typography sx={{ mt: 1 }} onClick={handleBack}>{'< Back'}</Typography> */}
                            <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                                <Typography
                                    sx={{
                                        textAlign: "center",
                                        fontSize: { xs: 14, md: 14 },
                                        color: "black",
                                    }}
                                >
                                    {t("otpsentmobile")}+91 xxxx xx {formattedMobileNumber}
                                </Typography>
                                <Button
                                    variant="contained"
                                    sx={{ borderRadius: 2, width: "150px", mt: 3 }}
                                    onClick={handleSubmit}
                                    disabled={loading || otp.length < 5}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : null
                                    }
                                >
                                    {t("credentialsData.signupmobiledata.submitbtn")}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </div>
    );
}
export default AadharStep4