import React from 'react'
import { Box, Button, Avatar, Grid, Switch, TextField, Typography, CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import { localstore } from '../../../../../localStore/localStore';
import { Metadata } from '../../../../atoms/Metadata';
import URL from '../../../../../utility/apiurl';
import axios from 'axios';
import { getpostbyid } from '../../../../../redux/Actions/Actions';
import { useEffect } from 'react';
import AnonymousIcon from '../../../../../assets/home/feed_assets/anonymous.svg'
import UserIcon from '../../../../../assets/home/usericon.svg'
import DoctorsComment from './DocComments';

function AskDocTimeline({ item, commentOpen }) {
    // console.log(postdata, "propsssss")
    const [anonymous, setAnonymous] = useState(false);
    const [text, setText] = useState('')
    const patientName = localstore.getPatientName()
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const patientId = localstore.getPatientId()
    const token = localstore.getToken()
    const metadata = { ...Metadata(), author: patientId };
    const dispatch = useDispatch()
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [alertopen, setAlertopen] = useState(false);
    const [skel, setSkel] = useState(false)
    let questiondata = item?.patientquestionData
    const [loading, setLoading] = useState(false)

    // console.log(questiondata, "questiondataaaaaaaaa")
    // console.log(commentOpen, "rajaaa")
    const handleAsk = () => {
        setLoading(true)
        let data = {
            'comment': text,
            'commentBy': patientId,
            'metadata': metadata,
            'userData': {
                'fullName': patientName?.replace(/['"]+/g, ''),
                'profilePic': '',
                'caption': '',
            },
            'anonymous': anonymous,
            'postId': item.id,
        }

        axios.post(`${URL.comment}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            console.log(res, "comment")
            setAlertopen(true);
            // handleNotification('COMMENTED');
            setAlertstatus('success');
            setAlertMessage('Question posted successfully!');

            setTimeout(() => {
                setAlertopen(false);
            }, 2000);
            dispatch(getpostbyid(item.id, token));
        })
            .catch(err => {
                setAlertopen(true);
                setAlertstatus('error');
                setAlertMessage(err.message);
                setTimeout(() => {
                    setAlertopen(false);
                }, 2000);
            })
            .finally(() => {
                setText('');
                setLoading(false)
            });
    }
    return (
        <Box sx={{ mb: { xs: 10 } }}>
            {
                commentOpen && (<>
                    <Grid container mt={2}>
                        <Grid item md={1} sm={1} xs={2}>
                            {
                                anonymous ?
                                    <img style={{ marginTop: "4px" }} variant="rounded" src={AnonymousIcon} />
                                    :
                                    ibm?.photo && ibm?.photo[0]?.url?.length > 0 ? (
                                        <img
                                            src={{ uri: ibm.photo[0].url }}
                                        />
                                    ) : (
                                        <Avatar sx={{ width: 34, height: 34, mt: "4px" }} variant="rounded">
                                            <img src={UserIcon} alt="usericon" />
                                        </Avatar>
                                    )
                            }

                        </Grid>
                        <Grid item xs={5} sm={7} md={7} >

                            <Box mt={1}>

                                {
                                    anonymous ?
                                        <Typography fontWeight={"bold"}>Anonymous</Typography>
                                        :
                                        <Typography fontWeight={"bold"}>{(patientName?.replace(/['"]+/g, ''))}</Typography>

                                }
                            </Box>

                        </Grid>
                        <Grid md={3} xs={4} sm={3} my={1} >
                            <Typography variant="caption" >

                                <Typography>Ask as Anonymous</Typography>

                            </Typography>
                        </Grid>
                        <Grid md={1} xs={1} sm={1}>
                            <Switch
                                // size={Platform.OS === 'ios' ? 'small' : 'medium'}    
                                checked={anonymous}
                                onChange={() => {
                                    setAnonymous(!anonymous);
                                }}
                            />
                        </Grid>

                    </Grid>
                    <Grid container>

                        <Grid item xs={2} sm={1} md={1}></Grid>
                        <Grid item xs={10} sm={11} md={11}>
                            <TextField
                                sx={{
                                    // borderRadius: 2
                                    ".MuiInputBase-root": {
                                        borderRadius: 2,
                                        backgroundColor: 'whitesmoke',
                                        mt: 2
                                    }
                                }}
                                fullWidth
                                name="Outlined"
                                placeholder="Write your Question here..."
                                variant="outlined"
                                multiline
                                rows={1}
                                value={text}
                                onChange={(e) => setText(e.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid container mt={2}>
                        <Grid item xs={10.5} sm={11} md={10.7}>
                        </Grid>
                        <Grid item xs={1.5} sm={1} md={1.3}>

                            <Button variant='contained' disabled={!text || loading} onClick={handleAsk}>
                                {loading ? <CircularProgress size={24} /> : <Typography sx={{ fontSize: '14px' }} >Ask</Typography>}
                            </Button>

                        </Grid>
                    </Grid>

                    <Box>

                        {
                            questiondata?.map((e, i) => (
                                <DoctorsComment e={e} i={i} name={patientName} key={i} />
                            ))
                        }
                    </Box>

                </>
                )
            }
        </Box >
    )
}

export default AskDocTimeline