import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import livehealthicon from '../../../assets-new/medxpedia-icons/livehealthicon.svg';
import doctorsicon from '../../../assets-new/medxpedia-icons/doctor_stethascope.svg';
import medlearnicon from '../../../assets-new/medxpedia-icons/medlearn.svg';
import offersicon from '../../../assets-new/medxpedia-icons/offers.svg';
import { getDoctorsFollowCount, getOrgFollowCount } from '../../../redux/Actions/Actions';
import { localstore } from '../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { KeyboardArrowDown } from '@mui/icons-material';
import Telemedicine from './Telemedicine';
import TabItem from '../../atoms/TabItem';
import Logo from '../../../assets/home/medxpertspassion.svg'
import MobileViewleft from './MedxResponsive'



const SubTabItem = ({ icon, label, selected, count, onClick, to }) => (
    <Box
        sx={{
            gap: 2,
            cursor: 'pointer',
            // backgroundColor: selected ? '#EFEFEF' : 'transparent',
            borderRadius: "5px",
            boxShadow: selected ? 1 : 'none',
            mt: 1,
            p: "3px 0px 3px 10px",
            width: '150px',
            ml: 3
        }}

        onClick={onClick}
    >
        <Grid container>
            <Grid item md={10.5}>
                <Typography sx={{ fontSize: '13px' }}>{label}</Typography></Grid>
            <Grid item md={1.5}><Typography sx={{ fontSize: '13px', fontWeight: 600 }}>{count}</Typography></Grid>
        </Grid>
    </Box>
);



function Medxpedia() {
    const location = useLocation()
    const [selectedTab, setSelectedTab] = useState('livehealth');
    const [selectedSubTab, setSelectedSubTab] = useState(null);
    const patientId = localstore.getPatientId()
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    let doctorscount = useSelector(state => state.medx.doctorsCount);
    let orgscount = useSelector(state => state.medx.orgsCount);

    // console.log(doctorscount, "iii");
    const dispatch = useDispatch()
    const navigate = useNavigate()
    useEffect(() => {
        if (patientId) {
            dispatch(getDoctorsFollowCount(patientId))
            dispatch(getOrgFollowCount(patientId))
        }
    }, [patientId])

    // useEffect(() => {
    //     const pathSegments = location.pathname.split('/');
    //     const currentTab = pathSegments[pathSegments.length - 1];
    //     setSelectedTab(currentTab);
    // }, [location.pathname]);

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        setSelectedSubTab(null);
        navigate(`/${tab}`)
    };

    const handleSubTabClick = (subTab) => {
        setSelectedSubTab(subTab);
        navigate(`/${subTab}`)

    };
    const hashtags = ['SkinCare', 'Diabetes', 'PainManagement', 'HealthyDiet', 'WeightLoss'];

    return (

        <Box>
            <MobileViewleft doctorscount={doctorscount} orgscount={orgscount} />
            <Grid container>
                <Grid item md={0.2}></Grid>
                <Grid item xs={12} sm={12} md={2}>
                    {/* <MobileViewTop /> */}

                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'flex' },
                            flexDirection: {
                                sm: 'row', md: 'column'
                            },
                            gap: 1,
                            mt: { xs: '140px', sm: '90px', md: "70px" },
                            position: 'fixed'
                        }}
                    >
                        <TabItem
                            icon={livehealthicon}
                            label="Live Health"
                            fontSize={"17px"}
                            selected={selectedTab === 'livehealth'}
                            onClick={() => handleTabClick("livehealth")}
                        />
                        <TabItem
                            icon={doctorsicon}
                            label="My Doctors"
                            fontSize={"17px"}
                            selected={selectedTab === 'doctors'}
                            onClick={() => handleTabClick("doctors")}

                        />
                        {selectedTab === 'doctors' && (
                            <>
                                <SubTabItem
                                    label="Recently Consulted"
                                    count={0}
                                    selected={selectedSubTab === 'consulted'}
                                    onClick={() => handleSubTabClick("consulted")}
                                />

                                <SubTabItem
                                    label="My Favourites"
                                    count={doctorscount?.DoctorCount ? doctorscount.DoctorCount : 0}

                                    selected={selectedSubTab === 'favorites'}
                                    onClick={() => handleSubTabClick("favorites")}
                                />

                                <SubTabItem
                                    label="My Providers"
                                    count={orgscount?.DoctorCount ? orgscount?.DoctorCount : 0}

                                    selected={selectedSubTab === 'providers'}
                                    onClick={() => handleSubTabClick("providers")}
                                />
                            </>
                        )}
                        <TabItem
                            icon={medlearnicon}
                            label="Med Learn "
                            fontSize={"17px"}
                            selected={selectedTab === 'medlearn'}
                            onClick={() => handleTabClick("medlearn")}
                        />
                        <TabItem
                            icon={offersicon}
                            label="Offers "
                            fontSize={"17px"}
                            selected={selectedTab === 'offers'}
                            onClick={() => handleTabClick("offers")}
                        />
                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={6.8} mt={{ xs: '0px', sm: '0px', md: "50px" }}>
                    <Outlet />
                </Grid>
                <Grid item xs={0} sm={0} md={3} mt={"50px"} >
                    {token && primaryId ? <Box sx={{ mt: "20px", p: '0px 10px 0px 0px' }}><Telemedicine /></Box> :
                        <Box sx={{ position: 'fixed', top: '125px', right: '80px', zIndex: 1 }}>
                            <Box>
                                <Typography sx={{ fontWeight: 600, mt: "22px", p: "0px 10px" }}>
                                    Following Trending <Typography sx={{ color: '#0525AA', fontWeight: 600 }} component={"span"}># Topics</Typography>
                                </Typography>
                                <Box>
                                    {
                                        hashtags.map((tag, index) =>
                                            < Grid container mt={2} key={index}>
                                                <Grid item md={1}></Grid>
                                                <Grid item md={8}>
                                                    <Typography sx={{ fontSize: '14px' }}>
                                                        #{tag}
                                                    </Typography>
                                                </Grid>
                                                <Grid item md={3}>
                                                    <Button variant='outlined' sx={{ borderRadius: '10px', width: '80px', height: "23px", }}><Typography sx={{ fontSize: '13px' }}> Follow</Typography></Button>
                                                </Grid>
                                            </Grid>
                                        )}
                                </Box>
                                <Box sx={{ textAlign: 'center', mt: '25px' }}>
                                    <Button sx={{ background: '#F4F2FF', "&:hover": { background: '#F4F2FF' }, color: 'black', height: '30px', borderRadius: '10px' }} variant='contained' endIcon={<KeyboardArrowDown />} >
                                        <Typography sx={{ fontSize: '14px' }}>Find More</Typography>
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    }
                </Grid >
            </Grid >
        </Box >
    );
}


// // const LiveHealthContent = () => <div>Live Health Content</div>;
// const MyDoctorsContent = () => <div>My Doctors Content</div>
// const SubTabContent1 = () => <div>my subcontent1</div>;
// const SubTabContent2 = () => <div>my subcontent2</div>;
// const SubTabContent3 = () => <div>my subcontent3</div>;
// const MedLearn = () => <div>medlearn</div>;
// const Offers = () => <div>offers</div>;






export default Medxpedia;
