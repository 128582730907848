import React, { useEffect, useState } from "react";
import { Check, ExpandLess, ExpandMore } from '@mui/icons-material';
import { Box, Card, Checkbox, Divider, IconButton, Typography } from '@mui/material';

import prescriptionIcon from '../../../../assets/home/consent_module/prescription.svg';
import consultationIcon from '../../../../assets/home/consent_module/consultation.svg';
import diagnosticIcon from '../../../../assets/home/consent_module/diagnostic.svg';
import dischargeIcon from '../../../../assets/home/consent_module/discharge.svg';
import immunisationIcon from '../../../../assets/home/consent_module/immunisation.svg';
import documentIcon from '../../../../assets/home/consent_module/document.svg';
import WellnessRecordIcon from '../../../../assets/home/consent_module/lifestyle.svg';

function InformationType(props) {
    const { open, setOpen, onSelectedItemsChange, onUnSelectedItemsChange, selectedCheckboxes } = props;

    const items = [
        { id: 1, text: 'Prescription', icon: <img src={prescriptionIcon} alt="pre" /> },
        { id: 2, text: 'OPConsultation', icon: <img src={consultationIcon} alt="cons" /> },
        { id: 3, text: 'DiagnosticReport', icon: <img src={diagnosticIcon} alt="diag" /> },
        { id: 4, text: 'WellnessRecord', icon: <img src={WellnessRecordIcon} alt="diag" /> },
        { id: 5, text: 'DischargeSummary', icon: <img src={dischargeIcon} alt="discharge" /> },
        { id: 6, text: 'ImmunizationRecord', icon: <img src={immunisationIcon} alt="immunisation" /> },
        { id: 7, text: 'HealthDocumentRecord', icon: <img src={documentIcon} alt="document" /> },
    ];

    const [selectAllChecked, setSelectAllChecked] = useState(true);

    useEffect(() => {
        // Initialize selectedCheckboxes based on selected item texts when the component mounts
        onSelectedItemsChange(items.map((item) => item.text));
        onUnSelectedItemsChange([]);
    }, []);

    const handleSelectAll = () => {
        if (selectedCheckboxes.length === items.length) {
            onUnSelectedItemsChange(items.map((item) => item.text));
            onSelectedItemsChange([]);
        } else {
            onSelectedItemsChange(items.map((item) => item.text));
            onUnSelectedItemsChange([]);
        }
        setSelectAllChecked(!selectAllChecked);
    }

    const handleCheckboxChange = (itemName) => {
        onSelectedItemsChange((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemName)) {
                return prevSelectedItems.filter((selectedItem) => selectedItem !== itemName);
            } else {
                return [...prevSelectedItems, itemName];
            }
        });

        onUnSelectedItemsChange((prevUnselectedItems) => {
            if (prevUnselectedItems.includes(itemName)) {
                return prevUnselectedItems.filter((unSelectedItem) => unSelectedItem !== itemName);
            } else {
                return prevUnselectedItems.concat(itemName);
            }
        });
        const updatedSelectedItems = selectedCheckboxes.includes(itemName)
            ? selectedCheckboxes.filter((selectedItem) => selectedItem !== itemName)
            : [...selectedCheckboxes, itemName];
        setSelectAllChecked(updatedSelectedItems.length === items.length);
    }

    return (
        <div>
            <Box>
                <Card sx={{
                    boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: 'pink', p: '0px 10px' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            {/* <IconButton> */}
                            {/* <Box sx={{ border: '1px solid black', width: "25px", height: '25px', borderRadius: 5, cursor: 'pointer' }} onClick={handleSelectAll}>
                                {selectAllChecked ? <Check sx={{ color: '#0118B4' }} /> : null}
                            </Box> */}
                            <Checkbox
                                color="secondary"
                                checked={selectAllChecked}
                                onChange={handleSelectAll}
                            />
                            {/* </IconButton> */}
                            <Typography sx={{ fontWeight: 600, fontSize: { xs: 14, sm: 14, md: 14 } }}>{"All (Prescription,Reports,Discharge,...)"}</Typography>
                        </Box>
                        <IconButton onClick={() => setOpen(!open)}>{open ? <ExpandLess sx={{ color: "black" }} /> : <ExpandMore sx={{ color: "black" }} />}</IconButton>
                    </Box>
                    {
                        open && (
                            <Box
                            >
                                {
                                    items.map((item, index) => (
                                        <React.Fragment key={index}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "0px 10px" }}>
                                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                                    {item.icon}
                                                    <Typography sx={{ fontSize: '14px' }}>{item.text}</Typography>
                                                </Box>
                                                <Checkbox
                                                    color='secondary'
                                                    checked={selectedCheckboxes.includes(item.text)}
                                                    onChange={() => handleCheckboxChange(item.text)}
                                                />
                                            </Box>
                                            <Divider />
                                        </React.Fragment>
                                    ))
                                }
                            </Box>
                        )}
                </Card>
            </Box>
        </div>
    )
}

export default InformationType;
