import * as React from 'react';
import { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { CircularProgress, TextField } from '@mui/material';
import { useSelector } from 'react-redux';
import axios from 'axios';
import URL from '../../../utility/apiurl';
import { useEffect } from 'react';
import patientdata from '../../atoms/Patientdata';
import { useTranslation } from "react-i18next";
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import CommonButton from '../../atoms/CommonButton';
import { useNavigate } from 'react-router-dom';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';
import { localstore } from '../../../localStore/localStore';

function SignupOtp(props) {
    // console.log(props)
    const phoneNumber = useSelector((state) => state.signup.registerPageMobileNumber)
    const mobileNumber = `+91${phoneNumber}`
    const profiledata = useSelector(state => state.signup.profiledata)
    const credentials = useSelector(state => state.signup.credentials)
    // const usertoken = useSelector((state) => state.signup.gettoken);
    const usertoken = localstore.getToken()

    // console.log(credentials, "mobile")
    const [otp, setOtp] = useState('')
    const [loading, setLoading] = useState(false)
    const [timer, setTimer] = useState(null);
    const [time, setTime] = useState(59);
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const countrycode = useSelector((state) => state.medx.countrycode);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()
    const handleOnback = () => {
        props.setShow(2)
    }


    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 5));
    }
    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://www.google.com/recaptcha/api.js?render=6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-"
        document.body.appendChild(script)
    }, [])

    const handleAddpatient = async () => {
        const padata = profiledata?.lastname
            ? {
                ...patientdata.patientData,
                gender: profiledata?.gender?.toLowerCase(),
                birthDate: profiledata?.dateofbirth,
                resourceType: 'Patient',
                name: [
                    {
                        ...patientdata.patientData.name[0],
                        given: [profiledata?.firstname],
                        family: profiledata?.lastname,
                    },
                ],
                telecom: [
                    {
                        ...patientdata.patientData.telecom[0],
                        value: mobileNumber,
                    },
                ],
                photo: profiledata?.image?.length > 0 ? [{ url: profiledata?.image }] : [],
            }
            : {
                ...patientdata?.patientData,
                gender: profiledata?.gender?.toLowerCase(),
                birthDate: profiledata?.dateofbirth,
                resourceType: 'Patient',
                name: [
                    {
                        ...patientdata.patientData.name[0],
                        given: [profiledata?.firstname],
                    },
                ],
                telecom: [
                    {
                        ...patientdata.patientData.telecom[0],
                        value: mobileNumber,
                    },
                ],
                photo: profiledata?.image?.length > 0 ? [{ url: profiledata?.image }] : [],
            }
        let data = {
            ...patientdata,
            createdDate: new Date().toISOString(),
            phoneNumber: mobileNumber,
            patientData: padata,

        }
        console.log(data, "daata")
        await axios.post(`${URL.patient}keycloak/Ibm/addPatient`, data, {
            headers: { 'Authorization': `Bearer ${usertoken}` }
        })
            .then(res => {
                console.log(res, "resssssssss")
                // setAlertmessage('account created successfull')
                // setAlertstatus('success')
                navigate('/login', { state: { patientIbmId: true } })
            }).catch(err => {
                setAlertmessage(err.message)
                setAlertstatus('error')
            })
    }

    const verifySubmit = () => {
        setLoading(true)
        const data = {
            "medxperts": 'True',
            "phoneNumber": mobileNumber,
            'otp': otp,
            'email': '',
        };
        axios.post(`${URL.verifyotp}`, data, {
            headers: { 'usertoken': `${usertoken}` }
        }).then(res => {
            console.log(res, "verifyOtp")
            if (res.status === 200) {
                setAlertmessage('Otp verified successfully')
                setAlertstatus('success')
                handleAddpatient()
            }
        }).catch(err => {
            setAlertmessage("Either otp is expired or invalid.")
            setAlertstatus('error')
        }).finally(() => {
            setLoading(false)
        })
    }
    useEffect(() => {
        // Clear the timer when the component is unmounted
        setTime(59);

        return () => {
            clearInterval(timer);
        };

    }, [timer]);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    const handleResendOtp = () => {
        const data = {
            "messagetype": "OTP",
            "apptype": "medxperts",
            "phoneNumber": mobileNumber,
            "email": ""
        }
        // timeinterval();
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-", { action: "submit" })
                .then(token => {
                    axios.post(`${URL.sendOtp}`, data, { headers: { "Authorization": `Bearer ${token}` } }).then(res => {
                        console.log(res, "sendotp")
                        if (res.status === 200 || res.status === 201) {
                            setAlertmessage("Otp send Successfully")
                            setAlertstatus('success')
                            setTime(59);

                            // Clear the previous timer
                            if (timer) {
                                clearInterval(timer);
                            }

                            // Rest of the code for resending OTP
                            // ...

                            // Start the new timer
                            setTimer(
                                setInterval(() => {
                                    setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
                                }, 1000)
                            );

                        }
                    }).catch(err => {
                        setAlertmessage(err.message)
                        setAlertstatus('error')
                    }).finally(() => {
                        setLoading(false)
                    })
                })
        })
    }
    return (
        <>
            <Box
                sx={{
                    mt: 2,
                    p: 2,
                }}
            >
                <Typography
                    sx={{ fontSize: { xs: 17, md: 17 }, fontWeight: 600 }}
                >
                    Link with Mobile Number
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ mt: 1, color: "gray", fontSize: { xs: 12, md: 14 } }}
                >
                    {t("credentialsData.signupmobiledata.subtxt1")} (+91)
                    {t("credentialsData.signupmobiledata.subtxt2")}
                    {""}
                    <Typography
                        component="span"
                        sx={{ color: "blueviolet", fontSize: { xs: 12, md: 14 } }}
                    >
                        {t("credentialsData.signupmobiledata.subtxt3")}
                    </Typography>{" "}
                </Typography>
            </Box>
            <Box sx={{ mt: 1 }}>
                <ColorTextField label="Mobie Number" value={mobileNumber || '+919876543210'} bgcolor="whitesmoke" />
            </Box>
            <Box sx={{ mt: 4 }}>
                <ColorTextField value={otp} label="OTP" placeholder="Enter OTP" name="otp" type="text" onChange={handleOnChangeOtp} />
            </Box>
            {alertmessage && (
                <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "0px 10px", mt: "5px" }}>
                <Typography sx={{ fontSize: "12px" }}>{`00:${time} `}</Typography>
                <Button disabled={time > 0} sx={{ textTransform: "capitalize" }}>
                    <Typography
                        sx={{ fontSize: { xs: 10, md: 12 } }}
                        onClick={handleResendOtp}
                    >
                        {t("credentialsData.signupmobiledata.resendotp")}
                    </Typography>
                </Button>
            </Box>
            <Box sx={{ textAlign: 'center', mt: 3 }}>
                <CommonButton bgcolor=" blueviolet" text="Submit" width="150px" onClick={verifySubmit}
                // disabled={loading || otp.length < 5} startIcon={loading ? <CircularProgress size={20} /> : null}
                />
            </Box>

            <Box textAlign="center" >
                <Button
                    type="submit"
                    variant="text"
                    sx={{
                        mt: "15px",
                        textTransform: "capitalize",
                        borderRadius: 3,
                        color: "#935711",
                        width: "100px",
                        height: "35px",
                        fontSize: { xs: "13px", md: 15 },
                    }}
                    onClick={handleOnback}
                >
                    {"<"} {t("credentialsData.signupmobiledata.backbtn")}
                </Button>
            </Box>
        </>
    );
}

export default SignupOtp;