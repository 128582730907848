import axios from "axios";
import URL from "../../utility/apiurl";


export const getHealthStatus = (phr, ibmId, token) => {
    if (phr && ibmId && token) {
        return (dispatch) => {
            axios.get(`${URL.consents}health-locker/setting/${ibmId}/MEDXPERTS_LOCKER/${phr}/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            })
                .then(res => {
                    // console.log(res.data);
                    dispatch({
                        type: 'HEALTH-STATUS',
                        payload: res.data.statusCode,
                    });
                })
                .catch(err => {
                    // console.log(err, 'healthstatus');
                    dispatch({
                        type: 'HEALTH-STATUS',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'HEALTH-STATUS',
                payload: [],
            });
        };
    }
};

export const getConsents = (primaryid, phraddress, status, token) => {
    // console.log(primaryid, token, phraddress, status, 'getconsentGranted');
    if (token?.length > 0 && status) {
        return (dispatch) => {
            axios.get(`${URL.consents}health-locker/request/${primaryid}/${phraddress}?status=${status}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    dispatch({
                        type: 'CONSENTDATA',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    // console.log(err, 'consentgranted');
                    dispatch({
                        type: 'CONSENTDATA',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'CONSENTDATA',
                payload: [],
            });
        };
    }
};
export const getConsentGranted = (primaryid, phraddress, status, token) => {

    if (token?.length > 0 && status) {
        return (dispatch) => {
            axios.get(`${URL.consents}consentArtefacts/${primaryid}/${phraddress}/?status=${status}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    //console.log(res, 'granteddata');
                    dispatch({
                        type: 'GRANTED_DATA',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    //console.log(err, 'consentgranted');
                    dispatch({
                        type: 'GRANTED_DATA',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'GRANTED_DATA',
                payload: [],
            });
        };
    }
};
export const getConsentDenied = (primaryid, phraddress, status, token) => {
    // console.log(token, status, phraddress, status, 'getconsentGranted');
    if (token?.length > 0 && status) {
        return (dispatch) => {
            // console.log('URL', `${URL.consents}health-locker/request/${primaryid}/${phraddress}?status=${status}`);
            axios.get(`${URL.consents}health-locker/request/${primaryid}/${phraddress}?status=${status}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    //console.log(res, 'granteddata');
                    dispatch({
                        type: 'DENIED_DATA',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    //console.log(err, 'consentgranted');
                    dispatch({
                        type: 'DENIED_DATA',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'DENIED_DATA',
                payload: [],
            });
        };
    }
};
export const getConsentRevoked = (primaryid, phraddress, status, token) => {
    // console.log(token, status, phraddress, status, 'getconsentGranted');
    if (token?.length > 0 && status) {
        return (dispatch) => {
            axios.get(`${URL.consents}health-locker/request/${primaryid}/${phraddress}?status=${status}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    dispatch({
                        type: 'REVOKED_DATA',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    //console.log(err, 'REVOKED_DATA');
                    dispatch({
                        type: 'REVOKED_DATA',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'REVOKED_DATA',
                payload: [],
            });
        };
    }
};
export const getConsentExpired = (primaryid, phraddress, status, token) => {
    // console.log(token, status, phraddress, status, 'getconsentGranted');
    if (token?.length > 0 && status) {
        return (dispatch) => {
            // console.log('URL', `${URL.consents}health-locker/request/${primaryid}/${phraddress}?status=${status}`);
            axios.get(`${URL.consents}health-locker/request/${primaryid}/${phraddress}?status=${status}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    // console.log(res, 'EXPIRED_DATA');
                    dispatch({
                        type: 'EXPIRED_DATA',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    //console.log(err, 'EXPIRED_DATA');
                    dispatch({
                        type: 'EXPIRED_DATA',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'EXPIRED_DATA',
                payload: [],
            });
        };
    }
};
export const getConsentsrequested = (primaryid, phraddress, status, token) => {
    // console.log(token, status, phraddress, status, 'getconsentGranted');
    if (token?.length > 0 && status) {
        return (dispatch) => {
            // console.log('URL', `${URL.consents}health-locker/request/${primaryid}/${phraddress}?status=${status}`);
            axios.get(`${URL.consents}health-locker/request/${primaryid}/${phraddress}?status=${status}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {

                    dispatch({
                        type: 'REQUEST_DATA',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    //console.log(err, 'REQUEST_DATA');
                    dispatch({
                        type: 'REQUEST_DATA',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'REQUEST_DATA',
                payload: [],
            });
        };
    }
};

export const fetchHospitalData = (data, primaryid, token) => {
    // console.log('fetchhospitaldata', data, primaryid);
    return (dispatch) => {
        if (token?.length > 0) {
            axios
                .get(`${URL.consents}providers/${primaryid}/${data}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    // console.log('fetchHospital2', res.data);
                    dispatch({
                        type: 'HOSPITAL_DATA_RECEIVED',
                        payload: res.data,
                    });
                })
                .catch((err) => {
                    //console.log(err, 'newActionError');
                    dispatch({
                        type: 'HOSPITAL_DATA_RECEIVED',
                        payload: null, // You can set the payload to null or an appropriate value in case of an error
                    });
                });
        } else {
            dispatch({
                type: 'HOSPITAL_DATA_RECEIVED',
                payload: null, // You can set the payload to null or an appropriate value if the token is missing or invalid
            });
        }
    };
};




export const getHealthlocker = (pid) => {
    if (pid) {
        return (dispatch) => {
            axios.get(`${URL.consents}health-locker?patientId=${pid}`,)
                .then(res => {

                    dispatch({
                        type: 'HEALTH-LOCKER',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    //console.log(err.message, 'healthlocker');
                    dispatch({
                        type: 'HEALTH-LOCKER',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'HEALTH-LOCKER',
                payload: [],
            });
        };
    }
};

export const getHealthLockersData = (primaryid, phraddress, token, status) => {
    if (token?.length > 0 && primaryid && phraddress) {
        return (dispatch) => {

            axios.get(`${URL.consents}health-locker/${primaryid}/${phraddress}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    console.log(res.data, "healthlocker")
                    dispatch({
                        type: 'HEALTHLOCKERS-DATA',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    console.log(err, 'HEALTHLOCKERS-DATA');
                    dispatch({
                        type: 'HEALTHLOCKERS-DATA',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'HEALTHLOCKERS-DATA',
                payload: [],
            });
        };
    }
};

export const getConsentsofHealthLocker = (primaryid, phraddress, token, status) => {
    console.log(`${URL.consents}health-locker/request/new/${primaryid}/${phraddress}?status=${status}`);
    if (token?.length > 0 && primaryid && phraddress && status) {
        return (dispatch) => {

            axios.get(`${URL.consents}health-locker/request/new/${primaryid}/${phraddress}?status=${status}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {

                    dispatch({
                        type: 'HLCONSENT_DATA',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    // console.log(err, 'HLCONSENT_DATA');
                    dispatch({
                        type: 'HLCONSENT_DATA',
                        payload: [],
                    });
                });
        };
    }
    else {
        return (dispatch) => {
            dispatch({
                type: 'HLCONSENT_DATA',
                payload: [],
            });
        };
    }
};