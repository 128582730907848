
import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf';
import { Box, Button } from "@mui/material";
import './PdfFile.css'
import { useNavigate } from 'react-router-dom';



function PdfView({ docFeed, stateview, image }) {
    // console.log(data, "fhgjk", stateview)
    const data = docFeed?.sourceLink
    const navigate = useNavigate()
    // const accpdf = image
    // console.log(url[0])
    const previous = "< previous";
    const next = "next >";
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }
    function changePage(offset) {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    function previousPage() {
        changePage(-1);
    }

    function nextPage() {
        changePage(1);
    }

    function onpreload(loaded, total) {
        // alert('Loading a document: ' + (loaded / total) * 100 + '%');
    }
    const handleclick = () => {
        // navigate('/pdfviewer', { state: { message: data } })

    }

    return (
        <>
            <Box sx={{ p: "10px" }}>
                <div onClick={handleclick}>
                    <Document
                        // canvasBackground="transparent"
                        // file='https://feed-dev-storage.s3.ap-south-1.amazonaws.com/files/220608111143424452.pdf'
                        file={data}
                        loading="Please wait!"
                        renderMode="canvas"
                        // options={{ workerSrc: "/pdf.worker.js" }}
                        onLoadSuccess={onDocumentLoadSuccess}
                        onLoadProgress={onpreload}
                        className={`${stateview ? "pdf_post" : "pdf_route"}`}
                    >
                        <Page
                            // width={300}
                            // height={300}
                            pageNumber={pageNumber}
                            renderAnnotationLayer={true}
                            renderTextLayer={false}
                            renderForms={true}
                        />
                    </Document>
                </div>
                <div className='bg_transperency'>
                    <div>
                        <Button
                            type="button"
                            disabled={pageNumber <= 1}
                            onClick={previousPage}
                            className={`${pageNumber <= 1 ? 'buttondisable' : 'buttonc'}`}
                        >
                            {`${previous}`}
                        </Button>
                    </div>
                    <div className="pagec">
                        {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
                    </div>
                    <div>
                        <Button
                            type="button"
                            disabled={pageNumber >= numPages}
                            onClick={nextPage}
                            className={`${pageNumber >= numPages ? 'buttondisable' : 'buttonc'}`}
                        >
                            {`${next}`}
                        </Button>
                    </div>
                </div>
            </Box>



        </>
    )
}

export default PdfView










