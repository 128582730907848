import React, { useEffect, useState } from 'react'
import { Close, SettingsApplications, VerifiedUser } from '@mui/icons-material'
import { Box, Card, IconButton, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import URL from '../../../../utility/apiurl'
import axios from 'axios'
import medxpertLogo from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import { localstore } from '../../../../localStore/localStore'
import { useDispatch, useSelector } from 'react-redux'
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat'
import { getHealthLockersData } from '../../../../redux/Actions/consentActions'
import MyhealthLocker from './MyhealthLocker'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'


function ActiveHealthLocker() {
    const navigate = useNavigate()
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [healthlocker, setHealthLocker] = useState(true)
    const [loading, setLoading] = useState(false);
    const [pageBox, setPageBox] = useState('');
    const [lockerNamedata, setLockerNameData] = useState(null);
    const [subscriptiondata, setsubscriptionData] = useState(null);
    const [authorizationdata, setauthorizationData] = useState(null);
    const [autoApprovals, setAutoApprovals] = useState(null);
    const dispatch = useDispatch()
    let abhadata = useSelector(stat => stat.abha.abhaprofile);
    // let healthlockerConsents = useSelector(state => state.consentreducer.healthlockerConsents);
    let healthlockerlist = useSelector(state => state.consent.healthlockerlist);
    // console.log(healthlockerlist, "healthlockers")
    // console.log(lockerNamedata, "lockernamedata")
    // console.log(autoApprovals, "autoApprovals")
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()

    useEffect(() => {
        // if (primaryId && abhadata?.AbhaAddress && lockerNamedata?.lockerId && token) {
        // }
        dispatch(getHealthLockersData(primaryId, abhadata?.AbhaAddress, token))

    }, [primaryId, token, lockerNamedata?.lockerId, abhadata?.AbhaAddress])

    // LOCKER DATA GET API HANDLER \\
    const getHandlelockerdata = async (Token, id, AbhaAddress, lockerid) => {
        console.log(lockerid, "lockedid")

        try {
            const response = await axios.get(`${URL.consents}health-locker/setting/${id}/${lockerid}/${AbhaAddress}`, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${Token}`,
                },
            });
            console.log(response.data, "healthlocker/setting")
            setsubscriptionData(response.data.body.subscriptions);
            setauthorizationData(response.data.body.authorizations)
            let data = response?.data?.body?.autoApprovals // Get the last object from the state
            const lastObject = data[data.length - 1];
            setAutoApprovals(lastObject);

        } catch (error) {
            console.log('Error in the API call:', error);
        }

    };



    const Handleenableapproval = async () => {
        try {
            const response = await axios.post(`${URL.consents}consent/request/enable/${primaryId}/${abhadata.AbhaAddress}/${autoApprovals?.id}`, {}, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            });
            setAlertmessage(' Autoapproval Enable Successfully ');
            setAlertstatus('success');
            if (primaryId && abhadata?.AbhaAddress && lockerNamedata?.lockerId && token) {
                getHandlelockerdata(token, primaryId, abhadata?.AbhaAddress, lockerNamedata?.lockerId)
            }
        } catch (error) {
            let data = error.response.data;
            const errorMessage = data.message;
            if (errorMessage.includes('Auto Approval Policy already enabled"')) {
                setAlertmessage('Auto Approval Policy already enabled');
                setAlertstatus('error');
            } else {
                setAlertmessage(error.message);
                setAlertstatus('error');
            }

        }

    };

    const Handledisableapproval = async () => {


        try {
            const response = await axios.post(`${URL.consents}consent/request/disable/${primaryId}/${abhadata.AbhaAddress}/${autoApprovals?.id}`, {}, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            });
            setAlertmessage(' Autoapproval disable Successfully ');
            setAlertstatus('success');

            if (primaryId && abhadata?.AbhaAddress && lockerNamedata?.lockerId && token) {
                getHandlelockerdata(token, primaryId, abhadata?.AbhaAddress, lockerNamedata?.lockerId)
            }
        } catch (error) {

            let data = error.response.data;
            const errorMessage = data.message;
            if (errorMessage.includes('Auto Approval Policy already disabled')) {
                setAlertmessage('Auto Approval Policy already disabled');
                setAlertstatus('error');
            } else {
                setAlertmessage(error.message);
                setAlertstatus('error');
            }

        }
    };
    const handleLockerData = (value) => {
        setHealthLocker(false)
        setLockerNameData(value)
        getHandlelockerdata(token, primaryId, abhadata?.AbhaAddress, value?.lockerId)
    }
    return (
        <div>
            <Box sx={{ mb: { sm: 5, xs: 5, md: 5 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>My Health Locker</Typography>
                    {/* <IconButton><Close sx={{ color: 'red' }} /></IconButton> */}
                </Box>
                <Box sx={{ mt: "15px" }}>

                    {healthlocker === true ?

                        healthlockerlist?.body?.map((item, index) => (

                            < Card key={index} sx={{ p: '10px 20px', mt: "20px" }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: "5px" }}>
                                        <VerifiedUser sx={{ color: '#0C9D00', fontSize: '20px' }} />
                                        <Typography sx={{ fontSize: '14px', color: '#0C9D00' }}>Active</Typography>
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>{DateFormat(item.dateCreated, 'DateTime')}</Typography>
                                    </Box>
                                </Box>

                                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        <img src={medxpertLogo} alt="medxperts" width={50} height={50} />
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{item.lockerName}
                                            </Typography>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Subscription,Authorization,Auto-Approval</Typography>
                                        </Box>
                                    </Box>
                                    <IconButton onClick={() => handleLockerData(item)}><SettingsApplications sx={{ color: '#2580EC', fontSize: '30px' }} /></IconButton>
                                </Box>

                                {
                                    alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                }
                            </Card>
                        ))
                        :
                        <Box >
                            <MyhealthLocker subscription={subscriptiondata} phraddress={abhadata?.AbhaAddress} alertmessage={alertmessage} alertstatus={alertstatus} autoApprovals={autoApprovals}
                                authorization={authorizationdata} lockerNamedata={lockerNamedata} handleenableapproval={Handleenableapproval} handledisableapproval={Handledisableapproval} />
                        </Box>
                    }
                </Box>
            </Box>

        </div >
    )
}

export default ActiveHealthLocker