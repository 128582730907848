import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CircularProgress, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, Typography } from '@mui/material'
import axios from 'axios';
import URL, { AadharPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos, Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { localstore } from '../../../../localStore/localStore';
import { useTranslation } from "react-i18next";
import JSEncrypt from 'jsencrypt';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { useNavigate } from 'react-router-dom';

function DeleteHealthId4(props) {
    const { setShow, handleClose } = props
    const result = useSelector((state) => state.abha.forgotHealthIdnum)
    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(59);
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    // const [phrAddress, setPhraddress] = useState('')
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }

    const handleVerify = async () => {
        // setShow(6)
        setLoading(true)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(AadharPublicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let d = {
            'otp': encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            'txnId': result.txnId,
            mobileOrAadhaar: 'aadhaar',
        };
        await axios.post(`${URL.abha}v2/verifyAadhaarOtp/${primaryId}`, d, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            const healthIdNumber = res.data.healthIdNumber
            if (healthIdNumber) {
                dispatch({
                    type: "GET_FORGOT_HEALTHID_NUMBER",
                    payload: healthIdNumber
                })
                setShow(6)
            } else {
                navigate('/aadharflow')
            }
            setAlertopen(true);
            setAlertmessage('OTP Verified Successfully!');
            setAlertstatus('success')
        }).catch(err => {
            console.log(err);
            setAlertopen(true);
            setAlertmessage('OTP entered is incorrect! or not enter ');
            setAlertstatus('error');
        })
            .finally(() => { setLoading(false); });
        setOtp('')
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);

    const handleResendOtp = async () => {
        const d = {
            txnId: result.txnId
        }
        await axios.post(`${URL.abha}v2/resendAadhaarOtp/${primaryId}`, d, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`
            },
        }).then(res => {
            // console.log(res.data);
            setAlertopen(true);
            setAlertmessage('OTP sent Successfully!');
            setAlertstatus('success');
            setTime(59);
        }).catch(err => {
            console.log(err.message);
            setAlertopen(true);
            setAlertmessage('Oops something went wrong!');
            setAlertstatus('error');

        }).finally(() => { setLoading(false); })
        setOtp('')
    }

    const handleClosedone = () => {
        handleClose()
        setShow(1)
    }
    return (
        <div>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '8px 20px' }}>

                <Typography sx={{ fontWeight: 600, fontSize: 17 }}> Verify AadharNumber</Typography>
                <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                        <Typography
                            sx={{
                                fontSize: { xs: 14, md: 16 },
                                fontWeight: 500,
                                textAlign: "center",
                            }}
                        >
                            {t("verifyotp")}
                        </Typography>

                        <Box>
                            <TextInputField
                                placeholder="Enter OTP"
                                mt={2}
                                name="otp"
                                border="none"
                                value={otp}
                                onChange={handleOnChangeOtp}
                            />
                            {alertmessage && (
                                <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                            )}

                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "0px 10px", mt: "5px" }}>
                                <Typography sx={{ fontSize: "12px" }}>{`00:${time} `}</Typography>
                                <Button disabled={time > 0} sx={{ textTransform: "capitalize" }}>
                                    <Typography
                                        sx={{ fontSize: { xs: 10, md: 12 } }}
                                        onClick={handleResendOtp}
                                    >
                                        {t("credentialsData.signupmobiledata.resendotp")}
                                    </Typography>
                                </Button>
                            </Box>

                            <Typography
                                sx={{
                                    fontStyle: "inherit",
                                    textAlign: "center",
                                    fontSize: { xs: 14, md: 14 },
                                    fontWeight: 400,
                                    mt: 2,
                                }}
                            >
                                {t("enterotpsndtoaadhar")} {result.mobileNumber}
                            </Typography>


                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                    <Button
                        variant="contained"
                        sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                        onClick={handleVerify}
                        disabled={loading || otp?.length < 6}
                        startIcon={
                            loading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : null
                        }
                    >
                        {t("verify")}
                    </Button>
                </Box>

            </DialogActions>
        </div>
    )
}

export default DeleteHealthId4