import React from 'react'
import { Box, Button, Avatar, Grid, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import AnonymousIcon from '../../../../../../assets/home/feed_assets/anonymous.svg'
import UserIcon from '../../../../../../assets/home/usericon.svg'
import { DateFormat } from '../../../../../atoms/DateTimeFormat/DateFormat';
import { Time } from '../../../../../atoms/DateTimeFormat/TimeFormat';


function CommentSection({ e, i, name }) {
    // console.log(e, "eeeeeeeeeeeeeeeee")
    const [quekey, setQuekey] = useState(-1);
    const [anskey, setAnskey] = useState(-1);
    const handleQueread = () => {
        setQuekey(i);
    };
    // console.log(i);
    const handleAnsread = () => {
        setAnskey(i);
    };
    const ibm = useSelector((state) => state.medx.ibmpdata)



    return (
        <Box>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: "10px" }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                    {
                        e.anonymous ?
                            <img variant="rounded" src={AnonymousIcon} />

                            :
                            ibm?.photo && ibm?.photo[0]?.url?.length > 0 ? (
                                <img
                                    src={{ uri: ibm.photo[0].url }}
                                />
                            ) : (
                                <Avatar sx={{ width: 34, height: 34, }} variant="rounded">
                                    {/* //     <img src={UserIcon} alt="usericon" /> */}
                                </Avatar>
                            )
                    }

                    <Box >
                        {
                            e.anonymous ?
                                <Typography fontWeight={600} sx={{ fontSize: '14px', }}>Anonymous</Typography>
                                :
                                <Typography fontWeight={600} sx={{ fontSize: '14px', }}>{(name?.replace(/['"]+/g, ''))}</Typography>

                        }
                        <Typography sx={{
                            fontSize: { xs: "14px", sm: "14px", md: "14px" },

                            overflow: 'hidden',
                            textOverflow: i === anskey ? 'clip' : 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: '-webkit-box',
                            '-webkit-line-clamp': i === anskey ? 'none' : 2,
                            '-webkit-box-orient': 'vertical',
                        }}
                            title={i === anskey ? undefined : e.reply}>
                            {e.comment}
                        </Typography>

                        {
                            e.comment.length > 75 ?
                                <Box justify="flex-end" align="flex-end">
                                    {
                                        quekey !== i ?
                                            <Button onPress={handleQueread}>
                                                <Typography >Readmore</Typography>
                                            </Button>
                                            :
                                            <Button onPress={() => { setQuekey(-1); }}>
                                                <Typography >Readless</Typography>
                                            </Button>
                                    }

                                </Box>
                                : null
                        }
                    </Box>

                </Box>
                <Typography sx={{ fontSize: { xs: 10, sm: 10, md: '11px' }, }}>{DateFormat(e.date)} | {Time(e.date)}</Typography>
            </Box>
        </Box>
    )
}

export default CommentSection;