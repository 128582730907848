
import { AddCircleOutline, ArrowDropDown, ListAlt } from '@mui/icons-material';
import { Box, Card, Grid, Paper, Typography } from '@mui/material';
import React, { useState } from 'react';

const Monitoring = () => {
    const days = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'];
    const [dayIndex, setDayIndex] = useState(4);
    const [selectedDate, setSelectedDate] = useState(new Date()); // Initialize with today's date

    const handleDays = (index) => {
        setDayIndex(index);

        // Calculate the date based on the selected day
        const today = new Date();
        const diff = index - today.getDay();
        const newDate = new Date(today);
        newDate.setDate(today.getDate() + diff);
        setSelectedDate(newDate);
    };

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 500 }}><ListAlt />Activate Medication</Typography>
                <Typography sx={{ fontSize: '14px', bgcolor: 'white', p: '2px', borderRadius: '5px' }}>List View</Typography>
            </Box>
            <Paper elevation={3} sx={{ mt: 2, opacity: 0.9 }}>
                <Box sx={{ p: '15px 20px' }}>
                    <Typography sx={{ textAlign: 'center', fontWeight: 500, fontSize: 14 }}> {selectedDate.toLocaleDateString()}</Typography>
                </Box>
                <hr />
                <div style={{ padding: '0 5rem 1rem 5rem' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        {days.map((item, index) => (
                            <Box key={index}>
                                <Box onClick={() => handleDays(index)} sx={{ position: 'relative' }}>
                                    {dayIndex === index ? (
                                        <ArrowDropDown sx={{
                                            position: 'absolute', bottom: '100%', left: '50%', fontSize: '30px', transform: 'translateX(-50%)'
                                        }} />
                                    ) : null}
                                    <Typography sx={{ fontSize: '14px', marginTop: '16px', width: '30px', height: '30px', borderRadius: '15px', padding: '5px', cursor: 'pointer', color: dayIndex === index ? "white" : 'black', backgroundColor: dayIndex === index ? '#2580EC' : 'white' }}>{item}</Typography>
                                </Box>
                            </Box>
                        ))}
                    </Box>
                </div>
            </Paper>
            <Card>
                <Grid container sx={{ p: '10px 20px' }}>
                    <Grid item md={2}>
                        <Typography>08:00 am</Typography>
                    </Grid>
                    <Grid item md={9}>
                        <Typography>Medication Display Name</Typography>

                    </Grid>
                    <Grid item md={1}>
                        <AddCircleOutline />
                    </Grid>
                </Grid>
            </Card>
        </div>
    );
};

export default Monitoring;
