import React, { useState } from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { useHistory, useNavigate } from 'react-router-dom'
import { localstore } from '../../../../localStore/localStore'
import URL, { PasswordpublicKey } from '../../../../utility/apiurl'
import axios from 'axios'
import { useDispatch } from 'react-redux'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import AdornmentTextField from '../../../atoms/TextFields/AdornmentTextField'
import { useTranslation } from "react-i18next";
import { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp'
import { cs } from 'date-fns/locale'
import { handleErrorResponse } from '../../../atoms/StatusError'
import JSEncrypt from 'jsencrypt'


function MobileEmailLogin() {
    const [activeButton, setActiveButton] = useState('mobile')
    const [mobileNumber, setMobileNumber] = useState('')
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const csrftoken = localstore.getCsrfToken()
    const primaryId = localstore.getPrimaryId()
    const publicKey = PasswordpublicKey
    const navigate = useNavigate()
    const [show, setShow] = useState(1)
    const dispatch = useDispatch()
    const [email, setEmail] = useState('')
    const [emailerrormsg, setEmailerrormsg] = useState('')
    const [t, i18n] = useTranslation("global");
    const [isValidEmail, setIsValidEmail] = useState(true);



    const handleOnChangeMobile = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setMobileNumber(inputValue.slice(0, 10));
        setAlertmessage('')
    }



    const handleValidations = () => {
        let isValid = true;
        if (!email) {
            setEmailerrormsg('Email Address is required');
        }
        if (email) {
            // console.log("if Email");/
            const EMAIL_REGEX = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
            if (EMAIL_REGEX.test(email)) {
                setEmailerrormsg('')
            }
            else (
                setEmailerrormsg('Enter Valid  Email Address')
            )
        }
        return isValid;

    }
    const validateEmail = (Email) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(Email);
    };

    const handleOnChangeEmail = (e) => {
        const isValid = validateEmail(e.target.value);
        setIsValidEmail(isValid);
        setEmail(e.target.value);
        setAlertmessage('')
    };

    // const handleOnChangeEmail = (e) => {
    //     const inputEmail = e.target.value;
    //     setEmail(inputEmail);
    //     setEmailerrormsg('')
    //     setAlertmessage('')
    // }


    const handleSendMobileOtp = async (e) => {
        // setShow(2)
        e.preventDefault()
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(publicKey)
        const encryptmobile = encrypt.encrypt(mobileNumber)
        const encryptemail = encrypt.encrypt(email)

        // setShow(2)
        // const isValid = handleValidations();

        // if (!isValid) {
        //     // If validations fail, do not proceed with sending the email
        //     return;
        // }
        setLoading(true);

        let d1 = {
            "authMethod": "MOBILE_OTP",

            "mobileOrAadhaar": "mobile",

            // "mobile": encryptmobile.replace(/\n/g, '').replace(/\r/g, '')
            "mobile": mobileNumber
        };
        let d2 = {
            "authMethod": "EMAIL_OTP",

            "mobileOrEmail": "email",

            "email": encryptemail.replace(/\n/g, '').replace(/\r/g, '')
        }

        let selectData = activeButton === "mobile" ? d1 : d2

        await axios.post(`${URL.abha}login/mobileEmail/auth/${primaryId}`, selectData, {
            headers: {
                'gatewayToken': 'medxperts',
                'Authorization': `Bearer ${token}`,
                'X-CSRF-TOKEN': `${csrftoken}`
            },
        }).then(res => {
            // console.log(res.data, "transaaaaaaaaaaa")
            setAlert(true);
            setAlertmessage('OTP sent to mobile number!');
            setAlertstatus('success');
            navigate("/loginmobile", {
                state: {
                    selectData: selectData
                }
            })
            let details = {
                transactionId: res.data.transactionId,
                mobile: mobileNumber,
            }
            dispatch({
                type: "LOGINMOBILE_SESSIONID",
                payload: details
            })
        }).catch(err => {
            if (err.response.status === 400) {
                setAlertmessage("No PHR address linked with the provided mobile number.");
                setAlertstatus('error');
            } else {
                const { alertmessage, alertstatus } = handleErrorResponse(err);
                setAlertmessage(alertmessage);
                setAlertstatus(alertstatus);
            }

        }).finally(() => { setLoading(false); });
    };
    const handleButtonClick = (value) => {
        setActiveButton(value)
    }


    return (
        <Box sx={{ p: 3, textAlign: "center" }}>
            <Box>
                {/* <Button variant='contained' sx={{ borderRadius: "5px 0px 0px 5px", boxShadow: 1, bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2", color: 'black', "&:hover": { bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2" }, width: '110px' }} disableElevation
                    onClick={() => handleButtonClick("mobile")}>Mobile OTP</Button>
                <Button variant='contained' sx={{ borderRadius: "0px 5px 5px 0px", boxShadow: 1, bgcolor: activeButton === "email" ? "white" : "#EAE2E2", color: 'black', "&:hover": { bgcolor: activeButton === "email" ? "white" : "#EAE2E2" }, width: '110px' }} disableElevation
                    onClick={() => handleButtonClick("email")}>Email OTP</Button> */}
                <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                    sx={{ mt: 2 }}
                >
                    <Button
                        sx={{
                            bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2",
                            color: "black",
                            "&:hover": {
                                bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2",
                            },
                        }}
                        disableElevation
                        onClick={() => handleButtonClick("mobile")}
                    >
                        {" "}
                        {t("mobileotp")}
                    </Button>
                    <Button
                        sx={{
                            bgcolor: activeButton === "email" ? "white" : "#EAE2E2",
                            color: "black",
                            "&:hover": {
                                bgcolor: activeButton === "email" ? "white" : "#EAE2E2",
                            },
                        }}
                        disableElevation
                        onClick={() => handleButtonClick("email")}
                    >
                        {" "}
                        {t("emailotp")}
                    </Button>
                </ButtonGroup>
            </Box>
            <Grid container sx={{ mt: 3 }}>
                <Grid item xs={12}>
                    {activeButton === "mobile" ? (
                        <AdornmentTextField
                            width={{ sm: "350px", md: '350px' }}
                            placeholder={t("mobilenum")}
                            position="start"
                            name="mobileNumber"
                            value={mobileNumber}
                            onChange={handleOnChangeMobile}
                        />
                    ) : (
                        <TextInputField
                            width={{ sm: "350px", md: '350px' }}
                            placeholder={t("emailadd")}
                            border="none"
                            name="email"
                            value={email}
                            onChange={handleOnChangeEmail}
                        />
                    )}
                    {
                        alertmessage && (
                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                        )
                    }
                </Grid>


                {/* {emailerrormsg && (
                <Typography
                    sx={{
                        color: "red",
                        mt: 1, fontSize: "14px"
                    }}
                >
                    {emailerrormsg}
                </Typography>)} */}
                {
                    !isValidEmail && <Typography
                        sx={{
                            color: "red",
                            mt: 1, fontSize: "14px"
                        }}
                    >
                        {'Invalid Email Address'}
                    </Typography>
                }
            </Grid>


            <Box sx={{ mt: 3 }}>
                <Button
                    variant="contained"
                    sx={{
                        bgcolor: "#9560D9",
                        width: "150px",
                        borderRadius: 2,
                        "&:hover": { bgcolor: "#9560D9" },
                    }}
                    onClick={handleSendMobileOtp}
                    disabled={
                        activeButton === "mobile"
                            ? loading || mobileNumber?.length < 10
                            : loading || !email
                    }
                    startIcon={
                        loading ? <CircularProgress size={20} color="inherit" /> : null
                    }
                >
                    {t("credentialsData.signupmobiledata.otpbtntxt")}
                </Button>
            </Box>
        </Box >
    );
}

export default MobileEmailLogin