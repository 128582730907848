import React, { useEffect } from 'react'
import { Box, Button, Card, Divider, Grid, IconButton, Typography } from '@mui/material';
import DoctorIcon from '../../../../assets/home/profile_assets/Userprofilelogo.svg';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import { Close } from '@mui/icons-material';
import { localstore } from '../../../../localStore/localStore';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorsFollowCount, getFollowdoctor } from '../../../../redux/Actions/Actions';
import { useState } from 'react';
import { useTranslation } from "react-i18next";
import { Outlet, useNavigate } from 'react-router-dom';

function MyFavourites() {

    const navigate = useNavigate()
    const [open, setOpen] = React.useState(false);
    const token = localstore.getToken()
    const [suggesteddata, setSuggesteddata] = useState([])
    const [loading, setLoading] = useState(false)
    const [t, i18n] = useTranslation("global");
    // console.log(suggesteddata, "eachhhhhhhhhh")
    const patientId = localstore.getPatientId()
    const dispatch = useDispatch()
    const [docId, setDocId] = useState()
    const offset = 0;
    const limit = 50;

    const doctorslist = useSelector((state) => state.medx.suggestedFollowDoctors)

    const handleClose = () => {
        setOpen(false);
    };
    useEffect(() => {
        if (doctorslist?.length > 0) {
            setSuggesteddata(doctorslist)
        }
    }, [doctorslist])


    useEffect(() => {
        if (patientId) {
            dispatch(getDoctorsFollowCount(patientId))
        }
        dispatch(getFollowdoctor(limit, offset, patientId))

    }, [patientId])

    const handleClickFollow = (docId) => {
        setOpen(true);
        setDocId(docId)

    }
    const handleUnfollowperson = () => {
        setLoading(true)
        if (token?.length > 0 && patientId !== 0 && docId !== 0) {
            axios.put(`${URL.unfollowDoctor}${patientId}/user/${docId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    // console.log(res, "unfollow")
                    setOpen(false)
                    let data = suggesteddata.filter((e) => {
                        if (e.id !== docId) {
                            return e;
                        }
                    });
                    setSuggesteddata(data);
                    dispatch(getDoctorsFollowCount(patientId));
                    // setTimeout(() => {
                    // window.location.reload();
                    // }, 1000);
                })
                .catch(err => { console.log(err.message); }).finally(() => {
                    setLoading(false)
                })

        }
    }
    const handleBack = () => {
        navigate({ pathname: '/medxperts', state: 'doctor' })

    }

    return (
        <>
            <Box sx={{
                mt: "10px", p: "7px 20px"
            }}>
                {/* {token ? */}
                <Box mt={2}>
                    {suggesteddata.length > 0 ? (
                        suggesteddata.map((each, index) => (
                            <Card sx={{ p: "12px", mt: "15px" }} key={index}>
                                <Grid container key={index} alignItems={"center"}>
                                    <Grid
                                        item
                                        xs={2}
                                        sm={2}
                                        md={1.5}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => navigate(`/doctor/${each.id}`)}
                                    >
                                        {each?.photos &&
                                            each?.photos[0] &&
                                            each?.photos[0].url !== null ? (
                                            <Box ml={2}>
                                                <img
                                                    src={each?.photos[0].url}
                                                    alt="user"
                                                    width={45}
                                                    height={45}
                                                    style={{ borderRadius: "5px" }}
                                                />
                                            </Box>
                                        ) : (
                                            <Box ml={2}>
                                                <img src={DoctorIcon} alt="user" />
                                            </Box>
                                        )}
                                    </Grid>
                                    <Grid
                                        item
                                        xs={6}
                                        sm={8}
                                        md={8}
                                        sx={{ cursor: "pointer" }}
                                        onClick={() => navigate(`/doctor/${each.id}`)}
                                    >
                                        <Typography>
                                            {each?.fullName?.length > 20 ? (
                                                <Typography sx={{ fontWeight: 600 }}>
                                                    {each?.fullName.slice(0, 19)}..
                                                </Typography>
                                            ) : (
                                                <Typography sx={{ fontWeight: 600 }}>
                                                    {each?.fullName}
                                                </Typography>
                                            )}
                                        </Typography>
                                        <Typography>
                                            {each?.captionAndTaglines?.length > 20 ? (
                                                <Typography
                                                    sx={{ fontSize: { xs: 12, sm: 14, md: 14 }, fontWeight: 300 }}
                                                >
                                                    {each?.captionAndTaglines.slice(0, 19)}..
                                                </Typography>
                                            ) : (
                                                <Typography sx={{ fontSize: { xs: 12, sm: 14, md: 14 }, fontWeight: 300 }}>{each?.captionAndTaglines}</Typography>
                                            )}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={4} sm={2} md={2.5}>
                                        <Button
                                            variant="contained"
                                            sx={{
                                                height: 28,
                                                bgcolor: "blueviolet",
                                                "&:hover": { bgcolor: "blueviolet" },
                                                borderRadius: 3,
                                            }}
                                            onClick={() => handleClickFollow(each?.id)}
                                        >
                                            {t("following")}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Card>
                        ))
                    ) : (
                        <Typography sx={{ textAlign: "center", fontWeight: 500, fontSize: "17px" }}>
                            {t("noonefollowed")}
                        </Typography>
                    )}
                </Box>
                {/* // : <Typography>There is no access please login</Typography>} */}
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {" "}
                            {t("unfollowbtn")}
                        </Typography>
                        <IconButton onClick={handleClose}>  <Close sx={{ color: 'red' }} /></IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogActions>
                        <Button onClick={handleClose} sx={{ color: "black" }} variant='outlined'>
                            {t("cancel")}
                        </Button>
                        <Button
                            sx={{
                                bgcolor: "blueviolet",
                                ":hover": {
                                    bgcolor: "blueviolet",
                                },
                            }}
                            onClick={handleUnfollowperson}
                            autoFocus
                            variant="contained"
                        >
                            {t("unfollow")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Box>

        </>
    );
}

export default MyFavourites