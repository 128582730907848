import React, { useState } from 'react'
import { ArrowBackIos, Close } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Card, Checkbox, CircularProgress, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import { useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next";
import UserIdStep2 from './UserIdStep2';
import URL from '../../../../utility/apiurl'
import { localstore } from '../../../../localStore/localStore'
import axios from 'axios'
import SelectVerification from '../ConnectAbhaFlows/SelectVerification'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'


function UserIdStep1() {
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");
    const [show, setShow] = useState(1)
    const [phradd, setPhradd] = useState('')
    const [phraddError, setPhraddError] = useState('') // State to manage the error message
    const [useHealthId, setUseHealthId] = useState(false); // State to manage checkbox state
    const [healthNumber, setHealthNumber] = useState('')
    const [activeButton, setActiveButton] = useState('mobile')
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [loading, setLoading] = useState(false)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()

    const handleOnChangeAbha = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setHealthNumber(inputValue.slice(0, 14));
        setAlertmessage('')
    }
    const dispatch = useDispatch()
    const handleCheckboxChange = () => {
        setUseHealthId(!useHealthId);
    }

    const handleOnChangeUser = (e) => {
        setPhradd(e.target.value)
        setPhraddError('')
    }

    const handleSend = () => {

        setLoading(true)

        if (phradd.length > 0) {
            setLoading(true)
            axios.get(`${URL.abha}login/search/authmodes/${primaryId}/${phradd}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    gatewayToken: 'medxperts',
                }
            }).then(res => {
                console.log(res, "authmethods")
                // setAlertMessage("")tok
                const updatedAuthMethods = res.data.authMethods.filter(method => method !== "AADHAAR_BIO" && method !== "DEMOGRAPHICS");

                dispatch({
                    type: "USERID_AUTH_METHODS",
                    payload: updatedAuthMethods
                })
                dispatch({
                    type: "USERID_PHRADDRESS",
                    payload: res.data.phrAddress
                })

                setShow(2)
            }).catch(err => {
                setAlertmessage(err.message)
                setAlertstatus("error")
            }).finally(() => {
                setLoading(false)
            })
        }

    };
    // const handleSend = (e) => {
    //     e.preventDefault()
    //     if (!phradd.trim()) {
    //         setPhraddError('username is required') // Set the error message
    //         return; // Prevent further execution
    //     }

    //     // If the field is not empty, clear the error message
    //     setPhraddError('')
    //     dispatch({
    //         type: "USER_PASSWORD_PHRADD",
    //         payload: `${phradd}@sbx`
    //     })
    //     setShow(2)
    // }

    const handleSendOtp = () => {
        setShow(2)
    }
    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '0px' }, mb: { xs: 10, sm: 10, md: 5 } }}>

            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: "5px", sm: "10px", md: "5px" } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 1 },
                            }}
                        >
                            <IconButton onClick={() => navigate("/loginabha")}>
                                <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: 15, sm: "16px", md: "17px" },
                                    fontWeight: 600, color: "#007DCD",
                                }}
                            >
                                {t("loginabha")}
                            </Typography>
                        </Box>
                    </Box>
                    <Paper elevation={3}
                        sx={{ p: 3, mt: 1, display: "flex", justifyContent: "center" }}
                    >
                        <Box>
                            <Typography sx={{ textAlign: "center", fontWeight: 600 }}>
                                {t("abhaadd")}
                            </Typography>
                            <Grid container sx={{ mt: 3 }}>
                                <Grid item xs={12}>
                                    {useHealthId ?
                                        (
                                            <TextField
                                                sx={{
                                                    ".MuiInputBase-root": {
                                                        borderRadius: 2,
                                                        backgroundColor: 'whitesmoke',
                                                        mt: 2,
                                                    },
                                                    ".MuiOutlinedInput-notchedOutline": {
                                                        border: 'none'
                                                    },
                                                    width: { sm: '350px', md: '350px' }
                                                }
                                                }
                                                placeholder={'ABHA  Number'}
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                value={healthNumber}
                                                onChange={handleOnChangeAbha}
                                                autoComplete='off'
                                                InputProps={{
                                                    endAdornment: <Typography variant="body1">@sbx</Typography>,
                                                }} />
                                        ) : (
                                            <TextField
                                                sx={{
                                                    ".MuiInputBase-root": {
                                                        borderRadius: 2,
                                                        backgroundColor: 'whitesmoke',
                                                        mt: 2,
                                                    },
                                                    ".MuiOutlinedInput-notchedOutline": {
                                                        border: 'none'
                                                    },
                                                    width: { sm: '350px', md: '350px' }
                                                }
                                                }
                                                placeholder={'ABHA Address'}
                                                size="small"
                                                fullWidth
                                                variant="outlined"
                                                value={phradd}
                                                onChange={
                                                    handleOnChangeUser}
                                                autoComplete='off'
                                                InputProps={{
                                                    endAdornment: <Typography variant="body1">@sbx</Typography>,
                                                }} />
                                        )}
                                </Grid>
                            </Grid>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                                <Checkbox
                                    checked={useHealthId}
                                    onChange={handleCheckboxChange}
                                />
                                <Typography sx={{ fontSize: '14px' }}>Login With Health Id Number of  @abdm</Typography>
                            </Box>
                            {phraddError && (
                                <Typography sx={{ color: "red", mt: 1, ml: 1 }}>
                                    {phraddError}
                                </Typography>
                            )}
                            <Box sx={{ mt: 5, textAlign: "center" }}>
                                {useHealthId ?
                                    <Box>
                                        <ButtonGroup
                                            variant="contained"
                                            aria-label="outlined primary button group"
                                            sx={{ mt: 1 }}
                                        >
                                            <Button
                                                sx={{
                                                    bgcolor:
                                                        activeButton === "aadhar" ? "white" : "#EAE2E2",
                                                    "&:hover": {
                                                        bgcolor:
                                                            activeButton === "aadhar" ? "white" : "#EAE2E2",
                                                    },
                                                    color: "black",
                                                }}
                                                disableElevation
                                                onClick={() => setActiveButton("aadhar")}
                                            >
                                                {t("aadharotp")}
                                            </Button>
                                            <Button
                                                sx={{
                                                    bgcolor:
                                                        activeButton === "mobile" ? "white" : "#EAE2E2",
                                                    "&:hover": {
                                                        bgcolor:
                                                            activeButton === "mobile" ? "white" : "#EAE2E2"
                                                    },
                                                    color: "black",
                                                }}
                                                disableElevation
                                                onClick={() => setActiveButton("mobile")}
                                            >
                                                {t("mobileotp")}
                                            </Button>
                                        </ButtonGroup>
                                        <Box sx={{ mt: 4, textAlign: "center", mb: 2 }}>
                                            <Button
                                                variant="contained"
                                                sx={{
                                                    borderRadius: 2,
                                                    "&:hover": {
                                                        bgcolor: "#1CB500",
                                                    },
                                                    bgcolor: "#1CB500",
                                                    width: "150px",
                                                }}
                                                onClick={handleSendOtp}
                                                disabled={loading || healthNumber.length < 14}
                                                startIcon={
                                                    loading ? (
                                                        <CircularProgress size={20} color="inherit" />
                                                    ) : null
                                                }
                                            >
                                                {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                                {t("credentialsData.signupmobiledata.otpbtntxt")}
                                            </Button>
                                        </Box>
                                    </Box> : <Button
                                        variant="contained"
                                        sx={{
                                            bgcolor: "#9560D9",
                                            width: "150px",
                                            borderRadius: 2,
                                            "&:hover": { bgcolor: "#9560D9" },
                                        }}
                                        disabled={!phradd}
                                        startIcon={
                                            loading ? (
                                                <CircularProgress size={20} color="inherit" />
                                            ) : null
                                        }
                                        onClick={handleSend}
                                    >
                                        {/* {t("mobileflow.confirm")} */}
                                        Confirm
                                    </Button>
                                }
                            </Box>
                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                // <UserIdStep2 setShow={setShow} useHealthId={useHealthId} phraddress={phradd} healthIdNumber={healthNumber} />
                <SelectVerification setShow={setShow} />
            ) : null
            }
        </Box >
    );
}

export default UserIdStep1