import React, { useEffect, useState } from 'react';
import { Cached, CancelOutlined, FindReplace, Room, StarRate, UploadFile } from '@mui/icons-material'
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Grid, IconButton, List, ListItem, ListItemText, MenuItem, TextField, Typography } from '@mui/material'
import SearchBar from '../../../atoms/Searchbar';
// import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { getDistricts, getStates } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { debounce } from 'lodash';
import linkIcon from '../../../../assets/home/records_module/link.svg';
import hospitalIcon from '../../../../assets/home/bookapp_assets/hospital.svg'
import searchIcon from '../../../../assets-new/services-icons/search-Icon-blue.svg'
import FetchRecords from './FetchRecords';
import UploadFiles from './UploadFiles';
// import FetchRecords1 from './FetchRecords1';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';


const NewRecords = ({ mobiledata }) => {
    const navigate = useNavigate();
    let states = useSelector(state => state.abha.states);
    let districts = useSelector(state => state.abha.districts);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const dispatch = useDispatch()
    const [file, setFile] = useState()
    const [stat, setStat] = useState('')
    const [district, setDistrict] = useState('')
    const [statecode, setStatecode] = useState('');
    const [districtcode, setDistrictcode] = useState('');
    const [searchvalue, setSearchvalue] = useState('')
    const [recordsdata, setRecordsdata] = useState([])
    const [list, setList] = useState('')
    const [loading, setLoading] = useState(false)
    const [stateopen, setStateopen] = useState(false)
    const [districtopen, setDistrictopen] = useState(false)
    const [show, setShow] = useState(1)
    const [open, setOpen] = React.useState(false);
    const [fileSize, setFileSize] = useState('')
    const [fileType, setFileType] = useState('')
    const [recordfiles, setRecordfiles] = useState([])
    const [t, i18n] = useTranslation("global");
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');

    const handleOpen = () => {
        setStateopen(!stateopen)
    }
    const handleFileChange = (e) => {
        const selectedFiles = e.target.files;
        const filesData = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            const file = selectedFiles[i];
            // console.log("File Name:", file.name);
            // console.log("File Type:", file.type);
            setFileType(file.type)

            // Check if the selected file type is allowed
            const selectedType = ["png", "pdf", "jpg", "jpeg"].includes(file.type.split("/")[1]);

            if (selectedType) {
                // File type is allowed
                setOpen(true);

                let readerObject = new FileReader();
                readerObject.readAsDataURL(file);

                readerObject.onloadend = () => {
                    let base64 = readerObject.result.split(',')[1];
                    console.log("Base64:", base64);

                    let base64StringLength = base64.length;
                    var fileLength = base64StringLength - (base64StringLength / 8) * 2;
                    let size = (fileLength / 1024 / 1024).toFixed(2);
                    setFileSize(size)
                    // console.log("File Size (MB):", size);
                    filesData.push({
                        fileType: file.type,
                        file: base64,
                    })
                    setRecordfiles(filesData)
                }
            } else {
                // File type is not allowed
                setOpen(false);
            }
        }
    }


    useEffect(() => {
        dispatch(getStates(token))

    }, []);


    const handlestateSelection = () => {
        setStateopen(true);
        setDistrictopen(true);
    }
    const handleState = (e) => {
        setStat(e.name);
        setStatecode(e.code);
        dispatch(getDistricts(e.code, token));
        setDistrict('');

    }

    const handledistrictSelection = () => {
        setStateopen(false)
        setDistrictopen(false)
    }
    const handleDistrict = (e) => {
        setDistrict(e.name);
        setDistrictcode(e.code);
    };


    const debouncedAxiosCall = debounce((data) => {
        axios.get(`${URL.consents}allProviders/${primaryId}/${data}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res, "searchapis")
            setRecordsdata(res.data)
        }).catch(err => {
            setAlertmessage(err.message)
            setAlertstatus('error')
            // console.log(err.message);
        })
            .finally(() => {
                setLoading(false)
            })

    }, 2000);

    useEffect(() => {

        // if (searchvalue || statecode || districtcode) {
        if (searchvalue) {
            setLoading(true); // Set loading to true when making the API request
            debouncedAxiosCall(searchvalue);
        }

        // Cancel API call when component unmounts
        return () => {
            debouncedAxiosCall.cancel();
        };
    }, [searchvalue]);

    const handleSharelink = (list) => {
        setList(list)
        setShow(2)
    }

    const filteredHospitalList = recordsdata.filter((item) =>
        item.identifier.name.toLowerCase().includes(searchvalue.toLowerCase())
    );



    const handleClosed = () => {
        console.log('close')
        navigate(-1)

    }

    return (
        <Box m={1}>
            <Box mb={{ xs: 10, mb: 10 }}>
                <Typography sx={{ mt: { xs: 0, sm: 1, md: "12px" }, fontSize: '17px', fontWeight: 600 }}> Add New Records</Typography>
                {show === 1 ? (
                    <CardContent sx={{ marginTop: { xs: 2, sm: 1, md: "10px" } }}>
                        <Grid
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                marginBottom: "10px",
                            }}
                        >
                            <Typography sx={{ fontWeight: 500, fontSize: "17px" }}>
                                {t("records.selecthospital")}
                            </Typography>

                            <Button
                                sx={{
                                    backgroundColor: "#003F89",
                                    color: "white",
                                    height: "32px",
                                    borderRadius: 4,
                                    "&:hover": { backgroundColor: "#003F89" },
                                }}
                                component="label"
                                variant="contained"
                                startIcon={<UploadFile />}
                            >
                                {t("records.uploadfiles")}
                                <input type="file" hidden onChange={handleFileChange} />
                            </Button>
                            {/* <Button component="label" variant="contained" startIcon={<UploadFile />}>
                            Upload file
                            <input hidden type="file" />
                        </Button> */}
                        </Grid>
                        <Grid

                        >
                            <SearchBar
                                placeholder={t("records.searchtxt")}
                                value={searchvalue}
                                icon={searchIcon}
                                onChange={(e) => {
                                    setSearchvalue(e.target.value);
                                    setRecordsdata([]);
                                    setLoading("");
                                    setAlertmessage('')
                                }}
                            />
                        </Grid>
                        <Grid
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                marginTop: "15px",
                                color: "#3B3BBC",
                                cursor: "pointer",
                            }}
                        >
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                <Room />
                                <Typography sx={{ mt: "2px" }} onClick={handleOpen}>
                                    {stat && district
                                        ? `${stat.toLowerCase()}, ${district.toLowerCase()}`
                                        : t("records.setlocation")}
                                </Typography>
                            </Box>
                            {stat.length > 0 || district.length > 0 ? (
                                <Box
                                    sx={{ display: "flex", alignItems: "center", mr: 2 }}
                                    onClick={() => {
                                        setStateopen(true);
                                        setDistrictopen(true);
                                    }}
                                >
                                    <Cached />
                                    <Typography>{t("records.change")}</Typography>
                                </Box>
                            ) : null}
                        </Grid>

                        {
                            stateopen && (
                                <Box onClick={handlestateSelection}>
                                    <TextField
                                        sx={{
                                            ".MuiInputBase-root": {
                                                borderRadius: 2,
                                                // backgroundColor: 'whitesmoke',
                                                mt: 2,
                                            },
                                            // ".MuiOutlinedInput-notchedOutline": {
                                            //     border: "none"
                                            // }
                                        }}
                                        placeholder="Relation"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => setStat(e.target.value)}
                                        value={stat}
                                        name={"stat"}
                                        autoFocus
                                        autoComplete="off"
                                        select
                                        SelectProps={{
                                            displayEmpty: true,
                                            renderValue: (selected) =>
                                                selected === "" ? t("state") : selected,
                                        }}
                                    >
                                        <MenuItem value="">{t("selstate")}</MenuItem>
                                        {states.map((state, i) => (
                                            <MenuItem
                                                key={i}
                                                value={state.name}
                                                onClick={() => handleState(state)}
                                            >
                                                {state.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            )
                        }

                        {
                            districtopen && (
                                <Box onClick={handledistrictSelection}>
                                    <TextField
                                        sx={{
                                            ".MuiInputBase-root": {
                                                borderRadius: 2,
                                                // backgroundColor: 'whitesmoke',
                                                mt: 2,
                                            },
                                            // ".MuiOutlinedInput-notchedOutline": {
                                            //     border: "none"
                                            // }
                                        }}
                                        placeholder="Relation"
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => setDistrict(e.target.value)}
                                        value={district}
                                        name={"district"}
                                        autoFocus
                                        autoComplete="off"
                                        select
                                        SelectProps={{
                                            displayEmpty: true,
                                            renderValue: (selected) =>
                                                selected === "" ? t("district") : selected,
                                        }}
                                    >
                                        <MenuItem value="">{t("seldis")}</MenuItem>
                                        {districts.map((district, i) => (
                                            <MenuItem
                                                key={i}
                                                value={district.name}
                                                onClick={() => handleDistrict(district)}
                                            >
                                                {district.name}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </Box>
                            )
                        }

                        {
                            loading ? (
                                <Box
                                    sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        mt: "100px",
                                    }}
                                >
                                    <Typography sx={{ color: "blue" }}>
                                        {t("fetchinghospitals")}
                                    </Typography>
                                </Box> // Centered loading indicator
                            ) : (
                                <List>
                                    <Typography sx={{ ml: "4px" }}>{t("searchresults")}</Typography>

                                    {filteredHospitalList.length > 0 ? (
                                        filteredHospitalList.map((list, index) => (
                                            <ListItem key={index}>
                                                <ListItemText>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                        }}
                                                    >
                                                        <Box>
                                                            <Box
                                                                sx={{
                                                                    display: "flex",
                                                                    alignItems: "center",
                                                                    gap: 2,
                                                                }}
                                                            >
                                                                <img
                                                                    src={hospitalIcon}
                                                                    alt="hospital"
                                                                    style={{
                                                                        width: 40,
                                                                        height: 40,
                                                                        background: "#D4F4F8",
                                                                        padding: 5,
                                                                        borderRadius: "5px",
                                                                    }}
                                                                />
                                                                <Box>
                                                                    <Typography sx={{ fontWeight: 600 }}>
                                                                        {" "}
                                                                        {list.identifier.name}
                                                                    </Typography>
                                                                    <Typography sx={{ fontSize: 14 }}>
                                                                        {t("address")}:{list?.address || "N/A"} ,
                                                                        {t("cityname")}:{list?.city || "N/A"},
                                                                        {t("pincode")}:{list?.pinCode || "N/A"}
                                                                    </Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        <Button
                                                            variant="contained"
                                                            sx={{
                                                                borderRadius: 4,
                                                                bgcolor: "#D4F4F8",
                                                                height: "28px",
                                                                width: "80px",
                                                                color: "#3A73B6",
                                                                "&:hover": { bgcolor: "#D4F4F8" },
                                                            }}
                                                            startIcon={<img src={linkIcon} alt="link" />}
                                                            onClick={() => handleSharelink(list)}
                                                        >
                                                            {t("link")}
                                                        </Button>
                                                    </Box>
                                                </ListItemText>
                                            </ListItem>
                                        ))
                                    ) : (
                                        <Typography
                                            sx={{ textAlign: "center", fontSize: "16px", mt: 10 }}
                                        >
                                            {t("nohospitalsfound")}
                                        </Typography>
                                    )}
                                </List>
                            )
                        }
                        {alertmessage && (
                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                        )}
                    </CardContent>
                ) : show === 2 ? (
                    <FetchRecords list={list} setShow={setShow} />
                ) : null}

                <UploadFiles
                    open={open}
                    setOpen={setOpen}
                    fileSize={fileSize}
                    fileType={fileType}
                    recordfiles={recordfiles}
                />
            </Box>
        </Box >
    );
}

export default NewRecords