
import React, { useState } from 'react'
import { ArrowBackIos, Close } from '@mui/icons-material'
import { Card, Grid, Typography, Box, Button, IconButton, CircularProgress, Paper, } from '@mui/material'
import { useHistory, useLocation, useNavigate } from 'react-router-dom'
import AadharIcon from '../../../../assets/home/services_assets/aadhar-logo.svg'
import TextInputField from '../../../atoms/TextFields//TextInputField'
import { useDispatch } from 'react-redux'
import { localstore } from '../../../../localStore/localStore'
import URL, { AadharPublicKey } from '../../../../utility/apiurl'
import axios from 'axios'

import { useTranslation } from "react-i18next";
import AadharStep2 from './AadharStep2'
import SelectPhrAddress from '../ConnectAbhaFlows/SelectPhrAddress'
import Registration from '../ConnectAbhaFlows/Registration'
import EditRegistration from '../ConnectAbhaFlows/EditRegistration'
import CreatePhrAddress from '../ConnectAbhaFlows/CreatePhrAddress'
import PhrAddressScreen from '../ConnectAbhaFlows/PhrAddressScreen'
import SelectVerification from '../ConnectAbhaFlows/SelectVerification'
import AadharStep3 from './AadharStep3'
import AadharStep4 from './AadharStep4'
import ValidateOtp from './ValidateOtp'
import JSEncrypt from 'jsencrypt'


function AadharStep1() {

    const navigate = useNavigate()
    const location = useLocation()
    const abhacreate = location?.state?.abhacreate
    // console.log(abhacreate, "abhacreate")
    const dispatch = useDispatch()
    const [aadhar, setAadhar] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(1)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const publicKey = AadharPublicKey
    const handleOnChangeAadhar = (e) => {
        const input = e.target.value;
        const formattedInput = input
            .replace(/[^0-9]/g, '')
            .slice(0, 12)
            .replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
        setAadhar(formattedInput);
        if (!/^\d+$/.test(e.target.value)) {
            setAlertmessage('Only numbers are allowed.');
        } else {
            setAlertmessage('');
        }
        // setAlertmessage('')
    };
    // console.log(typeof aadhar, "iiiiiii")
    const handleSendOtp = async () => {
        // setShow(2)
        setLoading(true)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(publicKey)
        let data = aadhar.replace(/ /g, '').replace(/-/g, '');
        const encryptAadharnum = encrypt.encrypt(data)
        console.log(data);
        let d = {
            aadhaar: encryptAadharnum.replace(/\n/g, '').replace(/\r/g, ''),
            mobileOrAadhaar: 'aadhaar',
        };
        await axios
            .post(`${URL.abha}v2/generateAadhaarOtp/${primaryId}`, d, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(res => {
                // console.log(res, "ahar1111111")
                if (res.status === 200 || res.status === 201) {
                    setAlertmessage('OTP sent to your registered mobile number!');
                    setAlertstatus('success');

                    dispatch({
                        type: "ABHAAADHAR",
                        payload: res.data
                    })
                    setShow(2)
                }
            })
            .catch(err => {
                if (err.response.status === 400) {
                    setAlertmessage(" Aadhaar Number is invalid or You have reached the maximum verify attempts");
                    setAlertstatus('error');
                } else {
                    setAlertmessage(err.message);
                    setAlertstatus('error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '44px' }, mb: { xs: 10, sm: 10, md: 5 } }}>

            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: "5px", sm: "10px", md: "15px" } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 2 },
                            }}
                        >
                            <IconButton onClick={() => navigate(-1)}><ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} /></IconButton>
                            <Typography
                                sx={{ fontSize: { xs: 15, sm: "16px", md: "16px" }, fontWeight: 600, ml: 1, color: '#007DCD' }}
                            >
                                {" "}
                                {t("creatingabhausingabha")}
                            </Typography>
                        </Box>
                    </Box>

                    <Paper
                        elevation={3}
                        sx={{
                            mt: 1,
                            boxShadow:
                                "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
                            p: 1,
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    alignItems: "center", // Center-align horizontally
                                    justifyContent: "center", // Center-align vertically
                                    minHeight: "100%", // Ensure full height of the card
                                    textAlign: "center", // Center-align text within the card
                                }}
                            >
                                <Box
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                        mt: 1.5,
                                        gap: 2,
                                    }}
                                >
                                    <img src={AadharIcon} alt="aadhar" />
                                    <Typography
                                        sx={{
                                            fontSize: { xs: 13, sm: "14px", md: "16px" },
                                            fontWeight: 500,
                                            mt: "6px",
                                        }}
                                    >
                                        {" "}
                                        {t("enteraadharcardnum")}
                                    </Typography>
                                </Box>

                                <Grid container mt={2}>
                                    <Grid item xs={12}>
                                        <TextInputField
                                            width={{ xs: '100%', sm: "350px", md: '350px' }}
                                            placeholder="XXXX - XXXX - XXXX"
                                            mt={2}
                                            border="none"
                                            name="aadhar"
                                            value={aadhar}
                                            onChange={handleOnChangeAadhar}
                                        />
                                    </Grid>
                                </Grid>
                                <Box mt={1} p={1.5}>
                                    <Typography
                                        component="p"
                                        sx={{
                                            fontSize: { xs: 11, sm: 12, md: "11px" },
                                            color: "black",
                                        }}
                                    >
                                        {t("enteraddtxt")}
                                    </Typography>
                                </Box>

                                {alertmessage && (
                                    <Typography
                                        sx={{
                                            color: alertstatus === "success" ? "green" : "red",
                                            mt: 1,
                                            fontSize: 13,
                                        }}
                                    >
                                        {alertmessage}
                                    </Typography>
                                )}
                                <Box sx={{ mt: 3, mb: 5 }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: 2,
                                            bgcolor: "#1CB500",
                                            "&:hover": {
                                                bgcolor: "#1CB500",
                                            },
                                            width: "150px",
                                        }}
                                        onClick={handleSendOtp}
                                        disabled={loading || aadhar?.length < 12}
                                        startIcon={
                                            loading ? (
                                                <CircularProgress size={20} color="inherit" />
                                            ) : null
                                        }
                                    >
                                        {t("sendotp")}
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                <AadharStep2 setShow={setShow} setAlertmessage={setAlertmessage} abhacreate={abhacreate} />
            ) : show === 3 ? (
                <SelectPhrAddress setShow={setShow} />
            ) : show === 4 ? (
                <Registration setShow={setShow} />
            ) : show === 5 ? (
                <EditRegistration setShow={setShow} />
            ) : show === 6 ? (
                <CreatePhrAddress setShow={setShow} />
            ) : show === 7 ? (
                <PhrAddressScreen setShow={setShow} />
            ) : show === 8 ? (
                <SelectVerification setShow={setShow} />
            ) : show === 11 ? (
                <AadharStep3 setShow={setShow} />
            ) : show === 12 ? (
                <AadharStep4 setShow={setShow} />
            ) : show === 13 ? (
                <ValidateOtp setShow={setShow} />
            ) : null}
        </Box>
    );
}

export default AadharStep1