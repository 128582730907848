
import React from 'react'
import { Box, Typography } from '@mui/material'
const TabItem = ({ icon, label, selected, onClick, fontSize, to }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 2,
                cursor: 'pointer',
                // backgroundColor: selected ? '#EFEFEF' : 'transparent',
                borderRadius: "5px",
                boxShadow: selected ? { xs: 'none', md: '0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)' } : 'none',
                p: "7px",
                width: selected ? '180px' : "100px"
            }}
            onClick={onClick}
        >
            <img src={icon} alt="live" width={20} height={20} />
            <Typography variant="h6" sx={{
                color: '#725F61', fontWeight: 600, fontSize: { md: 14, sm: 14, xs: 12 }, whiteSpace: 'nowrap', // Prevent wrapping to a new line
            }}>
                {label}
            </Typography>
        </Box>
    )
}


export default TabItem
