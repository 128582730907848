import React, { useState } from 'react';
import { Box, Typography, Chip, Button, IconButton, Card } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import searchblack from '../../../../assets-new/services-icons/search-Icon-black.svg';
import checkSymptoms from '../../../../assets-new/services-icons/checkSymptoms.svg';
import HospitalIcon from '../../../../assets-new/services-icons/hospitalicon.svg';
import SearchBar from '../../../atoms/Searchbar';
import { Close } from '@mui/icons-material';

const CheckSymptoms = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchedItems, setSearchedItems] = useState([]);

    const handleSearch = () => {
        if (searchTerm.trim() !== '') {
            setSearchedItems((prevItems) => [...prevItems, searchTerm]);
            setSearchTerm('');
        }
    };

    const handleRemoveItem = (index) => {
        setSearchedItems((prevItems) => prevItems.filter((_, i) => i !== index));
    };

    return (
        <Box sx={{ m: '0px 20px' }}>
            <Box display="flex" alignItems="center" gap={1}>
                <img src={checkSymptoms} alt="symptoms" />
                <Typography variant="h6" sx={{ fontSize: '17px' }}>Check Symptoms</Typography>
            </Box>
            <Typography component="p" sx={{ fontSize: '11px', fontWeight: 400, mt: 1 }}>
                Find the problem and Right speciality based on your Symptoms
            </Typography>
            <Box mt={1} display="flex" alignItems="center" gap={1}>
                <SearchBar
                    value={searchTerm}
                    width="260px"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder="Search Symptoms here"
                    height="35px"
                    icon={searchblack}
                    // Handle the key down event here
                    onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                            handleSearch();
                        }
                    }}
                />
            </Box>
            <Box sx={{ mt: 1, display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }} >
                {searchedItems.map((item, index) => (
                    <Chip sx={{ bgcolor: '#F4F2FF' }}
                        key={index}
                        label={<Typography sx={{ fontSize: '14px', fontWeight: 600 }} >{item}</Typography>}
                        onDelete={() => handleRemoveItem(index)}
                        deleteIcon={<IconButton><Close sx={{ color: '#FF0000 !important', fontSize: '14px !important', mt: "3px" }} /></IconButton>}
                    />
                ))}
            </Box>

            <Box sx={{ mt: 3, textAlign: 'center' }}>
                <Button variant='contained' sx={{ bgcolor: '#025CEC', borderRadius: "10px" }}>Advanced Search</Button>
            </Box>

            <Card sx={{ mt: 5, p: 1 }}>
                <Typography>Facilities Near you </Typography>
                <Box sx={{ display: 'flex', gap: 1, mt: 1 }}>
                    <img src={HospitalIcon} alt="hospital" />
                    <Box >
                        <Typography sx={{ fontSize: '13px', fontWeight: 600 }}>Hospital Organisation Name</Typography>
                        <Typography sx={{ fontSize: '13px', fontWeight: 400 }}>Captions and taglines</Typography>
                        <Typography sx={{ fontSize: '12px', fontWeight: 500 }} >Address Line 1,District,Sub-district,city,pin Code</Typography>
                    </Box>
                </Box>
                <Box sx={{ textAlign: 'center', mt: 2, }}>
                    <Button variant='contained' sx={{ bgcolor: '#D4F4F8', color: '#0748A0', height: '25px', borderRadius: 6 }}>More</Button>
                </Box>
            </Card >
        </Box >
    );
};

export default CheckSymptoms;
