import React, { useEffect, useState } from 'react'
import { Box, Card, CardContent, Grid, IconButton, Typography, CardActions, Avatar } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import { localstore } from '../../../../../../localStore/localStore';
import { getpostbyid } from '../../../../../../redux/Actions/Actions';
import medxpertsIcon from '../../../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import { Close } from '@mui/icons-material';
import Celebrate from '../PostTypes/Celebrate';
import PdfView from '../PostTypes/PdfFile';
import Video from '../PostTypes/Video';
import Media from '../PostTypes/Media';
import Event from '../PostTypes/Event';
import PollPost from '../PostTypes/PollPost';
import ArticleView from '../PostTypes/ArticleView';
import Postactions from '../Postactions';
import AskDoctor from '../Commentactions/AskDoctor'
import moment from 'moment';
import Text from '../PostTypes/Text';
function Commentsmain(route) {
    const location = useLocation()
    const data = location?.state?.data
    const [skel, setSkel] = useState(true);
    const [readmore, setReadmore] = useState(false);
    const token = localstore.getToken()
    const [expanded, setExpanded] = useState(false);
    const navigate = useNavigate()
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    const dispatch = useDispatch()
    useEffect(() => {
        if (token?.length > 0 && data.id) {
            setSkel(true);
            dispatch(getpostbyid(data.id, token));
        }
        return console.log('cleanup function in useEffect');
    }, [route, token]);

    let item = useSelector((state) => state.medx.postdata);

    useEffect(() => {
        if (item?.pageData || item?.userData) {
            setSkel(false);
        }
    }, [item]); console.log(location, "itemsss")

    function timeSince(date) {
        var ms = new Date().getTime() - new Date(date).getTime();
        var seconds = Math.floor(ms / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);
        var months = Math.floor(days / 30);
        var years = Math.floor(months / 12);
        if (seconds < 1) {
            return 'Just now';
        }
        if (seconds < 60) {
            return seconds + 's';
        }
        if (minutes < 60) {
            return minutes + 'm';
        }
        if (hours < 24) {
            return hours + 'h';
        }
        if (days < 30) {
            return days + 'd';
        }
        if (months < 12) {
            return months + ' month';
        } else {
            return years + 'y';
        }
    }

    const handleNavigate = (item) => {
        if (item.pageId == 0) {
            navigate(`/doctor/${item.userId}`);
        } else {
            navigate(`/org/${item.pageId}`);
        }
    }

    return (
        <Box sx={{ mt: 3, p: '0px 20px' }}>
            <Card
                sx={{
                    backgroundColor: "white",
                    p: "15px 0px",
                    border: "1px solid #e5e5dc",
                    borderRadius: " 10px",
                    pb: "2px",
                }}
            >
                <Grid container >
                    <Grid item md={0.2} xs={0.2} sm={0.2}></Grid>
                    <Grid item md={1.5} xs={1.5} sm={1.5}>
                        {item?.pageData?.profilePic?.length > 0 ||
                            item?.userData?.profilePic?.length > 0 ? (
                            <Avatar
                                src={
                                    item?.pageData?.profilePic
                                        ? item?.pageData?.profilePic
                                        : item?.userData?.profilePic
                                }
                                sx={{
                                    borderRadius: "7px!important",
                                    border: "1px solid lightgrey",
                                    marginLeft: "10px !important",
                                    width: { sm: 55, md: 60 },
                                    height: { sm: 55, md: 60 },
                                    p: "5px",
                                }}
                            />
                        ) : (
                            <Avatar sx={{
                                borderRadius: "7px!important",
                                // border: "1px solid lightgrey",
                                marginLeft: "10px !important",
                                width: { sm: 55, md: 65 },
                                height: { sm: 55, md: 65 },
                            }} src={medxpertsIcon} />
                        )}

                    </Grid>
                    <Grid item md={9} xs={9} sm={9}>
                        <div>
                            {item?.pageData?.fullName?.length > 0 ||
                                item?.userData?.fullName?.length > 0 ? (
                                <Typography onClick={() => handleNavigate(item)}
                                    sx={{
                                        fontSize: { xs: 12, sm: 15, md: 14 },
                                        fontWeight: 600, cursor: 'pointer'
                                    }}
                                >
                                    {item?.pageData?.fullName || item?.userData?.fullName}{" "}
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
                            )}

                            {item?.pageData?.caption?.length > 0 ||
                                item?.userData?.caption?.length > 0 ? (
                                <Typography
                                    sx={{
                                        fontStyle: "italic",
                                        fontSize: { xs: 11, sm: 12, md: 13 },
                                    }}
                                >
                                    {item?.pageData?.caption || item?.userData?.caption}
                                </Typography>
                            ) : null}

                            <Typography color="black">
                                <Typography
                                    sx={{ fontSize: { xs: 11, sm: 12, md: 13 } }}
                                >{`${timeSince(
                                    moment.utc(item?.createDate).local()
                                )}`}</Typography>
                                &nbsp;
                            </Typography>

                        </div>
                    </Grid>
                    <Grid item md={1.3}><IconButton onClick={() => navigate('/livehealth')}><Close sx={{ color: 'red' }} /></IconButton></Grid>
                </Grid>


                <CardContent>
                    <Box sx={{ width: { xs: "450px", sm: "700px", md: "100%" } }}>

                        {
                            item.postType === 'TEXT' ? (
                                <Text docFeed={item} />
                            ) : item.postType === 'ARTICLE' ? (
                                <ArticleView docFeed={item} />
                            ) : item.postType === 'CELEBRATE' ? (
                                <Celebrate docFeed={item} />
                            ) : item.postType === 'FILE' ? (
                                <PdfView docFeed={item} stateview={true} />
                            ) : item.postType === 'VIDEO' ? (
                                <Video docFeed={item} />
                            ) : item.postType === 'IMAGE' ? (
                                <Media docFeed={item} />
                            ) : item.postType === 'EVENT' ? (
                                <Event docFeed={item} />
                                // ) : item.postType === 'POLL' ? (
                                //     <PollPost docFeed={item} />
                            ) : null
                        }
                    </Box>

                    {/* <ExpandMoreIcon /> */}
                </CardContent>



                <Postactions item={item} />

            </Card>
            {skel ? null : <AskDoctor item={item} />}

        </Box>
    )
}

export default Commentsmain