import { configureStore } from "@reduxjs/toolkit";
// import demoReducer from "./features/demo/demoSlice";
import medxReducer from "../Reducers/reducer";
import abhaReducers from "../Reducers/abhaReducers";
import consentReducers from "../Reducers/consentReducers";
import recordsReducer from "../Reducers/recordsReducers";
import languageReducer from "../Reducers/languageReducer";
import signupReducer from "../Reducers/signupReducers";

export default configureStore({
        reducer: {
                // demo: demoReducer,
                medx: medxReducer,
                abha: abhaReducers,
                consent: consentReducers,
                records: recordsReducer,
                language: languageReducer,
                signup: signupReducer

        }
})