import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Avatar, Box, Card, Divider, Grid, Typography } from '@mui/material';
import DoctorIcon from '../../../../../assets/home/profile_assets/Userprofilelogo.svg';

const DateText = (props) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
            <Typography sx={{ fontWeight: 'bold' }}>
                {props.date}        </Typography>
            <Typography sx={{ color: '#5C9EDF', fontSize: { xs: 12, sm: 13, md: 14 } }}>
                {props.showmore}        </Typography>
        </Box>)
}
const TimeTextBtn = (props) => {
    return (
        <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between', }}>
            <Button variant='contained'><Typography sx={{ fontSize: { xs: 10, sm: 13, md: 16 } }}>{"11:00 AM"}</Typography></Button>
            <Button variant='contained'><Typography sx={{ fontSize: { xs: 10, sm: 13, md: 16 } }}>{"11:30 AM"}</Typography></Button>
            <Button variant='contained'><Typography sx={{ fontSize: { xs: 10, sm: 13, md: 16 } }}>{"12:00 PM "}</Typography></Button>
            <Button variant='contained'><Typography sx={{ fontSize: { xs: 10, sm: 13, md: 16 } }}>{"12:30 PM"}</Typography></Button>
        </Box>)
}
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BookAppointment({ open, handleClose, user }) {
    // console.log("user", user)
    return (
        <div>

            <Dialog
                maxWidth="sm"
                fullWidth
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>

                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <Box>
                            {user?.photos && user?.photos[0]?.url ? (
                                <Avatar src={user?.photos[0]?.url} sx={{ objectFit: 'cover' }} />
                            ) : (
                                <Avatar src={DoctorIcon} />
                            )}
                        </Box>
                        {user?.fullName?.length > 16 ?

                            <Typography sx={{ fontWeight: 'bold', fontSize: { sm: "16px", md: '16px' } }}>
                                {user?.fullName?.slice(0, 15)}..,
                            </Typography> :
                            <Typography sx={{ fontWeight: 'bold', fontSize: { sm: "16px", md: '16px' } }}>
                                {user?.fullName},
                            </Typography>}

                        {user?.profession?.length > 20 ?

                            <Typography sx={{ fontWeight: 'bold', fontSize: { sm: "16px", md: '16px' } }} component="span">
                                {user?.profession?.slice(0, 15)}..,

                            </Typography>
                            :
                            <Typography sx={{ fontWeight: 'bold', fontSize: { sm: "16px", md: '16px' } }} component="span">
                                {user?.profession}
                            </Typography>}

                    </Box>
                    <Box ml={7} mt={"-16px"}>
                        {user?.captionAndTaglines?.length > 0 ?
                            <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }}>
                                {user?.captionAndTaglines}
                            </Typography> : <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }}>N/A</Typography>
                        }

                    </Box>
                    <Box mt={2} >
                        <Typography sx={{ fontWeight: 'bold', fontSize: { xs: '13px', sm: 15, md: 18 } }}>
                            Select a Time
                        </Typography>
                        <Typography sx={{ fontSize: { xs: '12px', sm: 13, md: 13 } }}>
                            Make an Appointment
                        </Typography>
                    </Box>
                </DialogTitle>
                <DialogContent >

                    <Card sx={{
                        zIndex: 1,
                        position: 'sticky',
                        top: { xs: '0px', sm: '0px', md: '0px' },
                        // mt: { xs: '-5px', sm: "-10px", md: '-20px' },
                        p: 1, mt: 1, bgcolor: '#F5F5F5', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)',
                    }}>
                        <Typography sx={{ fontSize: { xs: '17px', sm: 17, md: 17 } }}>CHOOSE A TIME</Typography>
                    </Card>

                    <Box>
                        <DateText date="Today,June 28" showmore="Show More" />
                        <TimeTextBtn />
                        <Typography sx={{ fontSize: { xs: '12px', sm: 14, md: 14 }, mt: 1 }}>
                            "Event name here" is schedued for 11:00 AM - 12:30 PM
                        </Typography>
                        <Typography sx={{ mt: 1, fontSize: { xs: '12px', sm: 14, md: 14 }, color: "#5C9EDF" }}>
                            View in Calendar
                        </Typography>
                    </Box>
                    <Divider sx={{ mt: 1 }} />

                    <Box>
                        <DateText date="Wednesday,June 29" showmore="Show More" />
                        <TimeTextBtn />

                    </Box>
                    <Divider sx={{ mt: 1 }} />

                    <Box>
                        <DateText date="Thursday,June 30" showmore="Show More" />
                        <TimeTextBtn />
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                    <Box>
                        <DateText date="Friday,July 1" showmore="Show More" />
                        <TimeTextBtn />
                    </Box>
                    <Divider sx={{ mt: 1 }} />
                    <Box>
                        <DateText date="Saturday,July 1" showmore="Show More" />
                        <TimeTextBtn />
                    </Box>
                    <Divider sx={{ mt: 1 }} />

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Disagree</Button>
                    <Button onClick={handleClose}>Agree</Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}