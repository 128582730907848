const initialState = {
    profiledata: '',
    registerPageMobileNumber: '',
    countrycode: '',
    allData: {},
    credentials: '',
    gettoken: '',

};

const signupReducer = (state = initialState, action) => {
    // console.log(action,">>>>>>>>>>>>>");
    switch (action.type) {

        case 'ALLDATA':
            return {
                ...state,
                allData: action.payload,
            };
        case 'USERNAME_PASSWORD':
            return {
                ...state,
                credentials: action.payload,
            };
        case 'USER_CREDENTIALS':
            return {
                ...state,
                credentials: action.payload,
            };

        case "COMPLETE_PROFILE_DATA":
            return {
                ...state,
                profiledata: action.payload
            };

        case 'REGISTERMOBILE':
            return {
                ...state,
                registerPageMobileNumber: action.payload,
            };
        case 'COUNTRYCODE':
            return {
                ...state,
                countrycode: action.payload,
            };
        case 'GET_TOKEN':
            return {
                ...state,
                gettoken: action.payload,
            };
        default:
            return state;
    }
};

export default signupReducer;
