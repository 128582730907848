const initialstate = {
    providerdata: [],
    favouritedata: [],
    linkedproviders: [],
    medicalrecords: [],
    Recordlist: [],
    RecordDetails: [],
    FhirBundle: [],
    SingleRecord: [],
    RecordOrganization: [],

};
const recordsReducer = (state = initialstate, action) => {
    switch (action.type) {
        case 'PROVIDERDATA':
            return {
                ...state,
                providerdata: action.payload,
            };
        case 'FAVOURITESDATA':
            return {
                ...state,
                favouritedata: action.payload,
            };
        case 'LINKEDPROVIDERS':
            return {
                ...state,
                linkedproviders: action.payload,
            };
        case 'MEDICALRECORDS':
            return {
                ...state,
                medicalrecords: action.payload,
            };

        case 'RECORDLIST':
            return {
                ...state,
                Recordlist: action.payload,
            };
        case 'RECORDETAILS':
            return {
                ...state,
                RecordDetails: action.payload,
            };
        case 'SINGLERECORD':
            return {
                ...state,
                SingleRecord: action.payload,
            };
        case 'FHIRBUNDLE':
            return {
                ...state,
                FhirBundle: action.payload,
            };
        case 'RECORDLIST_AND_ORGANIZATION':
            // console.log('-----------------RECORDLIST23', action.payload);
            return {
                ...state,
                Recordlist: action.payload,
            };
        default: return state;
    }

};

export default recordsReducer;