
import React, { useEffect } from 'react'
import { CelebrationOutlined, Security } from '@mui/icons-material'
import { Box, Button, Card, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import { getAbhaMobile, getAbhaProfile } from '../../../../redux/Actions/Actions'
import { localstore } from '../../../../localStore/localStore'
import { useDispatch } from 'react-redux'

function CreatePinSuccess(props) {
    const { handleClose, setPin, setShow } = props
    const primaryId = localstore.getPrimaryId()
    const token = localstore.getToken()
    const dispatch = useDispatch()
    const handleCloseDone = () => {
        setPin(['', '', '', ''])
        dispatch(getAbhaMobile(primaryId, token))
        handleClose()
        props.setAlertMessage('')
        setShow(1)
    }

    return (
        <div> <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                    <Security />
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Set Security PIN</Typography>
                </Box>
                {/* <Button sx={{ height: "25px", color: '#B48FE4', borderRadius: "15px" }} variant='outlined' onClick={handleCloseDone}>Done</Button> */}
            </DialogTitle>
            <Divider />

            <DialogContent >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, justifyContent: 'center' }}>
                    <CelebrationOutlined sx={{ color: '#9560D9' }} />
                    <Typography sx={{ fontWeight: 600 }}>CONGRATULATIONS</Typography>
                </Box>
                <Box sx={{ textAlign: 'center' }}>
                    <Typography component="p" sx={{ mt: 2, color: 'black', fontSize: 14 }}>Your Consent Pin Has Been Set Successfully </Typography>
                    <Typography component="p" sx={{ mt: 4, fontSize: 13 }}>Remember this 4 Digit-pin to secure your transactions</Typography>
                </Box>
                <Box mt={7} sx={{ textAlign: 'center' }}>
                    <Button sx={{ bgcolor: '#9560D9', width: '120px', borderRadius: "10px" }} onClick={handleCloseDone} variant='contained'>Done</Button>
                </Box>

            </DialogContent>
        </></div>
    )
}

export default CreatePinSuccess