import React from 'react'
import { Box, Card, Grid, Paper, Typography } from '@mui/material'
import { Vaccines } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import consultationicon from '../../../../assets-new/carekit-icons/stethoscope.svg'
import medicationicon from '../../../../assets-new/carekit-icons/medicines.svg'
import allergyicon from '../../../../assets-new/carekit-icons/allergies.svg'
import vaccinationicon from '../../../../assets-new/carekit-icons/syringe-vaccine.svg'
import surgeriesicon from '../../../../assets-new/carekit-icons/surgical_sterilization.svg'
import familyhistoryicon from '../../../../assets-new/carekit-icons/ui_folder_family.svg'
import vitalicon from '../../../../assets-new/carekit-icons/heart_cardiogram.svg'
import socialhabiticon from '../../../../assets-new/carekit-icons/socialhabits.svg'
import medicaldevicesicon from '../../../../assets-new/carekit-icons/pacemaker_FILL0_wght400_GRAD0_opsz24.svg'
import sharewellnessicon from '../../../../assets-new/carekit-icons/upload_FILL0_wght400_GRAD0_opsz24.svg'
import diagnosticicon from '../../../../assets-new/carekit-icons/diagnostic.svg'
import clinicalicon from '../../../../assets-new/carekit-icons/clincalgoals.svg'

const CareCard = (props) => {
    return (

        <Box
            sx={{
                // display: 'flex',
                // flexWrap: 'wrap',
                '& > :not(style)': {
                    // m: 1,
                    // width: 360,
                    height: { xs: 250, sm: 200, md: 200 },
                },
                cursor: 'pointer'
            }}
            onClick={props.onClick}
        >
            <Paper elevation={3} sx={{ p: 2.5, borderRadius: '5px' }}>
                <Box sx={{ textAlign: 'center' }}>
                    <img src={props.icon} alt="healthicons" width={props.width} height={props.height} />
                    <Typography sx={{ mt: 1, fontWeight: 500, fontSize: { xs: 15, sm: 17, md: 17 } }}>{props.title} </Typography>
                    <Typography component={'p'} sx={{ mt: 2, fontSize: { xs: 13, sm: 16, md: 16 } }}> {props.content}</Typography>
                </Box>
            </Paper>
        </Box>
    )
}

function CareTimeline() {
    const navigate = useNavigate()
    return (
        <Box sx={{ m: { xs: 1, sm: 1, md: 1, } }} >
            <Grid container columnSpacing={3} sx={{ mt: { xs: '20px', sm: '20px', md: '10px' } }}>
                <Grid item xs={6} sm={6} md={6} >
                    <Box
                        sx={{
                            // display: 'flex',
                            // flexWrap: 'wrap',
                            '& > :not(style)': {
                                // m: 1,
                                // width: 360,
                                height: { xs: 160, sm: 120, md: 120 },
                            },
                        }}
                    >
                        <Paper elevation={3} sx={{ p: { xs: "15px 10px", md: '15px 25px' }, cursor: 'pointer', borderRadius: '5px' }} onClick={() => navigate('/carechart/consulttimeline')} >
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <img src={consultationicon} alt="stethascope" width={35} height={35} />
                                <Typography sx={{ fontWeight: 600, fontSize: { xs: '14px', sm: '17px', md: '17px' } }}>Consultations Timeline</Typography>
                            </Box>
                            <Typography sx={{ fontSize: { xs: 13, sm: '14px', md: '14px', }, mt: '5px' }} component={'p'}> Review your encounters with doctors & reasons for it </Typography>
                        </Paper>
                    </Box>
                    <Box
                        sx={{
                            // display: 'flex',
                            // flexWrap: 'wrap',
                            '& > :not(style)': {
                                // m: 1,
                                // width: 360,
                                mt: 2,
                                height: { xs: 160, sm: 120, md: 120 },
                            },
                        }}
                    >
                        <Paper elevation={3} sx={{ p: { xs: "15px 10px", md: '15px 25px' }, cursor: 'pointer', borderRadius: '5px' }} onClick={() => navigate('/carechart/clinicalgoal')}>
                            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                <img src={clinicalicon} alt="clinical" width={35} height={35} />
                                <Typography sx={{ fontWeight: 500, fontSize: '17px' }}>Clinical Goals</Typography>
                            </Box>
                            <Typography sx={{ mt: 1, fontSize: '14px' }}>  Track & Report outcomes to Goals set by your doctor </Typography>
                        </Paper>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={6} md={6} >
                    <Box
                        sx={{
                            // display: 'flex',
                            // flexWrap: 'wrap',
                            '& > :not(style)': {
                                // m: 1,
                                // width: 360,
                                height: { xs: 336, sm: 256, md: 256 },
                            },
                            cursor: 'pointer'
                        }}
                        onClick={() => navigate('/carechart/medication')}
                    >
                        <Paper elevation={3} sx={{ p: 2.9, borderRadius: '5px' }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <img src={medicationicon} alt="medication" />
                                <Typography sx={{ mt: 2, fontWeight: 500, fontSize: 17 }}>Medication use Timeline</Typography>
                                <Typography component={'p'} sx={{ mt: 2, fontSize: 14 }}> Record medication you use,track your medication compliance & never miss a prescribed medication </Typography>
                            </Box>
                        </Paper>
                    </Box>
                </Grid>
            </Grid>

            <Paper elevation={3} sx={{ mt: 3, p: 2.5, cursor: 'pointer', borderRadius: '5px' }} onClick={() => navigate('/carechart/diagnosticreports ')} >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <img src={diagnosticicon} alt="diagnostic" />
                    <Box>
                        <Typography sx={{ fontWeight: 500, fontSize: 17 }}>Diagnostic Reports & Lab Results</Typography>
                        <Typography component={'p'} sx={{ fontSize: 14 }}> Review your lab reports compare, analyse and understand them better to track you progress </Typography>
                    </Box>
                </Box>
            </Paper>

            <Grid container columnSpacing={3} mt={3} >
                <Grid item xs={6} sm={6} md={6}>
                    <CareCard title="Allergy Intolerance" content="Record allergic reaction to understand the cause & effect" icon={allergyicon} />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <CareCard title="Vaccination Timeline" content="Get Recommended immunisation trace your vaccination events" icon={vaccinationicon} />
                </Grid>
            </Grid>


            <Paper elevation={3} sx={{ mt: 3, p: 2.5, cursor: 'pointer', borderRadius: '5px' }} >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <img src={vitalicon} alt="vitalicon" />
                    <Box>
                        <Typography sx={{ fontWeight: 500, fontSize: 17 }}>Vitals signs & Body Stats</Typography>
                        <Typography component={'p'} sx={{ fontSize: 14 }}> Review your lab reports compare, analyse and understand them better to track you progress </Typography>
                    </Box>
                </Box>
            </Paper>
            <Grid container columnSpacing={3} mt={3} >
                <Grid item xs={6} sm={6} md={6}>
                    <CareCard title="Family History" content="Family history tells more about you risks & help doctors take preventive care" icon={familyhistoryicon} />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <CareCard title="Social Habits" content="Record,track and keep in check from you habits being turned to addictions" icon={socialhabiticon} width={30} height={47} />
                </Grid>
            </Grid>
            <Grid container columnSpacing={3} mt={3}>
                <Grid item xs={6} sm={6} md={6}>
                    <CareCard title="Surgerys & procedure" content="Keep a note you past surgeries and Procedures" icon={surgeriesicon} />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <CareCard title="Medical Devices" content="Keep track of your medical implants and Devices,connect & monitor" icon={medicaldevicesicon} width={40} height={40} />
                </Grid>
            </Grid>
            <Paper elevation={3} sx={{ mt: 3, p: 2.5, borderRadius: '5px', mb: { xs: 10, sm: 10, md: 0 }, cursor: 'pointer' }} >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    <img src={sharewellnessicon} alt="sharewell" width={40} height={40} />
                    <Box>
                        <Typography sx={{ fontWeight: 500, fontSize: 17 }}>Share Wellness Record</Typography>
                        <Typography component={'p'} sx={{ fontSize: 14 }}> With Care 360 create you can generate your wellness record and share them your doctor. Choose what must be included & excluded </Typography>
                    </Box>
                </Box>
            </Paper>
        </Box >
    )
}

export default CareTimeline