import React from 'react'
import Logo from '../../assets/home/medxpertspassion.svg'
import { AppBar, Grid } from '@mui/material'
import NavButton from './NavButton'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

function LoginHeader() {
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");
    return (
        <div>
            <AppBar sx={{ bgcolor: 'white', p: '10px 10px', }}>
                <Grid container sx={{ mt: 1 }}>
                    <Grid item xs={6}>
                        <div className="desk-nav" onClick={() => navigate('/')}>
                            <img src={Logo} alt="logo" width={150} style={{ objectFit: 'fill', cursor: 'pointer' }} />
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ position: "relative" }}>
                            <div className="subtext">
                                {t("navbar.login")}
                            </div>
                            <NavButton
                                variant="outlined"
                                text={t("navbar.ehealthservices")}
                                style={{ width: "100%" }}
                                onClick={() => navigate('/login')}
                            />
                        </div>
                    </Grid>
                </Grid>
            </AppBar>
        </div>
    )
}

export default LoginHeader