import { useEffect, useState } from 'react';
import { Box, Divider, IconButton, Paper, Typography } from '@mui/material';
import { ArrowForwardIos, LocationOn, NotificationsOutlined, Settings, VaccinesOutlined } from '@mui/icons-material';
import Monitoring from './Monitoring';

function CareDashboard() {
    return (
        <Box>
            <Box sx={{ m: 1, mb: { xs: 10, sm: 10, md: 2 } }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <Box sx={{ display: 'flex', gap: 1, }}>
                        <NotificationsOutlined />
                        <Typography sx={{ fontWeight: 500 }}>Recommended - To Do</Typography>
                    </Box>
                    <Typography sx={{ fontSize: '14px', bgcolor: 'white', p: '2px', borderRadius: '5px' }}>Show All</Typography>
                </Box>
                <Paper elevation={2} sx={{ p: '10px 20px', mt: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#9560D9' }}><VaccinesOutlined />Vaccination</Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Mar 2024</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                        <Box>
                            <Typography sx={{ fontWeight: 600 }}>DTaP Vaccine Recommended</Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: '5px', bgcolor: '#F1FAFF', fontSize: '14px' }}><LocationOn sx={{ color: '#9560D9' }} />Paramitha Children's Hospital,Kompally</Typography>
                        </Box>
                        <IconButton sx={{ bgcolor: '#F5F6FD' }}><ArrowForwardIos /></IconButton>
                    </Box>
                </Paper>
                <Paper elevation={2} sx={{ p: '10px 20px', mt: 2 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#9560D9' }}><VaccinesOutlined />Followup Appointment</Typography>
                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>21-Jan-2023</Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                        <Box>
                            <Typography sx={{ fontWeight: 600 }}>Dr Venkat Reddy K, Neonatologist</Typography>
                            <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: '5px', fontSize: '14px', bgcolor: '#F1FAFF' }}><LocationOn sx={{ color: '#9560D9' }} />Paramitha Children's Hospital,Kompally</Typography>
                        </Box>
                        <IconButton sx={{ bgcolor: '#F5F6FD' }}><ArrowForwardIos /></IconButton>
                    </Box>
                </Paper >

                <Monitoring />
            </Box>
        </Box >

    )
}

export default CareDashboard;