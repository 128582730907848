import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { createTheme } from '@mui/material/styles';
import { Public, Visibility, VisibilityOff } from '@mui/icons-material';
import CommonDesign from '../../atoms/CommonDesign';
import SelectLanguage from '../../atoms/SelectLanguage';
import CommonButton from '../../atoms/CommonButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgress, TextField } from '@mui/material';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EndAdornmentTextField } from '../../atoms/TextFields/AdornmentTextField';
import ConfirmPassword from './ConfirmPassword';
import URL, { PasswordpublicKey } from '../../../utility/apiurl';
import axios from 'axios';
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import { localstore } from '../../../localStore/localStore';
// import NodeRSA from 'node-rsa';
// import encryptRSA from 'encrypt-rsa';
import JSEncrypt from 'jsencrypt';
import { handleErrorResponse } from '../../atoms/StatusError';
// import NodeRSA from 'encrypt-rsa';





const theme = createTheme();

function Register() {
    const [show, setShow] = useState(1)
    const [alertstatus, setAlertstatus] = useState('');
    const [alertmessage, setAlertmessage] = useState('');
    const [password, setPassword] = useState('')
    const [username, setUsername] = useState('')
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global");
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
    const signUpValidationSchema = Yup.object().shape({
        username: Yup
            .string()
            .matches(/^[A-Za-z0-9]+$/, 'User name should not contain spaces/special characters..')
            .required('Username is required'),
        password: Yup
            .string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(/\d/, 'Password must have a number')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required'),
    })



    const handleDone = async (values) => {
        setLoading(true)
        let data = {
            'userName': `${values.username}@mdx`,
        };
        // axios.get(`${URL.patient}name`)
        //     .then(res => {
        //         console.log(res.headers, "headerssssssss")
        //         const csrftoken = res.data
        //         localStorage.setItem('csrftoken', csrftoken)
        axios.post(`${URL.findPatients}`, data,)
            .then(res => {
                if (res.data === "Valid Username") {
                    handleAddpatient(values)

                } else if (res.data === "UserName Already Taken") {
                    setAlertmessage(res.data)
                    setAlertstatus('error')
                }
            })
            .catch(err => {
                setAlertmessage(err.message)
                setAlertstatus('error')
            }).finally(() => {
                setLoading(false)
            })
        // })


    }

    //OR

    const handleAddpatient = async (values) => {
        setLoading(true)
        const publicKey = PasswordpublicKey;
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);

        const encryptpassword = encrypt.encrypt(values.password);
        const data = {
            passWord: encryptpassword.replace(/\n/g, '').replace(/\r/g, ''),
            userName: `${values.username}@mdx`,
        }
        console.log("data", data)
        await axios.post(`${URL.patient}keycloak/addPatient`, data).then(res => {
            if (res.status === 201) {
                setAlertmessage('created account successfully')
                setAlertstatus('success')
                dispatch({
                    type: 'USERNAME_PASSWORD',
                    payload: { username: values.username, password: values.password }
                })
                setShow(2)
            } else if (res.status === 200) {
                setAlertmessage(res.data)
                setAlertstatus('error')
            }
        }).catch((err) => {
            const { alertmessage, alertstatus } = handleErrorResponse(err)
            setAlertmessage(alertmessage)
            setAlertstatus(alertstatus)
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleOnNextPage = () => {
        setShow(2)
    }


    return (
        <Box>

            <Box sx={{ height: { sm: '0px', md: "3px" } }}></Box>
            <CommonDesign>
                <Box
                    sx={{
                        mt: { xs: 14, sm: 14, md: 2 },
                        px: 4,

                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: 1, color: '#8A2BE2' }}>
                            <Public />
                            <Typography sx={{ fontWeight: 600, fontSize: 17 }}>India</Typography>

                        </Box>
                        <SelectLanguage />
                    </Box>



                    <Box sx={{
                        mx: 4, overflowY: 'scroll', height: "420px", '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                        {show === 1 ? (
                            <>
                                <Box sx={{ mt: 3 }}
                                >
                                    <Typography
                                        sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, fontWeight: 600 }}

                                    >
                                        {t("credentialsData.createaccount.heading")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ mt: 1, color: "gray", fontSize: { xs: 12, md: 14 } }}
                                    >
                                        {t("credentialsData.createaccount.subtxt")}
                                    </Typography>
                                </Box>

                                <Formik
                                    validationSchema={signUpValidationSchema}
                                    initialValues={{ username: "", password: "" }}
                                    onSubmit={(values) => {
                                        handleDone(values);
                                    }}
                                >
                                    {({
                                        handleSubmit,
                                        isValid,
                                        values,
                                        errors,
                                        handleChange,
                                        handleBlur,
                                        touched,
                                        isSubmitting,
                                    }) => (
                                        // <form onSubmit={handleSubmit}>
                                        <>
                                            <Box sx={{ mt: 3 }}>
                                                <EndAdornmentTextField
                                                    borderColor="#59D8FA"
                                                    label="User Name"
                                                    placeholder="Create New User"
                                                    type={'text'}
                                                    icon={<Typography sx={{ fontWeight: 600 }}>@mdx</Typography>}
                                                    // icon={'@mdx'}
                                                    value={values.username}
                                                    onChange={handleChange('username')}
                                                    handleBlur={handleBlur('username')}
                                                />

                                                {errors.username && touched.username && (
                                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                                        {errors.username}</Typography>
                                                )}
                                                <Box sx={{ mt: 3 }}>

                                                    <EndAdornmentTextField
                                                        borderColor="#59D8FA"
                                                        label=" Password"
                                                        placeholder="Create New Password"
                                                        type={showPassword ? 'text' : 'password'}
                                                        icon={showPassword ? <Visibility /> : <VisibilityOff />}
                                                        value={values.password}
                                                        onChange={handleChange('password')}
                                                        handleBlur={handleBlur('password')}
                                                        onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    />
                                                    {errors.password && touched.password && (
                                                        <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                                            {errors.password}</Typography>
                                                    )}

                                                </Box>

                                                {alertmessage && (
                                                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                                )}
                                                <Box sx={{ mt: 3, textAlign: 'center' }}>
                                                    <CommonButton width="150px" bgcolor="blueviolet" text="Done" onClick={handleSubmit} startIcon={loading ? <CircularProgress color='inherit' size={20} /> : null} />

                                                </Box>
                                            </Box>
                                        </>)}
                                </Formik >
                            </>
                        )
                            : show === 2 ? (
                                <ConfirmPassword setShow={setShow} />
                            ) : null
                        }
                    </Box>
                    {/* <AlreadyAccount /> */}

                </Box>
            </CommonDesign >
        </Box >
    );
}

export default Register