import React from 'react'
import { Box, Card, Grid, IconButton, Paper, Typography } from '@mui/material'
import { ArrowForwardIos } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import sampleicon from '../../../../../assets-new/profile-icons/consents.svg'


const MedlearnCard = (props) => {
    return (
        <Paper elevation={3} sx={{ p: 2, textAlign: 'center', cursor: 'pointer', borderRadius: '10px' }}>
            <Box sx={{ height: { xs: '190px', sm: '170px', md: '170px' } }}>
                <img src={sampleicon} alt="consents" />
                <Typography sx={{ fontWeight: 500, mt: '10px', }}>{props.title}</Typography>
                <Typography sx={{ fontSize: '14px', mt: '10px' }}>{props.content}</Typography>
            </Box>
        </Paper>
    )
}
const SymptomsCard = (props) => {
    return (
        <Paper elevation={3} sx={{ p: 2, mt: 2, borderRadius: '10px' }}>
            <Grid container >
                <Grid item xs={2} sm={1} md={1}>
                    <img src={sampleicon} alt="consents" />

                </Grid>
                <Grid item xs={10} sm={11} md={11} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography sx={{ fontWeight: 500 }}>{props.title}</Typography>
                        <Typography sx={{ fontSize: '14px', mt: '10px' }}>{props.content}</Typography>
                    </Box>
                    <ArrowForwardIos />
                </Grid>
                <Grid></Grid>
            </Grid>
        </Paper>
    )
}

function Medlearn() {
    const navigate = useNavigate()
    return (
        <Box sx={{ m: 1, mt: { xs: '30px', md: "30px" }, }}>
            <Grid container columnSpacing={3}>
                <Grid item xs={6} sm={6} md={6} onClick={() => navigate('/healthproblems')}>
                    <MedlearnCard title="Health Problems" content="Understand your symptoms, causes possible treatments" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <MedlearnCard title="Medication Information" content="Know your Medication well for safe and effective treatment" />
                </Grid>
            </Grid>
            <SymptomsCard title="Symptom Checker" content="Find out what could be causing your symptoms and when to seek care., Assess your Risks" />
            <SymptomsCard title="Self Care (Tip& Tricks)" content="Understand natural remedies, diet, yoga and exercises based approach for your healthy living" />
            <Grid container columnSpacing={3} mt={2}>
                <Grid item xs={6} sm={6} md={6}>
                    <MedlearnCard title="Patient Rights" content="Know your rights and responsibilities for better health care system" />
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <MedlearnCard title="Self-Help Groups" content="Discuss with people to know how they have managed to over come, similar issues" />
                </Grid>
            </Grid>
        </Box >
    )
}

export default Medlearn