import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Radio, Tab, Tabs, Typography } from '@mui/material'
import { ArrowUpward, Check, ExpandMore, FilterList, FindInPage, KeyboardArrowDown, LocalHospital, MyLocation, Search } from '@mui/icons-material'
import stethascopeicon from '../../../../assets/home/stethoscope.svg'
import radiologyicon from '../../../../assets/home/bookapp_assets/radiology.svg'
import pharmacyicon from '../../../../assets/home/bookapp_assets/pharmacy.svg'
import microbiologyicon from '../../../../assets/home/bookapp_assets/microbiology.svg'
import endoscopyicon from '../../../../assets/home/bookapp_assets/endoscopy.svg'
import diagnosticicon from '../../../../assets/home/bookapp_assets/diagnostic.svg'
import surgeryicon from '../../../../assets/home/bookapp_assets/surgery.svg'
import healthcareicon from '../../../../assets-new/services-icons/healthcareicon.svg'
import consulticon from '../../../../assets-new/medxpedia-icons/doctor_stethascope.svg'
import searchblack from '../../../../assets-new/services-icons/search-Icon-black.svg'
import SearchBar from '../../../atoms/Searchbar'
import TabItem from '../../../atoms/TabItem'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import CheckSymptoms from './CheckSymptoms'
import ServicesMobileView from './ServicesResponsive';

const HealthCare = (props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2, }} >
            <img src={props.icon} alt="consult" />
            <Typography sx={{ fontSize: "14px", }}>
                {props.title}
            </Typography>
        </Box>
    )
}

function ServicesPage() {

    const navigate = useNavigate()
    const [selectedTab, setSelectedTab] = useState('consultation');
    const [showCheckboxes, setShowCheckboxes] = useState(true);
    const [visitChecked, setVisitChecked] = useState(false);
    const [consultChecked, setConsultChecked] = useState(false);
    const location = useLocation()

    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        if (tab === 'consultation') {
            setShowCheckboxes(true);
        } else {
            setShowCheckboxes(false);
        }
        // setSelectedSubTab(null);
        navigate(`/services/${tab}`)
    };
    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const currentTab = pathSegments[pathSegments.length - 1];
        setSelectedTab(currentTab);
    }, [location.pathname]);
    const handleCheckbox = (tab) => {
        // Add the logic to navigate to the selected tab here
        navigate(`/services/${tab}`);

        // Uncheck the other checkbox
        if (tab === 'visit-clinic') {
            setConsultChecked(false);
        } else if (tab === 'consult-online') {
            setVisitChecked(false);
        }
    };
    return (
        <Box sx={{ pt: '49px' }}  >
            {/* <Box sx={{ pt: '50px', position: 'fixed', right: 0, top: 90, left: 0 }}  > */}
            <Grid container >
                <Grid item md={2.2} p={"20px 0px 0px 20px"}>
                    <Box sx={{ display: { xs: 'none', sm: 'none', md: 'flex' }, alignItems: 'center', gap: '5px', }}>
                        <img src={healthcareicon} alt='healthcare' />
                        <Typography variant='h6' sx={{ color: '#3D6596', pt: "1px", fontSize: '17px' }}>
                            Healthcare Service
                        </Typography>
                    </Box>
                    {/* <ServicesMobileView /> */}

                    <Box sx={{
                        mt: "14px",
                        display: { xs: 'none', sm: 'none', md: 'flex' },
                        flexDirection: 'column',
                        gap: 1,
                        mt: { sm: '150px', md: "20px" },
                    }}>
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: "10px", bgcolor: '#F7F7F7', width: '180px', padding: "5px 10px", boxShadow: 1 }} onClick={() => setVisit(!visit)}>
                            <img src={consulticon} alt="consult" />
                            <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>
                                Consultation
                            </Typography>
                        </Box> */}
                        {/* {visit && */}
                        <TabItem icon={consulticon} label="Consultation" selected={selectedTab === 'consultation'}
                            onClick={() => handleTabClick("consultation")} />
                        {showCheckboxes && (

                            <FormGroup sx={{ ml: 3, mt: 1 }}>
                                <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }} control={<Checkbox checked={visitChecked} onChange={() => setVisitChecked(!visitChecked)} />} label="Visit Clinic" onClick={() => handleCheckbox('visit-clinic')} />
                                <FormControlLabel sx={{ '.MuiFormControlLabel-label': { fontSize: '14px' } }} control={<Checkbox />} label="Consult Online" checked={consultChecked} onChange={() => setConsultChecked(!consultChecked)} onClick={() => handleCheckbox('consult-online')} />
                            </FormGroup>
                        )}
                        {/* } */}
                        <TabItem icon={pharmacyicon} label="Pharmacy" fontSize={"17px"} selected={selectedTab === 'pharmacy'}
                            onClick={() => handleTabClick("pharmacy")} />
                        <TabItem icon={radiologyicon} label="Radiology" fontSize={"17px"} selected={selectedTab === 'radiology'}
                            onClick={() => handleTabClick("radiology")} />
                        <TabItem icon={microbiologyicon} label="Microbiology" fontSize={"17px"} selected={selectedTab === 'microbiology'}
                            onClick={() => handleTabClick("microbiology")} />
                        <TabItem icon={endoscopyicon} label="Endoscopy" fontSize={"17px"} selected={selectedTab === 'endoscopy'}
                            onClick={() => handleTabClick("endoscopy")} />
                        <TabItem icon={diagnosticicon} label="Diagnostics" fontSize={"17px"} selected={selectedTab === 'diagnostics'}
                            onClick={() => handleTabClick("diagnostics")} />
                        <TabItem icon={surgeryicon} label="Surgical Procedure" fontSize={"16px"} selected={selectedTab === 'surgical'}
                            onClick={() => handleTabClick("surgical")} />
                        {/* <HealthCare title="Pharmacy" icon={pharmacyicon} />
                        <HealthCare title="Radiology" icon={radiologyicon} />
                        <HealthCare title="Microbiology" icon={microbiologyicon} />
                        <HealthCare title="Endoscopy" icon={endoscopyicon} />
                        <HealthCare title="Diagnostics" icon={diagnosticicon} />
                        <HealthCare title="Surgical Procedure" icon={surgeryicon} /> */}
                    </Box>
                </Grid>
                <Grid item md={9.8}>
                    <Outlet />
                </Grid>
                {/* <Grid item md={0.2}>
                </Grid>
                <Grid item md={2.3} >

                    <CheckSymptoms />
                </Grid> */}
            </Grid>
        </Box>
    )
}

export default ServicesPage