import React from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material'
import URL from '../../../../utility/apiurl'
import axios from 'axios'
import { localstore } from '../../../../localStore/localStore'
import { useDispatch, useSelector } from 'react-redux'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'

import { useTranslation } from "react-i18next";
import DeleteHealthId1 from './DeleteHealthId1'
import DeleteHealthId2 from './DeleteHealthId2'
import { useNavigate } from 'react-router-dom'
import { useState } from 'react'
import DeleteHealthId3 from './DeleteHealthId3'
import DeleteHealthId4 from './DeleteHealthId4'
import DeletehealthId5 from './DeletehealthId5'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function DeleteHealthId(props) {

    const { open, setOpen } = props
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [show, setShow] = useState(1)
    const [activeButton, setActiveButton] = useState('mobile')
    const [alert, setAlert] = useState(false);
    const [checkid, setCheckid] = useState(null);
    const [abhaNum, setAbhaNum] = useState('')
    const [txnId, setTxnId] = useState('')
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()
    const csrftoken = localstore.getCsrfToken()

    const handleOnChangeAbha = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setAbhaNum(inputValue.slice(0, 14));
        setAlertmessage('')
    }

    const handleSend = async () => {
        // navigation.navigate('AbhaNumberStep2')
        const inputValue = abhaNum.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');

        setLoading(true);
        let d = {
            healthIdNumber: inputValue,
        };
        let d1 = {
            healthidnumber: inputValue,
            authMethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
        };

        try {
            // First API call
            const searchResponse = await axios.post(`${URL.abha}search/user/byHealthId/${primaryId}`, d, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log('--------->>> searchapi', searchResponse.data);
            setCheckid(searchResponse.data.status);

            if (searchResponse.status === 200 || searchResponse.status === 201) {
                // Second API call
                setLoading(true); // Set loading for the second API call
                const res = await axios.post(`${URL.abha}auth/hidNumber/init/${primaryId}`, d1, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },

                });
                setTxnId(res.data.txnId)
                setAlert(true);
                setAlertmessage('OTP sent to mobile number!');
                setAlertstatus('success');
                setShow(2)
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setAlert(true);
                setAlertmessage("ABHA Number not Found or No ABHA address linked with this ABHA number");
                setAlertstatus('error');
            } else {
                setAlert(true);
                setAlertmessage(err.message);
                setAlertstatus('error');
            }
        } finally {
            setLoading(false); // Reset loading state after both API calls
        }
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleCloseDone = () => {
        setAbhaNum('')
        setAlertmessage('')
        handleClose()
    }
    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >
                {show === 1 ? (
                    <>
                        <DialogTitle
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                p: '10px 20px'
                            }}
                        >
                            {/* <IconButton> <Delete sx={{ color: 'red' }} /></IconButton> */}
                            <Typography sx={{ fontSize: 600, fontSize: "17px" }}>{t("delhealthid")}</Typography>

                            <Button
                                sx={{
                                    height: "25px",
                                    color: "#B48FE4",
                                    borderRadius: "15px",
                                }}
                                variant="outlined"
                                onClick={handleCloseDone}
                            >
                                Cancel
                            </Button>
                        </DialogTitle>
                        <Divider />

                        <DialogContent>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 2,
                                        }}
                                    >
                                        <img src={Abhalogo} alt="abha" width={30} height={30} />
                                        <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16 } }}>{t("healthid14")}</Typography>
                                    </Box>

                                    <Grid container mt={2} textAlign={'center'}>
                                        <Grid item xs={12}>
                                            <TextInputField
                                                width={{ sm: "350px", md: '350px' }}
                                                placeholder={t("abhahealthid")}
                                                name="number"
                                                border="none"
                                                value={abhaNum}
                                                onChange={handleOnChangeAbha}
                                            />
                                        </Grid>
                                        {alertmessage && (
                                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                        )}
                                    </Grid>

                                    <Typography
                                        sx={{
                                            color: "#9560D9",
                                            ml: 1,
                                            fontSize: { xs: 12, sm: 13, md: 13 },
                                            mt: "5px",
                                            "&:hover": {
                                                textDecoration: "underline",
                                                cursor: "pointer",
                                            },
                                        }}
                                        onClick={() => { setShow(4) }}
                                    >
                                        {t("forgothealthid")}
                                    </Typography>
                                    <Box mt={3} textAlign={"center"}>
                                        <Typography>{t("verifyabha")}</Typography>
                                        <ButtonGroup
                                            variant="contained"
                                            aria-label="outlined primary button group"
                                            sx={{ mt: 1 }}
                                        >
                                            <Button
                                                sx={{
                                                    bgcolor:
                                                        activeButton === "aadhar" ? "white" : "#EAE2E2",
                                                    color: "black",
                                                    '&:hover': {
                                                        bgcolor:
                                                            activeButton === "aadhar" ? "white" : "#EAE2E2",
                                                    }
                                                }}
                                                disableElevation
                                                onClick={() => setActiveButton("aadhar")}
                                            >
                                                {t("aadharotp")}
                                            </Button>
                                            <Button
                                                sx={{
                                                    bgcolor:
                                                        activeButton === "mobile" ? "white" : "#EAE2E2",
                                                    color: "black",
                                                    '&:hover': {
                                                        bgcolor:
                                                            activeButton === "mobile" ? "white" : "#EAE2E2",
                                                    }
                                                }}
                                                disableElevation
                                                onClick={() => setActiveButton("mobile")}
                                            >
                                                {t("mobileotp")}
                                            </Button>
                                        </ButtonGroup>
                                    </Box>
                                </Box>
                            </Box>
                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ justifyContent: 'center' }}>
                            <Box sx={{ textAlign: "center" }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        borderRadius: 2,
                                        "&:hover": {
                                            bgcolor: "#1CB500",
                                        },
                                        bgcolor: "#1CB500",
                                        width: "150px",
                                        mt: 1, mb: 1
                                    }}
                                    onClick={handleSend}
                                    disabled={loading || abhaNum.length < 14}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : null
                                    }
                                >
                                    {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                    {t("credentialsData.signupmobiledata.otpbtntxt")}
                                </Button>
                            </Box>
                        </DialogActions>
                    </>
                ) : show === 2 ? (
                    <DeleteHealthId1
                        abhaNum={abhaNum}
                        setShow={setShow}
                        transactionId={txnId}
                        authMethod={
                            activeButton === "aadhar" ? "AADHAAR_OTP" : "MOBILE_OTP"
                        }
                        handleClose={handleClose}
                    />
                ) : show === 3 ? (
                    <DeleteHealthId2
                        setShow={setShow}
                        setOpen={setOpen}
                        handleClose={handleClose}
                    />
                ) : show === 4 ? (
                    <DeleteHealthId3 setShow={setShow} handleClose={handleClose} />
                ) : show === 5 ? (
                    <DeleteHealthId4 setShow={setShow} handleClose={handleClose} />
                ) : show === 6 ? (
                    <DeletehealthId5 setShow={setShow} />
                ) : null}
            </Dialog>
        </div>
    );
}

export default DeleteHealthId;