
import React from 'react'
import { Box, Card, Paper, Typography } from '@mui/material'
import { OutlinedButton } from './CommonButton'

function EditProfileCard(props) {
    return (
        <div>
            <Paper elevation={3} sx={{ p: '6px 20px', mt: "15px", borderRadius: '10px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Typography fontSize={14}>
                        {props.title}
                    </Typography>
                    <Typography fontSize={15} mt={'5px'}>
                        {props.displaydata}
                    </Typography>
                </Box>
                <OutlinedButton text="Update" borderColor="#785D9B" width="100px" height="32px" color="#000000" onClick={props.onClick} />
            </Paper>
        </div>
    )
}

export default EditProfileCard