import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material';
import settingsicon from '../../../../assets-new/carekit-icons/caresettings.svg';
import { localstore } from '../../../../localStore/localStore';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Timeline } from '@mui/icons-material';
import { useState } from 'react';
import CareMobileView from './CareResponsive';
import CareResponsive from './CareResponsive';


const TabItem = ({ icon, label, selected, onClick, to }) => (

    <Box
        sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 2,
            cursor: 'pointer',
            // backgroundColor: selected ? '#EFEFEF' : 'transparent',
            borderRadius: "7px",
            boxShadow: selected
                // ? '0px 2px 4px rgba(0, 0, 0, 0.2), 0px 4px 8px rgba(0, 0, 0, 0.1)'
                ? { md: '0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)' }

                : 'none',  // Customize the shadow values

            mt: 1,
            bgcolor: selected ? { xs: '#FFFFFF', md: '#FFFFFF' } : 'none',

            p: "5px 10px",
            // transition: 'box-shadow 0.3s ease-in-out',
        }}
        onClick={onClick}
    >
        {icon && React.isValidElement(icon) && React.cloneElement(icon, { fontSize: 'inherit' })}

        {/* <img src={icon} alt="live" width={20} height={20} /> */}
        <Typography variant="h6" sx={{ color: '#725F61', fontWeight: 600, fontSize: { xs: '14px', sm: '17px', md: "17px" } }}>
            {label}
        </Typography>
    </Box >
);
function CareChart() {
    const navigate = useNavigate()
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('caredashboard');
    // const isActive = ['/records/linkedproviders',];
    const primaryId = localstore.getPrimaryId()
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        navigate(`/carechart/${tab}`)
    };

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const currentTab = pathSegments[pathSegments.length - 1];
        setSelectedTab(currentTab);
    }, [location.pathname]);

    return (
        <div>
            <CareResponsive />
            <Grid container sx={{ p: { md: 0, sm: 1, xs: 1 } }}>
                <Grid item md={0.2}  ></Grid>
                <Grid item md={2.5} sm={12} xs={12}>
                    <Box
                        sx={{
                            display: { xs: 'none', md: 'flex' },
                            flexDirection: { sm: 'row', md: 'column' },
                            gap: 1,
                            mt: { sm: '80px', xs: '0px', md: "60px", },
                            position: 'fixed',
                        }}
                    >

                        <TabItem
                            label={<p>360&deg; Dashboard</p>}
                            selected={selectedTab === 'carechart' || location.pathname.includes('/caredashboard')}
                            onClick={() => handleTabClick("caredashboard")}
                        />

                        <TabItem
                            icon={<Timeline sx={{ color: '#756365' }} />}
                            label="Timeline"
                            selected={selectedTab === 'caretimeline' || location.pathname.includes('/consulttimeline') || location.pathname.includes('/clinicalgoal') || location.pathname.includes('/medication') || location.pathname.includes('/diagnosticreports') || location.pathname.includes('/bloodpressure') || location.pathname.includes('/platelet')}
                            onClick={() => handleTabClick("caretimeline")}
                        />

                        <TabItem
                            icon={<img src={settingsicon} alt="settings" width={25} height={25} />}
                            label="Care Preferences"
                            selected={selectedTab === 'caresettings'}
                            onClick={() => handleTabClick("caresettings")}
                        />

                    </Box>
                </Grid>
                <Grid item md={6.8} sm={12} xs={12} sx={{ mt: { xs: "0px", sm: "0px", md: '70px' } }}>
                    {primaryId ?
                        <Outlet /> : <Typography textAlign={'center'}>No data available. Please log in to view the data.</Typography>}
                </Grid>
                {/* <Grid item md={3} mt={"50px"} >
                    <NewRecords />
                </Grid > */}
            </Grid >
        </div>
    )
}

export default CareChart;