import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import medxpertIcon from '../../../assets/home/medxpertIcon.svg';
import { CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import URL from '../../../utility/apiurl';
import * as yup from 'yup';
import { Formik } from 'formik';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import CommonButton from '../../atoms/CommonButton';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { EndAdornmentTextField } from '../../atoms/TextFields/AdornmentTextField';

function CreateNewPassword(props) {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');

    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');

    const [t, i18n] = useTranslation("global");

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const handleClickShowConfirmPass = () => setShowConfirmPass((prevShowPassword) => !prevShowPassword);
    const signUpValidationSchema = yup.object().shape({

        password: yup
            .string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(/\d/, 'Password must have a number')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required'),
        confirmpassword: yup
            .string()
            .oneOf([yup.ref('password')], 'Passwords do not match')
            .required('Confirm password is required'),
    })




    const handleDone = (values) => {
        setLoading(true)
        let data = {
            password: values.password,
            userName: props.username,
        };

        axios
            .put(`${URL.resetPassword}`, data)
            .then((res) => {
                // console.log(res, 'resetpassword');
                setAlertstatus('success');
                setAlertmessage('Password Changed Successfully!');

                navigate('/login');
            })
            .catch((err) => {
                setAlertstatus('error');
                setAlertmessage(err.message);
            }).finally(() => {
                setLoading(false)
            })
    };



    return (
        <Box>
            <Formik
                validationSchema={signUpValidationSchema}
                initialValues={{ password: "", confirmpassword: "" }}
                onSubmit={(values) => {
                    handleDone(values);
                }}
            >
                {({
                    handleSubmit,
                    isValid,
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                    isSubmitting,
                }) => (
                    // <form onSubmit={handleSubmit}>
                    <>
                        <Box sx={{ mt: 3 }}>


                            <EndAdornmentTextField
                                borderColor="#59D8FA"
                                label=" Password"
                                placeholder="Enter Password"
                                type={showPassword ? 'text' : 'password'}
                                icon={showPassword ? <Visibility /> : <VisibilityOff />}
                                value={values.password}
                                onChange={handleChange('password')}
                                handleBlur={handleBlur('password')}
                                onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            />
                            {errors.password && touched.password && (
                                <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                    {errors.password}</Typography>
                            )}
                            <Box sx={{ mt: 3 }}>

                                <EndAdornmentTextField
                                    borderColor="#59D8FA"
                                    label="Confirm Password"
                                    placeholder="Enter Confirm Password"
                                    type={showConfirmPass ? 'text' : 'password'}
                                    icon={showConfirmPass ? <Visibility /> : <VisibilityOff />}
                                    value={values.confirmpassword}
                                    onChange={handleChange('confirmpassword')}
                                    handleBlur={handleBlur('confirmpassword')}
                                    onClick={handleClickShowConfirmPass}
                                // onMouseDown={handleMouseDownPassword}
                                />
                                {errors.confirmpassword && touched.confirmpassword && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {errors.confirmpassword}</Typography>
                                )}

                            </Box>

                            {alertmessage && (
                                <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                            )}
                            <Box sx={{ mt: 3, textAlign: 'center' }}>
                                <CommonButton width="150px" bgcolor="blueviolet" text="Done" onClick={handleSubmit} startIcon={loading ? <CircularProgress size={20} /> : null} />

                            </Box>
                        </Box>
                    </>)}
            </Formik >
            {/* <Box sx={{ mt: 4 }}>
                <ColorTextField label="Password" placeholder=" Enter Password" type="password" name="password" value={password} onChange={handleChangePassword}
                />
                {passerrormsg && (
                    <Typography sx={{ color: "red", fontSize: { xs: 14, md: 14 } }}>
                        {passerrormsg}
                    </Typography>
                )}
            </Box>
            <Box sx={{ mt: 4 }}>
                <ColorTextField label="Confirm Password" placeholder="Enter Confirm Password" type="password" name="confirmPassword" onChange={handleChangeConfirmPassword}
                />
                {conpasserrormsg && (
                    <Typography sx={{ color: "red", fontSize: { xs: 14, md: 16 } }}>
                        {conpasserrormsg}
                    </Typography>
                )}
            </Box>
            <Box sx={{ textAlign: 'center', mt: 4, }}>
                <CommonButton text="Done" width={150} bgcolor={"#8A2BE2"} disabled={loading || !password || !confirmPassword}
                    startIcon={
                        loading ? <CircularProgress size={20} color="inherit" /> : null
                    } onClick={handleDone} />
            </Box>

            {alertmessage && (
                <Typography
                    sx={{
                        color: "red",
                        fontSize: { xs: "12px", md: 14 },
                        mt: 3,
                        textAlign: 'center'
                    }}
                >
                    {alertmessage}
                </Typography>
            )} */}
        </Box>
    );
}

export default CreateNewPassword;
