import axios from 'axios';
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Box, Button, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import URL, { OtpPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { useTranslation } from "react-i18next";
import ResendOtp, { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';

function MobileStep2(props) {
    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(59);
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = OtpPublicKey
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    // console.log(result, "result-------->>>")
    const sessionId = result.sessionId
    const mobileNumber = result.mobile
    const dispatch = useDispatch()
    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }


    const handleVerify = async () => {
        // props.setShow(3)
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);

        const encryptOtp = encrypt.encrypt(otp);
        let d = {
            "otp": otp,
            'mobileOrAadhaar': 'mobile',
            'sessionId': sessionId,
        };
        setLoading(true);
        console.log(d);
        await axios.post(`${URL.abha}mobile/validate/otp/${primaryId}`, d, {
            headers: {
                'gatewayToken': 'medxperts',
                'Authorization': `Bearer ${token}`,
            },
        }).then(res => {
            // console.log(res, "mobilegener");
            if (res.status === 200 || res.status === 201) {
                setAlertmessage('OTP Verified Successfully!');
                setAlertstatus('success');

                dispatch({
                    type: "MAPPEDPHRADDRESS",
                    payload: res.data
                })
                props.setShow(3)
            }
        }).catch(err => {
            // console.log(err.message);
            if (err.response && err.response.data && err.response.status === 400) {
                const responseText = err.response.data.message;
                const responseArray = JSON.parse(responseText.split(': ')[1]);
                const errorMessage = responseArray[0].error.message;
                setAlertmessage(errorMessage);
                setAlertstatus('error');
            } else {
                setAlertmessage(err.message);
                setAlertstatus('error');
            }
        }).finally(() => {
            setLoading(false);
            // setOtp('')
        });
    }
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time, result]);

    const handleResendOtp = async () => {
        setLoading(true)
        let d = {
            'sessionId': sessionId,
        };
        await axios.post(`${URL.abha}mobile/resend/otp/${primaryId}`, d, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            // console.log(res.data);
            setTime(59);
            setAlertmessage('OTP sent Successfully!');
            setAlertstatus('success');

        }).catch(err => {
            // console.log(err.message);
            setAlertmessage('Oops something went wrong!');
            setAlertstatus('error');

        }).finally(() => { setLoading(false); });

    }

    let formattedMobileNumber = '';
    if (typeof mobileNumber === 'string') {
        formattedMobileNumber = mobileNumber.slice(-4);
    }
    const handleBack = (e) => {
        props.setShow(1)
        props.setAlertmessage('')
    }
    return (
        <div>
            <Box sx={{ mt: { xs: 1, sm: "10px", md: 1.5 } }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: 1, sm: 2, md: 2 },
                    }}
                >
                    <IconButton onClick={handleBack}>
                        <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: { xs: 15, sm: "17px", md: "17px" },
                            fontWeight: "500",
                            color: "#007DCD",
                        }}
                    >
                        Change Mobile Number
                    </Typography>
                </Box>
            </Box>
            <Paper elevation={3} sx={{ p: 2, mt: 1 }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        textAlign: "center",
                    }}
                >
                    <Box mt={1}>
                        <Typography
                            sx={{ fontSize: { xs: 14, md: 16 }, fontWeight: 500 }}
                        >
                            {t("verifyotp")}
                        </Typography>
                        <Box mt={2}>
                            <TextInputField
                                name="otp"
                                width="350px"
                                placeholder={t("credentialsData.signupmobiledata.enterotp")}
                                value={otp}
                                onChange={handleOnChangeOtp}
                                border="none"
                            />
                        </Box>
                        <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} />
                    </Box>
                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )}

                    <Typography
                        sx={{
                            fontStyle: "inherit",
                            fontSize: { xs: 14, sm: 14, md: 14 },
                            fontWeight: 400,
                            mt: 2,
                        }}
                    >
                        {t("otpsentmobile")} +91 xxxx xx {formattedMobileNumber}
                    </Typography>
                    <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                        <Button
                            variant="contained"
                            sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                            onClick={handleVerify}
                            disabled={loading || otp?.length < 6}
                            startIcon={
                                loading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : null
                            }
                        >
                            {t("credentialsData.signupmobiledata.submitbtn")}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </div>
    );
}

export default MobileStep2;