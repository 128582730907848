export const relation = [
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'SONC',
        'display': 'son',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'DAUC',
        'display': 'daughter',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'SPS',
        'display': 'spouse',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'GRNDSON',
        'display': 'grandson',
    },

    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'GRNDDAU',
        'display': 'granddaughter',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'GRFTH',
        'display': 'grandfather',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'GRMTH',
        'display': 'grandmother',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'FTH',
        'display': 'father',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'MTH',
        'display': 'mother',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'BRO',
        'display': 'brother',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'SIS',
        'display': 'sister',
    },
    {
        'system': 'http://terminology.hl7.org/CodeSystem/v3-RoleCode',
        'code': 'O',
        'display': 'Other',
    },
];
