import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, Grid, IconButton, Paper, Tab, Tabs, Typography } from '@mui/material';
import { ArrowBackIos, ArrowCircleLeft, Close, MoreVert, Share } from '@mui/icons-material';
import DoctorIcon from '../../../../../assets/home/org.svg';
// import suggestDocIcon from '../../../../assets/home/suggested1.jpg'
import profileCoverPic from '../../../../../assets/home/backgroundorg.png';
import EditIcon from '../../../../../assets/home/profile_assets/primaryEdit.svg'
import URL from '../../../../../utility/apiurl';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { localstore } from '../../../../../localStore/localStore';

import { getOrgFollowCount, getOrgdata } from '../../../../../redux/Actions/Actions';
import { useDispatch } from 'react-redux';
// import './orgprofile.css'
import { useTranslation } from "react-i18next";
import OrgOverview from './OrgOverview';
import OrgTimeline from './OrgTimeline';
import Peoples from './Peoples';
import Articles from './Articles';

function OrgProfile() {
    const [cover, setCover] = useState('');
    const [t, i18n] = useTranslation("global");
    const [value, setValue] = useState(0);
    const [page, setPage] = useState([])
    const [org, setOrg] = useState()
    const [ind, setInd] = useState()
    const [loading, setLoading] = useState(false)
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [modal, setModal] = useState(false)
    const [isFollowing, setIsFollowing] = useState()
    // console.log(isFollowing, "flllllllllllllll")
    const [beingFollowed, setBeingFollowed] = useState()
    // console.log(page, "page")
    // console.log(user, "org")
    const { id } = useParams()
    const navigate = useNavigate()
    const orgId = id
    // console.log(orgId, "orgiddddd")
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const patientId = localstore.getPatientId()

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const tabInfo = [
        { label: t("profileTabs.overview"), content: <OrgOverview user={org} /> },
        {
            label: t("profileTabs.timeline"),
            content: <OrgTimeline user={org} orgId={orgId} />,
        },
        { label: t("profileTabs.people"), content: <Peoples user={org} /> },
        { label: t("profileTabs.articles"), content: <Articles user={org} /> },
    ];

    useEffect(() => {
        if (id) {
            axios.get(`${URL.getOrgdata}${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    console.log(res, "orgdoc")
                    setPage(res?.data)
                    setOrg(res?.data?.[0]?.data);
                    setBeingFollowed(res?.data?.[0]?.BeingFollowed)

                })
                .catch((error) => console.log(error, '555555555'));
        }
    }, []);
    // console.log(beingFollowed, "iiiiiiiii");
    useEffect(() => {
        setIsFollowing(beingFollowed)
    }, [beingFollowed])

    const handleFollow = (index) => {
        setInd(index);
        setLoading(true);
        axios.post(`${URL.followOrganisation}${patientId}`, org)
            .then(res => {
                setAlertopen(true);
                setAlertMessage('Started Following');
                setAlertstatus('success');
                setLoading(true);
                dispatch(getOrgFollowCount(patientId));
                dispatch(getOrgdata(orgId, token));
                // setTimeout(() => {
                //     window.location.reload();
                // }, 1000);
            })
            .catch(err => {
                setAlertopen(true);
                setLoading(true);
                setAlertMessage(err.message);
                setAlertstatus('error');
            })
            .finally(() => {
                setTimeout(() => {
                    setAlertopen(false);
                }, 2000);
                setLoading(false);
                setInd('');
            });
        setIsFollowing(!isFollowing);

    }
    const handleOpenModal = () => {
        setModal(true)
    }
    const handleClose = () => {
        setModal(false)
    }
    const handleUnFollowPage = () => {
        if (token?.length > 0 && patientId !== 0 && org.id !== 0) {
            axios.put(`${URL.unfollowOrg}${patientId}/page/${org.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    setModal(false);
                    dispatch(getOrgdata(org.id, token));
                    dispatch(getOrgFollowCount(patientId));
                })
                .catch(err => { console.log(err.message); })
                .finally(setModal(false));
            setIsFollowing(!isFollowing);

        }
    }

    const uploadFile = () => {

    }

    return (
        <Box
            sx={{ p: 1 }}
        >
            <Box>
                <div
                    style={{
                        width: "100%",
                        overflow: "hidden",
                        height: `${cover ? "" : "120px"}`,
                        position: "relative",
                    }}
                >
                    <img
                        src={org?.coverPhoto ? org?.coverPhoto : profileCoverPic}
                        alt=""
                        width="100%"
                        style={{ objectFit: "cover" }}
                    />
                    {/* <div style={{ position: 'absolute', top: 15, right: 20, }}>
                        <label htmlFor="img" style={{ display: 'block', cursor: 'pointer' }}>
                            <input type="file" name="uploadfile" id="img" accept=".jpg, .png, .jpeg" style={{ display: 'none' }} onChange={(e) => uploadFile(e, 'cover')} />
                            <img src={EditIcon} alt="editIcon" width={30} height={30} />
                        </label>

                    </div> */}
                    <div style={{ position: "absolute", top: 10, left: 20 }}>
                        <IconButton onClick={() => navigate(-1)}
                        >
                            {" "}
                            <ArrowCircleLeft
                                sx={{ cursor: "pointer", color: "#007AC8" }}
                            />
                        </IconButton>
                    </div>
                </div>
                <Box sx={{ border: "1px solid #E7E7E7", }}>
                    <Grid container>
                        <Grid item xs={3.5} sm={1.9} md={2}>
                            <div
                                style={{ backgroundColor: "#c7ced9", position: "relative" }}
                            >
                                <div
                                    style={{
                                        position: "absolute",
                                        left: "20px",
                                        top: "-50px",
                                        float: "left",
                                    }}
                                >
                                    {/* User Photo */}
                                    <img
                                        src={org?.orgPageImage ? org?.orgPageImage : DoctorIcon}
                                        width={70}
                                        height={70}
                                        style={{
                                            objectFit: "cover",
                                            background: "white",
                                            borderRadius: "10px",
                                        }}
                                    />

                                    {/* Edit Icon */}
                                    {/* <div style={{ position: 'relative' }}>
                                        <input type="file" name="uploadfile2" id="img1" accept='.jpg, .png, .jpeg' style={{ display: 'none' }} onChange={(e) => uploadFile(e, 'profile')} />
                                        <label htmlFor="img1" className="follow-link font-weight add-cover cursor">
                                            <span style={{
                                                fontSize: "16px", position: 'absolute', top: '-30px', right: 0

                                            }}>
                                                <img src={EditIcon} alt="editIcon" width={20} height={20} style={{ cursor: 'pointer' }} />

                                            </span>
                                        </label>
                                    </div> */}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={8.5} sm={8.1} md={8}>
                            {org?.organisation?.name?.length > 0 ? (
                                <Typography
                                    sx={{ fontSize: { xs: "15px", md: 18 }, fontWeight: 600 }}
                                >
                                    {org?.organisation?.name}
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: { xs: "15px", md: 12 } }}>
                                    N/A
                                </Typography>
                            )}

                            <Typography>
                                {org?.organisation.identifier[0]?.type.text ? (
                                    org?.organisation.identifier[0]?.type.text.length > 25 ? (
                                        <Typography sx={{ fontSize: { xs: 12, sm: 14, md: 15 } }}>
                                            {org?.organisation.identifier[0].type.text.slice(0, 24)}
                                            ..
                                        </Typography>
                                    ) : (
                                        <Typography sx={{ fontSize: { xs: 12, sm: 14, md: 15 } }}>
                                            {org?.organisation.identifier[0].type.text}
                                        </Typography>
                                    )
                                ) : (
                                    <Typography sx={{ fontSize: { xs: 12, sm: 14, md: 12 } }}>
                                        {t("na")}
                                    </Typography>
                                )}
                            </Typography>

                            <Typography>
                                {org?.organisation.address[0].city.length > 0 ? (
                                    <Typography sx={{ fontSize: { xs: 12, sm: 14, md: 14 } }}>
                                        {org?.organisation.address[0].city}
                                    </Typography>
                                ) : (
                                    <Typography sx={{ fontSize: { xs: 12, sm: 14, md: 12 } }}>
                                        {t("na")}
                                    </Typography>
                                )}
                            </Typography>
                        </Grid>
                    </Grid>

                    <Box
                        sx={{
                            display: "flex",
                            ml: 2,
                            mb: 1,
                            mt: 2,
                            alignItems: "center",
                            justifyContent: "start",
                        }}
                    >
                        <div></div>
                        <div>
                            <Button
                                sx={{
                                    borderRadius: "10px",
                                    bgcolor: "#388AAF",
                                    "&:hover": {
                                        bgcolor: "#388AAF",
                                    },
                                    height: 28,
                                    width: 130,
                                }}
                                variant="contained"
                                onClick={() => {
                                    if (loading === false) {
                                        if (isFollowing) {
                                            handleOpenModal();
                                        } else {
                                            handleFollow(ind);
                                        }
                                        // setIsFollowing(!isFollowing);
                                    } else {
                                        return;
                                    }
                                }}
                            >
                                <Typography sx={{ fontSize: "16px" }}>
                                    {isFollowing ? t("following") : t("follow")}
                                </Typography>
                            </Button>
                            <Button
                                sx={{
                                    borderRadius: "10px",
                                    border: "1px solid #388AAF",
                                    color: "#388AAF",
                                    height: 28,
                                    width: 100,
                                    ml: 2,
                                }}
                                variant="outlined"
                                startIcon={<Share />}
                            >
                                <Typography>{t("actions.share")}</Typography>
                            </Button>
                        </div>
                    </Box>
                </Box>
            </Box>

            <Card
                sx={{
                    zIndex: 100,
                    position: "sticky",
                    top: { xs: "58px", sm: "63px", md: "130px" },
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="tabs example"
                    TabIndicatorProps={{ sx: { display: "none" } }}
                    centered
                    sx={{
                        "& .MuiTab-root": { minHeight: "47px", fontSize: { md: 16 } },
                        "& .Mui-selected": {
                            borderBottom: "2px solid blueviolet",
                        },
                        alignItems: "center",
                        "& .MuiTabs-flexContainer": {
                            display: "flex",
                            justifyContent: "start",
                        },
                    }}
                >
                    {tabInfo.map((tab, index) => (
                        <Tab
                            sx={{
                                fontWeight: value === index ? 600 : 500,
                                color: "black",
                                gap: 1,
                                // fontSize: value === index ? { xs: "18px !important", sm: 19, md: '20px !important' } : { xs: "12px !important", sm: '13px', md: '15px !important' },
                            }}
                            key={index}
                            label={tab.label}
                        />
                    ))}
                </Tabs>
            </Card>

            <Box sx={{ border: "1px solid #E7E7E7", p: 3 }}>
                {tabInfo.map((tab, index) => (
                    <Box key={index} hidden={value !== index}>
                        {tab.content}
                    </Box>
                ))}
            </Box>
            <div>
                <Dialog
                    open={modal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                        <Typography sx={{ fontWeight: 600 }}>
                            {" "}
                            {t("unfollowbtn")}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <Close sx={{ color: 'red' }} />
                        </IconButton>
                    </DialogTitle>
                    <Divider />
                    <DialogActions>
                        <Button onClick={handleClose} sx={{ color: "black" }}>
                            {t("cancel")}
                        </Button>
                        <Button
                            sx={{
                                bgcolor: "blueviolet",
                                "&:hover": {
                                    bgcolor: "blueviolet",
                                },
                            }}
                            onClick={handleUnFollowPage}
                            autoFocus
                            variant="contained"
                        >
                            {t("unfollow")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Box>
    );
}

export default OrgProfile;
