import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { localstore } from '../../localStore/localStore';

function NotFound() {
    const navigate = useNavigate()
    const primaryFixedid = localstore.getPrimaryFixedId()
    useEffect(() => {
        if (!primaryFixedid) {
            navigate('/login');
        }
    }, [primaryFixedid]);
    return (
        <div>
            {
                primaryFixedid ?

                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '30px', color: 'red', height: '200px' }
                    } >
                        <div>404 Page Not Found</div>
                    </div > : null}
        </div>
    )
}

export default NotFound