import React, { useState } from 'react'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Tooltip, Typography } from '@mui/material'
import ExperienceIcon from '../../../../../assets/home/overview-assets/experience-icon.svg'
import QualificationIcon from '../../../../../assets/home/overview-assets/qualification-icon.svg'
import CerticateIcon from '../../../../../assets/home/overview-assets/certificate.svg'
import BadgeIcon from '../../../../../assets/home/overview-assets/badge.svg'
import AchievementIcon from '../../../../../assets/home/overview-assets/achievement.svg'
import accomplishmentIcon from '../../../../../assets/home/overview-assets/accomplishment-icon.svg'
import OrgIcon from '../../../../../assets/home/organisationprofile_assets/Orgnaisationprofile1.svg'
import moment from 'moment'
import locationImg from '../../../../../assets/home/location.svg'
import mailImg from '../../../../../assets/home/mail.svg';
import callImg from '../../../../../assets/home/call.svg'
import languageImg from '../../../../../assets/home/langimage.svg'
import { useTranslation } from "react-i18next";
import { ContactEmergency } from '@mui/icons-material'




function Overview({ user }) {

    const [seemore, setSeemore] = useState(() => { return false; });
    const [qualseemore, setQualseemore] = useState(() => { return false; });
    const [t, i18n] = useTranslation("global");


    const handleDoctorsExp = (data, limit) => {
        return (
            data?.slice(0, limit).map((e, i) => (
                <Box key={i} sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 1 }}>
                    <Box>
                        {
                            e?.logo?.length > 0 ?
                                <img src={e.logo} alt="org" width={65} height={65} style={{ objectFit: 'cover', borderRadius: '5px' }} />
                                :
                                <Box >
                                    <img src={OrgIcon} alt="org" width={65} height={65} />
                                </Box>
                        }
                    </Box>
                    <Box>
                        {
                            e?.designation?.length > 0 ?
                                <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 16 }, fontWeight: 600 }}>{e.designation}</Typography>
                                :
                                <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>N/A</Typography>
                        }
                        <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 }, fontStyle: 'italic' }}>{e.organisationName || e.unofficialOrganisationName}</Typography>
                        {
                            e?.city?.length > 0 ?
                                <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{e.city}</Typography>
                                :
                                <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>N/A</Typography>
                        }
                        {
                            e?.fromDate?.length > 0 ?
                                <Box sx={{ display: 'flex' }}>
                                    {/* <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}>{e.fromDate}</Typography>
                                    {
                                        e.toDate?.length > 0 ?
                                            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}> - {e.toDate}</Typography>
                                            :
                                            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}> - Present</Typography>
                                    } */}
                                    <Typography variant="body2" sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{moment(e.fromDate).format('MMM yyyy')} - {e.toDate ? moment(e.toDate).format('MMM yyyy') : 'present'}</Typography>
                                </Box>
                                :
                                null
                        }
                    </Box>
                </Box >
            ))
        );
    };
    const handleseemore = () => {
        setSeemore(!seemore);
    };

    const handledoctorQual = (data, limit) => {
        return (
            <>
                {
                    data.slice(0, limit).map((e, i) => (
                        <Box key={i} sx={{ display: 'flex', gap: 2, alignItems: 'center', mt: 1 }}>

                            {e.logo ?
                                <img className="expImg" src={e.logo} height="56" width="56" alt="exp" />
                                :
                                <img src={OrgIcon} alt="org" width={65} height={65} />

                            }


                            <Box>
                                <Box sx={{ display: 'flex', gap: "4px" }}>
                                    <Typography>{e.code.coding[0].code}</Typography>-
                                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{e.code?.text}</Typography>
                                </Box>
                                {
                                    e?.issuer?.length > 0 ?
                                        <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{e.issuer}</Typography>
                                        :
                                        <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }} >N/A</Typography>
                                }
                                {
                                    e?.period?.start?.length > 0 ?
                                        <Box sx={{ display: 'flex' }} >
                                            {/* <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}>{moment(e.period.start).format('YYYY-MM-DD')}</Typography>
                                            {
                                                e.period?.end?.length > 0 ?
                                                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}>-{moment(e.period.end).format('YYYY-MM-DD')}</Typography>
                                                    :
                                                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 15 } }}> - Present</Typography>
                                            } */}

                                            <Typography variant="body2" sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>{moment(e.fromDate).format('MMM yyyy')} - {e.toDate ? moment(e.toDate).format('MMM yyyy') : 'present'}</Typography>

                                        </Box>
                                        :
                                        null
                                }
                            </Box>
                        </Box>
                    ))
                }
            </>
        );
    };
    const handleQualseemore = () => {
        setQualseemore(!qualseemore);
    };

    return (
        <Box>
            <div>
                <Typography sx={{ color: "#0C9CD7", fontWeight: "600" }}>
                    {t("overview.about")}
                </Typography>
            </div>
            <div style={{ marginTop: "2px" }}>
                {user?.about?.length > 0 ? (
                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>
                        {user?.about}
                    </Typography>
                ) : (
                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>
                        N/A
                    </Typography>
                )}
            </div>

            <Divider sx={{ mt: 2 }} />

            {/* <Box mt={2} sx={{ display: 'flex', gap: 2 }}> */}

            <div
                style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <img src={ExperienceIcon} alt="stethoscope" />
                    <Typography sx={{ color: "#0C9CD7", fontWeight: "600" }}>
                        {t("overview.experience")}{" "}
                    </Typography>
                </div>
            </div>
            {/* </Box> */}
            {/* <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 }, mt: 1 }}>
                 {t("overview.experience")}
            </Typography> */}
            <Box mt={1}>
                {user?.experience?.length > 0 ? (
                    <>
                        {handleDoctorsExp(
                            user.experience,
                            seemore == false ? 3 : user.experience.length
                        )}
                    </>
                ) : (
                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>
                        {t("overview.notmention")}
                    </Typography>
                )}
                {user?.experience?.length > 3 ? (
                    <Box onClick={handleseemore}>
                        <Typography>
                            {" "}
                            {seemore == false ? (
                                <Typography>{t("overview.seemore")}</Typography>
                            ) : (
                                <Typography>{t("overview.seeless")}</Typography>
                            )}{" "}
                        </Typography>
                    </Box>
                ) : null}
            </Box>
            <Divider sx={{ mt: 2 }} />
            <div
                style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <img src={QualificationIcon} alt="stethoscope" />
                    <Typography sx={{ color: "#0C9CD7", fontWeight: 600 }}>
                        {t("overview.qualification")}
                    </Typography>
                </div>
            </div>
            <Box mt={1}>
                {user?.qualification?.length > 0 ? (
                    <>
                        {handledoctorQual(
                            user.qualification,
                            qualseemore == false ? 3 : qualseemore.length
                        )}
                    </>
                ) : (
                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 } }}>
                        {t("overview.notmention")}
                    </Typography>
                )}
                {user?.qualification?.length > 3 ? (
                    <Box onClick={handleQualseemore}>
                        <Typography>
                            {" "}
                            {qualseemore == false ? (
                                <Typography>{t("overview.seemore")}</Typography>
                            ) : (
                                <Typography>{t("overview.seeless")}</Typography>
                            )}{" "}
                        </Typography>
                    </Box>
                ) : null}
                {/* {user?.qualification[0]?.code?.Typography ?
                    <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 }, mt: 1 }}>
                        {user?.qualification[0]?.code?.Typography}
                    </Typography> : <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 14 }, mt: 1 }}>
                        N/A
                    </Typography>} */}
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box mt={2} sx={{ display: "flex", gap: 2 }}>
                <img src={accomplishmentIcon} alt="experience" />
                <Typography sx={{ color: "#007DCD", fontWeight: 600 }}>
                    {t("overview.accomplishments")}
                </Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-around", mt: 1 }}>
                <Box sx={{ textAlign: "center" }}>
                    <img src={CerticateIcon} alt="certificate" />
                    <Typography sx={{ textAlign: "center" }}>
                        {user?.certificatesCount}
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                        {t("overview.certifications")}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                    <img src={BadgeIcon} alt="achievement" />
                    <Typography sx={{ textAlign: "center" }}>
                        {user?.achievementsCount}
                    </Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                        {t("overview.cme")}
                    </Typography>
                </Box>
                <Box sx={{ textAlign: "center" }}>
                    <img src={AchievementIcon} alt="accomplishment" />
                    <Typography>{user?.accomplishmentCount}</Typography>
                    <Typography sx={{ fontSize: "13px" }}>
                        {t("overview.achievements")}
                    </Typography>
                </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box mt={2}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <ContactEmergency sx={{ color: '#0094D4' }} />
                    <Typography sx={{ color: "#007DCD", fontWeight: 600, }}>
                        {t("overview.contact")}
                    </Typography>
                </Box>

                <Box sx={{ display: "flex", alignItems: "center", gap: 2, mt: 1 }}>
                    <img src={locationImg} alt="location" />
                    <Typography sx={{ fontSize: '14px', ml: '3px' }}>India</Typography>
                </Box>

                <Box
                    sx={{ display: "flex", alignItems: "center", gap: 2, mt: "5px" }}
                >
                    <img src={mailImg} alt="mail" />
                    <Typography sx={{ fontSize: '14px' }}>Privacy Protected</Typography>
                </Box>
                <Box
                    sx={{ display: "flex", alignItems: "center", gap: 2, mt: "5px" }}
                >
                    <img src={callImg} alt="mail" />
                    <Typography sx={{ fontSize: '14px' }}>Privacy Protected</Typography>
                </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />
            <Box mt={2} sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                <Box>
                    <img src={languageImg} alt="location" />
                </Box>
                <div>
                    <Typography sx={{ color: "#007DCD", fontWeight: 600, mt: 1 }}>
                        {t("overview.communictn")}
                    </Typography>

                    <Typography sx={{ fontSize: '14px' }}>English</Typography>
                </div>
            </Box>
        </Box>
    );
}

export default Overview