
import React from 'react'
import { Button, Typography } from '@mui/material';

const ArticleView = ({ docFeed }) => {
    const articleData = docFeed.articleData;
    return (
        <div
            style={{
                border: "1px solid lightgrey",
                color: "black",
                margin: "10px",
            }}
        >
            {docFeed?.articleData?.coAuthors ? (
                <Typography sx={{ p: "5px 10px", fontSize: "14px" }}>
                    Co-Authors:{" "}
                    <span
                        dangerouslySetInnerHTML={{
                            __html: docFeed.articleData.coAuthors,
                        }}
                    />
                </Typography>
            ) : (
                ""
            )}
            {docFeed?.articleData?.contributors ? (
                <Typography sx={{ p: "0px 10px 5px 10px", fontSize: "14px" }}>
                    Contributors:{" "}
                    <span
                        dangerouslySetInnerHTML={{
                            __html: docFeed.articleData.contributors,
                        }}
                    />
                </Typography>
            ) : (
                ""
            )}
            <div>
                <div
                    style={{
                        maxHeight: "300px",
                        overflow: "hidden",
                        position: "relative",
                    }}
                >
                    {docFeed.sourceLink !== null &&
                        docFeed.sourceLink !== "" &&
                        docFeed.sourceLink.length > 0 ? (
                        <img
                            src={docFeed.sourceLink}
                            alt="cover"
                            style={{
                                maxHeight: "100%",
                                maxWidth: "100%",
                                objectFit: "cover",
                                width: "100%",
                            }}
                        />
                    ) : (
                        <div
                            style={{ fontFamily: "inherit", padding: "10px" }}
                            className="article-para"
                            dangerouslySetInnerHTML={{
                                __html: articleData?.articleDescription,
                            }}
                        />
                    )}
                </div>
            </div>
            {docFeed?.articleData?.articleTitle !== null ? (
                <a href={`/viewarticle/${docFeed.id}`}>
                    <div
                        style={{
                            padding: "15px 20px",
                            backgroundColor: "#e8e8e0",
                            display: "flex",
                            justifyContent: "space-between",
                        }}
                        className="d-flex cursor"
                    >
                        {articleData?.articleTitle ? (
                            <p style={{ overflow: "hidden", flex: 1 }}>
                                <Typography
                                    className="font-bold capitalize add-cover text-ellipsis"
                                    style={{ pt: 1, fontSize: "20px" }}
                                >
                                    {articleData.articleTitle}
                                </Typography>
                            </p>
                        ) : null}

                        <Button
                            variant="outlined" sx={{ py: "3px!important" }}>
                            Read Article
                        </Button>
                    </div>
                </a>
            ) : null}
        </div>
    );
};

export default ArticleView