import React from 'react';
import { Button, Grid, Avatar, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Call } from '@mui/icons-material';

const NavButtonStyled = styled(Button)(({ theme }) => ({
    "&.MuiButton-root": {
        borderColor: "#59D8FA",
        borderRadius: "10px",
        borderWidth: "3px",
        color: "#3D6596",
        fontSize: "14px",
        padding: "0px",
    },
    [theme.breakpoints.down('md')]: {
        "&.MuiButton-root": {
            fontSize: "13px",
        },
    },
}));

const GridNavButton = ({ title, phoneNumber, color, size, variant, onClick, style, imageUrl }) => {
    return (
        <NavButtonStyled variant={variant} color={color} disableElevation={true} disableRipple={true} size={size} onClick={onClick} style={style}>
            <Grid container>
                <Grid item xs={1} sm={1} md={1}></Grid>
                <Grid item sm={1.5} md={1.5} mt={1} >
                    <Call sx={{ color: '#3D6596' }} />
                </Grid>
                <Grid item xs={9} sm={9.5} md={9.5}>
                    <Box sx={{ ml: "13px" }}>
                        <Typography sx={{
                            fontSize: '14px', color: '#3D6596', fontWeight: 600,
                            textAlign: 'left'
                        }}>
                            {title}
                        </Typography>
                        <Typography sx={{
                            fontSize: { xs: "10px", md: '12px' }, color: '#5B7DA6', textAlign: 'left'
                        }}>
                            {phoneNumber}
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </NavButtonStyled >
    );
};

export default GridNavButton;
