import React, { useEffect } from 'react'
import { setLanguage } from "../../redux/Actions/languageActions";
import { Box, Button, MenuItem, Select, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import i18next from 'i18next';

function SelectLanguage() {
    const dispatch = useDispatch()
    const selectedLang = useSelector((state) => state.language.selectedLanguage);

    const handleChangeLanguage = (e) => {
        // setSelectedLanguage (e);
        // sessionStorage.setItem('language', e)

        // i18n.changeLanguage(sessionStorage.getItem('language'));

        // new code
        dispatch(setLanguage(e));
        sessionStorage.setItem("language", e);
        i18next.changeLanguage(sessionStorage.getItem('language'));
    }
    useEffect(() => {
        if (sessionStorage.getItem("language")) {
            dispatch(setLanguage(sessionStorage.getItem("language")));
            i18next.changeLanguage(sessionStorage.getItem("language"));
        } else {
            dispatch(setLanguage("en"));
            sessionStorage.setItem("language", "en");
        }

    }, [selectedLang])
    return (
        <div>
            <Select
                sx={{
                    ".MuiOutlinedInput-input":
                        { p: "5px 5px", border: '1px solid blueviolet' },
                    color: '#8A2BE2',
                    // border: '1px solid blueviolet'
                }}
                // value={selectedLanguage}
                value={selectedLang}
            >
                <MenuItem value="en" onClick={() => handleChangeLanguage("en")}>
                    English
                </MenuItem>
                <MenuItem value="hi" onClick={() => handleChangeLanguage("hi")}>
                    हिन्दी
                </MenuItem>
                <MenuItem value="te" onClick={() => handleChangeLanguage("te")}>
                    తెలుగు
                </MenuItem>
            </Select>
        </div>
    )
}

export default SelectLanguage