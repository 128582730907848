import React, { forwardRef, useEffect, useState } from 'react';

const PinInputField = forwardRef((props, ref) => {
    const { onChange, name, value, key, onKeyDown } = props
    // console.log(ref, "prrrrrr")
    useEffect(() => {
        // When the component mounts, set the cursor position to the beginning
        if (ref.current) {
            ref.current.selectionStart = 0;
            ref.current.selectionEnd = 0;
        }
    }, [ref]);
    return (
        // <input
        //     key={index}
        //     type="text"
        //     name={name}
        //     ref={ref}
        //     maxLength={1}
        //     style={{
        //         width: '30px',
        //         outline: 'none',
        //         borderTop: '0px',
        //         borderLeft: '0px',
        //         borderRight: '0px',
        //         textAlign: 'center',
        //     }}
        //     value={value}
        //     onChange={onChange}
        // />
        <input
            key={key}
            type="text"
            name={name}
            maxLength="1"
            // className="otp-input"
            value={value}
            onChange={onChange}
            onKeyDown={
                onKeyDown}
            // autoFocus
            ref={ref}
            style={{
                width: '30px',
                outline: 'none',
                borderTop: '0px',
                borderLeft: '0px',
                borderRight: '0px',
                textAlign: 'center',
            }}
        />
    );

})

export default PinInputField;
