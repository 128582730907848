import React, { useState } from 'react';
import { Avatar, Box, Button, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import URL from '../../../utility/apiurl';
import axios from 'axios';
import { Person, Public } from '@mui/icons-material';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import CommonCalender from '../../atoms/CommonCalender';
import dayjs from 'dayjs';
import CommonButton from '../../atoms/CommonButton';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';
import SelectLanguage from '../../atoms/SelectLanguage';
import CommonDesign from '../../atoms/CommonDesign';
import SignupMobile from './SignupMobile';
import SignupOtp from './SignupOtp';
import AlreadyAccount from '../../atoms/AlreadyAccount';

function CompleteProfile() {
    const [alertopen, setAlertopen] = useState(false);
    const [alertstatus, setAlertstatus] = useState('');
    const [alertmessage, setAlertmessage] = useState('');
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [uploadpersonal, setUploadperonal] = useState(false)
    const [name, setName] = useState("")
    const [filename, setFilename] = useState("")
    const [open, setOpen] = React.useState(false);
    const [edit, setEdit] = useState(false)
    const [edit1, setEdit1] = useState(false)
    const [filesize, setFilesize] = useState(0)
    const [filetype, setFiletype] = useState("")
    const [image, setImage] = useState("")
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [uploadprofessional, setUploadprofessional] = useState(false)
    const [show, setShow] = useState(1)
    const [t, i18n] = useTranslation("global");

    const [birth, setBirth] = useState('');

    const [details, setDetails] = useState({
        firstname: '',
        lastname: '',
        error: {
            firstname: ''
        }
    })

    const { error, firstname, lastname } = details
    // console.log(details, "detailss");
    // console.log(t("credentialsData.completeprofile.genderdata"),"00000000");
    const genderdata = [

        { type: "Male" },
        { type: "Female" },
        { type: "Other" }

    ];
    // console.log(genderdata, "hello");
    let pdata = useSelector(state => state.medx.addpatient);
    const alldata = useSelector((state) => state.medx.allData)
    const [gender, setGender] = useState('Male');
    const handleGender = (selectedGender) => {
        setGender(selectedGender);
        setAlertmessage('')

    };

    const handleOnChangeName = (e) => {
        details[e.target.name] = e.target.value
        error[e.target.name] = ""
        setDetails({ ...details, error: error })
        setAlertmessage('')
    }

    const handleOnChangeDate = (newValue) => {
        setBirth(newValue?.format("YYYY-MM-DD"))
        setAlertmessage('')
        setLoading('')
    }


    const imageHandler = (e, imgFile) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            const fsize = e.target.files[0].size
            const filename = e.target.files[0].name
            console.log(filename)
            let type = e.target.files[0].type.split("/");
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = x => {
                let file = [];
                file.push(reader.result.split(',')[1])
                handleimagesubmit(file, type[1], fsize, filename, imgFile)
            }
        }
    }
    const handleimagesubmit = (Image, type, fsize, filename, imgFile) => {
        let data = {
            "file": Image,
            "fileType": type,
            "multiple": false,
            "subType": "profilePic",
            "fileSize": fsize,
            "type": "userProfilePics"
        }
        console.log(data)
        // let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.post(`${URL.uploadfile}`, data,).then(res => {
            console.log(res?.data?.data, "resphoto")
            if (res.data.status === 200) {
                setAlert({ open: true, msg: "Uploaded Succesfully" });
                setImage(res.data.data[0])
                setFilesize(fsize)
                setFiletype(type)
                if (imgFile === 'personal') {
                    // console.log("personal")
                    setName(filename)
                    setUploadperonal(true)
                }
                if (imgFile === 'professional') {
                    setFilename(filename)
                    setUploadprofessional(true)
                }
            }
        })
    }


    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        if (!details.firstname) {
            error.firstname = "First name is required"
        }
        setDetails({ ...details, error })
        if (!error.firstname) {

            let profiledata = {
                firstname: firstname, lastname: lastname, dateofbirth: birth, image: image,
                gender: gender
                // gender: {gender === "మగ" ? "male" : gender === "ఆడ" ? "female" : gender === "ఇతర"}

            }

            dispatch({
                type: 'COMPLETE_PROFILE_DATA',
                payload: profiledata
            })
            setShow(2)
            setLoading(false);
        }
    }
    return (
        <>
            <Box sx={{ height: { sm: '0px', md: "3px" } }}></Box>
            <CommonDesign>
                <Box
                    sx={{
                        mt: { xs: 14, sm: 14, md: 2 },
                        px: { xs: 3, sm: 4, md: 4 },

                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: 1, color: '#8A2BE2' }}>
                            <Public />
                            <Typography sx={{ fontWeight: 600, fontSize: 17 }}>India</Typography>

                        </Box>
                        <SelectLanguage />
                    </Box>
                    <Box sx={{
                        mx: { xs: 2, sm: 4, md: 4 }, overflowY: 'scroll', height: "420px", '&::-webkit-scrollbar': {
                            display: 'none',

                        }
                    }}>

                        {show === 1 ? (
                            <>
                                <Box sx={{
                                    mt: 2,
                                }}
                                >
                                    <Typography
                                        sx={{ fontSize: { xs: 17, md: 17 }, fontWeight: 600 }}

                                    >
                                        {t("credentialsData.completeprofile.heading")}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        sx={{ mt: "5px", color: "gray", fontSize: { xs: 12, md: 14 } }}
                                    >
                                        {t("credentialsData.completeprofile.subtxt")}
                                    </Typography>
                                </Box>


                                <Box sx={{ display: "flex", justifyContent: "center", mt: { sm: 1, xs: 1, md: 0 } }}>
                                    <div>
                                        <input
                                            type="file"
                                            name="uploadfile"
                                            multiple
                                            id="post-img2"
                                            accept=".png, .jpg, .jpeg, .pdf"
                                            style={{ display: "none" }}
                                            onChange={(e) => imageHandler(e, "personal")}
                                            autoFocus
                                        />
                                        <label htmlFor="post-img2" className="cursor">
                                            <div className="inputOption" style={{ display: "flex" }}>
                                                <Box
                                                    sx={{
                                                        width: "55px",
                                                        height: "45px",
                                                        position: "relative",
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        bgcolor: "#9560D9",
                                                        borderRadius: "10px",
                                                    }}
                                                >
                                                    {image ? (
                                                        <img
                                                            src={image}
                                                            alt="media"
                                                            style={{
                                                                objectFit: "cover",
                                                                width: "100%",
                                                                height: "100%",
                                                                cursor: "pointer",
                                                                borderRadius: "10px",
                                                            }}
                                                        />
                                                    ) : (
                                                        <Person sx={{ fontSize: "25px", cursor: "pointer" }} />
                                                    )}
                                                    {/* <div className='upload_text'>Upload File</div> */}
                                                    <Typography
                                                        sx={{
                                                            position: "absolute",
                                                            color: "white",
                                                            top: 24,
                                                            cursor: "pointer",
                                                            fontSize: "13px",
                                                            backgroundColor: "#0005",
                                                            color: "#fff",
                                                            borderBottomRightRadius: "10px",
                                                            borderBottomLeftRadius: "10px",
                                                            bottom: 0,
                                                            width: "55px",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        {t("credentialsData.createaccount.upload")}
                                                    </Typography>
                                                </Box>
                                            </div>
                                        </label>
                                    </div>
                                </Box>

                                <Box sx={{ mt: "20px" }}>
                                    <ColorTextField label="First Name" placeholder='Enter First Name' value={details.firstname} name="firstname" type="text" onChange={handleOnChangeName} />
                                </Box>
                                {error.firstname ? (
                                    <Typography sx={{ color: "red" }}>{error.firstname}</Typography>
                                ) : null}
                                <Box sx={{ mt: "20px" }}>
                                    <ColorTextField label="Last Name" placeholder='Enter Last Name' value={details.lastname} name="lastname" type="text" onChange={handleOnChangeName} />
                                </Box>
                                <Box sx={{ mt: 1 }}>
                                    <CommonCalender value={dayjs(birth)}
                                        onChange={handleOnChangeDate} maxDate={dayjs()} width="100%"
                                    />
                                </Box>

                                <div>
                                    <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
                                        <span>{t("credentialsData.completeprofile.selectgender")}</span>
                                    </Typography>

                                    <Box
                                        display={"flex"}
                                        justifyContent="space-around"
                                        sx={{
                                            border: "1px solid gray",
                                            borderRadius: "15px",
                                            p: "4px",
                                            backgroundColor: "whitesmoke",
                                        }}
                                    >
                                        {genderdata?.map((gen, i) => (
                                            <Button
                                                sx={{
                                                    height: "30px",
                                                    textTransform: "capitalize",
                                                    backgroundColor: "white",
                                                    color: gender === gen.type ? "black" : "gray",
                                                    "&:hover": {
                                                        bgcolor: "white",
                                                    },
                                                    bgcolor: gender === gen.type ? "white" : "whitesmoke",
                                                }}
                                                key={i}
                                                variant={gender === gen.type ? "contained" : "text"}
                                                onClick={() => handleGender(gen.type)}
                                            >
                                                {gen?.type}
                                            </Button>
                                        ))}
                                    </Box>
                                </div>
                                {alertmessage &&
                                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                }
                                <Box sx={{ mt: { sm: 2, md: 0, xs: 2 } }} container textAlign="center">
                                    <CommonButton

                                        width="150px"
                                        bgcolor="blueviolet"
                                        text="Next"
                                        disabled={!firstname || !lastname || !gender || !birth}

                                        // startIcon={loading ? <CircularProgress size={20} /> : null}
                                        onClick={handleSubmit}
                                    />
                                </Box >
                            </>
                        ) : show === 2 ? (
                            <SignupMobile setShow={setShow} />
                        ) : show === 3 ? (
                            <SignupOtp setShow={setShow} />
                        ) : null}
                        <Box sx={{ mb: { xs: 5, sm: 5, md: 5 } }}></Box>
                    </Box>
                    <AlreadyAccount />
                </Box>
            </CommonDesign >
        </>
    );
}
export default CompleteProfile;
