import React, { useEffect, useState } from 'react'
import { Box, Card, IconButton, Typography } from '@mui/material'
import medxpertLogo from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import { ArrowForwardIos, Close, RemoveCircleOutline, SettingsApplications, TaskAltOutlined, TaskOutlined, VerifiedUser } from '@mui/icons-material'
import { OutlinedButton } from '../../../atoms/CommonButton'
import ActiveSubcriptions from './ActiveSubscriptions'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import URL from '../../../../utility/apiurl'
import { localstore } from '../../../../localStore/localStore'
import { useSelector } from 'react-redux'
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat'
import Authorizations from './Authorizations'

function MyhealthLocker({ lockerNamedata, autoApprovals, phraddress, handleenableapproval, subscription, authorization, handledisableapproval, alertmessage }) {
    const navigate = useNavigate()
    const [openApprove, setOpenApprove] = useState(false)
    const [openKyc, setOpenKyc] = useState(false)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const [displayAlert, setDisplayAlert] = useState(false);

    useEffect(() => {
        if (alertmessage) {
            setDisplayAlert(true);

            const timer = setTimeout(() => {
                setDisplayAlert(false);
            }, 2000);

            return () => clearTimeout(timer);
        }
    }, [alertmessage]);
    return (
        <div>
            {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '0px 10px' }}>
                <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>My Health Locker</Typography>
                <IconButton onClick={() => navigate('/healthlocker')}><Close sx={{ color: 'red' }} /></IconButton>
            </Box> */}

            <Box >
                <Box sx={{ p: "0px 10px" }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <VerifiedUser sx={{ color: '#0C9D00' }} />
                            <Typography sx={{ color: '#60BE58' }}>Active</Typography>
                        </Box>
                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                            {DateFormat(lockerNamedata.dateCreated, 'DateTime')}
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            <img src={medxpertLogo} alt="medxperts" width={50} height={50} />
                            <Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>Medxperts Health Locker</Typography>
                                <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>Subscription,Authorization,Auto-Approval</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 1 }}>
                    <SettingsApplications sx={{ fontSize: '30px' }} />
                    <Typography sx={{ fontSize: '17px', fontWeight: 400 }}> Health-Locker Preferences</Typography>
                </Box>
                <Card sx={{ mt: '10px', p: "10px 20px 15px 20px " }} onClick={() => setOpenApprove(true)}>
                    <Typography sx={{ fontSize: '14px' }}>Subcription</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Approved</Typography>
                        <IconButton sx={{ bgcolor: '#B7D5F9', '&:hover': { bgcolor: '#B7D5F9' } }}> <ArrowForwardIos sx={{ color: '#2580EC' }} /></IconButton>
                    </Box>
                </Card>
                <Card sx={{ mt: '10px', p: "10px 20px 15px 20px ", cursor: 'pointer' }} onClick={() => setOpenKyc(true)}>
                    <Typography >Authorization</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>KYC and LINK</Typography>
                        <IconButton sx={{ bgcolor: '#B7D5F9', '&:hover': { bgcolor: '#B7D5F9' } }}>   <ArrowForwardIos sx={{ color: '#2580EC' }} /></IconButton>
                    </Box>
                </Card>
                <Card sx={{ mt: '10px', p: "10px 20px 20px 20px", cursor: 'pointer' }} >
                    <Typography sx={{ fontSize: '14px' }}>Auto Approval Policy</Typography>
                    <Typography sx={{ fontSize: '14px', mt: 1, fontWeight: 600 }}>Consent Auto-approval</Typography>
                    <Typography sx={{ fontSize: '14px', mt: 1 }} component={'p'}>If enables Health locker can access & store your data.Consent Request from Health locker will be Automatically approved</Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '14px' }} component={'p'}>You can change it anytime,but disabling it may Effect the locker performance</Typography>
                        {autoApprovals?.active === false ?
                            <OutlinedButton text=" DISABLE" border='1px solid red' color="red" height="32px" startIcon={<RemoveCircleOutline sx={{ color: 'red' }} />} onClick={() => handleenableapproval(token, primaryId, phraddress, autoApprovals?.id)} /> :

                            < OutlinedButton text=" ENABLE" border='1px solid #247FEA' color="#247FEA" height="32px" startIcon={<TaskAltOutlined sx={{ color: '#247FEA' }} />} onClick={() => handledisableapproval(token, primaryId, phraddress, autoApprovals?.id)} />
                        }
                    </Box>
                    {displayAlert && (
                        <Typography
                            sx={{
                                mt: 2,
                                p: '10px',
                                color: alertmessage ? 'green' : 'red', // Green for success, red for error
                                // color: '#fff',
                                borderRadius: '4px',
                            }}
                        >
                            {alertmessage}
                        </Typography>
                    )}
                </Card>
                <ActiveSubcriptions open={openApprove} setOpen={setOpenApprove} lockerNamedata={lockerNamedata}
                    subscription={subscription}
                    token={token}
                    primaryId={primaryId}
                    phraddress={phraddress} />
                <Authorizations open={openKyc} setOpen={setOpenKyc}
                    authorization={authorization}
                    lockerNamedata={lockerNamedata}
                    token={token}
                    primaryId={primaryId}
                    phraddress={phraddress} />
            </Box>
        </div >
    )
}

export default MyhealthLocker;


