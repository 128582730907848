import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ArrowDropDown, ArrowDropUp, Check, ExpandLess, ExpandMore, Pending, RemoveCircleOutline, Security, TaskAlt } from '@mui/icons-material';
import { Box, Card, Checkbox, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import consentIcon from '../../../../assets/home/consent_module/consent.svg'
import EditIcon from '../../../../assets/home/consent_module/edit.svg'
import hospitalIcon from '../../../../assets/home/consent_module/medxperts.svg'
import prescriptionIcon from '../../../../assets/home/consent_module/prescription.svg';
import consultationIcon from '../../../../assets/home/consent_module/consultation.svg';
import diagnosticIcon from '../../../../assets/home/consent_module/diagnostic.svg';
import dischargeIcon from '../../../../assets/home/consent_module/discharge.svg';
import immunisationIcon from '../../../../assets/home/consent_module/immunisation.svg';
import documentIcon from '../../../../assets/home/consent_module/document.svg';
import WellnessRecordIcon from '../../../../assets/home/consent_module/lifestyle.svg';
import moment from 'moment';
import { useEffect } from 'react';
import { getLinkedProviders } from '../../../../redux/Actions/recordActions';

function ConsentRequestDeny(props) {
    const { date, time, expirydate, expirytime, item, hospital } = props
    const [loading, setLoading] = useState(false)
    const handleCancel = () => {

    }

    return (
        <div>   <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                    <img src={consentIcon} alt='consent' />
                    <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 18 }, fontWeight: 600 }}>Conform Requests</Typography>
                </Box>
                <Button sx={{ height: "25px", color: 'black', borderRadius: "15px" }} variant='outlined' onClick={handleCancel}>Cancel</Button>
            </DialogTitle>
            <Divider />

            <DialogContent sx={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '16px' } }} color="text.secondary" gutterBottom>
                            Health Locker
                        </Typography>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', }}>
                            {hospital.orgImg ?
                                <img src={hospital.orgImg} alt="orgimg" width={45} height={45} style={{ borderRadius: '5px', marginLeft: "12px" }} />
                                :
                                <img src={hospitalIcon} alt="hospital" style={{ width: 70, height: 70 }} />
                            }
                            <Box>
                                {hospital?.identifier?.name ?
                                    <Typography sx={{ fontSize: { xs: '16px', sm: '17px', md: '17px' }, fontWeight: 600 }}>{hospital?.identifier?.name}</Typography> : <Typography sx={{ fontSize: { xs: '13px', sm: '18px', md: '17px' }, fontWeight: 500 }}>Medxperts Health Locker</Typography>}

                                <Typography sx={{ mt: "2px", fontSize: { xs: '13px', sm: '14px', md: '15px' } }}>{item?.purpose?.text}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 15 }, fontWeight: 600 }}>Requested On</Typography>
                        <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }} >
                            {date} | {time}
                        </Typography>
                        <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 15 }, fontWeight: 600, mt: 1 }}>Consent Expiry </Typography>
                        <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }} >
                            {expirydate} | {expirytime}
                        </Typography>
                    </Box>
                </Box>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                    <Checkbox defaultChecked />
                    <Typography sx={{ fontSize: { xs: "12px", sm: 12, md: 14 }, mt: 1 }}>Allowed Medxperts to automatically Fetch and store my Health Records (subscription)</Typography>
                </Box>

                <Typography sx={{ color: 'black', mt: 1, fontSize: { xs: 14, sm: 15, md: 16 } }}>Information Type Requested</Typography>

                {/* <InformationTypeConsent item={item} selectedCheckboxes={selectedCheckboxes} onSelectedItemsChange={handleDropdownSelect} onUnSelectedItemsChange={handleDropdownUnSelect} /> */}

                <Typography sx={{ color: 'black', mt: 2, fontSize: { xs: 14, sm: 15, md: 16 } }}>Information Source</Typography>
                {/* <InformationSourceConsent linkedHospitals={linkeddata} item={item} /> */}


                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                    <Checkbox defaultChecked />
                    <Typography sx={{ fontSize: { xs: "12px", sm: 12, md: 14 }, }}>Automatically grant Consent request for MedXperts (consent auto-approval)</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 1 }}>
                    <Checkbox defaultChecked />
                    <Typography sx={{ fontSize: { xs: "12px", sm: 12, md: 13.5 }, }}>Allow Health Records from MedXperts to be linked  authorise MedXperts as HIP</Typography>
                </Box>


            </DialogContent>
            <Box mb={4} sx={{ display: 'flex', justifyContent: "end", gap: 2, mr: 2 }}>
                <Button startIcon={loading ? <CircularProgress sx={{ color: "white" }} size={20} /> : <TaskAlt />} variant="contained" sx={{ bgcolor: '#247FEA', gap: 1, width: "120px", borderRadius: 2 }}>
                    Approve
                </Button>
            </Box>
        </></div>
    )
}

export default ConsentRequestDeny