import React, { useRef } from 'react'
import { Box, CardMedia, Typography } from '@mui/material'
import { useLocation } from 'react-router-dom'
import { FileDownloadOutlined, ShareOutlined } from '@mui/icons-material'
import { OutlinedButton } from '../../atoms/CommonButton'

function DownloadAbhacard() {
    const location = useLocation()
    const abhacard = location?.state?.abhacard
    const downloadLinkRef = useRef(null);

    const handleProfileDownload = () => {
        const element = downloadLinkRef.current;
        element.href = `data:image/png;base64,${abhacard}`;
        element.download = 'abhacard.png';
        element.click();
    };

    const handleProfileShare = async () => {
        try {
            if (navigator.share) {
                // Convert base64 to Blob before sharing
                const blob = await fetch(`data:image/png;base64,${abhacard}`).then((res) => res.blob());
                await navigator.share({
                    title: 'Share Ayshuman Bharat Health Account',
                    files: [new File([blob], 'abhacard.png', { type: 'image/png' })],
                });
            } else {
                alert('Web Share API is not supported in this browser');
            }
        } catch (error) {
            console.error('Error sharing:', error);
        }
    };

    // const handleProfileDowload = () => {
    //     const byteCharacters = atob(abhacard);
    //     const byteNumbers = new Array(byteCharacters.length);
    //     for (let i = 0; i < byteCharacters.length; i++) {
    //         byteNumbers[i] = byteCharacters.charCodeAt(i);
    //     }
    //     const byteArray = new Uint8Array(byteNumbers);
    //     const blob = new Blob([byteArray], { type: 'image/png' }); // Specify the MIME type for PNG

    //     // Create a download link and trigger the download
    //     const downloadLink = document.createElement('a');
    //     downloadLink.href = URL.createObjectURL(blob);
    //     downloadLink.download = 'abhacard.png';
    //     document.body.appendChild(downloadLink);
    //     downloadLink.click();
    //     document.body.removeChild(downloadLink);
    // }

    // const handleProfileShare = async () => {
    //     try {
    //         // Check if the Web Share API is available and if the page is served over HTTPS
    //         if (navigator.share && window.isSecureContext) {
    //             // Use the Web Share API to share the image URL
    //             await navigator.share({
    //                 title: 'Ayshuman Bharat Health Account',
    //                 text: 'Check out this Ayshuman Bharat Health Account!',
    //                 url: `data:image/png;base64,${abhacard}`,
    //             });
    //         } else {
    //             // Fallback for browsers that do not support the Web Share API or for non-HTTPS contexts
    //             alert('Web Share API is not supported in this browser or context');
    //         }
    //     } catch (error) {
    //         console.error('Error sharing:', error);
    //     }
    // };

    return (
        <Box sx={{ mt: { md: 2, xs: 9.5, sm: 11 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 300px' }, pt: { xs: '0px', sm: "5px", md: '50px' }, mb: { xs: 10, sm: 10, md: 5 } }}>
            <Typography sx={{ fontWeight: 600, fontSize: '17px', textAlign: 'center' }}>
                Ayshuman Bharat Health Account
            </Typography>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <img src={abhacard} alt="abha" width={1000} height={500} />
            </Box> */}
            <Box sx={{ maxWidth: { xs: 500, md: 1000 }, mt: 2 }} >
                <CardMedia
                    sx={{ height: '600px' }}
                    image={`data:image/png;base64,${abhacard}`}
                    title="green iguana"
                />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', gap: 2, mt: '15px' }}>
                <a ref={downloadLinkRef} style={{ display: 'none' }} />
                <OutlinedButton text="Download" width="130px" borderColor="#9560D9" color="#9560D9" startIcon={<FileDownloadOutlined />} onClick={handleProfileDownload} />
                <OutlinedButton text="Share" width="130px" borderColor="#9560D9" color="#9560D9" startIcon={<ShareOutlined />} onClick={handleProfileShare} />
            </Box>
        </Box>
    );
}

export default DownloadAbhacard;
