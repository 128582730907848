import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import global_en from "./translation/en/global.json";
import global_te from "./translation/te/global.json";
import global_hi from "./translation/hi/global.json";
import i18next from "i18next";
import { I18nextProvider } from "react-i18next";

// import reportWebVitals from './reportWebVitals';

i18next.init({
  interpolation: { escapeValue: false },
  lng: "en",
  resources: {
    en: {
      global: global_en,
    },
    te: {
      global: global_te,
    },
    hi: {
      global: global_hi,
    },

  },
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <App />
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
