import React from 'react'
import { ArrowForwardIos, Call } from '@mui/icons-material'
import { Card, Grid, IconButton, Paper, Typography } from '@mui/material'
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from "react-i18next";

function HealthIdNumber() {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()

    return (
        <Paper elevation={3}
            sx={{
                p: "10px 15px",
                borderRadius: '10px'
            }}
        >
            <Grid
                item
                container
                spacing={2}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/loginabhanumber")}
            >
                <Grid item xs={1.5} sm={1} md={1}>
                    <img src={Abhalogo} alt="abha" width={25} height={25} />
                </Grid>
                <Grid item xs={9} sm={10} md={10}>
                    <Typography
                        sx={{
                            fontSize: { xs: 14, sm: 15, md: 16 },
                            fontWeight: 600,
                        }}
                        varaint="h1"
                    >
                        {t("abhahidnum")}
                    </Typography>
                    <Typography
                        sx={{
                            mt: "5px",
                            fontSize: { xs: 11, sm: 12, md: 12 },
                            fontWeight: 400
                        }}
                    >
                        {t("logintxt")}
                    </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1} md={1}>
                    <IconButton>
                        <ArrowForwardIos sx={{ color: "#2891D5" }} />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default HealthIdNumber