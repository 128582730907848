import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import medxpertIcon from '../../../assets/home/medxpertIcon.svg'
import { CircularProgress, TextField } from '@mui/material';
import axios from 'axios';
import URL from '../../../utility/apiurl';
import { useTranslation } from "react-i18next";
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import CommonButton from '../../atoms/CommonButton';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';

function ForgotPasswordOtp(props) {
    const [otp, setOtp] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [timer, setTimer] = useState(null);
    const [time, setTime] = useState(59);
    const [loading, setLoading] = useState(false)
    const [t, i18n] = useTranslation("global");

    const handleChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 5));
        if (!/^\d+$/.test(e.target.value)) {
            setAlertmessage('Only numbers are allowed.');
        } else {
            setAlertmessage('');
        }
    }

    useEffect(() => {
        // Clear the timer when the component is unmounted
        setTime(59);

        return () => {
            clearInterval(timer);
        };

    }, [timer]);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    const handleverifyOtp = () => {
        // props.setShow(3)
        setLoading(true)
        let data = {
            'phoneNumber': `${'+91'}${props.phoneNumber}`,
            'otp': otp,
            'email': '',
        };
        axios.post(`${URL.verifyotp}`, data)
            .then(async res => {
                // navigation.navigate('Createaccount', { mobileNumber: mobilenum });
                setAlertstatus('success');
                setAlertmessage('OTP verified successfully!');

                props.setShow(3)
            })
            .catch(err => {
                setAlertstatus('error');
                setAlertmessage('OTP entered is wrong');

            }).finally(() => {
                setLoading(false)
            })
    };

    const handleResendOtp = () => {
        const data = {
            'messagetype': 'OTP',
            'apptype': 'medxperts',
            'username': props.username,
            'subject': 'FORGOT PASSWORD',
        }
        // timeinterval();
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9", { action: "submit" })
                .then(token => {
                    axios.post(`${URL.resetOtp} `, data, { headers: { "Authorization": `Bearer ${token} ` } }).then(res => {
                        console.log(res, "sendotp")
                        if (res["status"] === 200) {
                            if (res.data.status === 201) {
                                setAlert({ open: true, msg: `${res.data.message} ` });
                            } else if (res.data.status === 409) {
                                setAlert({ open: true, msg: `${res.data.message} ` })
                            }
                            setTime(59);

                            // Clear the previous timer
                            if (timer) {
                                clearInterval(timer);
                            }

                            // Rest of the code for resending OTP
                            // ...

                            // Start the new timer
                            setTimer(
                                setInterval(() => {
                                    setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
                                }, 1000)
                            );
                        } else {
                            setAlert({ open: true, msg: "Internal server error" });
                        }
                    }, error => {
                        setAlert({ open: true, msg: "Error " });
                    })
                })
        })
    }

    return (
        <Box>
            <Box sx={{ mt: 4 }}>
                <ColorTextField label="OTP" placeholder="Enter Received OTP" type="text" name="otp" value={otp} onChange={handleChangeOtp}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "0px 10px", mt: "5px" }}>
                <Typography sx={{ fontSize: "12px" }}>{`00:${time} `}</Typography>
                <Button disabled={time > 0} sx={{ textTransform: "capitalize" }}>
                    <Typography
                        sx={{ fontSize: { xs: 10, md: 12 } }}
                        onClick={handleResendOtp}
                    >
                        {t("credentialsData.signupmobiledata.resendotp")}
                    </Typography>
                </Button>
            </Box>

            <Box sx={{ textAlign: 'center', mt: 4, }}>
                <CommonButton text="Submit" width={150} bgcolor={"#8A2BE2"} disabled={loading || otp?.length < 5}
                    startIcon={
                        loading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : null
                    } onClick={handleverifyOtp} />
            </Box>
            <Box sx={{ mt: 2, textAlign: 'center' }}>
                {
                    alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )
                }
            </Box>
        </Box >
    );
}

export default ForgotPasswordOtp