import React, { useState } from 'react';
import { Box, Button, Card, CircularProgress, Grid, IconButton, MenuItem, Paper, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIos, Person } from '@mui/icons-material';
import axios from 'axios';
import URL from '../../../utility/apiurl';
import { relation } from '../../atoms/Relationdata';
import { Ibmpatient } from '../../atoms/Patientdata';
import { localstore } from '../../../localStore/localStore';
import TextInputField from '../../atoms/TextFields/TextInputField';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

import { useTranslation } from "react-i18next";

function CreateProfile(props) {
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");

    const [gender, setGender] = useState('Male');
    // const genderdata = ['Male', 'Female', 'Other'];
    const genderdata = [
        { type: 'Male' },
        { type: 'Female' },
        { type: 'Other' },
    ];

    const [details, setDetails] = useState({
        firstname: '',
        middlename: '',
        lastname: '',
        error: {
            firstname: '',
        }
    });
    const [ibmid, setIbmid] = useState('');
    const dispatch = useDispatch();
    const [uploading, setUploading] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [alertopen, setAlertopen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState('');
    const [system, setSystem] = useState('');
    const [display, setDisplay] = useState('');
    const { error, firstname, lastname } = details;
    const [uploadpersonal, setUploadperonal] = useState(false)
    const [name, setName] = useState("")
    const [filename, setFilename] = useState("")
    const [open, setOpen] = React.useState(false);

    const [filesize, setFilesize] = useState(0)
    const [filetype, setFiletype] = useState("")
    const [image, setImage] = useState("")
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [uploadprofessional, setUploadprofessional] = useState(false)
    const [RelResourseData, setRelResourseData] = useState(null);
    const [birth, setBirth] = useState('');


    const token = localstore.getToken()
    const primaryid = localstore.getPrimaryId()
    const fixedid = localstore.getPrimaryFixedId()

    const ibm = useSelector((state) => state.medx.ibmpdata);

    const handleOnChangeName = (e) => {
        setDetails({ ...details, [e.target.name]: e.target.value, error: { ...error, [e.target.name]: '' } });
        setAlertMessage('')
        setLoading('')

    };


    const handleChangeDate = (newValue) => {
        setBirth(newValue?.format("YYYY-MM-DD"))
        setAlertMessage('')
        setLoading('')

    }
    const handleGender = (selectedGender) => {
        setGender(selectedGender);
        setAlertMessage('')
        setLoading('')
    };


    const imageHandler = (e, imgFile) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            const fsize = e.target.files[0].size
            const filename = e.target.files[0].name
            console.log(filename)
            let type = e.target.files[0].type.split("/");
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = x => {
                let file = [];
                file.push(reader.result.split(',')[1])
                handleimagesubmit(file, type[1], fsize, filename, imgFile)
            }
        }
    }
    const handleimagesubmit = (Image, type, fsize, filename, imgFile) => {
        let data = {
            "file": Image,
            "fileType": type,
            "multiple": false,
            "subType": "profilePic",
            "fileSize": fsize,
            "type": "userProfilePics"
        }
        console.log(data)
        // let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.post(`${URL.uploadfile}`, data,).then(res => {
            console.log(res?.data?.data, "resphoto")
            if (res.data.status === 200) {
                setAlert({ open: true, msg: "Uploaded Succesfully" });
                setImage(res.data.data[0])
                setFilesize(fsize)
                setFiletype(type)
                if (imgFile === 'personal') {
                    // console.log("personal")
                    setName(filename)
                    setUploadperonal(true)
                }
                if (imgFile === 'professional') {
                    setFilename(filename)
                    setUploadprofessional(true)
                }
            }
        })
    }


    const handleSubmit = (e) => {
        setLoading(true)

        const error = {};

        if (!details.firstname) {
            error.firstname = "This field is required";
        }

        setDetails({ ...details, error });

        let data = {
            firstname: firstname,
            lastname: lastname,
            dateofbirth: birth,
            relation: display,
            gender:
                gender === "మగ"
                    ? "male"
                    : gender === "ఆడ"
                        ? "female"
                        : gender === "ఇతర"
                            ? "others"
                            : "",
        };
        let relateddata = data.lastname
            ? {
                ...Ibmpatient,
                gender: gender.toLowerCase(),
                birthDate: birth,
                resourceType: 'Patient',
                name: [
                    {
                        ...Ibmpatient.name[0],
                        given: [data.firstname],
                        family: data.lastname,
                    },
                ],
                telecom: [],
                photo: image?.length > 0 ? [{ url: image }] : [],
            }
            : {
                ...Ibmpatient,
                gender: gender.toLowerCase(),
                birthDate: birth,
                resourceType: 'Patient',
                name: [{ ...Ibmpatient.name[0], given: [data.firstname] }],
                telecom: [],
                photo: image?.length > 0 ? [{ url: image }] : [],
            };

        console.log(relateddata, "relateddta")
        handleRelatedPerson(fixedid, relateddata);

    };


    const handleRelatedPerson = async (id, relateddata) => {
        console.log(id, "idddddddd")


        let d = { ...relateddata };
        // console.log(d, "relateddata")
        let data = {
            ...d,
            resourceType: 'RelatedPerson',
            patient: {
                reference: `Patient/${id}`,
            },
            relationship: [
                {
                    coding: [
                        {
                            system: system,
                            code: code,
                            display: display,
                        },
                    ],
                },
            ],
            telecom: ibm.telecom,
        };
        console.log(data, "dataa")
        let secondarydata = {
            ...d,
            resourceType: 'Patient',
            telecom: ibm.telecom,
        };
        axios
            .post(`${URL.relatedPerson}RelatedPerson`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(async res => {
                console.log("----------------->>>> Relatedperson", res)

                // console.log(res, "resssssssssssssssss")

                let du = res.data
                secondarydata = {
                    ...secondarydata,
                    link: [
                        {
                            other: {
                                reference: `RelatedPerson/${du}`,
                            },
                            type: 'refer',
                        },
                    ],
                };
                console.log("----------secondary", secondarydata)
                await addPatienttoIbm(secondarydata)

            })
            .catch(err => {
                if (err) {
                    setAlertopen(true);
                    setAlertMessage(err.message);
                    setAlertstatus('error');
                }
            })

    }


    const addPatienttoIbm = (data) => {

        axios
            .post(`${URL.patient}Patient`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(async res => {
                console.log('----------->>>>>patinet', res);
                // let rid = res.headers.location.split('/').slice(0, -2);
                // // console.log('dataadded to ibm', 'addtoibm', res);
                // // console.log('dataadded to ibm', 'bm', res.headers.location);
                const relperIbmid = res.data;
                // // console.log('---------->>>>>rid', rid);
                // // console.log('---------->>>>>relperibid', relperIbmid);
                // console.log('-------->>>> second');
                await CreateRelPersonRes(relperIbmid);
            })

    };

    const CreateRelPersonRes = (RelatedperIbmId) => {
        // console.log('------------>>>>ibmid', RelatedperIbmId);
        // console.log('-------->>>> third');
        axios
            .get(`${URL.abha}create/resource/${RelatedperIbmId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                console.log('------------>>createrseource', res);
                setRelResourseData(res);

                navigate('/services')


            })
            .catch(err => {
                setAlertMessage("All fields are mandatory");
                setAlertstatus('error');
            }).finally(() => {
                setLoading(false)
            })

    };


    // Rest of the component

    const handleRelatives = (e) => {
        setDisplay(e.display);
        setCode(e.code);
        setSystem(e.system);
        setAlertMessage('')
        setLoading('')
    };



    return (
        <div>
            <Box sx={{ mt: { md: 1.5, xs: 9, sm: 9 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '0px', sm: "5px", md: '50px' } }}>

                <Typography sx={{ fontWeight: 600, fontSize: 17, display: 'flex', gap: 2, ml: 1, alignItems: 'center' }}><ArrowBackIos onClick={() => navigate(-1)} />Add Profile</Typography>
                <Paper elevation={3}
                    sx={{
                        borderRadius: 3,
                        mb: { xs: 10 },

                        mt: 1,
                        p: "20px 0px"
                    }}
                >
                    <Box sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <Box sx={{ maxWidth: "400px" }}>
                            <Box sx={{ display: "flex", justifyContent: "center", mt: 1.5 }}>
                                <div>
                                    <input
                                        type="file"
                                        name="uploadfile"
                                        multiple
                                        id="post-img2"
                                        accept=".png, .jpg, .jpeg, .pdf"
                                        style={{ display: "none" }}
                                        onChange={(e) => imageHandler(e, "personal")}
                                    />
                                    <label htmlFor="post-img2" className="cursor">
                                        <div className="inputOption" style={{ display: "flex" }}>
                                            <Box
                                                sx={{
                                                    width: "70px",
                                                    height: "60px",
                                                    position: "relative",
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    bgcolor: "#9560D9",
                                                    borderRadius: "10px",
                                                }}
                                            >
                                                {image ? (
                                                    <img
                                                        src={image}
                                                        alt="media"
                                                        style={{
                                                            objectFit: "cover",
                                                            width: "100%",
                                                            height: "100%",
                                                            cursor: "pointer",
                                                            borderRadius: "10px",
                                                        }}
                                                    />
                                                ) : (
                                                    <Person
                                                        sx={{ fontSize: "40px", cursor: "pointer" }}
                                                    />
                                                )}

                                                <Typography
                                                    sx={{
                                                        position: "absolute",
                                                        color: "white",
                                                        top: 37,
                                                        cursor: "pointer",
                                                        fontSize: "14px",
                                                        backgroundColor: "#0005",
                                                        color: "#fff",
                                                        borderBottomRightRadius: "10px",
                                                        borderBottomLeftRadius: "10px",
                                                        bottom: 0,
                                                        width: "70px",
                                                        textAlign: "center",
                                                    }}
                                                >
                                                    {t("records.upload")}
                                                </Typography>
                                            </Box>
                                        </div>
                                    </label>
                                </div>
                            </Box>
                            <Box mt={2}>
                                <TextInputField
                                    width={{ sm: 400, md: "400px" }}
                                    type="text"
                                    name="firstname"
                                    placeholder={t("credentialsData.completeprofile.firstname")}
                                    value={details.firstname}
                                    onChange={handleOnChangeName}
                                />

                                {error.firstname ? (
                                    <Typography sx={{ color: "red" }}>
                                        {error.firstname}
                                    </Typography>
                                ) : null}
                            </Box>
                            <Box mt={2}>
                                <TextInputField
                                    width={{ md: "400px" }}
                                    type="text"
                                    name="lastname"
                                    placeholder={t("credentialsData.completeprofile.lastname")}
                                    value={details.lastname}
                                    onChange={handleOnChangeName}
                                />
                            </Box>
                            {/* <Box mt={2}>
                        <TextInputField type="date" name="dateofbirth" placeholder={'Date Of Birth'} border="none" value={dateofbirth} onChange={handleOnChangeDate} />
                    </Box> */}
                            <Box mt={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={["DatePicker"]}>
                                        <DatePicker
                                            sx={{
                                                ".MuiOutlinedInput-input":
                                                {
                                                    padding: "9px 14px",
                                                    bgcolor: "whitesmoke",
                                                },
                                                ".MuiOutlinedInput-notchedOutline": {
                                                    // border: "none",
                                                    borderRadius: 2,
                                                },
                                                width: "100%",
                                            }}
                                            // label="Controlled picker"
                                            // maxDate={dayjs(new Date())}
                                            value={dayjs(birth)}
                                            onChange={handleChangeDate}
                                            maxDate={dayjs()}

                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </Box>

                            <div style={{ marginTop: "5px" }}>
                                <Typography sx={{ ml: 1, fontWeight: 600 }} variant="subtitle1">
                                    <span>
                                        {t("credentialsData.completeprofile.selectgender")}
                                    </span>
                                </Typography>

                                <Box
                                    display={"flex"}
                                    justifyContent="space-around"
                                    sx={{
                                        p: "4px",
                                        border: "1px solid #BCBCBC",
                                        borderRadius: 2,
                                        "&:hover": {
                                            border: "1px solid black",
                                        },
                                        backgroundColor: "whitesmoke",
                                    }}
                                >
                                    {genderdata.map((gen, i) => (
                                        <Button
                                            sx={{
                                                height: "30px",
                                                textTransform: "capitalize",
                                                // backgroundColor: "black",
                                                color: gender === gen.type ? "white" : "gray",
                                                "&:hover": {
                                                    bgcolor: "black",
                                                },
                                                bgcolor: gender === gen.type ? "black" : "whitesmoke",
                                            }}
                                            key={i}
                                            variant={gender === gen.type ? "contained" : "text"}
                                            onClick={() => handleGender(gen.type)}
                                        >
                                            {gen.type}
                                        </Button>
                                    ))}
                                </Box>
                            </div>

                            <TextField
                                sx={{
                                    ".MuiInputBase-root": {
                                        // borderRadius: 2,
                                        backgroundColor: "whitesmoke",
                                        mt: 2,
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                        // border: "none",
                                        borderRadius: 2,
                                    },
                                }}
                                placeholder="Relation"
                                size="small"
                                fullWidth
                                variant="outlined"
                                // onChange={(e) => setRelation(e.target.value)}
                                onChange={handleOnChangeName}
                                value={display}
                                name={"relation"}
                                autoFocus
                                autoComplete="off"
                                select
                                SelectProps={{
                                    displayEmpty: true,
                                    renderValue: (selected) =>
                                        selected === "" ? t("relation") : selected,
                                }}
                            >
                                <MenuItem value="">Select Relation</MenuItem>
                                {relation?.map((e, i) => (
                                    <MenuItem
                                        key={i}
                                        value={e.display}
                                        onClick={() => handleRelatives(e)}
                                    >
                                        {e.display}
                                    </MenuItem>
                                ))}
                            </TextField>
                            {error.relation ? (
                                <Typography sx={{ color: "red" }}>{error.relation}</Typography>
                            ) : null}

                            {alertmessage && (
                                <Typography
                                    sx={{
                                        color: alertstatus === "success" ? "green" : "red",
                                        mt: 1,
                                    }}
                                >
                                    {alertmessage}
                                </Typography>
                            )}
                            <Box mt={3} textAlign={"center"}>
                                <Button
                                    type="submit"
                                    sx={{
                                        borderRadius: 3,
                                        bgcolor: "blueviolet",
                                        "&:hover": {
                                            bgcolor: "blueviolet",
                                        },
                                        mt: 1,
                                        width: "150px",
                                        gap: 2,
                                    }}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleSubmit}
                                    disabled={
                                        loading ||
                                        !firstname ||
                                        !lastname ||
                                        !birth ||
                                        !gender ||
                                        !display
                                    }
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : null
                                    }
                                >
                                    {t("credentialsData.createaccount.donebtn")}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            </Box>
        </div >
    );
}

export default CreateProfile;