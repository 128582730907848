import React from 'react'
import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material'
import userIcon from '../../../../assets-new/services-icons/doctoricon.svg'
import orgIcon from '../../../../assets-new/services-icons/hospitalicon.svg'
import arrowRightIcon from '../../../../assets-new/services-icons/ArrowForward.svg'
import { ArrowForwardIos } from '@mui/icons-material'
import { isTimeView } from '@mui/x-date-pickers/internals/utils/time-utils'

const handleSpecialisation = (user) => {
    let qualdata = user ? user[user.length - 1] : null;
    if (qualdata && qualdata.code?.coding && qualdata.code.coding[0]?.code !== 'BS') {
        return (
            <>
                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                    {qualdata.code.text && qualdata.code.text.length > 16
                        ? qualdata.code.text.slice(0, 15) + '...'
                        : qualdata.code.text || 'N/A'}
                </Typography >
            </>
        );
    }
    if (qualdata && qualdata.code?.coding && qualdata.code.coding[0]?.code === 'BS') {
        return (
            <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                Physician
            </Typography>
        );
    } else {
        return (
            <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>N/A</Typography>
        );
    }
};

function AllRelated({ practitioner }) {

    return (
        <Box sx={{ p: "0px 15px 0px 8px" }}>
            {practitioner.map((item, index) => (
                <Box key={index}>
                    <Grid container mt={1}>
                        <Grid item md={2} sm={2} mt={'5px'}>
                            <img src={item?.resource?.photo?.[0]?.url} alt="user" width={50} height={50} style={{ borderRadius: '10px' }} />
                        </Grid>
                        <Grid item md={7.5} sm={7.5}>
                            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>

                                {item?.resource?.name?.[0]?.prefix}.{item?.resource?.name?.[0]?.given} {item?.resource?.name?.[0]?.family},
                                <Typography sx={{ fontSize: '12px' }} component={'span'}>{item?.resource?.name?.[0]?.suffix || 'N/A'}</Typography>
                            </Typography>
                            <Typography sx={{ fontSize: '11px' }}>
                                {item?.resource?.qualification?.[0]?.code?.text || 'N/A'}
                            </Typography>
                            <Typography sx={{ fontSize: '12px' }}>
                                {item?.resource?.qualification?.[0]?.issuer?.display},{item?.resource?.address?.[0]?.country}
                            </Typography>
                        </Grid>
                        <Grid item md={2.5} sm={2.5} mt={2}>
                            <Button variant='contained' sx={{ borderRadius: 4, width: '110px', height: '30px', bgcolor: '#D4F4F8', '&:hover': { bgcolor: '#D4F4F8' }, color: '#0748A0' }}>Schedule</Button>
                        </Grid>
                    </Grid>
                    <Divider sx={{ mt: 1 }} />
                </Box>)
            )}
        </Box>
    )
}

export default AllRelated