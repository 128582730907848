import React from 'react'
import { Box, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next';

function RecentlyConsulted() {
    const [t, i18n] = useTranslation("global");
    return (
        <Box sx={{ mt: "30px", p: "7px 20px" }}>
            <Typography sx={{ textAlign: "center", fontSize: "17px", fontWeight: 500 }}>
                No One Consulted
            </Typography>
        </Box >
    )
}

export default RecentlyConsulted