import React, { useEffect, useState } from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { ArrowBackIos, Call, Close, ExpandMore } from '@mui/icons-material';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { debounce } from 'lodash';
import AbhaLinking from './AbhaLinking';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import VerifyShowPhr from './VerifyShowphr';
import SelectVerification from '../ConnectAbhaFlows/SelectVerification';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { handleErrorResponse } from '../../../atoms/StatusError';

function VerifyAadhar() {

    const [show, setShow] = useState(1)
    const [activeButton, setActiveButton] = useState('mobile')
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [checkid, setCheckid] = useState(null);
    const [abhaNum, setAbhaNum] = useState('')
    const [txnId, setTxnId] = useState('')
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()

    const handleOnChangeAbha = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setAbhaNum(inputValue.slice(0, 14));
        setAlertmessage('')
    }

    const handleSend = async () => {
        // navigation.navigate('AbhaNumberStep2')
        const inputValue = abhaNum.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');

        setLoading(true);
        let d1 = {
            healthIdNumber: inputValue,
        };
        let d2 = {
            healthIdNumber: inputValue,
            authMethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
        };

        try {
            // First API call
            const searchResponse = await axios.post(`${URL.abha}search/user/byHealthId/${primaryId}`, d1, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            });

            console.log('--------->>> searchapi', searchResponse.data);
            setCheckid(searchResponse.data.status);

            if (searchResponse.status === 200 || searchResponse.status === 201) {
                // Second API call
                setLoading(true); // Set loading for the second API call
                const res = await axios.post(`${URL.abha}login/hid/init/transaction/${primaryId}`, d2, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                });

                setAlert(true);
                setAlertmessage('OTP sent to mobile number!');
                setAlertstatus('success');
                setShow(2)

                dispatch({
                    type: 'VERIFY_KYC_ABHANUMBER',
                    payload: {
                        // transactionId: res.data.txnId,
                        transactionId: res.data.transactionId,
                        number: inputValue
                    }
                })
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setAlert(true);
                setAlertmessage('ABHA Number not Found or No ABHA address linked with this ABHA number. Please register an ABHA address with the ABHA number.');
                setAlertstatus('error');
            } else {
                const { alertmessage, alertstatus } = handleErrorResponse(err)
                setAlertmessage(alertmessage)
                setAlertstatus(alertstatus)
            }
        } finally {
            setLoading(false); // Reset loading state after both API calls
        }
    };



    return (
        <Box sx={{ mt: { md: 2, xs: 9, sm: 9 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '0px', sm: "0px", md: '30px' } }}>



            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: 3, sm: 3, md: 2 } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 2 },
                            }}
                        >
                            {/* <IconButton onClick={() => history.push('/services/loginabha')}><ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} /></IconButton> */}
                            <Typography
                                sx={{
                                    fontSize: { xs: 14, sm: "16px", md: "17px" },
                                    fontWeight: 600,
                                    ml: 1,
                                }}
                            >
                                KYC Verification
                            </Typography>
                        </Box>
                    </Box>

                    <Paper elevation={3}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1,
                            p: 3,
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 2,
                                }}
                            >
                                <img src={Abhalogo} alt="abha" width={30} height={30} />
                                <Typography sx={{
                                    fontSize: { xs: 14, sm: "16px", md: "17px" },
                                }} >{t("enterabhatxt")}</Typography>
                            </Box>

                            <Grid container mt={2} sx={{ textAlign: 'center' }}>
                                <Grid item xs={12}>
                                    <TextInputField
                                        width={{ sm: "350px", md: '350px' }}
                                        placeholder={t("abhahealthid")}
                                        name="number"
                                        border="none"
                                        value={abhaNum}
                                        onChange={handleOnChangeAbha}
                                    />
                                </Grid>
                                {/* {checkid !== "ACTIVE" && abhaNum.length > 5 ? (
                                    <Typography sx={{ color: "red", fontSize: 14 }}>
                                        {t("abhadoesnotexist")}
                                    </Typography>
                                ) : null} */}
                                {alertmessage && (
                                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                )}
                            </Grid>

                            {/* <Typography
                                    sx={{
                                        color: "#9560D9",
                                        ml: 1,
                                        fontSize: { xs: 12, sm: 13, md: 13 },
                                        mt: "5px",
                                        "&:hover": {
                                            textDecoration: "underline",
                                            cursor: "pointer",
                                        },
                                    }}
                                    onClick={() => navigate("/aadharflow", { state: { abhacreate: false } })}
                                >
                                    {t("forgothealthid")}
                                </Typography> */}
                            <Box mt={3} textAlign={"center"}>
                                <Typography sx={{ fontSize: '14px' }}>{t("verifyabha")}</Typography>
                                <ButtonGroup
                                    variant="contained"
                                    aria-label="outlined primary button group"
                                    sx={{ mt: 1 }}
                                >
                                    <Button
                                        sx={{
                                            bgcolor:
                                                activeButton === "aadhar" ? "white" : "#EAE2E2",
                                            '&:hover': {
                                                bgcolor: activeButton === "aadhar" ? "white" : "#EAE2E2",
                                            },
                                            color: "black",
                                        }}
                                        disableElevation
                                        onClick={() => setActiveButton("aadhar")}
                                    >
                                        {t("aadharotp")}
                                    </Button>
                                    <Button
                                        sx={{
                                            bgcolor:
                                                activeButton === "mobile" ? "white" : "#EAE2E2",
                                            "&:hover": {
                                                bgcolor:
                                                    activeButton === "mobile" ? "white" : "#EAE2E2"
                                            },
                                            color: "black",
                                        }}
                                        disableElevation
                                        onClick={() => setActiveButton("mobile")}
                                    >
                                        {t("mobileotp")}
                                    </Button>
                                </ButtonGroup>
                            </Box>

                            <Box sx={{ mt: 2.5, textAlign: "center", }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        borderRadius: 2,
                                        "&:hover": {
                                            bgcolor: "#1CB500",
                                        },
                                        bgcolor: "#1CB500",
                                        width: "150px",
                                    }}
                                    onClick={handleSend}
                                    disabled={loading || abhaNum.length < 14}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : null
                                    }
                                >
                                    {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                    {t("credentialsData.signupmobiledata.otpbtntxt")}
                                </Button>
                            </Box>
                            <Box sx={{ textAlign: 'center' }}>
                                <Typography sx={{ fontSize: '14px', mt: 2 }}>Don't Remembering having ABHA Health ID Number ?</Typography>
                                <Button variant='outlined' sx={{
                                    "&:hover": {
                                        borderColor: "#9560D9",
                                    },
                                    borderColor: "#9560D9",
                                    color: '#9560D9',
                                    borderRadius: '20px',
                                    mt: 2
                                }} onClick={() => navigate("/aadharflow")}

                                >Recover / Create New</Button>
                            </Box>
                            {/* <Box sx={{ textAlign: "center", mt: 3 }}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            borderRadius: 2,
                                            "&:hover": {
                                                bgcolor: "#9560D9",
                                            },
                                            bgcolor: "#9560D9",
                                        }}
                                        onClick={() => navigate("/aadharflow")}
                                    >
                                        Create HealthId Number
                                    </Button>
                                </Box> */}
                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                <AbhaLinking
                    setShow={setShow}
                    authMethod={
                        activeButton === "aadhar" ? "AADHAAR_OTP" : "MOBILE_OTP"
                    }
                    setAlertmessage={setAlertmessage}
                />
            ) : show === 3 ? (
                <VerifyShowPhr setShow={setShow} />
            ) : show === 4 ? (
                <SelectVerification />
            ) : null
            }
        </Box >
    );
}

export default VerifyAadhar