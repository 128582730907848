import React from 'react'
import { Box, Button, Card, CircularProgress, DialogContent, DialogTitle, Divider, Grid, TextField, Typography } from '@mui/material'
import shareIcon from '../../../../assets/home/share_icon.svg'
import sharebtnIcon from '../../../../assets/home/sharebtn.svg'
import { useDispatch, useSelector } from 'react-redux'
import { Check, Group } from '@mui/icons-material'
import URL from '../../../../utility/apiurl'
import { useState } from 'react'
import { localstore } from '../../../../localStore/localStore'
import axios from 'axios'
import { useEffect } from 'react'
import hospitalIcon from '../../../../assets/home/bookapp_assets/hospital.svg'
import { useTranslation } from "react-i18next";


const NotApplicable = (props) => {
    return < Typography sx={{ mt: 1, fontSize: '14px', fontWeight: 600, }}>N/A</Typography>
}


const Information = (props) => {
    return < Typography sx={{ mt: 1, fontWeight: 600, fontSize: '14px' }}>{props.title}</Typography>

}
function QuickShare1(props) {

    const { user, mobiledata, setShow } = props
    console.log(user, "userr")
    const [sharedata, setSharedata] = useState('')
    const [loading, setLoading] = useState(false)
    const [timeRemaining, setTimeRemaining] = useState();
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const hospitalId = user.identifier.id
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        let timerId;

        if (timeRemaining > 0) {
            timerId = setInterval(() => {
                setTimeRemaining((prevTime) => prevTime - 1);
            }, 1000);
        }

        return () => {
            if (timerId) {
                clearInterval(timerId);
            }
        };
    }, [timeRemaining]);


    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        const secondsRemaining = seconds % 60;

        return `${minutes}:${secondsRemaining}`;
    };

    const handleshareProfile = async () => {
        setLoading(true)
        await axios.post(`${URL.consents}patients/profile/share/${primaryId}/${hospitalId}`, {}, {
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {
            setSharedata(res.data)
            if (timeRemaining === undefined || timeRemaining === null) {
                axios
                    .get(`https://devwebhooks.docsteth.com/api/v1/get/tokenTime/${mobiledata?.id}/${hospitalId}`).then((res) => {
                        // Remove the '-' character
                        const timeWithoutMinus = res.data.replace(/-/g, '');

                        // Split the string into hours and minutes
                        const [hoursString, minutesString] = timeWithoutMinus.split(':');

                        // Convert hours and minutes to numbers
                        const hours = parseInt(hoursString, 10);
                        const minutes = parseInt(minutesString, 10);

                        // Multiply hours by 60 and add minutes
                        const totalMinutes = (hours * 60) + minutes;
                        setTimeRemaining(totalMinutes);
                    }).catch(err => {
                        console.log(err, 'timeerr');

                    });
            }
        })
            .catch(err => {
                setAlertmessage(err.message)
                setAlertstatus('error')
            })
            .finally(() => {
                setLoading(false);
            });

    }

    const handleCloseDone = () => {
        props.setShow(1)
        props.handleClose()
    }

    return (
        <div>
            <>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                        {/* <IconButton> <Delete sx={{ color: 'red' }} /></IconButton> */}
                        <img src={shareIcon} alt="share" />
                        <Typography
                            sx={{ color: "#007DCD", fontWeight: 600, fontSize: "18px" }}
                        >
                            {t("shareprofile")}{" "}
                        </Typography>
                    </Box>
                </Box>

                <Card sx={{ mt: 2, p: "10px 25px" }}>
                    <Box >
                        {sharedata?.healthId?.length > 0 ? (
                            <>
                                <Box >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            gap: "3px",
                                        }}
                                    >
                                        <Check
                                            sx={{
                                                bgcolor: "green",
                                                borderRadius: "25px",
                                                fontSize: "17px",
                                                color: "white",
                                            }}
                                        />

                                        <Typography sx={{ color: "green", fontSize: "22px" }}>
                                            {t("successful")}
                                        </Typography>
                                    </Box>
                                    <Typography sx={{ color: "#1B1B77", mt: 2 }}>
                                        {t("profileshared")}
                                    </Typography>
                                    <Box
                                        mt={1}
                                        sx={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            gap: 3,
                                        }}
                                    >
                                        <img
                                            src={hospitalIcon}
                                            alt="hospital"
                                            style={{ width: 35, height: 35 }}
                                        />
                                        <Box>
                                            <Typography> {user.identifier.name}</Typography>
                                            <Typography sx={{ fontSize: 13 }}>
                                                {user?.address || "N/A"} ,{user?.city || "N/A"},{" "}
                                                {user?.pinCode || "N/A"}
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: 2,
                                            mt: 3,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Group sx={{ color: "#007DCD" }} />
                                        <Typography sx={{ color: "#007DCD" }}>
                                            {t("registerrequest")}{" "}
                                        </Typography>
                                    </Box>
                                    <Typography sx={{ mt: 2 }}>{t("tokennumber")}</Typography>
                                    <Typography sx={{ color: "#006CAF" }}>
                                        {" "}
                                        {sharedata?.tokenNumber ? sharedata?.tokenNumber : 0}
                                    </Typography>
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            mt: 2,
                                            gap: 2,
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Typography>{t("expiresin")}</Typography>
                                        <Typography sx={{ color: "#007DCD" }} component={"span"}>
                                            {formatTime}
                                        </Typography>
                                    </Box>
                                </Box>

                                <Box
                                    sx={{ textAlign: "center", mt: 4 }}
                                    onClick={handleCloseDone}
                                >
                                    <Button
                                        variant="contained"
                                        sx={{ width: "120px", borderRadius: 2 }}
                                    >
                                        {t("credentialsData.createaccount.donebtn")}
                                    </Button>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                                    <Box >
                                        <Typography sx={{ color: "#1B1B77", fontSize: 17 }}>
                                            {t("sharingdetails")}
                                        </Typography>

                                        <Box mt={1} sx={{ display: "flex", gap: 3 }}>

                                            <img
                                                src={hospitalIcon}
                                                alt="hospital"
                                                style={{ width: 35, height: 35 }}
                                            />
                                            <Box>
                                                <Typography sx={{ fontWeight: 600, }}>
                                                    {" "}
                                                    {user.identifier.name}
                                                </Typography>
                                                <Typography sx={{ fontSize: 14 }}>
                                                    {t("address")}:{user?.address ? user.address : <span style={{ fontSize: '10px' }}>N/A</span>} ,
                                                    {t("cityname")}:{user?.city ? user.city : <span style={{ fontSize: '10px' }}>N/A</span>},
                                                    {t("pincode")}:{user?.pinCode ? user.pinCode : <span style={{ fontSize: '10px' }}>N/A</span>}
                                                </Typography>

                                            </Box>
                                        </Box>

                                        <Typography sx={{ color: "#4098FF", fontSize: 17, mt: 1 }}>
                                            {t("detailssharing")}
                                        </Typography>

                                        <Grid container sx={{ textAlign: 'left', justifyContent: 'center' }}>
                                            <Grid item md={12} sm={12} xs={12}>
                                                <Grid item container>
                                                    <Grid item xs={4} md={4} sm={4}>
                                                        <Typography sx={{ mt: 1 }}>
                                                            {t("healthid")}{" "}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8}>
                                                        {mobiledata.healthId ? (
                                                            <Information title={mobiledata.healthId} />
                                                        ) : (
                                                            <NotApplicable />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4} md={4} sm={4}>
                                                        <Typography sx={{ mt: 1 }}>
                                                            {t("phraddress")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8}>
                                                        {mobiledata.id ? (
                                                            <Information title={mobiledata.id} />
                                                        ) : (
                                                            <NotApplicable />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4} md={4} sm={4}>
                                                        <Typography sx={{ mt: 1 }}>{t("name")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8}>
                                                        {mobiledata.fullName ? (
                                                            <Information title={mobiledata.fullName} />
                                                        ) : (
                                                            <NotApplicable />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4} md={4} sm={4}>
                                                        <Typography sx={{ mt: 1 }}>
                                                            {t("gender")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8}>
                                                        {mobiledata.gender ? (
                                                            <Information title={mobiledata.gender} />
                                                        ) : (
                                                            <NotApplicable />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4} md={4} sm={4}>
                                                        <Typography sx={{ mt: 1 }}>{t("dob")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8}>
                                                        {mobiledata.dateOfBirth ? (
                                                            <Typography
                                                                sx={{
                                                                    mt: 1,
                                                                    fontSize: "14px",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {" "}
                                                                {mobiledata?.dateOfBirth?.date} -{" "}
                                                                {mobiledata?.dateOfBirth?.month} -{" "}
                                                                {mobiledata?.dateOfBirth?.year}
                                                            </Typography>
                                                        ) : (
                                                            <NotApplicable />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4} md={4} sm={4}>
                                                        <Typography sx={{ mt: 1 }}>
                                                            {t("mobile")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8}>
                                                        {mobiledata.mobile ? (
                                                            <Information title={mobiledata.mobile} />
                                                        ) : (
                                                            <NotApplicable />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4} md={4} sm={4}>
                                                        <Typography sx={{ mt: 1 }}>{t("email")}</Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8}>
                                                        {mobiledata.email ? (
                                                            <Information title={mobiledata.email} />
                                                        ) : (
                                                            <NotApplicable />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={4} md={4} sm={4}>
                                                        <Typography sx={{ mt: 1 }}>
                                                            {t("address")}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={8} sm={8} md={8}>
                                                        {mobiledata.address ? (
                                                            <Typography
                                                                sx={{
                                                                    mt: 1,
                                                                    fontSize: "14px",
                                                                    fontWeight: 600,
                                                                }}
                                                            >
                                                                {mobiledata.address},{mobiledata.districtName},
                                                                {mobiledata.stateName},{mobiledata.pincode}
                                                            </Typography>
                                                        ) : (
                                                            <NotApplicable />
                                                        )}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Box>

                                    <Typography sx={{ mt: 3, fontSize: 12 }} component={"p"}>
                                        {t("shareprofiletxt")}
                                    </Typography>
                                    {alertmessage && (
                                        <Typography
                                            sx={{
                                                color: alertstatus === "success" ? "green" : "red",
                                                mt: 1,
                                            }}
                                        >
                                            {alertmessage}
                                        </Typography>
                                    )}
                                    <Box sx={{ textAlign: "center", mt: 2 }}>
                                        <Button
                                            variant="contained"
                                            sx={{ width: "120px", borderRadius: 2 }}
                                            onClick={handleshareProfile}
                                            startIcon={
                                                loading ? (
                                                    <CircularProgress sx={{ color: "white" }} size={20} />
                                                ) : (
                                                    <img src={sharebtnIcon} />
                                                )
                                            }
                                        >
                                            {t("actions.share")}
                                        </Button>
                                    </Box>
                                </Box>
                            </>
                        )}
                    </Box>
                </Card>
            </>
        </div >
    );
}

export default QuickShare1