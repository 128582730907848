import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { localstore } from '../../../../localStore/localStore';
import prescriptionIcon from '../../../../assets/home/consent_module/prescription.svg';
import consultationIcon from '../../../../assets/home/consent_module/consultation.svg';
import diagnosticIcon from '../../../../assets/home/consent_module/diagnostic.svg';
import dischargeIcon from '../../../../assets/home/consent_module/discharge.svg';
import immunisationIcon from '../../../../assets/home/consent_module/immunisation.svg';
import documentIcon from '../../../../assets/home/consent_module/document.svg';
import WellnessRecordIcon from '../../../../assets/home/consent_module/lifestyle.svg';
import { Box, Card, CardContent, Grid, Paper, Typography } from '@mui/material';
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat';
import FileViewer from './FileViewer';
import { useNavigate } from 'react-router-dom';
import FileTextIcon from '../../../../assets/home/consent_module/file-text.svg';
import { Compare } from '@mui/icons-material';
import URL from '../../../../utility/apiurl';



function MedicalRecordsData({ item }) {

    // const dateObject = new Date(item.localDateTime);
    // const date = dateObject?.toISOString().split('T')[0];
    // const time = dateObject.toLocaleString('en-US', { hour: 'numeric', minute: '2-digit', hour12: true });
    const navigate = useNavigate()
    const [refreshing, setRefreshing] = useState(false); // State for pull-to-refresh
    const [records, setRecords] = useState();
    const token = localstore.getToken()
    const [open, setOpen] = useState(false)
    console.log(records, "records")
    console.log(item, 'items')
    // useEffect(() => {
    //     axios
    //         .get(
    //             `https://dev.api.medxperts.in/fhir-server/api/v4/Composition/${item.compositionId}`,
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                 },
    //             }
    //         )
    //         .then((res) => {
    //             setRecords(res.data);
    //             setRefreshing(false); // Turn off refreshing when data is fetched
    //         })
    //         .catch((err) => {
    //             console.log(err.message, 'lll');
    //             setRefreshing(false); // Turn off refreshing in case of an error
    //         });

    // }, [])


    const HiTypeIcons = (type) => {
        if (type === "DiagnosticReport") {
            return <img src={diagnosticIcon} alt="diagnostic" width={25} height={25} />
        }
        if (type === "DischargeSummary") {
            return <img src={dischargeIcon} alt="discharge" width={25} height={25} />
        }
        if (type === "OPConsultation") {
            return <img src={consultationIcon} alt="consult" width={25} height={25} />
        }
        if (type === "ImmunizationRecord") {
            return <img src={immunisationIcon} alt="record" width={25} height={25} />
        }
        if (type === "WellnessRecord") {
            return <img src={WellnessRecordIcon} alt="wellness" width={25} height={25} />
        }
        if (type === "HealthDocumentRecord") {
            return <Compare />
        }
        if (type === "Prescription") {
            return <img src={prescriptionIcon} alt="prescription" width={25} height={25} />
        }
        if (type === "reports") {
            return <img src={FileTextIcon} alt="reports" width={25} height={25} />
        }
        if (type === '') {
            return <img src={FileTextIcon} alt="file" height={25} width={25} />;
        } else {
            return <img src={FileTextIcon} alt="file" height={25} width={25} />;
        }
    }

    const handleBase64 = (item) => {

        const base64data = item?.resource?.section?.[0]?.entry[0].reference;
        console.log(item, "base64")
        if (base64data && token.length > 0) {
            axios
                .get(`${URL.cliniapi}${base64data}/_history`, {
                    headers: {
                        Authorization: token,
                    },
                })
                .then((res) => {
                    const data = res?.data?.entry?.[0]?.resource?.content?.[0]?.attachment?.data;
                    const contentType = res?.data?.entry?.[0]?.resource?.content?.[0]?.attachment?.contentType.match(/\/(.*)/)?.[1];

                    if (data) {
                        navigate('/records/imgorpdf', {
                            state: {
                                Data: data,
                                Type: contentType,
                            }
                        });
                    }
                })
                .catch((err) => {
                    console.error(err, 'Base64ResponseError');
                });
        }
    };


    const openDocument = () => {
        navigate('/records/fileviewer')
    };
    return (

        <Paper elevation={3} sx={{
            width: { xs: '100%', sm: "100%", md: "100%" }, mt: 3, cursor: 'pointer'
        }} onClick={() => handleBase64(item)}>

            <Box sx={{ p: '7px 20px' }}>
                <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }} color="text.secondary" gutterBottom>
                        {item?.resource?.title}
                    </Typography>
                    <Typography sx={{ fontSize: { xs: '10px', sm: '13px', md: '13px' } }} >
                        {/* {DateFormat(item?.resource?.date)} | {Time(item?.resource?.date)} */}
                        {DateFormat(item?.resource?.date, 'DateTime')}

                    </Typography>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        {(HiTypeIcons(item?.resource?.title))}
                        <Box>
                            <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' }, fontWeight: 500 }}>
                                {item?.resource?.author?.[0]?.display === 'Self Uploaded' ? item?.resource?.identifier?.value : item?.resource?.attester?.[0]?.party.display}
                            </Typography>
                            <Typography sx={{ mt: '5px', fontSize: { xs: '13px', sm: '14px', md: '14px' } }}>{item?.resource?.author?.[0]?.display}</Typography>
                        </Box>
                    </Box>
                    <Typography sx={{ fontSize: { xs: '10px', md: '14px', sm: '14px' }, mt: 3 }} >
                        {item?.resource?.title}
                    </Typography>
                </Box>
                {/* <Grid sx={{ display: 'flex', alignItems: 'center' }}>
                    {(docTypeIcons(records.title))}
                    <Grid ml={2}>
                        <Typography variant="h5" component="div" sx={{ fontSize: { xs: '14px', sm: '18px', md: '17px' }, fontWeight: 'bold' }}>
                            {records?.identifier?.value}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }}>
                            {records?.author?.[0]?.display}
                        </Typography>
                    </Grid>
                </Grid>
                <Typography sx={{ fontSize: { xs: '10px', md: '15px', sm: '14px' }, float: 'right' }} >
                    {records?.title}
                </Typography> */}
                {/* <CardActions sx={{ float: 'right' }}>

        </CardActions>  */}
            </Box>
            {/* <FileViewer records={records} /> */}
        </Paper >
    )
}

export default MedicalRecordsData