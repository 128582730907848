import React, { useState } from 'react'
import { Close } from '@mui/icons-material'
import { Card, Grid, Typography, Box, Button, IconButton, } from '@mui/material'
import AbhaLogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
import MobileNumber from './MobileFlow';
import EmailIdflow from './EmailFlow';
import AbhaNumberFlow from './AbhaNumFlow';

function ConnectHealthId() {

    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()
    const handleBack = () => {
        navigate('/services')
    }

    return (
        <Box sx={{
            mt: { md: 2, xs: 9, sm: 9 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '0px', sm: "0px", md: '40px' }, mb: { sm: 10, xs: 10, md: 0 },
        }}>

            <Typography
                variant="h4"
                sx={{
                    fontSize: { xs: "16px", sm: "18px", md: "17px" },
                    mt: { xs: 3, sm: 3, md: 2 },
                    fontWeight: 600,
                    ml: 1,
                }}
            >
                {t("createabha")}
            </Typography>
            <Typography
                variant="h5"
                sx={{
                    fontSize: { xs: "13px", sm: "14px", md: "14px" },
                    mt: { xs: "5px", sm: "5px", md: "5px" },
                    color: "black",
                    ml: 1,
                }}
            >
                {t("selfdeclared")}
            </Typography>

            <Box sx={{ mt: 2 }}>
                <MobileNumber />
            </Box>

            <Box sx={{ mt: 2 }}>
                <EmailIdflow />
            </Box>

            <Box sx={{ mt: 2 }}>
                <Typography
                    variant="h4"
                    sx={{
                        fontSize: { xs: "16px", sm: "16px", md: "17px" },
                        mt: { xs: 3, sm: 3, md: 2 },
                    }}
                >
                    {t("preverifykyc")}
                </Typography>
            </Box>

            <Box sx={{ mt: "5px" }}>
                <AbhaNumberFlow />
            </Box>

            <Box mt={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: '0px 20px' }}>
                <Typography
                    variant="h5"
                    sx={{
                        fontSize: { xs: "16px", sm: "16px", md: "14px" },
                        mt: { sm: 3, md: "5px" },
                        color: "black",
                        ml: 1,
                    }}
                >
                    {t("donothaveabha")}
                </Typography>
                <Button onClick={() => navigate("/aadharflow", { state: { abhacreate: true } })}>
                    <Typography
                        variant="h3"
                        sx={{
                            fontSize: { xs: "16px", sm: "16px", md: "17px" },
                            mt: { xs: 1, sm: "5px", md: "5px" },
                            cursor: "pointer",
                            color: "#AA80E1",
                            fontWeight: 600,
                            ml: 1,
                            "&:hover": {
                                textDecoration: "underline",
                            },
                        }}
                    >
                        {t("createnow")}
                    </Typography>
                </Button>
            </Box>

            <Box
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    mr: 2,
                    mt: "15px",
                }}
            >
                <Typography
                    sx={{ mt: 2, fontSize: { xs: 14, sm: 16, md: 14 }, color: "black" }}
                >
                    {t("ayushmanid")}
                </Typography>
                <Button
                    variant="outlined"
                    startIcon={
                        <img src={AbhaLogo} alt="abha" width="20px" height="20px" />
                    }
                    sx={{
                        width: { xs: "140px", md: "130px" },
                        mt: 1,
                        height: "40px",
                        gap: 1,
                        "&:hover": { border: "1px solid #B18BE2" },
                        border: "1px solid #B18BE2",
                        borderRadius: 2,
                    }}
                    onClick={() => navigate("/loginabha")}
                >
                    <Typography
                        sx={{
                            fontSize: { xs: "16px", sm: "18px", md: "16px" },
                            color: "#B18BE2",
                            fontWeight: 600
                        }}
                    >
                        {t("login")}
                    </Typography>
                </Button>
            </Box>
        </Box>
    );
}

export default ConnectHealthId