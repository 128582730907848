import React, { useState } from 'react'
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Divider } from '@mui/material'
import { ArrowBackIos, Close } from '@mui/icons-material'
import { Card, Grid, Typography, Box, Button, IconButton, CircularProgress, } from '@mui/material'
import { useHistory, useNavigate } from 'react-router-dom'
import AadharIcon from '../../../../assets/home/services_assets/aadhar-logo.svg'
import TextInputField from '../../../atoms/TextFields//TextInputField'
import { useDispatch } from 'react-redux'
import { localstore } from '../../../../localStore/localStore'
import URL, { AadharPublicKey } from '../../../../utility/apiurl'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import JSEncrypt from 'jsencrypt'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'

function DeleteHealthId3(props) {
    const { setShow, handleClose } = props
    const dispatch = useDispatch()
    const [aadhar, setAadhar] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");

    const handleOnChangeAadhar = (e) => {
        const input = e.target.value;
        const formattedInput = input
            .replace(/[^0-9]/g, '')
            .slice(0, 12)
            .replace(/(\d{4})(\d{4})(\d{4})/, '$1-$2-$3');
        setAadhar(formattedInput);
        setAlertmessage('')
    };
    // console.log(typeof aadhar, "iiiiiii")
    const handleSendOtp = async () => {
        setLoading(true)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(AadharPublicKey)
        let data = aadhar.replace(/ /g, '').replace(/-/g, '');
        const encryptAadharnum = encrypt.encrypt(data)
        // console.log(data);
        let d = {
            aadhaar: encryptAadharnum.replace(/\n/g, '').replace(/\r/g, ''),
            mobileOrAadhaar: 'aadhaar',
        };
        await axios
            .post(`${URL.abha}v2/generateAadhaarOtp/${primaryId}`, d, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,

                },
            })
            .then(res => {
                setAlertopen(true);
                setAlertmessage('OTP sent to your registered mobile number!');
                setAlertstatus('success');
                setTimeout(() => {
                    setAlertopen(false);
                }, 2000);
                dispatch({
                    type: "FORGOT_HEALTHID_NUMBER",
                    payload: res.data
                })
                setShow(5)
            })
            .catch(err => {
                if (err.response.status === 400) {
                    setAlertopen(true);
                    setAlertmessage(" incorrect data entered or You have reached the maximum verify attempts");
                    setAlertstatus('error');
                } else {
                    setAlertopen(true);
                    setAlertmessage(err.message);
                    setAlertstatus('error');
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleClosedone = () => {
        handleClose()
        setShow(1)
    }
    return (
        <div>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '5px 20px' }}>

                <Typography sx={{ fontWeight: 600, fontSize: 17 }}> Verify AadharNumber</Typography>
                <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Center-align horizontally
                        justifyContent: "center", // Center-align vertically
                        textAlign: "center", // Center-align text within the card
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            gap: 2,
                        }}
                    >
                        <img src={AadharIcon} alt="aadhar" />
                        <Typography
                            sx={{
                                fontSize: { xs: 13, sm: "14px", md: "16px" },
                                fontWeight: 500,
                                mt: "6px",
                            }}
                        >
                            {" "}
                            {t("enteraadharcardnum")}
                        </Typography>
                    </Box>

                    <Box mt={1}>
                        <TextInputField
                            width="350px"
                            placeholder="XXXX - XXXX - XXXX"
                            mt={2}
                            border="none"
                            name="aadhar"
                            value={aadhar}
                            onChange={handleOnChangeAadhar}
                        />
                    </Box>
                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />

                    )}
                    <Box mt={1} p={1.5}>
                        <Typography
                            component="p"
                            sx={{
                                fontSize: { xs: 11, sm: 12, md: "11px" },
                                color: "black",
                            }}
                        >
                            {t("enteraddtxt")}
                        </Typography>
                    </Box>


                </Box>
            </DialogContent>
            <Divider />

            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    sx={{
                        borderRadius: 2,
                        bgcolor: "#1CB500",
                        "&:hover": {
                            bgcolor: "#1CB500",
                        },
                        width: "150px",
                        mb: 1,
                        mt: 1
                    }}
                    onClick={handleSendOtp}
                    disabled={loading || aadhar?.length < 12}
                    startIcon={
                        loading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : null
                    }
                >
                    {t("sendotp")}
                </Button>

            </DialogActions>
        </div>
    )
}

export default DeleteHealthId3