import React, { useState } from 'react';
import { Avatar, Box, Card, IconButton, Tab, Tabs, Typography } from '@mui/material';
import TelemedicineIcon from '../../../assets-new/telemedicine-icons/telemedicne.svg';
import { ArrowForwardIos, Comment, Search, Star } from '@mui/icons-material';
import SearchBar from '../../atoms/Searchbar';
import medxpertsIcon from '../../../assets-new/medxpedia-icons/medxlovebg.svg'
import staractiveIcon from '../../../assets-new/telemedicine-icons/star-active.svg'
import starinactiveIcon from '../../../assets-new/telemedicine-icons/star-inactive.svg'
import messageactive from '../../../assets-new/telemedicine-icons/message-active.svg'
import messageinactive from '../../../assets-new/telemedicine-icons/message-inactive.svg'

function Telemedicine() {
    const [activeTab, setActiveTab] = useState('favourites')
    const [isCollapsed, setIsCollapsed] = useState(true)
    const handleTabChange = (newValue) => {
        setActiveTab(newValue);
    };

    const handleCollapsed = () => {
        setIsCollapsed(!isCollapsed);
    }

    return (
        <Box sx={{ display: 'flex', justifyContent: "end" }}>
            <Card sx={{ position: 'fixed' }}>

                <Box sx={{ display: 'flex', alignItems: 'center', boxShadow: 1, justifyContent: 'space-between', p: '0px 0px 0px 5px' }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>

                        <img src={TelemedicineIcon} alt="telemedicine" />
                        {!isCollapsed && <Typography sx={{ fontSize: '17px', fontWeight: 600, color: '#205072' }}>Telemedicine</Typography>}
                    </Box>
                    <IconButton onClick={handleCollapsed}> <ArrowForwardIos /></IconButton>
                </Box>
                {!isCollapsed && (
                    <>
                        <Box sx={{ textAlign: 'center', mt: '20px', p: '0px 25px' }}>
                            <Box sx={{ display: 'flex', bgcolor: '#707070', borderRadius: '10px', justifyContent: 'space-between', p: "1px 10px" }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', gap: '5px', p: "2px 5px", borderRadius: '10px', bgcolor: activeTab === "favourites" ? "white" : 'transparent', color: activeTab === "favourites" ? "#247FEA" : 'white' }} onClick={() => handleTabChange('favourites')}>
                                    <Star sx={{ fontSize: '14px' }} />
                                    <Typography sx={{ fontSize: '14px' }}>Favourites</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', cursor: 'pointer', alignItems: 'center', gap: '5px', p: "2px 5px", borderRadius: '10px', bgcolor: activeTab === "messages" ? "white" : 'transparent', color: activeTab === "messages" ? "#247FEA" : 'white' }} onClick={() => handleTabChange('messages')}>
                                    <Comment sx={{ fontSize: '14px' }} />
                                    <Typography sx={{ fontSize: '14px' }}>Messages</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box sx={{ p: "0px 10px", mt: 2 }}><SearchBar height="30px" placeholder="Search" materialIcon={<Search />} /></Box>
                        <Box sx={{ mt: 1, p: '5px', width: "330px" }}>
                            {
                                activeTab === "favourites" &&
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                        <Avatar sx={{
                                            borderRadius: "7px!important",
                                            marginLeft: "10px !important",
                                            width: { sm: 55, md: 60 },
                                            height: { sm: 55, md: 60 },
                                        }} src={medxpertsIcon} />
                                        <Box>
                                            <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                                                Medxperts Care Assistant
                                            </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>
                                                Dr Practitioner Display Name
                                            </Typography>
                                            <Typography sx={{ fontSize: '14px' }}>
                                                Waiting for you
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Typography sx={{ fontSize: '14px' }}>Today</Typography>
                                </Box>
                            }
                            {
                                activeTab === "messages" && <Typography>Messages</Typography>
                            }
                        </Box>
                    </>)}
            </Card>
        </Box>
    );
}

export default Telemedicine;
