import React, { useState } from 'react'
import URL from '../../../../utility/apiurl'
import axios from 'axios'
import { getConsentsrequested } from '../../../../redux/Actions/consentActions'
import { useDispatch } from 'react-redux'
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat'
import { Box, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Slide, Typography } from '@mui/material';
import CommonButton from '../../../atoms/CommonButton'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'
import { ArrowForwardIos, Close } from '@mui/icons-material'
import PinInputField from '../../../atoms/TextFields/PinInputField'
import { localstore } from '../../../../localStore/localStore'

function EditApprovePin(props) {
    const { selectedCheckboxes, selectedHospitals, handleClose, createat, abhadata, handleCancel, item, token, primaryId } = props
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [pin, setPin] = useState(['', '', '', '']);
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const otpFieldsRef = React.useRef([]);
    const csrftoken = localstore.getCsrfToken()
    const consentId = item.id || item.requestId
    const handleInput = (index, value) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value.length === 1 && index < pin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertmessage('');
        } else if (value !== '') {
            setAlertmessage('Please enter 4 digits number only');
        }
    };

    const handleBackspace = (index) => {
        if (pin[index] !== '') {
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertmessage('');
    };


    const handleVerifyPin = async () => {
        // props.setShow(3)

        setLoading(true)
        let data = {
            pin: pin.join(''),
            scope: "consentrequest.approve"
        }
        // setPinnumber(data.pin)
        await axios.post(`${URL.consents}verifyPin/${primaryId}/${abhadata.AbhaAddress}`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(res => {
                handleApprove(res.data.temporaryToken);

                setAlertmessage('PIN is valid!');
                setAlertstatus('success');
                // handleClose()
            }).
            catch(error => {
                // console.error('Error validating OTP:', error);
                setAlertmessage('PIN Does Not Match!');
                setAlertstatus('error');
                // setAlertMessage('Oops, something went wrong!');
                // setAlertstatus('error');

            }).finally(() => {
                setLoading(false)
            })
    }

    const consents = selectedHospitals.map((hospital, hospitalIndex) => ({
        hiTypes: selectedCheckboxes,
        hip: {
            id: hospital?.hip?.id,
        },
        careContexts: hospital?.careContexts?.map((context) => ({
            patientReference: context?.display,
            careContextReference: context?.referenceNumber,
        })),
    }));

    const FromDate = DateFormat(item?.dateRange?.from, 'toISOString');
    const ToDate = DateFormat(item?.dateRange?.to, 'toISOString');
    const nextYearToISOString = DateFormat(item?.permission?.dataEraseAt, 'nextYearToISOString');

    const mappedData = {

        consents: consents?.map((consent) => ({
            hiTypes: consent?.hiTypes,
            hip: {
                id: consent?.hip.id,
            },
            careContexts: consent?.careContexts?.map((context) => ({
                patientReference: context?.patientReference,
                careContextReference: context?.careContextReference,
            })),
            permission: {
                accessMode: 'VIEW',
                dateRange: {
                    from: createat?.Expiryfrom1,
                    to: createat?.Validto1,
                },
                dataEraseAt: nextYearToISOString,
                frequency: {
                    unit: item?.permission?.frequency?.unit,
                    value: item?.permission?.frequency?.value,
                    repeats: item?.permission?.frequency?.repeats,
                },
            },
        })),
    };

    const handleApprove = (temporaryToken) => {
        if (temporaryToken && consentId) {
            axios
                .post(`${consents}consentrequest/approve/${primaryId}/${consentId}`, mappedData, {
                    headers: {
                        'X-AUTH-TOKEN': temporaryToken,
                    },
                })
                .then(async res => {

                    // await dispatch(getAbhaProfile(primaryId, token));
                    if (res.status === 200) {
                        await dispatch(getConsentsrequested(primaryId, abhadata?.AbhaAddress, 'REQUESTED', token));
                        setAlertmessage('Consent Approve is Successfully !');
                        setAlertstatus('success');
                        handleCancel()
                    }
                }).
                catch(err => {
                    // console.log('-------errorApprove', err);
                    setAlertmessage(
                        'Approve Failed'
                    );
                    setAlertstatus('error');

                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };



    return (
        <div>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}><Typography sx={{ fontSize: '17px', fontWeight: 600, }}>Consent Pin</Typography><IconButton><Close onClick={handleCancel} sx={{ color: 'red', cursor: 'pointer' }} /></IconButton></DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ p: '10px 40px' }}>

                    <Card sx={{ textAlign: "center", p: '10px 0px 30px 0px ', borderBottom: '2px solid gray' }}>
                        <Typography sx={{ fontWeight: 500, }}>
                            Enter Consent Pin
                        </Typography>
                        <Box mt={3}>
                            <div
                                style={{
                                    display: "flex",
                                    gap: "20px",
                                    textAlign: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {pin.map((value, index) => (
                                    <PinInputField
                                        type="text"
                                        maxLength="1"
                                        key={index}
                                        value={value}
                                        onChange={(e) => handleInput(index, e.target.value)}
                                        onKeyDown={(e) => {
                                            if (e.key === "Backspace") {
                                                handleBackspace(index);
                                            }
                                        }}
                                        ref={(ref) => {
                                            otpFieldsRef.current[index] = ref;
                                        }}
                                    />
                                ))}
                            </div>
                        </Box>

                    </Card>
                    {/* <Card sx={{ p: '0px 20px', cursor: 'pointer', display: 'flex', borderBottom: '2px solid gray', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 600 }}>
                    Biometric-Consent
                </Typography>
                <IconButton><ArrowForwardIos sx={{ color: 'black' }} /></IconButton>
            </Card> */}
                    <Card sx={{
                        p: '0px 20px', cursor: 'pointer',
                        boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)", display: 'flex', borderBottom: '2px solid gray', alignItems: 'center', justifyContent: 'space-between'
                    }}>
                        <Typography sx={{ fontWeight: 600 }}>
                            Consent with Password
                        </Typography>
                        <IconButton> <ArrowForwardIos sx={{ color: 'black' }} /></IconButton>
                    </Card>

                    {/* <Typography sx={{ fontSize: '14px', color: '#0822B3', mt: 1, fontWeight: 600, '&:hover': { textDecoration: 'underline' }, textAlign: 'center' }}>Reset Consent Pin?</Typography> */}


                    {/* <Card sx={{ textAlign: 'center', p: '10px' }}>
                <Typography> Enter Consent Pin</Typography>

            </Card> */}
                    {alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />}
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Box sx={{ m: "10px 0px" }}>
                    <CommonButton bgcolor="#9560D9" width="200px" text="Confirm Pin" endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null} onClick={handleVerifyPin} />
                </Box>
            </DialogActions>
        </div >
    )
}

export default EditApprovePin