import { useState } from 'react'
import { DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import axios from 'axios';
import { Box, Button, Card, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material'
import URL from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { useTranslation } from "react-i18next";
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';

function DeleteHealthId1(props) {
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const transactionId = props.transactionId
    const authMethod = props.authMethod
    const HidNumber = props.abhaNum
    const [otp, setOtp] = useState('')
    const [time, setTime] = useState(59);
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const primaryid = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);


    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertMessage('')
    }

    const handleVerify = async () => {
        let d = {
            otp: otp,
            txnId: transactionId,
            authMethod: authMethod,
        };
        setLoading(true);
        console.log('-------body1', d);
        await axios
            .post(`${URL.abha}new/hid/confirmOtp/${primaryid}`, d, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(async res => {
                console.log(res.data, 'kkkkkkkkkkkkk');
                const xtoken = res.data.token
                axios.post(`${URL.consents}account/mobile/generateOTP/${primaryid}`, {}, {
                    headers: {
                        gatewayToken: 'medxperts',
                        'X-Token': `Bearer ${xtoken}`,
                        Authorization: `Bearer ${token}`,

                    },
                }).then(res => {
                    const details = {
                        xtoken: xtoken,
                        txnId: res.data.txnId
                    }
                    dispatch({
                        type: "DELETE_XTOKEN_TXNID",
                        payload: details
                    })
                    props.setShow(3)
                }).catch(err => {
                    setAlertMessage(err.message)
                    setAlertstatus('error')
                })
                // console.log(res.data.healthIdNumber, 'kkkkkkkkkkkkk');
                setAlertMessage('OTP Verified Successfully!');
                setAlertstatus('success');
            })
            .catch(err => {
                console.log('--------errorinconfirm', err);
                console.log(err.message);
                setAlertMessage('OTP entered is incorrect!');
                setAlertstatus('error');
            })
            .finally(() => {
                setLoading(false);
            });
    };


    return (
        <div>
            <DialogTitle
                sx={{
                    display: "flex",
                    gap: 1,
                    alignItems: "center",
                    p: '8px 20px'
                }}
            >

                <IconButton onClick={() => props.setShow(1)}>
                    <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                </IconButton>
                <Typography
                    sx={{
                        fontSize: { xs: 14, sm: "16px", md: "18px" },
                        fontWeight: "500",
                        color: "#007DCD",
                    }}
                >
                    {t("registerdetails")}{" "}
                </Typography>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: { xs: 14, md: 16 },
                                    textAlign: "center",
                                    color: "black",
                                    ml: 1,
                                    fontWeight: 500,
                                }}
                            >
                                {t("verifyotp")}
                            </Typography>

                            <Box mt={2}>
                                <TextInputField
                                    width="350px"
                                    placeholder={t("enterotphere")}
                                    border="none"
                                    name="otp"
                                    value={otp}
                                    onChange={handleOnChangeOtp}
                                />
                            </Box>
                            {alertmessage && (
                                <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                            )}


                            <Box mt={5}>
                                <Typography sx={{ color: "black", fontSize: 12 }}>
                                    {t("enterotp")}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    sx={{ borderRadius: 2, width: "150px", mt: 1, mb: 1 }}
                    onClick={handleVerify}
                    disabled={loading || otp?.length < 6}
                    startIcon={
                        loading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : null
                    }
                >
                    {/* {loading ? "submit.." : "Submit"} */}
                    {t("credentialsData.signupmobiledata.submitbtn")}
                </Button>

            </DialogActions>
        </div >
    );
}

export default DeleteHealthId1