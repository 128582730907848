import React from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Slide, Typography } from '@mui/material'

import { useTranslation } from "react-i18next";

import { useState } from 'react'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { EndAdornment, EndAdornmentTextField } from '../../../atoms/TextFields/AdornmentTextField';
import CommonButton from '../../../atoms/CommonButton';
import { Formik } from 'formik';
import * as yup from 'yup';
import URL, { PasswordpublicKey } from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import axios from 'axios';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function UpdatePassword(props) {

    const { open, setOpen } = props
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPass, setShowNewPass] = useState(false);
    const [showConfirmPass, setShowConfirmPass] = useState(false);
    const handleClickShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
    const handleClickShowNewPassword = () => setShowNewPass((prevShowPassword) => !prevShowPassword);
    const handleClickShowConfirmPassword = () => setShowConfirmPass((prevShowPassword) => !prevShowPassword);

    const signUpValidationSchema = yup.object().shape({

        oldpassword: yup
            .string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(
                /^(?![.])(?!.*[-_.]$).*/, 'Password should not start dot characters..')
            .matches(/\d/, 'Password must have a number')
            .matches(
                /[!@#$%^&*()\-_"=+{}; :,<.>]/,
                'Password must have at least one special character',
            )
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required')
            .test(
                'no-consecutive-numbers',
                'Password should not contain consecutive sequences of numbers',
                (value) => !/\d{4,}/.test(value) // Exclude any consecutive sequences of numbers
            ),
        newpassword: yup
            .string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(
                /^(?![.])(?!.*[-_.]$).*/, 'Password should not start dot characters..')
            .matches(/\d/, 'Password must have a number')
            .matches(
                /[!@#$%^&*()\-_"=+{}; :,<.>]/,
                'Password must have atleaset one special character',
            )
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required')
            .test(
                'no-consecutive-numbers',
                'Password should not contain consecutive sequences of numbers',
                (value) => !/\d{4,}/.test(value)
            ),
        confirmpassword: yup
            .string()
            .oneOf([yup.ref('newpassword')], 'Passwords do not match')
            .required('Confirm password is required'),
    });




    const handleClose = () => {
        setOpen(false)
    }




    return (
        <div>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >


                <Formik
                    validationSchema={signUpValidationSchema}
                    initialValues={{
                        oldpassword: '',
                        newpassword: '',
                        confirmpassword: '',
                    }}
                    onSubmit={(values, { resetForm }) => {
                        const newPassword = values.newpassword;
                        const oldPassword = values.oldpassword;
                        const encrypt = new JSEncrypt()
                        encrypt.setPublicKey(PasswordpublicKey)
                        const encryptNewPassword = encrypt.encrypt(newPassword)
                        const encryptOldPassword = encrypt.encrypt(oldPassword)


                        let data = {
                            newPassword: encryptNewPassword.replace(/\n/g, '').replace(/\r/g, ''),
                            oldPassword: encryptOldPassword.replace(/\n/g, '').replace(/\r/g, '')
                        };
                        // console.log(data, 'data')
                        setLoading(true);

                        axios
                            .post(`${URL.consents}account/change/passwd/${primaryId}`, data, {
                                headers: {
                                    'gatewayToken': 'medxperts',
                                    'Authorization': `Bearer ${token}`,
                                },
                            })
                            .then(async res => {
                                // console.log('---------res', res);
                                // navigation.navigate('Healthprofile', { showPopup: false });
                                setAlertmessage('Password Updated Successfully! ');
                                setAlertstatus('success');
                                // handleClose(resetForm)
                            })
                            .catch(err => {
                                // console.log(err, 'messageresetpassword');
                                setAlertmessage(err.message);
                                setAlertstatus('error');

                                setLoading(false);
                            })
                    }}

                >
                    {({
                        handleSubmit,
                        isValid,
                        values,
                        errors,
                        handleChange,
                        handleBlur,
                        touched,
                        resetForm
                    }) => (
                        <>
                            <DialogTitle
                                sx={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                }}
                            >
                                <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                                    {/* <IconButton> <Delete sx={{ color: 'red' }} /></IconButton> */}
                                    <Typography sx={{ fontSize: 600, fontSize: "17px" }}>Update Password</Typography>
                                </Box>
                                <Button
                                    sx={{
                                        height: "25px",
                                        color: "#B48FE4",
                                        borderRadius: "15px",
                                    }}
                                    variant="outlined"
                                    onClick={() => {
                                        resetForm()
                                        handleClose()
                                    }}
                                >
                                    Cancel
                                </Button>
                            </DialogTitle>
                            <Divider />

                            <DialogContent >
                                <Box sx={{ p: "10px 30px" }}>
                                    <Box >
                                        <EndAdornmentTextField
                                            label="Old Password"
                                            placeholder="Enter Old Password"
                                            type={showPassword ? 'text' : 'password'}
                                            icon={showPassword ? <Visibility /> : <VisibilityOff />}
                                            value={values.oldpassword}
                                            onChange={handleChange('oldpassword')}
                                            handleBlur={handleBlur('oldpassword')}
                                            onClick={handleClickShowPassword}
                                        // onMouseDown={handleMouseDownPassword}
                                        />
                                        {errors.oldpassword && touched.oldpassword && (
                                            <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                                {errors.oldpassword}</Typography>
                                        )}
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <EndAdornmentTextField
                                            label="New Password"
                                            placeholder="Create New Password"
                                            type={showNewPass ? 'text' : 'password'}
                                            icon={showNewPass ? <Visibility /> : <VisibilityOff />}
                                            value={values.newpassword}
                                            onChange={handleChange('newpassword')}
                                            onBlur={handleBlur('newpassword')}
                                            onClick={handleClickShowNewPassword} />
                                        {errors.newpassword && touched.newpassword && (
                                            <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                                {errors.newpassword}</Typography>
                                        )}
                                    </Box>
                                    <Box sx={{ mt: 3 }}>
                                        <EndAdornmentTextField
                                            label="Confirm Password"
                                            placeholder="Confirm Password"
                                            type={showConfirmPass ? 'text' : 'password'}
                                            icon={showConfirmPass ? <Visibility /> : <VisibilityOff />}
                                            value={values.confirmpassword}
                                            onChange={handleChange('confirmpassword')}
                                            onBlur={handleBlur('confirmpassword')}
                                            onClick={handleClickShowConfirmPassword} />
                                        {errors.confirmpassword && touched.confirmpassword && (
                                            <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                                {errors.confirmpassword}
                                            </Typography>
                                        )}
                                    </Box>
                                </Box>
                            </DialogContent>
                            <Divider />
                            <Box sx={{ textAlign: 'center' }}>
                                {alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />}
                            </Box>
                            <DialogActions sx={{ justifyContent: 'center' }}>
                                <Box sx={{ m: "10px 0px" }}>
                                    <CommonButton text="Submit" width="150px" startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} onClick={handleSubmit} />
                                </Box>
                            </DialogActions>
                        </>)}
                </Formik>


            </Dialog>
        </div >
    );
}

export default UpdatePassword;