
import React, { useEffect, useRef, useState } from 'react';
import { Paper, Box, Typography, Button, Grid } from '@mui/material';
import venkatIcon from "../../../../assets-new/venkatreddy.jpg";
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getAbhaCard, getAbhaQRCode } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import URL from '../../../../utility/apiurl';
import { Delete } from '@mui/icons-material';
import { OutlinedButton } from '../../../atoms/CommonButton';

const ProfileUpdate = ({ mobiledata, abhadata }) => {
    const [selectedImage, setSelectedImage] = useState(null);
    const [image, setImage] = useState("")
    const ibm = useSelector(state => state.medx.ibmpdata)
    console.log(ibm, "ibmmmmm")
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const patientId = localstore.getPatientId()
    const primaryId = localstore.getPrimaryId()
    const token = localstore.getToken()
    const dispatch = useDispatch()
    const fileInputRef = useRef(null);

    const imageHandler = (e, imgFile) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            reader.onload = () => {
                const newImage = reader.result;
                setImage(newImage);
                setSelectedImage(e.target.files[0]);
                handleImageSubmit(newImage);
            };
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    const handleImageSubmit = (newImage) => {
        const dateOfBirth = mobiledata.dateOfBirth;
        const day = dateOfBirth?.date;
        const month = dateOfBirth?.month;
        const year = dateOfBirth?.year;
        const data = {
            'addressLine': mobiledata?.address,
            'gender': mobiledata?.gender,
            'dayOfBirth': day,
            'monthOfBirth': month,
            'yearOfBirth': year,
            'districtCode': mobiledata?.districtCode,
            'firstName': mobiledata?.name?.first,
            'lastName': mobiledata?.name?.last,
            'middleName': mobiledata?.name?.middle,
            'pinCode': mobiledata?.pincode,
            'stateCode': mobiledata?.stateCode,
            'photo': newImage.split(",")[1],
        };
        axios.post(`${URL.consents}account/profile/updatePicture/${primaryId}`, data, {
            headers: { 'Authorization': `Bearer ${token}`, gatewayToken: 'medxperts' },
        }).then(res => {
            dispatch(getAbhaCard(token, primaryId));
            dispatch(getAbhaQRCode(token, patientId, abhadata.AbhaAddress, primaryId));
            setAlertmessage('Profile Photo Updated Successfully');
            setAlertstatus('success');
        }).catch(err => {
            setAlertmessage(err.message);
            setAlertstatus('error');
        });
    }

    return (
        <Paper elevation={3} sx={{ p: { xs: '10px 10px', md: "15px 20px" }, borderRadius: '15px' }}>
            <Box sx={{ textAlign: 'center', height: { xs: '190px', sm: '180px', md: '170px' } }}>
                <Typography sx={{ fontWeight: 600 }}>Profile Picture</Typography>
                <Box sx={{ mt: { xs: 2, sm: 2, md: 1 } }}>
                    {ibm.photo?.[0]?.url ? (
                        <img
                            src={ibm.photo?.[0]?.url}
                            alt="media"
                            style={{
                                objectFit: "cover",
                                width: "80px",
                                height: "80px",
                                cursor: "pointer",
                                borderRadius: "10px",
                            }}
                        />
                    ) : (
                        <img
                            src={venkatIcon}
                            alt="media"
                            style={{
                                objectFit: "cover",
                                width: "80px",
                                height: "80px",
                                cursor: "pointer",
                                borderRadius: "10px",
                            }}
                        />

                    )}
                </Box>
                <Box sx={{ mt: { xs: 2, sm: 2, md: 2 }, display: 'flex', gap: { xs: 1, sm: 0, md: 0 }, justifyContent: 'space-around' }}>
                    <Button variant="outlined" sx={{ width: { xs: '80px', sm: '100px', md: '100px' }, height: '30px', borderColor: '#785D9B', borderRadius: "10px" }} onClick={() => fileInputRef.current.click()}>
                        <Typography sx={{ color: 'black' }} fontSize={{ xs: '12px', sm: '14px', md: '14px' }}>Modify</Typography>
                    </Button>
                    <input
                        type="file"
                        name="uploadfile"
                        multiple
                        id="fileInput"
                        accept=".png, .jpg, .jpeg, .pdf"
                        style={{ display: "none" }}
                        onChange={(e) => imageHandler(e, "personal")}
                        ref={fileInputRef}
                    />
                    <OutlinedButton text="Delete" fontSize={{ xs: "12px", sm: "14px", md: '14px' }} borderColor="#785D9B" color="#FF0000" width={{ xs: "80px", sm: '100px', md: "100px" }} height="30px" startIcon={<Delete sx={{ color: '#FF0000' }} />} />
                </Box>
            </Box>
        </Paper>
    );
};

export default ProfileUpdate;
