import React from 'react'
import { Security } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, Grid, Typography } from '@mui/material'
import CssTextField from '../../../atoms/TextFields/InputFieldwhite'
import URL from '../../../../utility/apiurl'
import axios from 'axios'
import { localstore } from '../../../../localStore/localStore'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'

function ForgotConsentPin(props) {
    // console.log(props, "propsssss")
    const forgotPin = useSelector((state) => state.abha.forgotGenerateotpdata)
    // console.log(forgotPin, "fffffff")
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()

    const [otp, setOtp] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const [time, setTime] = useState(59)

    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertMessage('')
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);

    const handleVerifyOtp = async () => {
        setLoading(true)
        props.setShow(4)

        // let data = {
        //     sessionId: forgotPin?.sessionId,
        //     value: otp
        // }
        // setLoading(true)
        // await axios.post(`${URL.consents}forgotPin/validateOtp/${primaryId}/${props.phraddress}`, data, { headers: { 'Authorization': `Bearer ${token}` } }).
        //     then(res => {
        //         // console.log(res, "verifyy")
        //         setAlertopen(true);
        //         setAlertMessage('OTP Verified Successfully!');
        //         setAlertstatus('success');
        //         dispatch({
        //             type: "CONSENT_FORGOTPIN_TOKEN",
        //             payload: res.data.temporaryToken
        //         })
        //         props.setShow(4)

        //     }).catch(err => {
        //         setAlertopen(true);

        //         setAlertMessage('OTP entered is incorrect or expired.');

        //         setAlertstatus('error');
        //     }).finally(() => {
        //         setLoading(false)
        //         setOtp('')
        //     })
    }

    // console.log(time, "timeee")
    const handleResendOtp = async () => {
        setTime(59);

        await axios.post(`${URL.consents}forgotPin/generateOtp/${primaryId}/${props.phraddress}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(res => {
                console.log(res, "resendPin")
                // setAlertMessage('OTP sent to mobile number!');
                setAlertstatus('success');
                dispatch({
                    type: 'FORGOTPIN_RESENDOTP_DATA',
                    payload: res.data
                })
            }).catch(err => {
                console.log(err.message);
                setAlertMessage('Oops something went wrong!');
                setAlertstatus('error');
            }).finally(() => {
                setLoading(false);
                setOtp(''); // Clear the OTP input field
            });
    }


    const handleCloseForgot = () => {
        props.setShow(1)
        setOtp('')
        props.handleClose()
        props.setAlertMessage('')
    }
    return (
        <div> <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                    <Security />
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Consent Security Reset PIN</Typography>
                </Box>
                <Button sx={{ height: "25px", color: '#B48FE4', borderRadius: "15px" }} variant='outlined' onClick={handleCloseForgot}>Done</Button>
            </DialogTitle>
            <Divider />

            <DialogContent >
                <Card sx={{ textAlign: 'center', mt: 1, p: 2, }}>
                    <Typography sx={{ fontWeight: 500 }}>Validate OTP </Typography>

                    <Grid container mt={2}>
                        <Grid item xs={12}>
                            <CssTextField placeholder="Enter OTP Here" name="otp" value={otp} onChange={handleOnChangeOtp} />
                        </Grid>
                    </Grid>
                    <Box sx={{ display: 'flex', ml: 1, mr: 1, mt: "5px", alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: { xs: 12, md: 14 }, fontWeight: 600 }}>{`00:${time}`}</Typography>
                        <Button sx={{ textTransform: 'capitalize' }} disabled={time > 0}><Typography sx={{ fontSize: { xs: 10, md: 12 } }} onClick={handleResendOtp}
                        >{"Resend OTP"}</Typography></Button>
                    </Box>
                    {alertmessage && (
                        <Typography sx={{ color: alertstatus === 'success' ? 'green' : 'red', mt: 1, fontSize: '14px' }}>{alertmessage}</Typography>
                    )}
                </Card>

                <Typography sx={{ color: 'black', ml: 1, mt: 2, fontSize: '14px' }} component={"p"}>OTP has been sent to your registered {forgotPin?.otpMedium}      {forgotPin?.otpMediumValue?.slice(4)} </Typography>


            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button sx={{ bgcolor: '#9560D9', width: '120px', borderRadius: "10px", m: "10px 0px" }} onClick={handleVerifyOtp} variant='contained'
                    disabled={otp.length < 6}
                    startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                >Submit</Button>
            </DialogActions>
        </></div>
    )
}

export default ForgotConsentPin