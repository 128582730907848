import React, { useState, useEffect } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTranslation } from "react-i18next";
import { Routes, Route, Link, BrowserRouter, Outlet } from 'react-router-dom';

import img1 from "../../images/111111111111/11.svg";
import img2 from "../../images/111111111111/22.svg";
import img3 from "../../images/111111111111/33.svg";
import img4 from "../../images/111111111111/44.svg";
import img5 from "../../images/111111111111/55.svg";


function CommonDesign({ children }) {
    const images = [img1, img2, img3, img4, img5];
    const [value, setValue] = useState(0);
    const [containerImage, setContainerImage] = useState(images[0]);
    const [t, i18n] = useTranslation("global");

    useEffect(() => {
        const interval = setInterval(() => {
            setValue((v) => (v === 4 ? 0 : v + 1));
            setContainerImage(images[value]);
        }, 5000);

        return () => clearInterval(interval);
    }, [value]);

    return (
        <Grid container
            sx={{
                // height: '100vh',
                height: {
                    xs: 'calc(100vh - 0px) ', sm: 'calc(100vh - 0px)', md: 'calc(100vh - 97px)'
                },
                backgroundImage: `url(${containerImage})`,
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }}>
            < CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
            // sx={{
            //     backgroundImage: 'url(https://source.unsplash.com/random?wallpapers)',
            //     backgroundRepeat: 'no-repeat',
            //     backgroundColor: (t) =>
            //         t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
            //     backgroundSize: 'cover',
            //     backgroundPosition: 'center',
            // }}
            />
            <Grid item xs={12} sm={8} md={4.6} component={Paper} elevation={6} square>

                {/* <Box
                    sx={{
                        my,
                        mx,
                        display: display,
                        flexDirection: flexDirection,
                        alignItems: alignItems,
                        bgcolor: bgcolor
                    }}
                > */}
                {children}
                {/* </Box> */}
            </Grid>
        </ Grid >
    );
}

export default CommonDesign