export const localstore = {
    getToken: () => localStorage.getItem('kctoken'),
    getUserId: () => localStorage.getItem('userId'),
    getPatientId: () => localStorage.getItem('patientId'),
    getPatientName: () => localStorage.getItem('patientName'),
    getPrimaryId: () => localStorage.getItem('primaryId'),
    getPrimaryFixedId: () => localStorage.getItem('primaryFixedid'),
    getCsrfToken: () => localStorage.getItem('csrftoken')
}


