import './App.css';
import { ThemeProvider, createTheme } from "@mui/material"
import Store from './redux/store/store'
import { Provider } from 'react-redux';
import Approutes from './Approutes';

function App() {

  // console.log(UserService.getToken())

  const theme = createTheme({
    components: {
      MuiButton: {
        defaultProps: {
          disableRipple: true,
        },
        // styleOverrides: {
        //   outlined: {
        // borderColor: "#59D8FA",
        // borderRadius: "10px",
        // borderWidth: "3px",
        // color: "#3D6596",
        // fontFamily: "Quicksand",
        // // fontSize: "17px",
        // fontSize: "13px",
        //   }
        // },
      }
    },
    typography: {
      fontSize: 14,
      fontFamily:
        'Inter',

      // fontFamily: 'Inter',
      // '-apple-system', 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue','sans-serif'",
      fontStyle: 'normal',
      color: '#333',
      button: {
        textTransform: "none"
      },
    }

  })

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Provider store={Store}>
          {/* <Home /> */}
          <Approutes />
        </Provider>

      </ThemeProvider>

    </div>
  );
}

export default App;
