import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom'
import prescriptionIcon from '../../../../assets/home/consent_module/prescription.svg';
import consultationIcon from '../../../../assets/home/consent_module/consultation.svg';
import diagnosticIcon from '../../../../assets/home/consent_module/diagnostic.svg';
import dischargeIcon from '../../../../assets/home/consent_module/discharge.svg';
import immunisationIcon from '../../../../assets/home/consent_module/immunisation.svg';
import documentIcon from '../../../../assets/home/consent_module/document.svg';
import WellnessRecordIcon from '../../../../assets/home/consent_module/lifestyle.svg';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { localstore } from '../../../../localStore/localStore';
import { FHIRBundle } from '../../../../redux/Actions/recordActions';
import { Box, Divider, Typography } from '@mui/material';
import FileTextIcon from '../../../../assets/home/consent_module/file-text.svg';
import { Compare, Image, PictureAsPdf } from '@mui/icons-material';
import { getAbhaMobile } from '../../../../redux/Actions/Actions';

function FullRecords() {
    const location = useLocation()
    const list = location?.state
    const Data = list?.Data
    const EncouterId = list?.EncouterId
    const HiType = list?.HiType
    const MRN = list?.MRN
    const Display = list?.Display
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const [recorddetails, setRecordDetails] = useState([]);
    const [base64, setBase64] = useState([]);
    const [base64data, setBase64Data] = useState([]);
    const [encounterDetails, setEncounterDetails] = useState([]);
    const dispatch = useDispatch();
    let FhirBundle = useSelector(stat => stat.records.FhirBundle);
    let mobiledata = useSelector(stat => stat.abha.mobileprofile);
    console.log(FhirBundle, "hiiiiiiii")
    // console.log(list, 'list')
    const navigate = useNavigate()

    useEffect(() => {
        const entriesWithSearchString = [];
        const Base64SearchString = [];
        if (Data && Data.section) {

            Data?.section?.map((entries) => {

                entries?.entry?.forEach((ref) => {
                    // Log the reference value
                    // console.log('FhirBundleref', ref);
                    if (ref?.reference?.includes('Binary') || ref?.reference?.includes('DocumentReference') || ref?.reference?.includes('DiagnosticReport')) {
                        Base64SearchString?.push(ref.reference);
                    }
                    FhirBundle?.forEach((bundleEntry) => {
                        // console.log('bundleEntry', bundleEntry);
                        if (bundleEntry?.fullUrl?.includes(ref?.reference)) {
                            entriesWithSearchString?.push(bundleEntry);
                        }
                    });
                });
            });
        }
        setRecordDetails(entriesWithSearchString);
        setBase64(Base64SearchString);
    }, [FhirBundle, Data]);
    useEffect(() => {
        let updatedArrayBase64 = [];
        if (base64.length > 0 && token.length > 0) {
            base64.map((item) => {

                axios.get(`${URL.cliniapi}${item}/_history`, {
                    headers: {
                        Authorization: token,
                    },
                })
                    .then(async res => {

                        await updatedArrayBase64?.push(res?.data?.entry?.[0]);
                        await setBase64Data(updatedArrayBase64);

                    }).catch(err => {
                        console.log(err, 'Base64ResponseError');
                    });
            });

        }
    }, [base64, token]);

    useEffect(() => {
        if (token && primaryId) {
            // dispatch(SingleRecord(token, Data.id));
            dispatch(FHIRBundle(token, primaryId));
            dispatch(getAbhaMobile(primaryId, token))
        }
    }, [primaryId, Data]);

    useEffect(() => {
        if (EncouterId !== null && EncouterId !== undefined && token.length > 0) {
            axios.get(`${URL.cliniapi}${EncouterId}`, {
                headers: {
                    Authorization: token,
                },
            })
                .then(res => {
                    setEncounterDetails(res.data);
                }).catch(err => {
                    console.log(err, 'EncouterIdError');
                });
        }

    }, [EncouterId]);


    const HiTypeIcons = (type) => {
        if (type === "DiagnosticReport") {
            return <img src={diagnosticIcon} alt="diagnostic" width={25} height={25} />
        }
        if (type === "DischargeSummary") {
            return <img src={dischargeIcon} alt="discharge" width={25} height={25} />
        }
        if (type === "OPConsultation") {
            return <img src={consultationIcon} alt="consult" width={25} height={25} />
        }
        if (type === "ImmunizationRecord") {
            return <img src={immunisationIcon} alt="record" width={25} height={25} />
        }
        if (type === "WellnessRecord") {
            return <img src={WellnessRecordIcon} alt="wellness" width={25} height={25} />
        }
        if (type === "HealthDocumentRecord") {
            return <Compare />
        }
        if (type === "Prescription") {
            return <img src={prescriptionIcon} alt="prescription" width={25} height={25} />
        }
        if (type === "reports") {
            return <img src={FileTextIcon} alt="reports" width={25} height={25} />
        }
        if (type === '') {
            return <img src={FileTextIcon} alt="file" height={25} width={25} />;
        } else {
            return <img src={FileTextIcon} alt="file" height={25} width={25} />;
        }
    }


    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: 2 }}>
                {HiTypeIcons(HiType)}
                <Box>
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>{HiType}</Typography>
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>{Display}</Typography>
                </Box>
            </Box>
            <Divider sx={{ mt: 2 }} />

            <Box >
                {encounterDetails?.type ?
                    <>
                        <Box >
                            <Box >
                                <Box>
                                    <Typography >{encounterDetails?.type ? encounterDetails?.type[0].coding[0].display : null}</Typography>

                                </Box>
                            </Box>
                            <Box>
                                <Typography>{null}</Typography>
                            </Box>
                        </Box>
                        <Divider />
                    </>
                    : null}
                <Box sx={{ mt: 2 }}>
                    <Typography sx={{ fontSize: '14px' }}>This is a OP Consult Note for patient {mobiledata?.fullName} Medical Record Number = {MRN};
                    </Typography>
                    <Typography sx={{ fontSize: '14px' }}>Ph :{mobiledata?.mobile} Gender :{mobiledata?.gender} Birdthdate : {mobiledata?.dateOfBirth?.date} / {mobiledata?.dateOfBirth?.month} / {mobiledata?.dateOfBirth?.year}</Typography>
                </Box>
                <Divider sx={{ mt: 2 }} />
                {recorddetails.map((item, i) => {
                    return (
                        (item.resource.resourceType === 'Condition' ||
                            item.resource.resourceType === 'Observation' ||
                            item.resource.resourceType === 'Procedure' ||
                            item.resource.resourceType === 'AllergyIntolerance' ||
                            item.resource.resourceType === 'MedicationRequest') ? (
                            <Box key={i}>
                                <Typography >{item.resource.resourceType}</Typography>
                                {
                                    item.resource.resourceType === 'Observation' ?
                                        <>
                                            <Box >
                                                <Typography>Display</Typography>
                                                <Typography >Value</Typography>
                                            </Box>
                                            <Box>
                                                <Typography >{item?.resource?.code?.coding[0]?.display}</Typography>
                                                <Typography >{item?.resource?.valueQuantity?.value}{item?.resource?.valueQuantity?.unit
                                                }</Typography>

                                            </Box>
                                        </>


                                        : <Typography >{' \u25CF '}{item?.resource?.code?.coding?.[0]?.display}</Typography>
                                }
                            </Box>
                        ) : null
                    );
                })}
                {base64data.map((item, i) => {
                    return (
                        <Box key={i}>
                            <Typography sx={{ bgcolor: 'black', color: 'white', p: '5px 20px' }}>{item?.resource?.resourceType}</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, gap: 2, cursor: 'pointer' }} onClick={() => navigate('/records/imgorpdf', {
                                state: {
                                    Data: item?.resource?.data,
                                    Type: item?.resource?.contentType,
                                }
                            })}>
                                <Box >
                                    {item?.contentType === 'png' || 'image' ?
                                        <Image sx={{ fontSize: '45px' }} /> : <PictureAsPdf sx={{ fontSize: '45px' }} />
                                    }
                                </Box>
                                <Box >
                                    <Typography sx={{ fontSize: '14px' }}>{item?.resource?.contentType}</Typography>
                                    <Typography sx={{ fontSize: '14px' }}>{item?.resource?.id.slice(0, 45)}</Typography>
                                </Box>
                            </Box>
                        </Box>
                    );
                })}
            </Box>
        </Box >
    )
}

export default FullRecords