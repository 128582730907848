import { TextField } from '@mui/material'
import React from 'react'

function CssTextField(props) {
    return (
        <div><TextField

            variant="standard"
            fullWidth
            placeholder={props.placeholder}
            name={props.name}
            value={props.value}
            disableUnderline={false}
            onChange={props.onChange}
            autoComplete='off'
            autoFocus
            InputProps={{

                disableUnderline: true,

            }}
            {...props}
            sx={{
                boxShadow: "2px 3px 10px lightgrey",

                borderRadius: "10px",

                padding: "5px 20px !important",

                backgroundColor: "white",

                border: "none",
            }}

        />
        </div>
    )
}

export default CssTextField