import React, { useEffect, useState } from 'react'
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material';
import CommonDialog from '../../../atoms/CommonDialog';
import { Close, ExpandLess, ExpandMore, Pending } from '@mui/icons-material';
import hospitalIcon from '../../../../assets/home/consent_module/hospital.svg'
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat';
import medxpertLogo from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import prescriptionIcon from '../../../../assets/home/consent_module/prescription.svg';
import consultationIcon from '../../../../assets/home/consent_module/consultation.svg';
import diagnosticIcon from '../../../../assets/home/consent_module/diagnostic.svg';
import dischargeIcon from '../../../../assets/home/consent_module/discharge.svg';
import immunisationIcon from '../../../../assets/home/consent_module/immunisation.svg';
import documentIcon from '../../../../assets/home/consent_module/document.svg';
import WellnessRecordIcon from '../../../../assets/home/consent_module/lifestyle.svg';
import CommonCalender from '../../../atoms/CommonCalender';
import moment from 'moment';
import dayjs from 'dayjs';

const renderIcon2 = (type) => {
    let data = {
        name: null,
        icon: null,
    };
    if (type === 'Prescription') {
        data = {
            name: 'Prescription',
            icon: <img src={prescriptionIcon} height={20} width={20} color="grey" />,
        };
    }
    if (type === 'DiagnosticReport') {
        data = {
            name: 'DiagnosticReport',
            icon: <img src={diagnosticIcon} height={20} width={20} color="grey" />,
        };
    }

    if (type === 'DischargeSummary') {
        data = {
            name: 'DischargeSummary',
            icon: <img src={dischargeIcon} height={20} width={20} color="grey" />,
        };
    }

    if (type === 'OPConsultation') {
        data = {
            name: 'OPConsultation',
            icon: <img src={consultationIcon} height={20} width={20} color="grey" />,
        };
    }
    if (type === 'WellnessRecord') {
        data = {
            name: 'WellnessRecord',
            icon: <img src={WellnessRecordIcon} height={20} width={20} color="grey" />,
        };
    }
    if (type === 'ImmunizationRecord') {
        data = {
            name: '"ImmunizationRecord"',
            icon: <img src={immunisationIcon} height={20} width={20} color="grey" />,
        };
    }
    if (type === 'HealthDocumentRecord') {
        data = {
            name: 'HealthDocumentRecord',
            icon: <img src={documentIcon} height={20} width={20} color="grey" />,
        };
    }
    return (
        <>
            <Box sx={{ marginTop: "5px", ml: '10px' }}>
                {data.icon}
            </Box>

        </>
    );
};



function ActiveSubcriptions({ open, setOpen, lockerNamedata, subscription, token, primaryId, phraddress }) {
    const [expand, setExpand] = useState(false);
    const [subid, setSubID] = useState(null);
    const [subdata, setSubData] = useState(null);
    const [hlthLockdata, setHlthLockData] = useState(null);
    const [isVisible, setIsVisible] = useState(false);
    const [isinform, setIsInForm] = useState(false);
    const [createat, setCreateAt] = useState({ Date: null, Time: null, Validto: null, Expiryfrom: null, Validto1: null, Expiryfrom1: null });
    const [selectedDropdownItems, setSelectedDropdownItems] = useState([]);
    const [UnselectedDropdownItems, setUnSelectedDropdownItems] = useState([]);
    const [selectedHospitals, setSelectedHospitals] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);
    const [date, setDate] = useState(new Date());
    const [isChecked, setChecked] = useState(false);
    const [category, setCategory] = useState(['LINK', 'DATA']);
    const [checkedCategories, setCheckedCategories] = useState(['LINK', 'DATA']);

    const [options, setOptions] = useState([
        'Prescription',
        'DiagnosticReport',
        'OPConsultation',
        'DischargeSummary',
        'ImmunizationRecord',
        'HealthDocumentRecord',
        'WellnessRecord',
    ]);
    // Assuming you have a state to track the open dropdown index
    const [openStates, setOpenStates] = useState([]);
    const [isCheckedState, setIsCheckedState] = useState({});
    const [selectedRecords, setSelectedRecords] = useState({}); // Store selected records
    const [hospitallinks, setHospitalLinks] = useState([]);
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [fromValues, setFromValues] = useState({});
    const [toValues, setToValues] = useState({});
    const [pastVisitsChecked, setPastVisitsChecked] = useState(true);
    const [upcomingVisitsChecked, setUpcomingVisitsChecked] = useState(true);
    const [spinnnerloading, setspinnnerloading] = useState(false);
    const [datePickerVisibility, setDatePickerVisibility] = useState({
        from: false,
        to: false,
    });

    // console.log(hospitallinks, "hospitallinks")
    // console.log(selectedRecords, "selectedRecords")
    // console.log(isCheckedState, "isCheckedState")
    // console.log(openDropdownIndex, "openDropdownIndex")
    const getHandlelockersubdata = async (tkn, id1, AbhaAddress1, subid1) => {
        try {
            const response = await axios.get(`${URL.consents}health-locker/subscription-requests/${id1}/${AbhaAddress1}/${subid1}`, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${tkn}`,
                },
            });
            setHlthLockData(response.data);

        } catch (error) {
            console.log('Error in the API call:', error);
        }

    };

    useEffect(() => {
        setHospitalLinks(hlthLockdata?.includedSources);
    }, [hlthLockdata]);

    useEffect(() => {
        if (token && primaryId && phraddress && subid) {
            getHandlelockersubdata(token, primaryId, phraddress, subid);

        }
    }, [token, primaryId, phraddress, subid]);

    //ALL HIPS AND HITYPES HANDLERS


    useEffect(() => {

        const currentDate = moment();
        const formattedDate = currentDate.format('DD-MMM-YYYY');
        const formattedTime = currentDate.format('HH:mm:ss');

        // Set Expiryfrom to the current date and time
        const expiryFromDate = currentDate.format('DD-MMM-YYYY');

        // Add 2 years to the current date and time for Validto
        const validToDate = currentDate.add(10, 'years').format('DD-MMM-YYYY');

        setCreateAt((prev) => ({
            ...prev,
            Date: formattedDate,
            Time: formattedTime,
            Expiryfrom: expiryFromDate,
            Expiryfrom1: expiryFromDate,
            Validto: validToDate,
            Validto1: validToDate,
        }));

    }, [subscription]);


    useEffect(() => {
        // Autofill the hospitals and their records on component mount
        // handleallcheckbox();
        // Initialize selectedItems with all option names when the component mounts
        handleDropdownSelect(options?.map((item) => item));
        handleDropdownUnSelect([]);

    }, [options]);

    // Function to toggle dropdown visibility
    const toggleDropdown1 = (index) => {
        if (!isChecked) {
            setOpenDropdownIndex(openDropdownIndex === index ? null : index);
        }
    };
    const handleCheckboxToggle = () => {
        setChecked((prevChecked) => !prevChecked);
    };

    const handleDropdownSelect = (selectedItems) => {
        setSelectedDropdownItems(selectedItems);
    };
    const handleDropdownUnSelect = (selectedItems) => {
        setUnSelectedDropdownItems(selectedItems);
    };

    const handleSelectItem = (item) => {
        handleDropdownSelect((prevSelectedItems) => {
            if (prevSelectedItems.includes(item)) {
                return prevSelectedItems.filter((selectedItem) => selectedItem !== item);
            } else {
                return [...prevSelectedItems, item];
            }
        });
        handleDropdownUnSelect((prevUnselectedItems) => {
            if (prevUnselectedItems.includes(item)) {
                return prevUnselectedItems.filter((unSelectedItem) => unSelectedItem !== item);
            } else {
                return prevUnselectedItems.concat(item); // Add the item to unselected
            }
        });
    };

    const handleCategoryToggle = (categoryName) => {
        if (checkedCategories.includes(categoryName)) {
            setCheckedCategories(checkedCategories.filter((cat) => cat !== categoryName));
        } else {
            setCheckedCategories([...checkedCategories, categoryName]);
        }
    };


    const handleDateChange = (date) => {
        let newdate = moment(date).format('DD-MMM-YYYY');
        let ISODATE = date.toISOString();
        setCreateAt((prev) => ({
            ...prev,
            Expiryfrom: newdate,
            Expiryfrom1: ISODATE,
        }));
    };

    const handleDateUpdate = (type, hospitalIndex, newDate) => {


        // Ensure selectedHospitals[hospitalIndex] exists
        if (!selectedHospitals[hospitalIndex]) {
            return;
        }

        // Create a copy of the selectedHospitals array to modify
        const updatedSelectedHospitals = [...selectedHospitals];
        // Update the 'from' or 'to' property based on the type
        updatedSelectedHospitals[hospitalIndex].period[type] = newDate
            .toISOString()
            .split('T')[0];


        // Update the state with the modified array
        setSelectedHospitals(updatedSelectedHospitals);
        setDatePickerVisibility({
            ...datePickerVisibility,
            [type]: false,
        });
    };


    const handleToDateChange = (date) => {
        let newdate = moment(date).format('DD-MMM-YYYY');
        let ISODATE = date.toISOString();
        setCreateAt((prev) => ({
            ...prev,
            Validto: newdate,
            Validto1: ISODATE,
        }));
    };



    {/*INFORMATION SOURCE HANDLERS   START*/ }

    const handleallcheckbox = () => {
        // Check if all checkboxes are selected
        const areAllCheckboxesSelected = selectedHospitals?.length === hospitallinks?.length;
        // Select all hospitals
        const selectedHospitalsCopy = [...hospitallinks];
        const selectedRecordsCopy = {};
        const isCheckedStateCopy = {};


        // Create an object with all records selected for each hospital
        for (let i = 0; i < selectedHospitalsCopy?.length; i++) {
            const hipId = selectedHospitalsCopy[i]?.hip?.id;
            const hiTypes = selectedHospitalsCopy[i]?.hiTypes;

            selectedRecordsCopy[hipId] = hiTypes ? [...hiTypes] : [];
            isCheckedStateCopy[i] = true;
        }

        setSelectedHospitals(selectedHospitalsCopy);
        setSelectedRecords(selectedRecordsCopy);
        setIsCheckedState(isCheckedStateCopy);
        // }
    };

    useEffect(() => {
        if (expand === true) {
            handleallcheckbox();
        } else {
            return;
        }
    }, [hospitallinks]);


    const handlehospitaldata = (index) => {
        // Get the hospital at the given index
        const hospital = hospitallinks[index];
        const hipId = hospital?.hip?.id;

        // Find the selected hospital's index in the selectedHospitals array
        const selectedHospitalIndex = selectedHospitals.findIndex(
            (selected) => selected?.hip?.id === hipId
        );

        if (selectedHospitalIndex !== -1) {
            // If the hospital is already selected, deselect it
            const updatedSelection = selectedHospitals.filter(
                (selected) => selected?.hip?.id !== hipId
            );

            // Deselect all records for this hospital by removing it from selectedRecords
            const updatedSelectedRecords = { ...selectedRecords };
            delete updatedSelectedRecords[hipId];

            setSelectedHospitals(updatedSelection);
            setSelectedRecords(updatedSelectedRecords);
            setIsCheckedState((prevState) => ({
                ...prevState,
                [index]: false,
            }));
        } else {
            // If the hospital is not selected, select it
            const updatedSelection = [...selectedHospitals, hospital];

            // Select all records for this hospital or add them if not present
            const recordsCount = hospital?.hiTypes?.length;
            const existingSelectedRecords = selectedRecords[hipId] || [];
            const updatedSelectedRecords = {
                ...selectedRecords,
                [hipId]: [...existingSelectedRecords, ...Array.from({ length: recordsCount }, (_, i) => i)],
            };

            setSelectedHospitals(updatedSelection);
            setSelectedRecords(updatedSelectedRecords);
            setIsCheckedState((prevState) => ({
                ...prevState,
                [index]: true,
            }));
        }
    };
    const handlerecords = (hospitalIndex, record) => {
        const hospital = hospitallinks[hospitalIndex];
        const hipId = hospital?.hip?.id;

        const selectedHospitalIndex = selectedHospitals?.findIndex(
            (selected) => selected?.hip?.id === hipId
        );

        if (selectedHospitalIndex !== -1) {
            const updatedHospital = { ...selectedHospitals[selectedHospitalIndex] };
            const careContexts = [...updatedHospital.hiTypes];
            const isRecordSelected = selectedRecords[hipId] || [];

            if (isRecordSelected?.includes(record)) {
                // If it's already selected, remove it
                const updatedRecords = isRecordSelected?.filter(
                    (selectedRecord) => selectedRecord !== record
                );


                setSelectedRecords((prevState) => ({
                    ...prevState,
                    [hipId]: updatedRecords,
                }));

                // Remove the record from the hospital's careContexts
                const recordToRemoveIndex = careContexts.findIndex(
                    (context) => context === record
                );

                if (recordToRemoveIndex !== -1) {
                    careContexts.splice(recordToRemoveIndex, 1);
                }
            } else {
                // If it's not selected, add it
                setSelectedRecords((prevState) => ({
                    ...prevState,
                    [hipId]: [...prevState[hipId], record],
                }));

                // Add the record to the hospital's careContexts
                careContexts.push(record);
            }

            const updatedHospitals = [...selectedHospitals];
            updatedHospitals[selectedHospitalIndex] = { ...updatedHospital, hiTypes: careContexts };

            setSelectedHospitals(updatedHospitals);
        }
    };

    // Define the isRecordSelected function
    const isRecordSelected = (hipId, record) => {
        const selectedRecordIndices = selectedRecords[hipId] || [];
        return selectedRecordIndices?.includes(record);
    };



    // Add this function to handle category checkbox
    const handleCategoryCheckbox = (hospitalIndex, category) => {
        const hospital = hospitallinks[hospitalIndex];
        const hipId = hospital?.hip?.id;

        const selectedHospitalIndex = selectedHospitals.findIndex(
            (selected) => selected?.hip?.id === hipId
        );

        if (selectedHospitalIndex !== -1) {
            const updatedHospital = { ...selectedHospitals[selectedHospitalIndex] };
            const selectedCategories = updatedHospital?.categories || [];

            const categoryIndex = selectedCategories.indexOf(category);

            if (categoryIndex !== -1) {
                // If the category is already selected, remove it
                selectedCategories.splice(categoryIndex, 1);
            } else {
                // If the category is not selected, add it
                selectedCategories.push(category);
            }

            // Update the selected categories for the hospital
            updatedHospital.categories = selectedCategories;

            // Update the selected hospitals state
            const updatedHospitals = [...selectedHospitals];
            updatedHospitals[selectedHospitalIndex] = updatedHospital;

            setSelectedHospitals(updatedHospitals);
        }
    };



    const updateSubscriptionRequest = async () => {


        const requestBody = {
            hiuId: hlthLockdata?.requester?.id,
            includeSources: selectedHospitals?.map((hospital) => {
                const { hip, hiTypes, period, categories } = hospital;
                const convertedFrom = period?.from ? moment(period.from)?.toISOString() : null;
                const convertedTo = period?.to ? moment(period.to)?.toISOString() : null;
                return {
                    hip: {
                        id: hip?.id,
                        name: hip?.name,
                    },
                    hiTypes: hiTypes,
                    purpose: {
                        text: hlthLockdata?.purpose?.text,// replace with the actual purpose text
                        code: hlthLockdata?.purpose?.code,  // replace with the actual purpose code
                        refUri: hlthLockdata?.purpose?.refUri, // replace with the actual refUri
                    },
                    categories: categories || [], // replace with the actual categories
                    period: {
                        from: convertedFrom,
                        to: convertedTo,
                        // from: new Date(period?.from)?.toISOString(),
                        // to: new Date(period?.to)?.toISOString(),
                    },
                };
            }),
            excludeSources: [],

        };
        const requestBody1 = {
            hiuId: hlthLockdata?.requester?.id,
            includeSources: selectedHospitals?.map((hospital) => {
                const { hip, hiTypes, period, categories } = hospital;
                const convertedFrom = createat.Expiryfrom ? moment(createat.Expiryfrom)?.toISOString() : null;
                const convertedTo = createat.Validto ? moment(createat.Validto)?.toISOString() : null;
                return {
                    hip: {
                        id: hip?.id,
                        name: hip?.name,
                    },
                    hiTypes: selectedDropdownItems,
                    purpose: {
                        text: hlthLockdata?.purpose?.text,// replace with the actual purpose text
                        code: hlthLockdata?.purpose?.code,  // replace with the actual purpose code
                        refUri: hlthLockdata?.purpose?.refUri, // replace with the actual refUri
                    },
                    categories: checkedCategories || [], // replace with the actual categories
                    period: {
                        from: convertedFrom,
                        to: convertedTo,
                        // from: new Date(period?.from)?.toISOString(),
                        // to: new Date(period?.to)?.toISOString(),
                    },
                };
            }),
            excludeSources: [],

        };


        let updatedData = isChecked ? requestBody1 : requestBody;


        setspinnnerloading(true);
        const apiUrl = `${URL.consents}health-locker/subscription-requests/${primaryId}/${phraddress}/${hlthLockdata.subscriptionId}`;

        try {
            const response = await axios.put(apiUrl, updatedData, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            }
            );
            // If the response is OK, you can handle the data here
            console.log('Success:', response);
            setspinnnerloading(false);
            handleClose()
        } catch (error) {
            // Handle any errors that occurred during the API call
            console.error('Error:', error);
            setspinnnerloading(false);
        }
    };
    const handleClose = () => {
        setOpen(false)
    }


    return (
        <React.Fragment>

            <CommonDialog open={open} onClose={handleClose}>

                < DialogTitle sx={{ fontWeight: 500, p: '5px 20px', fontSize: '17px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    {expand ? 'Edit Subcriptions' : 'Active Subscriptions'}
                    <IconButton sx={{ color: 'red' }} onClick={() => expand ? updateSubscriptionRequest() : handleClose()}>  <Close /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>

                    {
                        expand ?
                            <Box>

                                <Box sx={{ p: "0px 20px" }}>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                            {/* <VerifiedUser sx={{ color: '#0C9D00' }} /> */}
                                            <Typography>Subscription Request </Typography>
                                        </Box>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                                            {subdata?.status} {DateFormat(subdata?.dateCreated, 'DateTime')}

                                        </Typography>
                                    </Box>

                                    <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                            <img src={medxpertLogo} alt="medxperts" width={50} height={50} />
                                            <Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{subdata?.requester?.name}
                                                </Typography>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{subdata?.purpose?.text}</Typography>
                                            </Box>
                                        </Box>

                                    </Box>

                                </Box>
                                <Box sx={{ bgcolor: 'pink' }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: '0px 10px', mt: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                                            <Checkbox
                                                color="secondary"
                                                checked={Boolean(isChecked)}
                                                onChange={() => handleCheckboxToggle()}
                                            />
                                            {/* </IconButton> */}
                                            <Typography sx={{ fontWeight: 600, fontSize: { xs: 14, sm: 14, md: 14 } }}>{'Included from all Hospitals'}</Typography>
                                        </Box>
                                        <IconButton onClick={() => setIsVisible(!isVisible)}>{isVisible ? <ExpandLess sx={{ color: "black" }} /> : <ExpandMore sx={{ color: "black" }} />}</IconButton>
                                    </Box>
                                    <Divider />

                                    {
                                        isVisible &&
                                        <Box>
                                            <Typography sx={{ fontWeight: 600, fontSize: '14px', ml: "10px", mt: '5px' }}>Subscribed for Records of Type</Typography>
                                            {
                                                options.map((item, index) => (
                                                    <React.Fragment key={index}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "0px 10px" }}>
                                                            <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                                                {renderIcon2(item)}
                                                                <Typography sx={{ fontSize: '14px' }}>{item}</Typography>
                                                            </Box>
                                                            <Checkbox
                                                                color='secondary'
                                                                checked={Boolean(selectedDropdownItems.includes(item))}
                                                                onChange={() => handleSelectItem(item)}
                                                            />
                                                        </Box>
                                                        <Divider />
                                                    </React.Fragment>
                                                ))
                                            }
                                            <Typography sx={{
                                                fontSize: '14px', fontWeight: '600', ml: "10px", mt: '5px'
                                            }}>Type of Visit</Typography>
                                            < Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    gap: 2,
                                                    ml: '5px',
                                                    p: '0px 10px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: 14,
                                                            sm: 14,
                                                            md: 14,
                                                        },
                                                    }}
                                                >
                                                    {'Past and Completed Visits(Link)'}
                                                </Typography>
                                                <Checkbox
                                                    color="secondary"
                                                    checked={Boolean(checkedCategories.includes('LINK'))}
                                                    onChange={() => handleCategoryToggle('LINK')}
                                                />
                                            </Box>
                                            < Box
                                                sx={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'space-between',
                                                    gap: 2,
                                                    ml: '5px',
                                                    p: '0px 10px',
                                                }}
                                            >
                                                <Typography
                                                    sx={{
                                                        fontSize: {
                                                            xs: 14,
                                                            sm: 14,
                                                            md: 14,
                                                        },
                                                    }}
                                                >
                                                    {'Upcoming NewVisits(Link)'}
                                                </Typography>
                                                <Checkbox
                                                    color="secondary"
                                                    checked={Boolean(checkedCategories.includes('DATA'))}
                                                    onChange={() => handleCategoryToggle('DATA')}
                                                />
                                            </Box>
                                            <Typography sx={{
                                                fontSize: '14px', fontWeight: '600', ml: "10px", mt: '5px'
                                            }}>From Period</Typography>

                                            <Box sx={{ display: 'flex', gap: 4, p: '0px 20px', justifyContent: 'space-between' }}>
                                                <Box>
                                                    <Typography>From</Typography>
                                                    <CommonCalender
                                                        value={dayjs(createat?.Expiryfrom)}
                                                        onChange={handleDateChange}
                                                    />
                                                </Box>
                                                <Box mb={1}>
                                                    <Typography>To</Typography>
                                                    <CommonCalender
                                                        value={dayjs(createat?.Validto)}
                                                        onChange={handleDateChange}
                                                    />
                                                </Box>
                                            </Box>

                                        </Box>
                                    }
                                </Box>
                                <Box>
                                    {
                                        hospitallinks && hospitallinks?.map((link, hospitalIndex) => {
                                            const isCheckboxChecked = isChecked;
                                            return (
                                                <Card sx={{ mt: '10px', boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)' }}>
                                                    <Box sx={{
                                                        display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: '0px 10px',
                                                    }}>
                                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                                                            <Checkbox
                                                                color="secondary"
                                                                checked={Boolean(isCheckedState[hospitalIndex])}
                                                                onChange={() => handlehospitaldata(hospitalIndex)}
                                                                disabled={isCheckboxChecked} // Disable the dropdown if the checkbox is checked

                                                            />
                                                            {/* </IconButton> */}
                                                            <Typography sx={{ fontWeight: 600, fontSize: { xs: 14, sm: 14, md: 14 } }}>{link.hip.name ? link.hip.name : 'null'}</Typography>
                                                        </Box>
                                                        <IconButton onClick={() => toggleDropdown1(hospitalIndex)}>{isinform ? <ExpandLess sx={{ color: "black" }} /> : <ExpandMore sx={{ color: "black" }} />}</IconButton>
                                                    </Box>
                                                    {
                                                        !isCheckboxChecked && openDropdownIndex === hospitalIndex && (
                                                            <Box>
                                                                <Box>
                                                                    <Typography sx={{ fontWeight: 600, fontSize: '14px', ml: "10px", mt: '5px' }}>Subscribed for Records of Type</Typography>
                                                                    {

                                                                        options.map((record, recordIndex) => (
                                                                            <React.Fragment key={recordIndex}>
                                                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "0px 10px" }}>
                                                                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 3 }}>
                                                                                        {renderIcon2(record)}
                                                                                        <Typography sx={{ fontSize: '14px' }}>{record}</Typography>
                                                                                    </Box>
                                                                                    <Checkbox
                                                                                        color='secondary'
                                                                                        checked={Boolean(isRecordSelected(link.hip.id, record))}
                                                                                        onChange={() => handlerecords(hospitalIndex, record)}

                                                                                    />
                                                                                </Box>
                                                                                <Divider />
                                                                            </React.Fragment>
                                                                        ))
                                                                    }
                                                                    <Typography sx={{
                                                                        fontSize: '14px', fontWeight: '600', ml: "10px", mt: '5px'
                                                                    }}>Type of Visit</Typography>
                                                                    < Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                            gap: 2,
                                                                            ml: '5px',
                                                                            p: '0px 10px',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: {
                                                                                    xs: 14,
                                                                                    sm: 14,
                                                                                    md: 14,
                                                                                },
                                                                            }}
                                                                        >
                                                                            {'Past and Completed Visits(Link)'}
                                                                        </Typography>
                                                                        <Checkbox
                                                                            color="secondary"
                                                                            checked={Boolean(selectedHospitals[hospitalIndex]?.categories?.includes('LINK'))}
                                                                            onChange={() => handleCategoryCheckbox(hospitalIndex, 'LINK')}
                                                                        />
                                                                    </Box>
                                                                    < Box
                                                                        sx={{
                                                                            display: 'flex',
                                                                            alignItems: 'center',
                                                                            justifyContent: 'space-between',
                                                                            gap: 2,
                                                                            ml: '5px',
                                                                            p: '0px 10px',
                                                                        }}
                                                                    >
                                                                        <Typography
                                                                            sx={{
                                                                                fontSize: {
                                                                                    xs: 14,
                                                                                    sm: 14,
                                                                                    md: 14,
                                                                                },
                                                                            }}
                                                                        >
                                                                            {'Upcoming NewVisits(Link)'}
                                                                        </Typography>
                                                                        <Checkbox
                                                                            color="secondary"
                                                                            checked={Boolean(selectedHospitals[hospitalIndex]?.categories?.includes('DATA'))}
                                                                            onChange={() => handleCategoryCheckbox(hospitalIndex, 'DATA')}
                                                                        />
                                                                    </Box>
                                                                    <Typography sx={{
                                                                        fontSize: '14px', fontWeight: '600', ml: "10px", mt: '5px'
                                                                    }}>From Period</Typography>

                                                                    <Box sx={{ display: 'flex', gap: 4, p: '0px 20px', justifyContent: 'space-between' }}>
                                                                        <Box>
                                                                            <Typography>From</Typography>
                                                                            <CommonCalender
                                                                                value={dayjs((selectedHospitals?.[hospitalIndex]?.period?.from || '')
                                                                                    ? selectedHospitals?.[hospitalIndex]?.period?.from?.split('T')[0] // Extract date before 'T'
                                                                                    : '')}
                                                                                onChange={(date) =>
                                                                                    handleDateUpdate(
                                                                                        'from',
                                                                                        hospitalIndex,
                                                                                        date

                                                                                    )}
                                                                            />
                                                                        </Box>
                                                                        <Box mb={1}>
                                                                            <Typography>To</Typography>
                                                                            <CommonCalender
                                                                                value={dayjs((selectedHospitals?.[hospitalIndex]?.period?.to || '')
                                                                                    ? selectedHospitals[hospitalIndex]?.period?.to?.split('T')[0] // Extract date before 'T'
                                                                                    : '')}
                                                                                onChange={(date) =>
                                                                                    handleDateUpdate(
                                                                                        'to',
                                                                                        hospitalIndex,
                                                                                        date
                                                                                    )}
                                                                            />
                                                                        </Box>
                                                                    </Box>

                                                                </Box>
                                                            </Box>
                                                        )
                                                    }

                                                </Card>
                                            )
                                        })}
                                </Box>

                            </Box> : <Box>
                                {
                                    subscription?.map((item, i) => (
                                        <Card sx={{ p: "10px 20px" }} key={item}>
                                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                                    {/* <VerifiedUser sx={{ color: '#0C9D00' }} /> */}
                                                    <Typography>Subscription Request </Typography>
                                                </Box>
                                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                                                    {item?.status} {DateFormat(item?.dateCreated, 'DateTime')}

                                                </Typography>
                                            </Box>

                                            <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                                    <img src={medxpertLogo} alt="medxperts" width={50} height={50} />
                                                    <Box>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>{item?.requester?.name}
                                                        </Typography>
                                                        <Typography sx={{ fontSize: '14px', fontWeight: 400 }}>{item?.purpose?.text}</Typography>
                                                    </Box>
                                                </Box>
                                                <IconButton onClick={() => {
                                                    setExpand(true);
                                                    setSubID(item?.subscriptionId);
                                                    setSubData(item);
                                                }}>
                                                    <Pending sx={{ color: '#2580EC', fontSize: '30px' }} /></IconButton>
                                            </Box>

                                        </Card>
                                    ))
                                }
                            </Box>
                    }
                </DialogContent>

            </CommonDialog>
        </React.Fragment >
    );
}

export default ActiveSubcriptions; 