import React, { useState } from 'react'
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import { ArrowBackIos, ArrowForwardIos, Close, LocationOn, Vaccines } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import CommonButton from '../../../atoms/CommonButton'
import consultationicon from '../../../../assets-new/carekit-icons/stethoscope.svg'
import medicationicon from '../../../../assets-new/carekit-icons/medicines.svg'
import allergyicon from '../../../../assets-new/carekit-icons/allergies.svg'
import vaccinationicon from '../../../../assets-new/carekit-icons/syringe-vaccine.svg'
import surgeriesicon from '../../../../assets-new/carekit-icons/surgical_sterilization.svg'
import familyhistoryicon from '../../../../assets-new/carekit-icons/ui_folder_family.svg'
import vitalicon from '../../../../assets-new/carekit-icons/heart_cardiogram.svg'
import medicaldevicesicon from '../../../../assets-new/carekit-icons/pacemaker_FILL0_wght400_GRAD0_opsz24.svg'
import diagnosticicon from '../../../../assets-new/carekit-icons/diagnostic.svg'
import clinicalicon from '../../../../assets-new/carekit-icons/clincalgoals.svg'

const ConsultationCard = (props) => {
    return (
        <Paper elevation={3} sx={{ p: "10px 20px", borderRadius: "12px", cursor: 'pointer' }} onClick={props.onClick}>
            <Grid container>
                <Grid item xs={2} sm={2} md={2}>
                    <Typography sx={{ fontSize: 14 }}>{props.date}</Typography>
                </Grid>
                <Grid item xs={8.6} sm={8.8} md={8.8}>
                    <Typography sx={{ fontSize: 14, color: '#9560D9' }}>{props.timelinem}</Typography>
                </Grid>
                <Grid item xs={1.4} sm={1.2} md={1.2}>
                    <Typography sx={{ fontSize: 14 }}>{props.status}</Typography>
                </Grid>
            </Grid>

            <Grid container mt={1}>
                <Grid item xs={11} sm={11} md={11}>
                    <Typography sx={{ fontWeight: 500, fontSize: '15px', mt: '2px' }}>Paediatrician consultation (Dr Venkat) </Typography>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px', mt: '2px' }}>
                        <LocationOn sx={{ color: '#9560D9', fontSize: '12px' }} />
                        <Typography sx={{ fontSize: '12px' }}>Paramitha Children's Hospital,kompally</Typography>
                    </Box>
                </Grid>
                <Grid item xs={1} sm={1} md={1}>
                    <IconButton sx={{ bgcolor: '#F5F6FD' }}><ArrowForwardIos sx={{ color: 'black' }} /></IconButton>
                </Grid>
            </Grid>
        </Paper >
    )
}

const ListCards = (props) => {
    return (
        <Paper elevation={4} sx={{ p: "8px 15px", mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <img src={props.icon} alt="healthicon" width={25} height={25} />
                    <Typography>{props.title}</Typography>
                </Box>
                <ArrowForwardIos />
            </Box>
        </Paper>
    )
}
function ConsultationsTimeline() {
    const navigate = useNavigate()
    const [open, setOpen] = useState(true)

    return (
        <Box sx={{ m: 1, }}>
            {
                open ?
                    <>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                <img src={consultationicon} alt="consultation" width={35} height={35} />
                                <Typography sx={{ fontWeight: 600, fontSize: 17 }}>Consultations Timeline</Typography>
                            </Box>
                            <IconButton onClick={() => navigate(-1)}>  <Close sx={{ color: 'red' }} /></IconButton>
                        </Box>
                        <Box sx={{ mt: 1 }}>
                            <ConsultationCard date="21 Jan 2023" timeline="Followup visit" status="Upcoming" onClick={() => setOpen(false)} />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <ConsultationCard date="18 Jan 2023" timeline="Walk-in-visit" status="Booked" />
                        </Box>
                        <Box sx={{ mt: 2 }}>
                            <ConsultationCard date="12 Jan 2023" timeline="Walk-in-visit" status="Completed" />
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <Paper elevation={4} sx={{ p: "8px", borderRadius: '10px' }} >
                                <Box sx={{ color: 'black', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
                                    <img src={consultationicon} alt="consultation" width={30} height={30} />
                                    <Typography sx={{ fontWeight: 500 }}>Show Older</Typography>
                                </Box>
                            </Paper>
                        </Box>
                        <Box>
                            <ListCards title="Clinical Goal Tracking" icon={clinicalicon} />
                            <ListCards title="Medication use timeline" icon={medicationicon} />
                            <ListCards title="Vaccination history" icon={vaccinationicon} />
                            <ListCards title="My Lab Results Timeline" icon={diagnosticicon} />
                            <ListCards title="Vital Signs" icon={vitalicon} />
                            <ListCards title="Allergies timeline" icon={allergyicon} />
                            <ListCards title="Surgery & procedure history" icon={surgeriesicon} />
                            <ListCards title="Family history" icon={familyhistoryicon} />
                            <ListCards title="Medical devices & implants" icon={medicaldevicesicon} />
                        </Box>
                    </> :
                    <>
                        <Paper elevation={4} sx={{ p: "10px 15px" }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <ArrowBackIos sx={{ cursor: 'pointer' }} onClick={() => setOpen(true)} />
                                    <Box >
                                        <Typography sx={{ fontSize: '14px' }}>Followup Visit</Typography>
                                        <Typography sx={{ fontSize: '17px', color: '#9560D9' }}>OutPatient Consultation</Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>Proposed</Typography>
                            </Box>
                        </Paper>
                        <Paper elevation={4} sx={{ p: "10px 15px", mt: 2 }}>
                            <Typography sx={{ fontWeight: 500 }}>Paediatrician consultation With Dr Venkat Reddy K</Typography>
                            <Typography sx={{ fontSize: '14px' }}>Proposed Date & Time</Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: '14px' }}>12th January 2023</Typography>
                                <Typography sx={{ fontSize: '14px' }}>1:30 PM - 3:00 PM</Typography>
                            </Box>
                            <Box sx={{ textAlign: 'center', mt: 3 }}>
                                <CommonButton text="Book Appointment" bgcolor="black" width="250px" />
                            </Box>
                        </Paper>
                        <Paper elevation={4} sx={{ p: "10px 15px", mt: 2 }}>
                            <Box >
                                <Typography sx={{ fontSize: '14px' }}>Reason :<Typography component={'span'} sx={{ fontWeight: 500 }}> Stomach Pain</Typography>
                                </Typography>
                                <Typography sx={{ fontSize: '14px', mt: 2 }}>Created Date:<Typography component={'span'} sx={{ fontWeight: 500 }}> 04th January 2023</Typography>
                                </Typography>
                            </Box>
                        </Paper>
                        <Paper elevation={4} sx={{ p: "10px 15px", mt: 2 }}>
                            <Box >
                                <Typography sx={{ fontSize: '14px' }}>Address</Typography>
                                <Typography sx={{ fontSize: '14px', mt: 2 }}>Paramitha Children's Hospital,kothapet,..Hyderabad,Telangana,500035</Typography>
                            </Box>
                        </Paper>
                    </>
            }
        </Box>
    )
}

export default ConsultationsTimeline