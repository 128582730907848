
export const handleErrorResponse = (err) => {
    let alertmessage = '';
    let alertstatus = 'error';

    if (err.response) {
        switch (err.response.status) {
            case 401:
                alertmessage = 'Unauthorized';
                break;
            case 403:
                alertmessage = 'Forbidden';
                break;
            case 404:
                alertmessage = 'Not Found';
                break;
            case 500:
                alertmessage = 'Internal Server Error';
                break;
            case 504:
                alertmessage = 'Gateway Time-out';
                break;
            default:
                alertmessage = 'An error occurred';
        }
    } else {
        alertmessage = 'Network error or server unreachable';
    }

    return { alertmessage, alertstatus };
};
