import React, { useState } from 'react'
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material'
import { useDispatch } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useTranslation } from "react-i18next";
import AbhaNumStep2 from './AbhaNumStep2';
import SelectPhrAddress from './SelectPhrAddress';
import CreatePhrAddress from './CreatePhrAddress';
import PhrAddressScreen from './PhrAddressScreen';
import SelectVerification from './SelectVerification';


function AbhaNumStep1() {
    const navigate = useNavigate()
    const [show, setShow] = useState(1)
    const [activeButton, setActiveButton] = useState('mobile')
    const [loading, setLoading] = useState(false)
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [checkid, setCheckid] = useState(null);
    const [abhaNum, setAbhaNum] = useState('')
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const handleOnChangeAbha = (e) => {
        // const inputValue = e.target.value.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');
        const inputValue = e.target.value.replace(/\D/g, '')
        setAbhaNum(inputValue.slice(0, 14));
        setAlertmessage('')

    }



    const handleSend = async () => {
        // navigation.navigate('AbhaNumberStep2')
        const inputValue = abhaNum.replace(/(\d{2})(\d{4})(\d{4})(\d{4})/, '$1-$2-$3-$4');

        setLoading(true);
        let d = {
            healthIdNumber: inputValue,
        };
        let d1 = {
            healthIdNumber: inputValue,
            authMethod: activeButton === 'aadhar' ? 'AADHAAR_OTP' : 'MOBILE_OTP',
        };

        try {
            // First API call
            const searchResponse = await axios.post(`${URL.abha}search/user/byHealthId/${primaryId}`, d, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },
            });

            console.log('--------->>> searchapi', searchResponse.data);
            setCheckid(searchResponse.data.status);

            if (searchResponse.status === 200 || searchResponse.status === 201) {
                // Second API call
                setLoading(true); // Set loading for the second API call
                const res = await axios.post(`${URL.abha}registration/hid/init/transaction/${primaryId}`, d1, {
                    headers: {
                        'gatewayToken': 'medxperts',
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setAlertmessage('OTP sent to mobile number!');
                setAlertstatus('success');
                setShow(2);

                let details = {
                    transactionId: res.data.transactionId,
                    number: inputValue,
                };
                dispatch({
                    type: 'ABHANUMBER_TRANSID',
                    payload: details,
                });
            }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setAlertmessage("ABHA Number not Found or No ABHA address linked with this ABHA number");
                setAlertstatus('error');
            } else {
                setAlertmessage(err.message);
                setAlertstatus('error');
            }
        } finally {
            setLoading(false); // Reset loading state after both API calls
        }
    };

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '44px' }, mb: { xs: 10, sm: 10, md: 5 } }}>

            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: "5px", sm: "10px", md: 2 } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 2 },
                            }}
                        >
                            <IconButton
                                onClick={() => navigate("/connecthealthid")}
                            >
                                <ArrowBackIos sx={{ color: '#007DCD' }} />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: 15, sm: "16px", md: "16px" },
                                    fontWeight: 600,
                                    color: '#007DCD'
                                }}
                            >
                                Pre-verified Health ID Number
                            </Typography>
                        </Box>
                    </Box>
                    <Paper elevation={3}
                        sx={{
                            textAlign: "center",
                            mt: 1,
                            p: 3,
                            boxShadow:
                                "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                gap: 2,
                            }}
                        >
                            <img src={Abhalogo} alt="abha" width={30} height={30} />
                            <Typography sx={{
                                fontSize: { xs: 14, sm: "16px", md: "16px" },
                            }}>{t("healthid14")}</Typography>
                        </Box>
                        <Grid container mt={2}>
                            <Grid item xs={12}>
                                <TextInputField
                                    width={{ sm: "350px", md: "350px" }}
                                    placeholder="ABHA Health Id Number"
                                    name="number"
                                    border="none"
                                    value={abhaNum}
                                    onChange={handleOnChangeAbha}
                                />
                            </Grid>
                            {/* {checkid !== "ACTIVE" && abhaNum.length > 5 ? (
                                <Typography sx={{ color: "red", fontSize: 13 }}>
                                    Abha Health Id Number is not exist please check it.
                                </Typography>
                            ) : null} */}

                            {alertmessage && (
                                <Typography
                                    sx={{
                                        color: alertstatus === "success" ? "green" : "red",
                                        mt: 1,
                                        fontSize: 13,
                                    }}
                                >
                                    {alertmessage}
                                </Typography>
                            )}
                        </Grid>
                        <Box mt={3}>
                            <Typography>Verify ABHA using</Typography>
                        </Box>

                        <ButtonGroup
                            variant="contained"
                            aria-label="outlined primary button group"
                            sx={{ mt: 1 }}
                        >
                            <Button
                                sx={{
                                    bgcolor: activeButton === "aadhar" ? "white" : "#EAE2E2",
                                    color: "black",
                                    "&:hover": {
                                        bgcolor: activeButton === "aadhar" ? "white" : "#EAE2E2",
                                    }
                                }}
                                disableElevation
                                onClick={() => setActiveButton("aadhar")}
                            >
                                Aadhar OTP
                            </Button>
                            <Button
                                sx={{
                                    bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2",
                                    '&:hover': { bgcolor: activeButton === "mobile" ? "white" : "#EAE2E2" },

                                    color: "black",
                                }}
                                disableElevation
                                onClick={() => setActiveButton("mobile")}
                            >
                                Mobile OTP
                            </Button>
                        </ButtonGroup>
                        <Box sx={{ mt: 4, textAlign: "center", mb: 2 }}>
                            <Button
                                variant="contained"
                                sx={{
                                    borderRadius: 2,
                                    "&:hover": {
                                        bgcolor: "#1CB500",
                                    },
                                    bgcolor: "#1CB500",
                                    width: "150px",
                                }}
                                onClick={handleSend}
                                disabled={loading || abhaNum.length < 14}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                {/* {loading ? 'Sending...' : 'Send OTP'} */}
                                Send OTP
                            </Button>
                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                <AbhaNumStep2 setShow={setShow} setAlertmessage={setAlertmessage} />
            ) : show === 3 ? (
                <SelectPhrAddress setShow={setShow} />
            ) : show === 6 ? (
                <CreatePhrAddress setShow={setShow} />
            ) : show === 7 ? (
                <PhrAddressScreen setShow={setShow} />
            ) : show === 8 ? (
                <SelectVerification setShow={setShow} />
            ) : null
            }
        </Box >
    );
}

export default AbhaNumStep1