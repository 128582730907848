export const Metadata = () => {
    return (
        {
            author: '',
            dateTime: new Date().toISOString(),
            location: '',
            versionId: 0,
            securityCodes: '',
        }
    );
};
