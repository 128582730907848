import { Avatar, Box, Divider, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import URL from '../../../../../utility/apiurl'
import { localstore } from '../../../../../localStore/localStore'
import { useTranslation } from "react-i18next";
import TimelimeData from '../HospitalProfile/TimelineData'

function OrgTimeline({ orgId }) {
    // console.log(orgId, "orgIddddddd")
    const token = localstore.getToken()
    const [count, setCount] = useState()
    const [pageFeed, setPageFeed] = useState()
    const [pageOrg, setPageOrg] = useState()
    const [like, setLike] = useState(false)
    const [t, i18n] = useTranslation("global");
    // console.log(pageFeed, "pageFeed")
    const limit = 10;
    const [offset, setOffset] = useState(() => { return 0; });
    const getPageFeed = () => {
        axios.get(`${URL.pageFeed}${orgId}?limit=${limit}&offset=${offset}`, {

            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            // console.log(res, "timeline")
            setCount(res.data.count)
            setPageFeed(res.data.results)

        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        getPageFeed()
    }, [])



    return (
        <Box sx={{ mt: 6 }}>
            {pageFeed?.length > 0 ? (
                pageFeed.map((post, index) => (
                    <TimelimeData orgPage={post} key={index} />
                ))
            ) : (
                <Typography sx={{ mt: "-70px" }}>{t("overview.noposts")}</Typography>
            )}
        </Box>
    );
}

export default OrgTimeline