const initialState = {
    abhaprofile: [],
    mobileprofile: [],
    mappedphrAddress: [],
    // abhaQRCodeImage: null,
    gatewaytoken: '',
    otpVerified: null,
    otpVerified: null,
    abhatokens: {},


    // AbhaMobile 
    abhamobile: {},
    ndhmmobile: {},
    states: [],
    districts: [],
    phradd: '',
    phrtxnid: '',

    //Abha Aadhar
    abhaAadhar: {},
    ndhmAadhar: {},
    aadharNumber: {},
    txnid: {},
    adapter: null,
    aadhartokenHin: {},

    forgotHealthIdnum: {},
    getforgotHealthIdnum: '',
    sessionIdandMobilenumber: {},
    phrAddress: "",
    abhanumbertransId: {},
    mobilelinktransactionId: '',

    authMethods: [],

    abhaQRCodeImage: null,
    abhacard: null,
    // consent pin 
    consentPinToken: '',
    forgotGenerateotpdata: {},
};

const abhaReducers = (state = initialState, action) => {
    switch (action.type) {

        case 'ABHAPROFILE':
            return {
                ...state,
                abhaprofile: action.payload,
            };
        case 'MOBILEPROFILE':
            return {
                ...state,
                mobileprofile: action.payload,
            };

        // AbhaMobile 
        case 'ABHAMOBILE':
            return {
                ...state,
                abhamobile: action.payload,
            };
        case 'NDHMMOBILE':
            return {
                ...state,
                ndhmmobile: action.payload,
            };
        case 'MAPPEDPHRADDRESS':
            return {
                ...state,
                mappedphrAddress: action.payload,
            };
        case 'PHRADD':
            return {
                ...state,
                phradd: action.payload,
            }
        case 'PHRTXNID':
            return {
                ...state,
                phrtxnid: action.payload,
            }

        //Abha Aadhar
        case 'ABHAAADHAR':
            return {
                ...state,
                abhaAadhar: action.payload,
            };
        case 'AADHARNUMBER':
            return {
                ...state,
                aadharNumber: action.payload,
            }
        case 'NDHMAADHAR':
            return {
                ...state,
                ndhmAadhar: action.payload,
            };
        case 'AADHARSTEP3_TOKEN_HIN':
            return {
                ...state,
                aadhartokenHin: action.payload,
            };
        case 'AADHARSTEP4_TOKEN_HIN':
            return {
                ...state,
                aadhartokenHin: action.payload,
            };


        case 'TXNID':
            return {
                ...state,
                txnid: action.payload,
            };

        case 'GETSTATES':
            // console.log(action.payload, "ap")
            return {
                ...state,
                states: action.payload,
            };

        case 'GETDISTRICTS':
            return {
                ...state,
                districts: action.payload,
            };

        // case 'QRCODE':
        //     return {
        //         ...state,
        //         abhaQRCodeImage: action.payload,
        //     };
        case 'GATEWAYTOKEN':
            return {
                ...state,
                gatewaytoken: action.payload,
            };

        case 'OTPVERIFIED':
            return {
                ...state,
                otpVerified: action.payload,
            };
        case 'OTPVERIFIED':
            return {
                ...state,
                otpVerified: action.payload,
            };
        case 'ABHATOKENS':
            return {
                ...state,
                abhatokens: action.payload,
            };
        case 'MOBILEFLOW_SESSIONID':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload,
            };

        case 'AADHARSTEP3_SESSIONID':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload,
            }
        case 'AADHARSTEP4_SESSIONID':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload,
            }

        case 'EMAILFLOW_SESSIONID':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }
        case "ABHAFLOW_SESSIONID":
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }
        case "ABHAFLOW_SESSIONID1":
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }


        case "EMAILMAPPEDPHRADDRESS":
            return {
                ...state,
                mappedphrAddress: action.payload
            }

        case "PHRADDRESS":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "PHRADDRESS1":
            console.log(action.payload, '222222')
            return {
                ...state,
                phrAddress: action.payload
            }
        case "SELECTPHR":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "CREATE_PHRADDRESS":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "ABHALINK_PHRADDRESS":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "POPUP_PHRADDRESS":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "AADHARSTEP3_PHRADDRESS1":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "AADHARSTEP3_PHRADDRESS2":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "AADHARSTEP4_PHRADDRESS1":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "AADHARSTEP4_PHRADDRESS2":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "VERIFY_PHRADDRESS":
            return {
                ...state,
                phrAddress: action.payload
            }


        case "LOGINSELECTPHR":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "USERID_PHRADDRESS":
            return {
                ...state,
                phrAddress: action.payload
            }
        case "ABHANUMBER_TRANSID":
            return {
                ...state,
                abhanumbertransId: action.payload
            }
        // case "LOGINABHANUMBER_TRANSID":
        //     return {
        //         ...state,
        //         abhanumbertransId: action.payload
        //     }
        case 'ABHA_PHRADDRESS':
            return {
                ...state,
                mappedphrAddress: action.payload,
            };
        case 'AADHAR_MAPPEDPHRADDRESS':
            return {
                ...state,
                mappedphrAddress: action.payload,
            };
        case 'LOGINMOBILE_PHRADDRESS':
            return {
                ...state,
                mappedphrAddress: action.payload
            }
        case 'LOGINEMAIL_PHRADDRESS':
            return {
                ...state,
                mappedphrAddress: action.payload
            }
        case 'LOGINABHA_PHRADDRESS':
            return {
                ...state,
                mappedphrAddress: action.payload
            }
        case "USERID_PHRADDRESS":
            return {
                ...state,
                phrAddress: action.payload
            }
        case 'LOGINMOBILE_SESSIONID':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }

        case 'LOGINMOBILE_RESENDOTP_SESSIONID':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }
        case 'LOGINUSER_PASSWORD':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }
        case 'LOGINABHANUMBER_TRANSID':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }
        case 'LOGINABHANUM_RESENDOTP_TRANSID':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }
        case 'VERIFY_KYC_ABHANUMBER':
            return {
                ...state,
                sessionIdandMobilenumber: action.payload
            }


        case 'SELECTED_OPTIONS':
            return {
                ...state,
                mobilelinktransactionId: action.payload
            }
        case 'LOGINSELECTED_OPTIONS':
            return {
                ...state,
                mobilelinktransactionId: action.payload
            }



        // Consent Pin

        case 'CONSENTPIN_TOKEN':
            return {
                ...state,
                consentPinToken: action.payload
            }
        case 'CONSENT_FORGOTPIN_TOKEN':
            return {
                ...state,
                consentPinToken: action.payload
            }
        case 'EXISTING_CONSENTPIN_TOKEN':
            return {
                ...state,
                consentPinToken: action.payload
            }

        case 'FORGOTPIN_GENERATEOTP_DATA':
            return {
                ...state,
                forgotGenerateotpdata: action.payload
            }
        case 'FORGOTPIN_RESENDOTP_DATA':
            return {
                ...state,
                forgotGenerateotpdata: action.payload
            }


        case "AUTH_METHODS":
            return {
                ...state,
                authMethods: action.payload
            }
        case "CREATE_AUTH_METHODS":
            return {
                ...state,
                authMethods: action.payload
            }
        case 'USERID_AUTH_METHODS':
            return {
                ...state,
                authMethods: action.payload
            }
        case 'ABHALINK_AUTH_METHODS':
            return {
                ...state,
                authMethods: action.payload
            }
        case 'POPUP_AUTH_METHODS':
            return {
                ...state,
                authMethods: action.payload
            }
        case 'USERID_AUTH_METHODS':
            return {
                ...state,
                authMethods: action.payload
            }
        case 'FORGOT_HEALTHID_NUMBER':
            return {
                ...state,
                forgotHealthIdnum: action.payload
            }
        case 'GET_FORGOT_HEALTHID_NUMBER':
            return {
                ...state,
                getforgotHealthIdnum: action.payload
            }

        default:
            return state;
    }

};
export default abhaReducers;
