import React, { useEffect, useState } from 'react'
import { ArrowForward, Cached, Download, LocalHospital, Room, Search, SendToMobile, Share } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Divider, Grid, IconButton, List, ListItem, ListItemText, MenuItem, Paper, TextField, Typography } from '@mui/material'

import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import axios from 'axios';
import { debounce } from 'lodash';
// import ShareProfile1 from './shareProfile1';
// import ShareProfile2 from './shareProfile2';
import shareIcon from '../../../../assets/home/share_icon.svg'
import sharebtn from '../../../../assets/home/sharebtn.svg'
import { useTranslation } from "react-i18next";
import QuickShare1 from './QuickShare1';
import healthcareIcon from '../../../../assets-new/services-icons/healthcareicon.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getAbhaMobile, getDistricts, getStates } from '../../../../redux/Actions/Actions';
import SearchBar from '../../../atoms/Searchbar';
import searchIcon from '../../../../assets-new/services-icons/search-Icon-blue.svg'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';

function QuickShare() {

    const dispatch = useDispatch()
    const mobiledata = useSelector((state) => state.abha.mobileprofile)
    const abhadata = useSelector((state) => state.abha.mobileprofile)
    let states = useSelector(state => state.abha.states);
    let districts = useSelector(state => state.abha.districts);
    const [state, setState] = useState('')
    const [show, setShow] = useState(1)
    const [hospitalList, setHospitalList] = useState([])
    const [user, setUser] = useState({})
    const primaryId = localstore.getPrimaryId()
    const token = localstore.getToken()
    const [loading, setLoading] = useState(false); // Add a loading state
    const [t, i18n] = useTranslation("global");
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [stat, setStat] = useState('')
    const [district, setDistrict] = useState('')
    const [statecode, setStatecode] = useState('');
    const [districtcode, setDistrictcode] = useState('');
    const [stateopen, setStateopen] = useState(false)
    const [districtopen, setDistrictopen] = useState(false)

    useEffect(() => {
        dispatch(getAbhaMobile(primaryId, token))
    }, [])
    const debouncedAxiosCall = debounce((data) => {
        axios.get(`${URL.consents}allProviders/${primaryId}/${data}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            console.log(res, "searchapis")
            setHospitalList(res.data)

        }).catch(err => {
            setAlertmessage("Internal server error or token verification failed! or  phraddress not linked!")
            setAlertstatus('error')
        })
            .finally(() => {
                setLoading(false)
            })

    }, 2000);

    useEffect(() => {

        if (state) {
            setLoading(true); // Set loading to true when making the API request
            debouncedAxiosCall(state);
        }

        // Cancel API call when component unmounts
        return () => {
            debouncedAxiosCall.cancel();
        };
    }, [state]);


    useEffect(() => {
        if (hospitalList.length > 0) {
            setLoading(false); // Set loading to false when data is received
        }
    }, [hospitalList]);


    useEffect(() => {
        dispatch(getStates(token))
    }, []);

    const handleOpen = () => {
        setStateopen(!stateopen)
    }
    const handlestateSelection = () => {
        setStateopen(true);
        setDistrictopen(true);
    }
    const handleState = (e) => {
        setStat(e.name);
        setStatecode(e.code);
        dispatch(getDistricts(e.code, token));
        setDistrict('');

    }

    const handledistrictSelection = () => {
        setStateopen(false)
        setDistrictopen(false)
    }
    const handleDistrict = (e) => {
        setDistrict(e.name);
        setDistrictcode(e.code);
    };

    const handleShareProfile = async (hospitaldata) => {
        setUser(hospitaldata)
        setShow(2)
    }

    const filteredHospitalList = hospitalList.filter((item) =>
        item.identifier.name.toLowerCase().includes(state.toLowerCase())
    );
    return (
        <div>



            <Box sx={{ mt: { md: 2, xs: 9, sm: 11 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 300px' }, pt: { xs: '0px', sm: "0px", md: '50px' }, mb: { xs: 10, sm: 10, md: 0 } }}>

                {
                    show === 1 ? (
                        <>
                            <Box
                                sx={{ display: "flex", justifyContent: 'space-between', alignItems: "center" }}
                            >
                                <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}
                                >
                                    <IconButton>
                                        <img src={healthcareIcon} alt="health" width={25} height={25} />
                                    </IconButton>
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            color: "#3D6596",
                                            fontSize: "17px",
                                            mt: 1
                                        }}
                                    >
                                        Share Profile
                                    </Typography>
                                </Box>

                            </Box>


                            <Box mt={2}>
                                <Box sx={{ p: "0px 10px" }}>
                                    <SearchBar
                                        placeholder="Search Hospitals"
                                        value={state}
                                        icon={searchIcon}
                                        onChange={(e) => {
                                            setState(e.target.value);
                                            setHospitalList([]);
                                            setLoading("");
                                            setAlertmessage('')
                                        }}
                                    />

                                    <Grid
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "space-between",
                                            marginTop: "15px",
                                            color: "#3B3BBC",
                                            cursor: "pointer",
                                        }}
                                    >
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                            <Room />
                                            <Typography sx={{ mt: "2px", fontSize: '14px' }} onClick={handleOpen}>
                                                {stat && district
                                                    ? `${stat.toLowerCase()}, ${district.toLowerCase()}`
                                                    : t("records.setlocation")}
                                            </Typography>
                                        </Box>
                                        {stat.length > 0 || district.length > 0 ? (
                                            <Box
                                                sx={{ display: "flex", alignItems: "center", mr: 2 }}
                                                onClick={() => {
                                                    setStateopen(true);
                                                    setDistrictopen(true);
                                                }}
                                            >
                                                <Cached />
                                                <Typography>{t("records.change")}</Typography>
                                            </Box>
                                        ) : null}
                                    </Grid>

                                    {
                                        stateopen && (
                                            <Box onClick={handlestateSelection}>
                                                <TextField
                                                    sx={{
                                                        ".MuiInputBase-root": {
                                                            borderRadius: 2,
                                                            // backgroundColor: 'whitesmoke',
                                                            mt: 2,
                                                        },
                                                        // ".MuiOutlinedInput-notchedOutline": {
                                                        //     border: "none"
                                                        // }
                                                    }}
                                                    placeholder="Relation"
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={(e) => setStat(e.target.value)}
                                                    value={stat}
                                                    name={"stat"}
                                                    autoFocus
                                                    autoComplete="off"
                                                    select
                                                    SelectProps={{
                                                        displayEmpty: true,
                                                        renderValue: (selected) =>
                                                            selected === "" ? t("state") : selected,
                                                    }}
                                                >
                                                    <MenuItem value="">{t("selstate")}</MenuItem>
                                                    {states.map((state, i) => (
                                                        <MenuItem
                                                            key={i}
                                                            value={state.name}
                                                            onClick={() => handleState(state)}
                                                        >
                                                            {state.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Box>
                                        )
                                    }

                                    {
                                        districtopen && (
                                            <Box onClick={handledistrictSelection}>
                                                <TextField
                                                    sx={{
                                                        ".MuiInputBase-root": {
                                                            borderRadius: 2,
                                                            // backgroundColor: 'whitesmoke',
                                                            mt: 2,
                                                        },
                                                        // ".MuiOutlinedInput-notchedOutline": {
                                                        //     border: "none"
                                                        // }
                                                    }}
                                                    placeholder="Relation"
                                                    size="small"
                                                    fullWidth
                                                    variant="outlined"
                                                    onChange={(e) => setDistrict(e.target.value)}
                                                    value={district}
                                                    name={"district"}
                                                    autoFocus
                                                    autoComplete="off"
                                                    select
                                                    SelectProps={{
                                                        displayEmpty: true,
                                                        renderValue: (selected) =>
                                                            selected === "" ? t("district") : selected,
                                                    }}
                                                >
                                                    <MenuItem value="">{t("seldis")}</MenuItem>
                                                    {districts.map((district, i) => (
                                                        <MenuItem
                                                            key={i}
                                                            value={district.name}
                                                            onClick={() => handleDistrict(district)}
                                                        >
                                                            {district.name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            </Box>
                                        )
                                    }

                                    <Box
                                        sx={{
                                            height: "100vh",
                                            overflowY: "scroll",
                                            '&::-webkit-scrollbar': {
                                                display: 'none',
                                            },
                                            mt: 1
                                        }}
                                    >
                                        {loading ? (
                                            <Box
                                                sx={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    alignItems: "center",
                                                    mt: "100px",
                                                }}
                                            >
                                                <Typography sx={{ color: "blue" }}>
                                                    {/* {"fetching hospitals......"} */}
                                                    {t("fetchinghospitals")}
                                                </Typography>{" "}
                                            </Box> // Centered loading indicator
                                        ) : (
                                            <List>
                                                {filteredHospitalList.length > 0 ? (
                                                    filteredHospitalList.map((list, index) => (
                                                        <ListItem key={index}>
                                                            <ListItemText>
                                                                <Card
                                                                    sx={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "space-between",
                                                                        p: "10px 20px"
                                                                    }}
                                                                >
                                                                    <Box>
                                                                        <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>
                                                                            {" "}
                                                                            {list.identifier.name}
                                                                        </Typography>
                                                                        <Typography sx={{ fontSize: 12, mt: '5px' }}>
                                                                            address:{list?.address || "N/A"}{" "}
                                                                            ,city:{list?.city || "N/A"}, pincode:
                                                                            {list?.pinCode || "N/A"}
                                                                        </Typography>
                                                                    </Box>
                                                                    <IconButton
                                                                        onClick={() => handleShareProfile(list)}
                                                                    >
                                                                        <img src={shareIcon} alt="share" />
                                                                    </IconButton>
                                                                </Card>
                                                            </ListItemText>
                                                        </ListItem>
                                                    ))
                                                ) : (
                                                    <Typography
                                                        sx={{
                                                            textAlign: "center",
                                                            fontSize: "16px",
                                                            mt: 10,
                                                        }}
                                                    >
                                                        {t("nohospitalfound")}
                                                    </Typography>
                                                )}
                                            </List>
                                        )}

                                        {alertmessage && (
                                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                        )}
                                    </Box>
                                </Box>

                            </Box>
                        </>
                    ) : show === 2 ? (
                        <QuickShare1
                            setShow={setShow}
                            user={user}
                            mobiledata={mobiledata}
                        />
                    ) : null}
            </Box>

        </div>
    );
}

export default QuickShare;