import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import medxpertIcon from '../../../assets/home/medxpertIcon.svg'
import { CircularProgress, Link, TextField } from '@mui/material';
import axios from 'axios';
import URL from '../../../utility/apiurl';
import { useTranslation } from "react-i18next";

import ForgotuserOtp from './ForgotUserOtp';
import UserName from './Usernamecheck';
import { useNavigate } from 'react-router-dom';
import SelectLanguage from '../../atoms/SelectLanguage';
import CommonDesign from '../../atoms/CommonDesign';
import AlreadyAccount from '../../atoms/AlreadyAccount';
import CommonButton from '../../atoms/CommonButton';
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import { Public } from '@mui/icons-material';
import AdornmentTextField, { StartAdornmentTextField } from '../../atoms/TextFields/AdornmentTextField';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';

const theme = createTheme();

function ForgotUsername() {
    const navigate = useNavigate()
    const [phonenum, setPhonenum] = useState('')
    const [username, setUsername] = useState('')
    const [show, setShow] = useState(1)
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [newPhonenumber, setNewPhonenumber] = useState('');
    const [loading, setLoading] = useState(false)
    const [loadingOtp, setLoadingOtp] = useState(false)
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [t, i18n] = useTranslation("global");

    const handleChangemobileNumber = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setPhonenum(inputValue.slice(0, 10));
        setAlertmessage('')
    }

    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://www.google.com/recaptcha/api.js?render=6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-"
        document.body.appendChild(script)
    }, [])

    const handleSubmit = (e) => {
        setNewPhonenumber(`${'+91'}${phonenum}`);
        setLoading(true)
        e.preventDefault()
        let data = {
            phone: `${'+91'}${phonenum}`,
        };
        axios.post(`${URL.forgotUsername}`, data)
            .then(res => {
                console.log(res, "ressssss")
                if (res.data !== 'username does not exists') {
                    setUsername(res.data)
                    handlesendOtp();
                } else {
                    setAlertstatus('error');
                    setAlertmessage('User Name not registered with this Number!');
                }
            })
            .catch(err => {
                setAlertstatus('error');
                setAlertmessage(err.message);

            }).finally(() => {
                setLoading(false)
            })
    }

    const handlesendOtp = () => {
        setLoadingOtp(true)
        let data = {
            phoneNumber: `${'+91'}${phonenum}`,
            messagetype: 'OTP',
            apptype: 'medxperts',
            email: '',
        };

        window.grecaptcha.ready(() => {
            window.grecaptcha
                .execute("6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-", { action: "submit" })
                .then((token) => {
                    axios
                        .post(`${URL.forgotuserOtp}`, data, { headers: { "Authorization": `Bearer ${token}` } })
                        .then((res) => {
                            // console.log(res, "sendotp");
                            if (res.status === 200) {
                                setShow(2);
                            }

                        })
                        .catch((error) => {
                            setAlert({ open: true, msg: "Error" });
                        }).finally(() => {
                            setLoadingOtp(false);
                        });
                });
        });
    };

    return (
        <Box>

            <Box sx={{ height: { sm: '0px', md: "3px" } }}></Box>
            <CommonDesign>
                <Box
                    sx={{
                        mt: { xs: 20, sm: 14, md: 2 },
                        px: 4,

                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: 1, color: '#8A2BE2' }}>
                            <Public />
                            <Typography sx={{ fontWeight: 600, fontSize: 17 }}>India</Typography>

                        </Box>
                        <SelectLanguage />
                    </Box>
                    <Box sx={{
                        mx: 4, overflowY: 'scroll', height: "400px", '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                        <Box sx={{ textAlign: 'center', mt: 3 }}>
                            <img src={medxpertIcon} alt="medxperts" />
                            <Typography variant='h6' sx={{ color: 'blueviolet', fontWeight: 600 }}>Medxperts</Typography>
                        </Box>
                        {show === 1 ? (
                            <>
                                <Box sx={{ mt: 4, ml: 2 }}>
                                    <StartAdornmentTextField label="Mobile Number" placeholder="Enter Your Mobile Number" type="text" width="370px" value={phonenum} name="phonenum" onChange={handleChangemobileNumber} />
                                    {/* <ColorTextField label="Mobie Number" placeholder="Enter Your Mobile Number" type="text" value={phonenum} name="phonenum" onChange={handleChangemobileNumber}
                                        startAdornment="+91" /> */}
                                </Box>

                                <Box sx={{ textAlign: 'center', mt: 4, }}>
                                    <CommonButton text="Send OTP" width={150} bgcolor={"#8A2BE2"} disabled={loading || loadingOtp || phonenum.length < 10}
                                        startIcon={
                                            loading || loadingOtp ? (
                                                <CircularProgress size={20} color="inherit" />
                                            ) : null
                                        } onClick={handleSubmit} />
                                </Box>
                                <Box mt={2} textAlign={'center'}>
                                    {
                                        alertmessage && (
                                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                        )
                                    }
                                </Box>
                            </>
                        ) : show === 2 ?
                            (
                                <ForgotuserOtp
                                    phoneNumber={newPhonenumber}
                                    username={username}
                                    setShow={setShow}
                                />
                            ) :
                            show === 3 ?
                                (
                                    <UserName
                                        phoneNumber={newPhonenumber}
                                        username={username}
                                        setShow={setShow}
                                    />
                                ) : null}
                    </Box>

                    <AlreadyAccount />
                </Box>
            </CommonDesign>
        </Box>
    );
}

export default ForgotUsername