import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import medxpertIcon from '../../../assets/home/medxpertIcon.svg'
import { CircularProgress, Link, TextField } from '@mui/material';
import { Public, SyncAlt } from '@mui/icons-material';
import axios from 'axios';
import URL from '../../../utility/apiurl';


import { useTranslation } from "react-i18next";
import ForgotPasswordOtp from './ForgotpassOtp';
import CreateNewPassword from './Createnewpassword';
import { useNavigate } from 'react-router-dom';
import CommonDesign from '../../atoms/CommonDesign';
import SelectLanguage from '../../atoms/SelectLanguage';
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import CommonButton, { OutlinedButton } from '../../atoms/CommonButton';
import AlreadyAccount from '../../atoms/AlreadyAccount';
import { EndAdornmentTextField } from '../../atoms/TextFields/AdornmentTextField';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';

const theme = createTheme();

function ForgotPassword() {

    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [show, setShow] = useState(1)
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [phonenum, setPhonenum] = useState('');
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [newPasswordUsername, setNewPasswordUsername] = useState('');
    const [loading, setLoading] = useState(false)


    const [t, i18n] = useTranslation("global");


    const handleChangeUsername = (e) => {
        setUsername(e.target.value)
        setAlertmessage('')
    }

    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9"
        document.body.appendChild(script)
    }, [])


    const handleSubmit = () => {
        // setShow(2)
        setLoading(true);

        // Simulate a 2-second delay with setTimeout

        setNewPasswordUsername(`${username}@mdx`);
        let data = {
            'messagetype': 'OTP',
            'apptype': 'medxperts',
            'username': `${username}@mdx`,
            'subject': 'FORGOT PASSWORD',
        };
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9", { action: "submit" })
                .then(token => {
                    axios.post(`${URL.resetOtp}`, data, { headers: { "Authorization": `Bearer ${token} ` } }).then(res => {
                        console.log(res, "sendotp")
                        if (res.status === 200) {
                            setAlertmessage('sent the otp successfully')
                            setAlertstatus('success')
                            setShow(2);
                        }
                    }).catch(err => {
                        setAlertmessage("User name not registered please signup")
                        setAlertstatus('error')
                    }).finally(() => {
                        setLoading(false)
                    })
                })
        })

    };


    return (
        <Box>

            <Box sx={{ height: { sm: '0px', md: "3px" } }}></Box>
            <CommonDesign>
                <Box
                    sx={{
                        mt: { xs: 14, sm: 14, md: 2 },
                        px: { xs: 3, sm: 4, md: 4 },

                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', gap: 1, color: '#8A2BE2' }}>
                            <Public />
                            <Typography sx={{ fontWeight: 600, fontSize: 17 }}>India</Typography>

                        </Box>
                        <SelectLanguage />
                    </Box>
                    <Box sx={{
                        mx: { xs: 2, sm: 4, md: 4 }, overflowY: 'scroll', height: "400px", '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                        <Box sx={{ textAlign: 'center', mt: 3 }}>
                            <img src={medxpertIcon} alt="medxperts" />
                            <Typography variant='h6' sx={{ color: 'blueviolet', fontWeight: 600 }}>Medxperts</Typography>
                        </Box>
                        {show === 1 ? (
                            <>
                                <Box sx={{ mt: 4, }}>
                                    {/* <ColorTextField label="User Name" placeholder="Enter Your User Name" type="text" value={username} name="username" onChange={handleChangeUsername}
                                        InputProps="@mdx"
                                    /> */}
                                    <EndAdornmentTextField
                                        label="User Name"
                                        placeholder="Enter User Name"
                                        type={'text'}
                                        borderColor="#59D8FA"
                                        icon={<Typography sx={{ fontWeight: 600 }}>@mdx</Typography>}
                                        value={username}
                                        onChange={handleChangeUsername} />
                                </Box>

                                <Box sx={{ textAlign: 'center', mt: 4, }}>
                                    <CommonButton text="Send OTP" width={150} bgcolor={"#8A2BE2"} disabled={loading || !username}
                                        startIcon={
                                            loading ? (
                                                <CircularProgress size={20} color="inherit" />
                                            ) : null
                                        } onClick={handleSubmit} />
                                </Box>
                                <Box sx={{ mt: 2, textAlign: 'center' }}>
                                    {alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />}
                                </Box>
                            </>
                        ) : show === 2 ?
                            (
                                <ForgotPasswordOtp
                                    username={newPasswordUsername}
                                    phoneNumber={phonenum}
                                    setShow={setShow} />
                            ) :
                            show === 3 ?
                                (
                                    <CreateNewPassword username={newPasswordUsername} setShow={setShow} />
                                ) : null}
                    </Box>

                    <AlreadyAccount />
                </Box>
            </CommonDesign>
        </Box>
    );
}

export default ForgotPassword