import React from 'react';
import { Accordion, AccordionDetails, AccordionSummary, Box, Card, Typography } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';

function AcuteHeartFailure() {
    return (
        <Box sx={{ m: 1, mt: "20px" }}>
            <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Acute Heart Failure</Typography>
            <Card sx={{ display: 'flex', p: '5px', justifyContent: 'space-evenly', mt: 2 }}>
                <Typography sx={{ borderRight: '1px solid gray', fontSize: 16, '&:hover': { bgcolor: 'green', cursor: 'pointer', color: 'white' }, pl: "16px", pr: '30px' }}>Overview</Typography>
                <Typography sx={{ borderRight: '1px solid gray', fontSize: 16, '&:hover': { bgcolor: 'green', cursor: 'pointer', color: 'white' }, pl: "16px", pr: '30px' }}>Symptoms</Typography>
                <Typography sx={{ borderRight: '1px solid gray', fontSize: 16, '&:hover': { bgcolor: 'green', cursor: 'pointer', color: 'white' }, pl: "16px", pr: '30px' }}>In Emergency</Typography>
                <Typography sx={{ borderRight: '1px solid gray', fontSize: 16, '&:hover': { bgcolor: 'green', cursor: 'pointer', color: 'white' }, pl: "16px", pr: '30px' }}>What to Do</Typography>
                <Typography sx={{ px: 2, fontSize: 16, '&:hover': { bgcolor: 'green', cursor: 'pointer', color: 'white' } }}>Patient Resources</Typography>
            </Card >
            <Box sx={{ mt: 2 }}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ fontWeight: 600 }}
                    >
                        Overview
                    </AccordionSummary>
                    <AccordionDetails>
                        <p > Heart failure occurs when the heart muscle doesn't pump blood as well as it should.When this happens,blood often backs up and fluid can build up in the lungs,causing shortness of breath.</p>
                        <p style={{ marginTop: '10px' }}>Certain heart conditions gradually leave the heart too weak or stiff to fill and pump blood properly. These conditions include narrowed arteries in the heart and high blood pressure </p>
                        <p style={{ marginTop: '10px' }}> Proper treatment may improve the symptoms of heart failure and may help some people live longer.Lifestyle changes can improve quality of life. Try to lose weight,exercise,use less salt and manage stress. </p>
                        <p style={{ marginTop: '10px' }}>But heart failure can be life-threatening.People with heart failure may have severe symptoms. Some may need a heart transplant or a device to help the pump blood</p>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ fontWeight: 600 }}

                    >
                        Symptoms
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ fontWeight: 600 }}

                    >
                        In Emergency
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ fontWeight: 600 }}

                    >
                        What to Do
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Box sx={{ mt: 2 }}>
                <Accordion >
                    <AccordionSummary
                        expandIcon={<ExpandMore />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                        sx={{ fontWeight: 600 }}

                    >
                        Patient Resources
                    </AccordionSummary>
                    <AccordionDetails>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse
                        malesuada lacus ex, sit amet blandit leo lobortis eget.
                    </AccordionDetails>
                </Accordion>
            </Box>
        </Box >
    );
}

export default AcuteHeartFailure;
