import React, { useState } from 'react';
import { Box, Button, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';
import CommonDialog from '../../../atoms/CommonDialog';
import { Close } from '@mui/icons-material';
import { OutlinedButton } from '../../../atoms/CommonButton';
import { localstore } from '../../../../localStore/localStore';
import axios from 'axios';
import URL from '../../../../utility/apiurl';

function RequestDeleteProfile() {
    const [open, setOpen] = useState(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const token = localstore.getToken()
    const userId = localstore.getUserId()

    const handleClose = () => {
        setOpen(false);
    };


    const handleDeleteProfile = async () => {
        try {
            const res = await axios.delete(`${URL.patient}keycloak/deleteUser/${userId}`, {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            });
            console.log(res, "delete res")
            alert(res.data)
            handleClose()
            // Handle successful deletion
        } catch (error) {
            alert(error.message)
            handleClose()
            // Handle error
            console.error('Error deleting profile:', error);
        }
    };


    return (
        <Box sx={{ mt: 10 }}>
            <Typography variant='h6' sx={{ textAlign: 'center' }}>Delete MedXperts Profile</Typography>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                <Box>
                    <TextField
                        sx={{
                            ".MuiInputBase-root": {
                                borderRadius: 2,
                                backgroundColor: 'whitesmoke',
                                mt: 2,
                            }
                        }}
                        placeholder={'User Name'}
                        size="small"
                        fullWidth
                        variant="outlined"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        InputProps={{
                            endAdornment: <Typography variant="body1">@Mdx</Typography>,
                        }}
                    />

                    <TextField
                        sx={{
                            ".MuiInputBase-root": {
                                borderRadius: 2,
                                backgroundColor: 'whitesmoke',
                                mt: 2
                            }
                        }}
                        placeholder={'Password'}
                        size="small"
                        fullWidth
                        variant="outlined"
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />

                    <Grid mt={2} container justifyContent="center">
                        <Grid item>
                            <Button
                                type='submit'
                                sx={{ borderRadius: 3, mt: 2, width: "200px" }}
                                variant="contained"
                                color="primary"
                                onClick={() => setOpen(true)}
                            >
                                {'Delete Profile'}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <CommonDialog open={open} onClose={handleClose}>
                <DialogTitle sx={{
                    fontWeight: 500, p: '5px 15px', fontSize: '17px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                }}>
                    <Box></Box>
                    <IconButton onClick={handleClose}>< Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle >
                <DialogContent>
                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, fontWeight: 500 }}><WarningAmber />
                        Are you sure you want to delete your account permanently</Typography>
                    <Typography sx={{ fontSize: 13, ml: 4 }}>Press "Delete account " to remove it permanently,or "Cancel" if you want to keep your benefits</Typography>
                </DialogContent>
                <DialogActions sx={{ m: 0, justifyContent: 'center' }}>
                    <OutlinedButton text="Delete Account" height="30px" width="200px" borderColor="red" color="red" onClick={handleDeleteProfile} />
                    <OutlinedButton text="Cancel" height="30px" width="200px" onClick={handleClose} />
                </DialogActions>

            </CommonDialog>
        </Box>
    );
}

export default RequestDeleteProfile;
