import React from 'react'
import { Avatar, Box, CardContent, Divider, Menu, MenuItem, Typography } from '@mui/material'
import moment from 'moment'
import likeIcon from '../../../../../assets/home/feed_assets/like-icon.svg'
import commentIcon from '../../../../../assets/home/feed_assets/comment-icon.svg'
import shareIcon from '../../../../../assets/home/feed_assets/share-icon.svg'
import unlikeIcon from '../../../../../assets/home/feed_assets/liked-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { localstore } from '../../../../../localStore/localStore'
import URL from '../../../../../utility/apiurl'
import { useEffect } from 'react'
import { getpostbyid } from '../../../../../redux/Actions/Actions'
import axios from 'axios'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import ShareCopyIcon from '../../../../../assets/home/external-link.svg'
import medxpertsIcon from '../../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import AskDocTimeline from './AskDocTimeline'
import Celebrate from '../LiveHealth/PostTypes/Celebrate'
import PdfView from '../LiveHealth/PostTypes/PdfFile'
import Video from '../LiveHealth/PostTypes/Video'
import Media from '../LiveHealth/PostTypes/Media'
import Event from '../LiveHealth/PostTypes/Event'
import PollPost from '../LiveHealth/PostTypes/PollPost'
import ArticleView from '../LiveHealth/PostTypes/ArticleView'
import Text from '../LiveHealth/PostTypes/Text'
// import TimeLimeAskDoc from './TimelimeAskDoc'

export function timeSince(date) {

    var ms = (new Date()).getTime() - new Date(date).getTime();
    var seconds = Math.floor(ms / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var months = Math.floor(days / 30);
    var years = Math.floor(months / 12);

    if (seconds < 1) {
        return 'Just now';
    } if (seconds < 60) {
        return seconds + 's';
    } if (minutes < 60) {
        return minutes + 'min';
    } if (hours < 24) {
        return hours + 'h';
    } if (days < 30) {
        return days + 'd';
    } if (months < 12) {
        return months + 'mon';
    } else {
        return years + 'y';
    }
}


function DocTimelineData({ docPage }) {
    // console.log(orgPage, 'posssssssssss')
    const postdata = useSelector((state) => state.medx.postdata)
    // console.log(postdata, "posttttt")
    const [readmore, setReadmore] = useState(false);
    const [docFeed, setDocFeed] = useState(docPage)
    // console.log(pageOrg, 'pageorg')
    const [share, setShare] = useState(false)
    const [like, setLike] = useState(false)
    const [commentopen, setCommentopen] = useState(false)
    const token = localstore.getToken()
    const patientId = localstore.getPatientId()
    const dispatch = useDispatch()

    useEffect(() => {
        if (docFeed?.id === postdata?.id) {
            setDocFeed(postdata)
        }
    }, [docFeed, postdata])

    const handleLike = () => {
        setLike(true);
        let data = {
            userData: docFeed?.userData || docFeed?.pageData,
            date: new Date().toISOString(),
            likedBy: patientId,
            metadata: {
                author: patientId,
                dateTime: new Date().toISOString(),
                location: '',
                versionId: 0,
                securityCodes: '',
            },
            postId: docFeed.id,
        };

        axios
            .post(`${URL.addLikes}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                // console.log(res, "resssssssss")
                dispatch(getpostbyid(docFeed.id, token));
            })
            .catch(err => {
                console.log(err.message, 'handleLike');
            });
    };

    const handleUnlike = () => {
        setLike(false);
        axios
            .delete(`${URL.deleteLikes}${docFeed.likeId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                dispatch(getpostbyid(docFeed.id, token));
            })
            .catch(err => {
                console.log(err.message, 'handleUnlike');
            });
    };

    const handleClickComment = () => {
        dispatch(getpostbyid(docFeed.id, token))
        setCommentopen(!commentopen)
    }


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleShare = (e) => {
        console.log('hi share')
        setShare(!share)

        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const shareUrl = `${'http:/localhost:3500/'}post/${docPage?.id}`
    const handlelinkcopy = () => {
        navigator.clipboard.writeText(`${'http:/localhost:3500/'}post/${docPage?.id}`)
        // console.log(url, "yessss")
    }
    return (
        <>

            <Box sx={{
                backgroundColor: "white", p: "15px 0px", border: '1px solid #e5e5dc', borderRadius: " 10px", pb: "4px", mt: "-60px"
            }} >
                <Box sx={{ display: 'flex', gap: 1 }}>
                    {
                        docFeed?.pageData?.profilePic?.length > 0 || docFeed?.userData?.profilePic?.length > 0 ?
                            <Avatar src={docFeed.pageData?.profilePic ? docFeed?.pageData?.profilePic : docFeed?.userData?.profilePic} sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important", width: { sm: 55, md: 60 }, height: { sm: 55, md: 60 }, p: "5px" }} /> : <Avatar sx={{
                                borderRadius: "7px!important",
                                // border: "1px solid lightgrey",
                                marginLeft: "10px !important",
                                width: { sm: 55, md: 65 },
                                height: { sm: 55, md: 65 },
                            }} src={medxpertsIcon} />
                    }


                    <div>
                        {docFeed?.pageData?.fullName?.length > 0 || docFeed?.userData?.fullName?.length > 0 ?

                            <Typography sx={{ color: 'black', fontSize: { xs: 12, sm: 15, md: 15 }, fontWeight: 600 }}>{docFeed.pageData?.fullName || docFeed.userData?.fullName} </Typography> : <Typography>Not Mentioned</Typography>

                        }

                        {
                            docFeed?.pageData?.caption?.length > 0 || docFeed?.userData?.caption?.length > 0 ?
                                <Typography sx={{ fontStyle: 'italic', fontSize: { xs: 11, sm: 12, md: 13 } }}>{docFeed?.pageData?.caption || docFeed?.userData?.caption}</Typography>
                                :
                                null
                        }

                        <Typography color="black" >
                            <Typography sx={{ fontSize: { xs: 11, sm: 12, md: 13 } }}>{`${timeSince(moment.utc(docFeed?.createDate).local())}`}</Typography>&nbsp;
                        </Typography>
                    </div>
                </Box>

                <CardContent>
                    <Box sx={{ width: { xs: "450px", sm: "700px", md: "100%" } }}>

                        {
                            docFeed?.postType === 'TEXT' ? (
                                <Text docFeed={docFeed} />
                            ) : docFeed?.postType === 'ARTICLE' ? (
                                <ArticleView docFeed={docFeed} />
                            ) : docFeed?.postType === 'CELEBRATE' ? (
                                <Celebrate docFeed={docFeed} />
                            ) : docFeed?.postType === 'FILE' ? (
                                <PdfView docFeed={docFeed} stateview={true} />
                            ) : docFeed?.postType === 'VIDEO' ? (
                                <Video docFeed={docFeed} />
                            ) : docFeed?.postType === 'IMAGE' ? (
                                <Media docFeed={docFeed} />
                            ) : docFeed?.postType === 'EVENT' ? (
                                <Event docFeed={docFeed} />
                                // ) : item.postType === 'POLL' ? (
                                //     <PollPost docFeed={item} />
                            ) : null
                        }
                    </Box>

                    {/* <ExpandMoreIcon /> */}
                </CardContent>

                <Box sx={{ display: 'flex', pl: 1, pr: 1, mt: 3, justifyContent: 'space-between' }}>
                    {docFeed?.likeCount > 0 ? (
                        <Typography sx={{ fontSize: { xs: '7px', sm: '11px', md: '11px' }, margin: '5px' }}>
                            {docFeed?.likeCount} {docFeed.likeCount === 1 ? 'Person' : 'People'} Liked
                        </Typography>
                    ) : null}
                    {docFeed?.shareCount > 0 ? (
                        <Typography sx={{ fontSize: { xs: '7px', sm: '11px', md: '11px' }, margin: '5px' }}>
                            {docFeed?.shareCount} {docFeed.shareCount === 1 ? 'Person' : 'People'} Answered
                        </Typography>
                    ) : null}
                </Box>

                <Divider />
                <Box sx={{ display: { xs: 'flex' }, pl: 2, pr: 2, mt: "2px", justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: { md: 'flex' }, textAlign: 'center', gap: 1, cursor: 'pointer' }}>
                        {docFeed?.liked || like === true ?
                            <div onClick={handleUnlike}>
                                <img src={unlikeIcon} alt="unlike" />
                            </div>
                            :
                            <div onClick={handleLike}>
                                <img src={likeIcon} alt="like" />
                            </div>
                        }
                        <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }} onClick={docFeed?.liked || like === true ? handleUnlike : handleLike}>Like</Typography>
                    </Box>
                    <Box sx={{ display: { md: 'flex' }, textAlign: 'center', gap: 1, cursor: 'pointer' }} onClick={handleClickComment}>
                        <img src={commentIcon} alt="comment" />
                        <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}>Ask Doctor</Typography>

                    </Box>

                    <Box sx={{ display: { md: 'flex' }, textAlign: 'center', gap: 1, cursor: 'pointer' }} onClick={handleShare}>
                        <img src={shareIcon} alt="share" />
                        <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}>Share</Typography>

                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        keepMounted
                    >
                        <MenuItem >
                            <FacebookShareButton
                                url={shareUrl}
                                quote={'Title or jo bhi aapko likhna ho'}
                                hashtag={'#portfolio...'}
                            >
                                <FacebookIcon size={20} round='true' /> &nbsp;&nbsp;Facebook
                            </FacebookShareButton>
                        </MenuItem>
                        <MenuItem url={shareUrl}>
                            <TwitterShareButton url={shareUrl}>
                                <TwitterIcon size={20} round='true' /> &nbsp;&nbsp;Twitter
                            </TwitterShareButton>

                        </MenuItem>
                        <MenuItem url={shareUrl} >
                            <WhatsappShareButton
                                url={shareUrl}
                                quote={'Title or jo bhi aapko likhna ho'}
                                hashtag={'#portfolio...'}
                            >
                                <WhatsappIcon size={20} round='true' /> &nbsp;&nbsp;Whatsapp
                            </WhatsappShareButton>

                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <span onClick={handlelinkcopy} style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                <img src={ShareCopyIcon} width={18} height={18} />
                                &nbsp;&nbsp;CopyLink</span>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box >
            <AskDocTimeline commentOpen={commentopen} item={docFeed} />
        </>
    )
}

export default DocTimelineData;