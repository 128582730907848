import React from 'react'
import { Button, Typography } from '@mui/material'


export function OutlinedButton(props) {
    return (
        <div>
            <Button variant="outlined" sx={{ mt: props.mt, width: props.width, border: props.border, color: props.color, "&:hover": { border: props.border, borderColor: props.borderColor }, height: props.height, borderRadius: "10px", borderColor: props.borderColor }} onClick={props.onClick} startIcon={props.startIcon} endIcon={props.endIcon}>
                <Typography sx={{ fontSize: props.fontSize }}> {props.text}</Typography>
            </Button></div >
    )
}
function CommonButton(props) {
    return (
        <div>
            <Button variant="contained" sx={{ width: props.width, bgcolor: props.bgcolor, "&:hover": { bgcolor: props.bgcolor }, height: props.height, borderRadius: "10px" }} onClick={props.onClick} disabled={props.disabled} startIcon={props.startIcon} endIcon={props.endIcon}>
                {props.text}
            </Button></div>
    )
}

export default CommonButton