import { useState } from 'react'
import axios from 'axios';
import { DialogActions, DialogContent, DialogTitle, Divider } from '@mui/material'
import React, { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material'
import URL, { OtpPublicKey } from '../../../../utility/apiurl';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import JSEncrypt from 'jsencrypt';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';

function DeleteHealthId2(props) {
    const ibm = useSelector((state) => state.medx.ibmpdata)
    console.log(ibm, "ibm")
    const result = useSelector((state) => state.consent.deletetokentxnId)
    console.log(result, "resut")
    const txnId = result.txnId
    const xtoken = result.xtoken
    const [otp, setOtp] = useState('')

    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global");
    const fixedId = localstore.getPrimaryFixedId()


    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }
    const handleVerify = async () => {
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(OtpPublicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let d = {
            txnId: txnId,
            "otp": encryptOtp.replace(/\n/g, ''),
        };
        console.log('d...', d)
        setLoading(true);
        await axios
            .post(`${URL.consents}account/profile/delete/${primaryId}`, d, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                    'X-Token': `Bearer ${xtoken}`,

                },
            })
            .then(async res => {

                const ibmp = { ...ibm };
                const ibmpdata = ibmp?.identifier;

                const updatedIdentifier = ibmpdata?.filter(item => {
                    const coding = item?.type?.coding[0];
                    return !(coding && coding?.code === "HIN");
                });

                ibmp.identifier = updatedIdentifier;
                const data = ibmp;

                axios.put(`${URL.cliniapi}Patient/${primaryId}`, data, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then(res => {
                    setAlertmessage('Data registered Successfully!');
                    setAlertstatus('success');
                    dispatch(getAbhaProfile(primaryId, token));
                    dispatch(getPatientdatafromibm('Patient', primaryId, token));
                    handleCancel()
                })

                // if (fixedId === primaryId) {
                //     const ibmp = { ...ibm };
                //     const identifierAtIndex0 = ibmp.identifier[0];
                //     ibmp.identifier = [identifierAtIndex0];
                //     const d1 = ibmp;
                //     // Update the patient data
                //     axios.put(`${URL.cliniapi}Patient/${primaryId}`, d1, {
                //         headers: {
                //             Authorization: `Bearer ${token}`,
                //         },
                //     }).then(res => {
                //         handleCancel()
                //         setAlertmessage('Data registered Successfully!');
                //         setAlertstatus('success');
                //         dispatch(getAbhaProfile(primaryId, token));
                //         dispatch(getPatientdatafromibm('Patient', primaryId, token));
                //         handleCancel()
                //     })
                // } else {
                //     const ibmp = { ...ibm };
                //     delete ibmp.identifier;
                //     const d2 = ibmp;
                //     axios.put(`${URL.cliniapi}Patient/${primaryId}`, d2, {
                //         headers: {
                //             Authorization: `Bearer ${token}`,
                //         },
                //     }).then(res => {
                //         handleCancel()
                //         setAlertmessage('Data registered Successfully!');
                //         setAlertstatus('success');
                //         dispatch(getAbhaProfile(primaryId, token));
                //         dispatch(getPatientdatafromibm('Patient', primaryId, token));
                //         handleCancel()
                //     })
                // }
            }).catch(err => {
                console.log('--------errorinconfirm', err);
                console.log(err.message);
                setAlertmessage('OTP entered is incorrect!');
                setAlertstatus('error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCancel = () => {
        setOtp('')
        props.handleClose()
        props.setShow(1)
    }

    return (
        <div>
            <DialogTitle
                sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                }}
            >
                <Box sx={{ display: "flex", gap: 3, alignItems: "center" }}>
                    <Typography sx={{ fontWeight: 500, ml: 3 }}>
                        {t("delhealthidtxt")}
                    </Typography>
                </Box>
                <Button
                    sx={{ height: "25px", color: "#B48FE4", borderRadius: "15px" }}
                    variant="outlined"
                    onClick={handleCancel}
                >
                    {t("cancel")}
                </Button>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <Box>
                        <Box>
                            <Typography
                                sx={{
                                    fontSize: { xs: 14, md: 16 },
                                    textAlign: "center",
                                    color: "black",
                                    ml: 1,
                                    fontWeight: 500,
                                }}
                            >
                                {t("confirmotp")}
                            </Typography>
                            <Box mt={2}>
                                <TextInputField
                                    width="350px"
                                    placeholder={t("enterotphere")}
                                    border="none"
                                    name="otp"
                                    value={otp}
                                    onChange={handleOnChangeOtp}
                                />
                            </Box>

                            {alertmessage && (
                                <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                            )}

                            <Box mt={5}>
                                <Typography sx={{ color: "black", fontSize: 12 }}>
                                    {t("enterotp")}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                    variant="contained"
                    sx={{ borderRadius: 2, width: "150px", mt: 1, mb: 1 }}
                    onClick={handleVerify}
                    disabled={loading || otp?.length < 6}
                    startIcon={
                        loading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : null
                    }
                >
                    {/* {loading ? "submit.." : "Submit"} */}
                    {t("delete")}
                </Button>
            </DialogActions>
        </div>
    );
}

export default DeleteHealthId2