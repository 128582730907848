import React from 'react'
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';

function CommonCalender(props) {
    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                    <DatePicker
                        sx={{
                            ".MuiOutlinedInput-input":
                            {
                                padding: "9px 14px",
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                // border: "none",
                                borderRadius: "15px",
                            },
                            width: props.width,
                        }}
                        // label="Controlled picker"
                        // maxDate={dayjs(new Date())}
                        value={props.value}
                        onChange={props.onChange}
                        maxDate={props.maxDate}

                    />
                </DemoContainer>
            </LocalizationProvider>
        </div>
    )
}

export default CommonCalender