import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import Home from './components/pages/Home';
import Register from './components/pages/UserSignup/Register';
import Login from './components/pages/UserSignup/Login';
import ForgotPassword from './components/pages/UserSignup/Forgotpassword';
import ForgotUsername from './components/pages/UserSignup/ForgotUsername';
import Medxpedia from './components/pages/Medxperts/Medxpedia';
import Navbar from './components/organisms/Navbar';
import ServicesPage from './components/pages/Medxperts/ServicesPage/ServicesPage';
import Records from './components/pages/Medxperts/Records/Records';
import CareChart from './components/pages/Medxperts/CareChart/CareChart';
import Account from './components/pages/Medxperts/Account';
import NotFound from './components/pages/NotFound';
import Livehealth from './components/pages/Medxperts/Medxpedia/LiveHealth/Livehealth';
import MyDoctors from './components/pages/Medxperts/Medxpedia/MyDoctors';
import MyFavourites from './components/pages/Medxperts/Medxpedia/MyFavourites';
import MyProviders from './components/pages/Medxperts/Medxpedia/MyProviders';
import OffersTab from './components/pages/Medxperts/Medxpedia/Offers';
import RecentlyConsulted from './components/pages/Medxperts/Medxpedia/RecentlyConsulted';
import SwitchProfile from './components/pages/Medxperts/SwitchProfile';
import CreateProfile from './components/pages/Medxperts/CreateProfile';
import Consents from './components/pages/Medxperts/Consents/Consents';
import LoginAbhaAddress from './components/pages/Medxperts/LoginAbhaFlows/LoginAbhaAddress';
import ConnectHealthId from './components/pages/Medxperts/ConnectAbhaFlows/ConnectHealthId';
import AadharStep1 from './components/pages/Medxperts/AadharFlow/AadharStep1';
import Footer from './components/organisms/Footer';
import MobileStep1 from './components/pages/Medxperts/ConnectAbhaFlows/MobileStep1';
import EmailStep1 from './components/pages/Medxperts/ConnectAbhaFlows/EmailStep1';
import AbhaNumStep1 from './components/pages/Medxperts/ConnectAbhaFlows/AbhaNumStep1';
import HealthNumStep1 from './components/pages/Medxperts/LoginAbhaFlows/HealthNumStep1';
import UserIdStep1 from './components/pages/Medxperts/LoginAbhaFlows/UserIdStep1';
import MobileEmailStep1 from './components/pages/Medxperts/LoginAbhaFlows/MobileEmailStep1';
import QuickShare from './components/pages/Medxperts/QuickShare/QuickShare';
import VerifyAadhar from './components/pages/Medxperts/AadharFlow/VerifyKyc';
import SharePost from './components/pages/Medxperts/Medxpedia/LiveHealth/SharePost';
import MoreDoctors from './components/pages/Medxperts/Medxpedia/MoreDoctors';
import MoreHospitals from './components/pages/Medxperts/Medxpedia/MoreHospitals';
import EditAbhaProfile from './components/pages/Medxperts/EditAbhaProfile/EditAbhaProfile';
import { Box, useMediaQuery } from '@mui/material';
import NewRecords from './components/pages/Medxperts/Records/NewRecords';
import DoctorsProfile from './components/pages/Medxperts/Medxpedia/DoctorsProfile.js/DoctorsProfile';
import OrgProfile from './components/pages/Medxperts/Medxpedia/HospitalProfile/OrgProfile';
import MedicalRecords from './components/pages/Medxperts/Records/MedicalRecords';
import LinkedProviders from './components/pages/Medxperts/Records/LinkedProviders';
import Consultation from './components/pages/Medxperts/ServicesPage/Consultation';
import Pharmacy from './components/pages/Medxperts/ServicesPage/Pharmacy';
import Radiology from './components/pages/Medxperts/ServicesPage/Radiology';
import Endoscopy from './components/pages/Medxperts/ServicesPage/Endoscopy';
import Microbiology from './components/pages/Medxperts/ServicesPage/Microbiology';
import Surgical from './components/pages/Medxperts/ServicesPage/Surgical';
import Diagnostics from './components/pages/Medxperts/ServicesPage/Diagnostics';
import VisitClinic from './components/pages/Medxperts/ServicesPage/VisitClinic';
import ConsultOnline from './components/pages/Medxperts/ServicesPage/ConsultOnline';
import HealthLocker from './components/pages/Medxperts/HealthLocker/HealthLocker';
import Commentsmain from './components/pages/Medxperts/Medxpedia/LiveHealth/Commentactions/Commentsmain';
import PdfViewer from './components/pages/Medxperts/Medxpedia/LiveHealth/PostTypes/PdfViewer';
import SyncRecords from './components/pages/Medxperts/Records/SyncRecords';
import FullRecords from './components/pages/Medxperts/Records/FullRecords';
import FileViewer from './components/pages/Medxperts/Records/FileViewer';
import MyhealthLocker from './components/pages/Medxperts/HealthLocker/MyhealthLocker';
import ImageorPdfViewer from './components/pages/Medxperts/Records/ImageorPdfViewer';
import CompleteProfile from './components/pages/UserSignup/CompleteProfile';
import Medlearn from './components/pages/Medxperts/Medxpedia/Medlearn/Medlearn';
import HealthProblems from './components/pages/Medxperts/Medxpedia/Medlearn/HealthProblems';
import AcuteHeartFailure from './components/pages/Medxperts/Medxpedia/Medlearn/AcuteHeartFailure';
import CareDashboard from './components/pages/Medxperts/CareChart/CareDashboard';
import CareTimeline from './components/pages/Medxperts/CareChart/CareTimeline';
import CareSettings from './components/pages/Medxperts/CareChart/CareSettings';
import DownloadAbhacard from './components/pages/Medxperts/DownloadAbhaCard';
import CreatePhrAddress from './components/pages/Medxperts/ConnectAbhaFlows/CreatePhrAddress';
import ConsultationsTimeline from './components/pages/Medxperts/CareChart/ConsultationsTimeline';
import ClinicalGoal from './components/pages/Medxperts/CareChart/ClinicalGoal';
import DiagnosticReports from './components/pages/Medxperts/CareChart/DiagnosticReports';
import Medication from './components/pages/Medxperts/CareChart/Medication';
import Bloodpressure from './components/pages/Medxperts/CareChart/Bloodpressure';
import PlateletCount from './components/pages/Medxperts/CareChart/PlateletCount';
import AbhaMobileView from './components/AbhaMobileView';
import Privacy from './components/pages/Privacy/Privacy';
import RequestDeleteProfile from './components/pages/Medxperts/EditAbhaProfile/RequestDeleteProfile';
import { localstore } from './localStore/localStore';

function ScrollToTop() {
    const { pathname } = useLocation();
    React.useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return null;
}


const Approutes = () => {
    const isMobileView = useMediaQuery('(max-width:960px)');
    const primaryFixedid = localstore.getPrimaryFixedId()

    return (
        <Router>
            <ScrollToTop />
            <Navbar />
            <Box sx={{ pt: { xs: "0px", sm: '0px', md: "85px" } }} >

                <Routes>
                    {/* <Route path="/" element={<Home />} /> */}
                    <Route path="/login" element={<Login />} />
                    <Route path="/signup" element={<Register />} />
                    <Route path="/completeprofile" element={<CompleteProfile />} />
                    <Route path="/forgotpassword" element={<ForgotPassword />} />
                    <Route path="/forgotusername" element={<ForgotUsername />} />

                    <Route path="/" element={<Medxpedia />} >
                        <Route index element={<Livehealth />} />
                        <Route path="livehealth" element={<Livehealth />} />
                        <Route path="doctors" element={<MyDoctors />} />
                        <Route path="consulted" element={<RecentlyConsulted />} />
                        <Route path="favorites" element={<MyFavourites />} />
                        <Route path="moredoctors" element={<MoreDoctors />} />
                        <Route path="moreorgs" element={<MoreHospitals />} />
                        <Route path="providers" element={<MyProviders />} />
                        <Route path="doctor/:id" element={<DoctorsProfile />} />
                        <Route path="org/:id" element={<OrgProfile />} />
                        <Route path="medlearn" element={<Medlearn />} />
                        <Route path="healthproblems" element={< HealthProblems />} />
                        <Route path="heartfailure" element={< AcuteHeartFailure />} />
                        <Route path="offers" element={<OffersTab />} />
                        <Route path="commentsmain" element={<Commentsmain />} />
                    </Route>

                    <Route path={'/post/:id'} element={<SharePost />} />

                    < Route path="/services" element={<ServicesPage />} >
                        <Route index element={<Consultation />} />
                        <Route path="consultation" element={<Consultation />} />
                        <Route path="pharmacy" element={<Pharmacy />} />
                        <Route path="radiology" element={<Radiology />} />
                        <Route path="endoscopy" element={<Endoscopy />} />
                        <Route path="microbiology" element={<Microbiology />} />
                        <Route path="surgical" element={<Surgical />} />
                        <Route path="diagnostics" element={<Diagnostics />} />
                        <Route path="visit-clinic" element={<VisitClinic />} />
                        <Route path="consult-online" element={<ConsultOnline />} />
                    </Route>

                    <Route path="/records" element={<Records />} >
                        <Route index element={<MedicalRecords />} />
                        <Route path="medicalrecords" element={<MedicalRecords />} />
                        <Route path="linkedproviders" element={<LinkedProviders />} />
                        <Route path="newrecords" element={<NewRecords />} />
                        <Route path="syncrecords" element={<SyncRecords />} />
                        <Route path="fullrecords" element={<FullRecords />} />
                        <Route path="fileviewer" element={<FileViewer />} />
                        <Route path="imgorpdf" element={<ImageorPdfViewer />} />
                    </Route>

                    <Route path="/carechart" element={<CareChart />} >
                        <Route index element={<CareDashboard />} />
                        <Route path="caredashboard" element={<CareDashboard />} />
                        <Route path="caretimeline" element={<CareTimeline />} />
                        <Route path="caresettings" element={<CareSettings />} />
                        <Route path="consulttimeline" element={<ConsultationsTimeline />} />
                        <Route path="bloodpressure" element={<Bloodpressure />} />
                        <Route path="platelet" element={<PlateletCount />} />
                        <Route path="clinicalgoal" element={<ClinicalGoal />} />
                        <Route path="diagnosticreports" element={<DiagnosticReports />} />
                        <Route path="medication" element={<Medication />} />
                    </Route>

                    <Route path="/downloadabhacard" element={<DownloadAbhacard />} />
                    <Route path="/account" element={<Account />} />
                    <Route path="/consents" element={<Consents />} />
                    <Route path="/switchprofile" element={<SwitchProfile />} />
                    <Route path="/createprofile" element={<CreateProfile />} />


                    <Route path="/healthlocker" element={<HealthLocker />} />
                    <Route path="/myhealthlocker" element={<MyhealthLocker />} />
                    <Route path="/quickshare" element={<QuickShare />} />

                    <Route path="/loginabha" element={<LoginAbhaAddress />} />
                    <Route path="/connecthealthid" element={<ConnectHealthId />} />
                    <Route path="/aadharflow" element={<AadharStep1 />} />
                    <Route path="/mobileflow" element={<MobileStep1 />} />
                    <Route path="/emailflow" element={<EmailStep1 />} />
                    <Route path="/abhanumber" element={<AbhaNumStep1 />} />
                    <Route path="/verifykyc" element={<VerifyAadhar />} />

                    <Route path="/loginmobile" element={<MobileEmailStep1 />} />
                    <Route path="/loginuser" element={<UserIdStep1 />} />
                    <Route path="/loginabhanumber" element={<HealthNumStep1 />} />
                    <Route path="/pdfViewer" element={<PdfViewer />} />
                    <Route path="/phr" element={<CreatePhrAddress />} />
                    {/* <Route path="/abhaprofile" element={<AbhaMobileView />} /> */}

                    <Route path="/editprofile" element={<EditAbhaProfile />} />
                    <Route path="/abhaprofile" element={isMobileView ? <AbhaMobileView /> : <EditAbhaProfile />} />
                    {primaryFixedid ?
                        <>
                            <Route path="/deleteprofile" element={<RequestDeleteProfile />} />
                        </> : null}
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="*" element={<NotFound />} />
                </Routes >
            </Box >
            {/* <Footer /> */}
        </Router >
    )
}

export default Approutes;
