import { Box, Button, Card, CircularProgress, Typography } from '@mui/material'
import React, { useState } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import URL, { OtpPublicKey } from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';

import { useTranslation } from "react-i18next";
import JSEncrypt from 'jsencrypt';

function ValidateOtp(props) {
    const [otp, setOtp] = useState('');
    const [loading, setLoading] = useState(false)
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const healthnumberdetails = useSelector((state) => state.abha.sessionIdandMobilenumber)
    // console.log(healthnumberdetails, "=========>hhhhhhhhh")
    const mobileNumber = healthnumberdetails?.mobile
    const sessionId = healthnumberdetails?.sessionId
    const primaryId = localstore.getPrimaryId()
    const token = localstore.getToken()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = OtpPublicKey
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global");

    let formattedMobileNumber = '';
    if (typeof mobileNumber === 'string') {
        formattedMobileNumber = mobileNumber?.slice(-4);
    }
    const handleCreatePhr = async () => {
        setLoading(true)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(publicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let data = {
            otp: encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            mobileOrAadhaar: 'mobile',
            sessionId: sessionId,
        };
        setLoading(true);
        await axios.post(`${URL.abha}mobile/validate/otp/${primaryId}`, data, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                console.log('mobilestep2 validate', res.data);
                setAlertopen(true);
                setAlertMessage('OTP Verified Successfully!');
                setAlertstatus('success');
                if (res.data) {
                    dispatch({
                        type: "AADHAR_MAPPEDPHRADDRESS",
                        payload: res.data
                    })
                    props.setShow(3)
                }
                else {
                    props.setShow(4)
                }
            })
            .catch(err => {
                console.log(err.message);
                setAlertopen(true);
                setAlertMessage('OTP entered is incorrect!');
                setAlertstatus('error');

            }).finally(() => {
                setLoading(false)
            })
    }

    return (
        <div>
            <Typography sx={{ fontWeight: 600, ml: 1, mt: { md: "5px", sm: 2, xs: 4 } }}>
                Link PHR Address
            </Typography>
            <Card sx={{ mt: "15px", p: 3 }}>
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Center-align horizontally
                        justifyContent: "center", // Center-align vertically
                        textAlign: "center", // Center-align text within the card
                    }}
                >
                    <Typography>
                        {t("verifythisphone")}
                        {formattedMobileNumber}
                    </Typography>

                    <Box mt={2}>
                        <TextInputField
                            width="350px"
                            border="none"
                            name="otp"
                            placeholder={t("credentialsData.signupmobiledata.enterotp")}
                            value={otp}
                            onChange={(e) => setOtp(e.target.value)}
                        />
                    </Box>
                    {alertmessage && (
                        <Typography
                            sx={{
                                color: alertstatus === "success" ? "green" : "red",
                                mt: 1,
                            }}
                        >
                            {alertmessage}
                        </Typography>
                    )}
                    <Box sx={{ mt: 3, textAlign: "center" }}>
                        <Button
                            variant="contained"
                            sx={{ width: "150px", borderRadius: 2 }}
                            disabled={otp.length < 5}
                            onClick={handleCreatePhr}
                            startIcon={
                                loading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : null
                            }
                        >
                            {t("credentialsData.signupmobiledata.submitbtn")}
                        </Button>
                    </Box>
                </Box>
            </Card>
        </div>
    );
}

export default ValidateOtp