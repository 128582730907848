import React, { useState, useEffect } from 'react';
import { ArrowForward, Close, SyncAltOutlined } from '@mui/icons-material';
import { Avatar, Box, Card, Divider, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import {
    getAbhaProfile,
    getFixedPatient,
    getPatientdatafromibm,
    switchProfile,
} from '../../../redux/Actions/Actions';
import { localstore } from '../../../localStore/localStore';
import axios from 'axios';
import URL from '../../../utility/apiurl';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';

const getAge = (bdate) => {
    let d = new Date().getFullYear();

    if (bdate) {
        let birthyear = bdate?.split('-')[0];
        return Number(d) - Number(birthyear);
    }
};

function SwitchProfile(route) {

    // const switchprofiles = useSelector((state) => state.medx.switchprofile);
    const switchprofiles = useSelector((state) => state.medx.switchprofile?.response?.entry);
    // console.log(switchprofiles, "afterdata")

    const fixeddata = useSelector((state) => state.medx.fixeddata);
    // console.log(fixeddata, "beforedata")
    let ibm = useSelector((state) => state.medx.ibmpdata);
    // console.log(switchprofiles, "switchprofilessss")
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [relatedid, setRelatedid] = useState('');
    const [loading, setLoading] = useState(false);
    const [t, i18n] = useTranslation("global");


    const handleBack = () => {
        navigate('/services');
    };

    const token = localstore.getToken()
    const fixedid = localstore.getPrimaryFixedId()


    useEffect(() => {
        if (token && fixedid) {
            dispatch(getFixedPatient(fixedid, token));
            dispatch(switchProfile(fixedid, token));
        }

    }, [token, fixedid]);




    const handleSwitch = async (id, e) => {

        // console.log("----------relatedID", id)
        // console.log("------fixedid", fixedid)
        if (fixedid !== id) {
            // console.log("------url", `https://dev.api.medxperts.in/api/v1/patient/getRelatedPersonId/${id}`)
            await axios.get(`${URL.patient}getRelatedPersonId/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                // console.log(res, "=================>resswitch")
                const resId = res.data
                setLoading(true);
                localStorage.setItem('primaryId', resId);
                dispatch(getAbhaProfile(resId, token));
                dispatchfn(resId);
                setTimeout(() => {
                    navigate({ pathname: '/services', state: "healthprofile" })
                    setLoading(false);
                }, 2000)
            })
        }
        else {
            setLoading(true);
            localStorage.setItem('primaryId', id);
            setRelatedid(id);
            dispatch(getAbhaProfile(id, token));

            dispatchfn(id);

            setTimeout(() => {
                navigate({ pathname: '/services', state: "healthprofile" })
                setLoading(false);
            }, 2000)
        }
    };

    const dispatchfn = (id) => {
        // console.log(id, "haiderrrr")
        dispatch(
            getPatientdatafromibm(
                'Patient',
                id,
                token,
            ))

    };
    // console.log(ibm, "switchibmmm=========>")

    return (
        <Box sx={{ mt: { md: 2, xs: 9.5, sm: 9 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '10px', sm: "5px", md: '50px' }, mb: { xs: 10, sm: 10, md: 2 } }}>


            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, ml: 1 }}>
                <SyncAltOutlined />
                <Typography fontWeight={600} sx={{ fontSize: 17 }}>Select Profile to Switch </Typography>
            </Box>
            <Paper elevation={3} sx={{ mt: 2, p: 2, mb: { sm: 10 } }}>

                {fixeddata?.id?.length > 0 ? (
                    <Box sx={{ border: "1px solid #E7E7E7", p: 1, mt: 1 }}>
                        <Grid
                            container
                            spacing={2}
                            alignItems={"center"}
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                                handleSwitch(fixeddata?.id, fixeddata);
                            }}
                        >
                            <Grid item xs={0.5} md={0.5} sm={0.5}></Grid>

                            <Grid item xs={2} md={2} sm={2}>
                                <Avatar
                                    sx={{ bgcolor: "gray", width: { xs: 45, md: 55 }, height: { xs: 45, md: 55 } }}
                                    variant="rounded"
                                >
                                    {fixeddata?.photo &&
                                        fixeddata?.photo[0]?.url?.length > 0 && (
                                            <img
                                                src={fixeddata?.photo[0].url}
                                                alt="Profile Picture"
                                                style={{
                                                    objectFit: "cover",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        )}
                                </Avatar>
                            </Grid>
                            <Grid item xs={7.5} md={7.5} sm={7.5}>
                                <Typography sx={{ color: "gray", fontSize: '14px' }}>
                                    {t("primary")}
                                </Typography>
                                <Typography>
                                    {fixeddata?.name[0]?.given[0]} {fixeddata?.name[0]?.family}
                                </Typography>
                                <Typography sx={{ fontSize: '12px' }}>
                                    {getAge(fixeddata.birthDate)} Y/
                                    {fixeddata?.gender &&
                                        fixeddata?.gender?.charAt(0).toUpperCase() +
                                        fixeddata?.gender?.slice(1)}
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={2} md={2}>
                                <IconButton
                                    sx={{
                                        bgcolor: "#007DCD",
                                        "&:hover": {
                                            bgcolor: "#007DCD",
                                        },
                                        color: "white",
                                    }}
                                >
                                    <ArrowForward name="done" />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Box>
                ) : null}

                {
                    switchprofiles?.map((e, i) => (
                        <Box key={i} sx={{ border: "1px solid #E7E7E7", p: 1 }}>
                            <Grid
                                container
                                spacing={2}
                                alignItems={"center"}
                                sx={{ cursor: "pointer" }}
                                onClick={() => handleSwitch(e?.resource?.id, e)}
                            >
                                <Grid item xs={0.5} md={0.5} sm={0.5}></Grid>

                                <Grid item xs={2} md={2} sm={2}>
                                    <Avatar
                                        sx={{ bgcolor: "gray", width: { xs: 45, md: 55 }, height: { xs: 45, md: 55 } }}
                                        variant="rounded"
                                    >
                                        {e?.resource?.photo &&
                                            e?.resource?.photo[0]?.url?.length > 0 && (
                                                <img
                                                    src={e?.resource?.photo?.[0]?.url}
                                                    alt="Profile Picture"
                                                    style={{
                                                        objectFit: "cover",
                                                        width: "100%",
                                                        height: "100%",
                                                    }}
                                                />
                                            )}
                                    </Avatar>
                                </Grid>
                                <Grid item xs={7.5} md={7.5} sm={7.5}>
                                    <Typography variant="body2" sx={{ color: "gray", fontSize: 14 }}>
                                        {e?.resource?.relationship?.[0]?.coding?.[0]?.display},
                                    </Typography>
                                    <Typography>
                                        {e?.resource?.name?.[0]?.given?.[0]}{" "}
                                        {e?.resource?.name?.[0]?.family}
                                    </Typography>
                                    <Typography sx={{ fontSize: '12px' }}>
                                        {getAge(e?.resource?.birthDate)}Y/
                                        {e?.resource?.gender &&
                                            e?.resource?.gender.charAt(0).toUpperCase() +
                                            e?.resource?.gender?.slice(1)}
                                    </Typography>
                                </Grid>
                                <Grid item xs={1} sm={2} md={2}>
                                    <IconButton
                                        sx={{
                                            bgcolor: "#007DCD",
                                            color: "white",
                                            "&:hover": {
                                                bgcolor: "#007DCD",
                                            },
                                        }}
                                    >
                                        <ArrowForward name="done" />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Box>
                    ))}
            </Paper>

        </Box>
    );
};

export default SwitchProfile;
