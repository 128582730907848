
import React, { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import axios from 'axios';
import { useState } from 'react'
import { Box, Button, Card, CircularProgress, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import URL, { OtpPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { getPatientById, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import ResendOtp, { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';

function AbhaNumStep2(props) {
    const abhanumberdetails = useSelector((state) => state.abha.abhanumbertransId)
    // console.log(abhanumberdetails, "abhaaaaaaaa")
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const transactionId = abhanumberdetails.transactionId
    const abhaNumber = abhanumberdetails.number
    const [otp, setOtp] = useState('')
    const [time, setTime] = useState(59);
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = OtpPublicKey
    const dispatch = useDispatch()

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);

    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }

    const handleVerify = async () => {
        setLoading(true)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(publicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let d = {
            // otp: encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            otp: otp,
            mobileOrAadhaar: 'mobile',
            transactionId: transactionId,
        };
        setLoading(true);
        // console.log('mobilestep2', d);
        await axios
            .post(`${URL.abha}registration/hid/confirm-init/${primaryId}`, d, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(res => {
                console.log('AbhaNumberStep2 validate', res.data);
                let data1 = res.data;
                const PHRAddress = [];
                let transactionId1 = '';
                data1.forEach(item => {
                    // Check if the item has a "transactionId" property
                    if ('transactionId' in item) {
                        transactionId1 = item.transactionId;
                    } else {
                        // If not, it's one of the other values, so push it into the otherValues array
                        PHRAddress.push(item);
                    }
                });
                // console.log(PHRAddress, "phraddress")
                // console.log(transactionId1, "transactionid")
                setAlert(true);
                setAlertmessage('OTP Verified Successfully!');
                setAlertstatus('success');

                let ibmp = ibm?.identifier
                    ? { ...ibm }
                    : { ...ibm, identifier: [] };
                let da = {
                    ...ibmp,
                    identifier: [
                        ...ibmp.identifier,
                        {
                            system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                            value: abhaNumber,
                            type: {
                                coding: [
                                    {
                                        // code: 'JHN',
                                        code: 'HIN',
                                    },
                                ],
                            },
                        },
                    ],
                };

                axios
                    .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(res => {
                        if (PHRAddress === null || PHRAddress === undefined || PHRAddress.length === 0) {

                            let details = {
                                sessionId: transactionId1,
                                AbhaNumber: true,
                            }
                            dispatch({
                                type: 'ABHAFLOW_SESSIONID',
                                payload: details
                            })

                            props.setShow(6)
                        } else {
                            let details1 = {
                                transactionId: transactionId1,
                                AbhaNumber: true,
                            }
                            dispatch({
                                type: 'ABHAFLOW_SESSIONID1',
                                payload: details1
                            })
                            dispatch({
                                type: "ABHA_PHRADDRESS",
                                payload: PHRAddress
                            })
                            props.setShow(3)
                        }
                        dispatch(getPatientdatafromibm('Patient',
                            primaryId,
                            token,))
                        setAlertmessage('Data updated Successfully!');
                        setAlertstatus('success');

                    })
                    .catch(err => {
                        console.log(err.message, 'error in update');
                    });

            })
            .catch(err => {
                // console.log(err.message);
                setAlertmessage('OTP entered is incorrect! or try again in 12 hours');
                setAlertstatus('error');

            })
            .finally(() => {
                setLoading(false);
                // navigation.navigate('SelectPhr');
            });
        // setOtp('')
    };
    const handleResendOtp = async () => {
        setLoading(true)
        setTime(59);
        let data = {
            transactionId: transactionId,
        };

        await axios.post(`${URL.abha}hid/init/resend/otp/${primaryId}`, data, {
            headers: {
                'gatewayToken': 'medxperts',
                'Authorization': `Bearer ${token}`,
            }
        }).then(res => {


            console.log(res, "abharesendOtppppp")
            setAlert(true);
            setAlertmessage('OTP sent Successfully!');
            setAlertstatus('success');

        })
            .catch(err => {
                console.log(err.message);
                setAlert(true);
                setAlertmessage('Oops something went wrong!');
                setAlertstatus('error');

            })
            .finally(() => {
                setLoading(false);
            });

        setOtp('')
    }
    return (
        <div>

            <Box sx={{ mt: { xs: "6px", sm: "8px", md: 1 } }}>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: { xs: 1, sm: 2, md: 2 } }}>
                    <IconButton onClick={() => {
                        props.setShow(1)
                        props.setAlertmessage('')
                    }}>
                        <ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: 15, sm: '16px', md: "18px" }, fontWeight: '500', color: '#007DCD' }} >Change Registration Details </Typography>
                </Box>
            </Box>

            <Paper elevation={3} sx={{ p: 3, mt: 1 }}>
                <Box sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                }}>
                    <Box mt={1} >
                        <Typography sx={{ fontSize: { xs: 14, md: 16 }, textAlign: "center", color: 'black', ml: 1, fontWeight: 500 }}>
                            Verify OTP
                        </Typography>


                        <Box mt={2}>
                            <TextInputField width="350px" placeholder="Enter OTP Here" border="none" name="otp" value={otp} onChange={handleOnChangeOtp} />
                        </Box>
                        <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} />
                    </Box>

                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )}


                    <Box sx={{ mt: 2, textAlign: 'center', mb: 2 }}>

                        <Button variant="contained" sx={{ borderRadius: 2, width: "150px", mt: 1, }} onClick={handleVerify}
                            disabled={loading || otp?.length < 6} startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}

                        >
                            {/* {loading ? "submit.." : "Submit"} */}Submit
                        </Button>
                    </Box>


                    <Box mt={5}>
                        <Typography sx={{ color: 'black', fontSize: 12 }}>
                            Enter OTP sent on Mobile NO.Associated with ABHA Health ID
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </div>
    )
}

export default AbhaNumStep2