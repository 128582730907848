import React, { useCallback, useState } from 'react'
import { OrganizationIdentifier } from '../../../../redux/Actions/recordActions';
import { useDispatch } from 'react-redux';
import consentIcon from '../../../../assets/home/consent_module/consent.svg'
import EditIcon from '../../../../assets/home/consent_module/edit.svg'
import hospitalIcon from '../../../../assets/home/consent_module/medxperts.svg'
import prescriptionIcon from '../../../../assets/home/consent_module/prescription.svg';
import consultationIcon from '../../../../assets/home/consent_module/consultation.svg';
import diagnosticIcon from '../../../../assets/home/consent_module/diagnostic.svg';
import dischargeIcon from '../../../../assets/home/consent_module/discharge.svg';
import immunisationIcon from '../../../../assets/home/consent_module/immunisation.svg';
import documentIcon from '../../../../assets/home/consent_module/document.svg';
import WellnessRecordIcon from '../../../../assets/home/consent_module/lifestyle.svg';
import FileTextIcon from '../../../../assets/home/consent_module/file-text.svg';
import { Box, Card, Grid, Typography } from '@mui/material';
import { Date, DateFormat } from '../../../atoms/DateTimeFormat/DateFormat';
import { Time } from '../../../atoms/DateTimeFormat/TimeFormat';
import { Compare } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';



function RecordsyncCard({ records, MRN, hipid, token, primaryid }) {
    // const navigate = useNavigation();

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [isRefreshing, setRefreshing] = useState(false);

    const onRefresh = useCallback(() => {
        setRefreshing(true);

        dispatch(OrganizationIdentifier(hipid, token, primaryid));
        setTimeout(() => {
            setRefreshing(false);
        }, 2000);
    }, [hipid, token, primaryid, dispatch, setRefreshing]);

    const StatusIcon = (type) => {
        if (type === "DiagnosticReport") {
            return <img src={diagnosticIcon} alt="diagnostic" width={25} height={25} />
        }
        if (type === "DischargeSummary") {
            return <img src={dischargeIcon} alt="discharge" width={25} height={25} />
        }
        if (type === "OPConsultation") {
            return <img src={consultationIcon} alt="consult" width={25} height={25} />
        }
        if (type === "ImmunizationRecord") {
            return <img src={immunisationIcon} alt="record" width={25} height={25} />
        }
        if (type === "WellnessRecord") {
            return <img src={WellnessRecordIcon} alt="wellness" width={25} height={25} />
        }
        if (type === "HealthDocumentRecord") {
            return <Compare />
        }
        if (type === "Prescription") {
            return <img src={prescriptionIcon} alt="prescription" width={25} height={25} />
        }
        if (type === "reports") {
            return <img src={FileTextIcon} alt="reports" width={25} height={25} />
        }
        if (type === '') {
            return <img src={FileTextIcon} alt="file" height={25} width={25} />;
        } else {
            return <img src={FileTextIcon} alt="file" height={25} width={25} />;
        }
    }
    return (
        <div>

            {records?.length > 0 ?
                records?.map((list, index) => (
                    < Card sx={{
                        width: { xs: '100%', sm: "100%", md: "100%" }, mt: 3, mb: 5, cursor: 'pointer'
                    }} key={index} onClick={() => navigate('/records/fullrecords', {
                        state: {
                            Data: list,
                            HiType: list?.title,
                            EncouterId: list?.encounter?.reference,
                            MRN: MRN,
                            Display: list?.author?.[0]?.display ? list?.author?.[0]?.display : null,
                        }
                    })}>

                        <Box sx={{ p: '7px 20px' }}>
                            <Grid container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography sx={{ fontSize: { xs: '10px', sm: '14px', md: '16px' } }} color="text.secondary" gutterBottom>
                                    {list?.title}
                                </Typography>
                                <Typography sx={{ fontSize: { xs: '10px', sm: '13px', md: '13px' } }} >
                                    {DateFormat(list?.date)} | {Time(list?.date)}
                                </Typography>
                            </Grid>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    {(StatusIcon(list?.title))}
                                    <Box>
                                        <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' }, fontWeight: 600 }}> {list?.attester[0]?.party?.display
                                            ? list?.attester[0]?.party?.display
                                            : 'Hosipital_Name'}</Typography>
                                        <Typography sx={{ mt: '5px', fontSize: { xs: '13px', sm: '14px', md: '14px' } }}>{list?.author?.[0]?.display}
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ fontSize: { xs: '10px', md: '14px', sm: '14px' }, mt: 3 }} >
                                    {list?.title}
                                </Typography>
                            </Box>
                        </Box>
                    </Card >
                )) : <Typography sx={{ mt: "20px", textAlign: 'center', fontWeight: 600 }}> No Records Available</Typography>}
        </div >
    )
}

export default RecordsyncCard