import React, { useRef, useState } from 'react'
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Slide, Typography } from '@mui/material';
import CommonButton from '../../../atoms/CommonButton';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { ArrowForwardIos, Close, RemoveCircleOutline } from '@mui/icons-material';
import PinInputField from '../../../atoms/TextFields/PinInputField';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat';
import { getConsents } from '../../../../redux/Actions/consentActions';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function RevokeDialog({ item }) {
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const abhadata = useSelector(state => state.abha.abhaprofile)
    let linkeddata = useSelector(stat => stat.records.linkedproviders);
    const [open, setOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const otpFieldsRef = useRef([]);
    const [pin, setPin] = useState(['', '', '', '']);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()

    const handleInput = (index, value) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value.length === 1 && index < pin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertmessage('');
        } else if (value !== '') {
            setAlertmessage('Please enter 4 digits number only');
        }
    };

    const handleBackspace = (index) => {
        if (pin[index] !== '') {
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertmessage('');
    };
    const handleClose = () => {
        setOpen(false)
    }

    const handleCancel = () => {
        setPin(['', '', '', ''])
        setAlertmessage('')
        handleClose()
    }
    const handleVerifyPin = async () => {
        // props.setShow(3)

        setLoading(true)
        let data = {
            pin: pin.join(''),
            scope: "profile.revoke"
        }
        // setPinnumber(data.pin)
        await axios.post(`${URL.consents}verifyPin/${primaryId}/${abhadata.AbhaAddress}`, data, { headers: { 'Authorization': `Bearer ${token}` } })
            .then(res => {
                handleApprove(res.data.temporaryToken);

                setAlertmessage('PIN is valid!');
                setAlertstatus('success');
                handleClose()
            }).
            catch(error => {
                // console.error('Error validating OTP:', error);
                setAlertmessage('PIN Does Not Match!');
                setAlertstatus('error');
                // setAlertMessage('Oops, something went wrong!');
                // setAlertstatus('error');

            }).finally(() => {
                setLoading(false)
            })

    }



    const handleApprove = (temporaryToken) => {
        let consentId = item.id || item.requestId || item?.consentDetail?.consentId;
        const mappedData = {
            'consents': [
                consentId,
            ],
        };

        if (temporaryToken && consentId) {
            axios
                .post(`${URL.consents}consentrequest/revoke/${primaryId}`, mappedData, {
                    headers: {
                        'X-AUTH-TOKEN': temporaryToken,
                    },
                })
                .then(async res => {

                    // await dispatch(getAbhaProfile(primaryId, token));
                    setAlertmessage('Consent revoke is Successfully !');
                    setAlertstatus('success');
                    dispatch(getConsents(primaryId, abhadata?.AbhaAddress, 'ALL', token));

                    handleCancel()
                })
                .catch(err => {
                    // console.log('-----------ApproveApiError', err);
                    setAlertmessage(
                        'Revoke failed Please try later'
                    );
                    setAlertstatus('error');

                })
                .finally(() => {
                    setLoading(false);
                });
        }
    };
    return (
        <div>
            <Button variant='outlined' sx={{ border: '1px solid red', '&:hover': { border: '1px solid red' }, color: 'red', borderRadius: 4, height: { xs: "27px", md: '35px' } }} onClick={() => setOpen(true)} startIcon={<RemoveCircleOutline />}>Revoke</Button>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}><Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Consent Pin</Typography><IconButton><Close onClick={handleCancel} sx={{ color: 'red', cursor: 'pointer' }} /></IconButton></DialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ p: '10px 40px' }}>

                        <Card sx={{ textAlign: "center", p: '10px 0px 30px 0px ', borderBottom: '2px solid gray' }}>
                            <Typography sx={{ fontWeight: 500, }}>
                                Enter Consent Pin
                            </Typography>
                            <Box mt={3}>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "20px",
                                        textAlign: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {pin.map((value, index) => (
                                        <PinInputField
                                            type="text"
                                            maxLength="1"
                                            key={index}
                                            value={value}
                                            onChange={(e) => handleInput(index, e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Backspace") {
                                                    handleBackspace(index);
                                                }
                                            }}
                                            ref={(ref) => {
                                                otpFieldsRef.current[index] = ref;
                                            }}
                                        />
                                    ))}
                                </div>
                            </Box>

                        </Card>
                        {/* <Card sx={{ p: '0px 20px', cursor: 'pointer', display: 'flex', borderBottom: '2px solid gray', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography sx={{ fontWeight: 600 }}>
                    Biometric-Consent
                </Typography>
                <IconButton><ArrowForwardIos sx={{ color: 'black' }} /></IconButton>
            </Card> */}
                        <Card sx={{
                            p: '0px 20px', cursor: 'pointer',
                            boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)", display: 'flex', borderBottom: '2px solid gray', alignItems: 'center', justifyContent: 'space-between'
                        }}>
                            <Typography sx={{ fontWeight: 600 }}>
                                Consent with Password
                            </Typography>
                            <IconButton> <ArrowForwardIos sx={{ color: 'black' }} /></IconButton>
                        </Card>

                        {/* <Typography sx={{ fontSize: '14px', color: '#0822B3', mt: 1, fontWeight: 600, '&:hover': { textDecoration: 'underline' }, textAlign: 'center' }}>Reset Consent Pin?</Typography> */}


                        {/* <Card sx={{ textAlign: 'center', p: '10px' }}>
                <Typography> Enter Consent Pin</Typography>

            </Card> */}
                        {alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Box sx={{ m: "10px 0px" }}>
                        <CommonButton bgcolor="#9560D9" width="200px" text="Confirm Pin" endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null} onClick={handleVerifyPin} />
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default RevokeDialog