import { Box, Card, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import SuggestOrg from './SuggestOrg'
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { localstore } from '../../../../localStore/localStore';
import MoreHospitals from './MoreHospitals';
import { useNavigate } from 'react-router-dom';

function MyHospitals() {
    const [suggestOrg, setSuggestOrg] = useState([]);
    const [offset, setOffset] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const patientId = localstore.getPatientId()
    const navigate = useNavigate()
    const token = localstore.getToken()
    const limit = 50;


    useEffect(() => {
        axios.get(`${URL.suggestedOrganisations}${limit}/${offset}/${patientId}`)
            .then((res) => {
                setSuggestOrg(res.data.data)
                // console.log("=======>>", res.data)
                setIsLoading(true)

            })
            .catch((error) => console.log(error, 'EERRRRRRROOOOORRRRRR'));
    }, [])


    const handleOrgFavourite = (index) => {
        let data = suggestOrg.filter((e, i) => {
            if (i !== index) {
                return (e);
            }
        });
        setSuggestOrg(data);
    }

    const handleonnexthospitals = () => {
        navigate('/moreorgs')
    }
    return (
        <div>
            <Card sx={{ p: 1, mt: 2 }}>
                <Typography sx={{ ml: "5px", fontSize: '17px', fontWeight: 600 }}>
                    Hospitals & Providers Around
                </Typography>
                {!isLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress sx={{ color: "#CDF4F9" }} />
                    </Box>) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-around', gap: { xs: 1, sm: 1 }, p: 1 }}>
                        {suggestOrg?.slice(0, 3).map((each, ind) => (
                            <SuggestOrg key={ind} item={each}
                                index={ind} handleOrgFavourite={() => handleOrgFavourite(ind)} />
                        ))}
                    </Box>
                )}
                <Typography sx={{ textAlign: 'center', cursor: 'pointer', fontWeight: 600, color: '#2FA3EC', mb: { xs: 10, sm: 10, md: 0 }, '&:hover': { textDecoration: 'underline' } }} onClick={handleonnexthospitals}>More Hospitals</Typography>

            </Card>

        </div>
    )
}

export default MyHospitals