import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CircularProgress, IconButton, Paper, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowBackIos, Person } from '@mui/icons-material';
import { getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { Ibmpatient } from '../../../atoms/Patientdata';
import { localstore } from '../../../../localStore/localStore';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';

function EditRegistration(props) {
    const [ibmid, setIbmid] = useState('')
    const [linkid, setLinkid] = useState('')
    const [firstname, setFirstname] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [lastname, setLastname] = useState('')
    const [gender, setGender] = useState('male');
    const genderdata = ['male', 'female', 'other'];
    const [birthDate, setBirthDate] = useState('');
    const [image, setImage] = useState("")
    const [name, setName] = useState("")
    const [filename, setFilename] = useState("")
    const [filesize, setFilesize] = useState(0)
    const [filetype, setFiletype] = useState("")
    const [uploadpersonal, setUploadperonal] = useState(false)
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [uploadprofessional, setUploadprofessional] = useState(false)
    const [RelResourseData, setRelResourseData] = useState(null)
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [patient, setPatient] = useState('')
    const [relation, setRelation] = useState('')
    const [code, setCode] = useState('');
    const [system, setSystem] = useState('');
    const [display, setDisplay] = useState('');
    // console.log(firstname, "ffffffff")
    // console.log(lastname, "llllll")
    // console.log(birthDate, "bbb")
    // console.log(gender, "ggggggggg")
    // console.log(ibmid, "iii")
    // console.log(image, "image")
    // console.log(linkid, "linkid")
    // console.log(display, "display")
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const fixedid = localstore.getPrimaryFixedId()
    const dispatch = useDispatch()
    // console.log(ibm, "ggggggggg")

    useEffect(() => {
        setFirstname(patient?.name?.[0]?.given?.[0]);
        setLastname(patient?.name?.[0]?.family);
        setBirthDate(patient?.birthDate);
        setGender(patient?.gender);
        setIbmid(patient?.id)
        setImage(patient?.photo?.[0]?.url)
        setLinkid(patient?.link?.[0]?.other?.reference?.split('/')[1])
        setSystem(relation?.relationship?.[0]?.coding?.[0]?.system)
        setCode(relation?.relationship?.[0]?.coding?.[0]?.code)
        setDisplay(relation?.relationship?.[0]?.coding?.[0]?.display)
        // setDisplay()
    }, [patient, relation])

    const handleChangeDate = (newValue) => {
        setBirthDate(newValue?.format("YYYY-MM-DD"))
    }
    const handleGender = (selectedGender) => {
        setGender(selectedGender);
    }

    const imageHandler = (e, imgFile) => {
        if (e.target.files && e.target.files[0]) {
            const reader = new FileReader();
            const fsize = e.target.files[0].size
            const filename = e.target.files[0].name
            console.log(filename)
            let type = e.target.files[0].type.split("/");
            reader.readAsDataURL(e.target.files[0]);
            reader.onload = x => {
                let file = [];
                file.push(reader.result.split(',')[1])
                handleimagesubmit(file, type[1], fsize, filename, imgFile)
            }
        }
    }
    const handleimagesubmit = (Image, type, fsize, filename, imgFile) => {
        let data = {
            "file": Image,
            "fileType": type,
            "multiple": false,
            "subType": "profilePic",
            "fileSize": fsize,
            "type": "userProfilePics"
        }
        console.log(data)
        // let auth = `Bearer ${sessionStorage.getItem('kctoken')}`;
        axios.post(`${URL.uploadfile}`, data,).then(res => {
            console.log(res?.data?.data, "resphoto")
            if (res.data.status === 200) {
                setAlert({ open: true, msg: "Uploaded Succesfully" });
                setImage(res.data.data[0])
                setFilesize(fsize)
                setFiletype(type)
                if (imgFile === 'personal') {
                    // console.log("personal")
                    setName(filename)
                    setUploadperonal(true)
                }
                if (imgFile === 'professional') {
                    setFilename(filename)
                    setUploadprofessional(true)
                }
            }
        })
    }


    useEffect(() => {
        axios.get(`${URL.cliniapi}Patient/${primaryId}`, {

            headers: {
                Authorization: `${token}`
            }
        }
        ).then(res => {
            console.log(res, "patient")
            setPatient(res.data)

            axios.get(`${URL.cliniapi}RelatedPerson/${res.data.link?.[0]?.other?.reference?.split('/')[1]}`, {

                headers: {
                    Authorization: `${token}`
                }
            }
            ).then(res => {
                console.log(res, "relatedPerson")
                setRelation(res.data)
            })
                .catch((error) => console.log(error, 'EERRRRRRROOOOORRRRRR'));


        })
            .catch((error) => console.log(error, 'EERRRRRRROOOOORRRRRR'));

    }, [])


    // console.log(linkid, "linkssss")
    // const RelatedPersonDetails = () => {
    console.log(`RelatedPerson/${linkid}`, "goofby")


    // }


    const handleSubmit = async () => {

        setLoading(true)

        // Related Person Resource
        let data1 = {
            id: linkid,
            firstname: firstname,
            lastname: lastname,
            dateofbirth: birthDate,
            gender: gender,
            relation: display,
        }

        console.log(data1, "daaaa")
        let relateddata1 = data1.lastname
            ? {
                ...Ibmpatient,
                id: linkid,
                gender: gender.toLowerCase(),
                birthDate: birthDate,
                resourceType: 'RelatedPerson',
                name: [
                    {
                        ...Ibmpatient.name[0],
                        given: [data1.firstname],
                        family: data1.lastname,
                    },
                ],
                telecom: [],
                patient: {
                    reference: `Patient/${fixedid}`
                },
                relationship: [
                    {
                        coding: [
                            {
                                system: system,
                                code: code,
                                display: display,
                            },
                        ],
                    },
                ],
                telecom: ibm.telecom,
                photo: image?.length > 0 ? [{ url: image }] : [],
            }
            : {
                ...Ibmpatient,
                id: linkid,
                gender: gender.toLowerCase(),
                birthDate: birthDate,
                resourceType: 'RelatedPerson',
                name: [{ ...Ibmpatient.name[0], given: [data1.firstname] }],
                telecom: [],
                photo: image?.length > 0 ? [{ url: image }] : [],
            };
        let d1 = { ...relateddata1 };
        console.log(d1, "dddddddddddddddddddddd")
        //  Patient Resource

        let data2 = {
            id: ibmid,
            firstname: firstname,
            lastname: lastname,
            dateofbirth: birthDate,
            gender: gender,
        }

        console.log(data2, "daaaa")
        let relateddata2 = data2.lastname
            ? {
                ...Ibmpatient,
                id: ibmid,
                gender: gender.toLowerCase(),
                birthDate: birthDate,
                resourceType: 'Patient',
                name: [
                    {
                        ...Ibmpatient.name[0],
                        given: [data2.firstname],
                        family: data2.lastname,
                    },
                ],
                telecom: [],
                photo: image?.length > 0 ? [{ url: image }] : [],
            }
            : {
                ...Ibmpatient,
                id: ibmid,
                gender: gender.toLowerCase(),
                birthDate: birthDate,
                resourceType: 'Patient',
                name: [{ ...Ibmpatient.name[0], given: [data2.firstname] }],
                telecom: [],
                photo: image?.length > 0 ? [{ url: image }] : [],
            };
        let d2 = { ...relateddata2 };


        console.log(d2, "d2222222")
        if (fixedid !== ibmid) {

            // console.log(`RelatedPerson/ ${ linkid }`, "link")
            await axios.put(`${URL.cliniapi}RelatedPerson/${linkid} `, d1, {
                headers: {
                    Authorization: `Bearer ${token} `
                }
            }).then(res => {
                console.log(res, "ressss")

                setAlertMessage("Updated Successfully")
                setAlertstatus("success")
            })

            let secondarydata = {
                ...d2,
                resourceType: 'Patient',
                telecom: ibm.telecom,
            };
            secondarydata = {
                ...secondarydata,
                link: [
                    {
                        other: {
                            reference: `RelatedPerson/${linkid}`,
                        },
                        type: 'refer',
                    },
                ],
            };

            await axios.put(`${URL.cliniapi}Patient/${primaryId}`, secondarydata, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res, "ressss")

                setAlertMessage("Updated Successfully")
                setAlertstatus("success")
                dispatch(
                    getPatientdatafromibm(
                        'Patient',
                        primaryId,
                        token,
                    ),
                );
                // dispatch(switchProfile(primaryId, token))
                props.setShow(4)
            })
        } else {
            await axios.put(`${URL.cliniapi}Patient/${primaryId}`, d2, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            }).then(res => {
                console.log(res, "ressss")

                setAlertMessage("Updated Successfully")
                setAlertstatus("success")
                dispatch(
                    getPatientdatafromibm(
                        'Patient',
                        primaryId,
                        token,
                    ),
                );
                // dispatch(switchProfile(primaryId, token))
                props.setShow(4)
            })

                .catch(err => {
                    setAlertMessage(err.message);
                    setAlertstatus('error');

                })
                .finally(() => {
                    setLoading(false);
                })
            // }

        }
    }

    return (
        <div>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mt: { xs: "10px", sm: "10px", md: "20px" } }}>
                <IconButton onClick={() => props.setShow(4)}><ArrowBackIos sx={{ color: '#007DCD' }} /></IconButton>
                <Typography sx={{ fontSize: { md: "17px" }, fontWeight: 600, color: '#007DCD' }}>Edit Registration Profile</Typography>
            </Box>

            <Paper elevation={3} sx={{ mt: 1, borderRadius: 2, p: "10px 50px", mb: { xs: 7 }, display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '400px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 1.5 }}>
                        <div>
                            <input type="file" name="uploadfile" multiple id="post-img2" accept=".png, .jpg, .jpeg, .pdf" style={{ display: 'none' }}
                                onChange={(e) => imageHandler(e, 'personal')}
                            />
                            <label htmlFor="post-img2" className="cursor">
                                <div className="inputOption" style={{ display: "flex" }}>
                                    <Box sx={{ width: '80px', height: '70px', position: 'relative', display: 'flex', justifyContent: 'center', bgcolor: '#9560D9', borderRadius: "10px", }}>
                                        {image ?
                                            <img src={image} alt="media" style={{ objectFit: 'cover', width: '100%', height: '100%', cursor: 'pointer', borderRadius: "10px" }}
                                            /> : <Person sx={{ fontSize: "50px", cursor: 'pointer' }} />}
                                        {/* <div className='upload_text'>Upload File</div> */}
                                        <Typography sx={{
                                            position: 'absolute', color: 'white', top: 47, cursor: 'pointer',
                                            fontSize: '14px',
                                            backgroundColor: '#0005',
                                            color: '#fff',
                                            borderBottomRightRadius: '10px',
                                            borderBottomLeftRadius: '10px',
                                            bottom: 0,
                                            width: '80px',
                                            textAlign: 'center',
                                        }}>upload</Typography>
                                    </Box>
                                </div>
                            </label>
                        </div>
                    </Box>

                    <Box mt={2}>
                        <TextInputField width={{ sm: "400px", md: "400px" }} type="text" placeholder="First Name" name="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                    </Box>

                    <Box mt={2}>
                        <TextInputField width={{ sm: "400px", md: "400px" }} type="text" placeholder={'Middle Name'} value={middlename} onChange={(e) => setMiddlename(e.target.value)} />
                    </Box>

                    <Box mt={2}>
                        <TextInputField width={{ sm: "400px", md: "400px" }} type="text" placeholder={'Last Name'} value={lastname} onChange={(e) => setLastname(e.target.value)} />
                    </Box>

                    <Box mt={2}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker
                                    sx={{
                                        '.MuiOutlinedInput-input': {
                                            padding: '9px 14px',
                                            bgcolor: 'whitesmoke',
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderRadius: 2
                                        },
                                        width: '100%'
                                    }}
                                    value={dayjs(birthDate)}
                                    onChange={handleChangeDate}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Box>

                    <div>
                        <Typography sx={{ mt: 1, ml: 1 }} variant="subtitle1">
                            <span>Select Your Gender</span>
                        </Typography>

                        <Box display={"flex"} justifyContent="space-around" sx={{
                            border: "1px solid #BCBCBC", "&:hover": {
                                border: "1px solid black"
                            }, borderRadius: 2, p: "4px", backgroundColor: 'whitesmoke'
                        }}>
                            {genderdata.map((gen, i) => (
                                <Button sx={{
                                    height: "30px", textTransform: 'capitalize', backgroundColor: 'white', color: gender === gen ? "black" : "gray", '&:hover': {
                                        bgcolor: 'white',
                                    },
                                    bgcolor: gender === gen ? 'white' : 'whitesmoke'
                                }}
                                    key={i}
                                    variant={gender === gen ? 'contained' : 'text'}
                                    onClick={() => handleGender(gen)}
                                >
                                    {gen}
                                </Button>
                            ))}
                        </Box>
                    </div>

                    <Box mt={3} mb={5} container textAlign="center">
                        <Button type="submit" sx={{ borderRadius: 3, bgcolor: 'blueviolet', width: '150px', }} variant="contained" color="primary" onClick={handleSubmit} startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}>
                            {'Done'}
                        </Button>
                    </Box>
                </Box>
            </Paper>;


        </div >

    )
}

export default EditRegistration;