import React, { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import {
    Box, CircularProgress, Button,
    DialogContent,
    DialogTitle,
    Dialog,
    Slide, DialogActions, Divider, IconButton, MenuItem, Switch, TextField, Typography, Grid
} from '@mui/material';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import { useState } from 'react';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { getAbhaMobile } from '../../../../redux/Actions/Actions';
import { getmedicalrecords } from '../../../../redux/Actions/recordActions';
import { useNavigate } from 'react-router-dom';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { handleErrorResponse } from '../../../atoms/StatusError';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function UploadFiles({ open, setOpen, fileSize, fileType, recordfiles }) {
    const documentType = [
        { code: 'Prescription', text: 'Prescription' },
        { code: 'DiagnosticReport', text: 'Diagnostic Report' },
        { code: 'OPConsultation', text: 'OP Consultation' },
        { code: 'DischargeSummary', text: 'Discharge Summary' },
        { code: 'ImmunizationRecord', text: 'Immunization Record' },
        { code: 'HealthDocumentRecord', text: 'Health Document Record' },
        { code: 'WellnessRecord', text: 'Wellness Record' },
    ];

    const abhadata = useSelector((state) => state.abha.abhaprofile)
    // console.log(mobiledata, "moble")
    const [doctext, setDoctext] = React.useState('')
    const [formatedDate, setFormatedDate] = useState('');
    const [docName, setDocName] = useState('')
    const [loading, setLoading] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [alertopen, setAlertopen] = useState(false);
    const [linkAbha, setLinkAbha] = useState(false)
    const patientId = localstore.getPatientId()
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleClose = () => {
        setOpen(false);
    };

    const handleDocumentType = (e) => {
        setDoctext(e.code)
    }

    useEffect(() => {
        dispatch(getAbhaMobile(primaryId, token))
    }, [])

    const handleChangeDate = (newValue) => {
        setFormatedDate(newValue?.format("YYYY-MM-DD"))
        setAlertmessage('')
        setLoading('')
    }

    const recordSubmitHandler = async () => {
        setLoading(true)
        if (doctext && docName && formatedDate) {

            let data = recordfiles
            await axios
                .post(
                    `${URL.addRecords}/${patientId}/${primaryId}/${abhadata?.AbhaAddress}/${doctext}/${formatedDate}/${docName}`,
                    data,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                )
                .then(async response => {
                    if (response.status === 200) {
                        if (linkAbha === true && response.data !== undefined) {
                            let linkdata = {
                                'healthId': abhadata?.AbhaAddress,
                                'display': docName,
                            };

                            // console.log('responseupload', response);
                            await axios
                                .post(
                                    `${URL.consents}links/link/addContexts/${response?.data?.EncounterId}/${response?.data?.CompositionId}`,
                                    linkdata,
                                    {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                            gatewayToken: 'medxperts',
                                        },
                                    },
                                )
                                .then(response => {
                                    // console.log(response, '------------>>>Document Linking successfully');
                                    setAlertopen(true);
                                    setAlertmessage('Document uploaded successfully !!');
                                    setAlertstatus('success');
                                    dispatch(getmedicalrecords(token, primaryId, abhadata?.AbhaAddress, patientId));
                                    navigate('/records/medicalrecords')
                                })
                                .catch(error => {
                                    const { alertmessage, alertstatus } = handleErrorResponse(error)
                                    setAlertmessage(alertmessage);
                                    setAlertstatus(alertstatus);
                                });

                        } else {
                            // console.log(response, '------------>>>Document uploaded successfully');
                            setAlertopen(true);
                            setAlertmessage('Document uploaded successfully');
                            setAlertstatus('success');
                            dispatch(getmedicalrecords(token, primaryId, abhadata?.AbhaAddress, patientId));
                            navigate('/records/medicalrecords')
                        }
                    }
                })
                .catch(err => {
                    const { alertmessage, alertstatus } = handleErrorResponse(err)
                    setAlertmessage(alertmessage);
                    setAlertstatus(alertstatus);
                }).finally(() => {
                    setLoading(false)
                })
        }
    };

    const handleClosedone = () => {
        setOpen(false)
        setDoctext('')
        setDocName('')
        setFormatedDate('')
    }

    return (
        <div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth='sm'
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ display: 'flex', alignItems: 'center', p: "5px 20px", justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>
                        {'Upload Files'}
                    </Typography>
                    <IconButton sx={{ color: 'red' }} onClick={handleClosedone}>  <Close /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    {/* <Box sx={{ p: { xs: '0px 10px', md: "0px 30px" } }}> */}
                    <Grid container>
                        <Grid item xs={5} sm={3}>
                            <div>
                                {recordfiles.map((fileData, index) => (
                                    <div key={index}>
                                        <img src={`data:${fileData.fileType};base64,${fileData.file}`} alt={`Photo ${index + 1}`}
                                            style={{
                                                width: '100px',  // Default width for smaller screens
                                                height: 100
                                            }} />
                                    </div>
                                ))}
                            </div>
                        </Grid>
                        <Grid item xs={7} sm={8}>
                            <Typography sx={{ ml: 1, fontSize: { xs: 14, sm: '16px', md: '16px' } }}>
                                Document Name
                            </Typography>
                            <Box sx={{ mt: "5px" }}>
                                <TextInputField placeholder="Create File Name" value={docName} name='docName' onChange={(e) => setDocName(e.target.value)} />
                            </Box>
                            <Typography sx={{ mt: "5px", fontSize: '12px' }}>
                                {fileSize}MB/{fileType.toUpperCase()}
                            </Typography>
                            <Typography sx={{ mt: "3px", color: '#4caf50', fontSize: '13px' }}>
                                Document Selected Successfully
                            </Typography>
                        </Grid>
                    </Grid>
                    {/* </Box> */}

                    <Box sx={{ mt: "14px" }}>
                        <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 16 } }} >
                            Document Type
                        </Typography>
                        <Box >
                            <TextField
                                sx={{
                                    ".MuiInputBase-root": {
                                        borderRadius: 2,
                                        // backgroundColor: 'whitesmoke',
                                        mt: "5px",
                                    },
                                    // ".MuiOutlinedInput-notchedOutline": {
                                    //     border: "none"
                                    // }
                                }}
                                placeholder="Relation"
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setDoctext(e.target.value)}
                                value={doctext}
                                name={"stat"}
                                autoFocus
                                autoComplete='off'
                                select
                                SelectProps={{
                                    displayEmpty: true,
                                    renderValue: (selected) => (selected === '' ? 'Select Document Type *' : selected),
                                }}
                            >
                                <MenuItem value="">Select DocumentType</MenuItem>
                                {documentType.map((state, i) => (
                                    <MenuItem key={i} value={state.code} onClick={() => handleDocumentType(state.code)}>
                                        {state.text}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </Box>
                        <Typography sx={{ mt: "14px" }}>
                            Created Date
                        </Typography>

                        <Box>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer components={['DatePicker']}>
                                    <DatePicker sx={{

                                        '.MuiOutlinedInput-input': {

                                            padding: '9px 14px',
                                            bgcolor: 'whitesmoke',
                                        },
                                        ".MuiOutlinedInput-notchedOutline": {
                                            // border: "none",
                                            borderRadius: 2,
                                        },
                                        width: '100%'

                                    }}
                                        // label="Controlled picker"
                                        // maxDate={dayjs(new Date())}
                                        value={dayjs(formatedDate)}
                                        onChange={handleChangeDate}
                                        maxDate={dayjs()}

                                    />
                                </DemoContainer>
                            </LocalizationProvider>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                            <Typography>
                                Link Document with ABHA
                            </Typography>
                            <Switch onChange={() => setLinkAbha((prevLinkAbha) => !prevLinkAbha)} />
                        </Box>
                    </Box>


                </DialogContent>
                <Divider />
                <Box sx={{ textAlign: 'center' }}>
                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )}
                </Box>
                <DialogActions sx={{ justifyContent: 'center', m: 'auto' }} >
                    <Box sx={{ textAlign: 'center', mt: 1, mb: 1 }}>
                        <Button variant='contained' sx={{ width: '150px', borderRadius: 2 }} disabled={!docName || !doctext || !formatedDate || linkAbha === false} endIcon={loading ? <CircularProgress size={20} color='inherit' /> : null} onClick={recordSubmitHandler}>Submit</Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div >
    );
}
export default UploadFiles;