import React, { useEffect, useRef, useState } from 'react'
import hospitalIcon from '../../../../assets/home/bookapp_assets/hospital.svg'
import { Box, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Slide, Typography } from '@mui/material'
import { ArrowForwardIos, Close, SystemUpdateAlt } from '@mui/icons-material'
import CommonButton from '../../../atoms/CommonButton'
import { useDispatch, useSelector } from 'react-redux'
import URL from '../../../../utility/apiurl'
import { Recordlist } from '../../../../redux/Actions/recordActions'
import { localstore } from '../../../../localStore/localStore'
import axios from 'axios'
import { useTranslation } from 'react-i18next'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'
import PinInputField from '../../../atoms/TextFields/PinInputField'
import RecordsyncCard from './RecordsyncCard'
import { Date, DateFormat } from '../../../atoms/DateTimeFormat/DateFormat'
import { useLocation } from 'react-router-dom'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});


function SyncRecords() {
    // console.log(list, "list")s
    const location = useLocation()
    const list = location?.state?.linkedprovider
    let providerdata = useSelector(state => state.records.providerdata);
    let abhadata = useSelector(stat => stat.abha.abhaprofile);
    let RecordList = useSelector(stat => stat.records.Recordlist);
    const [t, i18n] = useTranslation("global");
    const [records, setRecords] = useState([]);
    const [newrecords, setNewRecords] = useState([]);
    const otpFieldsRef = useRef([]);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const [newrecordloading, setNewRecordLoading] = useState(false);
    const [syncrecords, setSyncRecords] = useState(true);
    const [newrecordsavaiable, setnewrecordsavaiable] = useState(false);
    const [newrecordresponse, setNewRecordResponse] = useState(false);
    const [organizationdata, setOrganizationData] = useState([]);

    const [pin, setPin] = useState(['', '', '', '']);
    const [pinnumber, setPinnumber] = useState(null)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()

    // console.log(newrecords, "newrecords")
    // console.log(syncrecords, "syncrecords")
    // console.log(newrecordresponse, "newrecordresponse")
    // console.log(newrecordsavaiable, "newrecordsavaiable")
    // console.log(organizationdata, "organizationdata")
    // console.log(records, "records")

    const handleInput = (index, value) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value.length === 1 && index < pin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertmessage('');
        } else if (value !== '') {
            setAlertmessage('Please enter 4 digits number only');
        }
    };

    const handleBackspace = (index) => {
        if (pin[index] !== '') {
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertmessage('');
    };

    useEffect(() => {
        if (list?.hip?.id && token.length > 0) {
            axios.get(`${URL.cliniapi}Organization?identifier=${list?.hip?.id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {
                    //console.log(res.data, 'RESPONSE-Organization');
                    dispatch(Recordlist(token, primaryId, res.data.entry[0].resource.id));
                    handlerecords(RecordList.entry);
                }).catch(err => {
                    console.log(err, 'Error-Organization');
                });
        }

    }, [list?.hip?.id, token, primaryId]);

    useEffect(() => {
        // dispatch(OrganizationIdentifier(linkedprovider.hip.id, token));
        // handlerecordbundle(linkedprovider.hip.id, token);
        if (RecordList?.entry?.length > 0) {
            handlerecords(RecordList.entry);
        }
    }, [RecordList]);
    const handlerecords = (item) => {
        item?.map((value) => {
            axios
                .get(
                    `${URL.cliniapi}Composition/${value.resource.id}`,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                .then((res) => {
                    const isDuplicate = records.some((rec) => rec.id === res.data.id);
                    if (!isDuplicate) {
                        // Update state with unique records
                        setRecords(prev => [...prev, res.data]);
                    }
                })
                .catch((err) => {
                    console.log(err.message, 'lll');
                });
        });
    };



    const handleVerifyPin = async () => {
        // props.setShow(3)

        setLoading(true)
        let data = {
            pin: pin.join(''),
            scope: 'consentrequest.approve',
        }
        setPinnumber(data.pin)
        await axios.post(`${URL.consents}verifyPin/${primaryId}/${abhadata.AbhaAddress}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                setPinnumber(data.pin);
                handleApprove(res.data.temporaryToken, data.pin);

                setAlertmessage('PIN is valid!');
                setAlertstatus('success');
                handleClose()
            }).
            catch(error => {
                // console.error('Error validating OTP:', error);
                setAlertmessage('PIN Does Not Match!');
                setAlertstatus('error');
                // setAlertMessage('Oops, something went wrong!');
                // setAlertstatus('error');

            }).finally(() => {
                setLoading(false)
            })
    }

    const handleApprove = (item, constpin) => {
        let mappedData = {
            id: abhadata?.AbhaAddress,
            hip: list?.hip?.id,
            requesterName: abhadata?.Name,
            patientIbmId: primaryId,
            careContextRef: list?.careContexts.map(context => context.referenceNumber),
            consentPin: constpin,
        };
        setNewRecordLoading(true);
        setSyncRecords(false);
        if (item) {
            axios
                .post(`${URL.consents}consents/request/init/${primaryId}`, mappedData, {
                    headers: {
                        'gatewayToken': 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    setTimeout(() => {
                        handleNewRecords(res.data);
                    }, 75000);
                    setAlertmessage('Please Wait request is processing !');
                    setAlertstatus('success');
                    setLoading(false);
                })
                .catch(err => {
                    setAlertmessage(
                        'Request failed in Request Id Generate'
                    );

                    setAlertstatus('error');
                    //setRecordLoading(false)
                    setNewRecordLoading(false);
                    setSyncRecords(true);
                    setLoading(false);

                });
        }
    };

    const handleNewRecords = (requestId) => {
        if (requestId) {
            axios
                .get(`${URL.consents}getHealthInfo/${primaryId}/${requestId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(async res => {
                    setNewRecordResponse(res?.data);
                    if (res?.data?.resources) {
                        setNewRecords(res?.data?.resources?.composition || res?.data?.resources?.compositionData);
                        setNewRecordLoading(false);
                        setnewrecordsavaiable(true);
                        setAlertmessage('Please Wait request is processing !');
                        setAlertstatus('success');
                    } else if (Object.keys(res.data.resources).length === 0 || res?.data === '' || res?.data === null || res?.data === undefined) {
                        setAlertmessage('NO Records Found');
                        setNewRecordLoading(false);
                        setnewrecordsavaiable(false);
                        setSyncRecords(false);
                        setAlertstatus('success');
                    }

                })
                .catch(err => {
                    setAlertmessage(
                        'Request failed while fetching newrecords'
                    );
                    setAlertstatus('error');
                    setNewRecordLoading(false);
                    setSyncRecords(true);
                });
        }
    };
    const handleCancel = () => {
        setPin(['', '', '', ''])
        setAlertmessage('')
        handleClose()
    }
    const [open, setOpen] = useState(false)
    const handleOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <Box sx={{ mt: 2, p: "0px 15px" }}>
            {/* <Box sx={{
                display: 'flex', alignItems: "center",
                justifyContent: "space-around",
            }}> */}
            < Box
                sx={{
                    display: "flex",
                    gap: 3,
                    alignItems: "center",
                    // justifyContent: "center",
                }}
            >
                <Box>
                    {
                        list.orgPageImage ?
                            < img src={list.orgPageImage}
                                alt=" hospital" style={{ width: 40, height: 40, borderRadius: '5px' }} /> :
                            < img src={hospitalIcon}
                                alt=" hospital" style={{ width: 40, height: 40, background: '#D4F4F8', padding: 5, borderRadius: '5px' }} />
                    }
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 600 }}>
                        {" "}
                        {list?.hip?.name}
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                        {list?.location?.address ? list?.location?.address?.text : null}
                    </Typography>
                </Box>

            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '15px' }}>
                <Typography sx={{ fontSize: '14px' }}>Patient ID/MR NO :<Typography sx={{ fontWeight: 600, }} component={'span'}>{list?.referenceNumber}</Typography></Typography>
                <Typography sx={{ fontSize: '14px' }}>Registration on :<Typography sx={{ fontSize: '14px' }} component={'span'}> 12-March-2022 | 03:00 PM</Typography></Typography>
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '5px' }}>
                <Typography sx={{ fontSize: '14px' }}>Linked Care Context :</Typography>
                <Typography sx={{ fontSize: '14px' }}>Care Context Display Ref.No:</Typography>
            </Box>
            {newrecordsavaiable ?
                <Card sx={{ mt: '20px', p: "10px 20px", bgcolor: '#E8FAFC' }}>
                    <div>
                        {/* Adjust styles as needed for your layout */}
                        <RecordsyncCard records={newrecords} MRN={list?.referenceNumber} hipid={list?.hip?.id} token={token} primaryId={primaryId} />
                    </div></Card> :
                <Box>
                    <Card sx={{ mt: '20px', p: "10px 20px", bgcolor: '#E8FAFC' }}>

                        {syncrecords ?
                            <Box>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Box></Box>
                                    <Typography sx={{ fontSize: '14px' }}> {'Last Updated '}
                                        {DateFormat(organizationdata?.resource?.meta?.lastUpdated, 'DateTime')}</Typography>
                                </Box>
                                <Box sx={{ display: 'flex', alignItems: 'center', mt: "10px", justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>if you want to fetch new records from facility</Typography>
                                    <CommonButton text="Sync Records" bgcolor="#13509B" width="150px" height="30px" startIcon={<SystemUpdateAlt />} onClick={handleOpen} />
                                </Box>
                            </Box>
                            : null}
                        {
                            newrecordloading ?
                                <>
                                    <Typography sx={{ fontWeight: '14px', fontWeight: 600 }}>Please Wait while we are updating new Records</Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50px', mt: "10px" }}>
                                        <CircularProgress size={35} />
                                    </Box>
                                </>
                                : null
                        }
                        {
                            newrecordresponse?.resources && Object?.keys(newrecordresponse?.resources).length === 0 ?
                                <Box sx={{ textAlign: 'center' }}>
                                    <Typography sx={{ fontWeight: 600, fontSize: '14px' }}>No Records Availale</Typography>
                                </Box>
                                : null
                        }
                    </Card>
                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                </Box>
            }
            {/* <Card sx={{ mt: '20px', p: "10px 20px", bgcolor: '#E8FAFC' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box></Box>
                    <Typography sx={{ fontSize: '14px' }}>Last Updated 28-12-2023 | 09:51</Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', mt: "10px", justifyContent: 'space-between' }}>
                    <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>if you want to fetch new records from facility</Typography>
                    <CommonButton text="Sync Records" bgcolor="#13509B" width="150px" height="30px" startIcon={<SystemUpdateAlt />} onClick={handleOpen} />
                </Box>
            </Card> */}

            <Box sx={{ mt: '20px' }}>
                <Typography sx={{ fontWeight: '600' }}>Available Records</Typography>
            </Box>
            {/* <Close />
            </Box> */}

            <RecordsyncCard records={records} MRN={list?.referenceNumber} hipid={list?.hip?.id} token={token} primaryId={primaryId} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth="sm"
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '10px 20px' }}><Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Consent Pin</Typography><Close onClick={handleCancel} sx={{ color: 'red', cursor: 'pointer' }} /></DialogTitle>
                <Divider />
                <DialogContent>
                    <Box sx={{ p: '10px 40px' }}>

                        <Card sx={{ textAlign: "center", p: '10px 0px 30px 0px ', borderBottom: '2px solid gray' }}>
                            <Typography sx={{ fontWeight: 500, }}>
                                Enter Consent Pin
                            </Typography>
                            <Box mt={3}>
                                <div
                                    style={{
                                        display: "flex",
                                        gap: "20px",
                                        textAlign: "center",
                                        justifyContent: "center",
                                    }}
                                >
                                    {pin.map((value, index) => (
                                        <PinInputField
                                            type="text"
                                            maxLength="1"
                                            key={index}
                                            value={value}
                                            onChange={(e) => handleInput(index, e.target.value)}
                                            onKeyDown={(e) => {
                                                if (e.key === "Backspace") {
                                                    handleBackspace(index);
                                                }
                                            }}
                                            ref={(ref) => {
                                                otpFieldsRef.current[index] = ref;
                                            }}
                                        />
                                    ))}
                                </div>
                            </Box>

                        </Card>
                        {/* <Card sx={{ p: '0px 20px', cursor: 'pointer', display: 'flex', borderBottom: '2px solid gray', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Typography sx={{ fontWeight: 600 }}>
                                Biometric-Consent
                            </Typography>
                            <IconButton><ArrowForwardIos sx={{ color: 'black' }} /></IconButton>
                        </Card> */}
                        <Card sx={{
                            p: '0px 20px', cursor: 'pointer',
                            boxShadow: "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)", display: 'flex', borderBottom: '2px solid gray', alignItems: 'center', justifyContent: 'space-between'
                        }}>
                            <Typography sx={{ fontWeight: 600 }}>
                                Consent with Password
                            </Typography>
                            <IconButton> <ArrowForwardIos sx={{ color: 'black' }} /></IconButton>
                        </Card>

                        {/* <Typography sx={{ fontSize: '14px', color: '#0822B3', mt: 1, fontWeight: 600, '&:hover': { textDecoration: 'underline' }, textAlign: 'center' }}>Reset Consent Pin?</Typography> */}


                        {/* <Card sx={{ textAlign: 'center', p: '10px' }}>
                            <Typography> Enter Consent Pin</Typography>

                        </Card> */}
                        {alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />}
                    </Box>
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center' }}>
                    <Box sx={{ m: "10px 0px" }}>
                        <CommonButton bgcolor="#9560D9" width="200px" text="Confirm Pin" endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null} onClick={handleVerifyPin} />
                    </Box>
                </DialogActions>
            </Dialog>
            {/* <RecordsConsentPin open={open} handleClose={handleClose} list={list} /> */}
        </Box >
    )
}

export default SyncRecords