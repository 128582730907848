import * as React from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material'
import EditProfileCard from '../../../atoms/EditProfileCard';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useState } from 'react';
import EmailUpdate1 from './EmailUpdate1';
import { useEffect } from 'react';
import { Close } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function EmailUpdate({ mobiledata }) {
    const [open, setOpen] = useState(false);
    const [email, setEmail] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [error, setError] = useState(false);
    const [show, setShow] = useState(1)
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const [emailerrormsg, setEmailerrormsg] = useState('')

    const handleOnChangeMobile = (e) => {

        const inputValue = e.target.value || "";

        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

        if (!emailRegex.test(inputValue)) {
            setError('Please enter a valid email address');
        } else {
            setError('');
        }

        setEmail(inputValue);
        setAlertmessage('')
        setError('');
    }

    useEffect(() => {
        setEmail(mobiledata?.email)
    }, [mobiledata?.email])

    const handleValidations = () => {
        let isValid = true;

        if (!email) {
            setEmailerrormsg('Email Address is required');
        }
        if (email) {
            // console.log("if Email");/
            const EMAIL_REGEX = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
            if (EMAIL_REGEX.test(email)) {
                setEmailerrormsg('')
            }
            else (
                setEmailerrormsg(t("emailalert"))
            )
        }
        return isValid;

    }

    const handleSubmit = async () => {
        // setShow(2)
        handleValidations()
        const isValid = handleValidations();

        if (!isValid) {
            // If validations fail, do not proceed with sending the email
            return;
        }
        setLoading(true)
        const data = {
            "authMode": "EMAIL_OTP",
            "email": email
        }
        await axios.post(`${URL.consents}abha/profile/update-mobileEmail/init/${primaryId}`, data, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(res => {

            setSessionId(res?.data?.sessionId)
            setAlertmessage("sent otp successfully")
            setAlertstatus('success')
            setShow(2)
        }).catch((err) => {
            setAlertmessage("Try different email address or Internal server error")
            setAlertstatus('error')
        }).finally(() => {
            setLoading(false)
        })
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClosedone = () => {
        handleClose()
        setAlertmessage('')
    }
    return (
        <React.Fragment>
            <EditProfileCard title="Email Address" displaydata={mobiledata.email ? mobiledata.email : "N/A"} action="Change" onClick={handleClickOpen} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >
                {show === 1 ? (
                    <>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: { xs: '14px', sm: '18px', md: '18px' }, fontWeight: 600, textAlign: 'center' }}>Update New Email Address</Typography>
                            <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>
                            <Grid container sx={{ textAlign: 'center', mt: 2 }}>
                                <Grid xs={12}>
                                    <TextInputField width={{ sm: '350px', md: "350px" }} placeholder="Enter New Email Address" name="email" value={email} onChange={handleOnChangeMobile} />
                                </Grid>
                                {error && (
                                    <Typography sx={{ fontSize: '13px', color: 'red', mt: 1 }}>
                                        {error}
                                    </Typography>
                                )}
                            </Grid>
                        </DialogContent>
                        {emailerrormsg && (
                            <Typography
                                sx={{ color: "red", fontSize: { xs: 14, md: 16 } }}
                            >
                                {emailerrormsg}
                            </Typography>
                        )}
                        {alertmessage && (
                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                        )}
                        <DialogActions sx={{ justifyContent: 'center', m: 0 }}>
                            <Button variant='contained' disabled={!email} sx={{ width: '120px', borderRadius: '10px' }} onClick={handleSubmit}>Submit</Button>
                        </DialogActions>
                    </>
                ) : show === 2 ? (
                    <EmailUpdate1 handleClose={handleClose} setShow={setShow} primaryId={primaryId} token={token} sessionId={sessionId} />
                ) : null}
            </Dialog>
        </React.Fragment>
    );
}
export default EmailUpdate;