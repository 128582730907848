import React, { useEffect } from 'react'
import { Box, Button, Grid, Typography } from '@mui/material';
import livehealthicon from '../../../../assets-new/medxpedia-icons/livehealthicon.svg';
import doctorsicon from '../../../../assets-new/medxpedia-icons/doctor_stethascope.svg';
import medlearnicon from '../../../../assets-new/medxpedia-icons/medlearn.svg';
import offersicon from '../../../../assets-new/medxpedia-icons/offers.svg';
import { getDoctorsFollowCount, getOrgFollowCount } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { AddBox, KeyboardArrowDown } from '@mui/icons-material';
import { useState } from 'react';
import NewRecords from './NewRecords';
import RecordsMobileView from './RecordsResponsive';


const TabItem = ({ icon, label, selected, onClick, to }) => (

    <Box
        sx={{
            // display: 'flex',
            // alignItems: 'center',
            gap: 2,
            cursor: 'pointer',
            // backgroundColor: selected ? '#EFEFEF' : 'transparent',
            borderRadius: "5px",
            boxShadow: selected ? '0 4px 8px rgba(0, 0, 0, 0.1), 0 8px 16px rgba(0, 0, 0, 0.1)' : 'none',
            mt: 1, p: "5px 10px",
            // width: '200px'
        }}
        onClick={onClick}
    >
        {/* <img src={icon} alt="live" width={20} height={20} /> */}
        <Typography variant="h6" sx={{ color: '#725F61', fontWeight: 600, fontSize: "17px" }}>
            {label}
        </Typography>
    </Box>
);
function Records() {
    const navigate = useNavigate()
    const location = useLocation();
    const [selectedTab, setSelectedTab] = useState('records');
    // const isActive = ['/records/linkedproviders',];
    const primaryId = localstore.getPrimaryId()
    const handleTabClick = (tab) => {
        setSelectedTab(tab);
        navigate(`/records/${tab}`)
    };

    useEffect(() => {
        const pathSegments = location.pathname.split('/');
        const currentTab = pathSegments[pathSegments.length - 1];
        setSelectedTab(currentTab);
    }, [location.pathname]);

    return (
        <div>
            <RecordsMobileView />
            <Grid container>
                <Grid item md={0.2} sm={0.2} xs={0.2}></Grid>
                <Grid item md={2.5} sm={2.5} xs={2.5}>
                    <Box
                        sx={{
                            display: { xs: 'none', sm: 'none', md: 'flex' },
                            flexDirection: 'column',
                            gap: 1,
                            mt: { sm: '150px', md: "60px" },
                            position: 'fixed'
                        }}
                    >
                        <TabItem
                            icon={livehealthicon}
                            label="Medical Records"
                            selected={selectedTab === 'records' || location.pathname.includes('/medicalrecords') || location.pathname.includes('/fileviewer') || location.pathname.includes('/imgorpdf')}
                            onClick={() => handleTabClick("medicalrecords")}
                        />
                        <TabItem
                            icon={doctorsicon}
                            label="Linked Providers"
                            selected={selectedTab === 'linkedproviders' || location.pathname.includes('/syncrecords') || location.pathname.includes('/fullrecords')}
                            onClick={() => handleTabClick("linkedproviders")}

                        />
                        <TabItem
                            icon={doctorsicon}
                            label="New Records"
                            selected={selectedTab === 'newrecords'}
                            onClick={() => handleTabClick("newrecords")}

                        />

                    </Box>
                </Grid>
                <Grid item md={6.8} sm={12} xs={12} mt={{ xs: '0px', sm: '0px', md: "60px" }}>
                    {primaryId ?
                        <Outlet /> : <Typography textAlign={'center'}>No data available. Please log in to view the data.</Typography>}
                </Grid>
                {/* <Grid item md={3} mt={"50px"} >
                    <NewRecords />
                </Grid > */}
            </Grid >
        </div>
    )
}

export default Records