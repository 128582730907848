import React, { useEffect } from 'react'
import { Box, Button, Card, CircularProgress, IconButton, Typography } from '@mui/material'
import { AllInclusive, Close, SettingsApplications, VerifiedUser } from '@mui/icons-material';
import medxpertsIcon from '../../../../assets/home/medxpertspassion.svg'
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { useTranslation } from "react-i18next";
import { useState } from 'react';
import HealthLocker1 from './HealthLocker1';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { getHealthStatus } from '../../../../redux/Actions/consentActions';
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat';
import { useNavigate } from 'react-router-dom';
import ActiveHealthLocker from './ActiveHealthLocker';

function HealthLocker() {
    const [show, setShow] = useState(1)
    const [status, setStatus] = useState(null);
    const [requestdata, setRequestdata] = useState('');
    // console.log(requestdata, "req uestdata")
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const healthstatus = useSelector((state) => state.consent.healthstatus)
    // console.log(healthstatus, "healss")
    // console.log("abhadata", abhadata)
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()

    useEffect(() => {
        if (abhadata?.AbhaAddress && primaryId && token)
            dispatch(getHealthStatus(abhadata?.AbhaAddress, primaryId, token));
    }, [primaryId, token, abhadata?.AbhaAddress])


    const handleOnRequest = async () => {
        // setShow(2)
        // console.log("click")
        let Requestdata = {
            patientId: abhadata.AbhaAddress,
            lockerId: 'MEDXPERTS_LOCKER',
        };
        setLoading(true)
        await axios
            .post(`${URL.consents}health-locker/subscription-auth/v2/${primaryId}`, Requestdata, {
                headers: {
                    "Authorization": `Bearer ${token}`,
                    gatewayToken: 'medxperts',
                }
            })
            .then(async res => {
                console.log(res.data, 'post api data');
                await axios
                    .get(`${URL.consents}health-locker/request/new/${primaryId}/${abhadata.AbhaAddress}`, {
                        headers: {
                            // 'X-Token': abhatoken,
                            gatewayToken: 'medxperts',
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(res => {

                        console.log("res", res)
                        if (res.status === 200 || res.status === 201) {
                            // Assuming the API returns an array of requests, get the latest one
                            if (res.data.requests && res.data.requests.length >= 0) {
                                const latest = res.data.requests[res.data.requests.length - 1];
                                // setLatestRequest(latest);
                                setRequestdata(latest);
                                // setReqeuestData(res.data.requests[0]);
                            }
                            // console.log('------------consents Data', res);
                            setStatus(res.status);
                            setAlertopen(true);
                            setAlertmessage('Request is Successfull');
                            setAlertstatus('success');
                            setShow(2)
                        }
                    })
                    .catch(err => {
                        // console.log(err, 'error in get api');
                        setAlertopen(true);
                        setAlertmessage(err.message);
                        setAlertstatus('error');
                    });
            })
            .catch(err => {

                console.log(err, 'error in post api');
                setAlertopen(true);
                setAlertmessage(err.message);
                setAlertstatus('error');

            }).finally(() => {
                setLoading(false)
            })
    };

    const handleLocked = () => {
        setAlertmessage("Aleady Set Up Completed")
        setAlertstatus("error")
    }
    return (
        <div>
            <Box sx={{ mt: { md: 2, xs: 18, sm: 11 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 300px' }, pt: { xs: '10px', sm: "5px", md: '42px' } }}>
                {
                    healthstatus === 'OK' ?
                        <ActiveHealthLocker /> :
                        < Box >
                            {show === 1 ?
                                <Box>

                                    <Typography sx={{ fontWeight: 600, fontSize: "17px", mt: '7px' }}>{t("sthlhlr")}</Typography>
                                    <Card sx={{ p: 2, mt: 1 }}>
                                        <img
                                            src={medxpertsIcon}
                                            alt="medxperts"
                                            width={"180px"}
                                            height={"50px"}
                                        />

                                        <Typography
                                            sx={{
                                                color: "black",
                                                mt: 3,
                                                fontSize: { xs: 14, sm: 14, md: 14 },
                                            }}
                                            component="p"
                                        >
                                            {t("sthlhtxt")}
                                        </Typography>
                                        {alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />}
                                        <Box sx={{ display: "flex", justifyContent: "end" }}>

                                            <Button
                                                onClick={handleOnRequest}
                                                variant="contained"
                                                sx={{
                                                    bgcolor: "#054B76", '&:hover': { bgcolor: "#054B76" },
                                                    width: "150px",
                                                    borderRadius: "20px",
                                                    mt: 3,
                                                }}
                                                startIcon={
                                                    loading ? (
                                                        <CircularProgress size={24} color="inherit" />
                                                    ) : (
                                                        <AllInclusive />
                                                    )
                                                }
                                            >
                                                {t("rqst")}
                                            </Button>

                                        </Box>
                                    </Card>
                                </Box> : show === 2 ? (
                                    <HealthLocker1 setShow={setShow}
                                        requestdata={requestdata} />
                                ) : null
                            }
                        </Box>
                }
            </Box>
        </div >
    )
}

export default HealthLocker
