import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Box, Button, Card, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, FormControl, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowBackIos, ArrowForward, Close, Diversity1Outlined } from '@mui/icons-material';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';


function TokenVerifyLogin(props) {
    const auth = useSelector((state) => state.abha.authMethods)
    const authvalues = auth
    // console.log(auth, "autheeeelinking")
    const transactionId = useSelector((state) => state.abha.mobilelinktransactionId)
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const phrAddress = useSelector((state) => state.abha.phrAddress)
    console.log(transactionId, "result")
    const Email = result.Email
    const [mobileOtp, setMobileOtp] = useState('')
    const [emailOtp, setEmailOtp] = useState('')
    const [password, setPassword] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()


    const [selectedOption, setSelectedOption] = useState("PASSWORD");
    //  const [selectedOption, setSelectedOption] = useState('');
    useEffect(() => {
        // Call the API for the initial selected option ('PASSWORD')
        handleOptionChange({ target: { value: 'PASSWORD' } });
    }, []);
    const handleOptionChange = (e) => {
        const selectDropdown = e.target.value
        // console.log("eee", hello)
        setSelectedOption(e.target.value);
        let selectValue;

        let d = {
            authMethod: 'MOBILE_OTP',
            healthid: phrAddress,
        };
        let d1 = {
            authMethod: 'AADHAAR_OTP',
            healthid: phrAddress,
        };
        let d2 = {
            authMethod: 'EMAIL_OTP',
            phrAddress: phrAddress,
        };
        let d3 = {
            authMethod: 'PASSWORD',
            healthid: phrAddress,
        };

        let apiUrlSegment = '';

        if (selectDropdown === 'MOBILE_OTP') {
            selectValue = d;
            apiUrlSegment = 'auth/init';
        } else if (selectDropdown === 'AADHAAR_OTP') {
            selectValue = d1;
            apiUrlSegment = 'auth/init';
        } else if (selectDropdown === 'EMAIL_OTP') {
            selectValue = d2;
            apiUrlSegment = 'email/login/mobileEmail/auth-init';

        } else if (selectDropdown === 'PASSWORD') {
            selectValue = d3;
            apiUrlSegment = 'auth/init';
        }
        // setLoading(true);
        if (selectDropdown === 'EMAIL_OTP' || selectDropdown === 'MOBILE_OTP' || selectDropdown === 'AADHAAR_OTP' || selectDropdown === 'PASSWORD') {
            axios.post(`${URL.abha}${apiUrlSegment}/${primaryId}`, selectValue, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },
            }).then(res => {


                let transactionId;
                if (selectDropdown === 'MOBILE_OTP') {
                    transactionId = res.data.txnId;
                    setAlertMessage('OTP is sent Successfully');
                    setAlertstatus('success');
                } else if (selectDropdown === 'AADHAAR_OTP') {
                    transactionId = res.data.txnId;
                    setAlertMessage('OTP is sent Successfully');
                    setAlertstatus('success');
                } else if (selectDropdown === 'EMAIL_OTP') {
                    transactionId = res.data.transactionId;
                    setAlertMessage('OTP is sent Successfully');
                    setAlertstatus('success');
                } else if (selectDropdown === 'PASSWORD') {
                    transactionId = res.data.transactionId;
                    setAlertMessage('Please enter a user password');
                    setAlertstatus('success');
                }
                dispatch({
                    type: 'SELECTED_OPTIONS',
                    payload: transactionId
                })
            }).catch(err => {
                if (err) {
                    // console.log('------------>>> errordata', err);
                    // console.log(err.message);
                    setAlertMessage(err.message);
                    setAlertstatus('error');

                }
            }).finally(() => {
                setLoading(false);
            })
        }
        setEmailOtp('')
        setMobileOtp('')
        setPassword('')
        setAlertMessage('')
    };




    const handleLogin = async () => {
        setLoading(true)
        let selectedData;
        // setSelectedOption('');

        let d = {
            authMethod: 'MOBILE_OTP',
            otp: mobileOtp,
            txnId: transactionId,
        };
        let d1 = {
            authMethod: 'AADHAAR_OTP',
            otp: mobileOtp,
            txnId: transactionId,
        };
        let d2 = {
            auth_method: 'input',
            input: mobileOtp,
            transactionId: transactionId,
        };
        let d3 = {
            authMethod: 'PASSWORD',
            password: password,
            healthId: phrAddress,
            txnId: transactionId,
        };

        let apiUrlSegment = '';
        if (selectedOption === 'MOBILE_OTP') {
            selectedData = d;
            apiUrlSegment = `new/confirmOtp`;
        } else if (selectedOption === 'AADHAAR_OTP') {
            selectedData = d1;
            apiUrlSegment = `new/confirmOtp`;
        } else if (selectedOption === 'EMAIL_OTP') {
            selectedData = d2;
            apiUrlSegment = `email/login/phrAddress/verify`;
        } else if (selectedOption === 'PASSWORD') {
            selectedData = d3;
            apiUrlSegment = `new/confirmOtp`;
        } else {
            selectedData = 'defaultOption';
        }
        // console.log('---------selectedData', `${URL.abha}${apiUrlSegment}`, selectedData,);
        await axios
            .post(`${URL.abha}${apiUrlSegment}/${primaryId}`, selectedData, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(async res => {
                // console.log(res, "================>loginphr")
                dispatch(getAbhaProfile(primaryId, token,));
                setAlertMessage('OTP verified successfully!');
                setAlertstatus('success');

                let ibmp = ibm?.identifier
                    ? { ...ibm }
                    : { ...ibm, identifier: [] };
                let da;
                let obj = ibmp.identifier.some(e => {
                    return e?.type?.coding[0]?.code === 'ABHA';
                });
                if (obj) {
                    let substitute = ibm.identifier?.map((e, i) => {
                        if (e?.type?.coding[0]?.code === 'ABHA') {
                            return { ...e, value: phrAddress };
                        } else {
                            return e;
                        }
                    });
                    da = { ...ibmp, identifier: substitute };
                } else {
                    da = {
                        ...ibmp,
                        identifier: [
                            ...ibmp.identifier,
                            {
                                system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                value: phrAddress,
                                type: {
                                    coding: [
                                        {
                                            code: 'ABHA',
                                        },
                                    ],
                                },
                            },
                        ],
                    };
                }
                // console.log('---------ibmdataupdate', da);
                await axios
                    .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                        headers: {
                            Authorization: `Bearer ${token}`,

                        },
                    })
                    .then(res => {
                        setAlertMessage('Data registered Successfully!');
                        setAlertstatus('success');
                        dispatch(
                            getPatientdatafromibm(
                                'Patient',
                                primaryId,
                                token,
                            ),
                        );
                        // setSelectedOption('');
                        handleDone()
                        props.popoverclose()
                    })
                    .catch(err => {
                        console.log(err.message, 'error in update');
                    });
            })
            .catch(err => {
                if (err) {
                    console.log('----------Error2', err);
                    setAlertMessage('Oops something went wrong or Otp is invalid!');
                    setAlertstatus('error');
                }
            }).finally(() => {
                setLoading(false)
            })
    };
    const handleDone = () => {
        props.handleClose()
        props.setShow(1)
    }

    const handleMobileOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setMobileOtp(inputValue.slice(0, 6));
        setAlertMessage('')
    }
    const handleEmailOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setEmailOtp(inputValue.slice(0, 6));
        setAlertMessage('')
    }


    return (
        <Box>
            <DialogTitle sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, p: '5px 20px' }}>
                <Typography sx={{ fontWeight: 500, fontSize: { xs: '17px', sm: '17px', md: "18px" }, }}>Select Verification methods</Typography>
                <IconButton onClick={() => props.popoverclose()}><Close sx={{ color: 'red' }} /></IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>

                <Box sx={{ p: { xs: 1, sm: 3, md: 3 }, }}>
                    <Box alignItems={"center"}>
                        <Typography sx={{ fontWeight: 400 }}>PhrAddress:<Typography component={"span"} sx={{ fontSize: { sm: 18, md: 20 }, fontWeight: 500 }}>{phrAddress}</Typography></Typography>

                        <Grid container textAlign={'center'}>
                            <Grid item xs={12}>


                                <Box mt={2}>
                                    <FormControl variant="outlined" fullWidth size='small'>
                                        <Select
                                            value={selectedOption}
                                            onChange={handleOptionChange}
                                        >
                                            {authvalues?.map((option) => (
                                                <MenuItem key={option} value={option}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Box>
                            </Grid>
                            {selectedOption === "MOBILE_OTP" || selectedOption === "EMAIL_OTP" || selectedOption === "AADHAAR_OTP" ? (
                                <Grid item xs={12} mt={2}>
                                    {/* <TextInputField name="mobileOtp" placeholder="Enter Mobile OTP" value={mobileOtp} onChange={handleMobileOtp} /> */}
                                    <TextField
                                        sx={{
                                            ".MuiInputBase-root": {
                                                borderRadius: 2,

                                            }, ".MuiOutlinedInput-notchedOutline": {
                                                // border: props.border
                                            },
                                            // width: { sm: '350px', md: "350px" }

                                        }}
                                        placeholder={"Enter  OTP"}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={handleMobileOtp}
                                        value={mobileOtp}
                                        autoFocus
                                        autoComplete='off'
                                        type={"text"}

                                    />
                                </Grid>
                            ) : null}
                            {/* {selectedOption === "email" ? (
                    <Box mt={2}>
                        <TextInputField name="emailOtp" placeholder="Enter Email OTP" value={emailOtp} onChange={handleEmailOtp} />
                    </Box>
                ) : null} */}
                            {selectedOption === "PASSWORD" ? (
                                <Grid item xs={12} mt={2}>
                                    <TextField
                                        sx={{
                                            ".MuiInputBase-root": {
                                                borderRadius: 2,

                                            }, ".MuiOutlinedInput-notchedOutline": {
                                                // border: props.border
                                            },
                                            // width: { sm: "350px", md: '350px' }

                                        }}
                                        placeholder={"Enter Password"}
                                        size="small"
                                        fullWidth
                                        variant="outlined"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                        autoFocus
                                        autoComplete='off'
                                        type={"password"}

                                    />
                                </Grid >
                            ) : null}

                            {alertmessage && (
                                <Typography sx={{ color: alertstatus === 'success' ? 'green' : 'red', mt: 1 }}>{alertmessage}</Typography>
                            )}

                        </Grid>
                    </Box>

                </Box >
            </DialogContent >
            <Divider />
            <DialogActions sx={{ justifyContent: 'center', m: '10px 0px' }}>
                <Box sx={{ textAlign: 'center' }} >
                    <Button variant='contained' sx={{ width: "120px", borderRadius: 2 }} onClick={handleLogin} disabled={loading}
                        endIcon={loading ? <CircularProgress size={20} color="inherit" /> : <ArrowForward sx={{ fontWeight: 'bold' }} />}
                    >
                        Login
                    </Button>
                </Box>
            </DialogActions>
        </Box >

    )
}

export default TokenVerifyLogin