import React, { useEffect, useState } from 'react'
import { Box, Card, CircularProgress, IconButton, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { getDoctorsFollowCount } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import MoreSuggestDoctors from './MoreSuggestDoctors';
import { ArrowBack } from '@mui/icons-material';
import InfiniteScroll from 'react-infinite-scroll-component';

function MoreDoctors() {
    const [suggestDoc, setSuggestDoc] = useState([]);
    const navigate = useNavigate()
    const [scrollNum, setScrollNum] = useState(0);
    const [totalNumCard, setTotalNumCard] = useState(0);
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    let doctorscount = useSelector(state => state.medx.doctorsCount);
    // console.log(suggestDoc, "sugggggggggg")
    // console.log(suggestOrg, "suOOOOOOOOOOO")
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(1)
    const [offset, setOffset] = useState(0);
    const [offsetOrg, setOffsetOrg] = useState(0);
    const [total, setTotal] = useState('');
    const [refreshing, setRefreshing] = useState(false);
    const patientId = localstore.getPatientId()
    const token = localstore.getToken()
    const limit = 90;
    useEffect(() => {

        fetchData()

    }, []);

    const fetchData = () => {

        axios.get(`${URL.suggestedDoctors}${limit}/${offset}/${patientId}`).then((res) => {
            console.log(res.data, "suggestdoctors")
            setSuggestDoc(suggestDoc.concat(res?.data?.data))
            setScrollNum(pre => pre + limit)
            setTotalNumCard(res.data.TotalCount)
            setIsLoading(true)
        })
            .catch((error) => console.log(error, 'EERRRRRRROOOOORRRRRR'));
    }





    const handleDocFavourite = (index) => {
        // console.log(index, "inddddddddddd")
        let data = suggestDoc.filter((e, i) => {
            if (i !== index) {
                return (e);
            }
        });
        setSuggestDoc(data);
    }




    return (
        <Box sx={{ p: 1, mt: "20px" }}>

            <Card sx={{ p: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                        <ArrowBack onClick={() => navigate(-1)} sx={{ cursor: "pointer" }} />
                    </IconButton>
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>
                        All Suggested Doctors
                    </Typography>
                </Box>
                {!isLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress sx={{ color: "#CDF4F9" }} />
                    </Box>
                ) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', p: 1 }}>
                        {suggestDoc?.map((each, ind) => (
                            <MoreSuggestDoctors key={ind} user={each}
                                index={ind} handleDocFavourite={() => handleDocFavourite(ind)} />
                        ))}
                    </Box>
                )}
            </Card>

            {isLoading ? ( // Render CircularProgress if isLoading is true
                <CircularProgress sx={{ display: 'block', mx: 'auto', mt: 3 }} />
            ) : (
                <InfiniteScroll
                    dataLength={suggestDoc?.length}
                    next={fetchData}
                    hasMore={suggestDoc.length < totalNumCard} // Adjust this condition based on your logic
                    loader={<h4>Loading...</h4>}
                />
            )}
        </Box>
    )
}

export default MoreDoctors