import React, { useState } from 'react'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { Box, Menu, MenuItem, Button, Card, CardContent, CardHeader, CardMedia, Collapse, Divider, Grid, IconButton, Typography, CardActions } from '@mui/material';
import LikeIcon from '../../../../../assets/home/feed_assets/like-icon.svg'
import LikedIcon from '../../../../../assets/home/feed_assets/liked-icon.svg'
import ShareIcon from '../../../../../assets/home/feed_assets/share-icon.svg'
import CommentIcon from '../../../../../assets/home/feed_assets/comment-icon.svg'
import ShareCopyIcon from '../../../../../assets/home/feed_assets/open_external.svg'
import URL from '../../../../../utility/apiurl'
import { localstore } from '../../../../../localStore/localStore';
import { getpostbyid } from '../../../../../redux/Actions/Actions';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Postactions({ item }) {
    const [t, i18n] = useTranslation("global");
    const [anchorEl, setAnchorEl] = useState(null);
    const [like, setLike] = useState(false)
    const [commentOpen, setCommentOpen] = useState(false)
    const token = localstore.getToken()
    const patientId = localstore.getPatientId()
    const dispatch = useDispatch()
    const [share, setShare] = useState(false)
    const open = Boolean(anchorEl);
    const shareUrl = `${'http:/localhost:3500/'}post/${item.id}`;
    const navigate = useNavigate()
    const handleShare = (e) => {
        console.log('hi share')
        setShare(!share)

        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };


    const handleLike = () => {
        setLike(true);
        let data = {
            userData: item.userData || item.pageData,
            date: new Date().toISOString(),
            likedBy: patientId,
            metadata: {
                author: patientId,
                dateTime: new Date().toISOString(),
                location: '',
                versionId: 0,
                securityCodes: '',
            },
            postId: item.id,
        };

        axios
            .post(`${URL.addLikes}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                // console.log(res, "resssssssss")
                dispatch(getpostbyid(item.id, token));
            })
            .catch(err => {
                console.log(err.message, 'handleLike');
            });
    };

    const handleUnlike = () => {
        setLike(false);
        axios
            .delete(`${URL.deleteLikes}${item.likeId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                dispatch(getpostbyid(item.id, token));
            })
            .catch(err => {
                console.log(err.message, 'handleUnlike');
            });
    };




    const handleCommentClick = () => {
        navigate('/commentsmain', { state: { data: item } });
        // dispatch(getpostbyid(item.id, token));
        // setCommentOpen(!commentOpen)
    }
    const handlelinkcopy = () => {
        navigator.clipboard.writeText(`${'http:/localhost:3500/'}post/${item.id}`)
        // console.log(url, "yessss")
    }
    return (
        <div>
            <Box
                sx={{
                    display: "flex",
                    pl: 1,
                    pr: 1,
                    mt: 1,
                    justifyContent: "space-between",
                }}
            >
                {item.likeCount > 0 ? (
                    <Typography
                        sx={{
                            fontSize: { xs: "7px", sm: "11px", md: "11px" },
                            margin: "5px",
                        }}
                    >
                        {item.likeCount}{" "}
                        {item.likeCount === 1
                            ? t("actions.person")
                            : t("actions.people")}{" "}
                        {t("actions.liked")}
                    </Typography>
                ) : null}
                {item.shareCount > 0 ? (
                    <Typography
                        sx={{
                            fontSize: { xs: "7px", sm: "11px", md: "11px" },
                            margin: "5px",
                        }}
                    >
                        {item.shareCount}{" "}
                        {item.shareCount === 1
                            ? t("actions.person")
                            : t("actions.people")}{" "}
                        Answered
                    </Typography>
                ) : null}
            </Box>

            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', p: "2px 20px" }}>

                <Box>
                    {item.liked || like === true ? (
                        <IconButton aria-label="unlike" onClick={handleUnlike}>
                            <img src={LikedIcon} alt="unlike" />
                        </IconButton>
                    ) : (<IconButton aria-label="like" onClick={handleLike}>
                        <img src={LikeIcon} alt="like" />
                    </IconButton>)}
                    <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={
                        item.liked || like === true ? handleUnlike : handleLike
                    }> Like</Typography>
                </Box>
                <Box onClick={handleCommentClick}>
                    <IconButton aria-label="comment" >
                        <img src={CommentIcon} alt="comment" />
                    </IconButton>
                    <Typography variant="caption" sx={{ cursor: 'pointer' }}>Ask Doctor</Typography>
                </Box>
                <Box onClick={handleShare}>
                    <IconButton aria-label="share" >
                        <img src={ShareIcon} alt="share" />
                    </IconButton>
                    <Typography variant="caption" sx={{ cursor: 'pointer' }}>Share</Typography>
                </Box>
                <Menu
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    keepMounted
                >
                    <MenuItem>
                        <FacebookShareButton
                            url={shareUrl}
                            quote={"Title or jo bhi aapko likhna ho"}
                            hashtag={"#portfolio..."}
                        >
                            <FacebookIcon size={20} round="true" /> &nbsp;&nbsp;
                            {t("actions.facebook")}
                        </FacebookShareButton>
                    </MenuItem>
                    <MenuItem url={shareUrl}>
                        <TwitterShareButton url={shareUrl}>
                            <TwitterIcon size={20} round="true" /> &nbsp;&nbsp;
                            {t("actions.twitter")}
                        </TwitterShareButton>
                    </MenuItem>
                    <MenuItem url={shareUrl}>
                        <WhatsappShareButton
                            url={shareUrl}
                            quote={"Title or jo bhi aapko likhna ho"}
                            hashtag={"#portfolio..."}
                        >
                            <WhatsappIcon size={20} round="true" /> &nbsp;&nbsp;
                            {t("actions.whatsapp")}
                        </WhatsappShareButton>
                    </MenuItem>
                    <MenuItem onClick={handleClose}>
                        <span
                            onClick={handlelinkcopy}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                alignContent: "center",
                            }}
                        >
                            <img src={ShareCopyIcon} />
                            &nbsp;&nbsp;{t("actions.copylink")}
                        </span>
                    </MenuItem>
                </Menu>
            </Box>
        </div>
    )
}

export default Postactions