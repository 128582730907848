
import React, { useRef } from 'react'
import { Security } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Slide, Typography } from '@mui/material'
import { useState } from 'react';
import { useEffect } from 'react';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import ForgotConsentPin from './FortgotPin';
import NewResetPin from './NewResetPin';
import PinInputField from '../../../atoms/TextFields/PinInputField';
import ChangeConsentPin from './ChangeConsentPin';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function ResetConsentPage(props) {
    // console.log(props, "props")
    const { open, handleClose, mobiledata } = props
    const [show, setShow] = useState(1)
    const [data, setData] = useState('')
    const [ibmpdata, setIbmpdata] = useState()
    const [loader, setLoader] = useState(false);
    const [phdState, setPhdState] = useState(null);
    const [abhaid, setAbhaId] = useState()
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const [pin, setPin] = useState(['', '', '', '']);
    // console.log(pin, "otppp")
    const otpFieldsRef = useRef([]);
    const ibm = useSelector((state) => state.medx.ibmpdata)
    // console.log(pin, "12345678")
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()
    const phraddress = mobiledata?.id
    const token = localstore?.getToken()


    const handleInput = (index, value) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value.length === 1 && index < pin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertMessage('');
        } else if (value !== '') {
            setAlertMessage('Please enter 4 digits number only');
        }
    };

    const handleBackspace = (index) => {
        if (pin[index] !== '') {
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertMessage('')

    };

    const handleCancelConsent = async () => {
        setLoading(true)
        let data = {
            pin: pin.join(''), // Join the otpValues array into a single string
            scope: 'profile.changepin'
        }
        console.log(data, "data")
        await axios.post(`${URL.consents}verifyPin/${primaryId}/${phraddress}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
            .then(res => {
                console.log(res, "existing pin")
                setAlertMessage('Verified Pin Successfully!');
                setAlertstatus('success');
                dispatch({
                    type: "EXISTING_CONSENTPIN_TOKEN",
                    payload: res.data.temporaryToken
                })
                setShow(3)
            }).catch(err => {
                setAlertMessage('verified pin is Not matched');
                setAlertstatus('error');
            }).finally(() => {
                setLoading(false)
            })
        setPin(['', '', '', ''])
    }

    const handleForgotPin = async () => {
        await axios.post(`${URL.consents}forgotPin/generateOtp/${primaryId}/${phraddress}`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(res => {
                // console.log(res, "forgotpin")
                setAlertMessage('OTP sent to mobile number!');
                setAlertstatus('success');
                setShow(2)
                dispatch({
                    type: "FORGOTPIN_GENERATEOTP_DATA",
                    payload: res.data
                })
            }).catch(err => {
                console.log(err.message);
                setAlertMessage('Oops something went wrong!');
                setAlertstatus('error');
            }).finally(() => { setLoading(false); });
        // setShow(5)
    }

    const handleClosed = () => {
        setPin(['', '', '', ''])
        setAlertMessage('')
        handleClose()
    }
    return (
        <div>
            <>
                <Dialog
                    open={open}
                    fullWidth
                    maxWidth="sm"
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={handleClose}
                    aria-describedby="alert-dialog-slide-description"
                >

                    {show === 1 ? (<>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                                <Security />
                                <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Consent Security Reset PIN</Typography>
                            </Box>
                            <Button sx={{ height: "25px", color: '#B48FE4', borderRadius: "15px" }} variant='outlined' onClick={handleClosed}>Cancel</Button>
                        </DialogTitle>
                        <Divider />

                        <DialogContent >
                            <Card sx={{ textAlign: 'center', mt: 1, p: "40px 0px", }}>
                                <Typography sx={{ fontWeight: 500, fontSize: '17px' }}>Enter Existing PIN</Typography>
                                <Box mt={3}>
                                    <div style={{ display: 'flex', gap: '20px', textAlign: 'center', justifyContent: 'center' }}>
                                        {pin.map((value, index) => (

                                            <PinInputField
                                                type="text"
                                                maxLength="1"
                                                value={value}
                                                key={index}
                                                onChange={(e) => handleInput(index, e.target.value)}
                                                onKeyDown={(e) => {
                                                    if (e.key === 'Backspace') {
                                                        handleBackspace(index);
                                                    }
                                                }}
                                                ref={(ref) => {
                                                    otpFieldsRef.current[index] = ref;
                                                }} />
                                            // <input
                                            //     key={index}
                                            //     type="text"
                                            //     maxLength="1"
                                            //     style={{
                                            //         width: '30px',
                                            //         outline: 'none',
                                            //         borderTop: '0px',
                                            //         borderLeft: '0px',
                                            //         borderRight: '0px',
                                            //         textAlign: 'center',
                                            //     }}
                                            //     // className="otp-input"
                                            //     value={value}
                                            //     onChange={(e) => handleInput(index, e.target.value)}
                                            //     onKeyDown={(e) => {
                                            //         if (e.key === 'Backspace') {
                                            //             handleBackspace(index);
                                            //         }
                                            //     }}
                                            //     ref={(ref) => {
                                            //         otpFieldsRef.current[index] = ref;
                                            //     }}
                                            // />
                                        ))}
                                    </div>
                                </Box>
                                {alertmessage && (
                                    <Typography sx={{ color: alertstatus === 'success' ? 'green' : 'red', mt: 1, fontSize: '14px' }}>{alertmessage}</Typography>
                                )}

                            </Card>
                            <Typography sx={{
                                color: '#103CB2', ml: 1, mt: 2, cursor: 'pointer', "&:hover": {
                                    textDecoration: 'underline'
                                }
                            }} onClick={handleForgotPin}>{'Forgot PIN ?'} </Typography>

                        </DialogContent>
                        <Divider />
                        <DialogActions sx={{ justifyContent: 'center' }}>
                            <Box sx={{ textAlign: 'center' }}>
                                <Button sx={{ bgcolor: '#9560D9', width: '120px', borderRadius: "10px", mt: 1, mb: 1 }} onClick={handleCancelConsent} variant='contained' disabled={!pin.every(value => value.length === 1)}

                                    startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                                >Submit</Button>
                            </Box>
                        </DialogActions>
                    </>

                    ) : show === 2 ? (
                        <ForgotConsentPin setShow={setShow} phraddress={phraddress} handleClose={handleClose} setAlertMessage={setAlertMessage} />
                    ) : show === 3 ? (
                        <ChangeConsentPin handleClose={handleClose} setShow={setShow} setAlertMessage={setAlertMessage} />
                    ) : show === 4 ? (
                        < NewResetPin handleClose={handleClose} open={open} setShow={setShow} setAlertMessage={setAlertMessage} />
                    ) : null}

                </Dialog>
            </></div>
    )
}

export default ResetConsentPage