import React from 'react'
import { Box, Button, Divider, Grid, Paper, Typography } from '@mui/material'
import searchblue from '../../../../assets-new/services-icons/search-Icon-blue.svg'
import { SearchBarStart } from '../../../atoms/Searchbar'
import { OutlinedButton } from '../../../atoms/CommonButton'
import { ArrowForwardIos, Cancel, LocalOffer } from '@mui/icons-material'
import hospitalIcon from '../../../../assets/home/bookapp_assets/hospital.svg'
import hdfcimg from '../../../../assets-new/hdfcergo.png'
function OffersTab() {
    return (
        <Box sx={{ p: { xs: 2, sm: 2, md: 1 }, mt: { xs: 0, sm: '10px', md: "20px" }, ml: { xs: 0, sm: 0, md: '50px' } }}>
            {/* <Typography sx={{ display: 'flex', gap: 1, alignItems: 'center' }}> <LocalOffer />Offers</Typography> */}
            <Box sx={{ textAlign: 'center', mt: "15px" }}>
                <Grid container>
                    <Grid item xs={12} >
                        <SearchBarStart icon={searchblue} width={{ sm: "500px", md: '600px' }} placeholder="Search Lab tests,surgeries,devices" />
                    </Grid>
                </Grid>
            </Box>
            <Typography sx={{ mt: 2 }}>Trending Topics</Typography>
            <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <OutlinedButton fontSize={{ xs: '12px', sm: '14px', md: '14px' }} text='Thyroid Function Test' borderColor="#EBCCFB" color="#000EBC" />
                <OutlinedButton fontSize={{ xs: '12px', sm: '14px', md: '14px' }} text='Diabetes' borderColor="#EBCCFB" color="#000EBC" />
                <OutlinedButton fontSize={{ xs: '12px', sm: '14px', md: '14px' }} text='HbA1C' borderColor="#EBCCFB" color="#000EBC" />
                <OutlinedButton fontSize={{ xs: '12px', sm: '14px', md: '14px' }} text='Blood Test' borderColor="#EBCCFB" color="#000EBC" />
            </Box>

            <Paper sx={{ mt: 2, p: "12px 25px" }}>
                <Typography sx={{ fontWeight: 600 }}>Offers Around You</Typography>

                <Grid container columnSpacing={3} mt={"15px"}>
                    <Grid item xs={6} sm={6} md={6}>
                        <Paper elevation={3} sx={{ borderRadius: '10px 10px 0px 0px' }}>
                            <Box sx={{ p: "10px 20px", height: { xs: 400, sm: 280, md: '280px' } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#205072' }}><LocalOffer sx={{ color: '#205072' }} />30% Off</Typography>
                                    <Cancel />
                                </Box>
                                <Box sx={{ textAlign: 'center', mt: 3 }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: { xs: '14px', sm: '16px', md: '16px' } }}>Diabetes Awareness Week</Typography>
                                    <Typography sx={{ mt: 2, fontSize: { xs: '12px', sm: '14px', md: '14px' } }}>Free Consultation, RBS, HBA1C, Creatinine, eGFR,& 15+ test</Typography>
                                    <Typography sx={{ mt: { xs: 1, sm: 3, md: 3 }, fontSize: { xs: '13px', sm: 15, md: 15 }, fontWeight: 500, display: 'flex', alignItems: 'center', gap: 1 }}>
                                        <img src={hospitalIcon} alt="hospitalicon" />
                                        Apollo Super Speciality Hospitals</Typography>
                                    <Typography sx={{ mt: 2, fontSize: { xs: 11, sm: 13, md: 13 }, fontWeight: 400 }}>Area, Sub-district, Disctrict, City, State- Pin Code</Typography>
                                </Box>
                            </Box >
                            <Button fullWidth sx={{ borderRadius: 0, mt: 4 }} variant='contained'>Check Eligibility</Button>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={6} md={6}>
                        <Paper elevation={3} sx={{ borderRadius: '10px 10px 0px 0px', }}>
                            <Box sx={{ p: "10px 20px", height: { xs: 400, sm: 280, md: '280px' } }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '#205072' }}><LocalOffer sx={{ color: '#205072' }} />Free</Typography>
                                    <Cancel />
                                </Box>
                                <Box sx={{ textAlign: 'center', mt: 3 }}>
                                    <Typography sx={{ fontWeight: 500, fontSize: { xs: '14px', sm: '16px', md: '16px' } }}>Diabetes Checkup Camp</Typography>
                                    <Typography sx={{ mt: 2, fontSize: { xs: '12px', sm: '14px', md: '14px' } }}>Free Consultation, RBS, HBA1C, pre& post prandial blood sugar</Typography>
                                    <Typography sx={{ mt: { xs: 1, sm: 3, md: 3 }, fontSize: { xs: '13px', sm: 15, md: 15 }, fontWeight: 500, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 1 }}>
                                        <img src={hospitalIcon} alt="hospitalicon" />
                                        Yashoda  Hospitals</Typography>
                                    <Typography sx={{ mt: 2, fontSize: { xs: 11, sm: 13, md: 13 }, fontWeight: 400 }}>Area, Sub-district, Disctrict, City, State- Pin Code</Typography>
                                </Box>
                            </Box >
                            <Button fullWidth sx={{ borderRadius: 0, mt: 4 }} variant='contained'>Check Eligibility</Button>
                        </Paper>
                    </Grid>
                </Grid>
            </Paper>
            <Box sx={{ mt: 3, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Box>
                    <Typography sx={{ fontWeight: 500 }}>Universal Health Schemes (Government)</Typography>
                    <Typography sx={{ fontSize: '14px', mt: "5px" }}>Find Government schemes that cover your treatment, check eligibility & Register for benefits</Typography>
                </Box>
                <ArrowForwardIos />
            </Box>
            <Box sx={{ mt: 3 }}>
                <Typography sx={{ fontWeight: 600 }}>Health Insurance & Coverage</Typography>
                <Paper elevation={2} sx={{ opacity: 0.9 }} >
                    <Grid container columnSpacing={3} sx={{ p: "10px 20px", mt: 2 }}>
                        <Grid item xs={6} sm={6} md={6} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <img src={hdfcimg} alt="hdfc" height={50} />
                            <Box>
                                <Typography sx={{ fontSize: 14 }}>HDFC ERGO</Typography>
                                <Typography sx={{ fontWeight: 600, fontSize: 14 }}>Optima Secure</Typography>
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                            <Box>
                                <Typography sx={{ fontSize: 14 }}>Claim Success</Typography>
                                <Typography sx={{ fontWeight: 600, textAlign: 'center' }}>98%</Typography>
                            </Box>

                            <OutlinedButton text="View Features" fontSize={{ xs: "10px", sm: '14px', md: '14px' }} width={{ xs: "100px", sm: '150px', md: '150px' }} borderColor="#3C98E8" />
                        </Grid>
                    </Grid>

                    <Divider />

                    <Grid container sx={{ p: "10px 20px" }}>
                        <Grid item md={4}>
                            <Typography>Cover 5L</Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography>Cashless Facilities 400+</Typography>
                        </Grid>
                        <Grid item md={4}>
                            <Typography>Starting From 500/month</Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Box sx={{ p: '0px 50px', }}>
                    <Grid container sx={{ borderRight: '2px solid #FF0000', borderLeft: '2px solid #FF0000', borderBottom: '2px solid #FF0000' }}>
                        <Grid item md={6} sx={{ bgcolor: '#FF0000' }}>
                            <Typography sx={{ p: '7px', color: 'white', fontWeight: 600 }}>Offer:28% Discount</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Typography sx={{ p: '7px', color: '#FF0000', textAlign: 'center', fontWeight: 600 }}>  Check Eligibility</Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Box >
    )
}

export default OffersTab