import React, { useState } from 'react'
import { ArrowBackIos, Close } from '@mui/icons-material'
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Typography } from '@mui/material'
import { useHistory, useLocation, useNavigate } from 'react-router-dom'
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { localstore } from '../../../../localStore/localStore'
import URL, { OtpPublicKey } from '../../../../utility/apiurl'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import { useTranslation } from "react-i18next";
import { t } from 'i18next'
import LoginSelectPhr from './LoginSelectPhr'
import ResendOtp, { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp'
import { handleErrorResponse } from '../../../atoms/StatusError'
import JSEncrypt from 'jsencrypt'



function MobileEmailStep1() {
    const location = useLocation()
    const selectData = location?.state?.selectData
    const [show, setShow] = useState(1)
    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(59);
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = OtpPublicKey;
    const navigate = useNavigate()
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    const transactionId = result.transactionId
    const mobileNumber = result.mobile

    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global");

    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }


    const handleVerify = async () => {
        // props.setShow(3)
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);

        const encryptOtp = encrypt.encrypt(otp);
        setLoading(true)
        let d = {
            "transactionId": transactionId,
            "authCode": "otp",
            "otp": encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
        };
        setLoading(true);
        console.log(d);
        await axios.post(`${URL.abha}login/mobileEmail/verify/otp/${primaryId}`, d, {
            headers: {
                'gatewayToken': 'medxperts',
                'Authorization': `Bearer ${token}`,
            },
        }).then(res => {
            // console.log(res);
            setAlertmessage('OTP Verified Successfully!');
            setAlertstatus('success');

            dispatch({
                type: "LOGINMOBILE_PHRADDRESS",
                payload: res.data
            })
            setShow(3)
        }).catch(err => {
            // console.log(err.message);
            if (err.response.status === 400) {
                setAlertmessage('OTP is invalid, Please enter the correct OTP.')
                setAlertstatus('error')

            } else {
                const { alertmessage, alertstatus } = handleErrorResponse(err)
                setAlertmessage(alertmessage)
                setAlertstatus(alertstatus)
            }

        }).finally(() => {
            setLoading(false);
            setOtp('')
        });
    }
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time, result]);

    const handleResendOtp = async () => {
        setLoading(true)
        setTime(59);
        await axios.post(`${URL.abha}login/mobileEmail/auth/${primaryId}`, selectData, {
            headers: {
                'gatewayToken': 'medxperts',
                'Authorization': `Bearer ${token}`,
            },
        }).then(res => {
            // console.log(res.data);
            setAlertmessage('OTP sent Successfully!');
            setAlertstatus('success');
            let details = {
                transactionId: res.data.transactionId,
                mobile: mobileNumber,
            }
            dispatch({
                type: "LOGINMOBILE_RESENDOTP_SESSIONID",
                payload: details
            })

        }).catch(err => {
            // console.log(err.message);
            const { alertmessage, alertstatus } = handleErrorResponse(err);
            setAlertmessage(alertmessage);
            setAlertstatus(alertstatus);
        }).finally(() => { setLoading(false); });

    }

    let formattedMobileNumber = '';
    if (typeof mobileNumber === 'string') {
        formattedMobileNumber = mobileNumber.slice(-4);
    }


    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '0px' }, mb: { xs: 10, sm: 10, md: 5 } }}>
            {show === 1 ? (
                <>
                    <div>
                        {" "}
                        <Box sx={{ mt: { xs: "5px", sm: "10px", md: 1 } }}>
                            <Box
                                sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: { xs: 1, sm: 2, md: 2 },
                                }}
                            >
                                <IconButton
                                    onClick={() => navigate("/loginabha")}
                                >
                                    <ArrowBackIos
                                        sx={{ color: "#007DCD", cursor: "pointer" }}
                                    />
                                </IconButton>
                                <Typography
                                    sx={{
                                        fontSize: { xs: 15, sm: "16px", md: "17px" },
                                        color: "#237DCD",
                                        fontWeight: 600,
                                    }}
                                >
                                    {/* {t("changelogindetails")} */}
                                    Change Mobile Number
                                </Typography>
                            </Box>
                        </Box>
                        <Card sx={{ p: 3, display: "flex", justifyContent: "center", mt: 1 }}>
                            <Box mt={1}>
                                <Typography
                                    sx={{
                                        fontSize: { xs: 14, md: 16 },
                                        color: "black",
                                        textAlign: "center",
                                    }}
                                >
                                    {t("verifyotp")}
                                </Typography>
                                <Box mt={2}>
                                    <TextInputField
                                        name="otp"
                                        width="350px"
                                        placeholder={t(
                                            "credentialsData.signupmobiledata.enterotp"
                                        )}
                                        value={otp}
                                        onChange={handleOnChangeOtp}
                                        border="none"
                                    />
                                </Box>
                                <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} />

                                {alertmessage && (
                                    <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                )}


                                <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                                    <Button
                                        variant="contained"
                                        sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                                        onClick={handleVerify}
                                        disabled={loading || otp?.length < 6}
                                        startIcon={
                                            loading ? (
                                                <CircularProgress size={20} color="inherit" />
                                            ) : null
                                        }
                                    >
                                        {t("credentialsData.signupmobiledata.submitbtn")}
                                    </Button>
                                    {/* <Typography
                                        sx={{
                                            fontStyle: "inherit",
                                            fontSize: { xs: 14, md: 14, },
                                            color: "black",
                                            mt: 2
                                        }}
                                    >
                                        Enter OTP sent on mobile +91 xxxx xx {formattedMobileNumber}
                                    </Typography> */}
                                </Box>
                            </Box>
                        </Card>
                    </div>
                </>
            ) : show === 3 ? (
                <LoginSelectPhr setShow={setShow} />
            ) : null}
        </Box>
    );
}

export default MobileEmailStep1