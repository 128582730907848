import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    IconButton,
    Slide,
    Typography,
} from "@mui/material";
import deletehealthIdIcon from "../../../../assets/home/healthprofile_assets/deletehealthid.svg";
import { Close } from "@mui/icons-material";
import URL from "../../../../utility/apiurl";
import { localstore } from "../../../../localStore/localStore";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import {
    getAbhaProfile,
    getPatientdatafromibm,
} from "../../../../redux/Actions/Actions";
import { useTranslation } from "react-i18next";
import { ErrorStatusMsg } from "../../../atoms/ResendOtp";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function DelinkHealthId({ open, setOpen }) {
    const [loading, setLoading] = useState(false);
    const [alertmessage, setAlertmessage] = useState("");
    const [alertstatus, setAlertstatus] = useState("");
    const [show, setShow] = useState(1)
    const primaryId = localstore.getPrimaryId();
    const csrftoken = localstore.getCsrfToken()
    const token = localstore.getToken();
    const ibm = useSelector((state) => state.medx.ibmpdata);
    const [t, i18n] = useTranslation("global");
    const abhadata = useSelector(state => state.abha.abhaprofile)
    // console.log(ibm, "ibmdelink");
    const dispatch = useDispatch();

    const handleDelink = async () => {
        setLoading(true);
        const ibmp = { ...ibm };
        const ibmpdata = ibmp?.identifier;

        const updatedIdentifier = ibmpdata?.filter(item => {
            const coding = item?.type?.coding[0];
            return !(coding && coding?.code === "HIN");
        });

        ibmp.identifier = updatedIdentifier;

        // console.log(ibmp, "ibmpppp");
        await axios
            .post(
                `${URL.consents}abha/delinklink/hid/phrAddress/${primaryId}`,
                {},
                {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                        'gatewayToken': "medxperts",
                    },
                }
            )
            .then((res) => {
                if (res.status === 200) {
                    setAlertmessage("ABHA number is unlinked to existing ABHA address. Now, ABHA number is not visible in your profile ");
                    setAlertstatus("success");
                    const data = ibmp;
                    axios.put(`${URL.cliniapi}Patient/${primaryId}`, data, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    });

                    setAlertmessage("Data registered Successfully!");
                    setAlertstatus("success");
                    dispatch(getAbhaProfile(primaryId, token));
                    dispatch(getPatientdatafromibm("Patient", primaryId, token));
                    // setOpen(false);
                    // handleClose();
                    setShow(2)
                }

            }).catch(error => {
                console.error(error.message, "error in delink or update");
                setAlertmessage(
                    error.message
                );
                setAlertstatus("error");
            }).finally(() => {

                setLoading(false);
            })
    }

    const handleClose = () => {
        setOpen(false);
        setAlertmessage("");
        setLoading("");
        setShow(1)
    };
    return (
        <React.Fragment>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >
                {show === 1 ? (
                    <>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: '5px 20px' }}>
                            <Typography sx={{ fontSize: '17px', fontWeight: 600, textAlign: 'center' }}>
                                Delink HealthId
                            </Typography>
                            <IconButton onClick={handleClose}><Close sx={{ color: 'red' }} /></IconButton>
                        </DialogTitle>
                        <Divider />

                        <DialogContent >
                            <Typography component={'p'} sx={{ fontSize: '15px' }}>
                                Even after unlinking ABHA number, you will still be able to share your health records with healthcare providers through ABHA address.
                                <p>Do you still want to unlink ABHA number <Typography sx={{ fontWeight: 600 }} component={'span'}> {abhadata.AbhaNumber}</Typography> with your existing ABHA address?
                                </p>
                            </Typography>
                        </DialogContent>

                        <Divider />
                        {
                            alertmessage && (
                                <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                            )
                        }
                        <DialogActions >
                            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                                <div></div>
                                <Box sx={{ m: "5px 0px" }}>

                                    <Button
                                        sx={{
                                            bgcolor: "blueviolet",
                                            ":hover": {
                                                bgcolor: "blueviolet",
                                            },
                                            width: "90px",
                                            borderRadius: 2,
                                            mr: 2,
                                        }}
                                        startIcon={
                                            loading ? (
                                                <CircularProgress sx={{ color: "white" }} size={20} />
                                            ) : null
                                        }
                                        onClick={handleDelink}
                                        autoFocus
                                        variant="contained"
                                    >
                                        Yes
                                    </Button>
                                    <Button
                                        onClick={handleClose}
                                        variant="outlined"
                                        sx={{ color: "black", width: "90px", mr: 1, borderRadius: 2 }}
                                    >
                                        No
                                    </Button>
                                </Box>
                            </Box>
                        </DialogActions>
                    </>
                ) : show === 2 ? (
                    <>
                        <DialogContent sx={{ p: 3, textAlign: 'center' }} >

                            <Typography sx={{ fontWeight: 500 }}>
                                ABHA number is unlinked to existing ABHA address. Now, ABHA number is not visible in your profile
                            </Typography>
                            <Box sx={{ mt: 3 }}>
                                <Chip sx={{ width: '100px' }} label="Close" color="success" onClick={handleClose} />
                            </Box>
                        </DialogContent>
                    </>
                ) : null
                }
            </Dialog >
        </React.Fragment >
    );
}
export default DelinkHealthId