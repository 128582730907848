import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { MenuItem, TextField } from '@mui/material'
import { Close } from '@mui/icons-material';
import { getAbhaMobile, getDistricts, getPatientdatafromibm, getStates } from '../../../../redux/Actions/Actions';
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import EditProfileCard from '../../../atoms/EditProfileCard';
import { useTranslation } from "react-i18next";


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function NameUpdate({ mobiledata }) {
    console.log(mobiledata, "mobile")
    const [open, setOpen] = useState(false);
    const [firstname, setFirstname] = useState('')
    const [middlename, setMiddlename] = useState('')
    const [lastname, setLastname] = useState('')
    const [address, setAddress] = useState('')
    const [gender, setGender] = useState('Male');
    const genderdata = ['Male', 'Female', 'Other'];
    const [birthDate, setBirthDate] = useState('');
    const [pincode, setPincode] = useState('');
    const [stat, setStat] = useState('')
    const [district, setDistrict] = useState('')
    const [statecode, setStatecode] = useState('');
    const [districtcode, setDistrictcode] = useState('');
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [relation, setRelation] = useState('')
    const [t, i18n] = useTranslation("global");

    let states = useSelector(state => state.abha.states);
    // console.log(result, "states")
    let districts = useSelector(state => state.abha.districts);

    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()
    const nameParts = mobiledata?.fullName?.split(' ');
    // Extract first name and last name
    const firstName = nameParts?.[0];
    const lastName = nameParts?.slice(1).join(' '); // Join
    const dateOfBirth = mobiledata.dateOfBirth
    const day = dateOfBirth?.date;
    const month = dateOfBirth?.month;
    const year = dateOfBirth?.year;
    const formatedDate = `${year}-${month}-${day}`
    console.log(formatedDate, "formateddate")
    const dateObject = new Date(birthDate)
    console.log(dateObject, "dateee")
    const yearObject = dateObject.getFullYear()
    const monthObject = dateObject.getMonth() + 1
    const dayObject = dateObject.getDate()
    useEffect(() => {
        dispatch(getStates(token))
    }, []);
    useEffect(() => {
        setFirstname(firstName)
        setLastname(lastName)
        setAddress(mobiledata?.address)
        setPincode(mobiledata?.pincode)
        setStat(mobiledata?.stateName)
        setDistrict(mobiledata?.districtName)
        setStatecode(mobiledata?.stateCode)
        setDistrictcode(mobiledata?.districtCode)
        setBirthDate(formatedDate);
        if (mobiledata?.gender === "M") {
            setGender("Male");
        } else if (mobiledata?.gender === "F") {
            setGender("Female");
        } else if (mobiledata?.gender === "O") {
            setGender("Other");
        }
    }, [mobiledata, relation])

    const handleChangeDate = (newValue) => {
        setBirthDate(newValue?.format("YYYY-MM-DD"))
    }
    const handleGender = (selectedGender) => {
        setGender(selectedGender);
    }

    const handleState = (e) => {
        setStat(e.name);
        setStatecode(e.code);
        dispatch(getDistricts(e.code, token));
        setDistrict('');

    }
    const handleDistrict = (e) => {
        setDistrict(e.name);
        setDistrictcode(e.code);
    };
    const handleSubmit = async () => {

        setLoading(true)
        let genderValue;

        if (gender === "Male") {
            genderValue = "M";
        } else if (gender === "Female") {
            genderValue = "F";
        } else if (gender === "Other") {
            genderValue = "O";
        } else {
            genderValue = "";
        }
        const data = {
            "addressLine": address,
            "gender": genderValue,
            "dayOfBirth": dayObject,
            "monthOfBirth": monthObject,
            "yearOfBirth": yearObject,
            "districtCode": districtcode,
            "firstName": firstname,
            "lastName": lastname,
            "middleName": middlename,
            "pinCode": pincode,
            "stateCode": statecode
        }
        console.log(data, "dataaa")
        await axios.post(`${URL.consents}account/profile/updateAddress/${primaryId}`, data, {
            headers: { "Authorization": `Bearer ${token}`, "gatewayToken": "medxperts" }
        }).then(res => {

            setAlertmessage("updated successfully")
            setAlertstatus('success')
            dispatch(getAbhaMobile(primaryId, token))
            handleClosedone()
        }).catch(err => {
            setAlertmessage("try again some time")
            setAlertstatus("error")
        }).finally(() => {
            setLoading(false)
        })

    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClosedone = () => {
        handleClose()
        setAlertmessage('')


    }
    return (
        <React.Fragment>
            <EditProfileCard title="Display Name" displaydata={mobiledata.fullName ? mobiledata.fullName : 'N/A'} action="Update" onClick={handleClickOpen} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                    <Typography sx={{ fontSize: '18px', fontWeight: 600, textAlign: 'center' }}>Update New Name</Typography>
                    <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogContent>
                    <div>
                        <Grid container textAlign={'center'}>
                            <Grid item xs={12} >
                                <TextInputField type="text" width={{ sm: '400px', md: '400px' }} placeholder="First Name" name="firstname" value={firstname} onChange={(e) => setFirstname(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} mt={2}>
                                <TextInputField type="text" width={{ sm: '400px', md: '400px' }} placeholder={'Middle Name'} value={middlename} onChange={(e) => setMiddlename(e.target.value)} />
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <TextInputField type="text" width={{ sm: '400px', md: '400px' }} placeholder={'Last Name'} value={lastname} onChange={(e) => setLastname(e.target.value)} />
                            </Grid>
                        </Grid>
                    </div >
                </DialogContent>
                {alertmessage && (
                    <Typography
                        sx={{
                            color: alertstatus === "success" ? "green" : "red", fontSize: '14px', textAlign: 'center',
                            mt: 1,
                        }}
                    >
                        {alertmessage}
                    </Typography>
                )}
                <DialogActions sx={{
                    justifyContent: 'center', // Center the content horizontally
                    margin: 'auto',
                }}>
                    <Button variant='contained' startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} sx={{ width: '120px', borderRadius: '10px', left: 0 }} onClick={handleSubmit}>Submit</Button>
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
export default NameUpdate;