import React from 'react';
import { Box, Typography } from '@mui/material';
import moment from "moment";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

const Event = ({ docFeed }) => {
    return (
        <div style={{ padding: "10px" }}>
            <Typography sx={{ pl: "10px" }}>{docFeed?.description}</Typography>
            <Box
                sx={{
                    p: "10px",
                    borderRadius: "10px",
                    border: "1px solid gray",
                    marginTop: "10px",
                }}
            >
                <Box
                    style={{ padding: "10px", backgroundColor: "#e8e8e0" }}
                    className="newcontact-fieldheader"
                >
                    <div className="overflow_hidden">
                        <Typography
                            className="d-flex e-align color_head font-bold cursor capitalize"
                            sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                alignItems: "center",
                            }}
                        // onClick={() =>
                        //   data.link
                        //     ? console.log("")
                        //     : docFeed.id && navigate(`/webinar/eventdata-${data.id}`)
                        // }
                        >
                            {/* <img  width="20px" height={"20px"} /> */}
                            <CalendarMonthIcon />
                            &nbsp;
                            <span className="text-ellipsis">{docFeed.eventData.title}</span>
                            &nbsp;
                            <span className="text-ellipsis">
                                <Typography>{docFeed?.title}</Typography>
                            </span>
                        </Typography>
                        <Typography sx={{ mt: "2px" }}>
                            {moment(docFeed?.openingTime).format("DD/MM/YYYY")} -{" "}
                            {moment(docFeed?.eventData?.closingTime).format("DD/MM/YYYY")}
                        </Typography>
                    </div>
                </Box>
            </Box>
        </div>
    );
}

export default Event;