import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ArrowDropDown, ArrowDropUp, ArrowForward, Check, ExpandLess, ExpandMore, Pending, RemoveCircleOutline, Security, TaskAlt } from '@mui/icons-material';
import { Box, Card, Checkbox, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import consentIcon from '../../../../assets/home/consent_module/consent.svg'
import EditIcon from '../../../../assets/home/consent_module/edit.svg'
import hospitalIcon from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import prescriptionIcon from '../../../../assets/home/consent_module/prescription.svg';
import consultationIcon from '../../../../assets/home/consent_module/consultation.svg';
import diagnosticIcon from '../../../../assets/home/consent_module/diagnostic.svg';
import dischargeIcon from '../../../../assets/home/consent_module/discharge.svg';
import immunisationIcon from '../../../../assets/home/consent_module/immunisation.svg';
import documentIcon from '../../../../assets/home/consent_module/document.svg';
import WellnessRecordIcon from '../../../../assets/home/consent_module/lifestyle.svg';
import moment from 'moment';
import { useEffect } from 'react';
import { getLinkedProviders } from '../../../../redux/Actions/recordActions';
import ConsentRequestEdit from './RequestEdit';
import ConsentRequestDeny from './RequestDeny';
import DenyDialog from './DenyDialog';
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat';
import { Time } from '../../../atoms/DateTimeFormat/TimeFormat';
import ApproveConsentPin from './ApproveConsentPin';
import EditApprovePin from './EditApprovePin';

const InformationTypeConsent = (props) => {
    const { onSelectedItemsChange, item, onUnSelectedItemsChange, selectedCheckboxes } = props;

    const items = item?.hiTypes
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [open, setOpen] = useState(false)
    useEffect(() => {
        // Initialize selectedCheckboxes based on selected item texts when the component mounts
        onSelectedItemsChange(items?.map((item) => item));
        onUnSelectedItemsChange([]);
    }, []);


    const handleSelectAll = () => {
        if (selectedCheckboxes.length === items.length) {
            onUnSelectedItemsChange(items?.map((item) => item));
            onSelectedItemsChange([]);
        } else {
            onSelectedItemsChange(items?.map((item) => item));
            onUnSelectedItemsChange([]);
        }
        setSelectAllChecked(!selectAllChecked);
    }


    const StatusIcon = (type) => {
        if (type === "DiagnosticReport") {
            return <img src={diagnosticIcon} alt="diagnostic" width={25} height={25} />
        }
        if (type === "DischargeSummary") {
            return <img src={dischargeIcon} alt="discharge" width={25} height={25} />
        }
        if (type === "OPConsultation") {
            return <img src={consultationIcon} alt="consult" width={25} height={25} />
        }
        if (type === "ImmunizationRecord") {
            return <img src={immunisationIcon} alt="record" width={25} height={25} />
        }
        if (type === "WellnessRecord") {
            return <img src={WellnessRecordIcon} alt="wellness" width={25} height={25} />
        }
        if (type === "HealthDocumentRecord") {
            return <img src={documentIcon} alt="document" width={25} height={25} />
        }
        if (type === "Prescription") {
            return <img src={prescriptionIcon} alt="prescription" width={25} height={25} />
        }
    }

    return (
        <div>
            <Box>
                <Card sx={{
                    boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: '#FEF0FF', p: "0px 10px" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            {/* <Box sx={{ border: '1px solid black', width: "25px", height: '25px', borderRadius: 5, cursor: 'pointer' }} onClick={handleSelectAll}>
                                {selectAllChecked ? <Check sx={{ color: '#0118B4' }} /> : null}
                            </Box> */}
                            <Checkbox
                                color="secondary"
                                checked={selectAllChecked}
                                onClick={handleSelectAll}
                            />
                            <Typography sx={{ fontWeight: 600, fontSize: { xs: 14, sm: 14, md: 14 } }}>{"All (Prescription,Reports,Discharge,...)"}</Typography>
                        </Box>
                        <IconButton onClick={() => setOpen(!open)}>{open ? <ExpandLess sx={{ color: "black" }} /> : <ExpandMore sx={{ color: "black" }} />}</IconButton>
                    </Box>
                    {
                        open && (
                            <Box sx={{
                                maxHeight: '200px', // Set your desired maximum height
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                            }}>
                                {
                                    items ? (
                                        items.map((hiType, index) => (
                                            <React.Fragment key={index}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "10px 15px" }}>
                                                    <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                        {StatusIcon(hiType)}
                                                        <Typography sx={{ fontSize: { xs: 13, md: 15, sm: 14 } }}>{hiType}</Typography>
                                                    </Box>
                                                    {/* <Checkbox
                                                        checked={selectedCheckboxes.includes(hiType)}
                                                        onChange={() => handleCheckboxChange(hiType)}
                                                    /> */}
                                                </Box>
                                                <Divider />
                                            </React.Fragment>
                                        ))) : <Typography sx={{ p: "10px 15px", fontSize: { xs: 13, sm: 14, md: 14 } }}>No Data available</Typography>

                                }
                            </Box>
                        )}
                </Card>
            </Box >
        </div >
    )
}

const InformationSourceConsent = (props) => {
    const { linkedHospitals, item } = props || {};
    const [links, setLinks] = useState([])
    const [open, setOpen] = useState(false)
    const [openStates, setOpenStates] = useState([]);
    const [selectAllChecked, setSelectAllChecked] = useState(true)
    const [recordscheck, setRecordsCheck] = useState(true)
    console.log(item, "linkshosp")

    const handleSelectAll = () => {

    }
    const handleHospitalToggle = (itemId) => {
        setOpenStates((prev) => ({
            ...prev,
            [itemId]: !prev[itemId],
        }));
    };
    useEffect(() => {
        if (linkedHospitals?.patient?.links) {
            setLinks(linkedHospitals?.patient?.links);
        }

    }, [linkedHospitals]);
    return (
        <Box >
            <Card sx={{
                boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: '#FEF0FF', p: '0px 10px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {/* <Box sx={{ border: '1px solid black', width: "25px", height: '25px', borderRadius: 5, cursor: 'pointer' }} onClick={handleSelectAll}
                        >
                            {selectAllChecked &&
                                <Check sx={{ color: '#0118B4' }} />
                            }
                        </Box> */}
                        <Checkbox
                            color="secondary"
                            checked={selectAllChecked}
                            onClick={handleSelectAll}
                        />
                        <Typography sx={{ fontWeight: 600, fontSize: { xs: 14, sm: 15, md: 14 } }}>{"All Facilities and Health Programs"}</Typography>
                    </Box>
                    <IconButton onClick={() => setOpen(!open)}>{open ? < ExpandLess sx={{ color: "black" }} /> : < ExpandMore sx={{ color: "black" }} />}</IconButton>
                </Box>

                {
                    open && (
                        <Box sx={{
                            maxHeight: '250px', // Set your desired maximum height
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}>
                            {
                                links.map((link, index) => (
                                    <>
                                        <Card sx={{ mt: "10px", p: "0px 10px", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                            <Box>
                                                {/* <Checkbox defaultChecked /> */}
                                                <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14 } }}>{link?.hip?.name}</Typography>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => handleHospitalToggle(index)}> {openStates[index] ? <ArrowDropUp sx={{ color: 'black' }} /> : <ArrowDropDown sx={{ color: 'black' }} />}</IconButton>
                                            </Box>
                                        </Card>
                                        {
                                            openStates[index] && (
                                                <Box >
                                                    {link?.careContexts?.map((record, recordIndex) => (
                                                        <>
                                                            <Card sx={{ p: "6px 10px", mt: "2px", bgcolor: 'whitesmoke' }}>
                                                                <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14 } }} key={recordIndex}>{record.display}</Typography>
                                                            </Card>
                                                        </>
                                                    ))}
                                                </Box >)
                                        }
                                    </>

                                ))
                            }
                        </Box>
                    )
                }
            </Card >
        </Box >
    )
}


function ApproveDialog(props) {

    const { hospital, item, date, time } = props
    let linkeddata = useSelector(stat => stat.records.linkedproviders);

    // console.log(item, "consents")
    const expirydate = DateFormat(item?.permission?.dataEraseAt)
    const expirytime = Time(item?.permission?.dataEraseAt);
    const primaryId = localstore.getPrimaryId()
    const token = localstore.getToken()
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [pin, setPin] = useState(['', '', '', '']);
    const otpFieldsRef = React.useRef([]);
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = useState(1)
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };


    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [unselectedCheckboxes, setUnselectedCheckboxes] = useState([]);

    const handleDropdownSelect = (selectedItems) => {
        setSelectedCheckboxes(selectedItems);
    };
    const handleDropdownUnSelect = (selectedItems) => {
        setUnselectedCheckboxes(selectedItems);
    };

    useEffect(() => {
        if (token && primaryId) {
            dispatch(getLinkedProviders(token, primaryId));
        }
    }, [token, primaryId])
    const handleEdit = () => {
        setShow(2)
    }
    const handleCancel = () => {
        setPin(['', '', '', ''])
        setAlertMessage('')
        handleClose()
    }

    return (
        <div>
            <IconButton onClick={handleClickOpen}>  <Pending sx={{ color: '#247FEB', ml: '3px' }} /></IconButton>
            <Dialog
                open={open}
                fullWidth
                maxWidth="sm"
                onClose={handleClose}
            >
                {show === 1 ? (
                    <>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                                <img src={consentIcon} alt='consent' />
                                <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 17 }, fontWeight: 600 }}>Confirm Requests</Typography>
                            </Box>
                            <Button sx={{ height: "25px", color: 'black', borderRadius: "15px" }} variant='outlined' startIcon={<img src={EditIcon} alt="edit" />} onClick={handleEdit}>Edit</Button>
                        </DialogTitle>
                        <Divider />

                        <DialogContent sx={{
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box>
                                    {item?.requester?.name ?
                                        <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                                            {item?.requester?.name}
                                        </Typography>
                                        : <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                                            Health Locker
                                        </Typography>
                                    }
                                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                        {hospital.orgImg ?
                                            <img src={hospital?.orgImg} alt="orgimg" width={45} height={45} style={{ borderRadius: '5px', marginLeft: "12px" }} />
                                            :
                                            <img src={hospitalIcon} alt="hospital" style={{ width: 50, height: 50 }} />
                                        }
                                        <Box>
                                            {hospital?.identifier?.name ?
                                                <Typography sx={{ fontSize: { xs: '14px', sm: '14px', md: '14px' }, fontWeight: 600 }}>{hospital?.identifier?.name}</Typography> : <Typography sx={{ fontSize: { xs: '13px', sm: '18px', md: '17px' }, fontWeight: 500 }}>Medxperts Health Locker</Typography>}

                                            <Typography sx={{ mt: "2px", fontSize: { xs: '13px', sm: '14px', md: '14px' } }}>{item?.purpose?.text}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 15 }, fontWeight: 600 }}>Requested On</Typography>
                                    <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }} >
                                        {date} | {time}
                                    </Typography>
                                    <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 15 }, fontWeight: 600, mt: 1 }}>Consent Expiry </Typography>
                                    <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }} >
                                        {expirydate} | {expirytime}
                                    </Typography>
                                </Box>
                            </Box>


                            <Typography sx={{ color: 'black', mt: 1, fontSize: { xs: 14, sm: 15, md: 16 }, color: '#1843D0', }}> Requested Information</Typography>
                            <Box mt={1}>
                                <InformationTypeConsent item={item} selectedCheckboxes={selectedCheckboxes} onSelectedItemsChange={handleDropdownSelect} onUnSelectedItemsChange={handleDropdownUnSelect} />
                            </Box>
                            <Typography sx={{ color: 'black', mt: 2, fontSize: { xs: 14, sm: 15, md: 16 } }}>Information Source</Typography>
                            <InformationSourceConsent linkedHospitals={linkeddata} item={item} />

                            <Box>
                                <Typography sx={{ color: 'black', mt: 2, fontSize: { xs: 14, sm: 15, md: 16 } }} component={'p'}>Received Information Period</Typography>
                            </Box>
                            <Box>
                                <Typography sx={{ color: 'black', mt: 1, fontSize: { xs: 14, sm: 15, md: 14 } }} component={'p'}> From {DateFormat(item?.dateRange?.from)} To {DateFormat(item?.dateRange?.to)}</Typography>
                            </Box>
                        </DialogContent>
                        <Divider />
                        <DialogActions  >
                            <Box sx={{ mt: 1, mb: 1 }}>
                                <Button startIcon={loading ? <CircularProgress sx={{ color: "white" }} size={20} /> : <TaskAlt />} variant="contained" sx={{ bgcolor: '#247FEA', gap: 2, width: "150px", mr: 1, borderRadius: 2 }} onClick={() => setShow(4)}>
                                    Approve
                                </Button>
                                <Button variant='outlined' sx={{ color: 'red', gap: 2, borderRadius: 2, border: '1px solid red', "&:hover": { border: '1px solid red' }, width: '150px' }} startIcon={<RemoveCircleOutline sx={{ color: 'red' }} />} onClick={() => setShow(5)}>Reject</Button>
                            </Box>
                            {/* </Box> */}
                        </DialogActions>

                    </>) : show === 2 ? (
                        <ConsentRequestEdit time={time} date={date} expirydate={expirydate} expirytime={expirytime} item={item} hospital={hospital} setShow={setShow} handleClose={handleClose} />
                    ) : show === 3 ? (
                        <ConsentRequestDeny time={time} date={date} expirydate={expirydate} expirytime={expirytime} item={item} hospital={hospital} setShow={setShow} handleClose={handleClose} />
                    ) : show === 4 ? (
                        <ApproveConsentPin time={time} date={date} expirydate={expirydate} expirytime={expirytime} item={item} hospital={hospital} setShow={setShow} handleClose={handleClose} />
                    ) : show === 5 ? (
                        <DenyDialog time={time} date={date} expirydate={expirydate} expirytime={expirytime} item={item} hospital={hospital} setShow={setShow} handleClose={handleClose} />
                    ) : show === 6 ? (
                        <EditApprovePin time={time} date={date} expirydate={expirydate} expirytime={expirytime} item={item} hospital={hospital} setShow={setShow} handleClose={handleClose} />
                    ) : null}
            </Dialog>
        </div>
    );
}

export default ApproveDialog;