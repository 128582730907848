import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Box, Button, Card, CircularProgress, FormControl, MenuItem, Paper, Select, TextField, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { ArrowForward } from '@mui/icons-material';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { handleErrorResponse } from '../../../atoms/StatusError';


function SelectVerification(props) {

    const auth = useSelector((state) => state.abha.authMethods)
    const authvalues = auth
    // console.log(auth, "autheeeelinking")
    const aadhartokenHin = useSelector((state) => state.abha.aadhartokenHin)
    const transactionId = useSelector((state) => state.abha.mobilelinktransactionId)
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const phrAddress = useSelector((state) => state.abha.phrAddress)
    // console.log(aadhartokenHin, "result")
    const Email = result.Email
    const [mobileOtp, setMobileOtp] = useState('')
    const [emailOtp, setEmailOtp] = useState('')
    const [password, setPassword] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");


    const [selectedOption, setSelectedOption] = useState("PASSWORD");
    //  const [selectedOption, setSelectedOption] = useState('');
    useEffect(() => {
        // Call the API for the initial selected option ('PASSWORD')
        handleOptionChange({ target: { value: 'PASSWORD' } });
    }, []); // E
    const handleOptionChange = (e) => {
        const selectDropdown = e.target.value
        // console.log("eee", hello)

        setSelectedOption(e.target.value);
        let selectValue;

        let d = {
            authMethod: 'MOBILE_OTP',
            healthid: phrAddress,
        };
        let d1 = {
            authMethod: 'AADHAAR_OTP',
            healthid: phrAddress,
        };
        let d2 = {
            authMethod: 'EMAIL_OTP',
            phrAddress: phrAddress,
        };
        let d3 = {
            authMethod: 'PASSWORD',
            healthid: phrAddress,
        };

        let apiUrlSegment = '';

        if (selectDropdown === 'MOBILE_OTP') {
            selectValue = d;
            apiUrlSegment = 'auth/init';
        } else if (selectDropdown === 'AADHAAR_OTP') {
            selectValue = d1;
            apiUrlSegment = 'auth/init';
        } else if (selectDropdown === 'EMAIL_OTP') {
            selectValue = d2;
            apiUrlSegment = 'email/login/mobileEmail/auth-init';
        } else if (selectDropdown === 'PASSWORD') {
            selectValue = d3;
            apiUrlSegment = 'auth/init';
        }
        // setLoading(true);
        if (selectDropdown === 'EMAIL_OTP' || selectDropdown === 'MOBILE_OTP' || selectDropdown === 'AADHAAR_OTP' || selectDropdown === 'PASSWORD') {
            axios.post(`${URL.abha}${apiUrlSegment}/${primaryId}`, selectValue, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },
            }).then(res => {



                let transactionId;

                if (selectDropdown === 'MOBILE_OTP') {
                    transactionId = res.data.txnId;
                    setAlertmessage('OTP is sent Successfully');
                    setAlertstatus('success');
                } else if (selectDropdown === 'AADHAAR_OTP') {
                    transactionId = res.data.txnId;
                    setAlertmessage('OTP is sent Successfully');
                    setAlertstatus('success');
                } else if (selectDropdown === 'EMAIL_OTP') {
                    transactionId = res.data.transactionId;
                    setAlertmessage('OTP is sent Successfully');
                    setAlertstatus('success');
                } else if (selectDropdown === 'PASSWORD') {
                    transactionId = res.data.transactionId;
                    setAlertmessage('Please enter a user password');
                    setAlertstatus('success');
                }
                dispatch({
                    type: 'SELECTED_OPTIONS',
                    payload: transactionId
                })
            }).catch(err => {
                if (err) {
                    console.log('------------>>> errordata', err);
                    // console.log(err.message);
                    setAlertmessage(err.message);
                    setAlertstatus('error');

                }
            }).finally(() => {
                setLoading(false);

            })
        }
        setEmailOtp('')
        setMobileOtp('')
        setPassword('')
        setAlertmessage('')
    };

    const handleLogin = async () => {
        setLoading(true)
        let selectedData;
        // setSelectedOption('');

        let d = {
            authMethod: 'MOBILE_OTP',
            otp: mobileOtp,
            txnId: transactionId,
        };
        let d1 = {
            authMethod: 'AADHAAR_OTP',
            otp: mobileOtp,
            txnId: transactionId,
        };
        let d2 = {
            auth_method: 'input',
            input: mobileOtp,
            transactionId: transactionId,
        };
        let d3 = {
            authMethod: 'PASSWORD',
            password: password,
            healthId: phrAddress,
            txnId: transactionId,

        };

        let apiUrlSegment = '';
        if (selectedOption === 'MOBILE_OTP') {
            selectedData = d;
            apiUrlSegment = `new/confirmOtp/${primaryId}`;
        } else if (selectedOption === 'AADHAAR_OTP') {
            selectedData = d1;
            apiUrlSegment = `new/confirmOtp/${primaryId}`;
        } else if (selectedOption === 'EMAIL_OTP') {
            selectedData = d2;
            apiUrlSegment = `email/login/phrAddress/verify/${primaryId}`;
        } else if (selectedOption === 'PASSWORD') {
            selectedData = d3;
            apiUrlSegment = `new/confirmOtp/${primaryId}`;
        } else {
            selectedData = 'defaultOption';
        }
        // console.log('---------selectedData', `${URL.abha}${apiUrlSegment}`, selectedData,);
        await axios
            .post(`${URL.abha}${apiUrlSegment}`, selectedData, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(async res => {
                console.log(res, "================>loginphr")
                dispatch(getAbhaProfile(primaryId, token,));
                // if (aadhartokenHin.healthIdNumber) {
                //     handleLink(aadhartokenHin.xtoken)
                // }
                setAlertmessage(t("otpversuccesfully"));
                setAlertstatus('success');
                let ibmp = ibm?.identifier
                    ? { ...ibm }
                    : { ...ibm, identifier: [] };
                let da;
                let obj = ibmp.identifier.some(e => {
                    return e?.type?.coding[0]?.code === 'ABHA';
                });
                if (obj) {
                    let substitute = ibm.identifier?.map((e, i) => {
                        if (e?.type?.coding[0]?.code === 'ABHA') {
                            return { ...e, value: phrAddress };
                        } else {
                            return e;
                        }
                    });
                    da = { ...ibmp, identifier: substitute };
                } else {
                    da = {
                        ...ibmp,
                        identifier: [
                            ...ibmp.identifier,
                            {
                                system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                value: phrAddress,
                                type: {
                                    coding: [
                                        {
                                            code: 'ABHA',
                                        },
                                    ],
                                },
                            },
                        ],
                    };
                }
                console.log('---------ibmdataupdate', da);
                await axios
                    .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(res => {
                        console.log('update success');
                        setAlertmessage(t("dataregsuccessfully"));
                        setAlertstatus('success');

                        dispatch(
                            getPatientdatafromibm(
                                'Patient',
                                primaryId,
                                token,
                            ),
                        );
                        setSelectedOption('');
                        navigate({ pathname: '/services', state: 'healthprofile' });

                    })
                    .catch(err => {
                        console.log(err.message, 'error in update');
                    });
            })
            .catch(err => {
                if (err.response.status === 400) {
                    setAlertmessage('Otp Or Password are incorrect');
                    setAlertstatus('error');
                } else {
                    const { alertmessage, alertstatus } = handleErrorResponse(err)
                    setAlertmessage(alertmessage)
                    setAlertstatus(alertstatus)
                }
            }).finally(() => {
                setLoading(false)
            })
    };


    const handleMobileOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setMobileOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }
    const handleEmailOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setEmailOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }


    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: { xs: 1, sm: 2, md: 2 },
                    mt: { xs: "10px", sm: "15px", md: 2 },
                }}
            >
                {/* <IconButton onClick={() => props.setShow(3)}><ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} /></IconButton> */}
                <Typography
                    sx={{
                        fontWeight: 600,
                        fontSize: { xs: 17, sm: "17px", md: "17px" }, color: '#007DCD'
                    }}
                >
                    {t("selectvermethod")}
                </Typography>
            </Box>
            <Paper elevation={2} sx={{ mt: 1, p: 3, display: "flex", justifyContent: "center" }}>
                <Box alignItems={"center"}>
                    <Typography sx={{ fontWeight: 400 }}>
                        {t("phradd")}
                        <Typography
                            component={"span"}
                            sx={{ fontSize: { sm: 17, md: 17 }, fontWeight: 500 }}
                        >
                            {phrAddress}
                        </Typography>
                    </Typography>

                    <Box mt={1}>
                        <FormControl variant="outlined" fullWidth size="small">
                            <Select value={selectedOption} onChange={handleOptionChange}>
                                {authvalues?.map((option) => (
                                    <MenuItem key={option} value={option}>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            {option}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    {selectedOption === "MOBILE_OTP" ||
                        selectedOption === "EMAIL_OTP" ||
                        selectedOption === "AADHAAR_OTP" ? (
                        <Box mt={2}>
                            {/* <TextInputField name="mobileOtp" placeholder="Enter Mobile OTP" value={mobileOtp} onChange={handleMobileOtp} /> */}
                            <TextField
                                sx={{
                                    ".MuiInputBase-root": {
                                        borderRadius: 2,
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                        // border: props.border
                                    },
                                    width: "350px",
                                }}
                                placeholder={t("credentialsData.signupmobiledata.enterotp")}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={handleMobileOtp}
                                value={mobileOtp}
                                autoFocus
                                autoComplete="off"
                                type={"text"}
                            />
                        </Box>
                    ) : null}

                    {/* {selectedOption === "email" ? (
                    <Box mt={2}>
                        <TextInputField name="emailOtp" placeholder="Enter Email OTP" value={emailOtp} onChange={handleEmailOtp} />
                    </Box>
                ) : null} */}

                    {selectedOption === "PASSWORD" ? (
                        <Box mt={2}>
                            <TextField
                                sx={{
                                    ".MuiInputBase-root": {
                                        borderRadius: 2,
                                    },
                                    ".MuiOutlinedInput-notchedOutline": {
                                        // border: props.border
                                    },
                                    width: "350px",
                                }}
                                placeholder={t("credentialsData.signupmobiledata.enterpswd")}
                                size="small"
                                fullWidth
                                variant="outlined"
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                                autoFocus
                                autoComplete="off"
                                type={"password"}
                            />{" "}
                        </Box>
                    ) : null}

                    {alertmessage && (
                        <Typography
                            sx={{
                                color: alertstatus === "success" ? "green" : "red",
                                mt: 1,
                            }}
                        >
                            {alertmessage}
                        </Typography>
                    )}
                    <Box sx={{ textAlign: "center", mt: 4 }}>
                        <Button
                            variant="contained"
                            sx={{ width: "120px", borderRadius: 2 }}
                            onClick={handleLogin}
                            disabled={loading}
                            endIcon={
                                loading ? (
                                    <CircularProgress size={20} color="inherit" />
                                ) : (
                                    <ArrowForward sx={{ fontWeight: "bold" }} />
                                )
                            }
                        >
                            {t("credentialsData.commondata.login")}
                        </Button>
                    </Box>
                </Box>
            </Paper>
        </Box>
    );
}

export default SelectVerification