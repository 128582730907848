import React, { useEffect, useState } from 'react'
import { AppBar, Box, Avatar, Typography, useMediaQuery, IconButton, Card, Button, Paper, Grid, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Logo from '../assets/home/medxpertspassion.svg'
import Iconsetting from '../assets-new/Icon core-settings.svg'
import healthIdIcon from '../assets-new/profile-icons/editabhaprofile.svg'
import switchprofileIcon from '../assets-new/profile-icons/switchprofile.svg';

import TelemedcineIcon from '../assets-new/TELEMEDICINE-ICON.svg'
import { ArrowForwardIos, Close, Comment, Login, Search, Star, Vaccines, VerifiedUser } from '@mui/icons-material'
import AbhaLogo from '../assets/home/services_assets/ABHA_Number_390923a281.svg'
import AyushIcon from '../assets-new/profile-icons/abhalogo.svg'
import nationalIcon from '../assets-new/profile-icons/healthministry.cc00ac5f.svg';
import CommonButton, { OutlinedButton } from './atoms/CommonButton'
import AbhaSwitch from './AbhaSwitch'
import { useDispatch, useSelector } from 'react-redux'
import { getHealthStatus } from '../redux/Actions/consentActions'
import { localstore } from '../localStore/localStore'
import { getAbhaProfile, getPatientdatafromibm } from '../redux/Actions/Actions'
import MobileAppbar from './atoms/MobileAppbar'
import SwitchProfile from './pages/Medxperts/SwitchProfile'


function AbhaMobileView() {
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:900px)'); // Adjust the breakpoint as needed
    const [activeTab, setActiveTab] = useState('healthid')

    const handleTabChange = (newValue) => {
        setActiveTab(newValue);
    };
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const dispatch = useDispatch()
    const token = localstore.getToken()
    const fixedid = localstore.getPrimaryFixedId()
    const primaryId = localstore.getPrimaryId()

    const abhadata = useSelector((state) => state.abha.abhaprofile)



    useEffect(() => {
        if (abhadata?.AbhaAddress && primaryId && token)
            dispatch(getHealthStatus(abhadata?.AbhaAddress, primaryId, token));
    }, [primaryId, token, abhadata?.AbhaAddress])

    const [primaryid, setPrimaryid] = useState()
    const [selectedid, setSelectedid] = useState()

    useEffect(() => {
        const storedPrimaryId = localStorage.getItem('primaryId');
        if (storedPrimaryId) {
            setPrimaryid(storedPrimaryId);
        }
    }, []);

    useEffect(() => {
        if (primaryid && token)
            dispatch(getAbhaProfile(primaryid, token))
    }, [primaryid, token])

    useEffect(() => {
        // console.log(fixedid, primaryid, token, '+++++++++++++++++++<<<<<<<<<<<<<<<<<<<');

        if (token && primaryid && fixedid && !selectedid) {
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryid,
                    token,
                ),
            );
        }
        if (selectedid === fixedid) {
            setSelectedid(fixedid);
        }
    }, [token, primaryid, fixedid, selectedid]);



    return (
        <>
            <AppBar sx={{ bgcolor: 'white' }}>
                <Toolbar>

                    <Grid container alignItems={'center'}>
                        <Grid item xs={9}>
                            <MobileAppbar />
                        </Grid>
                        <Grid item xs={1.8}></Grid>
                        <Grid item xs={1.2} onClick={() => navigate(-1)}>
                            <Close sx={{ color: 'black', cursor: 'pointer' }} />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar >
            < Box sx={{ m: 1, mb: 10 }
            }>

                <Box sx={{ mt: isMobile ? 9 : 0, p: 1 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            bgcolor: '#CCE6E8',
                            borderRadius: '10px',
                            justifyContent: 'space-between',
                            p: isMobile ? '5px 10px' : '5px 20px',
                        }}
                    >
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                                p: isMobile ? '5px 30px' : '5px 20px',
                                cursor: 'pointer',
                                gap: '10px',
                                borderRadius: '10px',
                                justifyContent: isMobile ? 'space-between' : 'space-evenly',
                                bgcolor: activeTab === 'healthid' ? 'white' : '#CCE6E8',
                                color: activeTab === 'healthid' ? '#6B8099' : 'black',
                            }}
                            onClick={() => handleTabChange('healthid')}
                        >
                            <img src={healthIdIcon} alt="healthid" />
                            <Typography sx={{ fontSize: '14px' }}>My Health ID</Typography>
                        </Box>
                        <Box
                            sx={{
                                display: 'flex',
                                cursor: 'pointer',
                                alignItems: 'center',
                                gap: '5px',
                                p: isMobile ? '5px 30px' : '5px 20px',
                                borderRadius: '10px',

                                bgcolor: activeTab === 'switchprofile' ? 'white' : '#CCE6E8',
                                color: activeTab === 'switchprofile' ? '#6B8099' : 'black',
                            }}
                            onClick={() => handleTabChange('switchprofile')}
                        >
                            <img src={switchprofileIcon} alt="switchprofile" />
                            <Typography sx={{ fontSize: '14px' }}>SwitchProfile</Typography>
                        </Box>
                        {/* </Box> */}


                    </Box>
                    {
                        activeTab === "healthid" &&
                        <Box>
                            <AbhaSwitch ibmpdata={ibm} />
                        </Box>

                    }
                    {

                        activeTab === "switchprofile" &&
                        <>
                            <Typography sx={{ fontSize: 14, mt: 2 }} component={'p'}>
                                Need to Manage Health ID for  your  family  members ? Add them your MedXperts Account
                            </Typography>
                            <Box mt={2}>
                                <CommonButton text="New Profile" bgcolor="#205072" width="150px" onClick={() => navigate('/createprofile')} />
                            </Box>
                            <Box mt={"-50px"}>
                                <SwitchProfile />
                            </Box>
                        </>
                    }
                    {/* <Box sx={{ mt: 10 }}>
                        {
                            activeTab === "favourites" &&
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                    <Avatar sx={{
                                        borderRadius: "7px!important",
                                        marginLeft: "10px !important",
                                        width: { sm: 55, md: 60 },
                                        height: { sm: 55, md: 60 },
                                    }} src={Iconsetting} />
                                    <Box>
                                        <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
                                            Medxperts Care Assistant
                                        </Typography>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            Dr Practitioner Display Name
                                        </Typography>
                                        <Typography sx={{ fontSize: '14px' }}>
                                            Waiting for you
                                        </Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ fontSize: '14px' }}>Today</Typography>
                            </Box>
                        }
                        {
                            activeTab === "messages" && <Typography>Messages</Typography>
                        }
                    </Box> */}




                </Box>
            </Box >
        </>
    )
}

export default AbhaMobileView