import React from 'react'
import { Box, Typography } from '@mui/material'
import { OutlinedButton } from './CommonButton'
import { useNavigate } from 'react-router-dom'

function AlreadyAccount() {
    const navigate = useNavigate()
    const handleLogin = () => {
        navigate('/login')
    }
    return (
        <div>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <Typography sx={{ fontSize: '14px', cursor: 'pointer', color: '#8A2BE2', "&:hover": { textDecoration: 'underline' } }} onClick={handleLogin}>Already have an Account?</Typography>
                <OutlinedButton text="Login" width="100px" mt={1} border={"1px solid #8A2BE2"} hovercolor={"1px solid #8A2BE2"} color={"#8A2BE2"} onClick={handleLogin} />
            </Box>
        </div>
    )
}

export default AlreadyAccount