import React from 'react'
import { AssignmentIndOutlined, Close, MonitorHeart, NotificationsOutlined, Settings, VaccinesOutlined } from '@mui/icons-material'
import { Box, Divider, Grid, IconButton, Paper, Radio, Switch, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import consultationicon from '../../../../assets-new/carekit-icons/caresettings.svg'
import '../../../../utility/style.css';
import vaccinationicon from '../../../../assets-new/carekit-icons/syringe-vaccine.svg'

const CareCards = (props) => {
    return (
        <Box className="space_btw_center" sx={{ mt: '5px' }} >
            <Box className="flex_center" gap={2}>
                <VaccinesOutlined />
                <Typography className='font-caption' fontSize={14}>{props.title}</Typography>
            </Box>
            <Switch defaultChecked />
        </Box>
    )
}


function CareSettings() {
    const navigate = useNavigate()
    return (
        <Box sx={{ m: 1, mb: { xs: 10, sm: 10, md: 2 }, }}>
            <Box sx={{ display: { xs: "flex", sm: 'flex', md: 'none' }, justifyContent: 'space-between' }}>
                <Box sx={{ display: { xs: 'flex', md: 'none' }, gap: 1, alignItems: 'center' }}>
                    <img src={consultationicon} alt="consultation" width={35} height={35} />
                    <Typography sx={{ fontWeight: 500, fontSize: 17 }}>Care 360 &deg; Preferences </Typography>
                </Box>
                <IconButton onClick={() => navigate(-1)}>  <Close sx={{ color: 'red' }} /></IconButton>
            </Box>
            <Box sx={{ display: 'flex', gap: 1, mt: { xs: 1, md: 0 } }}>
                <NotificationsOutlined />
                <Typography sx={{ fontWeight: 500 }}>Recommended - To Do</Typography>
            </Box>

            <Paper elevation={2} sx={{ p: '5px 20px', mt: '20px', borderRadius: '10px' }}>
                <CareCards title="Vaccination Recommended" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Practitioner Appointments" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Laboratory Test Orders" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Clinical Goals" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Referalls" />
            </Paper>
            <Box className=" mt-20 flex_center" gap={1} >
                <AssignmentIndOutlined />
                <Typography sx={{ fontWeight: 500 }}>Active Medication </Typography>
            </Box>
            <Grid container columnSpacing={3} mt={2} >
                <Grid item xs={6} sm={6} md={6}>
                    <Paper elevation={3} sx={{ display: 'flex', alignItems: 'center', borderRadius: '5px' }}>
                        <Radio />
                        <Typography fontSize={14}>Calendar View</Typography>
                    </Paper>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                    <Paper elevation={3} sx={{ display: 'flex', alignItems: 'center', borderRadius: '5px' }}>
                        <Radio />
                        <Typography fontSize={14}>List View</Typography>
                    </Paper>
                </Grid>
            </Grid>
            <Box className=" mt-20 flex_center" gap={1} >
                <MonitorHeart />
                <Typography sx={{ fontWeight: 500 }}>Health Tracking</Typography>
            </Box>
            <Paper elevation={2} sx={{ p: '5px 20px', mt: '20px', borderRadius: '10px' }}>
                <CareCards title="Recommended Clinical Goals" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Activities" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Vital Signs" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Body Measure" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Diagnostic results tracking" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Symptoms tracking" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Social Habits & Addictions" />
            </Paper>
            <Box className=" mt-20 flex_center" gap={1} >
                <MonitorHeart />
                <Typography sx={{ fontWeight: 500 }}>Recommended Clinical Goals</Typography>
            </Box>
            <Paper elevation={2} sx={{ p: '5px 20px', mt: '20px', borderRadius: '10px' }}>
                <CareCards title="Blood Pressure" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Blood Oxygen" />
                <Divider sx={{ mt: '5px' }} />
                <CareCards title="Hemoglobin" />
            </Paper>
        </Box >
    )
}

export default CareSettings