import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { Box, Menu, MenuItem, Button, Card, CardActions, CardContent, CardHeader, CardMedia, Collapse, Divider, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, IconButton, Switch, TextField, TextareaAutosize, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import LikeIcon from '../../../../../assets/home/feed_assets/like-icon.svg'
import LikedIcon from '../../../../../assets/home/feed_assets/liked-icon.svg'
import ShareIcon from '../../../../../assets/home/feed_assets/share-icon.svg'
import ShareCopyIcon from '../../../../../assets/home/feed_assets/open_external.svg'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import { useNavigate, useParams } from 'react-router-dom';
import { getpostbyid } from '../../../../../redux/Actions/Actions';
import CommentIcon from "../../../../../assets/home/feed_assets/comment-icon.svg"
import URL from '../../../../../utility/apiurl';
import { localstore } from '../../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import PostAskDoctor from './PostAskDoctor';
import medxpertsIcon from '../../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import { useTranslation } from 'react-i18next';


function timeSince(date) {
    var ms = new Date().getTime() - new Date(date).getTime();
    var seconds = Math.floor(ms / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var months = Math.floor(days / 30);
    var years = Math.floor(months / 12);
    if (seconds < 1) {
        return 'Just now';
    }
    if (seconds < 60) {
        return seconds + 's';
    }
    if (minutes < 60) {
        return minutes + 'm';
    }
    if (hours < 24) {
        return hours + 'h';
    }
    if (days < 30) {
        return days + 'd';
    }
    if (months < 12) {
        return months + ' month';
    } else {
        return years + 'y';
    }
}
function SharePost() {
    const { id } = useParams()
    console.log(id, "postidddddddddddddd")
    const [state, setState] = useState()
    // console.log(state, "statee")
    const [comment, setComment] = useState(false)
    const [t, i18n] = useTranslation("global");

    const [expanded, setExpanded] = useState(false);
    const [share, setShare] = useState(false)
    const [like, setLike] = useState(false);
    // const [item, setItem] = useState()
    const token = localstore.getToken()
    const patientId = localstore.getPatientId()
    const post = useSelector((state) => state.medx.postdata)
    // console.log(post, "post1==============>")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    useEffect(() => {
        if (id) {
            axios
                .get(`${URL.feedApi}post/${id}`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((res) => {
                    console.log(res, "ressssss")
                    setState(res?.data);
                })
                .catch((error) => console.log(error, '555555555'));
        }
    }, []);
    useEffect(() => {
        if (state?.id === post?.id) {
            setState(post)
        }
    }, [state, post])

    const handleLike = () => {
        setLike(true);
        let data = {
            userData: state?.userData || state?.pageData,
            date: new Date().toISOString(),
            likedBy: patientId,
            metadata: {
                author: patientId,
                dateTime: new Date().toISOString(),
                location: '',
                versionId: 0,
                securityCodes: '',
            },
            postId: state.id,
        };

        axios
            .post(`${URL.addLikes}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                // console.log(res, "resssssssss")
                dispatch(getpostbyid(state.id, token));
            })
            .catch(err => {
                console.log(err.message, 'handleLike');
            });
    };

    const handleUnlike = () => {
        setLike(false);
        axios
            .delete(`${URL.deleteLikes}${state.likeId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                dispatch(getpostbyid(state.id, token));
            })
            .catch(err => {
                console.log(err.message, 'handleUnlike');
            });
    };

    const handleCommentClick = () => {

        dispatch(getpostbyid(state.id, token));
        setComment(!comment)
    }

    const handlelinkcopy = () => {
        const url = navigator.clipboard.writeText(`${"dev.medxperts.in"}`)
        // console.log(url, "yessss")
    }
    const shareUrl = `${"dev.medxperts.in"}`;


    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleShare = (e) => {
        // console.log('hi share')
        setShare(!share)

        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };
    const handleNavigate = (item) => {
        if (item.pageId == 0) {
            navigate(`/medxpedia/doctor/${item.userId}`);
        } else {
            navigate(`/medxpedia/org/${item.pageId}`);
        }
    }
    return (
        <div>
            <Box sx={{ mt: { md: 2, xs: 18, sm: 11 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '10px', sm: "5px", md: '50px' } }}>

                <Card sx={{
                    backgroundColor: "white", p: "15px 0px", border: '1px solid #e5e5dc', borderRadius: " 10px", pb: "2px",
                }} >
                    <Box sx={{ display: 'flex', gap: 1 }}>
                        {
                            state?.pageData?.profilePic?.length > 0 || state?.userData?.profilePic?.length > 0 ?
                                <Avatar src={state?.pageData?.profilePic ? state?.pageData?.profilePic : state?.userData?.profilePic} sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important", width: { sm: 55, md: 60 }, height: { sm: 55, md: 60 }, p: "5px" }} /> : <Avatar sx={{
                                    borderRadius: "7px!important",
                                    // border: "1px solid lightgrey",
                                    marginLeft: "10px !important",
                                    width: { sm: 55, md: 65 },
                                    height: { sm: 55, md: 65 },
                                }} src={medxpertsIcon} />
                        }


                        <div>
                            {state?.pageData?.fullName?.length > 0 || state?.userData?.fullName?.length > 0 ?

                                <Typography onClick={() => handleNavigate(state)} sx={{ color: 'black', fontSize: { xs: 12, sm: 15, md: 15 }, fontWeight: 600 }}>{state.pageData?.fullName || state.userData?.fullName} </Typography> : <Typography>Not Mentioned</Typography>

                            }

                            {
                                state?.pageData?.caption?.length > 0 || state?.userData?.caption?.length > 0 ?
                                    <Typography sx={{ fontStyle: 'italic', fontSize: { xs: 11, sm: 12, md: 13 } }}>{state?.pageData?.caption || state?.userData?.caption}</Typography>
                                    :
                                    null
                            }

                            <Typography color="black" >
                                <Typography sx={{ fontSize: { xs: 11, sm: 12, md: 13 } }}>{`${timeSince(moment.utc(state?.createDate).local())}`}</Typography>&nbsp;
                            </Typography>
                        </div>
                    </Box>

                    <CardContent sx={{ p: 0 }}>
                        <Grid sx={{ width: { xs: '450px', sm: '700px', md: '720px' } }}>
                            <Typography variant="body2" color="text.secondary"
                                sx={{ textOverflow: 'ellipsis', wordWrap: 'break-word', pl: 1.6 }}
                            >
                                {expanded ? state?.description : `${state?.description?.slice(0, 114)}`}
                                {/* <Collapse in={expanded} timeout="auto" >
                            <Typography paragraph> attention if they persist or worsen.</Typography>
                        </Collapse> */}
                            </Typography>
                            {state?.description?.length > 95 ?
                                <Typography onClick={handleExpandClick} sx={{ cursor: 'pointer' }}>{expanded ? 'see less' : 'see more'} </Typography>
                                : ""}
                        </Grid>
                        {/* <ExpandMoreIcon /> */}

                    </CardContent>

                    <Box>
                        {state?.sourceLink ? <CardMedia
                            component="img"
                            // height="194"
                            sx={{ height: { xs: '100%', sm: '100%', md: '480px' }, }}
                            // image='https://media.istockphoto.22com/id/1341976416/photo/healthy-eating-and-diet-concepts-top-view-of-spring-salad-shot-from-above-on-rustic-wood-table.jpg?b=1&s=170667a&w=0&k=20&c=xYV0gZRXSLeAGJAPaNFaLH1V3VLNLY3KZGVL-neS1js='
                            image={state?.sourceLink}
                            alt="Paella dish"
                        /> : null}
                    </Box>

                    <Box sx={{ display: 'flex', pl: 1, pr: 1, justifyContent: 'space-between' }}>
                        {state?.likeCount > 0 ? (
                            <Typography sx={{ fontSize: { xs: '7px', sm: '11px', md: '11px' }, margin: '5px' }}>
                                {state.likeCount} {state.likeCount === 1 ? 'Person' : 'People'} Liked
                            </Typography>
                        ) : null}
                        {state?.shareCount > 0 ? (
                            <Typography sx={{ fontSize: { xs: '7px', sm: '11px', md: '11px' }, margin: '5px' }}>
                                {state?.shareCount} {state?.shareCount === 1 ? 'Person' : 'People'} Answered
                            </Typography>
                        ) : null}
                    </Box>

                    <Divider />
                    <CardActions sx={{ justifyContent: 'space-between', p: "2px 20px" }}>
                        <Box>
                            {state?.liked || like === true ? (
                                <IconButton aria-label="unlike" onClick={handleUnlike}>
                                    <img src={LikedIcon} alt="unlike" />
                                </IconButton>
                            ) : (<IconButton aria-label="like" onClick={handleLike}>
                                <img src={LikeIcon} alt="like" />
                            </IconButton>)}
                            <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={
                                state?.liked || like === true ? handleUnlike : handleLike
                            }> Like</Typography>
                        </Box>
                        <Box onClick={handleCommentClick}>
                            <IconButton aria-label="comment" >
                                <img src={CommentIcon} alt="comment" />
                            </IconButton>
                            <Typography variant="caption" sx={{ cursor: 'pointer' }}>Ask Doctor</Typography>
                        </Box>
                        <Box onClick={handleShare}>
                            <IconButton aria-label="share" >
                                <img src={ShareIcon} alt="share" />
                            </IconButton>
                            <Typography variant="caption" sx={{ cursor: 'pointer' }}>Share</Typography>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            keepMounted
                        >
                            <MenuItem>
                                <FacebookShareButton
                                    url={shareUrl}
                                    quote={"Title or jo bhi aapko likhna ho"}
                                    hashtag={"#portfolio..."}
                                >
                                    <FacebookIcon size={20} round="true" /> &nbsp;&nbsp;
                                    {t("actions.facebook")}
                                </FacebookShareButton>
                            </MenuItem>
                            <MenuItem url={shareUrl}>
                                <TwitterShareButton url={shareUrl}>
                                    <TwitterIcon size={20} round="true" /> &nbsp;&nbsp;
                                    {t("actions.twitter")}
                                </TwitterShareButton>
                            </MenuItem>
                            <MenuItem url={shareUrl}>
                                <WhatsappShareButton
                                    url={shareUrl}
                                    quote={"Title or jo bhi aapko likhna ho"}
                                    hashtag={"#portfolio..."}
                                >
                                    <WhatsappIcon size={20} round="true" /> &nbsp;&nbsp;
                                    {t("actions.whatsapp")}
                                </WhatsappShareButton>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <span
                                    onClick={handlelinkcopy}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <img src={ShareCopyIcon} />
                                    &nbsp;&nbsp;{t("actions.copylink")}
                                </span>
                            </MenuItem>
                        </Menu>
                    </CardActions>
                </Card >

                <PostAskDoctor item={state} commentOpen={comment} />
            </Box>
        </div >
    )
}

export default SharePost