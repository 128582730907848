import React, { useEffect } from 'react';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import axios from 'axios';
import URL from '../../../../../utility/apiurl';
import InfiniteScroll from 'react-infinite-scroll-component';
import { localstore } from '../../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import LiveHealthdata from '../PublicInformation/LiveHealthdata';
import HomePosts from './HomePosts';

const Livehealth = () => {
    const [feedData, setFeedData] = useState([]);
    const [feedDataNum, setFeedDataNum] = useState(0);
    const [scrollNum, setScrollNum] = useState(0);
    const [refreshing, setRefreshing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const token = localstore.getToken();
    const primaryId = localstore.getPrimaryId()

    // console.log(feedData, 'feed')
    const limit = 25;
    const post = useSelector((state) => state.medx.postdata)

    useEffect(() => {
        fetchData()
        // fetchDataWithOutToken()
    }, [token])


    const fetchData = () => {
        if (token) {
            setIsLoading(true);

            axios.get(`${URL.feed}?limit=${limit}&offset=${scrollNum}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((res) => {
                // console.log(res, "liehealth")
                if (res.status === 200) {
                    setFeedData(feedData.concat(res?.data?.results))
                    setFeedDataNum(res.data.count)
                    setScrollNum(pre => pre + limit)
                    setRefreshing(false);
                }
                // console.log(res?.data?.results, '====================<<<<<<<<<<<')

            })
                .catch((error) => console.log(error, '6666666666'))
                .finally(() => {
                    setIsLoading(false);
                });

        }
    }

    return (
        <Box>
            {token && primaryId ?
                <Stack sx={{ paddingTop: { xs: '0px', sm: '0px', md: "0px" } }}>
                    <Box sx={{ p: "0px 20px", mb: { md: 5, sm: 0, xs: 0 } }}>
                        {feedData.length > 0 ? (
                            // If feedData has items, map and render LivehealthCard components
                            feedData.map((val, indx) => <Box sx={{ pt: { sm: "25px", xs: "25px" } }} key={indx} > <HomePosts feedData={val} /></Box>)
                        ) : (
                            // If feedData is empty, display a message
                            <Typography sx={{ textAlign: 'center', fontWeight: 600, mt: 2 }}>No Posts Available</Typography>
                        )}
                    </Box>
                </Stack> :
                <Box sx={{ p: "0px 20px", mt: 2, mb: { md: 5, sm: 0, xs: 0 } }}>
                    <LiveHealthdata />
                </Box>
            }
            {isLoading ? ( // Render CircularProgress if isLoading is true
                <CircularProgress sx={{ display: 'block', mx: 'auto', mt: 3 }} />
            ) : (
                <InfiniteScroll
                    dataLength={feedData?.length}
                    next={fetchData}
                    hasMore={feedData.length < feedDataNum} // Adjust this condition based on your logic
                    loader={<h4>Loading...</h4>}
                />
            )}
        </Box>
    )
}

export default Livehealth;