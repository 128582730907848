import React, { useEffect, useState } from 'react';
import { Avatar, Box, Button, Card, CircularProgress, Dialog, DialogActions, DialogTitle, Divider, Grid, IconButton, Paper, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import { AddAPhoto, ArrowBackIos, ArrowCircleLeft, Bookmark, BookmarkAddOutlined, BookmarkOutlined, Close, Edit, Error, MoreVert, Share } from '@mui/icons-material';
import DoctorIcon from '../../../../../assets/home/profile_assets/Userprofilelogo.svg';
import profileCoverPic from '../../../../../assets/home/backgroundpeople.png'
import { Metadata } from '../../../../atoms/Metadata';
import URL from '../../../../../utility/apiurl';
import axios from 'axios';
import { getDoctorsFollowCount, getDoctorsdata } from '../../../../../redux/Actions/Actions';
import { useNavigate, useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { localstore } from '../../../../../localStore/localStore';
import { useDispatch } from 'react-redux';
import { useTranslation } from "react-i18next";
import Overview from './Overview';
import Timeline from './DocTimeline';
import Publications from './Publications';
import DoctorServices from './DoctorServices';


function DoctorsProfile() {
    const [cover, setCover] = useState('');
    const [value, setValue] = useState(0);
    const [modal, setModal] = useState(false)
    const [doc, setDoc] = useState()
    const [doctor, setDoctor] = useState([])
    const [beingFollowed, setBeingFollowed] = useState()
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [isFollowing, setIsFollowing] = useState();
    const [t, i18n] = useTranslation("global");
    // console.log(isFollowing, "beingfolleddddddddddd")
    const navigate = useNavigate()
    const [ind, setInd] = useState('');
    const [index, setIndex] = useState(0)
    // console.log("============>doc", doc)
    const { id } = useParams()
    // console.log(id, "iddddddd")
    const docId = id
    const dispatch = useDispatch()
    const patientId = localstore.getPatientId()
    const token = localstore.getToken()
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const tabInfo = [
        { label: t("profileTabs.overview"), content: <Overview user={doc} /> },
        { label: t("profileTabs.services"), content: <DoctorServices user={doc} /> },
        { label: t("profileTabs.timeline"), content: <Timeline docId={docId} /> },
        { label: t("profileTabs.publications"), content: <Publications /> },
    ];

    useEffect(() => {
        if (id) {
            axios.get(`${URL.getDoctordata}${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((res) => {
                    // console.log(res, "singledoc")
                    setDoctor(res?.data)
                    setDoc(res?.data?.[0]?.data);
                    setBeingFollowed(res?.data?.[0].BeingFollowed)
                })
                .catch((error) => console.log(error, '555555555'));
        }
    }, []);


    useEffect(() => {
        setIsFollowing(beingFollowed)
    }, [beingFollowed])

    const handleFollow = (index) => {
        setInd(index);
        setLoading(true);
        const data = { ...Metadata(), author: patientId };
        axios.post(`${URL.followDoctor}${docId}/${patientId}`, data)
            .then(res => {
                // console.log('res', res);
                setAlertopen(true);
                setAlertMessage('Started Following');
                setAlertstatus('success');
                setTimeout(() => {
                    setAlertopen(false);
                }, 2000);

                dispatch(getDoctorsdata(docId, token));
                dispatch(getDoctorsFollowCount(patientId));
            })
            .catch(err => {
                setAlertopen(true);
                setAlertMessage(err.message);
                setAlertstatus('error');
                setTimeout(() => {
                    setAlertopen(false);
                }, 2000);
            })
            .finally(() => {
                setLoading(false);
                setInd('');
            });
        setIsFollowing(!isFollowing);
    };

    const handleOpenModal = () => {
        setModal(true)
    }

    const handleClose = () => {
        setModal(false)
    }

    const handleUnFollowDoc = () => {
        setLoading(true)
        if (token?.length > 0 && patientId !== 0 && doc.id !== 0) {
            axios.put(`${URL.unfollowDoctor}${patientId}/user/${doc.id}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    setModal(false);
                    dispatch(getDoctorsdata(doc.id, token));
                    dispatch(getDoctorsFollowCount(patientId));
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 1000);
                })
                .catch(err => {
                    setAlertopen(true);
                    setAlertMessage(err.message);
                    setAlertstatus('error');
                    setTimeout(() => {
                        setAlertopen(false);
                    }, 2000);
                }).finally(() => {
                    setLoading(false)
                }

                )
        }
        setIsFollowing(!isFollowing);
    }
    // console.log(isFollowing, '666')




    return (
        <Box sx={{ p: 1 }}>
            <Box

            >
                <div
                    style={{
                        width: "100%",
                        overflow: "hidden",
                        height: `${cover ? "" : "120px"}`,
                        position: "relative",
                    }}
                >
                    <img
                        src={doc?.coverPic ? doc?.coverPic : profileCoverPic}
                        alt=""
                        width="100%"
                        style={{ objectFit: "cover" }}
                    />
                    {/* <div style={{ position: 'absolute', top: 15, right: 20, }}>
                        <label htmlFor="img" style={{ display: 'block', cursor: 'pointer' }}>
                            <input type="file" name="uploadfile" id="img" accept=".jpg, .png, .jpeg" style={{ display: 'none' }} onChange={(e) => uploadFile(e, 'cover')} />
                            <img src={EditIcon} alt="editIcon" width={30} height={30} />
                        </label>

                    </div> */}
                    <div style={{ position: "absolute", top: 10, left: 20 }}>
                        <IconButton onClick={() => navigate(-1)}
                        >
                            {" "}
                            <ArrowCircleLeft
                                sx={{ cursor: "pointer", color: "white" }}
                            />
                        </IconButton>
                    </div>
                </div>
                {/* </Paper> */}
                <Box sx={{ border: "1px solid #E7E7E7" }}>
                    <Grid container>
                        <Grid item xs={3.5} sm={2} md={2}>
                            <div
                                style={{ backgroundColor: "#c7ced9", position: "relative" }}
                            >
                                <div
                                    style={{ position: "absolute", left: "20px", top: "-40px" }}
                                >
                                    {/* User Photo */}
                                    <img
                                        src={
                                            doc?.photos[0]?.url ? doc?.photos[0]?.url : DoctorIcon
                                        }
                                        width={70}
                                        height={70}
                                        style={{ background: "white", borderRadius: "10px" }}
                                    />

                                    {/* Edit Icon */}
                                    {/* <div style={{ position: 'relative' }}>
                                        <input type="file" name="uploadfile2" id="img1" accept='.jpg, .png, .jpeg' style={{ display: 'none' }} onChange={(e) => uploadFile(e, 'profile')} />
                                        <label htmlFor="img1" className="follow-link font-weight add-cover cursor">
                                            <span style={{
                                                fontSize: "16px", position: 'absolute', top: '-30px', right: 0

                                            }}>
                                                <img src={EditIcon} alt="editIcon" width={20} height={20} style={{ cursor: 'pointer' }} />

                                            </span>
                                        </label>
                                    </div> */}
                                </div>
                            </div>
                        </Grid>
                        <Grid item xs={4.7} sm={7.8} md={8}>
                            <Box sx={{ mt: "5px", alignItems: "center", gap: 1 }}>
                                {doc?.fullName?.length > 16 ? (
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: { sm: "16px", },
                                        }}
                                    >
                                        {doc?.fullName?.slice(0, 15)}..,
                                    </Typography>
                                ) : (
                                    <Typography
                                        sx={{
                                            fontWeight: 600,
                                            fontSize: { sm: "16px", },
                                        }}
                                    >
                                        {doc?.fullName},
                                    </Typography>
                                )}

                                {/* {user?.data?.profession?.length > 20 ?

                                                <Typography sx={{ fontSize: { xs: '12px', sm: '13px', md: '14px' } }} component="span">
                                                    {user?.data?.profession?.slice(0, 15)}..,

                                                </Typography>
                                                :
                                                <Typography sx={{ fontSize: { xs: '12px', sm: '13px', md: '14px' } }} component="span">
                                                    {user?.data?.profession}
                                                </Typography>} */}
                                <Box>
                                    {doc?.captionAndTaglines?.length > 0 ? (
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "11px", sm: "12px", md: "14px" }, fontWeight: 300
                                            }}
                                        >
                                            {doc?.captionAndTaglines}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "11px", sm: "12px", md: "12px" }, fontWeight: 300
                                            }}
                                        >
                                            N/A
                                        </Typography>
                                    )}
                                    {doc?.address[0]?.country?.length > 0 ? (
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "11px", sm: "12px", md: "14px" },
                                                fontWeight: 400,
                                            }}
                                        >
                                            {doc?.address[0]?.city} {doc?.address[0]?.state}{" "}
                                            {doc?.address[0]?.country}
                                        </Typography>
                                    ) : (
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "11px", sm: "12px", md: "12px" },
                                            }}
                                        >
                                            N/A
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                        </Grid>

                        <Grid item xs={3.5} sm={2} md={2}>
                            <Button
                                variant="outlined"
                                sx={{
                                    borderRadius: 5,
                                    width: "120px",
                                    mt: "5px",
                                    mr: 1,
                                    color: "black",
                                    border: "1px solid black",
                                }}
                            >
                                {t("profileTabs.getverify")}
                            </Button>
                        </Grid>
                    </Grid>

                    {/* <Box sx={{ display: 'flex', ml: 1, mb: 1, mt: 2, justifyContent: 'space-around' }}>
                                    <div>                                    </div>
                                    <div>
                                        <Button variant='outlined' sx={{ border: '1px solid #0F8ED3', borderRadius: 3, width: '130px', height: '30px' }} startIcon={<img src={EditImg} width={13} height={13} />}>
                                            Edit Profile
                                        </Button>
                                        <Button variant='outlined' sx={{ border: " 1px solid #0F8ED3", borderRadius: 3, width: '130px', height: '30px', ml: 2 }} endIcon={<img src={shareIcon} width={13} height={13} />}>
                                            Share
                                        </Button>
                                    </div>
                                </Box> */}
                    <Box
                        sx={{
                            display: "flex",
                            ml: 2,
                            mb: 1,
                            mt: 3,
                            alignItems: "center",
                            justifyContent: "start",
                        }}
                    >
                        <div></div>
                        <div>
                            <Button
                                sx={{
                                    borderRadius: "10px",
                                    bgcolor: "#388AAF",
                                    "&:hover": {
                                        bgcolor: "#388AAF",
                                    },
                                    height: 28,
                                    width: 100,
                                }}
                                variant="contained"
                                onClick={() => {
                                    if (loading === false) {
                                        if (isFollowing) {
                                            handleOpenModal();
                                        } else {
                                            handleFollow(ind);
                                        }
                                        // setIsFollowing(!isFollowing);
                                    } else {
                                        return;
                                    }
                                }}
                            >
                                <Typography sx={{ fontSize: "12px" }}>
                                    {isFollowing ? t("following") : t("follow")}
                                </Typography>
                            </Button>

                            <Button
                                sx={{
                                    borderRadius: "10px",
                                    border: "1px solid #388AAF",
                                    color: "#388AAF",
                                    height: 28,
                                    width: 100,
                                    ml: 2,
                                }}
                                variant="outlined"
                                startIcon={<Share />}
                            >
                                <Typography>{t("actions.share")}</Typography>
                            </Button>
                            <Tooltip title="Bookmark" sx={{ ml: "15px" }}>
                                <IconButton>
                                    <BookmarkAddOutlined
                                        sx={{ width: "25px", height: "25px" }}
                                    />
                                </IconButton>
                            </Tooltip>
                        </div>
                    </Box>
                </Box>
            </Box>
            <Card
                sx={{
                    zIndex: 100,
                    position: "sticky",
                    top: { xs: "58px", sm: "63px", md: "130px" },
                }}
            >
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="tabs example"
                    TabIndicatorProps={{ sx: { display: "none" } }}
                    centered
                    sx={{
                        "& .MuiTab-root": { minHeight: "47px" },
                        "& .Mui-selected": {
                            borderBottom: "2px solid blueviolet",
                        },
                        alignItems: "center",
                        "& .MuiTabs-flexContainer": {
                            display: "flex",
                            justifyContent: "start",
                        },
                    }}
                >
                    {tabInfo.map((tab, index) => (
                        <Tab
                            sx={{
                                fontWeight: value === index ? 600 : 500,
                                color: "black",
                                gap: 1,
                                // fontSize: value === index ? { xs: "18px !important", sm: 20, md: '20px !important' } : { xs: "12px !important", sm: '13px', md: '15px !important' }
                            }}
                            key={index}
                            label={tab.label}
                        />
                    ))}
                </Tabs>
            </Card>
            <Box sx={{ border: "1px solid #E7E7E7", p: 3 }}>
                {tabInfo.map((tab, index) => (
                    <Box key={index} hidden={value !== index}>
                        {tab.content}
                    </Box>
                ))}
            </Box>

            <div>
                <Dialog
                    open={modal}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{ display: "flex", alignItems: "center", gap: 10 }}
                    >
                        <Typography sx={{ fontWeight: "bold" }}>
                            {" "}
                            {t("profileTabs.wanttounfollow")}
                        </Typography>
                        <Close sx={{ cursor: "pointer" }} onClick={handleClose} />
                    </DialogTitle>
                    <Divider />
                    <DialogActions>
                        <Button onClick={handleClose} sx={{ color: "black" }}>
                            {t("cancel")}
                        </Button>
                        <Button
                            sx={{ bgcolor: "blueviolet" }}
                            onClick={handleUnFollowDoc}
                            autoFocus
                            variant="contained"
                        >
                            {t("unfollow")}
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        </Box>
    );
}

export default DoctorsProfile;
