import React, { useEffect, useState } from 'react'
import { ArrowForward, CelebrationOutlined } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Typography } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import URL from '../../../../utility/apiurl'
import { localstore } from '../../../../localStore/localStore'
import axios from 'axios'
import { useTranslation } from "react-i18next";
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'

function VerifyShowPhr(props) {

    const token = localstore.getToken()
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch()
    const primaryId = localstore.getPrimaryId()
    const [phraddress, setPhraddress] = useState('');
    console.log(phraddress, "phrrrrrrr")
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const [t, i18n] = useTranslation("global");

    const [phdState, setPhdState] = useState(null);
    const abhadata = useSelector(state => state.abha.abhaprofile)
    // useEffect(() => {

    //     if (ibm?.identifier) {
    //         let phd = ibm.identifier?.filter(e => {
    //             // if (e?.type?.coding[0]?.code === 'ACSN') {
    //             if (e?.type?.coding[0]?.code === 'ABHA') {
    //                 return e.value;
    //             }
    //         });
    //         setPhdState(phd);
    //         setPhraddress(phd?.length > 0 ? phd[0].value : '');

    //     }

    // }, []);

    const handleloginPhr = () => {
        setLoading(true)
        if (abhadata.AbhaAddress.length > 0) {
            setLoading(true)
            axios.get(`${URL.abha}login/search/authmodes/${primaryId}/${abhadata.AbhaAddress}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    gatewayToken: 'medxperts'
                }
            }).then(res => {
                console.log(res, "authmethods")
                // setAlertMessage("")
                const updatedAuthMethods = res.data.authMethods.filter(method => method !== "AADHAAR_BIO" && method !== "DEMOGRAPHICS");

                dispatch({
                    type: "ABHALINK_AUTH_METHODS",
                    payload: updatedAuthMethods
                })
                dispatch({
                    type: "ABHALINK_PHRADDRESS",
                    payload: res.data.phrAddress
                })

                props.setShow(4)
            }).catch(err => {
                setAlertMessage(err.message)
                setAlertstatus("error")
            }).finally(() => {
                setLoading(false)
            })
        }

    };

    return (
        <Box>
            <Typography sx={{ fontSize: '17px', fontWeight: 600, mt: { xs: 3, sm: 2, md: '15px' } }}>PHR Address</Typography>
            <Box sx={{ p: 2, mt: 2, textAlign: "center" }}>
                <Box>
                    <Typography>{t("yourabhaorphr")}</Typography>
                    <Typography
                        sx={{ mt: 1, fontSize: { sm: 18, md: 20 }, fontWeight: 600 }}
                    >
                        {phraddress}
                    </Typography>

                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )}
                    <Button
                        sx={{ mt: 3, fontWeight: "bold", width: "120px", borderRadius: 2 }}
                        variant="contained"
                        onClick={handleloginPhr}
                        endIcon={
                            loading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <ArrowForward sx={{ fontWeight: "bold" }} />
                            )
                        }
                    >
                        {t("credentialsData.commondata.login")}
                    </Button>
                </Box>
            </Box>
        </Box>

    );
}

export default VerifyShowPhr