import * as React from 'react';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { TextField, Typography } from '@mui/material';
import { ArrowBackIos, Search } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function HealthProblems() {
    const [age, setAge] = React.useState('');
    const navigate = useNavigate()
    const handleChange = (event) => {
        setAge(event.target.value);
    };

    return (
        <Box sx={{ m: 1, mt: { xs: 0, sm: 0, md: "20px" }, p: { xs: 1, sm: 1, md: 0 } }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <ArrowBackIos />
                <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Common Health Problems</Typography>
            </Box>
            <Box sx={{ mt: 2, display: 'flex' }}>
                <FormControl
                    fullWidth
                    sx={{
                        ".MuiInputBase-root": {
                            borderRadius: "15px 0px 0px 15px",
                            borderRight: "none",
                        },
                        width: '300px'
                    }}
                    size='small'
                >
                    <InputLabel id="demo-simple-select-label">Conditions</InputLabel>

                    <Select
                        // labelId="demo-simple-select-label"
                        // id="demo-simple-select"
                        label="conditions"
                        value={age}
                        onChange={handleChange}
                    >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    sx={{
                        ".MuiInputBase-root": {
                            borderRadius: '0px 15px 15px 0px',
                        },
                    }}
                    size='small'
                    fullWidth
                    placeholder='Search Diseases/ conditions'
                    InputProps={{ endAdornment: <Search /> }} />
            </Box>

            <Box sx={{ m: 2, cursor: 'pointer' }} onClick={() => navigate('/heartfailure')} >
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Abdominal aortic aneurysm</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}>  Abdominal aortic aneurysm</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Acute coronary syndrome (overview)</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Acute congestive heart failure</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Acute heart failure</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}>Acute heart failure</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Aneurysm, abdominal aorta</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Aneurysm, abdominal aorta</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Angina, chronic stable</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}>Angina, unstable</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}>Angina, unstable</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}>Aortic arch syndrome</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}>Aneurysm, abdominal aorta</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Angina, chronic stable</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Angina, unstable</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}>Angina, unstable</Typography>
                <Typography sx={{ fontSize: '14px', mt: 1 }}> Aortic arch syndrome</Typography>
            </Box>
        </Box >
    );
}

export default HealthProblems;