import React from 'react'
import { Box, Button, Typography } from '@mui/material'

export const ErrorStatusMsg = (props) => {
    return (
        <Box>
            <Typography
                sx={{
                    color: props.alertstatus === "success" ? "green" : "red",
                    mt: 1, fontSize: "14px", textAlign: props.textAlign
                }}
            >
                {props.alertmessage}
            </Typography>
        </Box>
    )
}

function ResendOtp(props) {
    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "0px 10px", mt: "5px" }}>
                <Typography sx={{ fontSize: "12px" }}>{`00:${props.time} `}</Typography>
                <Button disabled={props.disabled} sx={{ textTransform: "capitalize" }}>
                    <Typography
                        sx={{ fontSize: { xs: 10, md: 12 } }}
                        onClick={props.onClick}
                    >
                        {props.text}
                    </Typography>
                </Button>
            </Box>
        </div>
    )
}

export default ResendOtp