import React, { useState } from 'react'
import CommonDialog from '../../../atoms/CommonDialog'
import { Box, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Paper, Typography } from '@mui/material'
import { AddCircleOutlineOutlined, Close, DonutSmall, DownloadDoneOutlined, SkipNext } from '@mui/icons-material'
import CommonButton, { OutlinedButton } from '../../../atoms/CommonButton'

const AltaceData = (props) => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 2 }}>
            <Typography sx={{ mt: '5px' }}>{props.title}</Typography>
            <Typography sx={{ fontWeight: 500, mt: '5px' }}>{props.answer}</Typography>
        </Box >
    )
}
function LogIntakeDialog() {
    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    return (
        <>
            <Paper sx={{ p: "10px 20px", borderRadius: '10px', mt: 2, cursor: 'pointer' }} onClick={() => setOpen(true)}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <AddCircleOutlineOutlined />
                    <Typography sx={{ fontWeight: 500 }}>Log Intake</Typography>
                </Box>
            </Paper>
            <CommonDialog open={open} onClose={handleClose}>
                <DialogTitle sx={{
                    fontWeight: 500, p: '5px 15px', fontSize: '17px', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                }}>
                    Medication Intake Log
                    <IconButton onClick={handleClose}>< Close sx={{ color: 'red' }} /></IconButton>
                </DialogTitle >
                <Divider />
                <DialogContent >
                    <Typography sx={{ fontWeight: 500 }}>
                        ALTACE 2.5
                    </Typography>
                    <Grid container mt={1}>
                        <Grid item md={2}>
                            <Box sx={{ mt: 2, width: 60, height: 60, bgcolor: "#2E3655", display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px' }}>
                                <DonutSmall sx={{ color: 'white', fontSize: 30 }} />
                            </Box>
                        </Grid>
                        <Grid item md={10}>
                            <AltaceData title="Quantity Taken" answer="1 Tablet" />
                            <Divider sx={{ mt: "5px" }} />
                            <AltaceData title="Date of Dose" answer="15 Jan 2023" />
                            <Divider sx={{ mt: "5px" }} />
                            <AltaceData title="Time taken at" answer="08:45 PM" />
                            <Divider sx={{ mt: "5px" }} />
                        </Grid>
                    </Grid>


                </DialogContent>
                <DialogActions>
                    <OutlinedButton text="Skipped" width="120px" borderColor="#FF0000" color="#FF0000" startIcon={<SkipNext sx={{ color: '#FF0000' }} />} />
                    <CommonButton text="Taken" width="120px" bgcolor="#0E0058" startIcon={<DownloadDoneOutlined />} />
                </DialogActions>
            </CommonDialog >
        </>
    )
}

export default LogIntakeDialog