import React, { useState } from 'react'
import { Box, Grid, IconButton, Paper, Typography } from '@mui/material'
import { AddCircle, AddCircleOutlineOutlined, ArrowBackIos, ArrowForwardIos, CalendarMonth, Close, DonutSmall, ErrorOutlineOutlined, ExpandMore, HistoryOutlined, Schedule, ShoppingCartOutlined, Update, Vaccines } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import CommonButton from '../../../atoms/CommonButton'
import LineChart from '../../../atoms/LineChart'
import prescriptionIcon from '../../../../assets/home/records_module/prescription.svg'
import medicationIcon from '../../../../assets-new/carekit-icons/medicines.svg'
import LogIntakeDialog from './LogIntake'

const ListCards = (props) => {
    return (
        <Paper elevation={4} sx={{ p: "8px 15px", mt: 2, borderRadius: '10px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Vaccines />
                    <Typography sx={{ fontWeight: 500 }}>{props.title}</Typography>
                </Box>
                <ExpandMore />
            </Box>
        </Paper>
    )
}
const MedicationCard = (props) => {
    return (
        <Paper elevation={3} sx={{ borderRadius: '10px', mt: 2, cursor: 'pointer' }} onClick={props.onClick}>
            <Grid container>
                <Grid item md={1} sx={{ bgcolor: props.bgcolor, height: 90, borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '90' }}>
                    {/* <Box sx={{  }}> */}
                    {/* <Box sx={{ width: 30, height: 30, borderRadius: '20px', border: '1px solid white' }}>
                        
                    </Box> */}
                    {/* </Box> */}
                    <DonutSmall sx={{ color: 'white', fontSize: 30 }} />
                </Grid>
                <Grid item xs={1} sm={0.5} md={0.5} >
                </Grid>
                <Grid item xs={8.9} sm={9.2} md={9.2} p={1}>
                    <Box sx={{ display: 'flex', gap: '5px', alignItems: 'center', mt: '5px' }}>
                        <CalendarMonth />
                        <Typography sx={{ fontSize: '14px' }}>{props.day}</Typography>
                    </Box>
                    <Typography sx={{ fontWeight: 500, mt: '5px' }}>{props.title}</Typography>
                    <Typography sx={{ fontSize: '14px', mt: '5px' }}>{props.time}</Typography>
                </Grid>
                <Grid item xs={1.1} md={1.1}>
                    <Typography sx={{ fontSize: '14px', mt: '5px' }}>{props.type}</Typography>
                    <Box sx={{ mt: '10px' }}>
                        <IconButton sx={{ bgcolor: '#F5F6FD' }}><ArrowForwardIos /></IconButton>
                    </Box>
                </Grid>
            </Grid>
        </Paper>

    )
}

const DayMenuCard = (props) => {
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Box sx={{ mt: '5px' }}>{props.icon}</Box>
            <Typography>{props.title}</Typography>
        </Box>
    )
}
function Medication() {
    const navigate = useNavigate()
    const [expand, setExpand] = useState(true)
    const [modal, setModal] = useState(false)
    return (
        <div>
            <Box sx={{ m: 1, mt: { xs: 1, md: 0 } }}>
                {
                    expand ?
                        <>
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                    <img src={medicationIcon} alt="medicine" />
                                    <Typography sx={{ fontWeight: 600, fontSize: 17 }}>Medication use timeline</Typography>
                                </Box>
                                <IconButton onClick={() => navigate(-1)}>  <Close sx={{ color: 'red' }} /></IconButton>
                            </Box>
                            <MedicationCard day='Every day' bgcolor="#2E3655" title="ALTACE 2.5mg" type="Active" time="Take 1 tablet 9:00 am - 1:00 pm - 9:00 pm" onClick={() => setExpand(false)} />
                            <MedicationCard day='As needed' bgcolor="#2E3655" title="DOLO 650" type="Active" time="Take 1 tablet Max 5 per day" />
                            <MedicationCard day='Every day' bgcolor="#00000029" title="A2Z MULTIVITAMIN" type="Completed" time="Take 1 capsule Every 24 hours" />
                            <MedicationCard day='Every day' bgcolor="#F0F2F8" title="A2Z MULTIVITAMIN" type="Active" time="Take 5 ml Twice a day" />
                        </> :
                        <Box>
                            <Paper sx={{ p: "10px 20px" }}>
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <ArrowBackIos sx={{ cursor: 'pointer' }} onClick={() => setExpand(true)} />  <Typography sx={{ fontWeight: 500 }}>ALTACE 2.5</Typography>
                                </Box>
                                <Box sx={{ mt: 2, display: 'flex', alignItems: 'center', gap: 3, ml: 4 }}>
                                    <Box sx={{ width: 70, height: 70, bgcolor: "#2E3655", display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '10px' }}>
                                        <DonutSmall sx={{ color: 'white', fontSize: 30 }} />
                                    </Box>
                                    <Box>
                                        <DayMenuCard icon={<Schedule />} title="Take 1 tablet Orally" />
                                        <DayMenuCard icon={<Schedule />} title="9:00 am - 1:00 pm - 9:00 pm" />
                                        <DayMenuCard icon={<CalendarMonth />} title="Every day" />
                                        <DayMenuCard icon={<Update />} title="Use for 30 days" />
                                    </Box>
                                </Box>
                            </Paper>
                            <LineChart />
                            {/* <Paper sx={{ p: "10px 20px", borderRadius: '10px', mt: 2, cursor: 'pointer' }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <AddCircleOutlineOutlined />
                                    <Typography sx={{ fontWeight: 500 }}>Log Intake</Typography>
                                </Box>
                            </Paper> */}
                            <LogIntakeDialog />

                            <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontWeight: 500 }}> Additional Instructions</Typography>
                                <CommonButton text="Add to cart" bgcolor="black" color="white" width="170px" height="30px" startIcon={<ShoppingCartOutlined />} />
                            </Box>

                            <Paper elevation={3} sx={{ p: '10px 20px', mt: 2 }}>
                                <Box sx={{ display: 'flex', gap: 3 }}>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        <Vaccines />
                                        <Typography sx={{ mt: 1, fontSize: '14px' }}>Before food</Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        <Vaccines />
                                        <Typography sx={{ mt: 1, fontSize: '14px' }}>Avoid Alcohol</Typography>
                                    </Box>
                                </Box>
                                <Typography sx={{ fontSize: '14px', mt: '10px' }}>*Note :</Typography>
                            </Paper>
                            <Typography sx={{ fontWeight: 500, mt: 1 }}>Prescritption Details</Typography>
                            <Paper elevation={3} sx={{ mt: 1, p: '10px 20px', borderRadius: '10px' }}>
                                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <Typography sx={{ fontSize: '14px' }}>12-Jan-2023 | 3.00 PM</Typography>
                                    <Typography sx={{ display: 'flex', gap: 1, fontSize: '14px', color: 'red' }}><ErrorOutlineOutlined sx={{ fontSize: '20px' }} />Report Issue</Typography>
                                </Box>
                                <Box sx={{ mt: '5px' }}>
                                    <Typography sx={{ fontSize: '14px' }}>Reason : <Typography component={'span'} sx={{ fontWeight: 500, color: '#06239D' }}>Hypertension</Typography></Typography>
                                    <Typography sx={{ fontSize: '14px', mt: '5px' }}>Dispense quantity : <Typography component={'span'} sx={{ fontWeight: 500 }}>90 tablets</Typography></Typography>
                                    <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: '5px' }}>
                                        <Typography sx={{ fontSize: '14px' }}>Rills Allowed : <Typography component={'span'} sx={{ fontWeight: 500 }}>01/01</Typography></Typography>
                                        <Typography sx={{ fontSize: '14px' }}>Max Refill Qty : <Typography component={'span'} sx={{ fontWeight: 500 }}>10 tablets</Typography></Typography>
                                    </Box>

                                    <Grid container sx={{ border: '1px solid #DCDCDC', mt: 2, p: '7px', borderRadius: '10px' }}>
                                        <Grid item xs={0.7} md={0.7} mt={1}>
                                            <img src={prescriptionIcon} alt="prescription" />
                                        </Grid>
                                        <Grid item xs={6.5} md={7.5}>
                                            <Typography sx={{ fontSize: '14px' }}>
                                                Encounter ID
                                            </Typography>
                                            <Typography sx={{ fontWeight: 500 }}>
                                                Dr Venkat Reddy K
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={4.3} md={3.3}>
                                            <Typography sx={{ fontSize: '14px' }}>
                                                12-Mar-2022 | 3:00 PM
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={0.5} md={0.5} mt={1}>
                                            <ArrowForwardIos />
                                        </Grid>
                                    </Grid>
                                    <Typography sx={{ fontWeight: 500, display: 'flex', gap: 1, mt: 1 }}><HistoryOutlined /> History</Typography>
                                </Box>
                            </Paper>
                            <Box sx={{ mt: 1 }}>
                                <Typography sx={{ fontWeight: 500, display: 'flex', gap: 1 }}><Vaccines />About this Medication</Typography>
                                <Paper elevation={3} sx={{ p: '10px 20px', mt: 1, borderRadius: '10px' }}>
                                    <Typography sx={{ fontSize: '14px' }}>Contains : <Typography component={'span'} sx={{ fontWeight: 500, color: '#06239D' }}>Ramipril (2.5 mg)</Typography></Typography>
                                    <Typography sx={{ fontSize: '14px' }}>Forms : <Typography component={'span'} sx={{ fontWeight: 500, color: '#06239D', mt: '5px' }}>Tablet</Typography></Typography>
                                    <Typography sx={{ fontSize: '14px' }}>Doses per package : <Typography component={'span'} sx={{ fontWeight: 500, color: '#06239D', mt: '5px' }}>10 tablets</Typography></Typography>
                                    <Typography sx={{ fontSize: '14px' }}>Manufacturer : <Typography component={'span'} sx={{ fontWeight: 500, color: '#06239D', mt: '5px' }}>Pfizer</Typography></Typography>
                                </Paper>
                            </Box>
                            <Paper sx={{ p: "10px 20px", borderRadius: '10px', mt: 2 }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    <AddCircleOutlineOutlined />
                                    <Typography sx={{ fontWeight: 500 }}>Package information of purchase</Typography>
                                </Box>
                            </Paper>
                            <ListCards title="What is Ramipril" />
                            <ListCards title="Dosage & Administration" />
                            <ListCards title="Warnings & Precautions" />
                        </Box>

                }
            </Box>
        </div >
    )
}

export default Medication