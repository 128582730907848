import React, { useEffect } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import { useState } from 'react';
import ActiveRequestsIcon from '../../../../assets/home/consent_module/activeRequest.svg'
import ActiveRequestsblackIcon from '../../../../assets/home/consent_module/activeRequestblack.svg'
import ConsentHistoryIcon from '../../../../assets/home/consent_module/consent-history.svg'
import ConsentHistoryblueIcon from '../../../../assets/home/consent_module/consent-history -blue.svg'
import ConsentIcon from '../../../../assets/home/consent_module/consentblue.svg'
import searchIcon from '../../../../assets-new/services-icons/search-Icon-black.svg'
import { useTranslation } from "react-i18next";
import ActiveRequests from './ActiveRequests';
import ConsentHistory from './ConsentHistory';
import SearchBar from '../../../atoms/Searchbar';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import { getConsents, getConsentsrequested } from '../../../../redux/Actions/consentActions';

function Consents() {
    const [value, setValue] = useState(0);
    const [t, i18n] = useTranslation("global");
    const [selectTab, setSelectTab] = useState('Active Requests')
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const abhadata = useSelector(state => state.abha.abhaprofile)
    const dispatch = useDispatch()

    const data = [
        { icon: ActiveRequestsIcon, label: 'Active Requests' },
        { icon: ConsentHistoryIcon, label: 'Consent History' },
    ];
    const handleTabChange = (newValue) => {
        setSelectTab(newValue);
    };
    useEffect(() => {

        const fetchData = async () => {
            await dispatch(getConsents(primaryId, abhadata?.AbhaAddress, 'ALL', token));
            await dispatch(getConsentsrequested(primaryId, abhadata?.AbhaAddress, 'REQUESTED', token));
            // await dispatch(getConsentGranted(primaryId, abhadata?.AbhaAddress, 'GRANTED', token));
            // await dispatch(getConsentDenied(primaryId, abhadata?.AbhaAddress, 'DENIED', token));
            // await dispatch(getConsentRevoked(primaryId, abhadata?.AbhaAddress, 'REVOKED', token));
            // await dispatch(getConsentExpired(primaryId, abhadata?.AbhaAddress, 'EXPIRED', token));
        };
        if (primaryId && abhadata?.AbhaAddress && token) {
            fetchData();
        }
    }, [primaryId, abhadata?.AbhaAddress, token]);
    const allData = useSelector(state => state.consent.consentdata)
    const requestData = useSelector(state => state.consent.Resquesters)
    return (
        <Box sx={{ mt: { md: "20px", xs: 9, sm: 11 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 300px' }, pt: { xs: '0px', sm: "0px", md: '50px' } }}>
            <Grid container>
                <Grid item xs={5} columnSpacing={3}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <img src={ConsentIcon} alt="consent" width={25} height={25} />
                        <Typography sx={{ fontWeight: 500, fontSize: { xs: 14, sm: '17px', md: '17px' }, color: '#247FEA' }}>Consent Management</Typography>
                    </Box>
                </Grid>
                <Grid item xs={7}>
                    <SearchBar width={{ sm: "450px", md: '450px' }} icon={searchIcon} placeholder="Search Consents by facility or Health-locker" />
                </Grid>
            </Grid>

            <Box m={2} sx={{ display: 'flex' }}>
                {data.map((item, index) => (
                    <Box
                        key={index}
                        m={2}
                        sx={{
                            display: 'flex',
                            gap: 1,
                            boxShadow: selectTab === item.label ? 3 : 'none',
                            p: 1,
                            color: selectTab === item.label ? "#247FEA" : "black",
                            borderRadius: '20px',
                            cursor: 'pointer',
                        }}
                        onClick={() => handleTabChange(item.label)}
                    >
                        <img src={item.icon} alt={item.label.toLowerCase()} />
                        <Typography>{item.label}</Typography>
                    </Box>
                ))}
            </Box>

            <Box >
                {selectTab === 'Active Requests' && <ActiveRequests requestData={requestData} />}
                {selectTab === 'Consent History' && <ConsentHistory allData={allData} />}
            </Box>
        </Box >
    );
}

export default Consents