import { Box, Button, Typography } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react'
import Poll from "react-polls";
import URL from '../../../../../../utility/apiurl';
import { localstore } from '../../../../../../localStore/localStore';

const metadata = async () => {
    const userId = localstore.getUserId
    return {
        dateTime: new Date(),
        location: await getLoc(),
        author: Number(userId),
        versionId: 0,
        securityCodes: null,
    };
};

const getLoc = async () => {
    const position = await getCurrentPosition();
    const loc = position.coords
        ? `${position.coords.latitude},${position.coords.longitude}`
        : null;

    return loc;
};

function getCurrentPosition() {
    return new Promise((resolve, reject) => {
        const Location = window.navigator && window.navigator.geolocation;
        if (Location) {
            Location.getCurrentPosition(
                (position) => {
                    resolve(position);
                },
                (error) => {
                    resolve({});
                }
            );
        }
    });
}

function expiryFun(expiryDate) {
    // The number of milliseconds in one day
    const ONE_DAY = 1000 * 60 * 60 * 24;
    // Calculate the difference in milliseconds
    const differenceMs = new Date(expiryDate) - new Date();
    // Convert back to days and return
    const days = Math.round(differenceMs / ONE_DAY);

    return days > 0
        ? days > 1
            ? `Expires in ${days} days`
            : `Expires in ${days} day`
        : days === -0
            ? "Expires Today"
            : "Expired";
}

export const getPostByIdFn = async (id) => {
    const token = localstore.getToken();
    return await axios
        .get(
            `${URL.postById}/${id}`,
            //  header()
            {
                headers: { Authorization: `Bearer ${token}` },
                // headers: { Docsteth: `Bearer ${token}` },
            }
        )
        .then((res) => {
            console.log(res, "response");
            return res.data;
        })
        .catch((err) => {
            return { error: true };
        });
};



const PollPost = ({ docFeed }) => {
    const [polldata, setPolldata] = useState(docFeed);
    // console.log(polldata, "polldata");

    const pollStyles1 = {
        questionSeparator: false,
        questionSeparatorWidth: "question",
        questionBold: false,
        questionColor: "#4F70D6",
        align: "left",
        theme: "blue",
    };

    const [pollAnswers1, setPollAnswers1] = useState(
        polldata?.pollData?.pollResponseData
    );
    // console.log(polldata, "vvvvvv");
    // console.log(pollAnswers1, "pollAnswers1");
    const [load, setLoad] = useState(false);

    const token = localstore.getToken();

    const handleVote = async (voteAnswer, pollAnswers, pollNumber) => {
        console.log(pollAnswers, "pollAnswers");
        console.log(voteAnswer, "voteAnswer");
        const newPollAnswers = pollAnswers.map((answer) => {
            console.log(answer);
            let ans = { ...answer };
            if (ans.option === voteAnswer) {
                ans["votes"] = ans.votes + 1;
            }
            console.log(ans, "ans");
            return ans;
        });

        // console.log(newPollAnswers);

        setPollAnswers1(newPollAnswers);

        let data = {
            metadata: await metadata(),
            postId: polldata.id,
            questionId: polldata.pollData.id,
            response: voteAnswer,
            userId: sessionStorage.getItem("userId"),
            postId: polldata.id,
        };

        axios
            .post(`${URL.addpoll}`, data, {
                headers: { Authorization: `Bearer ${token}` },
                // headers: { Docsteth: `Bearer ${token}` },
            })
            .then((res) => {
                // console.log(res, "polling");
                if (res.status === 200 || 201) {
                    setLoad(true);
                }
                //  getpolldata()
            })
            .catch((err) => console.log(err));
    };

    const handleresponse = () => {
        let id = polldata.pollData.pollId;
        // let id = polldata.pollData.id;

        console.log(id, "idd");
        setLoad(false);
        axios
            .delete(
                `${URL.deleteresponse}/${id}`,
                //  header()
                {
                    headers: { Authorization: `Bearer ${token}` },
                    // headers: { Docsteth: `Bearer ${token}` },
                }
            )
            .then((res) => {
                getpolldata();
            });
    };

    const getpolldata = async () => {
        let data = await getPostByIdFn(polldata.id);
        console.log(data, "seedata");
        if (data.id) {
            setPolldata(data);
            setPollAnswers1(data.pollData.pollResponseData);
            setLoad(true);
        } else if (data.error) setLoad(true);
    };

    return (
        <Box sx={{ p: "10px" }}>
            <Box
                sx={{
                    p: "10px",
                    borderRadius: "10px",
                    border: "1px solid gray",
                    //   marginTop: "10px",
                }}
            >
                <Box>
                    <Typography sx={{ display: "flex", justifyContent: "flex-end" }}>
                        {" "}
                        {expiryFun(polldata.pollData.expiry)}
                    </Typography>
                    {polldata?.pollData?.question ||
                        polldata?.pollData?.question.length > 0 ? (
                        <Poll
                            question={polldata.pollData?.question}
                            answers={pollAnswers1}
                            // onVote={(voteAnswer) => handleVote(voteAnswer, pollAnswers1, 1)}
                            onVote={(voteAnswer) => handleVote(voteAnswer, pollAnswers1, 1)}
                            customStyles={pollStyles1}
                            noStorage={true}
                            vote={polldata?.pollData?.response}
                        // vote={polldata?.pollData?.response}
                        />
                    ) : (
                        <Typography></Typography>
                    )}

                    {(!load || polldata.pollData.pollAnswered === true) && (
                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                            {expiryFun(polldata.pollData.expiry) !== "Expired" ? (
                                <Button onClick={handleresponse} className="undo_btn">
                                    undo
                                </Button>
                            ) : (
                                ""
                            )}
                        </div>
                    )}
                </Box>
            </Box>
        </Box>
    );
}

export default PollPost;