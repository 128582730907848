import * as React from 'react';
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material'
import EditProfileCard from '../../../atoms/EditProfileCard';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import axios from 'axios';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useState } from 'react';
import PhonenumUpdate1 from './PhonenumUpdate1';
import { useEffect } from 'react';
import { Close } from '@mui/icons-material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function PhonenumUpdate({ mobiledata }) {
    const [open, setOpen] = useState(false);
    const [mobilenum, setMobilenum] = useState('')
    const [sessionId, setSessionId] = useState('')
    const [error, setError] = useState(false);
    const [show, setShow] = useState(1)
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const handleOnChangeMobile = (e) => {

        const inputValue = e.target.value || "";
        const numericValue = inputValue.replace(/\D/g, "");

        if (/[^0-9]/.test(inputValue)) {
            setError(true);
        } else {
            setError(false);
            setMobilenum(numericValue.slice(0, 10));
        }
        setAlertmessage('')
    }

    useEffect(() => {
        setMobilenum(mobiledata?.mobile)
    }, [mobiledata?.mobile])

    const handleSubmit = async () => {
        // setShow(2)
        setLoading(true)
        const data = {
            'authMode': 'MOBILE_OTP',
            'mobile': mobilenum
        }
        await axios.post(`${URL.consents}abha/profile/update-mobileEmail/init/${primaryId}`, data, {
            headers: { 'Authorization': `Bearer ${token}` }
        }).then(res => {

            setSessionId(res?.data?.sessionId)
            setAlertmessage("sent otp successfully")
            setAlertstatus('success')
            setShow(2)
        }).catch((err) => {
            setAlertmessage("please try with different 'Mobile Number'")
            setAlertstatus('error')
        }).finally(() => {
            setLoading(false)
        })
    }
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    const handleClosedone = () => {
        handleClose()
        setAlertmessage('')
    }
    return (
        <React.Fragment>
            <EditProfileCard title="Phone Number" displaydata={mobiledata.mobile ? mobiledata.mobile : "N/A"} action="Update" onClick={handleClickOpen} />
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                fullWidth
                maxWidth='sm'
            >
                {show === 1 ? (
                    <>
                        <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', p: '5px 20px', alignItems: 'center' }}>
                            <Typography sx={{ fontSize: { xs: '15px', sm: '18px', md: '18px' }, fontWeight: 600, textAlign: 'center' }}>Update New Mobile Number</Typography>
                            <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>
                        </DialogTitle>
                        <Divider />
                        <DialogContent>

                            <Grid container sx={{ textAlign: 'center', mt: 2 }}>
                                <Grid item xs={12} >
                                    <TextInputField width={{ sm: "350px", md: "350px" }} placeholder=" Enter New Mobile Number" name="mobilenumber" value={mobilenum} onChange={handleOnChangeMobile} />
                                </Grid>
                                {error && (
                                    <Typography sx={{ fontSize: '13px', color: 'red', mt: 1 }}>
                                        Only numbers are allowed.
                                    </Typography>
                                )}
                            </Grid>
                        </DialogContent>
                        {alertmessage && (
                            <Typography
                                sx={{
                                    color: alertstatus === "success" ? "green" : "red",
                                    mt: 1, fontSize: '13px', textAlign: 'center'
                                }}
                            >
                                {alertmessage}
                            </Typography>
                        )}
                        <DialogActions sx={{ justifyContent: 'center', m: 0 }}>
                            <Button variant='contained' sx={{ width: '120px', borderRadius: '10px' }} startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} onClick={handleSubmit}>Submit</Button>
                        </DialogActions>
                    </>
                ) : show === 2 ? (
                    <PhonenumUpdate1 handleClose={handleClose} setShow={setShow} primaryId={primaryId} token={token} sessionId={sessionId} />
                ) : null}
            </Dialog>
        </React.Fragment >
    );
}
export default PhonenumUpdate;