import axios from "axios";
import URL from "../../utility/apiurl";

export const findValidUsername = (patientname) => {
    let data = {
        'userName': patientname,
    };
    return (dispatch) => {
        axios.post(`${URL.findPatients}`, data)
            .then(res => {
                dispatch({
                    type: 'FINDVALIDUSERNAME',
                    payload: res.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'FINDVALIDUSERNAME',
                    payload: '',
                });
            });
    };
};

export const addPatient = (data) => {
    return (dispatch) => {
        axios.post(`${URL.addPatient}`, data)
            .then(res => {
                console.log(res, "addpateint")
                dispatch({
                    type: 'ADDPATIENT',
                    payload: res.data,
                });
            }).catch(err => {
                console.log(err.message, 'addpatient');
                dispatch({
                    type: 'ADDPATIENT',
                    payload: [],
                });
            });
    };
};

export const getPatientdatafromibm = (system, value, token) => {
    // console.log(system, "ssvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv");
    // console.log(value, "valueeeeeeeeee");
    // console.log(token, "tokennnnnnnnnnnn");

    return (dispatch) => {
        axios.get(`${URL.cliniapi}${system}/${value}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                // console.log(res, "-------->>>RES3")
                // console.log(res.data, "12345678909876543")
                dispatch({
                    type: 'IBMPDATA',
                    payload: res.data,
                });
            })
            .catch(err => {
                console.log(err.message, 'ibmapi');
                dispatch({
                    type: 'IBMPDATA',
                    payload: [],
                });
            });
    };


};

export const getStates = (token) => {
    // console.log('---------------------->>>>>>>>>>>>>>>getStates')
    return dispatch => {
        axios
            .get(`${URL.abha}listOfStates`, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`

                },
            })
            .then(res => {
                // console.log(res.data, 'sres')
                dispatch({
                    type: 'GETSTATES',
                    payload: res.data,
                });
            })
            .catch(err => {
                console.log(err.message, 'states');
                dispatch({
                    type: 'GETSTATES',
                    payload: [],
                });
            });
    };
};
export const getDistricts = (code, token) => {
    // console.log('---------------------->>>>>>>>>>>>>>>getDistricts')

    return dispatch => {
        code
            ? axios
                .get(`${URL.abha}listOfDistricts/${code}`, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`

                    },
                })
                .then(res => {
                    dispatch({
                        type: 'GETDISTRICTS',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    console.log(err.message, 'states');
                    dispatch({
                        type: 'GETDISTRICTS',
                        payload: [],
                    });
                })
            : dispatch({
                type: 'GETDISTRICTS',
                payload: [],
            });
    };
};

export const getAbhaProfile = (ibmid, token) => {
    // console.log(ibmid, "ibmiiddd")
    // console.log(token, "tokennnnn")
    if (ibmid && token) {
        return dispatch => {
            axios
                .get(`${URL.abha}account/profile/${ibmid}`, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`
                    },
                })
                .then(res => {
                    // console.log('abhaprofile success', res);
                    dispatch({
                        type: 'ABHAPROFILE',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    // console.log(err.message, 'abhaprofile');
                    dispatch({
                        type: 'ABHAPROFILE',
                        payload: [],
                    });
                });
        };
    } else {
        return dispatch => {
            dispatch({
                type: 'ABHAPROFILE',
                payload: [],
            });
        };
    }
};

export const getAbhaMobile = (ibmid, token) => {
    if (ibmid && token) {
        return dispatch => {
            axios
                .get(`${URL.abha}mobile/profile/${ibmid}`, {
                    headers: {
                        // 'X-AUTH-TOKEN': mobiletoken,
                        Authorization: `Bearer ${token}`
                    },
                })
                .then(res => {
                    // console.log(res, "mobileprofile")
                    dispatch({
                        type: 'MOBILEPROFILE',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    console.log(err.message, 'mobile');
                    dispatch({
                        type: 'MOBILEPROFILE',
                        payload: [],
                    });
                });
        };
    } else {
        return dispatch => {
            dispatch({
                type: 'MOBILEPROFILE',
                payload: [],
            });
        };
    }
};

export const getAbhaCard = (token, primaryid) => {
    // console.log(token, primaryid, 'Token, AbhaAddress');
    return dispatch => {
        if (token && primaryid) {
            axios
                .get(`${URL.abha}download/profile/${primaryid}`, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,

                    },
                })
                .then(response => {
                    dispatch({
                        type: 'ABHACARD',
                        payload: response.data
                    });
                })
                .catch(error => console.log(error, '==============>>> ABHACARD1'));
        }
    };
};
export const getAbhaQRCode = (token, pid, AbhaAddress, primaryid) => {
    // console.log(primaryid, ' getabha');
    return dispatch => {
        if (pid && AbhaAddress && token && primaryid) {
            axios
                .get(`${URL.abhaQRCode}/${primaryid}/${pid}/${AbhaAddress}`, {
                    headers: {
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(response => {
                    // console.log('------>>>> ResponseQRCODE', response);
                    dispatch({
                        type: 'ABHAQRCODE',
                        payload: response.data,
                    });
                })
                .catch(error => console.log(error, '==============>>> QRCODE'));
        }
    };
};

export const addPatienttoIbm = (data, token) => {
    // console.log("--------->>>Data", data)
    // console.log(data, token, "actions")
    return (dispatch) => {
        axios.post(`${URL.cliniapi}Patient`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                // console.log('dataadded to ibm', 'addtoibm');
                // console.log("------------addpatient data", res)
                // console.log("------------addpatient location", res.headers.location)
                dispatch({
                    type: 'ADDPATIENTIBM',
                    payload: {
                        response: res.headers.location,
                        loading: false,
                        err: '',
                    },
                });
            }).catch(err => {
                // console.log(err.message, 'kkkkkkkkkk');
                dispatch({
                    type: 'ADDPATIENTIBM',
                    payload: {
                        response: '',
                        loading: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const getLinkedProfiles = (id, token) => {
    return (dispatch) => {
        axios.get(`${URL.cliniapi}Patient/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                dispatch({
                    type: 'LINKEDPROFILES',
                    payload: {
                        response: res.data,
                        loading: false,
                        err: '',
                    },
                });
            }).catch(err => {
                dispatch({
                    type: 'LINKEDPROFILES',
                    payload: {
                        response: '',
                        loading: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const switchProfile = (ibmId, token) => {

    return (dispatch) => {
        axios.get(`${URL.patient}get/RelatedPerson/count/${ibmId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                // console.log(res, "switchprofiles")
                dispatch({
                    type: 'SWITCHPROFILES',
                    payload: {
                        response: res.data,
                        loading: false,
                        err: '',
                    },
                });
            }).catch(err => {
                dispatch({
                    type: 'SWITCHPROFILES',
                    payload: {
                        response: '',
                        loading: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const getFixedPatient = (value, token) => {
    return (dispatch) => {
        // console.log(`${URL.cliniapi}${system}/${value}`);
        axios.get(`${URL.cliniapi}Patient/${value}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                // console.log(res.data);
                dispatch({
                    type: 'FIXEDDATA',
                    payload: res.data,
                });
            })
            .catch(err => {
                console.log(err.message, 'fixedapi');
                dispatch({
                    type: 'FIXEDDATA',
                    payload: [],
                });
            });
    };
};



export const Notificationdata = (token, patientId) => {

    return (dispatch) => {
        axios
            .get(`https://devcommunications.docsteth.com/notify/notificationbyuserid/${patientId}`, {

                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                // console.log('res', res.data);
                dispatch({
                    type: 'NOTIFICATION_DATA',
                    payload: res.data,
                });

            })
            .catch(err => {
                console.log(err);
                console.log(err.message, 'notification screen');
                dispatch({
                    type: 'NOTIFICATION_DATA',
                    payload: [],
                });
            });
    };
};


export const getToken = (value) => {
    return (dispatch) => {
        dispatch(
            {
                type: 'GET_TOKEN',
                payload: value,
            }
        );
    };
};

export const gatewayToken = () => {
    // console.log(`${URL.gatewaytoken}`);
    return dispatch => {
        axios
            .post(`${URL.gatewaytoken}`, 'default', {
                headers: {
                    gatewayToken: 'medxperts',
                },
            })
            .then(res => {
                dispatch({
                    type: 'GATEWAYTOKEN',
                    payload: res.data,
                });
            })
            .catch(err => {
                console.log(err.message, 'gatewaytoken');
                dispatch({
                    type: 'GATEWAYTOKEN',
                    payload: [],
                });
            });
    };
};

export const abhaOtpVerifiedSuccess = () => {
    return dispatch => {
        dispatch({
            type: 'OTPVERIFIED',
            payload: true,
        });
    };
};

export const abhaOtpVerifiedFailed = () => {
    return dispatch => {
        dispatch({
            type: 'OTPVERIFIED',
            payload: false,
        });
    };
};

export const getpostbyid = (id, token) => {
    // console.log('---- id', id);
    return (dispatch) => {
        axios.get(`${URL.post}${id}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                // console.log('res getpostbyid', res.data);
                dispatch({
                    type: 'POSTBYID',
                    payload: res.data,
                });
            })
            .catch(err => {
                // console.log(err.message, 'kkk');
                dispatch({
                    type: 'POSTBYID',
                    payload: [],
                });
            });
    };
};
export const clearPoststate = () => {
    // console.log('cleared');
    return (dispatch) => {
        dispatch({
            type: 'CLEARPOST',
            payload: [],
        });
    };
};

export const getFeeddata = (limit, offset, token) => {
    return (dispatch) => {
        dispatch({
            type: 'FEED',
            payload: [],
            count: 0,
            loading: true,
        });
        axios.get(`${URL.feed}?limit=${limit}&offset=${offset}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                dispatch({
                    type: 'FEED',
                    payload: res.data.results,
                    count: res.data.count,
                    loading: false,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'FEED',
                    payload: [],
                    count: 0,
                    loading: false,
                });
            });
    };
};


export const getPatientById = (pid) => {
    return (dispatch) => {
        if (pid) {
            axios.get(`${URL.getPatientById}${pid}`)
                .then(res => {
                    dispatch({
                        type: 'GETPATIENTDETAILS',
                        payload: res.data,
                    });
                })
                .catch(err => {
                    console.log(err.message, 'kkkk');
                    dispatch({
                        type: 'GETPATIENTDETAILS',
                        payload: [],
                    });
                });
        } else {
            dispatch({
                type: 'GETPATIENTDETAILS',
                payload: [],
            });
        }
    };
};


export const getSuggestedDoctors = (limit, offset, patientid) => {
    return (dispatch) => {
        axios.get(`${URL.suggestedDoctors}${limit}/${offset}/${patientid}`)
            .then(res => {
                dispatch({
                    type: 'SUGGESTED_DOCTORS',
                    payload: res.data,
                });
            }).catch(err => {
                console.log(err.message, 'suggested Docs error');
                dispatch({
                    type: 'SUGGESTED_DOCTORS',
                    payload: [],
                });
            });
    };
};
export const getDoctorsdata = (docId, token) => {
    return (dispatch) => {
        axios.get(`${URL.getDoctordata}${docId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                dispatch({
                    type: 'DOCTORDATA',
                    payload: res.data,
                });
            })
            .catch(err => {
                console.log(err.message, 'doctorsdata');
                dispatch({
                    type: 'DOCTORDATA',
                    payload: [],
                });
            });
    };
};

export const getDoctorsFollowCount = (pid) => {
    return (dispatch) => {
        axios.get(`${URL.followDoctorsCount}${pid}`)
            .then(res => {
                dispatch({
                    type: 'DOCFOLLOWCOUNT',
                    payload: res.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'DOCFOLLOWCOUNT',
                    payload: [],
                });
            });
    };
};

export const getOrgdata = (orgId, token) => {
    return (dispatch) => {
        axios.get(`${URL.getOrgdata}${orgId}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        })
            .then(res => {
                dispatch({
                    type: 'ORGDATA',
                    payload: res.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'ORGDATA',
                    payload: [],
                });
            });
    };
};
export const getOrgFollowCount = (pid) => {
    return (dispatch) => {
        axios.get(`${URL.followOrgCount}${pid}`)
            .then(res => {
                dispatch({
                    type: 'ORGFOLLOWCOUNT',
                    payload: res.data,
                });
            })
            .catch(err => {
                dispatch({
                    type: 'ORGFOLLOWCOUNT',
                    payload: [],
                });
            });
    };
};

export const getSuggestedOrgs = (limit, offset, patientid) => {
    return (dispatch) => {
        axios.get(`${URL.suggestedOrganisations}${limit}/${offset}/${patientid}`)
            .then(res => {
                dispatch({
                    type: 'SUGGESTED_ORGANISATION',
                    payload: res.data,
                });
            }).catch(err => {
                // console.log(err.message, 'suggested org error');
                dispatch({
                    type: 'SUGGESTED_ORGANISATION',
                    payload: [],
                });
            });

    };
};
export const getFollowdoctor = (limit, offset, patientid) => {
    return (dispatch) => {
        axios.get(`${URL.doctorsFollowList}${patientid}/${limit}/${offset}`)
            .then(res => {
                dispatch({
                    type: 'DOCTORSFOLLOWLIST',
                    payload: res.data.data,
                });
            }).catch(err => {
                // console.log(err.message, 'suggested org error');
                dispatch({
                    type: 'DOCTORSFOLLOWLIST',
                    payload: [],
                });
            });

    };
};
export const getFollowOrganisation = (limit, offset, patientid) => {
    return (dispatch) => {
        axios.get(`${URL.orgFollowList}${patientid}/${limit}/${offset}`)
            .then(res => {
                dispatch({
                    type: 'ORGSFOLLOWLIST',
                    payload: res.data,
                });
            }).catch(err => {
                console.log(err.message, 'suggested org error');
                dispatch({
                    type: 'ORGSFOLLOWLIST',
                    payload: [],
                });
            });

    };
};

