import React from 'react';
import './Privacy.css'
import { Box } from '@mui/material';


export default function Privacy() {
    return (
        <Box sx={{ padding: { xs: "90px 20px", sm: "90px 20px", md: "20px" } }}>
            {/* <div fxLayout="row" fxLayoutAlign="center center"> */}
            <h1 className="center">Privacy Policy</h1>
            {/* </div> */}
            {/* <div fxLayout="row" fxLayoutAlign="start none">
                    Last Updated: 21-01-2020
                </div> */}
            <h4 className="p-t-15">Contents</h4>
            <ol className="p-b-15 m-l-15">
                <li className="justify"><a href="/privacy/#one">General Information</a></li>
                <li className="justify"><a href="/privacy/#two">Personal Information of End-User / Client</a></li>
                <li className="justify"><a href="/privacy/#three">Personal Information of Health Care Providers / Doctors /
                    Clinics</a></li>
                <li className="justify"><a href="/privacy/#four">Medical Practitioners on use of E-prescription and Practice
                    Management System</a></li>
                <li className="justify"><a href="/privacy/#five">Personal Health Information and Personal Health Records</a>
                </li>
            </ol>
            <h4 className="p-t-15" id="one">1. General Information</h4>
            <p className="justify p-b-15">MedXperts<sup>TM</sup> a brand of Vollmond Innovations and Social Entrepreneurs Private
                Limited
                ("us", "we", or "MedXperts<sup>TM</sup>", which also includes its affiliates) is the author and publisher of the
                internet resource
                <a href="https://www.medxperts.in">www.medxperts.in</a> / <a
                    href="https://www.medxpertsindia.com">www.medxpertsindia.com</a> ("Website")
                on the world wide web as well as the software and applications provided by MedXperts, including but not limited to
                the mobile application
                'MedXperts<sup>TM</sup>', and its software applications.
            </p>
            <p className="justify p-b-15">
                This privacy policy ("<b>Privacy Policy</b>") explains how we collect, use, share, disclose and protect Personal
                Information about the Users of the Services, including the Practitioners (as defined in the Terms of Use, which may
                be accessed via the following weblink https://medxperts.in/privacy (the "Terms of Use")), the End-Users (as defined
                in the Terms of Use), and the visitors of Website (jointly and severally referred to as "you" or "Users / Clients"
                in this
                Privacy Policy). We created this Privacy Policy to demonstrate our commitment to the protection of your privacy and
                your personal information. Your use of and access to the Services is subject to this Privacy Policy and our Terms of
                Use. Any capitalized term used but not defined in this Privacy Policy shall have the meaning attributed to it in our
                Terms of Use.
            </p>
            <p className="justify p-b-15">
                BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE DEEMED TO HAVE READ, UNDERSTOOD AND
                AGREED TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY AND AGREE TO BE BOUND BY THE PRIVACY POLICY.
                YOU HEREBY CONSENT TO OUR COLLECTION, USE AND SHARING, DISCLOSURE OF YOUR INFORMATION AS DESCRIBED IN THIS PRIVACY
                POLICY. WE RESERVE THE RIGHT TO CHANGE, MODIFY, ADD OR DELETE PORTIONS OF THE TERMS OF THIS PRIVACY POLICY, AT OUR
                SOLE DISCRETION, AT ANY TIME. IF YOU DO NOT AGREE WITH THIS PRIVACY POLICY AT ANY TIME, DO NOT USE ANY OF THE
                SERVICES OR GIVE US ANY OF YOUR INFORMATION. IF YOU USE THE SERVICES ON BEHALF OF SOMEONE ELSE (SUCH AS YOUR CHILD)
                OR AN ENTITY (SUCH AS YOUR EMPLOYER), YOU REPRESENT THAT YOU ARE AUTHORISED BY SUCH INDIVIDUAL OR ENTITY TO (I)
                ACCEPT THIS PRIVACY POLICY ON SUCH INDIVIDUAL’S OR ENTITY’S BEHALF, AND (II) CONSENT ON BEHALF OF SUCH INDIVIDUAL OR
                ENTITY TO OUR COLLECTION, USE AND DISCLOSURE OF SUCH INDIVIDUAL’S OR ENTITY’S INFORMATION AS DESCRIBED IN THIS
                PRIVACY POLICY.
            </p>
            <div className="m-l-15">
                <b>1.1 WHY THIS PRIVACY POLICY?</b>
                <p className="justify">This Privacy Policy is published in compliance with, inter alia:</p>
                <ol type="i">
                    <li className="justify">Section 43A of the Information Technology Act, 2000.</li>
                    <li className="justify">Regulation 4 of the Information Technology (Reasonable Security Practices and Procedures and
                        Sensitive
                        Personal Information) Rules, 2011 (the "SPI Rules").</li>
                    <li className="justify">Regulation 3(1) of the Information Technology (Intermediaries Guidelines) Rules, 2011.</li>
                </ol>
                <p className="justify">This Privacy Policy states the following:</p>
                <ol type="i">
                    <li className="justify">The type of information collected from the Users, including
                        Personal Information (as defined in paragraph 2 below) and Sensitive Personal Data or Information (as defined
                        in
                        paragraph 2 below) relating to an individual.</li>
                    <li className="justify">The purpose, means and modes of collection, usage, processing, retention and destruction of
                        such
                        information, and</li>
                    <li className="justify">How and to whom MedXperts will disclose such information.</li>
                </ol>
                <b>1.2 CHANGE TO PRIVACY POLICY</b>
                <p className="justify p-b-15">
                    MedXperts may update this Privacy Policy at any time, with or without advance notice. In the event there are
                    significant changes in the way MedXperts treats User's personally identifiable information, or in the Privacy
                    Policy itself, MedXperts will display a notice on the Website or send Users an email, as provided for above, so
                    that you may review the changed terms prior to continuing to use the Services. As always, if you object to any of
                    the changes to our terms, and you no longer wish to use the Services, you may contact <a
                        href="mailto:support@medxpertsindia.com">support@medxpertsindia.com</a> to
                    deactivate your account. Unless stated otherwise, MedXperts current Privacy Policy applies to all information that
                    MedXperts has about you and your account.
                </p>
                <p className="justify p-b-15">
                    If a User uses the Services or access the Website after a notice of changes has been sent to such User or
                    published on the Website, such User hereby provides his / her consent to the changed terms.
                </p>
                <b>1.3 CONSENT TO THIS POLICY</b>
                <p className="justify p-b-15">You acknowledge that this Privacy Policy is a part of the Terms of Use of the Website and
                    the other Services, and
                    you unconditionally agree that becoming a User of the Website and its Services signifies your (i)assent to this
                    Privacy Policy, and (ii) consent to MedXperts using, collecting, processing and/or disclosing your Personal
                    Information in
                    the manner and for the purposes set out in this Privacy Policy. Your visit to the Website and use of the Services
                    is subject to this Privacy Policy and the Terms of Use.</p>
                <b>1.4 CHILDREN'S AND MINOR'S PRIVACY</b>
                <p className="justify p-b-15">MedXperts strongly encourages parents and guardians to supervise the online activities of
                    their minor children and consider using parental control tools available from online services and software
                    manufacturers to help provide a child-friendly online environment. These tools also can prevent minors from
                    disclosing their name, address, and other personally identifiable information online without parental permission.
                    Although the MedXperts Website and Services are not intended for use by minors, MedXperts respects the privacy
                    of minors who may inadvertently use the internet or the mobile application.</p>
                <b>1.5 ADDRESS FOR PRIVACY QUESTIONS</b>
                <p className="justify p-b-15">Should you have questions about this Privacy Policy or MedXpert's information collection,
                    use and disclosure practices, you may contact, the Data Protection Officer appointed by MedXperts in accordance
                    with the provisions of PDPA. We will use reasonable efforts to respond promptly to any requests, questions or
                    concerns, which you may have regarding our use of your personal information. If you have any grievance with
                    respect
                    to our use of your information, you may communicate such grievance to the Data Protection Officer:</p>
                <address className="p-b-15">
                    <p>
                        Name: Mr. Dinny Praneels<br />
                        MedXperts - Vollmond Innovations and Social Entrepreneurs Pvt Ltd<br />
                        8-903/7A, 1st Floor Padmaja Villa,<br />
                        Nagarjuna Nagar, Ameerpet,<br />
                        Hyderabad - 500073<br />
                        Phone: <a href="tel:+919505893029">+919505893029</a><br />
                        Email: <a href="mailto:support@medxpertsindia.com">privacy@medxpertsindia.com</a><br />
                    </p>
                </address>
            </div>
            <h4 className="p-t-15" id="two">2. Personal Information of End-User / Client</h4>
            <div className="m-l-15">
                <b>2.1 COLLECTION OF PERSONAL INFORMATION</b>
                <p className="justify">Generally some of the Services require us to know who you are so that we can best meet
                    your needs. When you access the Services, or through any interaction with us via emails, telephone calls or other
                    correspondence, we may ask you to voluntarily provide us with certain information that personally identifies you
                    or could be used to personally identify you. You hereby consent to the collection of such information by
                    MedXperts<sup>TM</sup>. Without prejudice to the generality of the above, information collected by us from you may
                    include
                    (but is not limited to) the following:</p>
                <ol type="i">
                    <li className="justify">Contact Data (such as your email address and phone number).</li>
                    <li className="justify">Demographic Data (such as your gender, your date of birth and your pin code).</li>
                    <li className="justify">Data regarding your usage of the services and history of the appointments made by or with you
                        through the use
                        of Services.</li>
                    <li className="justify">Insurance Data (such as your insurance carrier and insurance plan).</li>
                    <li className="justify">Other information that you voluntarily choose to provide to us (such as information shared by
                        you with us
                        through emails or letters).</li>
                    <li className="justify">The information collected from you by MedXperts<sup>TM</sup> may constitute 'personal
                        information' or
                        'sensitive personal data or information' under the SPI Rules.</li>
                </ol>
                <p className="justify p-b-15">
                    <b>2.2 "Personal Information": </b>
                    is defined under the SPI Rules to mean any information that
                    relates to a natural person, which, either directly or indirectly, in combination with other information available
                    or likely to be available to a body corporate, is capable of identifying such person.</p>
                <p className="justify">
                    The SPI Rules further define "Sensitive Personal Data or Information" of a person to mean
                    personal information about that person relating to:
                </p>

                <ol type="i">
                    <li className="justify">Passwords.</li>
                    <li className="justify">Financial information such as bank accounts, credit and debit card details or other payment
                        instrument
                        details.</li>
                    <li className="justify">Physical, physiological and mental health condition.</li>
                    <li className="justify">Sexual orientation.</li>
                    <li className="justify">Medical records and history.</li>
                    <li className="justify">Biometric information.</li>
                    <li className="justify">Information received by body corporate under lawful contract or otherwise.</li>
                    <li className="justify">Visitor details as provided at the time of registration or thereafter, and</li>
                    <li className="justify">Call data records.</li>
                </ol>
                <p className="justify p-b-15">MedXperts will be free to use, collect and disclose information that is freely available
                    in the
                    public domain without your consent.
                </p>
                <b>2.3 PRIVACY STATEMENTS FOR END USERS NOTE</b>
                <ol type="i">
                    <li className="justify">Accordingly, a condition of each User's use of and access to the Services is their acceptance
                        of the Terms of
                        Use, which also involves acceptance of the terms of this Privacy Policy. Any User that does not agree with any
                        provisions of the same has the option to discontinue the Services provided by MedXperts immediately.</li>
                    <li className="justify">An indicative list of information that MedXperts may require you to provide to enable your use
                        of the Services
                        is provided in the Schedule annexed to this Privacy Policy.</li>
                    <li className="justify">All the information provided to MedXperts by a User, including Personal Information or any
                        Sensitive Personal
                        Data or Information, is voluntary. You understand that MedXperts may use certain information of yours, which
                        has been designated as Personal Information or 'Sensitive Personal Data or Information' under the SPI Rules, for
                        the purpose of providing you the Services</li>
                    <ol type="a">
                        <li className="justify">For commercial purposes and in an aggregated or non-personally identifiable form for
                            research, statistical
                            analysis and business intelligence purposes</li>
                        <li className="justify">For sale or transfer of such research, statistical or intelligence data in an aggregated or
                            non-personally
                            identifiable form to third parties and affiliates</li>
                        <li className="justify">For communication purpose so as to provide You a better way of booking appointments and for
                            obtaining
                            feedback in relation to the Practitioners and their practice</li>
                        <li className="justify">Debugging customer support related issues</li>
                        <li className="justify">For the purpose of contacting you to complete any transaction if you do not complete a
                            transaction after
                            having provided us with your contact information in the course of completing such steps that are designed
                            for completion of the transaction
                        </li>
                    </ol>
                    <li className="justify">MedXperts also reserves the right to use information provided by or about the End-User for the
                        following
                        purposes:</li>
                    <ol type="a">
                        <li className="justify">Publishing such information on the Website.</li>
                        <li className="justify">Contacting End-Users for offering new products or services.</li>
                        <li className="justify">Contacting End-Users for taking product and Service feedback.</li>
                        <li className="justify">Analysing software usage patterns for improving product design and utility.</li>
                        <li className="justify">Analysing anonymized practice information for commercial use.</li>
                    </ol>
                </ol>
                <p className="justify p-b-15">If you have voluntarily provided your Personal Information to MedXperts for any of the
                    purposes stated above, you
                    hereby consent to such collection and use of such information by MedXperts. However, MedXperts shall not contact
                    You on Your telephone
                    number(s) for any purpose including those mentioned in this sub-section, if such telephone number is registered
                    with the Do Not
                    Call registry ("DNC Registry") under the PDPA without your express, clear and un-ambiguous written consent.</p>
                <p className="justify p-b-15"><b>2.4</b> Collection, use and disclosure of information which has been designated
                    as Personal Information or Sensitive Personal Data or Information under the SPI Rules requires your express
                    consent. By affirming your assent to this Privacy Policy, you provide your consent to such use, collection and
                    disclosure as required under applicable law.</p>
                <p className="justify p-b-15"><b>2.5</b> MedXperts does not control or endorse the content, messages or information
                    found
                    in any Services and, therefore, MedXperts specifically disclaims any liability with regard to the Services and any
                    actions resulting from your participation in any Services, and you agree that you waive any claims against
                    MedXperts relating to same, and to the extent such waiver may be ineffective, you agree to release any claims
                    against MedXperts relating to the same.</p>
                <p className="justify p-b-15"><b>2.6</b> You are responsible for maintaining the accuracy of the information you submit
                    to us, such as your contact information provided as part of account registration. If your personal information
                    changes, you may correct, delete inaccuracies, or amend information by making the change on our member information
                    page or by contacting us through <a href="mailto:privacy@medxpertsindia.com">privacy@medxpertsindia.com</a>. We
                    will make good faith efforts to make requested changes in our then active databases as soon as reasonably
                    practicable.
                    If you provide any information that is untrue, inaccurate, out of date or incomplete (or becomes untrue,
                    inaccurate,
                    out of date or incomplete), or MedXperts has reasonable grounds to suspect that the information provided by you is
                    untrue,
                    inaccurate, out of date or incomplete, MedXperts may, at its sole discretion, discontinue the provision of the
                    Services
                    to you. There may be circumstances where MedXperts will not correct, delete or update your Personal Data,
                    including (a)
                    where the Personal Data is opinion data that is kept solely for evaluative purpose; and (b) the Personal Data is
                    in documents
                    related to a prosecution if all proceedings relating to the prosecution have not been completed.</p>
                <p className="justify p-b-15"><b>2.7</b> If you wish to cancel your account or request that we no longer use
                    your information to provide you Services, contact us through <a
                        href="mailto:support@medxpertsindia.com">support@medxpertsindia.com</a>. We will retain your information
                    for as long as your account with the Services is active and as needed to provide you the Services. We shall not
                    retain such information for longer than is required for the purposes for which the information may lawfully be
                    used or is otherwise required under any other law for the time being in force. After a period of time, your data
                    may be anonymized and aggregated, and then may be held by us as long as necessary for us to provide our Services
                    effectively, but our use of the anonymized data will be solely for analytic purposes. Please note that your
                    withdrawal of consent, or cancellation of account may result in MedXperts being unable to provide you with its
                    Services or to terminate any existing relationship MedXperts may have with you.</p>
                <p className="justify p-b-15"><b>2.8</b> If you wish to opt-out of receiving non-essential communications such
                    as promotional and marketing-related information regarding the Services, please send us an email at
                    <a href="mailto:support@medxpertsindia.com">support@medxpertsindia.com</a>.</p>
                <p className="justify p-b-15"><b>2.9</b> MedXperts may require the User to pay with a credit card, wire transfer, debit
                    card or cheque for Services for which subscription amount(s) is/are payable. MedXperts will collect such User's
                    credit card number and/or other financial institution information such as bank account numbers and will use that
                    information for the billing and payment processes, including but not limited to the use and disclosure of such
                    credit card number and information to third parties as necessary to complete such billing operation. Verification
                    of credit information, however, is accomplished solely by the User through the authentication process. User's
                    credit-card/debit card details are transacted upon secure sites of approved payment
                    gateways which are digitally under encryption, thereby providing the highest possible degree of care as per
                    current technology. However, MedXperts provides you an option not to save your payment details. User is advised,
                    however, that internet technology is not full proof safe and User should exercise discretion on using the same.
                </p>
                <p className="justify p-b-15"><b>2.10</b> Due to the communications standards on the Internet, when a User or the
                    End-User or anyone who visits the Website, MedXperts automatically receives the URL of the site from which anyone
                    visits. MedXperts also receives the Internet Protocol(IP) address of each User's computer (or the proxy server a
                    User used to access the World Wide Web), User's computer operating system and type of web browser the User is
                    using, email patterns, as well as the name of User's ISP. This information is used to analyze overall trends to
                    help MedXperts improve its Service. The linkage between User's IP address and User's personally identifiable
                    information is not shared with or disclosed to third parties. Not withstanding the above, MedXperts may share
                    and/or disclose some of the aggregate findings (not the specific data) in anonymized form (i.e., non-personally
                    identifiable) with advertisers, sponsors, investors, strategic partners, and others in order to help grow its
                    business.</p>
                <p className="justify p-b-15"><b>2.11</b> The Website uses temporary cookies to store certain (that is not
                    sensitive personal data or information) that is used by MedXperts and its service providers for the technical
                    administration of the Website, research and development, and for User administration. In the course of serving
                    advertisements or optimizing services to its Users, MedXperts may allow authorized third parties to place
                    or recognize a unique cookie on the User's browser. The cookies however, do not store any Personal Information of
                    the User. You may adjust your internet browser to disable cookies. If cookies are disabled you may still use the
                    Website, but the Website may be limited in the use of some of the features.</p>
                <p className="justify p-b-15"><b>2.12</b> A User may have limited access to the Website without creating an account on
                    the Website. Unregistered Users can not make appointments with the doctors by providing their name and phone
                    number. In order to have access to all the features and benefits on our Website, a User must first create an
                    account on our Website. To create an account, a User is required to provide the following information, which such
                    User recognizes and expressly acknowledges is Personal Information allowing others, including MedXperts, to
                    identify the User: name, UserID, email address, country, ZIP/postal code, age, phone number, password chosen by
                    the User and valid financial account information. Other information requested on the registration page, including
                    the ability to receive promotional offers from MedXperts, is optional. MedXperts may, in future, include other
                    optional requests for information from the User to help MedXperts to customize the Website to deliver personalized
                    information to the User.</p>
                <p className="justify p-b-15"><b>2.13</b> This Privacy Policy applies to Services that are owned and operated by
                    MedXperts. MedXperts does not exercise control over the sites displayed as search results or links from within its
                    Services. These other sites may place their own cookies or other files on the Users' computer, collect data or
                    solicit personal information from the Users, for which MedXperts is not responsible or liable.
                    Accordingly, MedXperts does not make any representations concerning the privacy practices or policies of such
                    third parties or terms of use of such websites, nor does MedXperts guarantee the accuracy, integrity, or quality
                    of the information, data, text, software, sound, photographs, graphics, videos, messages or other materials
                    available on such websites. The inclusion or exclusion does not imply any endorsement by MedXperts of the website,
                    the website's provider, or the information on the website. If you decide to visit a third party website linked to
                    the Website, you do this entirely at your own risk. MedXperts encourages the User to read the privacy policies of
                    that website.</p>
                <p className="justify p-b-15"><b>2.14</b> The Website may enable User to communicate with other Users or to
                    post information to be accessed by others, where upon other Users may collect such
                    data. Such Users, including any moderators or administrators, are not authorized MedXperts representatives or
                    agents, and their opinions or statements do not necessarily reflect those of MedXperts, and they are not
                    authorized to bind MedXperts to any contract. MedXperts hereby expressly disclaims any liability for any reliance
                    or misuse of such information that is made available by Users or visitors in such a manner.</p>
                <p className="justify p-b-15"><b>2.15</b> MedXperts does not collect information about the visitors of the Website
                    from other sources, such as public records or bodies, or private organisations, save except for the purposes of
                    registration of the Users (the collection, use, storage and disclosure of which each End User must agree to under
                    the Terms of Use inorder for MedXperts to effectively render the Services).</p>
                <p className="justify p-b-15"><b>2.16</b> MedXperts maintains a strict "No-Spam" policy, which means that MedXperts
                    does not intend to sell, rent or otherwise give your e-mail address to a third party without your consent.</p>
                <p className="justify p-b-15"><b>2.17</b> MedXperts has implemented best international market practices and
                    security policies, rules and technical measures to protect the personal data that it has under its control from
                    unauthorised access, improper use or disclosure, unauthorised modification and unlawful destruction or accidental
                    loss. However, for any data loss or theft due to unauthorized access to the User's electronic devices through
                    which the User avails the Services, MedXperts shall not be held liable for any loss whatsoever incurred by the
                    User.</p>
                <p className="justify p-b-15"><b>2.18</b> MedXperts implements reasonable security practices and procedures and has
                    a comprehensive documented information security programme and information security policies that contain
                    managerial, technical, operational and physical security control measures that are commensurate with respect to
                    the information being collected and the nature of MedXperts business.</p>
                <p className="justify p-b-15"><b>2.19</b> MedXperts takes your right to privacy very seriously and other than as
                    specifically stated in this Privacy Policy, will only disclose your Personal Information in the event it is
                    required to do so by law, rule, regulation, law enforcement agency, governmental official, legal authority or
                    similar requirements or when MedXperts, in its sole discretion, deems it necessary in order to protect its rights
                    or the rights of others, to prevent harm to persons or property, to fight fraud and credit risk, or to enforce or
                    apply the Terms of Use.</p>
                <p className="justify p-b-15"><b>2.20</b> No personal information or contact related information will not be shared to
                    any third party business or applications. However, your contact information can be viewed by the doctors and
                    clinics from which you have availed the services.</p>
                <p className="justify p-b-15"><b>2.21</b> The contact related data and personal information shared with the clinics
                    and doctors, is strictly for the purpose of availing medical services and miss use of your data if suspected you
                    can report the same to privacy officer of MedXperts for appropriate enquiry and legal action.</p>
                <b>2.22 CONFIDENTIALITY AND SECURITY</b>
                <ol type="i">
                    <li className="justify">Your Personal Information is maintained by MedXperts in electronic form on its equipment, and
                        on the equipment
                        of its employees. Such information may also be converted to physical form from time to time. MedXperts takes all
                        necessary precautions to protect your personal information both online and offline, and implements reasonable
                        security practices and measures including certain managerial, technical, operational and physical security
                        control measures that are commensurate with respect to the information being collected and the nature of
                        MedXperts business.</li>
                    <li className="justify">No administrator at MedXperts will have knowledge of your password. It is important for you to
                        protect against
                        unauthorized access to your password, your computer and your mobile phone. Be sure to log off from the Website
                        when finished. MedXperts does not undertake any liability for any unauthorised use of your account and password.
                        If you suspect any unauthorized use of your account, you must immediately notify MedXperts by sending an email
                        to <a href="mailto:support@medxpertsindia.com">support@medxpertsindia.com</a> You shall be liable to indemnify
                        MedXperts due to any loss suffered by it due to such unauthorized use of your account and password.</li>
                    <li className="justify">MedXperts makes all User information accessible to its employees, agents or partners and third
                        parties only on
                        a need-to-know basis, and binds only its employees to strict confidentiality obligations.</li>
                    <li className="justify">Part of the functionality of MedXperts is assisting the doctors to maintain and organise such
                        information.
                        MedXperts may, therefore, retain and submit all such records to the appropriate authorities, or to doctors
                        who request access to such information.</li>
                    <li className="justify">Part of the functionality of the MedXperts is assisting the patients to access information
                        relating to them.
                        MedXperts may, therefore, retain and submit all such records to the relevant patients, or to their doctors.</li>
                    <li className="justify">Not withstanding the above, MedXperts is not responsible for the confidentiality, security or
                        distribution of
                        your Personal Information by our partners and third parties outside the scope of our agreement with such
                        partners and third parties. Further, MedXperts shall not be responsible for any breach of security or for any
                        actions of any third parties or events that are beyond the reasonable control of MedXperts including but not
                        limited to, acts of government, computer hacking, unauthorised access to computer data and storage device,
                        computer crashes, breach of security and encryption, poor quality of Internet service or telephone service of
                        the User etc.</li>
                </ol>
                <b>2.23 SHEDULE</b>
                <p className="justify">Indicative List of Information by Nature of Service</p>
                <ol type="i">
                    <li className="justify">End-Users using the Website by registering for an account on the Website or 'MedXperts' mobile
                        application:
                    </li>
                    <ol type="a">
                        <li className="justify">You can create an account by giving us information regarding your [name, mobile number,
                            email address], and
                            such other information as requested on the End-User registration page. This is to enable us to provide you
                            with the facility to use the account to book your appointments and store other health related information.
                        </li>
                        <li className="justify">Against every Practitioner listed in MedXperts.in, you may see a 'show number' option. When
                            you choose this
                            option, you choose to call the number through a free telephony service provided by MedXperts, and the records
                            of such calls are recorded and stored in MedXperts servers. Such call will have an IVR message stating the
                            purpose of recording your calls and your consent to such recordings which are dealt with as per the Privacy
                            Policy. If you choose not to consent to recording of such call, MedXperts hereby reserves the right to not
                            provide you the Services for which such Personal Information is sought. Such records are dealt with only in
                            accordance with this Privacy Policy.</li>
                    </ol>
                    <li className="justify">End-Users using the Website without registering for an account on the Website or 'MedXperts'
                        mobile
                        application
                        (i.e., 'Guest' End-User):</li>
                    <ol type="a">
                        <li className="justify">You can use the Website without registering for an account, but to book an appointment, you
                            may be asked
                            certain information (including your mobile number, and such other information as requested when you choose to
                            use the Services without registration) to confirm the appointment.</li>
                        <li className="justify">Against every Practitioner listed in MedXperts.in, you may see a 'show number' option. When
                            you choose this
                            option, you choose to call the number through a free telephony service provided by MedXperts, and the records
                            of such calls are recorded and stored in MedXperts servers. Such call will have an IVR message stating the
                            purpose of recording your calls and your consent to such recordings which are dealt with as per the Privacy
                            Policy. If you choose not to consent to recording of such call, MedXperts hereby reserves the right to not
                            provide you the Services for which such Personal Information is sought. Such records are dealt with only in
                            accordance with this Privacy Policy.
                        </li>
                    </ol>
                </ol>
            </div>
            <h4 className="p-t-15" id="three">3. Personal Information of Health Care Providers / Doctors / Clinics</h4>
            <div className="m-l-15">
                <p className="justify"><b>3.1</b> Practitioner availing the service on the Website or 'MedXperts'
                    mobile application by registering for an account:</p>
                <ol type="i">
                    <li className="justify">As a Practitioner, you may be required to provide us with information regarding your name,
                        mobile number,
                        email address, qualification and speciality, and such other information as requested on
                        the Practitioner registration page to create an account. MedXperts may send email and/or SMS confirmations or
                        other communications to End-Users in connection with their bookings, appointments or other interactions with
                        you, if such interactions have been facilitated by MedXperts.</li>
                </ol>
                <p className="justify"><b>3.2</b> Practitioner filling intent to join MedXperts form network on the Website
                    or 'MedXperts' mobile application without registering for an account:</p>
                <ol type="i">
                    <li className="justify">As a Practitioner, you may fill a intent to join MedXperts form to avail MedXperts service and
                        Join the
                        MedXperts Network, without registering for an account by providing information regarding your name, mobile
                        number, email address, and such other information as requested by any of MedXperts employees or agents who
                        contact you in person or by telephone for verification. In such event, MedXperts will maintain this information
                        if and until you choose to register for an account, for which MedXperts may contact you from time to time.
                        MedXperts will, after such information is collected from you, send you a confirmation email confirming the
                        information provided and the fact that you will be listed on the Website. In the event you do not wish to be so
                        listed on the Website, please inform MedXperts immediately at <a
                            href="mailto:support@medxpertsindia.com">support@medxpertsindia.com</a></li>
                    <li className="justify">All the Practitioners registered for MedXperts Patient Management module will be verified
                        physically.
                        Practitioners must Provide the Personal Identification proof ID and Proof of Medical Practice Registration
                        during
                        the verification Process.</li>
                    <li className="justify">However, MedXperts software application is free open source application for internal use, but,
                        your
                        information
                        will be displayed in the web site will be open for online appointment booking to patients only after
                        verification.</li>
                </ol>
                <p className="justify"><b>3.3</b> Practitioners using the MedXperts Patient Management application:</p>
                <ol type="i">
                    <li className="justify">You will be required to create an account and may be required to provide MedXperts with
                        information regarding
                        your name, mobile number, email address, and such other information as requested by MedXperts on the website
                        and/or Tab Practitioner registration page, in order to complete your registration.</li>
                    <li className="justify">Upon registration, MedXperts will access non-personally identifiable information of your
                        patients from your
                        patient records. You agree to make your patients fully aware of such access.</li>
                    <li className="justify">MedXperts reserves the right to extend and withdraw 'ABS' (also known as Instant)
                        functionality to you at its
                        sole discretion, based on the number of End-User appointments being honoured by you. The extension or withdrawal
                        of such facility shall be intimated to you by MedXperts.</li>
                    <li className="justify">You have an option under these products to switch on 'End-User Feedback'. This will mean that
                        you are giving
                        one or more patients' contact details to MedXperts feedback system. End-Users may choose to send
                        feedback anonymously too, in which case you agree that you have no objection to such anonymous feedback. The
                        feedback system will then send an SMS and email to the patient(s) asking for feedback which may then be
                        published
                        on the Website. You agree to make your patients fully aware of the possibility of their receiving such feedback
                        queries.</li>
                </ol>
                <p className="justify p-b-15"><b>3.4</b> Personal Information with regards to your Phone number, email address, Medical
                    Registration number will not be disclosed to any third party application or business in any circumstances unless
                    mandated by Law enforcement.</p>
                <p className="justify p-b-15"><b>3.5</b> Your Clinic / Hospital Phone number and Email address will be point of
                    contact for patients using MedXperts application.</p>
                <p className="justify p-b-15"><b>3.6</b> In case if personal telephone/mobile number and clinic/hospital
                    telephone number are same, we are not responsible for the protection of your contact details. In such cases you
                    can contact MedXperts Team for issuing business Extension numbers, which functions as cloud reception. This would
                    help us improve your privacy.</p>
                <p className="justify"><b>3.7</b> Upon successful registration with MedXperts, you agree with privacy policy of
                    MedXperts to make your profile displayed to all the MedXperts End-users, and profile information for display
                    contains:</p>
                <ol type="i">
                    <li className="justify">Your clinic/hospital Name, Address</li>
                    <li className="justify">Doctor Name</li>
                    <li className="justify">Qualification(s)</li>
                    <li className="justify">Speciality</li>
                    <li className="justify">Experience</li>
                    <li className="justify">Availability timings</li>
                    <li className="justify">Available Services</li>
                    <li className="justify">Self-created description about doctor/ clinic/ hospital</li>
                    <li className="justify">Google Map Location of address</li>
                </ol>
                <p className="justify p-b-15"><b>3.8</b> In case if you wish to hide any of the information related your personal
                    contact details or from display in MedXperts Web and mobile application you can write us on
                    <a href="mailto:dr-privacy@medxpertsindia.com">dr-privacy@medxpertsindia.com</a>.</p>
                <p className="justify p-b-15"><b>3.9</b> Information we automatically collect when you use our Services</p>
                <p className="justify">Some information, which may include Personal Information, is automatically collected when
                    you use our Services, such as <b>Trafic Data</b>.</p>
                <p className="justify">We also may automatically collect certain data when you use the Services, such as</p>
                <ol type="i">
                    <li className="justify">IP address</li>
                    <li className="justify">Domain server</li>
                    <li className="justify">Type of device(s) used to access the Services</li>
                    <li className="justify">Web browser(s) used to access the Services</li>
                    <li className="justify">Referring webpage or other source through which you accessed the Services</li>
                    <li className="justify">Geolocation information, and</li>
                    <li className="justify">Other statistics and information associated with the interaction between your browser or
                        device and
                        the Services (collectively "Traffic Data"). Depending on applicable law, some Traffic Data may be
                        Personal Information.</li>
                </ol>
                <p className="justify p-b-15"><b>3.10</b> The data collected on IP address, geolocation will help us improve security
                    and recognise if you are using or accessing the MedXperts from the familiar and secure Internet services.</p>
            </div>
            <h4 className="p-t-15" id="four">4. Medical Practitioners on use of E-prescription and Practice Management System</h4>
            <div className="m-l-15">
                <b>4.1 Privacy of your client/customer/patient details</b>
                <ol type="i">
                    <li className="justify">Patients/clients registered in your clinic or patient availed services in your clinic/hospital
                        are confined to your clinic. Whenever your patient visit a new practitioner at any other
                        clinic/hospital/provider they have to go through fresh registration process. This is to ensure your clients
                        associated with you are not promoted to other provider/practitioner.</li>
                    <li className="justify">However, you can use our API’s to integrate our patient management application to your custom
                        application. These API’s are secured by API protection key that ensure authentic integration.</li>
                    <li className="justify">MedXperts customer care representative here after mentioned as MedXperts Clinical Co-ordinator
                        / MedXperts-CC Executive works as a cloud receptionist for your clinic to schedule your patient book
                        appointments on phone call.</li>
                    <li className="justify">So MedXperts-CC, share access to your Patient Management application to manage your
                        appointments and schedules.</li>
                    <li className="justify">In case, if you would prefer to take enhanced privacy you can contact us at
                        <a href="mailto:privacy@medxpertsindia.com">privacy@medxpertsindia.com</a> to submit your request with subject
                        "Do not access my appointments and cases". During the signup or post signup you can file for advanced privacy
                        and choose if MedXperts-CC can access your cases or not. The online visibility of your profile will remain open
                        for patients to book appointments.</li>
                    <li className="justify">However if you choose MedXperts-CC not to access your appointments and cases, you will not be
                        entitled to services such as, Phone call based appointment booking, MedXperts Co-pay services.</li>
                    <li className="justify">MedXperts or its employees will not share your patient data to any third party business or
                        applications and will not promote any other business or service or competitor to your clients/patients.</li>
                    <li className="justify">Your patient information is confined to your hospital and no other hospital or provider with
                        MedXperts can access your patient contact information or any other information.</li>
                </ol>
                <b>4.2 Ratings and Reviews</b>
                <ol type="i">
                    <li className="justify">MedXperts do not publish ratings and reviews of your patients/clients. The ratings and reviews
                        collected are purely for internal purpose and they will not be shared publicly through web site or to any other
                        individual other than the concerned practitioner/provider/hospital.</li>
                    <li className="justify">The feedback collected from your clients/patient is only for internal quality purpose and will
                        be shared to the practitioner/provider directly through Email.</li>
                </ol>
                <b>4.3 Privacy of Prescription and Health Information</b>
                <ol type="i">
                    <li className="justify">The digital Prescription/E-prescription written on MedXperts application by a practitioner is
                        subjected to Digital Information Security in Healthcare Act, ("<b>DISHA</b>") – 2018. and Section 43A of the
                        Information Technology Act, 2000 ("<b>IT Act</b>")</li>
                    <li className="justify">Section 28(8)(b), DISHA, 2018: The rights of owner of digital health data. As per which the
                        complete ownership of digital Health data of any individual must be his/her right. The patient/patient's
                        authorised person of attorney must be considered as the owners of their health care data.</li>
                    <li className="justify">Section 34(4), DISHA, 2018: Access to digital health data: The access to the patient/clients
                        medical records is subjected individuals to right to privacy. Hence a approved consent must be recorded.</li>
                    <li className="justify">From the above Laws and Regulations, Ownership and Access to the individual/client/patient
                        personal health care is completely the choice of individual/client/patient.</li>
                    <li className="justify">Neither MedXperts-CC nor any other health care provider can access your prescription or your
                        patient's medical records without the consent and approval of your patient/client.</li>
                    <li className="justify">MedXperts-CC Executive is a qualified doctor with Medical Degree (MBBS/Pharm.D), who act as a
                        bridge between you and your patient/client. They perform drug information services, disease based counselling,
                        diet management and Medication therapy management services on behalf of your clinic/hospital. The main objective
                        of MedXperts-CC is to ensure patient trust in his practitioner and his prescribed treatment.</li>
                    <li className="justify">However, the involvement of MedXperts-CC is the choice of patient and will take place on
                        patient consent.</li>
                </ol>
                <b>4.4 Other Privacy Related Information</b>
                <p className="justify p-b-15">MedXperts will always try its best to protect the privacy by ensuring secure access to
                    practice management systems and E-prescriptions system. However, in case of accidental data breach, or loss of
                    data please raise the issue immediately with our MedXperts Data protection officer at <a
                        href="mailto:datasafety@medxpertsindia.com">datasafety@medxpertsindia.com</a>.</p>
                <b>4.5 Processing of data by 'entities' other than clinical establishments</b>
                <ol type="i">
                    <li className="justify">There are a number of provisions for processing by all other 'entities' apart from clinical
                        establishments and health information exchanges, which will apply to the processing of health data in any other
                        form such as, for example, the State or an intermediary or service provider (say a pharmacy) in use by a
                        clinical
                        establishment. Such other 'entities' are highly restricted under DISHA, and are only permitted to generate,
                        collect and store health data for the following purposes only:</li>
                    <ol type="a">
                        <li className="justify">To advance the delivery of patient centric medical care</li>
                        <li className="justify">To provide information to guide medical decisions, or</li>
                        <li className="justify">To improve coordination of care and information among hospitals, laboratories, etc</li>
                    </ol>
                    <li className="justify">Apart from this, health data cannot be generated, collected, stored, accessed or disclosed for
                        a purpose not listed under the DISHA. Even use for a purpose listed under DISHA will additionally require
                        either consent or a legal requirement.</li>
                    <li className="justify">Thus, processing by any entity, including the government, is not recognized or allowed for any
                        other purposes apart from those listed here, including if there is a legal requirement.</li>
                </ol>
                <b>4.6 Governmental Access of the data</b>
                <p className="justify">The governmental access to data is subjected to law and when mandated for submission by
                    central or state government. The nature of data that is going to be provided will be subjected to the purpose and
                    requirements of MoHFW. This is permissible only for the following purpose:</p>
                <ol type="i">
                    <li className="justify">For public health activities or to deal with public health threats</li>
                    <li className="justify">To facilitate health and clinical research</li>
                    <li className="justify">To promote detection, prevention and management of chronic diseases</li>
                    <li className="justify">To carry out public health research and analysis, and</li>
                    <li className="justify">To undertake academic research</li>
                </ol>
            </div>
            <h4 className="p-t-15" id="five">5. Personal Health Information and Personal Health Records</h4>
            <div className="m-l-15">
                <b>5.1 Ownership of your Personal Health Information</b>
                <ol type="i">
                    <li className="justify">Data Ownership of your medical records is completely owned by you. As a end-user you have
                        complete ownership of your Prescriptions, Diagnostic reports, Medical records, and Medical history.</li>
                    <li className="justify">Data governance in MedXperts takes an entirely consent-based approach, giving the individual
                        significant rights and putting him/her squarely as the owner of his/her data.</li>
                    <li className="justify">As per Digita Information Security in Healthcare Act, an individual has been given an actual
                        say in what happens with his/her data.</li>
                </ol>
                <b>5.2 Right to Consent</b>
                <ol type="i">
                    <li className="justify">Firstly, the individual/end-user, you are entitled with explicit rights to give or refuse
                        consent at every stage of processing, generation, collection, storage, transmission, access and disclosure.</li>
                    <li className="justify">As an End-User you also have the right to withdraw consent for storage and transmission of
                        your data.</li>
                    <li className="justify">Two very significant consent related rights in addition to these are the need for explicit,
                        prior permission for every use of your data in an identifiable form or digital format (through OTP) and
                        the right not to be refused health care if you refuses consent at any stage.</li>
                    <li className="justify">No Practitioner/provider have access to your medical records unless you approve the access
                        through consent. To make the consent process easy and fast MedXperts provide you with a unique One Time Password
                        (OTP) to approve consent for access. However you can end the consent at any point of time you wish.</li>
                </ol>
                <b>5.3 Private and commercial use of health data</b>
                <ol type="i">
                    <li className="justify">Your Personal healthcare records and medical data will not be used by MedXperts for any
                        commercial Purpose, whether in an identifiable or anonymized form.</li>
                    <li className="justify">MedXperts strictly prohibit access, use and disclosure of your Medical records to insurance
                        companies, employers, human resource consultants and pharmaceutical companies.</li>
                    <li className="justify">However, A limited exception for insurance companies is to seek consent for accessing data for
                        the specific purpose of processing insurance claims.</li>
                    <li className="justify">The use of such data for marketing of any sort, another fundamentally commercial activity, is
                        also strictly prohibited.</li>
                </ol>
                <b>5.4 Processing of health data by smartphones, wearable devices, etc</b>
                <p className="justify p-b-15">MedXperts do not process the Health data in your smartphone or wearable device, however,
                    when possible such data can be stored to MedXperts on your approval, if the device/application is supported for
                    integration with MedXperts.</p>
                <p className="justify p-b-15">The use of such data for any private and commercial purpose will be strictly prohibited
                    and no such feasibility is created by MedXperts.</p>
                <b>5.5 Processing of data by 'entities' other than clinical establishments</b>
                <p className="justify p-b-15">There are a number of provisions for processing by all other 'entities' apart from
                    clinical establishments and health information exchanges, which will apply to the processing of health data in any
                    other form such as, for example, the State or an intermediary or service provider (say a pharmacy) in use by a
                    clinical establishment.</p>
                <p className="justify">Such other 'entities' are highly restricted under MedXperts, and are only permitted to
                    generate, collect and store health data for the following purposes only:</p>
                <ol type="i">
                    <li className="justify">To advance the delivery of patient centered medical care</li>
                    <li className="justify">To provide information to guide medical decisions, or</li>
                    <li className="justify">To improve coordination of care and information among hospitals, laboratories, etc</li>
                </ol>
                <b>5.6 Right to Privacy of Personal Health Information for Children</b>
                <p className="justify p-b-15">If the individual is a child who is less than 18 years of age and who is capable of
                    consenting to
                    the collection, use or disclosure of the information and if there is a person who is entitled to act as the
                    substitute decision maker of the child, it is the decision of the child given, withhold or withdraw the consent
                    or to provide the information prevails over a conflicting decision of that person.</p>
                <b>5.7 Disclosure of Laboratory and Diagnostics Reports</b>
                <p className="justify p-b-15">The practitioner/prescriber of the lab test will have access to the results and reports of your
                    laboratory/diagnostic tests. However, you may choose to end the consent in your setting page which prohibits
                    practitioner from getting update on your laboratory reports. It will also stop practitioner from accessing your
                    laboratory reports.</p>
            </div>
        </Box>
    )
}
