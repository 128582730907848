
import React from 'react';
import { Worker } from '@react-pdf-viewer/core';
// Import the main component
import { Viewer } from '@react-pdf-viewer/core';
import { SpecialZoomLevel } from '@react-pdf-viewer/core';
// Import the styles
import '@react-pdf-viewer/core/lib/styles/index.css';

import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import styles
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

function PdfViewer(props) {
    const url = props.location.state;
    // console.log(url)
    // const defaultLayoutPluginInstance = defaultLayoutPlugin();
    return (
        <div className='width_pdf'>
            <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
                <Viewer
                    // plugins={[defaultLayoutPluginInstance]}
                    fileUrl={url[0]}
                    defaultScale={SpecialZoomLevel.PageFit}
                />
            </Worker>
        </div>
    )
}

export default PdfViewer