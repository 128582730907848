import React from 'react'
import { Box, Button, Card, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios';
import * as Yup from 'yup';
import { useState } from 'react';
import URL, { PasswordpublicKey } from '../../../../utility/apiurl';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { localstore } from '../../../../localStore/localStore';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';

function CreatePhrAddress(props) {
    const dispatch = useDispatch()
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    // console.log(result, "resulttt")
    const transactionId = result.transactionId
    const sessionId = result.sessionId
    const AbhaNumber = result.AbhaNumber
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [checkid, setCheckid] = useState(false);
    let ibm = useSelector((state) => state.medx.ibmpdata);
    const aadhar = useSelector((state) => state.abha.aadhartokenHin)
    // console.log(aadhar, "adharrrrrrrrrrrrrrrrrr")
    const xtoken = aadhar.xtoken

    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()

    const usernameValidationSchema = Yup.object().shape({
        username: Yup.string()
            .matches(
                /^[A-Za-z0-9]+([._][A-Za-z0-9]+)?$/,
                'Username should not contain spaces and can have one dot or underscore in between alphanumeric characters. Special characters cannot be at the beginning or end.'
            )
            .min(8, 'Username should be at least 8 characters.')
            .max(18, 'Username should be at most 18 characters.')
            .required('Username is required'),
        password: Yup.string()
            .matches(/\w*[a-z]\w*/, 'Password must have a small letter')
            .matches(/\w*[A-Z]\w*/, 'Password must have a capital letter')
            .matches(/\d/, 'Password must have a number')
            .matches(/[!@#$%^&*()\-_"=+{}; :,<.>]/, 'Password must have a special character')
            .min(8, ({ min }) => `Password must be at least ${min} characters`)
            .required('Password is required'),
        confirmpassword: Yup.string()
            .oneOf([Yup.ref('password')], 'Passwords do not match')
            .required('Confirm password is required'),
    })

    const handleLink = async (jwtResponsetoken) => {
        let d = {};
        try {
            const response = await axios.post(
                `${URL.abha}link/phrAddress/${primaryId}`,
                d,
                {
                    headers: {
                        'X-Token': `Bearer ${jwtResponsetoken}`,
                        'gatewayToken': 'medxperts',
                        'Authorization': `Bearer ${token}`,
                    },
                }
            );
            // console.log(response.data, 'linking successful');
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryId,
                    token,
                ),
            );
            dispatch(getAbhaProfile(primaryId, token));
            setAlertmessage('Data registered Successfully!');
            setAlertstatus('success');
            props.setShow(7)
        } catch (error) {
            console.log(error.message, 'in linking');
            setAlertmessage(error.message);
            setAlertstatus('error');
        }
    };
    return (
        <div>
            <Typography sx={{ fontSize: { xs: 17, sm: 17, md: 17 }, color: '#007DCD', fontWeight: 600, mt: { md: 2, sm: "15px", xs: "10px" } }}>
                Create New PHR Address
            </Typography>
            <Paper elevation={3} sx={{ p: 3, mt: 1, display: 'flex', justifyContent: 'center' }}>
                <Box sx={{ maxWidth: '450px', width: '100%' }}>
                    <Typography sx={{ fontStyle: 'inherit', fontSize: { xs: 16, sm: 18, md: 16 }, fontWeight: 600 }}>
                        PHR address
                    </Typography>
                    <Formik
                        validationSchema={usernameValidationSchema}
                        initialValues={{ username: '', password: '', confirmpassword: '' }}
                        onSubmit={async (values, { setSubmitting }) => {
                            try {
                                let d = { healthId: values.username };
                                let data = {
                                    password: values.password,
                                    mobileOrAadhaar: 'mobile',
                                    sessionId: sessionId,
                                    phrAddress: `${values.username}@sbx`,
                                };
                                let data1 = {
                                    password: values.password,
                                    abhaPassword: 'password',
                                    transactionId: transactionId,
                                    phrAddress: `${values.username}@sbx`,
                                };

                                // First API call
                                setLoading(true);
                                const existsByHealthIdResponse = await axios.post(`${URL.abha}existsByHealthId/${primaryId}`, d, {
                                    headers: {
                                        gatewayToken: 'medxperts',
                                        Authorization: `Bearer ${token}`
                                    },
                                });

                                setCheckid(existsByHealthIdResponse.data.status);

                                // Second API call
                                if (existsByHealthIdResponse.status === 200 || existsByHealthIdResponse.status === 201) {
                                    if (AbhaNumber === true) {
                                        const createPhrAddressResponse = await axios.post(`${URL.abha}registration/hid/create/phrAddress/${primaryId}`, data1, {
                                            headers: {
                                                gatewayToken: 'medxperts',
                                                Authorization: `Bearer ${token}`,
                                            },
                                        });

                                        dispatch({
                                            type: "PHRADDRESS1", payload: createPhrAddressResponse.data.phrAdress
                                        });

                                        props.setShow(7);
                                    } else {
                                        const createPhrAddressResponse = await axios.post(`${URL.abha}mobile/create/phrAddress/${primaryId}`, data, {
                                            headers: {
                                                gatewayToken: 'medxperts',
                                                Authorization: `Bearer ${token}`,
                                            },
                                        });

                                        const phraddress = createPhrAddressResponse.data.phrAddress;
                                        dispatch({
                                            type: "PHRADDRESS", payload: phraddress
                                        });

                                        if (aadhar.healthIdNumber) {
                                            // Additional logic for linking if needed
                                            let ibmp = ibm?.identifier
                                                ? { ...ibm }
                                                : { ...ibm, identifier: [] };
                                            let da = {
                                                ...ibmp,
                                                identifier: [
                                                    ...ibmp.identifier,
                                                    {
                                                        system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                                        value: aadhar.healthIdNumber,
                                                        type: {
                                                            coding: [
                                                                {
                                                                    // code: 'JHN',
                                                                    code: 'HIN',
                                                                },
                                                            ],
                                                        },
                                                    },
                                                ],
                                            };

                                            da.identifier.push({
                                                system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                                value: phraddress,
                                                type: {
                                                    coding: [
                                                        {
                                                            code: 'ABHA', // or any other code you want to use for Healthid
                                                        },
                                                    ],
                                                },
                                            });
                                            axios
                                                .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                                                    headers: {
                                                        Authorization: `Bearer ${token}`,
                                                    },
                                                })
                                                .then(res => {
                                                    handleLink(xtoken)
                                                }).catch(err => {
                                                    console.log(err.message, 'error in update');
                                                });
                                        } else {
                                            props.setShow(7);
                                        }
                                    }
                                }
                            } catch (error) {
                                if (error.response && error.response.data && error.response.status === 400) {
                                    const responseText = error.response.data.message;
                                    const errorMessagePattern = /"message":"(.*?)"/;
                                    const match = responseText.match(errorMessagePattern);

                                    if (match && match[1]) {
                                        const errorMessage = match[1];
                                        setAlertmessage(errorMessage);
                                        setAlertstatus('error');
                                    }
                                } else {
                                    setAlertmessage(error.message);
                                    setAlertstatus('error');
                                }
                            } finally {
                                setLoading(false);
                                setSubmitting(false); // Set submitting to false to allow the form to be submitted again
                            }
                        }}
                    >
                        {/* Rest of your Formik component */}
                        {({ handleSubmit, isValid, values, errors, setFieldTouched, handleChange, handleBlur, touched, isSubmitting }) => (
                            <Box>
                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: 'whitesmoke',
                                            mt: 2,
                                        }
                                    }}
                                    placeholder={'User Name'}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={values.username}
                                    onChange={(e) => {
                                        handleChange('username')(e); // Handle the change
                                        setAlertmessage(''); // Clear the alert message
                                    }}
                                    onBlur={handleBlur('username')}
                                    autoComplete='off'
                                    InputProps={{
                                        endAdornment: <Typography variant="body1">@abdm</Typography>,
                                    }}
                                // error={Boolean(errors.username)}
                                // helperText={errors.username}
                                />
                                {errors.username && touched.username && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {errors.username}</Typography>
                                )}
                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: 'whitesmoke',
                                            mt: 2
                                        }
                                    }}
                                    placeholder={'Password'}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={values.password}
                                    onChange={(e) => {
                                        handleChange('password')(e); // Handle the change
                                        setAlertmessage(''); // Clear the alert message
                                    }}
                                    onBlur={handleBlur('password')}
                                    type="password"
                                // error={touched.password && Boolean(errors.password)}
                                // helperText={touched.password && errors.password}
                                />
                                {errors.password && touched.password && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {errors.password}</Typography>
                                )}
                                {/* <ErrorMessage name="email" component={Typography} variant="body2" style /> */}
                                <TextField
                                    sx={{
                                        ".MuiInputBase-root": {
                                            borderRadius: 2,
                                            backgroundColor: 'whitesmoke',
                                            mt: 2

                                        }
                                    }}
                                    placeholder={'Confirm Password'}
                                    size="small"
                                    fullWidth
                                    variant="outlined"
                                    value={values.confirmpassword}
                                    onChange={(e) => {
                                        handleChange('confirmpassword')(e); // Handle the change
                                        setAlertmessage(''); // Clear the alert message
                                    }}
                                    onBlur={handleBlur('confirmpassword')}
                                    type="password"
                                // error={touched.password && Boolean(errors.confirmpassword)}
                                // helperText={touched.confirmpassword && errors.confirmpassword}
                                />
                                {errors.confirmpassword && touched.confirmpassword && (
                                    <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                        {errors.confirmpassword}</Typography>
                                )}
                                {alertmessage && <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />}
                                <Grid mt={2} container justifyContent="center">
                                    <Grid item>
                                        <Button type='submit' sx={{ borderRadius: 3, mt: 2, width: "200px" }} variant="contained" color="primary"
                                            onClick={handleSubmit}
                                            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                                        >
                                            {'Create New Address'}
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Box>
                        )}
                    </Formik>
                </Box>
            </Paper>
        </div >

    )
}

export default CreatePhrAddress;