import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CardContent, CircularProgress, Grid, Typography } from '@mui/material'
import hospitalIcon from '../../../../assets/home/bookapp_assets/hospital.svg'
import { useDispatch, useSelector } from 'react-redux'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import URL from '../../../../utility/apiurl'
import axios from 'axios'
import { localstore } from '../../../../localStore/localStore'
import { CheckCircle } from '@mui/icons-material'
import { getAbhaMobile } from '../../../../redux/Actions/Actions'
import { useNavigate } from 'react-router-dom'
import AuthRecords from './AuthRecords'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp'
import { getProviderById } from '../../../../redux/Actions/recordActions'

const NotApplicable = (props) => {
    return < Typography sx={{ mt: 1, fontSize: '14px', fontWeight: 'bold', }}>N/A</Typography>
}

const Information = (props) => {
    return < Typography sx={{ mt: 1, fontWeight: 'bold', fontSize: '14px' }}>{props.title}</Typography>

}
function FetchRecords(props) {
    const { list } = props
    const mobiledata = useSelector((state) => state.abha.mobileprofile)
    console.log(list, "mobiledata")
    const [loading, setLoading] = useState(false)
    const [matchingdata, setMatchingdata] = useState('');
    const [referenceNumber, setReferenceNumber] = useState(null);
    const [statuscode, setStatuscode] = useState(null);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [otp, setOtp] = useState('');
    const dispatch = useDispatch();
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [show, setShow] = useState(1)
    const [open, setOpen] = useState(false);
    const navigate = useNavigate()
    let providerdata = useSelector(state => state.records.providerdata);

    useEffect(() => {
        dispatch(getAbhaMobile(primaryId, token))
        dispatch(getProviderById(list.identifier.id, token, primaryId))
    }, [])
    const handlefetchRecords = async () => {
        // console.log('hellooooooooooo');
        setLoading(true);
        let data = {
            id: list.identifier.id,
        };
        await axios
            .post(`${URL.consents}careContexts/discover/${primaryId}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(res => {
                console.log(res.data, 'setMatchingdata');
                setAlertopen(true);
                setAlertmessage('Successfully fetch the records');
                setAlertstatus('success');
                setMatchingdata(res.data);
                setStatuscode(res.data.status);
                // setLoading(false);
                setShow(2)
            })
            .catch(err => {
                console.log(err, 'error');
                setStatuscode(err.response.status);
                setLoading(false);
                setAlertopen(true);
                setAlertmessage('Please Try Again');
                setAlertstatus('error');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleLink = async () => {
        setLoading(true);
        let data = {
            transactionId: matchingdata.transactionId,
            phrAddress: matchingdata?.patient?.referenceNumber,
            careContextRefId: matchingdata.patient.careContexts[0].referenceNumber,
        };
        // console.log(mobiletoken);
        await axios
            .post(`${URL.consents}links/link/init/${primaryId}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(res => {
                // console.log(res.data);
                setReferenceNumber(res.data.link.referenceNumber);
                setOpen(true)
                // setLoading(false);
            })
            .catch(err => {
                setAlertmessage(err.message)
                setAlertstatus('error')
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handletryAgain = () => {
        setMatchingdata('');
        setStatuscode(null);
        setAlertopen(false);
        setAlertmessage('');
        setAlertstatus('');
        props.setShow(1);
    }
    return (
        <Box sx={{ mt: { xs: 4, sm: 4, md: 3, }, p: "0px 0px" }}>
            <Box sx={{ display: 'flex', gap: 3, ml: 3 }}>
                <Box sx={{ alignItems: 'center', justifyContent: 'center' }}>
                    {
                        providerdata.orgImg ?
                            < img src={providerdata.orgImg}
                                alt=" hospital" style={{ width: 45, height: 45, borderRadius: '5px' }} /> :
                            < img src={hospitalIcon}
                                alt=" hospital" style={{ width: 40, height: 40, background: '#D4F4F8', padding: 5, borderRadius: '5px' }} />
                    }
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 600 }}> {list.identifier.name}</Typography>
                    <Typography sx={{ fontSize: 13 }}>
                        address:{list?.address || 'N/A'} ,city:{list?.city || 'N/A'}, pincode:{list?.pinCode || 'N/A'}
                    </Typography>
                </Box>
            </Box>
            {/* {show === 1 ? ( */}
            {/* <> */}
            {matchingdata ? (
                <Box sx={{ mt: 1, ml: 3 }}>
                    <Typography sx={{ fontWeight: 600 }}>Matching Results</Typography>
                    <Typography sx={{ mt: 1 }}>Matching with Phone number</Typography>
                    {/* <Card sx={{ p: "10px 30px", bgcolor: '#E8FAFC ', mt: 1, borderRadius: 3 }}>
                        <CardContent>
                            <Box sx={{ display: 'flex', gap: 2 }}>
                                <Box>
                                    <CheckCircle sx={{ color: '#033299' }} />
                                </Box>
                                <Box>
                                    <Typography sx={{ fontWeight: 600 }}>{matchingdata.patient.display}</Typography>
                                    <Typography sx={{ mt: "5px", fontSize: 14 }}>Patient ID/MR NO:N/A</Typography>
                                    <Typography sx={{ mt: "5px", fontSize: 14 }}> Registration On :N/A</Typography>
                                </Box>
                            </Box>
                        </CardContent>
                    </Card> */}
                    <Card sx={{ mt: '5px', p: "10px 30px", bgcolor: '#E8FAFC ', borderRadius: 3 }}>
                        <CardContent>
                            {matchingdata?.patient?.careContexts?.length > 0 ?
                                matchingdata.patient.careContexts.map((e, i) => (

                                    <Box sx={{ display: 'flex', gap: 2 }}>
                                        <Box>
                                            <CheckCircle sx={{ color: '#033299' }} />
                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontWeight: 600 }}>{e.display}</Typography>
                                            <Typography sx={{ mt: "5px" }}>ref: {e.referenceNumber}</Typography>
                                        </Box>
                                    </Box>
                                )) : <Typography sx={{ textAlign: 'center' }}>No data Available</Typography>}
                        </CardContent>
                    </Card>

                    <Box sx={{ mt: 3, textAlign: 'center' }}>
                        <Button variant='contained' sx={{ width: '160px', borderRadius: 2 }} startIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null} onClick={handleLink}>Yes,Its Me</Button>
                    </Box>
                    <Box sx={{ textAlign: 'center', mb: { xs: 10, sm: 10, md: 1 } }}>
                        <Button variant='outlined' sx={{ width: '160px', mt: 3, borderRadius: 2, border: '1px solid red', color: 'red' }} onClick={() => { navigate('/records/newrecords') }}>Cancel</Button>
                    </Box>
                </Box>
            ) : null}

            {!matchingdata && statuscode !== 404 ? (
                <>
                    <Typography sx={{ fontWeight: 600, mt: 2, ml: 1 }}>Search Records Matching with Details</Typography>
                    <Card sx={{ p: "10px 30px", bgcolor: '#E8FAFC ', mt: 1, borderRadius: 3 }}>
                        <Grid container >


                            <Grid item xs={4}>
                                <Typography sx={{ mt: 1 }}>ABHA Address</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {mobiledata.id ?
                                    <Information title={mobiledata.id} /> : <NotApplicable />
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ mt: 1 }}>Name</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {mobiledata.fullName ?
                                    <Information title={mobiledata.fullName} /> : <NotApplicable />
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ mt: 1 }}>Gender</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {mobiledata.gender ?
                                    <Information title={mobiledata.gender} /> : <NotApplicable />
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ mt: 1 }}>DOB</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {mobiledata.dateOfBirth ?
                                    < Typography sx={{ mt: 1, fontSize: '14px', fontWeight: 'bold' }}> {mobiledata?.dateOfBirth?.date} -{' '}
                                        {mobiledata?.dateOfBirth?.month} -{' '}
                                        {mobiledata?.dateOfBirth?.year}</Typography> : <NotApplicable />
                                }
                            </Grid>
                            <Grid item xs={4}>
                                <Typography sx={{ mt: 1 }}>Mobile</Typography>
                            </Grid>
                            <Grid item xs={8}>
                                {mobiledata.mobile ?
                                    <Information title={mobiledata.mobile} /> : <NotApplicable />
                                }
                            </Grid>
                        </Grid >
                    </Card>

                    <Typography sx={{ fontWeight: 600, mt: 2, ml: 1 }}>Additional Details </Typography>
                    <Box mt={1}>
                        <TextInputField placeholder="Patient ID / MR number" />
                    </Box>
                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )}
                    <Box sx={{ textAlign: 'center', mt: 5, mb: { xs: 5, sm: 10 } }}>
                        <Button variant='contained' sx={{ width: '200px', borderRadius: 3 }} onClick={handlefetchRecords} startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null}>
                            Fetch Records
                        </Button>
                    </Box>
                </>) : null}
            {
                statuscode === 404 ? (
                    <Box sx={{ mt: 2, ml: 3 }}>
                        <Typography sx={{ fontWeight: 600 }}>Matching Results</Typography>

                        <Typography sx={{ mt: 1 }}>No Records Found</Typography>
                        <Box sx={{ mt: 10, textAlign: 'center' }}>
                            <Button variant='contained' sx={{ width: '160px', borderRadius: 2 }} onClick={handletryAgain}>Try Again</Button>
                        </Box>
                        <Box sx={{ textAlign: 'center' }}>
                            <Button variant='outlined' sx={{ width: '160px', mt: 3, borderRadius: 2, border: '1px solid red', color: 'red' }} onClick={() => props.setShow(1)}>Cancel</Button>
                        </Box>
                    </Box>) : null}

            {/* </>) : show === 2 ? (
                    <FetchRecords1 matchingdata={matchingdata} statuscode={statuscode} list={list} />
                ) : null
            } */}
            <AuthRecords open={open} setOpen={setOpen} mobile={mobiledata.mobile} referenceNumber={referenceNumber} />
        </Box >
    )
}

export default FetchRecords