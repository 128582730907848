import React, { useRef, useState } from 'react'
import { Box, Card, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Slide, TextField, Typography } from '@mui/material';
import CommonButton, { OutlinedButton } from '../../../atoms/CommonButton';
import consentIcon from '../../../../assets/home/consent_module/consent.svg'
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { ArrowForwardIos, Close } from '@mui/icons-material';
import PinInputField from '../../../atoms/TextFields/PinInputField';
import { useDispatch, useSelector } from 'react-redux';
import hospitalIcon from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { getConsents } from '../../../../redux/Actions/consentActions';
import axios from 'axios';


function DenyDialog({ handleClose, item, setShow, time, date, expirydate, expirytime }) {
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [reason, setReason] = useState('')
    const [loading, setLoading] = useState(false)
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()
    let consentdata = useSelector(state => state.consent.consentdata);
    const abhadata = useSelector(state => state.abha.abhaprofile)
    console.log(item, "consentdata")

    const handleDeny = async () => {
        let data = { 'requestId': item.id || item.requestId, 'reason': reason };
        setLoading(true);
        if (reason) {
            await axios.post(`${URL.consents}consentrequest/deny/${primaryId}/${abhadata?.AbhaAddress}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then(res => {
                dispatch(getConsents(primaryId, abhadata?.AbhaAddress, 'ALL', token));
                handleClose();
                setAlertstatus('success');
                setAlertmessage('Request denied Successfully!');

            })
                .catch(err => {
                    setLoading(false);
                    setAlertstatus('error');
                    setAlertmessage(err.message);

                });
        }
        else {
            setAlertstatus('error');
            setAlertmessage('Please enter valid reason!');

        }
    }

    const handleCancel = () => {
        handleClose()
        setShow(1)
    }
    return (
        <div>

            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                    <img src={consentIcon} alt='consent' />
                    <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 18 }, fontWeight: 600 }}>Deny Consent</Typography>
                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{
                '&::-webkit-scrollbar': {
                    display: 'none',
                },
            }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        {/* {item?.requester?.name ? */}
                        {/* <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '16px' } }} color="text.secondary" gutterBottom>
                                {item?.requester?.name}
                            </Typography> */}
                        {/* :  */}
                        <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                            Requester Name
                        </Typography>
                        {/* } */}
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', }}>
                            {/* {hospital.orgImg ? */}
                            {/* // <img src={hospital.orgImg} alt="orgimg" width={45} height={45} style={{ borderRadius: '5px', marginLeft: "12px" }} />
                                // : */}
                            <img src={hospitalIcon} alt="hospital" style={{ width: 50, height: 50 }} />
                            {/* } */}
                            <Box>
                                {consentdata?.requester?.name ?
                                    <Typography sx={{ fontSize: { xs: '16px', sm: '14px', md: '14px' }, fontWeight: 600 }}>{consentdata?.requester?.name}</Typography> : <Typography sx={{ fontSize: { xs: '13px', sm: '18px', md: '17px' }, fontWeight: 500 }}>Medxperts Health Locker</Typography>}

                                <Typography sx={{ mt: "2px", fontSize: { xs: '13px', sm: '14px', md: '14px' } }}>{consentdata?.purpose?.text}</Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box>
                        <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 15 }, fontWeight: 600 }}>Requested On</Typography>
                        <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }} >
                            {date} | {time}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 15 }, fontWeight: 600, mt: 1 }}>Consent Expiry </Typography>
                        <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }} >
                            {expirydate} | {expirytime}
                        </Typography>
                    </Box>
                </Box>
                <Box>
                    <Typography sx={{ fontWeight: 600 }}>Reason for Reject <Typography sx={{ ml: "2px" }} component={'span'}>(Optional)</Typography></Typography>
                    <Box sx={{ p: '0px 20px' }}>
                        <TextField
                            sx={{
                                // borderRadius: 2
                                ".MuiInputBase-root": {
                                    borderRadius: "10px 0px 10px 10px",
                                    backgroundColor: "whitesmoke",
                                    mt: "10px",
                                    height: '150px'
                                },
                            }}
                            fullWidth
                            name="Outlined"
                            placeholder={'Write your reason for deny the consent request'}
                            variant="outlined"
                            multiline
                            rows={5}
                            value={reason}
                            onChange={(e) => {
                                setReason(e.target.value)
                                setAlertmessage('')
                            }}
                        />
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    </Box>
                </Box>
            </DialogContent>
            <Divider />
            <DialogActions sx={{ justifyContent: 'center', gap: 2, m: '10px 0px' }}>
                {/* <Box sx={{ m: "10px 0px" }}> */}
                <OutlinedButton border="1px solid red" color="black" width="100px" text='Cancel' onClick={handleCancel} />
                <CommonButton bgcolor="red" width="150px" text="Deny Access" disabled={loading || !reason} endIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null} onClick={handleDeny} />
                {/* </Box> */}
            </DialogActions>
        </div>
    )
}

export default DenyDialog