import React, { useEffect, useState } from 'react'
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, Divider, Grid, IconButton, Tooltip, Typography } from '@mui/material';
import SuggestOrgIcon from '../../../../assets/home/profile_assets/orglogo.svg'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { ArrowCircleRightOutlined, Close, ExpandMore } from '@mui/icons-material';
import { localstore } from '../../../../localStore/localStore';
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getFollowOrganisation, getOrgFollowCount } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';


function MyProviders() {
    const token = localstore.getToken()
    const patientId = localstore.getPatientId()
    // console.log(each, "follorggggg")
    const [open, setOpen] = React.useState(false);
    const [orgList, setOrgList] = useState([])
    const [orgId, setOrgId] = useState()
    const orgsdata = useSelector((state) => state.medx.suggestedFollowOrganizations)
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    const limit = 50;
    const offset = 0;
    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        if (orgsdata.length > 0) {
            setOrgList(orgsdata)
        }
    }, [orgsdata])

    useEffect(() => {
        if (patientId) {
            dispatch(getOrgFollowCount(patientId))
        }
        dispatch(getFollowOrganisation(limit, offset, patientId))

    }, [patientId])


    const handleClickFollow = (orgId) => {
        setOpen(true);
        setOrgId(orgId)
    }

    const handleUnfollowperson = () => {
        if (token?.length > 0 && patientId !== 0 && orgId !== 0) {
            axios.put(`${URL.unfollowOrg}${patientId}/page/${orgId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then(res => {
                    console.log(res, "unfollow")
                    setOpen(false)
                    let data = orgList.filter((e) => {
                        if (e.id !== orgId) {
                            return e;
                        }
                    });
                    setOrgList(data);
                    dispatch(getOrgFollowCount(patientId));
                    // setTimeout(() => {
                    //     window.location.reload();
                    // }, 1000);
                })
                .catch(err => { console.log(err.message); });
        }
    }


    const handleBack = () => {
        navigate({ pathname: '/medxperts', state: 'doctor' })
    }
    return (
        <Box sx={{
            mt: "10px", p: "1px 30px"

        }}>

            {/* {token ? */}

            <Box mt={2}>
                {orgList.length > 0 ? (
                    orgList.map((each, index) => (
                        <Card key={index} sx={{ p: { xs: '10px 5px', md: "12px" }, mt: "15px" }}  >
                            <Grid container key={index} alignItems={"center"}>
                                <Grid
                                    item
                                    xs={3}
                                    sm={2}
                                    md={1.5}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/org/${each.id}`)}
                                >
                                    {each?.orgPageImage &&
                                        each?.orgPageImage &&
                                        each?.orgPageImage !== null ? (
                                        <Box ml={2}>
                                            <img
                                                src={each?.orgPageImage}
                                                alt="user"
                                                width={42}
                                                height={42}
                                            />
                                        </Box>
                                    ) : (
                                        <Box
                                            ml={2}
                                            sx={{
                                                width: { xs: 30, md: 45 },
                                                height: { xs: 30, md: 45 },
                                                bgcolor: "#A8ECFF",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                borderRadius: "5px",
                                            }}
                                        >
                                            <img src={SuggestOrgIcon} alt="user" />
                                        </Box>
                                    )}
                                </Grid>
                                <Grid
                                    item
                                    xs={5}
                                    sm={8}
                                    md={8}
                                    sx={{ cursor: "pointer" }}
                                    onClick={() => navigate(`/org/${each.id}`)}
                                >
                                    <Typography>
                                        {each?.organisation?.name > 20 ? (
                                            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                {each?.organisation?.name.slice(0, 19)}..
                                            </Typography>
                                        ) : (
                                            <Typography sx={{ fontWeight: 600, fontSize: 14 }}>
                                                {each?.organisation?.name}
                                            </Typography>
                                        )}
                                    </Typography>
                                    <Typography>
                                        {each?.organisation?.identifier[0]?.type.text ? (
                                            each?.organisation?.identifier[0]?.type.text.length >
                                                25 ? (
                                                <Typography
                                                    sx={{ fontSize: { xs: 12, sm: 14, md: 14 }, fontWeight: 300 }}
                                                >
                                                    {each?.organisation?.identifier[0].type.text.slice(
                                                        0,
                                                        24
                                                    )}
                                                    ..
                                                </Typography>
                                            ) : (
                                                <Typography
                                                    sx={{ fontSize: { xs: 12, sm: 14, md: 14 }, fontWeight: 300 }}
                                                >
                                                    {each?.organisation?.identifier[0]?.type?.text}
                                                </Typography>
                                            )
                                        ) : (
                                            <Typography
                                                sx={{ fontSize: { xs: 12, sm: 14, md: 14 }, fontWeight: 300 }}
                                            >
                                                N/A
                                            </Typography>
                                        )}
                                    </Typography>
                                </Grid>
                                <Grid item xs={4} sm={2} md={2.5}>
                                    <Button
                                        variant="contained"
                                        sx={{
                                            height: 28,
                                            bgcolor: "blueviolet",
                                            "&:hover": { bgcolor: "blueviolet" },
                                            borderRadius: 3,
                                        }}
                                        onClick={() => handleClickFollow(each?.id)}
                                    >
                                        <Typography sx={{ fontSize: { xs: '12px', sm: '14px', md: '14px' } }}>   {t("following")}</Typography>
                                    </Button>
                                </Grid>
                            </Grid>
                        </Card>
                    ))
                ) : (
                    <Typography sx={{ textAlign: "center", fontWeight: 500, fontSize: { xs: '14px', sm: '14px', md: "17px" } }}>
                        {t("noonefollowed")}
                    </Typography>
                )}
            </Box>
            {/* : <Typography>There is no access please login</Typography>} */}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle
                    id="alert-dialog-title"
                    sx={{ display: "flex", alignItems: "center", gap: 10 }}
                >
                    <Typography sx={{ fontWeight: 600 }}>
                        {" "}
                        {t("unfollowbtn")}
                    </Typography>
                    <IconButton> <Close onClick={handleClose} /></IconButton>
                </DialogTitle>
                <Divider />
                <DialogActions>
                    <Button onClick={handleClose} variant='outlined'>
                        {t("cancel")}
                    </Button>
                    <Button
                        sx={{
                            bgcolor: "blueviolet",
                            ":hover": {
                                bgcolor: "blueviolet",
                            },
                        }}
                        onClick={handleUnfollowperson}
                        autoFocus
                        variant="contained"
                    >
                        {t("unfollow")}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default MyProviders