import { Box, Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'

function UserName(props) {
    const navigate = useNavigate()
    return (
        <>
            <Box sx={{ textAlign: 'center', mt: 4 }}>

                <Typography sx={{ fontWeight: 300 }}>Your UserName is </Typography>
                <Typography sx={{ fontWeight: 600, fontSize: '25px' }}>{`${props.username}`}</Typography>
                {/* <Button sx={{ mt: 2, height: 30, textTransform: 'capitalize', bgcolor: 'blueviolet', borderRadius: '20px', width: '150px', '&:hover': { bgcolor: 'blueviolet' } }} variant='contained' onClick={() => navigate('/login')}>Please Login</Button> */}
                <Typography mt={2}>please login below </Typography>
            </Box>
        </>
    )
}

export default UserName