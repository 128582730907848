import React, { useEffect } from 'react'
import { Box, Button, Card, Checkbox, FormControlLabel, FormGroup, Grid, IconButton, Paper, Radio, Tab, Tabs, Typography } from '@mui/material'
import { ExpandMore, FilterList } from '@mui/icons-material'
import searchblack from '../../../../assets-new/services-icons/search-Icon-black.svg'
import SearchBar from '../../../atoms/Searchbar'
import SearchIcon from '../../../../assets/home/Icon material-search.svg';
import orthopaedicsicon from '../../../../assets-new/services-icons/orthopaedics.svg'
import paeditricsicon from '../../../../assets-new/services-icons/paedritics.svg'
import gynaecologyicon from '../../../../assets/home/bookapp_assets/gastroenterology.svg'
import pulmonologisticon from '../../../../assets-new/services-icons/pulmonology.svg'
import dermotologyicon from '../../../../assets-new/services-icons/dermotologist.svg'
import locationicon from '../../../../assets-new/services-icons/location-Icon.svg'
import searchblue from '../../../../assets-new/services-icons/search-Icon-blue.svg'
import filterIcon from '../../../../assets-new/filter-Icon.svg'
import checkIcon from '../../../../assets-new/services-icons/check-Icon.svg'
import AllRelated from './AllRelated'
import { useState } from 'react'
import Image from '../../../atoms/Image'
import URL from '../../../../utility/apiurl'
import axios from 'axios'
import { localstore } from '../../../../localStore/localStore'
import { debounce } from 'lodash'
import CheckSymptoms from './CheckSymptoms'

const SpecialityCards = (props) => {

    return (
        <Paper elevation={2} sx={{ display: 'flex', justifyContent: 'space-between', p: "5px 10px", mt: '12px', }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, color: '#7D849A' }}>
                <img src={props.icon} alt="pharma" />
                <Box>
                    <Typography sx={{ fontSize: '14px' }}>{props.title}</Typography>
                    <Typography sx={{ fontSize: '11px' }}>{props.paragraph}</Typography>
                </Box>
            </Box>
            <Radio />
            {/* <Box
                sx={{
                    border: "1px solid #0118B4",
                    width: "15px",
                    height: "15px",
                    borderRadius: 5,
                }}
            >
                <img src={checkIcon} />
            </Box> */}
        </Paper>
    )
}

function Consultation() {
    const [speciality, setSpeciality] = useState('')
    const [list, setList] = useState([])
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredSpecialities, setFilteredSpecialities] = useState([]);
    const [latitude, setLatitude] = useState('')
    const [longitude, setLongitude] = useState('')
    const [location, setLocation] = useState('')
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [practitioner, setPractitioner] = useState([])
    // console.log(practitioner, "practitionerr")
    const token = localstore.getToken()
    const patientId = localstore.getPatientId()
    const [value, setValue] = useState(0);

    const tabInfo = [
        { label: "ALL Related", content: (<div> <AllRelated practitioner={practitioner} /></div>) },
        { label: "Doctors", content: (<div>Doctors </div>) },
        { label: "Facilities / Hospitals", content: (<div> Facilities</div>) },
        { label: "Services", content: (<div> Services </div>) },
        { label: "Offers", content: (<div>Offers </div>) },
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }
    useEffect(() => {
        navigator.geolocation.getCurrentPosition(position => {
            const { latitude, longitude } = position.coords;
            setLatitude(latitude)
            setLongitude(longitude)
            console.log(latitude, longitude)
            // use the latitude and longitude to get the user's address
            // fetch(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`)
            //     .then(res => res.json())
            //     .then(data =>
            //         // setUserAddress(data.address)
            //         console.log(data)
            //     )
        });
    }, []);

    const handleGetLocation = async () => {
        const data = {
            "latitude": latitude,
            "longitude": longitude,
            "patientId": patientId
        }
        await axios.post(`${URL.feedApi}addpatientlocation`, data, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
            .then(res =>
                setLocation(res.data)
            ).catch(err => {
                alert(err.message)
            })
    }

    useEffect(() => {
        SearchbySpeciality()
        SearchbyDoctorName()
    }, [])

    const SearchbySpeciality = async () => {
        await axios.get(`${URL.cliniapi}ValueSet?name=PracticeSettingCodeValueSet`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            setList(res?.data?.entry?.[0].resource?.compose?.include?.[0]?.concept)
            setFilteredSpecialities(res?.data?.entry?.[0].resource?.compose?.include?.[0]?.concept);
        })
    }
    const SearchbyDoctorName = async () => {
        await axios.get(`${URL.patient}Practitioner/searchByName/raju/17.4408924/78.3916304`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data, "resssssss")
        })
    }
    useEffect(() => {
        axios.get(`${URL.cliniapi}Practitioner`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }).then(res => {
            console.log(res.data, "allpractioners")
            setPractitioner(res?.data?.entry)
        })
    }, [])

    const getLocationString = () => {
        if (location) {
            return `${location.subUrban}, ${location.district}, ${location.city}, ${location.state}, ${location.country} - ${location.pincode}`;
        } else {
            return 'Set Your Location';
        }
    };

    const handleSearchSpeciality = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchTerm(searchTerm);
        if (searchTerm) {
            const resultArray = list.filter((post) => post.display.toLowerCase().includes(searchTerm));
            setFilteredSpecialities(resultArray);
        } else {
            setFilteredSpecialities(list);
        }
    };
    return (
        <div>
            <Box mt={2}>
                <Typography component='p' sx={{ fontSize: '14px', fontWeight: 600 }}>
                    Search Doctors, Hospital or Service for booking
                </Typography>
            </Box>
            <Grid item container mt={2} columnSpacing={2}>
                <Grid item md={3}>
                    <SearchBar icon={locationicon} placeholder="Jaihind Enclave,Madhapur" value={getLocationString()} onClick={handleGetLocation} />
                    <Box sx={{ mt: 1, display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Image src={filterIcon} alt="filter" style={{ width: '20px', height: '20px' }} />
                        <Box sx={{ display: 'flex', alignItems: 'center', }}>
                            <Typography>Filter by Speciality :</Typography>
                            <IconButton>  <ExpandMore sx={{ color: 'black' }} /></IconButton>
                        </Box>
                    </Box>
                    <SearchBar icon={searchblack} height="35px" placeholder="Search Select Speciality" value={searchTerm} onChange={handleSearchSpeciality} />

                    <Box sx={{
                        overflowY: 'scroll', height: '60vh', '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                        {filteredSpecialities.map((item, index) => (
                            <Box key={index}><SpecialityCards title={item.display} paragraph="Bone & Muscle Care" icon={orthopaedicsicon} /></Box>
                        ))}
                        {/* <SpecialityCards title="Paediatrics" paragraph="Child & Neonate Care" icon={paeditricsicon} />
                        <SpecialityCards title="Gynaecology" paragraph="Woman Health Care" icon={gynaecologyicon} />
                        <SpecialityCards title="Pulmonologist" paragraph="Respiratory Care" icon={pulmonologisticon} />
                        <SpecialityCards title="Dermotologist & Trichology" paragraph="Skin & Hair Care" icon={dermotologyicon} />
                        <SpecialityCards title="Dermotologist & Trichology" paragraph="Skin & Hair Care" icon={dermotologyicon} />
                        <SpecialityCards title="Dermotologist & Trichology" paragraph="Skin & Hair Care" icon={dermotologyicon} /> */}
                    </Box>
                </Grid>

                <Grid item md={9}>
                    <Grid item container  >
                        <Grid item md={7.5}>

                            <Card sx={{ p: "2px 10px" }}>
                                <SearchBar icon={searchblue} placeholder="Search By Doctor Name,speciality,or Clinic" />
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    TabIndicatorProps={{ sx: { display: "none" } }}

                                    sx={{
                                        ".MuiTab-root": { minHeight: "5px", },
                                        "& button.Mui-selected": {
                                            backgroundColor: { xs: '#CDF4F9' },
                                            color: 'black',
                                            borderRadius: { xs: 2, sm: 2, md: 4 },
                                        },

                                        ".MuiTabs-flexContainer": {
                                            display: 'flex',
                                            justifyContent: 'space-evenly'
                                        },
                                        // "& button:hover": {},
                                        // "& button:active": { color: 'blue' },
                                    }}
                                >
                                    {tabInfo.map((tab, index) => (
                                        // <Tab sx={{ fontWeight: "bold", color: "black", fontSize: { md: '10px', lg: '16px' }, display: { xs: 'flex', md: 'block' }, flexDirection: { xs: 'column' }, gap: { xs: "5px" }, alignItems: { md: 'center' } }}
                                        <Tab sx={{
                                            fontWeight: value === index ? 600 : 500, p: { xs: 1, sm: 1, md: 1 },
                                            mt: { xs: '8px', sm: "5px", md: "8px" }, height: { xs: "25px", sm: "25px", md: "25px" },
                                            fontSize: '12px', display: { xs: 'flex', }, flexDirection: { xs: 'column', sm: 'column', md: 'row' }, gap: { xs: "5px", md: '5px' },
                                        }}
                                            key={index} label={tab.label} />
                                    ))}
                                </Tabs>

                                {tabInfo.map((tab, index) => (
                                    <Box key={index} hidden={value !== index} sx={{
                                        overflowY: 'scroll', height: '56vh', '&::-webkit-scrollbar': {
                                            display: 'none',
                                        },
                                    }}
                                    >
                                        {tab.content}
                                    </Box>
                                ))}

                            </Card>
                        </Grid>

                        <Grid item md={4.5} >

                            <CheckSymptoms />
                        </Grid>
                    </Grid>

                </Grid>

            </Grid></div>
    )
}

export default Consultation;