import React from 'react'
import { Box, Button, Card, Typography } from '@mui/material'
import MessageIcon from '../../../../../assets/home/profile_services/message.svg'
import DirectionIcon from '../../../../../assets/home/profile_services/direction.svg'
import MobileIcon from '../../../../../assets/home/profile_services/mobile.svg'
import { Chat, MoreHoriz, Phone } from '@mui/icons-material'
import BookAppointment from './BookAppointment'
import Menubar from '../../../../organisms/Menubar'
function DoctorServices({ user }) {

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Box mt={2}>
                <Card sx={{ p: 2, borderRadius: 3, background: 'whitesmoke' }}>
                    <Box sx={{ float: 'right' }}>
                        <Typography>Out-Patient Consultation</Typography>
                    </Box>

                    <Typography sx={{ fontWeight: 'bold', mt: 4 }}>
                        Organisation Name
                    </Typography>
                    <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}>
                        Address Line 1
                    </Typography>
                    <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}>
                        Area, City, State Pincode
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 5 }}>
                        <img src={MobileIcon} alt="mobile" />
                        <img src={MessageIcon} alt="message" />
                        <img src={DirectionIcon} alt="direction" />
                        <MoreHoriz />
                        <Button sx={{ borderRadius: 3, height: 28, bgcolor: '#388AAF' }} variant='contained' onClick={handleClickOpen}>Book Appointment</Button>
                    </Box>
                </Card>
                <Card sx={{ p: 2, borderRadius: 3, mt: 2, background: 'whitesmoke' }}>
                    <Typography sx={{ fontWeight: 'bold', mt: 1 }}>
                        Organisation Name
                    </Typography>
                    <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}>
                        Address Line 1
                    </Typography>
                    <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}>
                        Area, City, State Pincode
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 5 }} >
                        <Button sx={{ bgcolor: "#8786B4", color: 'black', height: 23, }} variant='contained' startIcon={<Phone />}>Talk</Button>
                        <Button sx={{ bgcolor: "#D4D3E1", color: 'black', height: 23, }} variant='contained' startIcon={<Chat />}>Chat</Button >
                        <Button sx={{ borderRadius: 3, height: 28, width: 150, bgcolor: '#388AAF' }} variant='contained' onClick={handleClickOpen}>Book Now</Button>
                    </Box>
                </Card >
            </Box >
            <BookAppointment open={open} handleClose={handleClose} user={user} />
        </>

    )
}

export default DoctorServices