import React, { useEffect, useState } from 'react'
import { Box, Button, Card, CircularProgress, IconButton, InputAdornment, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios';
import URL, { AadharPublicKey } from '../../../../utility/apiurl';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';

function AadharStep3(props) {

    const [mobilenum, setMobilenum] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [checklinked, setChecklinked] = useState(false);
    const result = useSelector((state) => state.abha.abhaAadhar)
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global");
    const jwtResponsetoken = useSelector((state) => state.abha.jwtResponsetoken)
    // console.log(jwtResponsetoken, "abhadataaaaaaaaaa3")

    const handleOnChangeMobile = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setMobilenum(inputValue.slice(0, 10));
    }

    // useEffect(() => {
    //   if (checklinked) {
    //     handleSkip()
    //   }
    // }, [checklinked])

    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = AadharPublicKey
    const ibm = useSelector((state) => state.medx.ibmpdata)
    // console.log(ibm, "ibmmmmmmmmm")

    const handleValidate = async () => {
        // props.setShow(4)

        setLoading(true);
        // const encrypt = new JSEncrypt()
        // encrypt.setPublicKey(publicKey)
        // const encryptnumber = encrypt.encrypt(mobilenum)
        let data = {
            mobile: mobilenum,
            txnId: result.txnId,
            mobileOrAadhaar: 'aadhaar',
        };
        await axios.post(`${URL.abha}v2/checkAndGenerateMobileOtp/${primaryId}`, data,
            {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then(async res => {
                console.log(res, "checkeeee")
                setChecklinked(res.data.mobileLinked);
                if (res.data.mobileLinked) {
                    // props.setShow(4)
                    setAlertmessage('This mobile number is already registered!');
                    setAlertstatus('success');

                }
                else {
                    props.setShow(12)
                }
                dispatch({
                    type: "NDHMAADHAR",
                    payload: mobilenum
                })
            }).catch(err => {
                setAlertmessage(err.response.data.message);
                setAlertstatus('success');
            }
            )
            .finally(() => { setLoading(false); });
    }

    const handleLink = async (jwtResponsetoken) => {
        let d = {};
        try {
            const response = await axios.post(
                `${URL.abha}link/phrAddress/${primaryId}`,
                d,
                {
                    headers: {
                        'X-Token': `Bearer ${jwtResponsetoken}`,
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            console.log(response.data, 'linking successful');
            dispatch(
                getPatientdatafromibm(
                    'Patient',
                    primaryId,
                    token,
                ),
            );
            dispatch(getAbhaProfile(primaryId, token));
            setAlertmessage('Data registered Successfully!');
            setAlertstatus('success');
            props.setShow(7)
        } catch (error) {
            setAlertmessage("PHR-Address is already exist try another aadhar number");
            setAlertstatus('error');
        }
    };


    const handleSkip = async () => {
        setLoading(true);
        let data = {
            txnId: result.txnId,
            //     // mobileOrAadhaar: 'aadhaar',
        };

        await axios.post(`${URL.abha}v2/createHealthIdByAdhaar/${primaryId}`, data, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        })
            .then(async res => {
                console.log('------------->>>> createHealthId Res', res);
                let healthid = res.data.healthId;
                let phrAddress = res.data.HealthId
                let healthNumber = res.data.healthIdNumber;
                let sessionId = res.data.sessionId;
                let data3 = res.data;
                let jwttoken = res.data.token
                let details = {
                    sessionId: sessionId,
                    mobile: mobilenum,
                }
                dispatch({
                    type: 'AADHARSTEP3_SESSIONID',
                    payload: details
                })

                dispatch({
                    type: "AADHARSTEP3_PHRADDRESS1",
                    payload: healthid
                })
                dispatch({
                    type: "AADHARSTEP3_PHRADDRESS2",
                    payload: phrAddress
                })


                axios
                    .get(`${URL.abha}HealthIdNumber/${primaryId}`, {
                        headers: { "Authorization": `Bearer ${token}` }
                    }).then(res => {

                        var HealthIdNumber1 = res.data.healthIdNumber;
                        const xtoken = res.data.token
                        const adhardetails = {
                            healthIdNumber: HealthIdNumber1,
                            xtoken: xtoken,
                            aadhartoken: true,
                        }
                        dispatch({
                            type: "AADHARSTEP3_TOKEN_HIN",
                            payload: adhardetails
                        })

                        dispatch({
                            type: "AADHARSTEP3_PHRADDRESS1",
                            payload: healthid
                        })
                        if (res.status === 200 || res.status === 201) {

                            dispatch(getAbhaProfile(primaryId, token))

                            if (healthid && healthNumber) {

                                let ibmp = ibm?.identifier
                                    ? { ...ibm }
                                    : { ...ibm, identifier: [] };
                                let da = {
                                    ...ibmp,
                                    identifier: [
                                        ...ibmp.identifier,
                                        {
                                            system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                            value: healthNumber,
                                            type: {
                                                coding: [
                                                    {
                                                        code: 'HIN',
                                                    },
                                                ],
                                            },
                                        },
                                    ],
                                };
                                if (healthid) {
                                    da.identifier.push({
                                        system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                        value: healthid,
                                        type: {
                                            coding: [
                                                {
                                                    code: 'ABHA', // or any other code you want to use for Healthid
                                                },
                                            ],
                                        },
                                    });
                                }
                                // } else {
                                //     da.identifier.push({
                                //         system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                //         value: phrAddress,
                                //         type: {
                                //             coding: [
                                //                 {
                                //                     code: 'ABHA', // or any other code you want to use for Healthid
                                //                 },
                                //             ],
                                //         },
                                //     });
                                // }
                                //  else {
                                axios
                                    .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                                        headers: {
                                            Authorization: `Bearer ${token}`,
                                        },
                                    })
                                    .then(res => {
                                        // handleLink(jwttoken, healthid)
                                        dispatch(
                                            getPatientdatafromibm(
                                                'Patient',
                                                primaryId,
                                                token,
                                            ),
                                        );
                                        props.setShow(7)
                                    })
                                    .catch(err => {
                                        console.log(err.message, 'error in update');
                                    });

                            } else {
                                props.setShow(13)
                            }
                        }
                    }).catch(err => {
                        console.log(err, 'Get HealthIdNUmber');
                    });

            }).catch(err => {
                console.log(err, 'error before updated');
            }).finally(() => {
                setLoading(false);
            });
    };
    return (
        <div>
            <Box sx={{ mt: { xs: "10px", sm: "15px", md: 2 } }}>
                <Typography sx={{ fontWeight: 600, color: '#007DCD', fontSize: 17 }}>
                    Verify Mobile Number
                </Typography>
            </Box>
            <Paper elevation={3}
                sx={{
                    p: 3,
                    mt: { xs: "10px", sm: 1, md: 1 },

                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center", // Center-align horizontally
                        justifyContent: "center", // Center-align vertically
                        textAlign: "center", // Center-align text within the card
                    }}
                >
                    <Typography
                        sx={{
                            fontStyle: "inherit",
                            fontSize: { xs: 14, sm: 16, md: 16 },
                            fontWeight: 500,
                        }}
                        varaint="h1"
                    >
                        Enter Mobile Number
                    </Typography>

                    <TextField
                        sx={{
                            ".MuiInputBase-root": {
                                borderRadius: 2,
                                backgroundColor: "whitesmoke",
                                mt: 2,
                            },
                            ".MuiOutlinedInput-notchedOutline": {
                                border: "none",
                            },
                            width: "350px",
                        }}
                        placeholder={"Enter your Mobile Number"}
                        size="small"
                        fullWidth
                        variant="outlined"
                        onChange={handleOnChangeMobile}
                        value={mobilenum}
                        name="mobilenum"
                        autoFocus
                        autoComplete="off"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <IconButton
                                        variant="outlined"
                                        sx={{
                                            borderRadius: 2,
                                            backgroundColor: "white",
                                            height: "32px",
                                        }}
                                    >
                                        +91
                                    </IconButton>
                                </InputAdornment>
                            ),
                        }}
                    />

                    {alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )}

                    <Box sx={{ mt: 3, textAlign: "center", mb: 2 }}>
                        {checklinked && mobilenum.length > 9 ? (
                            <Button
                                variant="contained"
                                sx={{ width: "120px", borderRadius: 2 }}
                                disabled={mobilenum?.length === 10 ? false : true}
                                onClick={handleSkip}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                {"Skip"}
                            </Button>
                        ) : (
                            <Button
                                variant="contained"
                                sx={{ bgcolor: "#1CB500", width: "150px", borderRadius: 2 }}
                                disabled={mobilenum?.length !== 10}
                                onClick={handleValidate}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                {t("validate")}
                            </Button>
                        )}
                    </Box>
                </Box>
                <Box mt={5}>
                    <Typography sx={{ color: "red" }}>*Note:</Typography>
                    <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14 } }}>
                        {t("mobilenumtxt")}
                    </Typography>
                </Box>
            </Paper>
        </div>
    );
}

export default AadharStep3