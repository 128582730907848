import React from 'react'
import axios from 'axios'
import { ArrowForward, Check, Link } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, Grid, Typography } from '@mui/material'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import URL from '../../../../utility/apiurl'
import { useHistory, useNavigate } from "react-router-dom";
import { useEffect } from 'react'
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions'
import { localstore } from '../../../../localStore/localStore'
import Abhalogo from '../../../../assets/home/services_assets/ABHA_Number_390923a281.svg'
import { useTranslation } from "react-i18next";
import { handleErrorResponse } from '../../../atoms/StatusError'



function LoginSelectPhr(props) {
    const phrs = useSelector((state) => state.abha.mappedphrAddress)
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const [abhatoken, setAbhatoken] = useState('')
    // console.log(phrs, "abmobileeeeeeeeeeee")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [phradd, setPhradd] = useState('');
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const result = useSelector((state) => state.abha.sessionIdandMobilenumber)
    // console.log(result, "result-------->>>")
    const abhaNumber = result.AbhaNumber
    const transactionId = result.transactionId
    const mobileNumber = result.mobile
    let abhadata = useSelector(state => state.abha.abhaprofile);
    // console.log(abhadata, "abhadtaaaaaaaaaaaaaaaaaaaaaaaa")
    const [errorMessage, setErrorMessage] = useState(''); // State for error message
    const [t, i18n] = useTranslation("global");

    const handleSelect = (e) => {
        // console.log(e, "eeeeeeeeeee")

        // if (phradd === Object.keys(e)[0]) {
        // setPhradd('');
        // } else {
        setPhradd(Object.keys(e)[0]);
        setErrorMessage('')
        // }
    }


    const handleOnLink1Next = async () => {
        setLoading(true)
        const data = {
            "patientId": phradd,
            "transactionId": transactionId
        }
        let value = abhaNumber === true ? "hid" : "mobileEmail"
        await axios.post(`${URL.abha}login/${value}/confirm/otp/${primaryId}`, data, {
            headers: {
                Authorization: `Bearer ${token}`,
                gatewayToken: "medxperts",
            }
        })
            .then(async res => {

                dispatch(getAbhaProfile(primaryId, token,));
                setAlertMessage('OTP verified successfully!');
                setAlertstatus('success');

                let ibmp = ibm?.identifier
                    ? { ...ibm }
                    : { ...ibm, identifier: [] };
                let da;
                let obj = ibmp.identifier.some(e => {
                    return e?.type?.coding[0]?.code === 'ABHA';
                });
                if (obj) {
                    let substitute = ibm.identifier?.map((e, i) => {
                        if (e?.type?.coding[0]?.code === 'ABHA') {
                            return { ...e, value: phradd };
                        } else {
                            return e;
                        }
                    });
                    da = { ...ibmp, identifier: substitute };
                } else {
                    da = {
                        ...ibmp,
                        identifier: [
                            ...ibmp.identifier,
                            {
                                system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                                value: phradd,
                                type: {
                                    coding: [
                                        {
                                            code: 'ABHA',
                                        },
                                    ],
                                },
                            },
                        ],
                    };
                }
                // console.log('---------ibmdataupdate', da);
                await axios
                    .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    })
                    .then(res => {
                        // console.log('update success');
                        setAlertMessage('Data registered Successfully!');
                        setAlertstatus('success');

                        dispatch(
                            getPatientdatafromibm(
                                'Patient',
                                primaryId,
                                token,
                            ),
                        );
                        navigate({ pathname: '/services', state: 'healthprofile' });

                    })
                    .catch(err => {
                        console.log(err.message, 'error in update');
                    });
            })
            .catch(err => {
                if (err.response.status === 400) {
                    console.log('----------Error2', err);
                    setAlertMessage('Oops something went wrong');
                    setAlertstatus('error');
                } else {
                    const { alertmessage, alertstatus } = handleErrorResponse(err)
                    setAlertMessage(alertmessage);
                    setAlertstatus(alertstatus);
                }
            }).finally(() => {
                setLoading(false)
            })
    }

    const handlelinked = () => {
        const isAlreadyLinked = phrs.some((e) => Object.keys(e)[0] === phradd && e[Object.keys(e)[0]] === 'available');
        if (isAlreadyLinked) {
            setErrorMessage('This PHR address is already linked choose another one');
        } else {
            setErrorMessage(''); // Clear the error message
        }
    }
    const handleClickOnMobi2 = () => {
        props.setShow(4)
    }
    return (
        <Box sx={{ mt: { xs: 4, sm: 2, md: 0 } }}>
            <Typography
                sx={{
                    fontStyle: "inherit",
                    fontSize: { xs: 16, sm: 16, md: 17 },
                    fontWeight: 600,
                }}
            >
                {t("existphr")}
            </Typography>
            <Card sx={{ p: 2, mt: { xs: 2, sm: 2, md: 2 } }}>


                <Box mt={2}>
                    {phrs.length > 0 ? (
                        phrs.map((e, i) => (
                            <Card
                                key={i}
                                sx={{
                                    borderRadius: 2,
                                    p: "5px 15px",
                                    mt: 1,
                                    bgcolor:
                                        e[Object.keys(e)[0]] === "available" ? "" : "#F5EDED",
                                }}
                            >
                                <Grid
                                    container
                                    spacing={2}
                                    sx={{ display: "flex", alignItems: "center" }}
                                >
                                    <Grid item xs={10.5} sm={11} md={11}>
                                        <Typography
                                            sx={{
                                                fontSize: { xs: "17px", sm: "18px", md: "19px" },
                                                color: "black",
                                            }}
                                        >
                                            {Object.keys(e)[0]}
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1.5}
                                        sm={1}
                                        md={1}
                                        sx={{ cursor: "pointer" }}
                                    >
                                        {e[Object.keys(e)[0]] === "available" ? (
                                            <Box
                                                sx={{
                                                    border: "1px solid #0118B4",
                                                    width: "25px",
                                                    height: "25px",
                                                    borderRadius: 5,
                                                }}
                                                onClick={() => handleSelect(e)}
                                            >
                                                {phradd === Object.keys(e)[0] && (
                                                    <Check
                                                        sx={{ color: "#0118B4" }}
                                                        name={t("credentialsData.createaccount.donebtn")}
                                                    />
                                                )}
                                            </Box>
                                        ) : (
                                            <Typography
                                                onClick={handlelinked}
                                                sx={{ fontSize: { sm: 12, md: 12 } }}
                                            >
                                                {t("linked")}
                                            </Typography>
                                        )}
                                    </Grid>
                                </Grid>
                            </Card>
                        ))
                    ) : (
                        <Typography
                            sx={{
                                fontSize: "20px",
                                fontWeight: "bold",
                                color: "black",
                                textAlign: "center",
                                mt: 3,
                            }}
                        >
                            {t("nophraddavailable")}
                        </Typography>
                    )}
                </Box>
                {alertmessage && (
                    <Typography
                        sx={{ color: alertstatus === "success" ? "green" : "red", mt: 1 }}
                    >
                        {alertmessage}
                    </Typography>
                )}
                {errorMessage && (
                    <Typography color="red" sx={{ mt: 1, ml: 1 }}>
                        {errorMessage}
                    </Typography>
                )}

                <Box sx={{ textAlign: "center", mt: 4 }}>
                    <Button
                        sx={{ width: "120px" }}
                        onClick={handleOnLink1Next}
                        variant="contained"
                        disabled={loading || !phradd}
                        endIcon={
                            loading ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                <ArrowForward sx={{ fontWeight: "bold" }} />
                            )
                        }
                    >
                        {t("credentialsData.commondata.login")}
                    </Button>
                </Box>

                <Box sx={{ mb: { xs: 10, sm: 10, md: 3 } }}>
                    {/* {
                    result.createAbha === true ? null : */}
                    <Box sx={{ textAlign: "center", mt: 2 }}>
                        <Button
                            variant="outlined"
                            sx={{ borderRadius: 2 }}
                            onClick={() => navigate("/connecthealthid")}
                        >
                            {t("createnewabha")}
                        </Button>
                    </Box>
                    {/* } */}
                </Box>
            </Card>
        </Box>
    );
}

export default LoginSelectPhr