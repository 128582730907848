import React from 'react'
import styled from '@emotion/styled';
import { InputAdornment, TextField } from '@mui/material';
import { useTranslation } from "react-i18next";

export const SearchBarStart = (props) => {
    return (
        <div>
            <TextField
                sx={{
                    ".MuiInputBase-root": {
                        borderRadius: 2,
                        mt: props.mt,
                        height: props.height,
                        bgcolor: '#CEF5FA'
                    }, ".MuiOutlinedInput-notchedOutline": {
                        border: props.border
                    },
                    width: props.width,
                }}
                variant="outlined"
                size='small'
                fullWidth
                placeholder={props.placeholder}
                // autoFocus
                value={props.value}
                name={props.name}
                onKeyDown={props.onKeyDown}
                onChange={props.onChange} InputProps={{
                    startAdornment: (
                        <InputAdornment sx={{
                            cursor: 'pointer',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add shadow effect
                        }} position="start" onClick={props.onClick}>
                            {props.icon && <img src={props.icon} alt="search" width={20} height={20} />}
                            {props.materialIcon && props.materialIcon}
                        </InputAdornment>),
                }}
            />
        </div>

    )
}

const SearchBar = (props) => {
    return (
        <div>
            <TextField
                sx={{
                    ".MuiInputBase-root": {
                        borderRadius: 2,
                        mt: props.mt,
                        height: props.height

                    }, ".MuiOutlinedInput-notchedOutline": {
                        border: props.border
                    },
                    width: props.width,
                }}
                variant="outlined"
                size='small'
                fullWidth
                placeholder={props.placeholder}
                // autoFocus
                value={props.value}
                name={props.name}
                onKeyDown={props.onKeyDown}
                onChange={props.onChange} InputProps={{
                    endAdornment: (
                        <InputAdornment sx={{
                            cursor: 'pointer',
                            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', // Add shadow effect
                        }} position="end" onClick={props.onClick}>
                            {props.icon && <img src={props.icon} alt="search" width={20} height={20} />}
                            {props.materialIcon && props.materialIcon}
                        </InputAdornment>),
                }}
            />
        </div>
    )
}

export default SearchBar