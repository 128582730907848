
import React from 'react'
import { Card, Grid, IconButton, Paper, Typography } from '@mui/material'
import { ArrowForwardIos, Call } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from "react-i18next";

function MobileNumber() {
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()
    return (
        <Paper elevation={3}
            sx={{
                p: "10px 15px",
                borderRadius: '10px'
                // boxShadow:
                //     "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
            }}
        >
            <Grid
                item
                container
                spacing={2}
                sx={{ cursor: "pointer" }}
                onClick={() => navigate("/mobileflow")}
            >
                <Grid item xs={1.5} sm={1} md={1}>
                    <Call sx={{ color: "green" }} />
                </Grid>
                <Grid item xs={9} sm={10} md={10}>
                    <Typography
                        sx={{ fontSize: { xs: 14, sm: 15, md: 16 }, fontWeight: 600 }}
                    >
                        {t("mobeml")}
                    </Typography>
                    <Typography
                        sx={{
                            fontSize: { xs: 11, sm: 12, md: 12 }, mt: "5px",
                            fontWeight: 400
                        }}
                    >
                        {t("mobemltxt")}
                    </Typography>
                </Grid>
                <Grid item xs={1.5} sm={1} md={1}>
                    <IconButton>
                        <ArrowForwardIos sx={{ color: "#2891D5" }} />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    );
}

export default MobileNumber;