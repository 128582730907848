import React, { useState } from 'react'
import { Typography } from '@mui/material';

function Text({ docFeed }) {
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };
    return (
        <div>
            <Typography
                variant="body2"
                color="text.secondary"
                sx={{
                    textOverflow: "ellipsis",
                    wordWrap: "break-word",
                    pl: 1.6,

                }}
            >
                {expanded
                    ? docFeed?.description
                    : `${docFeed?.description?.slice(0, 114)}`}
            </Typography>
            {docFeed?.description?.length > 95 ? (
                <Typography
                    onClick={handleExpandClick}
                    sx={{ cursor: "pointer" }}
                >
                    {expanded ? "see less" : "see more"}{" "}
                </Typography>
            ) : (
                ""
            )}
        </div>
    )
}

export default Text