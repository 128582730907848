import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import URL from "../../../utility/apiurl";
import { useEffect } from "react";
import { debounce } from "lodash";
import { MuiTelInput } from "mui-tel-input";
import { useTranslation } from "react-i18next";
import CommonButton from "../../atoms/CommonButton";
import { CircularProgress } from "@mui/material";
import { localstore } from "../../../localStore/localStore";
import { ErrorStatusMsg } from "../../atoms/ResendOtp";
import ColorTextField from "../../atoms/TextFields/ColorTextField";
import { StartAdornmentTextField } from "../../atoms/TextFields/AdornmentTextField";

const countryData = [
    {
        name: "India",
        countryCode: "+91",
        flag: "https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg",
    },
    {
        name: "Australia",
        countryCode: "+61",
        flag: "https://upload.wikimedia.org/wikipedia/commons/b/b9/Flag_of_Australia.svg",
    },
    {
        name: "Canada",
        countryCode: "+1",
        flag: "https://upload.wikimedia.org/wikipedia/commons/c/cf/Flag_of_Canada.svg",
    },
    {
        name: "UAE",
        countryCode: "+971",
        flag: "https://upload.wikimedia.org/wikipedia/commons/c/cb/Flag_of_the_United_Arab_Emirates.svg",
    },
    {
        name: "United States",
        countryCode: "+1",
        flag: "https://upload.wikimedia.org/wikipedia/commons/a/a4/Flag_of_the_United_States.svg",
    },
    {
        name: "United Kingdom",
        countryCode: "+44",
        flag: "https://upload.wikimedia.org/wikipedia/commons/8/83/Flag_of_the_United_Kingdom_%283-5%29.svg",
    },
];

function SignupMobile(props) {
    const dispatch = useDispatch();
    const countrycode = useSelector((state) => state.signup.countrycode);
    // const usertoken = useSelector((state) => state.signup.gettoken);
    const usertoken = localstore.getToken()
    // console.log(countryCode, "heloooooooooo")
    const [mobilenum, setMobilenum] = useState("");
    const [valid, setValid] = useState("");
    const [alert, setAlert] = useState({ open: false, msg: "" });
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({ phone: null });
    const [verifymobilenum, setVerifymobilenum] = useState(null);
    const [avaliable, setAvaliable] = React.useState(false);
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const [t, i18n] = useTranslation("global");
    const token = localstore.getToken()
    // const [value, setValue] = React.useState("");
    const handleChange = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setMobilenum(inputValue.slice(0, 10));
        setAlertmessage('')

    }
    // const handleChange = (newPhone) => {
    //     const formattedPhone = newPhone.replace(/\s/g, "");
    //     const truncatedPhone = formattedPhone.slice(0, 13);
    //     setMobilenum(truncatedPhone);
    //     setAlertmessage('')
    // };

    useEffect(() => {
        const script = document.createElement("script");
        script.src =
            "https://www.google.com/recaptcha/api.js?render=6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-";
        document.body.appendChild(script);
    }, []);

    const handleNavigateCountries = () => {
        // countriessssssss
    };

    const handleSMSOTP = () => {
        setLoading(true);
        const d1 = { phoneNumber: `+91${mobilenum}` };
        const d2 = {
            messagetype: "OTP",
            apptype: "medxperts",
            phoneNumber: `+91${mobilenum}`,
            email: "",
        };

        axios.post(`${URL.checkMobile}`, d1,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            })
            .then((res) => {
                console.log(res, "mobilecheck");

                if (res.data === "Valid Number") {
                    return new Promise((resolve) => {
                        window.grecaptcha.ready((_) => {
                            window.grecaptcha.execute("6LfZoZEnAAAAAI-P-K64YET4zUQtJiZwVGCpY0m-", {
                                action: "submit",
                            }).then((token) => {
                                resolve(token);
                            });
                        });
                    });
                } else if (res.data === "PhoneNumber Already Taken") {
                    setAlertmessage(res.data);
                    setAlertstatus('error');
                    throw new Error("PhoneNumber Already Taken");
                }
            })
            .then((token) => {
                console.log(token, "token")
                return axios.post(`${URL.sendOtp}`, d2, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Usertoken: `${usertoken}`
                    },
                });
            })
            .then((res) => {
                console.log(res, "sendotp");

                if (res.status === 200 || res.status === 201) {
                    setAlertmessage(res.data.msg);
                    setAlertstatus('success');
                    dispatch({
                        type: "REGISTERMOBILE",
                        payload: mobilenum,
                    });
                    props.setShow(3);
                }
            })
            .catch((err) => {
                setAlertmessage(err.message);
                setAlertstatus('error');
            })
            .finally(() => {
                setLoading(false);
            });
    };



    return (
        <>

            <Box
                sx={{
                    mt: 2,
                    p: 2,
                }}
            >
                <Typography
                    sx={{ fontSize: { xs: 15, sm: 17, md: 17 }, fontWeight: 600 }}

                >
                    {/* SignUp with Mobile # */}
                    Link with Mobile Number
                </Typography>
                <Typography
                    variant="body2"
                    sx={{ mt: 1, color: "gray", fontSize: { xs: 12, md: 14 } }}
                    onClick={handleNavigateCountries}
                >
                    {t("credentialsData.signupmobiledata.subtxt1")}
                    {countrycode?.data?.length > 0 ? (
                        <Typography sx={{ fontSize: { xs: 12, md: 16 } }} component="span">
                            {" "}
                            ({countrycode.data}){" "}
                        </Typography>
                    ) : (
                        <Typography sx={{ fontSize: { xs: 12, md: 16 } }} component="span">
                            {" "}
                            (+91){" "}
                        </Typography>
                    )}
                    <Typography sx={{ fontSize: { xs: 12, md: 16 } }} component="span">
                        {" "}
                        {t("credentialsData.signupmobiledata.subtxt2")}
                    </Typography>
                    <Typography
                        component="span"
                        sx={{ color: "blueviolet", fontSize: { xs: 12, md: 16 } }}
                    >
                        {t("credentialsData.signupmobiledata.subtxt3")}
                    </Typography>{" "}
                </Typography>
            </Box>

            <Grid container >
                <Grid item xs={12}>
                    <StartAdornmentTextField label="Mobile Number" placeholder="Enter Your Mobile Number" width="370px" type="text" value={mobilenum} name="mobilenum" onChange={handleChange} />
                </Grid>

            </Grid>
            {alertmessage && (
                <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
            )}
            <Box sx={{ mt: 4, textAlign: 'center' }}>
                <CommonButton bgcolor="blueviolet" text="Send OTP" width="150px" onClick={handleSMSOTP}
                    disabled={loading || mobilenum.length < 10} startIcon={loading ? <CircularProgress size={20} /> : null} />

            </Box>

        </>
    );
}

export default SignupMobile;
