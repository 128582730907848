import React from 'react'
import { Box, Button, CircularProgress, DialogActions, DialogContent, DialogTitle, Divider, IconButton, Typography } from '@mui/material'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import axios from 'axios'
import URL from '../../../../utility/apiurl'
import { useState } from 'react'
import { Close } from '@mui/icons-material'
import { localstore } from '../../../../localStore/localStore'
import { useDispatch } from 'react-redux'
import { getAbhaMobile } from '../../../../redux/Actions/Actions'

function PhonenumUpdate1({ handleClose, setShow, sessionId, primaryId, token }) {
    const [otp, setOtp] = React.useState('')
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const csrftoken = localstore.getCsrfToken()
    const dispatch = useDispatch()

    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value || "";
        const numericValue = inputValue.replace(/\D/g, "");
        setOtp(numericValue.slice(0, 6))
    }
    const handleSubmitOtp = async () => {
        setLoading(true)
        const data = {
            "sessionId": sessionId, "otp": otp
        }
        await axios.post(`${URL.consents}abha/profile/update-mobileEmail/confirm/${primaryId}`, data, {
            headers: { "Authorization": `Bearer ${token}` }
        }).then(res => {
            setAlertmessage("otp verified successfully")
            setAlertstatus('success')
            dispatch(getAbhaMobile(primaryId, token))
            handleClosedone()
        }).catch((err) => {
            setAlertmessage("otp invalid")
            setAlertstatus('error')
        }).finally(() => {
            setLoading(false)
        })
    }
    const handleClosedone = () => {
        handleClose()
        setShow(1)
        setAlertmessage('')
    }

    return (
        <div>
            <DialogTitle>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>

                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Enter OTP</Typography>
                    <IconButton onClick={handleClosedone}><Close sx={{ color: 'red' }} /></IconButton>

                </Box>
            </DialogTitle>
            <Divider />
            <DialogContent>

                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <TextInputField width="350px" placeholder=" Enter Received OTP" name="otp" value={otp} onChange={handleOnChangeOtp} />

                </Box>
            </DialogContent>
            {alertmessage && (
                <Typography
                    sx={{
                        color: alertstatus === "success" ? "green" : "red",
                        mt: 1, fontSize: '13px', textAlign: 'center'
                    }}
                >
                    {alertmessage}
                </Typography>
            )}
            <DialogActions sx={{ justifyContent: 'center', m: 0 }}>
                <Button variant='contained' sx={{ width: '120px', borderRadius: '10px' }} startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} onClick={handleSubmitOtp}>Submit</Button>
            </DialogActions>
        </div>
    )
}

export default PhonenumUpdate1