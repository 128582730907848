import React, { useState } from 'react';
import { Box, Button, ButtonGroup, Card, CircularProgress, Grid, IconButton, Paper, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import URL, { PasswordpublicKey } from '../../../../utility/apiurl';
import axios from 'axios';
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { useTranslation } from "react-i18next";
import EmailStep2 from './EmailStep2';
import SelectPhrAddress from './SelectPhrAddress';
import Registration from './Registration';
import EditRegistration from './EditRegistration';
import CreatePhrAddress from './CreatePhrAddress';
import PhrAddressScreen from './PhrAddressScreen';
import SelectVerification from './SelectVerification';
import JSEncrypt from 'jsencrypt';
import { ErrorStatusMsg } from '../../../atoms/ResendOtp';

function EmailStep1() {
    const [show, setShow] = useState(1);
    const [t, i18n] = useTranslation("global");
    const [email, setEmail] = useState('');
    const [emailerrormsg, setEmailerrormsg] = useState('');
    const [activeButton, setActiveButton] = useState('mobile');
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const token = localstore.getToken();
    const primaryId = localstore.getPrimaryId();
    const csrftoken = localstore.getCsrfToken();
    const publicKey = PasswordpublicKey;
    const [isValidEmail, setIsValidEmail] = useState(false);

    const validateEmail = (email) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,4}$/;
        return emailRegex.test(email);
    };

    const handleOnChangeEmail = (e) => {
        const enteredEmail = e.target.value;
        setEmail(enteredEmail);
        setIsValidEmail(validateEmail(enteredEmail));
        setEmailerrormsg('');
        setAlertmessage('');
    };

    const handleSend = async (e) => {
        e.preventDefault();

        if (!isValidEmail) {
            setEmailerrormsg('Invalid Email Address');
            return;
        }

        setLoading(true);
        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        const encryptedEmail = encrypt.encrypt(email);

        try {
            const data = {
                mobileOrEmail: 'email',
                // email: encryptedEmail.replace(/\n/g, '').replace(/\r/g, '')
                email: email
            };

            const res = await axios.post(`${URL.abha}email/generate/otp/${primaryId}`, data, {
                headers: {
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                }
            });

            if (res.status === 200 || res.status === 201) {
                setAlert(true);
                setAlertmessage(t("otpsendedtoemail"));
                setAlertstatus('success');
                setShow(2);
            }

            const details = {
                sessionId: res.data.sessionId,
                email: email,
                Email: true,
            };

            dispatch({
                type: "EMAILFLOW_SESSIONID",
                payload: details
            });
        } catch (err) {
            console.log(err.message);
            setAlert(true);
            setAlertmessage('Oops something went wrong!');
            setAlertstatus('error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box sx={{ p: { xs: "0px 10px", sm: "0px 10px", md: '0px 250px' }, pt: { xs: '60px', sm: "60px", md: '44px' }, mb: { xs: 10, sm: 10, md: 5 } }}>
            {show === 1 ? (
                <>
                    <Box sx={{ mt: { xs: 1, sm: 1, md: 1.5 } }}>
                        <Box
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: { xs: 1, sm: 2, md: 2 },
                            }}
                        >
                            <IconButton onClick={() => navigate('/connecthealthid')}>
                                <ArrowBackIos sx={{ color: '#007DCD' }} />
                            </IconButton>
                            <Typography
                                sx={{
                                    fontSize: { xs: 15, sm: "16px", md: "17px" },
                                    fontWeight: "600", color: '#007DCD'
                                }}
                            >
                                {" "}
                                {t("selfdeclared")}
                            </Typography>
                        </Box>
                    </Box>
                    <Paper elevation={3}
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            mt: 1,
                            p: 3,
                            boxShadow:
                                "1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)",
                        }}
                    >
                        <Box textAlign={"center"}>
                            <Typography sx={{ fontSize: 16 }}>{t("registerabha")} </Typography>
                            <ButtonGroup
                                variant="contained"
                                aria-label="outlined primary button group"
                                sx={{ mt: 2 }}
                            >
                                <Button
                                    sx={{
                                        bgcolor: activeButton === "email" ? "#EAE2E2" : "white",
                                        color: "black",
                                        '&:hover': {
                                            bgcolor: activeButton === "email" ? "#EAE2E2" : "white",
                                        }
                                    }}
                                    disableElevation
                                >
                                    {t("emailotp")}
                                </Button>
                            </ButtonGroup>
                            <Grid container mt={3}>
                                <Grid item xs={12}>
                                    <TextInputField
                                        placeholder={t("email")}
                                        width={{ sm: "350px", md: '350px' }}
                                        border="none"
                                        name="email"
                                        onChange={handleOnChangeEmail}
                                    />

                                    {emailerrormsg && (
                                        <Typography
                                            sx={{
                                                color: "red", fontSize: { xs: 14, md: 16 }, mt: 1,
                                            }}
                                        >
                                            {emailerrormsg}
                                        </Typography>
                                    )}
                                    {alertmessage && (
                                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                                    )}
                                </Grid>
                            </Grid>
                            <Box sx={{ mt: 3, textAlign: "center", mb: 2 }}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        borderRadius: 2,
                                        "&:hover": {
                                            bgcolor: "#1CB500",
                                        },
                                        bgcolor: "#1CB500",
                                        width: "150px",
                                    }}
                                    onClick={handleSend}
                                    disabled={loading || !email}
                                    startIcon={
                                        loading ? (
                                            <CircularProgress size={20} color="inherit" />
                                        ) : null
                                    }
                                >
                                    {t("credentialsData.signupmobiledata.otpbtntxt")}
                                </Button>
                            </Box>
                        </Box>
                    </Paper>
                </>
            ) : show === 2 ? (
                <EmailStep2 setShow={setShow} setAlertMessage={setAlertmessage} />
            ) : show === 3 ? (
                <SelectPhrAddress setShow={setShow} />
            ) : show === 4 ? (
                <Registration setShow={setShow} />
            ) : show === 5 ? (
                <EditRegistration setShow={setShow} />
            ) : show === 6 ? (
                <CreatePhrAddress setShow={setShow} show={show} />
            ) : show === 7 ? (
                <PhrAddressScreen setShow={setShow} />
            ) : show === 8 ? (
                <SelectVerification setShow={setShow} />
            ) : null
            }
        </Box>
    );
}

export default EmailStep1;
