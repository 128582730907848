import React, { useState } from 'react'
import { Box, Button, Card, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Slide, Typography } from '@mui/material';
import CommonDialog from '../../../atoms/CommonDialog';
import { Close, Pending, RemoveCircle, RemoveCircleOutline } from '@mui/icons-material';
import hospitalIcon from '../../../../assets/home/consent_module/hospital.svg'
import { OutlinedButton } from '../../../atoms/CommonButton';
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat';
import medxpertLogo from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import URL from '../../../../utility/apiurl';
import axios from 'axios';


function Authorizations({ open, setOpen, authorization, lockerNamedata, token, primaryId, phraddress }) {
    const [expand, setExpand] = useState(false)
    const [requestid, setRequestId] = useState(null);
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const grantedObjects = authorization?.filter(obj => obj.status === 'GRANTED');
    // console.log(requestid, "requestid")
    const handlerevoke = async () => {

        try {
            const response = await axios.get(`${URL.consents}health-locker/authorization-requests/revoke/${primaryId}/${phraddress}/${requestid}`, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            });

            setAlertopen(true);
            setAlertmessage('Revoke Successfully ');
            setAlertstatus('success');
            setTimeout(() => {
                setOpen(false)
            }, 2000);
        } catch (error) {

            let data = error.response.data;
            const errorMessage = data.message;
            if (errorMessage.includes('Authorization already revoked')) {
                setAlertmessage('Authorization already revoked');
                setAlertstatus('error');
            } else {
                setAlertmessage(error.message);
                setAlertstatus('error');
                setOpen(false);
            }

        }

    };

    const handleClose = () => {
        setOpen(false)
    }

    return (
        <React.Fragment>

            <CommonDialog open={open} onClose={handleClose}>
                <DialogTitle sx={{ fontWeight: 600, p: '5px 20px', display: 'flex', fontSize: '17px', justifyContent: 'space-between', alignItems: 'center' }}>
                    Authorizations
                    <IconButton sx={{ color: 'red' }} onClick={handleClose}>  <Close /></IconButton>
                </DialogTitle>
                <Divider />

                {expand ?
                    <DialogContent>
                        <Card sx={{ p: "10px 20px" }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    {/* <VerifiedUser sx={{ color: '#0C9D00' }} /> */}
                                    <Typography >Authorization Request </Typography>
                                </Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                                    Granted {DateFormat(lockerNamedata?.dateCreated, 'DateTime')}
                                </Typography>
                            </Box>

                            <Grid container mt={2}>
                                <Grid item xs={1.5} sm={1.5} md={1.5} >
                                    <img src={medxpertLogo} alt="medxperts" width={50} height={50} />
                                </Grid>
                                <Grid item xs={8.8} sm={8.8} md={8.8}>
                                    <Box>
                                        <Typography sx={{ fontWeight: 600, fontSize: '15px' }}>{lockerNamedata?.lockerName}</Typography>
                                        <Typography sx={{ fontSize: '14px' }}> KYC & Link Records</Typography>
                                    </Box>
                                </Grid>
                            </Grid>

                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox defaultChecked />
                                <Typography sx={{ fontSize: '14px' }} >Authorise Medxperts to authenticate your Profile details</Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Checkbox defaultChecked />
                                <Typography sx={{ fontSize: '14px' }}>Authorise Medxperts to Link Records with your Health Account</Typography>
                            </Box>

                            <Box sx={{ textAlign: 'center', mt: 2 }}>
                                <OutlinedButton text='REVOKE' borderColor="red" color="red" height="35px" startIcon={<RemoveCircleOutline />} onClick={handlerevoke} />
                            </Box>
                        </Card>
                    </DialogContent> :
                    <DialogContent>
                        <Card sx={{ p: "10px 20px" }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                                    {/* <VerifiedUser sx={{ color: '#0C9D00' }} /> */}
                                    <Typography >Authorization Request </Typography>
                                </Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: 500 }}>
                                    {DateFormat(lockerNamedata?.dateCreated, 'DateTime')}
                                </Typography>
                            </Box>

                            <Grid container mt={2}>
                                <Grid item xs={0.2} sm={0.2} md={0.2}></Grid>
                                <Grid item xs={1.5} sm={1.5} md={1.5} >
                                    <img src={medxpertLogo} alt="medxperts" width={50} height={50} />
                                </Grid>
                                <Grid item xs={8.8} sm={8.8} md={8.8}>
                                    <Box>
                                        <Typography sx={{ fontWeight: 600 }}>{lockerNamedata?.lockerName}</Typography>
                                        <Typography sx={{ fontSize: '14px', }}> KYC & Link Records</Typography>
                                    </Box>
                                </Grid>


                                <Grid item md={1.5} s>
                                    <IconButton onClick={() => {
                                        setExpand(true);
                                        setRequestId(grantedObjects[0]?.requestId);
                                    }}><Pending sx={{ color: '#247FEA', ml: '3px' }} /></IconButton>
                                </Grid>
                            </Grid>
                        </Card>
                    </DialogContent>
                }
            </CommonDialog>
        </React.Fragment >
    );
}

export default Authorizations; 