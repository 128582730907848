import React from 'react'
import { Box, CardMedia } from '@mui/material'

function Media({ docFeed }) {
    return (
        <Box sx={{}}>
            {docFeed.sourceLink ? (
                <CardMedia
                    component="img"
                    // height="194"
                    sx={{ height: { xs: "100%", sm: "100%", md: "100%" } }}

                    // image='https://media.istockphoto.22com/id/1341976416/photo/healthy-eating-and-diet-concepts-top-view-of-spring-salad-shot-from-above-on-rustic-wood-table.jpg?b=1&s=170667a&w=0&k=20&c=xYV0gZRXSLeAGJAPaNFaLH1V3VLNLY3KZGVL-neS1js='
                    image={docFeed.sourceLink}
                    alt="Paella dish"
                />
            ) : null}
        </Box>)
}

export default Media