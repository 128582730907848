import React from 'react'
import { Line } from 'react-chartjs-2';

function LineChart() {
    const data = {
        labels: ['12 AM', '1 AM', '2 AM', '3 AM'],
        datasets: [
            {
                label: 'Line Chart',
                data: [50, 100, 150, 200], // Adjust these values based on your data
                fill: false,
                borderColor: 'rgb(75, 192, 192)',
                tension: 0.1,
            },
        ],
    };

    // Configuration options
    const options = {
        scales: {
            x: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Time (AM)',
                },
            },
            y: {
                beginAtZero: true,
                title: {
                    display: true,
                    text: 'Y-axis Label',
                },
                ticks: {
                    stepSize: 50, // Set the step size for the y-axis
                },
            },
        },
    };

    return (
        <div>
            <Line />
        </div>
    )
}

export default LineChart;