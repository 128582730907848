import React, { useState, useEffect } from 'react';
import { Box, Card, Checkbox, Divider, IconButton, Typography } from '@mui/material';
import { ArrowDropDown, ArrowDropUp, ExpandLess, ExpandMore } from '@mui/icons-material';
import CommonCalender from '../../../atoms/CommonCalender';
import dayjs from 'dayjs';

function InformationSource(props) {
    const { open, setOpen, linkeddata, selectedHospitals, requestdata, setSelectedHospitals } = props;
    const [isCheckedState, setIsCheckedState] = useState({});
    const [openDropdownIndex, setOpenDropdownIndex] = useState(null);
    const [selectedRecords, setSelectedRecords] = useState({})
    const [datePickerVisibility, setDatePickerVisibility] = useState({
        from: false,
        to: false,
    });
    const links = linkeddata?.patient?.links;
    console.log(selectedHospitals, "selectedHospitals")
    const toggleDropdown1 = (index) => {
        setOpenDropdownIndex((prevIndex) =>
            prevIndex === index ? null : index
        );
    };

    const handleallcheckbox = () => {
        const areAllCheckboxesSelected =
            selectedHospitals?.length === requestdata?.subscription?.hips?.length;

        if (areAllCheckboxesSelected) {
            // Deselect all hospitals
            setSelectedHospitals([]);
            // Clear selected records if needed
            setSelectedRecords({});
            // Clear isCheckedState if needed
            setIsCheckedState({});
        } else {
            // Select all hospitals
            const updatedSelectedRecords = {};
            const selectedHospitalsCopy = requestdata?.subscription?.hips?.map(
                (hip) => {
                    const hipId = hip?.id;
                    const matchingCareContexts = linkeddata?.patient?.links
                        .filter((dataItem) => dataItem.hip.id === hipId)
                        .flatMap((dataItem) =>
                            dataItem?.careContexts?.map((context) => context?.display)
                        );

                    // Update the selectedRecords state
                    updatedSelectedRecords[hipId] = matchingCareContexts;

                    return {
                        hip: {
                            id: hipId,
                            name: hip?.name,
                        },
                        categories: requestdata.subscription.categories,
                        hiTypes: [],
                        careContexts: matchingCareContexts,
                        period: {
                            from: requestdata?.subscription?.period?.from,
                            to: requestdata?.subscription?.period?.to,
                        },
                    };
                }
            );

            const isCheckedStateCopy = {};
            for (let i = 0; i < selectedHospitalsCopy?.length; i++) {
                isCheckedStateCopy[i] = true;
            }

            setSelectedHospitals(selectedHospitalsCopy);
            setSelectedRecords(updatedSelectedRecords);
            setIsCheckedState(isCheckedStateCopy);
        }
    };

    // useEffect(() => {
    //     handleallcheckbox();
    // }, [requestdata]);

    const handlehospitaldata = (hospitalIndex, hipId) => {
        const isHospitalSelected = isCheckedState[hospitalIndex];
        const existingHospitalIndex = selectedHospitals.findIndex(
            (selected) => selected?.hip?.id === hipId
        );

        if (isHospitalSelected) {
            // If the hospital is already selected, unselect it
            const updatedSelection = selectedHospitals.map((selected, index) =>
                index === existingHospitalIndex
                    ? { ...selected, categories: [], careContexts: [] }
                    : selected
            );

            setIsCheckedState((prevState) => ({
                ...prevState,
                [hospitalIndex]: false,
            }));

            // Update selectedRecords state to remove records for the hospital
            const updatedSelectedRecords = { ...selectedRecords };
            delete updatedSelectedRecords[hipId];
            setSelectedRecords(updatedSelectedRecords);

            setSelectedHospitals(updatedSelection);
        } else {
            // If the hospital is not selected, select it and add categories
            const updatedCategories = ['LINK', 'DATA'];

            // Filter careContexts from the hospital in the dataArray based on hipId
            const updatedCareContexts = linkeddata?.patient?.links?.find(
                (context) => context.hip.id === hipId
            );

            const careContextsArray = updatedCareContexts.careContexts.map(
                (care) => care.display
            );

            // Update selectedRecords state to include records for the hospital
            const updatedSelectedRecords = {
                ...selectedRecords,
                [hipId]: careContextsArray,
            };

            const updatedSelection =
                existingHospitalIndex !== -1
                    ? selectedHospitals.map((selected, index) =>
                        index === existingHospitalIndex
                            ? {
                                ...selected,
                                categories: updatedCategories,
                                careContexts: careContextsArray,
                            }
                            : selected
                    )
                    : [
                        ...selectedHospitals,
                        {
                            ...linkeddata?.patient?.links?.find(
                                (item) => item.hip.id === hipId
                            ),
                            categories: updatedCategories,
                            careContexts: careContextsArray,
                        },
                    ];

            setIsCheckedState((prevState) => ({
                ...prevState,
                [hospitalIndex]: true,
            }));

            setSelectedRecords(updatedSelectedRecords);
            setSelectedHospitals(updatedSelection);
        }
    };

    const handleCareContextCheckbox = (
        hipId,
        hospitalIndex,
        dataItemIndex,
        careContext
    ) => {
        const selectedRecordsCopy = { ...selectedRecords };
        const selectedHospitalsCopy = [...selectedHospitals];

        if (!selectedRecordsCopy[hipId]) {
            selectedRecordsCopy[hipId] = [];
        }

        const careContextIndex = selectedRecordsCopy[hipId].indexOf(careContext);

        if (careContextIndex !== -1) {
            // If care context is already selected, deselect it
            selectedRecordsCopy[hipId].splice(careContextIndex, 1);
        } else {
            // If care context is not selected, select it
            selectedRecordsCopy[hipId].push(careContext);
        }

        // Update careContexts in selectedHospitalsCopy
        selectedHospitalsCopy[hospitalIndex].careContexts =
            selectedRecordsCopy[hipId];

        // Set the updated state
        setSelectedRecords(selectedRecordsCopy);
        setSelectedHospitals(selectedHospitalsCopy);
    };

    const handleCategoryCheckbox = (hipid, category) => {
        const selectedHospitalsCopy = JSON.parse(
            JSON.stringify(selectedHospitals)
        );

        const selectedHospitalIndex = selectedHospitalsCopy.findIndex(
            (hospital) => hospital.hip.id === hipid
        );

        if (selectedHospitalIndex !== -1) {
            const selectedHospitalCopy = JSON.parse(
                JSON.stringify(selectedHospitalsCopy[selectedHospitalIndex])
            );

            const categoryIndex = selectedHospitalCopy.categories.indexOf(category);

            if (categoryIndex !== -1) {
                // If category is already selected, deselect it
                selectedHospitalCopy.categories.splice(categoryIndex, 1);
            } else {
                // If category is not selected, select it
                selectedHospitalCopy.categories.push(category);
            }

            // Update the selected hospital in the copied array
            selectedHospitalsCopy[selectedHospitalIndex] = selectedHospitalCopy;

            setSelectedHospitals(selectedHospitalsCopy);
        }
    };

    const handleDateChange = (type, hospitalIndex, newDate) => {
        // Ensure selectedHospitals[hospitalIndex] exists
        if (!selectedHospitals[hospitalIndex]) {
            return;
        }

        // Create a copy of the selectedHospitals array to modify
        const updatedSelectedHospitals = [...selectedHospitals];
        // Update the 'from' or 'to' property based on the type
        updatedSelectedHospitals[hospitalIndex].period[type] = newDate
            .toISOString()
            .split('T')[0];

        // Update the state with the modified array
        setSelectedHospitals(updatedSelectedHospitals);
    };

    return (
        <Box>
            <Card
                sx={{
                    boxShadow:
                        '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)',
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        bgcolor: 'pink',
                        p: '0px 10px',
                    }}
                >
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        <Checkbox
                            color="secondary"
                            checked={Boolean(
                                links && links.length === selectedHospitals?.length
                            )}
                            onChange={handleallcheckbox}
                        />
                        <Typography
                            sx={{
                                fontWeight: 600,
                                fontSize: { xs: 14, sm: 14, md: 14 },
                            }}
                        >
                            {"Included from all Hospitals"}
                        </Typography>
                    </Box>
                    <IconButton onClick={() => setOpen(!open)}>
                        {open ? (
                            <ExpandLess sx={{ color: 'black' }} />
                        ) : (
                            <ExpandMore sx={{ color: 'black' }} />
                        )}
                    </IconButton>
                </Box>

                {open && (
                    <Box
                        sx={{
                            maxHeight: '250px', // Set your desired maximum height
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}
                    >
                        {Object?.keys(requestdata)?.map((key) => (
                            <Box key={key}>
                                {key === 'subscription' && open ? (
                                    requestdata?.[key]?.hips?.map((hip, hospitalIndex) => (
                                        hip.id !== 'MEDXPERTS_LOCKER' && (
                                            <Box key={hospitalIndex}>
                                                <Card
                                                    sx={{
                                                        mt: '10px',
                                                        p: '0px 10px',
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'space-between',
                                                        bgcolor: 'whitesmoke',
                                                    }}
                                                >
                                                    <Box
                                                        sx={{
                                                            display: 'flex',
                                                            gap: 2,
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <Checkbox
                                                            color="secondary"
                                                            checked={Boolean(
                                                                isCheckedState?.[hospitalIndex]
                                                            )}
                                                            onChange={() =>
                                                                handlehospitaldata(hospitalIndex, hip.id)
                                                            }
                                                        />
                                                        <Typography
                                                            sx={{
                                                                fontSize: { xs: 13, sm: 14, md: 14 },
                                                            }}
                                                        >
                                                            {hip.name ? hip.name : 'null'}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <IconButton
                                                            onClick={() => toggleDropdown1(hospitalIndex)}
                                                        >
                                                            {openDropdownIndex === hospitalIndex ? (
                                                                <ArrowDropUp sx={{ color: 'black' }} />
                                                            ) : (
                                                                <ArrowDropDown sx={{ color: 'black' }} />
                                                            )}
                                                        </IconButton>
                                                    </Box>
                                                </Card>
                                                {openDropdownIndex === hospitalIndex && (
                                                    <Card sx={{ mt: 1, p: '5px 10px' }}>
                                                        <Typography
                                                            sx={{ fontSize: '14px', fontWeight: 600 }}
                                                        >
                                                            Subscribed for Records of Type
                                                        </Typography>
                                                        {links?.map((record, recordIndex) => {
                                                            const hipId = record?.hip?.id;
                                                            if (record?.hip?.name === hip?.name) {
                                                                return (
                                                                    <Card key={recordIndex}>
                                                                        {record.careContexts.map(
                                                                            (careContext, careContextIndex) => (
                                                                                <Box
                                                                                    sx={{
                                                                                        display: 'flex',
                                                                                        alignItems: 'center',
                                                                                        justifyContent: 'space-between',
                                                                                    }}
                                                                                    key={careContextIndex}
                                                                                >
                                                                                    <Typography
                                                                                        sx={{
                                                                                            fontSize: {
                                                                                                xs: 13,
                                                                                                sm: 14,
                                                                                                md: 14,
                                                                                            },
                                                                                        }}
                                                                                    >
                                                                                        {careContext.display !== ''
                                                                                            ? careContext.display
                                                                                            : ''}
                                                                                    </Typography>
                                                                                    <Checkbox
                                                                                        color="secondary"
                                                                                        checked={Boolean(
                                                                                            selectedRecords[hipId]?.includes(
                                                                                                careContext.display
                                                                                            )
                                                                                        )}
                                                                                        onChange={() =>
                                                                                            handleCareContextCheckbox(
                                                                                                hipId,
                                                                                                hospitalIndex, // Include hospitalIndex
                                                                                                recordIndex,
                                                                                                careContext.display
                                                                                            )
                                                                                        }
                                                                                    />
                                                                                </Box>
                                                                            )
                                                                        )}
                                                                    </Card>
                                                                );
                                                            }
                                                            return null;
                                                        })}
                                                        <Divider />
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 600,
                                                                fontSize: '14px',
                                                            }}
                                                        >
                                                            Type of Visit
                                                        </Typography>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                gap: 2,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: {
                                                                        xs: 14,
                                                                        sm: 14,
                                                                        md: 14,
                                                                    },
                                                                }}
                                                            >
                                                                {'Past and Completed Visits(Link)'}
                                                            </Typography>
                                                            <Checkbox
                                                                color="secondary"
                                                                checked={Boolean(
                                                                    selectedHospitals?.[hospitalIndex]?.categories?.includes(
                                                                        'LINK'
                                                                    )
                                                                )}
                                                                onChange={() =>
                                                                    handleCategoryCheckbox(hip.id, 'LINK')
                                                                }
                                                            />
                                                        </Box>
                                                        <Box
                                                            sx={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                gap: 2,
                                                            }}
                                                        >
                                                            <Typography
                                                                sx={{
                                                                    fontSize: {
                                                                        xs: 14,
                                                                        sm: 14,
                                                                        md: 14,
                                                                    },
                                                                }}
                                                            >
                                                                {'Upcoming New Visits(Data)'}
                                                            </Typography>
                                                            <Checkbox
                                                                color="secondary"
                                                                checked={Boolean(
                                                                    selectedHospitals?.[hospitalIndex]?.categories?.includes(
                                                                        'DATA'
                                                                    )
                                                                )}
                                                                onChange={() =>
                                                                    handleCategoryCheckbox(hip.id, 'DATA')
                                                                }
                                                            />
                                                        </Box>
                                                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                                            <Box>
                                                                <Typography>From</Typography>
                                                                <CommonCalender
                                                                    value={dayjs(selectedHospitals?.[hospitalIndex]
                                                                        ?.period?.from || '')}
                                                                    onChange={(date) =>
                                                                        handleDateChange(
                                                                            'from',
                                                                            hospitalIndex,
                                                                            date

                                                                        )}
                                                                />
                                                            </Box>
                                                            <Box mb={1}>
                                                                <Typography>To</Typography>
                                                                <CommonCalender
                                                                    value={dayjs(selectedHospitals?.[hospitalIndex]
                                                                        ?.period?.to || '')}
                                                                    onChange={(date) =>
                                                                        handleDateChange(
                                                                            'to',
                                                                            hospitalIndex,
                                                                            date

                                                                        )}
                                                                />
                                                            </Box>
                                                        </Box>

                                                    </Card>
                                                )}
                                            </Box>
                                        )
                                    ))
                                ) : null}
                            </Box>
                        ))}
                    </Box>
                )}
            </Card>
        </Box>
    );
}

export default InformationSource;
