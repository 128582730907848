import React from 'react'

function Image(props) {
    return (
        <div>
            <img src={props.src} alt={props.alt} style={props.style} />
        </div>
    )
}

export default Image