import React, { useState } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ArrowBackIos, ArrowDownward, Share } from '@mui/icons-material';
// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useLocation, useNavigate } from 'react-router-dom';
// import PdfView from '../Medxpedia/LiveHealth/PostTypes/PdfFile';
// import { Worker, Viewer, Document, Page } from '@react-pdf-viewer/pdfjs-dist';
// import '@react-pdf-viewer/core/lib/styles/index.css';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';



const useStyles = makeStyles((theme) => ({
    container: {
        flex: 1,
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    header: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        width: '100%',
        // padding: theme.spacing(2),
        // marginBottom: theme.spacing(3),
    },
    iconContainer: {
        marginRight: theme.spacing(1),
    },
    pdf: {
        fontSize: theme.spacing(3),
        color: 'black',
    },
    pdfViewer: {
        width: '100%',
        minHeight: '500px', // Set the minimum height for the PDF viewer
        border: '1px solid #ddd', // Optional border style
    },
}));

const ImgOrPdfViewer = () => {
    const location = useLocation()
    const Data = location?.state?.Data
    const Type = location?.state?.Type
    const navigate = useNavigate()
    const classes = useStyles();
    console.log(Data, "data")
    console.log(Type, "data")
    const handleDownload = () => {
        // Implement your download logic here
        console.log('Download clicked');
    };

    const handleShare = () => {
        // Implement your share logic here
        console.log('Share clicked');
    };

    const previous = "< previous";
    const next = "next >";
    const [numPages, setNumPages] = useState(null);
    const [pageNumber, setPageNumber] = useState(1);

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
        setPageNumber(1);
    }

    function changePage(offset) {
        setPageNumber((prevPageNumber) => Math.min(Math.max(prevPageNumber + offset, 1), numPages));
    }

    const previousPage = () => {
        changePage(-1);
    };

    const nextPage = () => {
        changePage(1);
    };

    // const onDocumentLoadSuccess = ({ numPages }) => {
    //     setNumPages(numPages);
    // };
    return (
        <Box className={classes.container} sx={{
            mb: { xs: 5, sm: 5, md: 10 },
        }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <IconButton className={classes.iconContainer} ><ArrowBackIos className={classes.pdf} onClick={() => navigate('/records/medicalrecords')} /></IconButton>
                <Box >
                    <IconButton className={classes.iconContainer} onClick={handleDownload}>
                        <ArrowDownward className={classes.pdf} />
                    </IconButton>
                    <IconButton className={classes.iconContainer} onClick={handleShare}>
                        <Share className={classes.pdf} />
                    </IconButton>
                </Box>
            </Box>
            {Type === 'png' || Type === 'jpg' || Type === 'jpeg' ? (
                <Box className={classes.pdfViewer} sx={{ mt: "10px", }}>
                    {/* Display Image */}
                    <img src={`data:image/${Type};base64,${Data}`} alt="Image" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
                </Box>
            ) : null}
            {
                Type === 'pdf' ? (
                    <div>
                        {
                            Type === 'pdf' ? (
                                <Box className={classes.pdfViewer} sx={{ mt: '10px' }}>
                                    <Document
                                        file={`data:application/${Type};base64,${Data}`}
                                        onLoadSuccess={onDocumentLoadSuccess}
                                    >
                                        <Page pageNumber={pageNumber} />
                                    </Document>
                                    <Box sx={{ mt: '10px', display: 'flex', justifyContent: 'space-between' }}>
                                        <IconButton onClick={previousPage} disabled={pageNumber <= 1}>
                                            <Typography sx={{ color: pageNumber <= 1 ? 'gray' : 'black', fontSize: '14px' }}>Previous</Typography>
                                        </IconButton>
                                        <Typography variant="body2">{`${pageNumber} / ${numPages}`}</Typography>
                                        <IconButton onClick={nextPage} disabled={pageNumber >= numPages}>
                                            <Typography variant="body2" sx={{ color: pageNumber >= numPages ? 'gray' : 'black' }} >Next</Typography>
                                        </IconButton>
                                    </Box>
                                </Box>
                            ) : null}

                    </div>

                ) : null
            }
        </Box>
    );
};

export default ImgOrPdfViewer;
