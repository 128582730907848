import React from 'react';
import { Tabs, Tab, Typography, useMediaQuery, useTheme, Box, Paper } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Settings } from '@mui/icons-material';

const CareResponsive = ({ tabs }) => {
    const theme = useTheme();
    const navigate = useNavigate()
    const isMobile = useMediaQuery('(max-width:960px)');
    const tabData = [
        { title: '360° Dashboard', path: 'caredashboard', content: 'Content for Tab 1' },

        { title: 'Timeline', path: 'caretimeline', content: 'Content for Tab 6' },
        { title: 'Care 360° Preferences', path: 'caresettings', content: 'Content for Tab 6' },
        // Add more tabs as needed
    ];
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const navigateToTab = (tab) => {
        setValue(tabData.findIndex(t => t.path === tab));
        navigate(`${tab}`);
    };
    return (
        <Box sx={{ m: "5px" }}>
            {isMobile &&
                <Paper sx={{ mt: 7, p: '2px' }}>
                    <Tabs
                        sx={{
                            ".MuiTab-root": { minHeight: "5px", mt: { xs: "7px", sm: '11px' }, p: '8px 14px', display: 'flex', alignItems: 'center', justifyContent: 'center' },
                            "& button.Mui-selected": {
                                backgroundColor: { xs: '#205072' },
                                borderRadius: { xs: 2, sm: 2, md: 4 },
                                color: 'white'
                            },
                        }}
                        value={value}
                        onChange={handleChange}
                        variant={'scrollable'}
                        scrollButtons={'auto'}
                        TabIndicatorProps={{ sx: { display: "none" } }}
                    >
                        {tabData.map((tab, index) => (
                            <Tab
                                key={index}
                                label={tab.title}
                                sx={{
                                    fontSize: '13px' // color: tab === index ? 'red' : 'black'
                                }}
                                onClick={() => navigateToTab(tab.path)}
                            />
                        ))}
                    </Tabs>
                </Paper>
            }
        </Box >
    );
};

export default CareResponsive;
