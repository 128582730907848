import React, { useEffect, useState } from 'react'
import URL from '../../../../utility/apiurl'
import { useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import { localstore } from '../../../../localStore/localStore'
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import hospitalIcon from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import { ArrowForwardIos, Check, CheckCircle, KeyboardArrowDown, KeyboardArrowUp, Pending, RemoveCircle, RemoveCircleOutline } from '@mui/icons-material'
import { debounce } from 'lodash'
import moment from 'moment'
import ApproveDialog from './ApproveDialog'
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat'
import { Time } from '../../../atoms/DateTimeFormat/TimeFormat'


const GrantedCardData = ({ item, primaryId, token, loading }) => {
    // console.log(item, "requests")
    // const dateObject = moment(item?.createdAt);
    const date = DateFormat(item?.createdAt)
    const time = Time(item?.createdAt);
    const [hospital, setHospital] = useState('')


    const debouncedAxiosCall = debounce((data) => {
        // console.log(data, "data")
        axios.get(`${URL.consents}providers/${data}/${primaryId}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setHospital(res.data)
        }).catch(err => {
            console.log(err.message)
        })
    }, 5000);

    useEffect(() => {
        // console.log(item, 'itemss'); // Log the contents of item for debugging

        if (item && item.hiu && item.hiu.id) {
            debouncedAxiosCall(item.hiu.id);
        }

        // Cancel API call when component unmounts
        return () => {
            debouncedAxiosCall.cancel();
        };
    }, [item]);

    return (
        <Box sx={{ p: '10px 20px' }}>
            <Grid container sx={{ display: 'flex', justifyContent: 'space-between' }}>
                {item?.requester?.name ?
                    <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                        {item?.requester?.name}
                    </Typography>
                    : <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                        Health Locker
                    </Typography>
                }
                <Typography sx={{ fontSize: { xs: '11px', sm: '14px', md: '14px' } }} >
                    {date} | {time}
                </Typography>
            </Grid>

            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'space-between', ml: "12px", mt: "5px" }}>
                <Box sx={{ display: 'flex', gap: 4, alignItems: 'center', }}>
                    {hospital.orgImg ?
                        <img src={hospital.orgImg} alt="orgimg" width={45} height={45} style={{ borderRadius: '5px', marginLeft: "12px" }} /> : <img src={hospitalIcon} alt="hospital" style={{ width: 50, height: 50 }} />}
                    <Box>
                        {hospital?.identifier?.name ?
                            <Typography sx={{ fontSize: { xs: '14px', sm: '14px', md: '14px' }, fontWeight: 500 }}>{hospital?.identifier?.name}</Typography> : <Typography sx={{ fontSize: { xs: '13px', sm: '18px', md: '17px' }, fontWeight: 500 }}>Medxperts Health Locker</Typography>}

                        <Typography sx={{ mt: "5px", fontSize: { xs: '13px', sm: '13px', md: '13px' } }}>{item?.purpose?.text}</Typography>
                    </Box>
                </Box>
                <Box sx={{ mr: "5px", display: 'flex', alignItems: 'center' }}>
                    <ApproveDialog item={item} date={date} time={time} hospital={hospital} />
                    <Typography sx={{ fontSize: { xs: '10px', md: '14px', sm: '14px' } }} >
                        Pending
                    </Typography>
                </Box>
            </Box>
        </Box>
    )
}

function ActiveRequests({ requestData }) {
    // console.log(requestData, "requesteddata")
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const [data, setData] = useState()
    const [authorize, setAuthorize] = useState()
    const [locker, setLocker] = useState()
    const [subscribe, setSubscribe] = useState()
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    // const [allData, setAllData] = useState([]);
    const [showMore, setShowMore] = useState(false);
    const [loading, setLoading] = useState(false)
    const [alertmessage, setAlertmessage] = useState('')
    const [alertstatus, setAlertstatus] = useState('')
    const dispatch = useDispatch()

    const list = requestData;

    const lockerSetupPurposes = (list?.lockerSetups?.requests || []).reduce((purposes, request) => {
        if (request?.authorization) {
            purposes.push(request.authorization);
        }
        if (request?.subscription) {
            purposes.push(request.subscription);
        }
        return purposes;
    }, []);

    const combinedPurposes = [...(list?.requests || []), ...(list?.subscriptions?.requests || []), ...(list?.authorizations?.requests || []), ...lockerSetupPurposes];

    // console.log(data, 'consentsssssssssssssss')
    // useEffect(() => {
    //     axios.get(`${URL.consents}health-locker/request/${primaryId}/${abhadata?.AbhaAddress}?status=REQUESTED`, {
    //         headers: { Authorization: `Bearer ${token}` }
    //     }).then(res => {
    //         // console.log(res, "consnentssssss")
    //         const list = res?.data;

    //         const lockerSetupPurposes = (list?.lockerSetups?.requests || []).reduce((purposes, request) => {
    //             if (request?.authorization) {
    //                 purposes.push(request.authorization);
    //             }
    //             if (request?.subscription) {
    //                 purposes.push(request.subscription);
    //             }
    //             return purposes;
    //         }, []);

    //         const combinedPurposes = [...(list?.consents?.requests || []), ...(list?.subscriptions?.requests || []), ...(list?.authorizations?.requests || []), ...lockerSetupPurposes];

    //         setAllData(combinedPurposes);
    //     }).catch(err => {
    //         setAlertmessage(err.message)
    //         setAlertstatus('error')
    //     }).finally(() => {
    //         setLoading(false)
    //     })
    // }, [abhadata])
    // console.log(allData, "alldata")

    const toggleShowMore = () => {
        setShowMore(!showMore);
    };
    return (
        <div>
            {loading ?
                (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            mt: "100px",
                        }}
                    >
                        <Typography sx={{ color: "blue" }}>
                            {"fetching hospitals......"}
                        </Typography>{" "}
                    </Box>
                ) : (
                    <>
                        {
                            combinedPurposes?.length > 0 ? (
                                <Box sx={{
                                    // overflowY: 'scroll', height: '381px', '&::-webkit-scrollbar': {
                                    //     display: 'none',
                                    // },
                                }}>


                                    {combinedPurposes?.slice(0, showMore ? combinedPurposes?.length : 2).map((item, index) => (
                                        <Card
                                            key={index}
                                            sx={{
                                                width: { xs: '100%', sm: '100%', md: '100%' },
                                                mt: 2,
                                            }}
                                        >
                                            <GrantedCardData item={item} primaryId={primaryId} token={token} loading={loading} />
                                        </Card>
                                    )

                                    )}

                                    < Box sx={{ display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center', marginTop: 2, cursor: 'pointer' }} onClick={toggleShowMore}>
                                        <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 18 }, color: "#2C84EB" }}>{showMore ? "See Less" : "See More"}</Typography>
                                        {showMore ? <KeyboardArrowUp sx={{ color: '#2C84EB' }} /> : <KeyboardArrowDown sx={{ color: '#2C84EB' }} />}
                                    </Box>


                                </Box >
                            ) : (
                                <Typography
                                    sx={{ textAlign: 'center', fontSize: { xs: 13, sm: 15, md: 15 } }}
                                >
                                    No Active Requests Available
                                </Typography>
                            )
                        }

                    </>)
            }
            {/* <ConsentDialog /> */}
        </div >
    )
}

export default ActiveRequests