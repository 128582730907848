import React from 'react'
import { ArrowForwardIos, Close, ExpandMore, RecommendOutlined, Vaccines } from '@mui/icons-material'
import { Box, Divider, Grid, IconButton, Paper, Switch, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import prescriptionIcon from '../../../../assets/home/records_module/prescription.svg'
import LineChart from '../../../atoms/LineChart'
import clinicalIcon from '../../../../assets-new/carekit-icons/clincalgoals.svg'

const ListCards = (props) => {
    return (
        <Paper elevation={4} sx={{ p: "8px 15px", mt: 2, borderRadius: '10px' }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Vaccines />
                    <Typography sx={{ fontWeight: 500 }}>{props.title}</Typography>
                </Box>
                <ExpandMore />
            </Box>
        </Paper>
    )
}

function PlateletCount() {
    const navigate = useNavigate()

    return (
        <div>
            <Box sx={{ m: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <img src={clinicalIcon} alt="clincal" />
                        <Typography sx={{ fontWeight: 500, fontSize: 17 }}>Platelet Count</Typography>
                    </Box>
                    <IconButton onClick={() => navigate(-1)}>  <Close sx={{ color: 'red' }} /></IconButton>
                </Box>
                <Box sx={{ mt: 1 }}>
                    <LineChart />
                </Box>
                <Paper elevation={3} sx={{ p: "5px 20px", borderRadius: '10px', mt: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Typography>Track this on Dashboard</Typography>
                        <Switch defaultChecked />
                    </Box>
                </Paper>
                <Paper elevation={3} sx={{ p: "10px 20px", borderRadius: '10px', mt: 2 }}>
                    <Box sx={{ display: 'flex', gap: 2 }}>
                        <RecommendOutlined />
                        <Box>
                            <Typography sx={{ fontWeight: 500 }}>
                                Recommendations
                            </Typography>
                            <Typography sx={{ fontSize: '14px' }}>
                                Rx: Dr Venkat Reddy K - Neonatologist
                            </Typography>
                        </Box>
                    </Box>

                    <Box sx={{ mt: 1 }}>
                        <Typography sx={{ fontSize: '14px' }}>Target Measure</Typography>
                        <Typography sx={{ fontSize: '14px', color: 'black' }}>Platelet count    :    150000 - 400000 mm Hg</Typography>
                    </Box>

                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '14px', color: 'black' }}>Measure Frequency  :    Daily</Typography>
                        <Typography sx={{ fontSize: '14px', color: 'black' }}>Acheivement Status :    improving</Typography>

                    </Box>
                    <Divider sx={{ mt: 2 }} />
                    <Box sx={{ mt: 1, display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '14px' }}>
                            Start Date : immediately
                        </Typography>

                        <Typography sx={{ fontSize: '14px' }}>
                            Due Date : 31 Dec 2024
                        </Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography sx={{ fontSize: '14px' }}>Last Observation  (23 sep 2023)</Typography>
                        <Typography sx={{ fontSize: '14px' }}>Platelet count 110000 per mcL </Typography>
                    </Box>

                    <Box sx={{ mt: 2 }}>
                        <Typography sx={{ fontSize: '14px' }}>Addresses</Typography>
                        <Typography sx={{ fontSize: '14px' }}>Dengue Fever</Typography>
                        <Typography sx={{ fontSize: '14px' }}>hermorhagic fever (Risk)</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography sx={{ fontSize: '14px' }}>Care Plan</Typography>
                        <Typography sx={{ fontSize: '14px' }}>Dengue management-uncomplicated</Typography>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Typography sx={{ fontSize: '14px' }}>MedicationRequest</Typography>
                        <Typography sx={{ fontSize: '14px' }}>Tablet.Dolo 650</Typography>
                    </Box>
                </Paper>

                <Typography sx={{ fontWeight: 500, mt: 1 }}>Reference</Typography>
                <Paper elevation={3} sx={{ p: "10px 20px", borderRadius: '10px', mt: 1 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography sx={{ fontSize: '14px' }}>Out-Patient Consultation</Typography>
                        <Typography sx={{ fontSize: '14px' }}>12-Mar-2022 | 3:00 PM</Typography>
                    </Box>

                    <Grid container sx={{ mt: 1 }}>
                        <Grid item md={0.7} mt={1}>
                            <img src={prescriptionIcon} alt="prescription" />
                        </Grid>
                        <Grid item md={11}>
                            <Typography sx={{ fontWeight: 500, fontSize: '14px' }}>Paramitha Super Speciality Children Hospitals  </Typography>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Typography sx={{ fontSize: '14px' }}>Dr Venkat Reddy K - Neonatologist </Typography>
                                <Typography sx={{ fontSize: '14px' }}>Prescription</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Paper>
                <Typography sx={{ fontWeight: 500, mt: 1 }}>About Platelet Count</Typography>
                <Paper elevation={3} sx={{ p: "15px", mt: 1, borderRadius: '10px' }}>
                    <Typography sx={{ fontSize: '14px' }} component={'p'}>Platelet Count Test measures the number of platelets in a person’s blood. Platelets are small, colorless cell fragments that help in blood clotting to prevent bleeding. The test is usually ordered by a doctor when a patient has symptoms of abnormal bleeding or clotting.</Typography>
                </Paper>
                <Box>
                    <ListCards title="Symptoms" />
                    <ListCards title="In emergency" />
                    <ListCards title="What to Do & Don't" />
                    <ListCards title="Patient Resources" />
                </Box>
            </Box>
        </div >
    )
}

export default PlateletCount;