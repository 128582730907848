const initialState = {
    healthstatus: [],
    deletetokentxnId: {},
    consentdata: {},
    Resquesters: [],
    granteddata: [],
    denieddata: [],
    revokeddata: [],
    expireddata: [],
    healthlocker: [],
    healthlockerlist: [],
    healthlockerConsents: [],
};

const consentReducers = (state = initialState, action) => {
    switch (action.type) {


        case 'DELETE_XTOKEN_TXNID':
            return {
                ...state,
                deletetokentxnId: action.payload,
            };
        case 'CONSENTDATA':
            return {
                ...state,
                consentdata: action.payload,
            };
        case 'GRANTED_DATA':
            return {
                ...state,
                granteddata: action.payload,
            };
        case 'REQUEST_DATA':
            return {
                ...state,
                Resquesters: action.payload,
            };
        case 'DENIED_DATA':
            return {
                ...state,
                denieddata: action.payload,
            };
        case 'REVOKED_DATA':
            return {
                ...state,
                revokeddata: action.payload,
            };
        case 'EXPIRED_DATA':
            return {
                ...state,
                expireddata: action.payload,
            };
        case 'HEALTH-LOCKER':
            return {
                ...state,
                healthlocker: action.payload,
            };
        case 'HEALTH-STATUS':
            return {
                ...state,
                healthstatus: action.payload,
            };
        case 'HEALTHLOCKERS-DATA':
            return {
                ...state,
                healthlockerlist: action.payload,
            };
        case 'HLCONSENT_DATA':
            return {
                ...state,
                healthlockerConsents: action.payload,
            };
        default:
            return state;
    }
};
export default consentReducers;
