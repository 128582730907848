import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { ArrowDropDown, ArrowDropUp, Check, ExpandLess, ExpandMore, Pending, RemoveCircleOutline, Security, TaskAlt } from '@mui/icons-material';
import { Box, Card, Checkbox, CircularProgress, Divider, Grid, IconButton, Typography } from '@mui/material';
import { useState } from 'react';
import URL from '../../../../utility/apiurl';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import consentIcon from '../../../../assets/home/consent_module/consent.svg'
import EditIcon from '../../../../assets/home/consent_module/edit.svg'
import hospitalIcon from '../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import prescriptionIcon from '../../../../assets/home/consent_module/prescription.svg';
import consultationIcon from '../../../../assets/home/consent_module/consultation.svg';
import diagnosticIcon from '../../../../assets/home/consent_module/diagnostic.svg';
import dischargeIcon from '../../../../assets/home/consent_module/discharge.svg';
import immunisationIcon from '../../../../assets/home/consent_module/immunisation.svg';
import documentIcon from '../../../../assets/home/consent_module/document.svg';
import WellnessRecordIcon from '../../../../assets/home/consent_module/lifestyle.svg';
import moment from 'moment';
import { useEffect } from 'react';
import { getLinkedProviders } from '../../../../redux/Actions/recordActions';
import dayjs from 'dayjs';
import { DateFormat } from '../../../atoms/DateTimeFormat/DateFormat';
import { Time } from '../../../atoms/DateTimeFormat/TimeFormat';
import { getConsentsrequested } from '../../../../redux/Actions/consentActions';
import EditApprovePin from './EditApprovePin';
import CommonCalender from '../../../atoms/CommonCalender';


const InformationTypeConsent = (props) => {
    const { onSelectedItemsChange, item, onUnSelectedItemsChange, selectedCheckboxes } = props;
    const items = item?.hiTypes
    const [selectAllChecked, setSelectAllChecked] = useState(true);
    const [open, setOpen] = useState(false)

    const StatusIcon = (type) => {
        if (type === "DiagnosticReport") {
            return <img src={diagnosticIcon} alt="diagnostic" width={25} height={25} />
        }
        if (type === "DischargeSummary") {
            return <img src={dischargeIcon} alt="discharge" width={25} height={25} />
        }
        if (type === "OPConsultation") {
            return <img src={consultationIcon} alt="consult" width={25} height={25} />
        }
        if (type === "ImmunizationRecord") {
            return <img src={immunisationIcon} alt="record" width={25} height={25} />
        }
        if (type === "WellnessRecord") {
            return <img src={WellnessRecordIcon} alt="wellness" width={25} height={25} />
        }
        if (type === "HealthDocumentRecord") {
            return <img src={documentIcon} alt="document" width={25} height={25} />
        }
        if (type === "Prescription") {
            return <img src={prescriptionIcon} alt="prescription" width={25} height={25} />
        }
    }

    useEffect(() => {
        // Initialize selectedCheckboxes based on selected item texts when the component mounts
        onSelectedItemsChange(items?.map((item) => item));
        onUnSelectedItemsChange([]);
    }, []);


    const handleSelectAll = () => {
        // if (selectedCheckboxes.length === items.length) {
        if (selectedCheckboxes) {
            onUnSelectedItemsChange(items?.map((item) => item));
            onSelectedItemsChange([]);
        } else {
            onSelectedItemsChange(items?.map((item) => item));
            onUnSelectedItemsChange([]);
        }
        setSelectAllChecked(!selectAllChecked);
    }

    const handleCheckboxChange = (itemName) => {
        onSelectedItemsChange((prevSelectedItems) => {
            if (prevSelectedItems.includes(itemName)) {
                return prevSelectedItems.filter((selectedItem) => selectedItem !== itemName);
            } else {
                return [...prevSelectedItems, itemName];
            }
        });

        onUnSelectedItemsChange((prevUnselectedItems) => {
            if (prevUnselectedItems.includes(itemName)) {
                return prevUnselectedItems.filter((unSelectedItem) => unSelectedItem !== itemName);
            } else {
                return prevUnselectedItems.concat(itemName);
            }
        });
        const updatedSelectedItems = selectedCheckboxes.includes(itemName)
            ? selectedCheckboxes.filter((selectedItem) => selectedItem !== itemName)
            : [...selectedCheckboxes, itemName];
        setSelectAllChecked(updatedSelectedItems.length === items.length);
    }


    return (
        <div>
            <Box>
                <Card sx={{
                    boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)'
                }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: '#FEF0FF', p: "0px 10px" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            {/* <Box sx={{ border: '1px solid black', width: "25px", height: '25px', borderRadius: 5, cursor: 'pointer' }} onClick={handleSelectAll}>
                                {selectAllChecked ? <Check sx={{ color: '#0118B4' }} /> : null}
                            </Box> */}

                            <Checkbox
                                color="secondary"
                                checked={selectAllChecked}
                                onClick={handleSelectAll}
                            />
                            <Typography sx={{ fontWeight: 600, fontSize: { xs: 14, sm: 14, md: 14 } }}>{"All (Prescription,Reports,Discharge,...)"}</Typography>
                        </Box>
                        <IconButton onClick={() => setOpen(!open)}>{open ? <ExpandLess sx={{ color: "black" }} /> : <ExpandMore sx={{ color: "black" }} />}</IconButton>
                    </Box>
                    {
                        open && (
                            <Box sx={{
                                maxHeight: '200px', // Set your desired maximum height
                                overflowY: 'auto',
                                '&::-webkit-scrollbar': {
                                    display: 'none',
                                },
                            }}>
                                {items ?
                                    items.map((hiType, index) => (
                                        <React.Fragment key={index}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "5px 15px" }}>
                                                <Box key={index} sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                                                    {StatusIcon(hiType)}
                                                    <Typography sx={{ fontSize: { xs: 14, md: 14, sm: 14 } }}>{hiType}</Typography>
                                                </Box>
                                                <Checkbox
                                                    checked={selectedCheckboxes.includes(hiType)} color='secondary'
                                                    onChange={() => handleCheckboxChange(hiType)}
                                                />
                                            </Box>
                                            <Divider />
                                        </React.Fragment>
                                    )) : <Typography sx={{ p: "5px 15px", fontSize: { xs: 14, md: 14, sm: 14 } }}>No Data Available</Typography>

                                }
                            </Box>
                        )}
                </Card>
            </Box>
        </div>
    )
}

const InformationSourceConsent = (props) => {
    const { links, item, selectedHospitals, setSelectedHospitals, setCreateAt } = props || {};
    const [open, setOpen] = useState(false)
    const [openStates, setOpenStates] = useState([]);
    const [isCheckedState, setIsCheckedState] = useState({});
    const [selectedRecords, setSelectedRecords] = useState({}); // Store selected records
    // console.log(selectedHospitals, "selectedhos")
    // console.log(isCheckedState, "selectedstate")
    // console.log(selectedRecords, "selectedrecords")
    const handleHospitalToggle = (itemId) => {
        setOpenStates((prev) => ({
            ...prev,
            [itemId]: !prev[itemId],
        }));
    };


    useEffect(() => {
        // Extract the Date and Time from the API data
        if (item && item.createdAt) {
            const formattedDate = DateFormat(item?.createdAt); // Date portion
            const formattedTime = Time(item?.createdAt); // Time portion
            const Expiry = moment(item?.period?.from).format('DD-MMM-YYYY');
            const Valid = moment(item?.period?.to).format('DD-MMM-YYYY');
            // Update the createat state using ...prev
            setCreateAt((prev) => ({
                ...prev, // Spread the previous state
                Date: formattedDate,
                Time: formattedTime,
                Expiryfrom: Expiry,
                Expiryfrom1: Expiry,
                Validto: Valid,
                Validto1: Valid,
            }));
        }
        handleallcheckbox()

    }, [links]);


    const handleallcheckbox = () => {
        // Check if all checkboxes are selected
        const areAllCheckboxesSelected = selectedHospitals?.length === links?.length;

        if (areAllCheckboxesSelected) {
            // Deselect all hospitals
            setSelectedHospitals([]);
            setSelectedRecords({});
            setIsCheckedState({});
        } else {
            // Select all hospitals
            const selectedHospitalsCopy = [...links];
            const selectedRecordsCopy = {};
            const isCheckedStateCopy = {};

            // Create an object with all records selected for each hospital
            for (let i = 0; i < selectedHospitalsCopy?.length; i++) {
                const hipId = selectedHospitalsCopy[i].hip.id;
                const recordsCount = selectedHospitalsCopy[i]?.careContexts.length;

                selectedRecordsCopy[hipId] = Array.from({ length: recordsCount }, (_, j) => j);
                isCheckedStateCopy[i] = true;
            }

            setSelectedHospitals(selectedHospitalsCopy);
            setSelectedRecords(selectedRecordsCopy);
            setIsCheckedState(isCheckedStateCopy);
        }
    };
    const handlehospitaldata = (index) => {
        // Get the hospital at the given index
        const hospital = links[index];
        const hipId = hospital?.hip?.id;

        // Find the selected hospital's index in the selectedHospitals array
        const selectedHospitalIndex = selectedHospitals.findIndex(
            (selected) => selected?.hip?.id === hipId
        );

        if (selectedHospitalIndex !== -1) {
            // If the hospital is already selected, deselect it
            const updatedSelection = selectedHospitals.filter(
                (selected) => selected?.hip?.id !== hipId
            );

            // Deselect all records for this hospital by removing it from selectedRecords
            const updatedSelectedRecords = { ...selectedRecords };
            delete updatedSelectedRecords[hipId];

            setSelectedHospitals(updatedSelection);
            setSelectedRecords(updatedSelectedRecords);
            setIsCheckedState((prevState) => ({
                ...prevState,
                [index]: false,
            }));
        } else {
            // If the hospital is not selected, select it
            const updatedSelection = [...selectedHospitals, hospital];

            // Select all records for this hospital or add them if not present
            const recordsCount = hospital?.careContexts?.length;
            const existingSelectedRecords = selectedRecords[hipId] || [];
            const updatedSelectedRecords = {
                ...selectedRecords,
                [hipId]: [...existingSelectedRecords, ...Array.from({ length: recordsCount }, (_, i) => i)],
            };

            setSelectedHospitals(updatedSelection);
            setSelectedRecords(updatedSelectedRecords);
            setIsCheckedState((prevState) => ({
                ...prevState,
                [index]: true,
            }));
        }
    };

    const handlerecords = (hospitalIndex, recordIndex, record) => {
        // Get the hospital at the given index
        const hospital = links[hospitalIndex];
        const hipId = hospital?.hip?.id;

        // Find the selected hospital's index in the selectedHospitals array
        const selectedHospitalIndex = selectedHospitals?.findIndex(
            (selected) => selected?.hip?.id === hipId
        );

        if (selectedHospitalIndex !== -1) {
            const updatedHospital = { ...selectedHospitals[selectedHospitalIndex] };
            const careContexts = [...updatedHospital.careContexts]; // Create a copy to avoid mutating state directly

            // Check if the record is already selected for this hospital
            const isRecordSelected = selectedRecords[hipId] || [];


            if (isRecordSelected?.includes(recordIndex)) {
                // If it's already selected, remove it
                const updatedRecords = isRecordSelected?.filter(
                    (selectedIndex) => selectedIndex !== recordIndex
                );

                // Update the selectedRecords with the updated records for this hospital
                setSelectedRecords((prevState) => ({
                    ...prevState,
                    [hipId]: updatedRecords,
                }));

                // Find the index of the selected record in careContexts
                const recordToRemoveIndex = careContexts?.findIndex(
                    (context) => context.referenceNumber === record.referenceNumber
                );

                if (recordToRemoveIndex !== -1) {
                    // Remove the record from the hospital's careContexts
                    careContexts?.splice(recordToRemoveIndex, 1);
                }
            } else {
                // If it's not selected, add it
                const selectedRecord = hospital?.careContexts[recordIndex];



                // // Update the selectedRecords with the updated records for this hospital
                // setSelectedRecords((prevState) => ({
                //   ...prevState,
                //   [hipId]: [...isRecordSelected, selectedRecord],
                // }));

                setSelectedRecords((prevState) => ({
                    ...prevState,
                    [hipId]: [...prevState[hipId], recordIndex],
                }));
                // Add the record to the hospital's careContexts
                careContexts?.push(selectedRecord);
            }



            // Update the selectedHospitals array with the updated hospital
            const updatedHospitals = [...selectedHospitals];
            updatedHospitals[selectedHospitalIndex] = { ...updatedHospital, careContexts };

            // Update the state with the updated selectedHospitals
            setSelectedHospitals(updatedHospitals);
        }
    };

    // Define the isRecordSelected function
    const isRecordSelected = (hipId, recordIndex) => {
        const selectedRecordIndices = selectedRecords[hipId] || [];
        return selectedRecordIndices?.includes(recordIndex);
    };

    return (
        <Box >
            <Card sx={{
                boxShadow: '1px 4px 8px rgba(0, 0, 0, 0.1), 0 0 2px rgba(0, 0, 0, 0.2)'
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', bgcolor: '#FEF0FF', p: '0px 10px' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>

                        <Checkbox
                            color="secondary"
                            checked={Boolean(links && links.length === selectedHospitals?.length)}
                            onChange={handleallcheckbox}
                        />

                        <Typography sx={{ fontWeight: 600, fontSize: { xs: 14, sm: 15, md: 14 } }}>{"All Facilities and Health Programs"}</Typography>
                    </Box>
                    <IconButton onClick={() => setOpen(!open)}>{open ? < ExpandLess sx={{ color: "black" }} /> : < ExpandMore sx={{ color: "black" }} />}</IconButton>
                </Box>

                {
                    open && (
                        <Box sx={{
                            maxHeight: '250px', // Set your desired maximum height
                            overflowY: 'auto',
                            '&::-webkit-scrollbar': {
                                display: 'none',
                            },
                        }}>
                            {
                                links.map((link, index) => (
                                    <Box key={index}>
                                        <Card sx={{ mt: "10px", p: "0px 10px", display: 'flex', alignItems: 'center', justifyContent: 'space-between' }} >
                                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                <Checkbox color="secondary" checked={Boolean(isCheckedState[index])}
                                                    onChange={() => handlehospitaldata(index)} />
                                                <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14 } }}> {link?.hip?.name}</Typography>
                                            </Box>
                                            <Box>
                                                <IconButton onClick={() => handleHospitalToggle(index)} > {openStates[index] ? <ArrowDropUp sx={{ color: 'black' }} /> : <ArrowDropDown sx={{ color: 'black' }} />}</IconButton>
                                            </Box>
                                        </Card >
                                        {
                                            openStates[index] && (
                                                <Box >
                                                    {link?.careContexts?.map((record, recordIndex) => (

                                                        <Card sx={{ p: "6px 10px", display: 'flex', justifyContent: 'space-between', mt: "2px", alignItems: 'center', bgcolor: 'whitesmoke' }} key={recordIndex}>
                                                            <Typography key={recordIndex} sx={{ fontSize: { xs: 14, md: 14, sm: 14 } }}>{record.display}</Typography>
                                                            <Checkbox
                                                                color='secondary'
                                                                checked={Boolean(isRecordSelected(link.hip.id, recordIndex))}
                                                                onChange={() => handlerecords(index, recordIndex, record)}
                                                            />
                                                        </Card >

                                                    ))}
                                                </Box >)
                                        }
                                    </Box>

                                ))
                            }
                        </Box>
                    )
                }
            </Card >


        </Box >
    )
}


function ConsentRequestEdit(props) {

    const { hospital, item, date, time, expirydate, expirytime, setShow, handleClose } = props
    let linkeddata = useSelector(stat => stat.records.linkedproviders);
    const primaryId = localstore.getPrimaryId()
    const token = localstore.getToken()
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    // console.log(expirytime, "time")
    const dispatch = useDispatch()
    const abhadata = useSelector((state) => state.abha.abhaprofile)
    const [state, setState] = useState(1)
    const [createat, setCreateAt] = useState({ Date: null, Time: null, Validto: null, Expiryfrom: null, Validto1: null, Expiryfrom1: null });
    // console.log(createat, "creataaaaa")
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [unselectedCheckboxes, setUnselectedCheckboxes] = useState([]);
    const [selectedHospitals, setSelectedHospitals] = useState([]);
    const [links, setLinks] = useState([])
    const handleDropdownSelect = (selectedItems) => {
        setSelectedCheckboxes(selectedItems);
    };
    const handleDropdownUnSelect = (selectedItems) => {
        setUnselectedCheckboxes(selectedItems);
    };

    useEffect(() => {
        setLinks(linkeddata?.patient?.links)
        if (token && primaryId) {
            dispatch(getLinkedProviders(token, primaryId));
        }
    }, [token, primaryId])
    const handleDateSelect = (date) => {
        let newdate = moment(date).format('DD-MMM-YYYY');
        let ISODATE = date.toISOString();
        setCreateAt((prev) => ({
            ...prev,
            Expiryfrom: newdate,
            Expiryfrom1: ISODATE,
        }));
    };

    const handleValidto = (date) => {
        let newdate = moment(date).format('DD-MMM-YYYY');
        let ISODATE = date.toISOString();
        setCreateAt((prev) => ({
            ...prev,
            Validto: newdate,
            Validto1: ISODATE,
        }));
    };

    const handleCancel = () => {
        setAlertmessage('')
        setSelectedHospitals([])
        // setLinks([])
        handleClose()
        setShow(1)
    }
    return (
        <div>
            {state === 1 ? (
                <>
                    <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', gap: 1.5, alignItems: 'center' }}>
                            <img src={consentIcon} alt='consent' />
                            <Typography sx={{ fontSize: { xs: 14, sm: 16, md: 17 }, fontWeight: 600 }}>Conform Requests</Typography>
                        </Box>
                        <Button sx={{ height: "25px", color: 'black', borderRadius: "15px" }} variant='outlined' onClick={handleCancel}>cancel</Button>
                    </DialogTitle>
                    <Divider />

                    <DialogContent sx={{
                        '&::-webkit-scrollbar': {
                            display: 'none',
                        },
                    }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                                {item?.requester?.name ?
                                    <Typography sx={{ fontSize: { xs: '14px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                                        {item?.requester?.name}
                                    </Typography>
                                    : <Typography sx={{ fontSize: { xs: '14px', sm: '14px', md: '14px' } }} color="text.secondary" gutterBottom>
                                        Health Locker
                                    </Typography>
                                }
                                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', }}>
                                    {hospital.orgImg ?
                                        <img src={hospital.orgImg} alt="orgimg" width={45} height={45} style={{ borderRadius: '5px', marginLeft: "12px" }} />
                                        :
                                        <img src={hospitalIcon} alt="hospital" style={{ width: 50, height: 50 }} />
                                    }
                                    <Box>
                                        {hospital?.identifier?.name ?
                                            <Typography sx={{ fontSize: { xs: '14px', sm: '14px', md: '14px' }, fontWeight: 600 }}>{hospital?.identifier?.name}</Typography> : <Typography sx={{ fontSize: { xs: '13px', sm: '14px', md: '14px' }, fontWeight: 500 }}>Medxperts Health Locker</Typography>}

                                        <Typography sx={{ mt: "2px", fontSize: { xs: '14px', sm: '14px', md: '14px' } }}>{item?.purpose?.text}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            <Box>
                                <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14 }, fontWeight: 600 }}>Requested On</Typography>
                                <Typography sx={{ fontSize: { xs: '11px', sm: '13px', md: '13px' } }} >
                                    {date} | {time}
                                </Typography>
                                <Typography sx={{ fontSize: { xs: 13, sm: 14, md: 14 }, fontWeight: 600, mt: 1 }}>Consent Expiry </Typography>
                                <Typography sx={{ fontSize: { xs: '11px', sm: '12px', md: '13px' } }} >
                                    {expirydate} | {expirytime}
                                </Typography>
                            </Box>
                        </Box>


                        <Typography sx={{ color: 'black', mt: 1, fontSize: { xs: 14, sm: 15, md: 16 }, color: '#1843D0', }}> Requested Information</Typography>
                        <Box mt={1}>
                            <InformationTypeConsent item={item} selectedCheckboxes={selectedCheckboxes} onSelectedItemsChange={handleDropdownSelect} onUnSelectedItemsChange={handleDropdownUnSelect} />
                        </Box>

                        <Typography sx={{ color: 'black', mt: 2, fontSize: { xs: 14, sm: 15, md: 16 } }}>Information Source</Typography>
                        <Box mt={1}>

                            <InformationSourceConsent item={item} links={links} selectedHospitals={selectedHospitals} setSelectedHospitals={setSelectedHospitals} setCreateAt={setCreateAt} handleCancel={handleCancel} />
                        </Box>
                        <Box>
                            <Typography sx={{ color: 'black', mt: 2, fontSize: { xs: 14, sm: 15, md: 16 } }} component={'p'}>Received Information Period</Typography>
                        </Box>
                        <Grid container  >
                            <Grid item xs={6}>
                                <Typography sx={{ fontSize: '14px' }}>From</Typography>
                                <CommonCalender width={{ sm: "200px", md: '200px' }} value={dayjs(createat?.Expiryfrom)} onChange={handleDateSelect} />
                            </Grid>
                            <Grid xs={6}>
                                <Typography sx={{ fontSize: '14px' }}>To</Typography >
                                <CommonCalender width={{ sm: "200px", md: '200px' }} value={dayjs(createat?.Validto)} onChange={handleValidto} />
                            </Grid>
                            {/* <Typography sx={{ color: 'black', mt: 1, fontSize: { xs: 14, sm: 15, md: 16 } }} component={'p'}>from  07-11-2023 to 07-11-2024</Typography> */}
                        </Grid>

                    </DialogContent>
                    <Divider />
                    <DialogActions>
                        <Box sx={{ display: 'flex', justifyContent: "end", gap: 2, mt: 1, mb: 1, mr: 2 }}>
                            <Button variant='outlined' sx={{ color: 'red', width: '100px', borderRadius: 2, border: '1px solid red', "&:hover": { border: '1px solid red' } }} onClick={() => props.setShow(1)}>Close</Button>
                            <Button variant="contained" sx={{ bgcolor: '#247FEA', gap: 1, width: "150px", borderRadius: 2 }} startIcon={loading ? <CircularProgress sx={{ color: "white" }} size={20} /> : <TaskAlt />} onClick={() => setState(2)}>
                                Approve
                            </Button>
                        </Box>
                    </DialogActions>
                </>
            ) : state === 2 ? (
                <EditApprovePin selectedHospitals={selectedHospitals} selectedCheckboxes={selectedCheckboxes} createat={createat} handleCancel={handleCancel} item={item} token={token} primaryId={primaryId} abhadata={abhadata} handleClose={handleClose} />
            ) : null
            }
        </div >

    );
}
export default ConsentRequestEdit;