import React from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { useTranslation } from "react-i18next";

function OrgOverview({ user }) {
    const [t, i18n] = useTranslation("global");
    return (
        <Box>
            <Typography sx={{ fontWeight: "bold" }}>
                {t("overview.about")}
            </Typography>
            <div style={{ marginTop: "10px" }}>
                {user?.description?.length > 0 ? (
                    <Typography sx={{ fontSize: { xs: 11, sm: 12, md: 15 } }}>
                        {user?.description}
                    </Typography>
                ) : (
                    <Typography sx={{ fontSize: { xs: 11, sm: 12, md: 15 } }}>
                        {t("overview.notmention")}
                    </Typography>
                )}
            </div>
            <Divider sx={{ mt: 2 }} />
            <Typography sx={{ fontWeight: "bold", mt: 2 }}>
                {" "}
                {t("overview.websites")}
            </Typography>
            <div style={{ marginTop: "10px" }}>
                {user?.pageWebsite?.length > 0 ? (
                    <Typography sx={{ fontSize: { xs: 11, sm: 12, md: 15 } }}>
                        {user?.pageWebsite}
                    </Typography>
                ) : (
                    <Typography sx={{ fontSize: { xs: 11, sm: 12, md: 15 } }}>
                        {t("overview.notmention")}
                    </Typography>
                )}
            </div>
        </Box>
    );
}

export default OrgOverview