import React, { useEffect, useState } from 'react'
import { Box, Card, CircularProgress, IconButton, Typography } from '@mui/material'
import SuggestOrg from './SuggestOrg'
import URL from '../../../../utility/apiurl';
import axios from 'axios';
import { localstore } from '../../../../localStore/localStore';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import MoreSuggestOrgs from './MoreSuggestOrgs';
import InfiniteScroll from 'react-infinite-scroll-component';

function MoreHospitals() {
    const [suggestOrg, setSuggestOrg] = useState([]);
    const [offset, setOffset] = useState(0);
    const [orgcount, setOrgcount] = useState(0)
    const [isLoading, setIsLoading] = useState(false);
    const patientId = localstore.getPatientId()
    const navigate = useNavigate()
    const token = localstore.getToken()
    const limit = 21;
    useEffect(() => {

        fetchData()

    }, []);

    const fetchData = () => {
        axios.get(`${URL.suggestedOrganisations}${limit}/${offset}/${patientId}`)
            .then((res) => {
                setSuggestOrg(res.data.data)
                // console.log("=======>>orgss", res.data)
                setOrgcount(res.data.count)
                setIsLoading(true)

            })
            .catch((error) => console.log(error, 'EERRRRRRROOOOORRRRRR'));
    }


    const handleOrgFavourite = (index) => {
        let data = suggestOrg.filter((e, i) => {
            if (i !== index) {
                return (e);
            }
        });
        setSuggestOrg(data);
    }


    return (
        <Box sx={{ p: 1, mt: "13px" }}>
            <Card sx={{ p: 1 }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    <IconButton>
                        <ArrowBack onClick={() => navigate(-1)} sx={{ cursor: "pointer" }} />
                    </IconButton>
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>
                        All  Hospitals & Providers Around
                    </Typography>
                </Box>
                {!isLoading ? (
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                        }}
                    >
                        <CircularProgress sx={{ color: "#CDF4F9" }} />
                    </Box>) : (
                    <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-around', p: 1, }}>
                        {suggestOrg?.map((each, ind) => (
                            <MoreSuggestOrgs key={ind} item={each}
                                index={ind} handleOrgFavourite={() => handleOrgFavourite(ind)} />
                        ))}
                    </Box>
                )}

            </Card>
            {isLoading ? ( // Render CircularProgress if isLoading is true
                <CircularProgress sx={{ display: 'block', mx: 'auto', mt: 3 }} />
            ) : (
                <InfiniteScroll
                    dataLength={suggestOrg?.length}
                    next={fetchData}
                    hasMore={suggestOrg.length < orgcount} // Adjust this condition based on your logic
                    loader={<h4>Loading...</h4>}
                />
            )}
        </Box>
    )
}

export default MoreHospitals