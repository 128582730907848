import { AddCircleOutline, Description, ShoppingCartTwoTone, VolunteerActivismTwoTone } from '@mui/icons-material'
import { Box, Card, Divider, Typography } from '@mui/material'
import React from 'react'
import { OutlinedButton } from '../../atoms/CommonButton'
import AccountIcon from '../../../assets-new/menubar-icons/account.svg'


function Account() {
    return (
        <Box sx={{ mt: { md: 2, xs: 8, sm: 9 }, p: { xs: "0px 10px", sm: "0px 10px", md: '0px 300px' }, pt: { xs: '10px', sm: "5px", md: '45px' }, mb: { xs: 10, sm: 7, md: 2 } }}>
            <Typography sx={{ fontWeight: 600, fontSize: '17px' }}>Insurance </Typography>

            <Card sx={{ p: '5px 20px', mt: '15px', borderRadius: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <AddCircleOutline />
                    <Typography sx={{ fontWeight: 500 }}>Insurance & Healthcare Program</Typography>
                </Box>
                <Divider sx={{ mt: '10px' }} />
                <Box sx={{ height: '35px' }}></Box>
            </Card>
            <Card sx={{ p: '7px 20px', mt: '15px', borderRadius: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '8px' }}>
                    <Box>
                        <Typography sx={{ fontWeight: 600 }}>MedXperts Health card</Typography>
                        <Typography component={'p'} sx={{ fontSize: '14px', mt: '5px' }}>Credit card for cash less payments Pay now,no more wait for claim settlement.<p> Pay later & Easy EMI</p></Typography>
                    </Box>
                    <OutlinedButton text="Apply Now" width="145px" height='35px' />
                </Box>

            </Card>
            <Card sx={{ p: '5px 20px', mt: '15px', borderRadius: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                    <ShoppingCartTwoTone />
                    <Box>
                        <Typography sx={{ fontWeight: 600 }}>Checkout </Typography>
                        <Typography sx={{ fontSize: '14px' }}>Powered by ONDC </Typography>
                    </Box>
                </Box>

            </Card>

            <Card sx={{ mt: '15px', p: '5px 20px', borderRadius: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Typography sx={{ fontWeight: 500 }}>My Orders</Typography>
                </Box>
            </Card>
            <Card sx={{ mt: '15px', p: '5px 20px', borderRadius: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <Description />
                    <Typography sx={{ fontWeight: 500 }}>Payments & Invoice</Typography>
                </Box>
            </Card>
            <Card sx={{ mt: '15px', p: '5px 20px', borderRadius: '10px' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <img src={AccountIcon} />
                    <Typography sx={{ fontWeight: 500 }}>Donate (money,blood,organs) </Typography>
                </Box>
            </Card>
        </Box>
    )
}

export default Account