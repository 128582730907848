import React from 'react';
import DehazeIcon from '@mui/icons-material/Dehaze';
import SearchBar from "../../../atoms/Searchbar"
import { Box, Button, Card, Grid, IconButton, List, ListItem, ListItemText, Typography } from "@mui/material";
import { useEffect } from 'react';
import { OrganizationIdentifier, getLinkedProviders } from '../../../../redux/Actions/recordActions';
import { localstore } from '../../../../localStore/localStore';
import { useDispatch, useSelector } from 'react-redux';
import { useState } from 'react';
import linkIcon from '../../../../assets/home/records_module/link.svg'
import hospitalIcon from '../../../../assets/home/bookapp_assets/hospital.svg'
import filterIcon from '../../../../assets-new/filter-Icon.svg'
import { useTranslation } from "react-i18next";
import { AddBox, ArrowForwardIos, SystemUpdateAlt } from '@mui/icons-material';
import searchblue from '../../../../assets-new/services-icons/search-Icon-blue.svg'
import { useNavigate } from 'react-router-dom';
import SyncRecords from './SyncRecords';

const LinkedProviders = () => {
    let linkeddata = useSelector(stat => stat.records.linkedproviders);
    // let providerdata = useSelector(stat => stat.records.providerdata);

    console.log(linkeddata, "linkeddata")
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const dispatch = useDispatch()
    const [searchValue, setSearchValue] = useState('');
    const [loading, setLoading] = useState(false)
    const [links, setLinks] = useState([])
    const [show, setShow] = useState(1)
    const [list, setList] = useState('')
    const [t, i18n] = useTranslation("global");
    const [open, setOpen] = useState(false)
    const navigate = useNavigate()
    useEffect(() => {
        if (token && primaryId) {
            dispatch(getLinkedProviders(token, primaryId));
        }
    }, [token, primaryId])


    useEffect(() => {
        if (linkeddata?.patient?.links) {
            setLinks(linkeddata?.patient?.links);
        }
    }, [linkeddata]);

    const searchOnChangeHandler = (e) => {
        const searchTerm = e.target.value.toLowerCase();
        setSearchValue(searchTerm);

        const filtered = linkeddata.patient.links.filter(link => {
            const hipName = link.hip.name.toLowerCase();
            return hipName.includes(searchTerm);
        });

        setLinks(filtered);
    };
    // console.log(links, "linkssss")

    const handleOpenDialogue = () => {
        setOpen(!open)
    }
    const handleSyncRecords = (e) => {
        dispatch(OrganizationIdentifier(e.hip.id, token, primaryId));
        navigate('/records/syncrecords', { state: { linkedprovider: e } });
    }

    return (
        <Box m={1}>
            <Typography sx={{ mt: { xs: 0, sm: 1, md: '12px' }, fontSize: '17px', fontWeight: 600 }}> Linked Providers</Typography>
            <Box sx={{ mt: { xs: 2, sm: 2, md: 3 }, }}>

                <Grid container spacing={2} sx={{ alignItems: "center" }}>
                    <Grid item xs={2} sm={1} md={1}  >
                        <img src={filterIcon} alt="filter" />
                    </Grid>
                    <Grid item xs={10} sm={11} md={8} sx={{
                        '.MuiTextField-root': {
                            width: '100%'
                        }
                    }}
                    >
                        <SearchBar placeholder="Search Link Providers" value={searchValue} name='searchValue'
                            onChange={searchOnChangeHandler} icon={searchblue} />
                    </Grid>

                </Grid>
                <Box sx={{ display: "flex", mt: 2, gap: 2, ml: "3px" }}>
                    <img src={linkIcon} alt="link" />
                    <Typography sx={{ fontSize: '14px' }}>Linked Providers</Typography>
                </Box>
                <Box>
                    {links.length > 0 ?
                        links?.map((list, index) => (
                            <Box sx={{ pr: '40px' }} key={index}>
                                <Card
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        gap: 3,
                                        // ml: 3,
                                        mt: 2,
                                        p: "7px 20px",
                                        cursor: 'pointer'

                                    }}
                                    onClick={() => handleSyncRecords(list)}
                                >
                                    <Box
                                        sx={{
                                            display: "flex",
                                            gap: 3,
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Box>
                                            {
                                                list.orgPageImage ?
                                                    < img src={list.orgPageImage}
                                                        alt=" hospital" style={{ width: 40, height: 40, borderRadius: '5px' }} /> :
                                                    < img src={hospitalIcon}
                                                        alt=" hospital" style={{ width: 40, height: 40, background: '#D4F4F8', padding: 5, borderRadius: '5px' }} />
                                            }
                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontWeight: 600 }}>
                                                {" "}
                                                {list?.hip?.name}
                                            </Typography>
                                            <Typography sx={{ fontSize: 14 }}>
                                                {list?.location?.address ? list?.location?.address?.text : null}

                                            </Typography>
                                        </Box>
                                    </Box>
                                    <IconButton >
                                        <ArrowForwardIos sx={{ color: "black" }} />
                                    </IconButton>
                                </Card>
                            </Box>
                        )) :
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 6 }}>
                            <Typography sx={{ fontSize: '14px' }}>No Providers are Available</Typography>
                        </Box>
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default LinkedProviders;