import React from 'react'
import { Box, Card, Checkbox, Divider, IconButton, Paper, Typography } from '@mui/material'
import { ArrowForward, ArrowForwardIos, Close, NotificationsOffOutlined, Recommend, Vaccines } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import CommonButton from '../../../atoms/CommonButton'
import consultationicon from '../../../../assets-new/carekit-icons/stethoscope.svg'
import medicationicon from '../../../../assets-new/carekit-icons/medicines.svg'
import allergyicon from '../../../../assets-new/carekit-icons/allergies.svg'
import vaccinationicon from '../../../../assets-new/carekit-icons/syringe-vaccine.svg'
import surgeriesicon from '../../../../assets-new/carekit-icons/surgical_sterilization.svg'
import familyhistoryicon from '../../../../assets-new/carekit-icons/ui_folder_family.svg'
import vitalicon from '../../../../assets-new/carekit-icons/heart_cardiogram.svg'
import medicaldevicesicon from '../../../../assets-new/carekit-icons/pacemaker_FILL0_wght400_GRAD0_opsz24.svg'
import diagnosticicon from '../../../../assets-new/carekit-icons/diagnostic.svg'
import clinicalicon from '../../../../assets-new/carekit-icons/clincalgoals.svg'


const ListCards = (props) => {
    return (
        <Paper elevation={4} sx={{ p: "8px 15px", mt: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', }}>
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <img src={props.icon} alt="healthicons" width={25} height={25} />
                    <Typography>{props.title}</Typography>
                </Box>
                <ArrowForwardIos />
            </Box>
        </Paper>
    )
}
function ClinicalGoal() {
    const navigate = useNavigate()
    return (
        <Box sx={{ m: 1 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
                <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                    <img src={clinicalicon} alt="clinicalicon" width={30} height={30} />
                    <Typography sx={{ fontWeight: 600, fontSize: 17 }}>Clinical Goal Tracking</Typography>
                </Box>
                <IconButton onClick={() => navigate(-1)}>  <Close sx={{ color: 'red' }} /></IconButton>
            </Box>
            <Paper elevation={3} sx={{ p: "10px 20px", mt: 1, opacity: 0.9 }} onClick={() => navigate('/careChart/bloodpressure')}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                        Blood Pressure Control
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                        Proposed
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                        <Typography sx={{ fontSize: 14 }}>Rx: Dr Rammohan Naidu k</Typography>
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        {/* Achieved */}
                    </Typography>
                </Box>                <Divider />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                    <Typography sx={{ fontSize: 14 }}>Start Date : <Typography component={'span'}>12 jan 2023</Typography></Typography>
                    <Typography sx={{ fontSize: 14 }}>Due Date : <Typography component={'span'}>31 Dec 2024</Typography></Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                    <Typography sx={{ fontSize: 14 }}>Latest Report Value : <Typography component={'span'}>12.2 mg/dL(31 Dec 2024)</Typography></Typography>
                    <Typography sx={{ fontSize: 14 }}>Measure Freq. : <Typography component={'span'}>Monthly</Typography></Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Checkbox defaultChecked />
                        <Typography sx={{ fontSize: '14px' }}>Track on Dashboard</Typography>
                    </Box>
                    <CommonButton text="Accept Goal" width="120px" bgcolor="#03E00B" height="30px" />
                </Box>
            </Paper>
            <Card sx={{ display: 'flex', p: '1px 10px', justifyContent: 'space-between' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><Recommend />Recommendations</Typography>
                <IconButton> <ArrowForward sx={{ color: '#1E02B4' }} /></IconButton>
            </Card>
            <Paper elevation={3} sx={{ p: "10px 20px", mt: 2, opacity: 0.9 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                        Himoglobin
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                        Active
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                        <Typography sx={{ fontSize: 14 }}>Rx: Dr Rammohan Naidu k</Typography>
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        Achieved
                    </Typography>
                </Box>                <Divider />
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                    <Typography sx={{ fontSize: 14 }}>Start Date : <Typography component={'span'}>12 jan 2023</Typography></Typography>
                    <Typography sx={{ fontSize: 14 }}>Due Date : <Typography component={'span'}>31 Dec 2024</Typography></Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 1 }}>
                    <Typography sx={{ fontSize: 14 }}>Latest Report Value : <Typography component={'span'}>12.2 mg/dL(31 Dec 2024)</Typography></Typography>
                    <Typography sx={{ fontSize: 14 }}>Measure Freq. : <Typography component={'span'}>Monthly</Typography></Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mt: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <NotificationsOffOutlined />
                        <Typography sx={{ fontSize: '14px' }}>Do not Remind</Typography>
                    </Box>
                    <CommonButton text="Add Data" width="120px" bgcolor="#2580EC" height="30px" />
                </Box>
            </Paper >
            <Card sx={{ display: 'flex', p: '1px 10px', justifyContent: 'space-between' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><Recommend />Recommendations</Typography>
                <IconButton> <ArrowForward sx={{ color: '#1E02B4' }} /></IconButton>
            </Card>
            <Paper elevation={3} sx={{ p: "10px 20px", mt: 2, opacity: 0.9 }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                        Blood Oxygen (SpO2)
                    </Typography>
                    <Typography sx={{ fontSize: 14 }}>
                        Completed
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography sx={{ fontWeight: 600 }}>
                        <Typography sx={{ fontSize: 14 }}>Rx: Dr Rammohan Naidu k</Typography>
                    </Typography>
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }}>
                        Achieved
                    </Typography>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <Typography sx={{ fontSize: 14 }}>Start Date : <Typography component={'span'}>12 jan 2023</Typography></Typography>
                    <Typography sx={{ fontSize: 14 }}>Due Date : <Typography component={'span'}>31 Dec 2024</Typography></Typography>
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 1 }}>
                    <Typography sx={{ fontSize: 14 }}>Latest Report Value : <Typography component={'span'}>12.2 mg/dL(31 Dec 2024)</Typography></Typography>
                    <Typography sx={{ fontSize: 14 }}>Measure Freq. : <Typography component={'span'}>Monthly</Typography></Typography>
                </Box>
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                    <Typography sx={{ color: '#2580EC' }}>Show Data</Typography>
                </Box>
            </Paper>
            <Card sx={{ display: 'flex', p: '1px 10px', justifyContent: 'space-between' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', gap: 1 }}><Recommend />Recommendations</Typography>
                <IconButton> <ArrowForward sx={{ color: '#1E02B4' }} /></IconButton>
            </Card>
            <Box sx={{ mt: 2 }}>
                <Paper elevation={4} sx={{ p: "8px", borderRadius: '10px' }} >
                    <Typography sx={{ fontWeight: 500, textAlign: 'center' }}>Show Older</Typography>
                </Paper>
            </Box>

            <Box>
                <ListCards title="Consultations Timeline" icon={consultationicon} />
                <ListCards title="Medication use timeline" icon={medicationicon} />
                <ListCards title="Vaccination history" icon={vaccinationicon} />
                <ListCards title="My Lab Results Timeline" icon={diagnosticicon} />
                <ListCards title="Vital Signs" icon={vitalicon} />
                <ListCards title="Allergies timeline" icon={allergyicon} />
                <ListCards title="Surgery & procedure history" icon={surgeriesicon} />
                <ListCards title="Family history" icon={familyhistoryicon} />
                <ListCards title="Medical devices & implants" icon={medicaldevicesicon} />
            </Box>
        </Box >
    )
}

export default ClinicalGoal