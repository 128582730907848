import React, { useState } from 'react'
import { Avatar, Box, Button, Card, CardActions, CardContent, CardMedia, Grid, Paper, Typography } from '@mui/material';
import DoctorIcon from '../../../../assets/home/profile_assets/Userprofilelogo.svg';
import { PersonAddAlt, PersonAddAlt1, TaskAlt, ThumbUpAlt } from '@mui/icons-material';
import URL from '../../../../utility/apiurl';
import { Metadata } from '../../../atoms/Metadata';
import { localstore } from '../../../../localStore/localStore';
import CoverPicIcon from '../../../../assets/home/profile_assets/cover_pic.svg'
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { getDoctorsFollowCount, getSuggestedDoctors } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import doctorfollowicon from '../../../../assets-new/medxpedia-icons/doctorfollowicon.svg'


const handleQual = (user) => {
    let qualdata = user ? user[user.length - 1] : null;
    if (qualdata && qualdata.code && qualdata.code.coding && qualdata.code.coding[0] && qualdata.code.coding[0].code !== 'BS') {
        return (
            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 10 }, pr: 2 }}>
                {qualdata.code.coding[0].code}
            </Typography>
        );
    }
    if (qualdata && qualdata.code && qualdata.code.coding && qualdata.code.coding[0] && qualdata.code.coding[0].code === 'BS') {
        return (
            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 10 }, pr: 2 }}>
                {qualdata.code.text.split(' ')[0]}
            </Typography>
        );
    } else {
        return (
            <Typography sx={{ fontSize: { xs: 10, sm: 12, md: 10 }, pr: 2 }}>N/A</Typography>
        );
    }
};

const handleSpecialisation = (user) => {
    let qualdata = user ? user[user.length - 1] : null;
    if (qualdata && qualdata.code?.coding && qualdata.code.coding[0]?.code !== 'BS') {
        return (
            <>
                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                    {qualdata.code.text && qualdata.code.text.length > 16
                        ? qualdata.code.text.slice(0, 15) + '...'
                        : qualdata.code.text || 'N/A'}
                </Typography >
            </>
        );
    }
    if (qualdata && qualdata.code?.coding && qualdata.code.coding[0]?.code === 'BS') {
        return (
            <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                Physician
            </Typography>
        );
    } else {
        return (
            <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>N/A</Typography>
        );
    }
};

let handleQualificationdata = (qual) => {
    return (
        qual && qual[qual.length - 1]?.organisation?.length > 0 ?
            qual[qual.length - 1]?.organisation.length > 16 ?
                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>{qual[qual.length - 1]?.organisation.slice(0, 15)}..</Typography> :
                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>{qual[qual.length - 1]?.organisation}</Typography>
            :
            <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>N/A</Typography>
    );
};

const handleLocation = (location) => {
    if (location && location[0]?.hasOwnProperty('city')) {
        return (
            location[0]?.city?.length > 0 ?
                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>
                    {location[0]?.city?.length > 15 ? <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>{location[0]?.city?.slice(0, 14)}..</Typography> : <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>{location[0]?.city}</Typography>}
                </Typography>
                :
                <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>N/A</Typography>
        );
    } else {
        return <Typography sx={{ fontSize: { xs: 13, sm: 12, md: 13 } }}>N/A</Typography>;
    }
};

function MoreSuggestDoctors({ user, index, handleDocFavourite }) {
    const navigate = useNavigate()
    const [favourite, setfavourite] = useState(false);
    const [ind, setInd] = useState('');
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const patientId = localstore.getPatientId()
    const [suggesteddata, setSuggesteddata] = useState([])
    // console.log(suggesteddata, "sugggdocccccc")
    const [loadingMore, setLoadingMore] = useState(false);
    const [offset, setOffset] = useState(0)
    const dispatch = useDispatch()
    const [t, i18n] = useTranslation("global");

    const limit = 20;
    const handleFollow = (docid, index) => {
        // console.log(docid, "idddddd")
        // console.log(index, "indexxxxxx")
        setInd(index);
        setfavourite(true);
        const data = { ...Metadata(), author: patientId };
        axios.post(`${URL.followDoctor}${docid}/${patientId}`, data)
            .then(res => {
                console.log('res', res);
                setAlertopen(true);
                // window.alert('Started Following');
                // setTimeout(() => {
                // window.location.reload();
                // }, 1000);
                setAlertstatus('success');
                setTimeout(() => {
                    setAlertopen(false);
                }, 2000);
                setTimeout(() => {
                    handleDocFavourite(index);
                }, 3000);
                dispatch(getDoctorsFollowCount(patientId));
            })
            .catch(err => {
                setAlertopen(true);
                // window.alert(err.message);
                setAlertstatus('error');
                setTimeout(() => {
                    setAlertopen(false);
                }, 2000);
            })
            .finally(() => {
                setTimeout(() => {
                    setfavourite(false);
                    setInd('');
                    dispatch(getSuggestedDoctors(limit, offset + 1, patientId));
                }, 3000);
            });

    };
    return (
        <Box mt={1}>
            <Card
                sx={{
                    width: { xs: "195px", sm: "200px", md: "219px" },
                    height: { sm: "260px", md: "243px" },
                    borderRadius: "10px 10px 0px 0px",
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                }}
            >
                <Box
                    container
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        position: "relative",
                    }}
                >
                    {user?.coverPic?.length > 0 ? (
                        <Box sx={{ width: "100%", height: 52 }}>
                            <img
                                src={user?.coverPic}
                                alt="hello"
                                width={"100%"}
                                height={62}
                                style={{ objectFit: "cover" }}
                            />{" "}
                        </Box>
                    ) : (
                        // <Avatar alt={"doc"} src={item.orgPageImage} sx={{ marginTop: '20px', height: '45px', width: '45px' }} variant='square' />
                        <Box sx={{ width: "100%", height: 54 }}>
                            <img
                                src={CoverPicIcon}
                                alt="hello"
                                width={"100%"}
                                height={65}
                                style={{ objectFit: "cover" }}
                            />{" "}
                        </Box>
                    )}

                    {user?.photos && user?.photos[0]?.url ? (
                        <Box mt={2} sx={{ position: "absolute", top: "15px" }}>
                            <img
                                src={user.photos[0].url}
                                alt="hello"
                                width={50}
                                height={50}
                                style={{ objectFit: "cover", borderRadius: "10px" }}
                            />
                        </Box>
                    ) : (
                        // <Avatar alt={"doc"} src={item.orgPageImage} sx={{ marginTop: '20px', height: '45px', width: '45px' }} variant='square' />
                        <Box mt={2} sx={{ position: "absolute", top: "15px" }}>
                            <img src={DoctorIcon} alt="hello" width={50} height={50} />{" "}
                        </Box>
                        // <Avatar alt={"doc"} src={item.orgPageImage} sx={{ marginTop: '20px', height: '45px', width: '45px' }} variant='square' />

                        // <Avatar alt={"doc"} src={OrgIcon} sx={{ marginTop: '20px', height: '45px', width: '45px' }} variant='square' />
                    )}
                </Box>

                <Box sx={{ textAlign: "center", mt: 5 }}>
                    <Box
                        sx={{ cursor: "pointer" }}
                        onClick={() => navigate(`/doctor/${user.id}`)}
                    >
                        {user?.fullName?.length > 16 ? (
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: { xs: 12, sm: 12, md: 15 },
                                }}
                            >
                                {user?.fullName?.slice(0, 15)}..
                            </Typography>
                        ) : user?.fullName ? (
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: { xs: 12, sm: 12, md: 15 },
                                }}
                            >
                                {user.fullName}
                            </Typography>
                        ) : (
                            <Typography
                                sx={{
                                    fontWeight: 600,
                                    fontSize: { xs: 12, sm: 12, md: 15 },
                                }}
                            >
                                N/A
                            </Typography>
                        )}
                    </Box>
                    <Box sx={{ float: "right" }}>
                        <Typography sx={{ fontSize: { xs: 12, sm: 12, md: 12 } }}>
                            {handleQual(user?.qualification)}
                        </Typography>
                    </Box>

                    <Box sx={{ textAlign: "center", mt: 2 }}>
                        <Typography>
                            {handleSpecialisation(user?.qualification)}
                        </Typography>
                        <Typography>
                            {handleQualificationdata(user?.qualification)}
                        </Typography>
                        <Typography>{handleLocation(user?.address)}</Typography>
                    </Box>

                    <Grid container mt={"22px"}>

                        <Grid xs={6} sm={6} md={6}>
                            <Button
                                variant="contained"
                                sx={{ width: '109px', bgcolor: '#88868D', "&:hover": { bgcolor: '#88868D' }, borderRadius: 0, '.MuiButton-startIcon>*:nth-of-type(1)': { fontSize: '16px' } }}
                                startIcon={
                                    favourite ? (
                                        <TaskAlt />
                                    ) : (
                                        <img src={doctorfollowicon} alt="doctor" width={13} height={13} />
                                    )
                                }
                                onClick={() => {
                                    if (favourite === false) {
                                        handleFollow(user.id, index);
                                    } else {
                                        return;
                                    }
                                }}
                            >
                                {index === ind && favourite === true ? (
                                    <Box>
                                        <Typography sx={{ fontSize: { xs: 12, sm: 12, md: 12 } }}>
                                            Connected
                                        </Typography>
                                    </Box>
                                ) : (
                                    <Box>
                                        <Typography sx={{ fontSize: { xs: 12, sm: 12, md: 12 } }}>
                                            Follow
                                        </Typography>
                                    </Box>
                                )}
                            </Button>
                        </Grid>
                        <Grid xs={5.7} sm={5.7} md={6}>
                            <Button sx={{ color: 'white', width: '110px', borderRadius: 0, }}
                                variant="contained"
                                onClick={() => navigate(`/doctor/${user.id}`)}
                            >
                                <Typography sx={{ fontSize: "12px" }}>
                                    View Profile
                                </Typography>
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Card>
        </Box>
    )
}

export default MoreSuggestDoctors