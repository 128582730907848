import React from 'react'
import { IconButton, InputAdornment, TextField, Typography } from '@mui/material'

export function StartAdornmentTextField(props) {
    return (
        <TextField
            sx={{
                ".MuiInputBase-root": {
                    borderRadius: "15px",
                    // backgroundColor: 'whitesmoke',
                }, ".MuiOutlinedInput-notchedOutline": {
                    // border: "none"
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: '#59D8FA'
                    },
                },
                width: "100%"
            }}
            label={props.label}
            placeholder={props.placeholder}
            size="small"
            fullWidth
            variant="outlined"
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            type={props.type}
            autoFocus
            onBlur={props.onBlur}
            autoComplete={props.autoComplete}
            onKeyDown={props.onKeyDown}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" >
                        <Typography sx={{ fontWeight: 600 }}>+91</Typography>
                    </InputAdornment>
                ),
            }}
        />
    )
}

export function EndAdornmentTextField(props) {
    return (
        <TextField
            sx={{
                ".MuiInputBase-root": {
                    borderRadius: "15px",
                    // backgroundColor: 'whitesmoke',
                }, ".MuiOutlinedInput-notchedOutline": {
                    // border: "none"
                },
                '& .MuiOutlinedInput-root': {
                    '&.Mui-focused fieldset': {
                        borderColor: props.borderColor,
                    },
                },
                width: props.width
            }}
            label={props.label}
            placeholder={props.placeholder}
            size="small"
            fullWidth
            variant="outlined"
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            type={props.type}
            autoFocus
            onBlur={props.onBlur}
            autoComplete={props.autoComplete}
            onKeyDown={props.onKeyDown}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end" >
                        <IconButton variant="outlined" onMouseDown={props.onMouseDown}
                            onClick={props.onClick} sx={{ borderRadius: 2, height: '32px' }}>
                            {props.icon}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}

function AdornmentTextField(props) {
    return (
        <TextField
            sx={{
                ".MuiInputBase-root": {
                    borderRadius: 2,
                    backgroundColor: 'whitesmoke',
                }, ".MuiOutlinedInput-notchedOutline": {
                    border: "none"
                },
                width: props.width
            }}
            placeholder={props.placeholder}
            size="small"
            fullWidth
            variant="outlined"
            onChange={props.onChange}
            value={props.value}
            name={props.name}
            autoFocus
            autoComplete={props.autoComplete}

            InputProps={{
                startAdornment: (
                    <InputAdornment position="start" >
                        <IconButton variant="outlined" sx={{ borderRadius: 2, height: '32px' }}>
                            <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>+91</Typography>
                        </IconButton>
                    </InputAdornment>
                ),
            }}
        />
    )
}

export default AdornmentTextField