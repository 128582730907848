import React, { useEffect } from 'react'
import { Box, Button, Card, CircularProgress, Grid, IconButton, Paper, TextField, Typography } from '@mui/material'
import axios from 'axios';
import { useState } from 'react'
import URL, { AadharPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';
import { useTranslation } from "react-i18next";
import ResendOtp, { ErrorMessage, ErrorStatusMsg } from '../../../atoms/ResendOtp';
import JSEncrypt from 'jsencrypt';

function AadharStep2(props) {
    const result = useSelector((state) => state.abha.abhaAadhar)
    const [otp, setOtp] = useState('');
    const [time, setTime] = useState(59);
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    // const [phrAddress, setPhraddress] = useState('')
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const publicKey = AadharPublicKey
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        if (!/^\d+$/.test(e.target.value)) {
            setAlertmessage('Only numbers are allowed.');
        } else {
            setAlertmessage('');
        }
    }

    // useEffect(() => {
    //     axios.get(`${URL.cliniapi}Patient/${primaryId}`,
    //         {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         })
    //         .then(res => {
    //             console.log("patient adhar details", res)
    //             let phd = res.data.identifier?.filter(e => {
    //                 if (e?.type?.coding[0]?.code === 'ABHA') {
    //                     return e.value;
    //                 }
    //             });
    //             setPhraddress(phd?.length > 0 ? phd[0].value : '');
    //         })
    // }, [])

    const handleLink = async (jwtResponsetoken, phrAddress) => {
        let d = {};
        await axios.post(
            `${URL.abha}link/phrAddress/${primaryId}`,
            d,
            {
                headers: {
                    'X-Token': `Bearer ${jwtResponsetoken}`,
                    'gatewayToken': 'medxperts',
                    'Authorization': `Bearer ${token}`,
                },

            }).then(res => {
                // console.log(response.data, 'linking successful');
                dispatch(
                    getPatientdatafromibm(
                        'Patient',
                        primaryId,
                        token,
                    ),
                );
                dispatch(getAbhaProfile(primaryId, token));
                setAlertmessage('Data registered Successfully!');
                setAlertstatus('success');
                dispatch({
                    type: 'VERIFY_PHRADDRESS',
                    payload: phrAddress
                })
                props.setShow(7)
            }).catch(err => {
                // console.log(erro.message, 'in linking');
                setAlertopen(true);
                setAlertmessage(err.message);
                setAlertstatus('error');

            })
    };

    const handleVerifyKyc = async () => {
        // props.setShow(3)
        setLoading(true)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(AadharPublicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let d = {
            'otp': encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            'txnId': result.txnId,
            'mobileOrAadhaar': 'aadhaar',
        };
        await axios.post(`${URL.abha}v2/verifyAadhaarOtp/${primaryId}`, d, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            console.log(res.data, 'kkkkkkkkkkkkk OTP Verified')
            const healthIdNumber = res.data.healthIdNumber
            const jwtResponsetoken = res.data.jwtResponse
            const phrAddress = res.data.HealthId
            // setHidNumber(res.data.healthIdNumber)
            if (res.status === 200 || res.status === 201) {
                if (healthIdNumber) {
                    let ibmp = ibm?.identifier ? { ...ibm } : { ...ibm, identifier: [] };
                    let da = {
                        ...ibmp, identifier: [...ibmp.identifier, {
                            'system': 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                            'value': healthIdNumber,
                            'type': {
                                coding: [
                                    {
                                        code: 'HIN',
                                    },
                                ],
                            },
                        }],
                    };

                    // da.identifier.push({
                    //     system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                    //     value: phrAddress,
                    //     type: {
                    //         coding: [
                    //             {
                    //                 code: 'ABHA', // or any other code you want to use for Healthid
                    //             },
                    //         ],
                    //     },
                    // });


                    axios
                        .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                            },
                        })
                        .then(res => {
                            handleLink(jwtResponsetoken?.token, phrAddress)
                        })
                        .catch(err => {
                            console.log(err.message, 'error in update');
                        });
                }
                else {
                    props.setShow(11)
                }
            }

        }).catch(err => {
            console.log(err);
            setAlertopen(true);
            setAlertmessage('OTP entered is incorrect! or not enter ');
            setAlertstatus('error');
        })
            .finally(() => { setLoading(false); });
        // setOtp('')
    }


    const handleVerifyOtp = async () => {
        setLoading(true)
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(publicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let d = {
            'otp': encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            'txnId': result.txnId,
            mobileOrAadhaar: 'aadhaar',
        };
        await axios.post(`${URL.abha}v2/verifyAadhaarOtp/${primaryId}`, d, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            setAlertmessage('OTP Verified Successfully!');
            setAlertstatus('success');
            props.setShow(11)

        })
            .catch(err => {
                console.log(err);
                setAlertopen(true);
                setAlertmessage('OTP entered is incorrect! or not enter ');
                setAlertstatus('error');
            })
            .finally(() => { setLoading(false); });
    }
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);

    const handleResendOtp = async () => {
        const d = {
            txnId: result.txnId
        }
        await axios.post(`${URL.abha}v2/resendAadhaarOtp/${primaryId}`, d, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            },
        }).then(res => {
            // console.log(res.data);
            setAlertmessage('OTP sent Successfully!');
            setAlertstatus('success');
            setTime(59);
        }).catch(err => {
            setAlertmessage('Oops something went wrong!');
            setAlertstatus('error');

        }).finally(() => { setLoading(false); })
        setOtp('')
    }

    return (
        <div>
            <Box sx={{ mt: { xs: "5px", sm: 1, md: 1 } }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: 1, sm: 2, md: 2 },
                    }}
                >
                    <IconButton onClick={() => {
                        props.setShow(1)
                        props.setAlertmessage('')
                    }}>
                        <ArrowBackIos sx={{ color: '#007DCD', cursor: 'pointer' }} />
                    </IconButton>
                    <Typography sx={{ fontSize: { xs: 17, sm: "17px", md: "17px" }, color: '#007DCD', fontWeight: 600 }}>
                        {" "}
                        {t("createaadharnum")}{" "}
                    </Typography>
                </Box>
            </Box>

            <Paper elevation={3} sx={{ mt: { md: 1, sm: 1, xs: 1 }, p: 3, display: "flex", justifyContent: "center" }}>
                <Box>
                    <Typography
                        sx={{
                            fontSize: { xs: 14, md: 16 },
                            fontWeight: 500,
                            textAlign: "center",
                        }}
                    >
                        {t("verifyotp")}
                    </Typography>

                    <Box>
                        <TextInputField
                            placeholder="Enter OTP"
                            mt={2}
                            name="otp"
                            border="none"
                            value={otp}
                            onChange={handleOnChangeOtp}
                        />
                        {alertmessage && (
                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                        )}

                        <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} />
                        <Typography
                            sx={{
                                fontStyle: "inherit",
                                textAlign: "center",
                                fontSize: { xs: 14, md: 14 },
                                fontWeight: 400,
                                mt: 2,
                            }}
                        >
                            {t("enterotpsndtoaadhar")} {result.mobileNumber}
                        </Typography>

                        <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                                onClick={() => props.abhacreate === true ? handleVerifyOtp() : handleVerifyKyc()}
                                disabled={loading || otp?.length < 6}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                {t("verify")}
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Paper>
        </div>
    );
}

export default AadharStep2