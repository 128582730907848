import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box, CircularProgress, Divider, Typography } from '@mui/material';
import TextInputField from '../../../atoms/TextFields/TextInputField';
import URL from '../../../../utility/apiurl';
import { useState } from 'react';
import { localstore } from '../../../../localStore/localStore';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AuthRecords({ open, setOpen, mobile, referenceNumber }) {

    const [otp, setOtp] = useState('')
    const [alertopen, setAlertopen] = React.useState(false);
    const [seconds, setSeconds] = useState(59);
    const [alertstatus, setAlertstatus] = useState('');
    const [alertmessage, setAlertmessage] = useState('');
    const [loading, setLoading] = React.useState(false)
    const navigate = useNavigate()
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }


    let MobileNumber = '';
    if (typeof mobile === 'string') {
        MobileNumber = mobile.slice(-4);
    }

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [seconds]);
    const handleConfirmOtp = async () => {
        setLoading(true);
        // onOpen();
        let data = {
            token: otp,
        };
        // console.log(mobiletoken);
        await axios
            .post(`${URL.consents}links/link/confirm/${primaryId}/${referenceNumber}`, data, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
            .then(res => {
                console.log(res.data);
                setAlertopen(true);
                setAlertmessage('OTP succesfully Valid!');
                setAlertstatus('success');
                setLoading(false);
                navigate('/records')
            })
            .catch(err => {
                // console.log(err, 'confirm err');
                setAlertopen(true);
                setAlertmessage(err.message);
                setAlertstatus('error');
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const handleClose = () => {
        setOtp('')
        setOpen(false);

    };
    return (
        <div>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth='sm'
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle>Authentication (Verify its you)</DialogTitle>
                <Divider />
                <DialogContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%', // Make the Dialog content take up the full height
                }}>
                    <DialogContentText id="alert-dialog-slide-description">

                        <Typography>
                            Verification Method
                        </Typography>
                        <Typography sx={{ fontWeight: 600, mt: "5px" }}>
                            Mobile OTP
                        </Typography>
                        <Typography sx={{ fontSize: 14, mt: "5px" }}>
                            Hint:OTP sent on your mobile ending with XX {MobileNumber}
                        </Typography>
                        <Box sx={{ mt: 1 }}>
                            <TextInputField placeholder="Enter your OTP here" value={otp} onChange={handleOnChangeOtp} />
                        </Box>

                        <Typography sx={{ mt: 1, fontSize: '14px' }}>Token expires in :{seconds} </Typography>

                    </DialogContentText>
                </DialogContent>
                <Divider />
                <DialogActions>
                    <Box sx={{ display: 'flex', gap: 2, justifyContent: 'center', mt: 3 }}>
                        <Button variant='outlined' sx={{ border: '1px solid red', width: '150px' }} onClick={handleClose}>Cancel</Button>
                        <Button variant='contained' sx={{ width: '150px' }} startIcon={loading ? <CircularProgress size={20} sx={{ color: 'white' }} /> : null} onClick={handleConfirmOtp}>Confirm</Button>
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
}


export default AuthRecords;
