import { Box, InputAdornment, TextField, Typography } from '@mui/material'
import React from 'react'

function ColorTextField(props) {
    const { onChange, value, width, label, placeholder, onBlur, InputProps, endAdornment, name, startAdornment, onKeyDown, type, bgcolor } = props
    return (
        <Box >
            <TextField
                sx={{
                    '& .MuiOutlinedInput-root': {
                        '&.Mui-focused fieldset': {
                            borderColor: '#59D8FA',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#3D6596',
                    },
                    ".MuiInputBase-root": {
                        borderRadius: "15px",
                        width: { width },
                        bgcolor: props.bgcolor
                    }
                }}
                label={label}
                autoFocus
                size='small'
                autoComplete='off'
                type={type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={onChange}
                // onKeyDown={onKeyDown}
                onBlur={onBlur}
                fullWidth
            // InputProps={{
            //     endAdornment: (
            //         <InputAdornment position="end" >
            //             <Typography sx={{ fontWeight: 600 }}>{endAdornment}</Typography>
            //         </InputAdornment>
            //     ),
            //     startAdornment: (
            //         <InputAdornment position="start" >
            //             <Typography sx={{ fontWeight: 600, mr: "5px" }}>{startAdornment}</Typography>
            //         </InputAdornment>
            //     ),

            // }}

            />

        </Box>
    )
}

export default ColorTextField