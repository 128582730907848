import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Public, Visibility, VisibilityOff } from '@mui/icons-material';
import CommonButton from '../../atoms/CommonButton';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { CircularProgress, TextField } from '@mui/material';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { EndAdornmentTextField } from '../../atoms/TextFields/AdornmentTextField';
import { getToken } from '../../../redux/Actions/Actions';
import { useNavigate } from 'react-router-dom';
import JSEncrypt from 'jsencrypt';
import URL, { PasswordpublicKey } from '../../../utility/apiurl';
import axios from 'axios';
import { handleErrorResponse } from '../../atoms/StatusError';


function ConfirmPassword({ setShow }) {
    const [alertstatus, setAlertstatus] = useState('');
    const [alertmessage, setAlertmessage] = useState('');
    const [state, setState] = useState(() => { return ' '; });
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [t, i18n] = useTranslation("global");
    const credentials = useSelector(state => state.signup.credentials)
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((prevShowPassword) => !prevShowPassword);
    const password = credentials.password
    const username = credentials.username
    const signUpValidationSchema = Yup.object().shape({
        confirmpassword: Yup
            .string()
            .oneOf([password], 'Passwords do not match')
            .required('Confirm password is required'),
    })


    const handleDone = async (value) => {
        setLoading(true);
        const publicKey = PasswordpublicKey;

        const encrypt = new JSEncrypt();
        encrypt.setPublicKey(publicKey);
        const encryptusername = encrypt.encrypt(`${username}@mdx`);
        const encryptpassword = encrypt.encrypt(password);
        const data = {
            userName: encryptusername.replace(/\n/g, '').replace(/\r/g, ''),
            passWord: encryptpassword.replace(/\n/g, '').replace(/\r/g, '')
        }
        await axios.post(`${URL.login}`, data)
            .then((res) => {
                // console.log(JSON.stringify(res.data), "keyyyyy");
                const token = res?.data?.access_token;
                localStorage.setItem('kctoken', token)

                // dispatch(getToken(token));
                setAlertmessage('Successfully LogedIn!');
                setAlertstatus('success');
                navigate('/completeprofile')
            }).catch(err => {
                const { alertmessage, alertstatus } = handleErrorResponse(err)
                setAlertmessage(alertmessage)
                setAlertstatus(alertstatus)
            }).finally(() => {
                setLoading(false)
            })
    }


    return (
        <Box>

            <Box sx={{ height: { sm: '0px', md: "3px" } }}></Box>


            <Box sx={{ mt: "30px" }}
            >
                <Typography sx={{ fontSize: { xs: 17, md: 17 }, fontWeight: 600 }} >
                    Confirm Password
                </Typography>

            </Box>

            <Formik
                validationSchema={signUpValidationSchema}
                initialValues={{ confirmpassword: "" }}
                onSubmit={(values) => {
                    handleDone(values);
                }}
            >
                {({
                    handleSubmit,
                    isValid,
                    values,
                    errors,
                    handleChange,
                    handleBlur,
                    touched,
                    isSubmitting,
                }) => (
                    // <form onSubmit={handleSubmit}>
                    <>
                        <Box sx={{ mt: 3 }}>

                            <EndAdornmentTextField
                                borderColor="#59D8FA"
                                label="Confirm Password"
                                placeholder="Enter Confirm Password"
                                type={showPassword ? 'text' : 'password'}
                                icon={showPassword ? <Visibility /> : <VisibilityOff />}
                                value={values.confirmpassword}
                                onChange={handleChange('confirmpassword')}
                                handleBlur={handleBlur('confirmpassword')}
                                onClick={handleClickShowPassword}
                            // onMouseDown={handleMouseDownPassword}
                            />
                            {errors.confirmpassword && touched.confirmpassword && (
                                <Typography sx={{ color: 'red', fontSize: '14px' }}>
                                    {errors.confirmpassword}</Typography>
                            )}


                            {alertmessage && (
                                <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                            )}
                            <Box sx={{ mt: "30px", textAlign: 'center' }}>
                                <CommonButton width="150px" bgcolor="blueviolet" text="Done" onClick={handleSubmit} startIcon={loading ? <CircularProgress sx={{ color: 'white' }} size={20} /> : null} />

                            </Box>
                        </Box>
                    </>)}
            </Formik >



        </Box >
    );
}

export default ConfirmPassword