import React from 'react'
import { Button } from '@mui/material'
import { styled } from '@mui/material/styles'

const NavButtonStyled = styled(Button)(({ theme }) => ({
    "&.MuiButton-root": {
        borderColor: "#59D8FA",
        borderRadius: "10px",
        borderWidth: "3px",
        color: "#3D6596",
        // fontSize: "17px",
        fontSize: "17px",
    },
    [theme.breakpoints.down('md')]: {
        "&.MuiButton-root": {
            fontSize: "13px"
        }
    },

}))

const NavButton = ({ text, color, size, variant, onClick, style }) => {
    return (
        <NavButtonStyled
            variant={variant}
            color={color}
            disableElevation={true}
            disableRipple={true}
            size={size}
            onClick={onClick}
            style={style}
        >
            {text}
        </NavButtonStyled>
    );
};

export default NavButton