import React from 'react'
import { Security } from '@mui/icons-material'
import { Box, Button, Card, CircularProgress, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import URL from '../../../../utility/apiurl'
import { localstore } from '../../../../localStore/localStore'
import axios from 'axios'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import PinInputField from '../../../atoms/TextFields/PinInputField'

function ReEnterConsentPin(props) {
    console.log(props, "props")
    const { phraddress, setShow } = props
    const primaryId = localstore.getPrimaryId()
    const [alertmessage, setAlertMessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [pin, setPin] = useState(['', '', '', '']);
    const otpFieldsRef = React.useRef([]);
    const token = localstore.getToken()
    const csrftoken = localstore.getCsrfToken()
    const handleInput = (index, value) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...pin];
            newPin[index] = value;
            setPin(newPin);
            if (value.length === 1 && index < pin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertMessage('');
        } else if (value !== '') {
            setAlertMessage('Please enter 4 digits number only');
        }
    };

    const handleBackspace = (index) => {
        if (pin[index] !== '') {
            const newPin = [...pin];
            newPin[index] = '';
            setPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertMessage('')

    };
    const handleReenterPin = async () => {
        // props.setShow(3)

        setLoading(true)
        let data = {
            pin: pin.join(''),
            scope: "profile.changepin"
        }
        await axios.post(`${URL.consents}verifyPin/${primaryId}/${phraddress}`, data, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })
            .then(res => {
                if (res.status === 200 || res.status === 201) {
                    setAlertMessage('PIN is valid!');
                    setAlertstatus('success');
                }
                dispatch({
                    type: "CONSENTPIN_TOKEN",
                    payload: res.data.temporaryToken
                })
                setShow(3)
            }).
            catch(error => {
                // console.error('Error validating OTP:', error);

                setAlertMessage(' PIN Does Not Match! ');
                setAlertstatus('error');

                // setAlertMessage('Oops, something went wrong!');
                // setAlertstatus('error');

            }).finally(() => {
                setLoading(false)
            })
    }

    const handleCancel = () => {
        setPin(['', '', '', ''])
        props.handleClose()
        props.setAlertMessage('')
        props.setShow(1)
    }
    return (
        <div> <>
            <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', gap: 3, alignItems: 'center' }}>
                    <Security />
                    <Typography sx={{ fontSize: '17px', fontWeight: 600 }}>Set Security PIN</Typography>
                </Box>
                {/* <Button sx={{ height: "25px", color: '#B48FE4', borderRadius: "15px" }} variant='outlined' onClick={handleCancel}>Cancel</Button> */}
            </DialogTitle>
            <Divider />

            <DialogContent >
                <Card sx={{ textAlign: 'center', mt: 1, p: "40px 0px", }}>
                    <Typography sx={{ fontWeight: 500 }}>Re-Enter Consent PIN to Confirm</Typography>
                    <Box mt={3}>
                        <div style={{ display: 'flex', gap: '20px', textAlign: 'center', justifyContent: 'center' }}>
                            {pin.map((value, index) => (
                                <PinInputField type="text" maxLength="1" value={value} key={index}
                                    onChange={(e) => handleInput(index, e.target.value)}
                                    onKeyDown={(e) => {
                                        if (e.key === 'Backspace') {
                                            handleBackspace(index);
                                        }
                                    }}
                                    ref={(ref) => {
                                        otpFieldsRef.current[index] = ref;
                                    }} />
                            ))}
                        </div>
                    </Box>
                    {alertmessage && (
                        <Typography sx={{ color: alertstatus === 'success' ? 'green' : 'red', mt: 1, fontSize: '14px' }}>{alertmessage}</Typography>
                    )}

                </Card>
                <Typography sx={{ fontSize: 14, ml: 1, mt: 1 }} component={"p"}>Remember this 4 Digit-pin to secure you Health records and approve any request</Typography>
                <Box mt={2} sx={{ textAlign: 'center' }}>
                    <Button sx={{ bgcolor: '#9560D9', width: '120px', borderRadius: "10px" }} onClick={handleReenterPin} variant='contained'
                        disabled={pin.length < 4}
                        startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null}
                    >SetPIN</Button>
                </Box>

            </DialogContent>
        </></div>
    )
}

export default ReEnterConsentPin