import React from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Slide } from '@mui/material';



const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

function CommonDialog(props) {
    const { open, onClose, title, children } = props;

    return (
        <React.Fragment>

            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                fullWidth
                maxWidth='sm'
                onClose={onClose}
                aria-describedby="alert-dialog-slide-description"
            >
                {children}
            </Dialog>
        </React.Fragment>
    );
}

export default CommonDialog;