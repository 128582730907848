import axios from "axios";
import URL from "../../utility/apiurl";

export const getLinkedProviders = (token, primardyid) => {
    // console.log(hospitalid, mobiletoken);
    if (token?.length > 0) {
        return (dispatch) => {
            // console.log(`${URL.consents}patients/links/list/${primardyid}`);
            axios.get(`${URL.consents}patients/links/list/${primardyid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {
                    console.log(res.data, 'inactions');
                    dispatch({
                        type: 'LINKEDPROVIDERS',
                        payload: res.data,
                    });
                }).catch(err => {
                    console.log(err, 'linkedproviders');
                    dispatch({
                        type: 'LINKEDPROVIDERS',
                        payload: [],
                    });
                });
        };
    } else {
        return (dispatch) => {
            dispatch({
                type: 'LINKEDPROVIDERS',
                payload: [],
            });
        };
    }

};
export const getmedicalrecords = (token, primardyid) => {
    // console.log(hospitalid, mobiletoken);
    if (token?.length > 0 && primardyid) {
        return (dispatch) => {

            axios.get(`${URL.cliniapi}Composition?patient=${primardyid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {

                    dispatch({
                        type: 'MEDICALRECORDS',
                        payload: res.data.entry,
                    });
                }).catch(err => {
                    dispatch({
                        type: 'MEDICALRECORDS',
                        payload: [],
                    });
                });
        };
    } else {
        return (dispatch) => {
            dispatch({
                type: 'MEDICALRECORDS',
                payload: [],
            });
        };
    }
};

export const getProviderById = (hospitalid, token, primaryid) => {
    // console.log(hospitalid, '--------------', token, primaryid, 'getpriveder');
    if (hospitalid && token) {
        return (dispatch) => {
            // console.log('---url5', `${URL.consents}providers/${hospitalid}/${primaryid}`);
            axios.get(`${URL.consents}providers/${primaryid}/${hospitalid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {
                    // console.log(res.data, 'inactions');
                    dispatch({
                        type: 'PROVIDERDATA',
                        payload: res.data,
                    });
                }).catch(err => {
                    // console.log(err, 'providerdata');
                    dispatch({
                        type: 'PROVIDERDATA',
                        payload: [],
                    });
                });
        };
    } else {
        return (dispatch) => {
            dispatch({
                type: 'PROVIDERDATA',
                payload: [],
            });
        };
    }

};

export const Recordlist = (token, primardyid, organizationId) => {
    // console.log(primardyid, hipid, 'Recordlistids');
    if (token?.length > 0) {
        return (dispatch) => {
            // axios.get(`${URL.cliniapi}Composition?patient=${primardyid}&attester=${organizationId}`, {
            axios.get(`${URL.cliniapi}Composition?patient=${primardyid}&attester=${organizationId}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {
                    // console.log(res, 'RESPONSE-RECORDLIST');
                    dispatch({
                        type: 'RECORDLIST',
                        payload: res.data,
                    });
                }).catch(err => {
                    // console.log(err, 'RESPONSE-Error');
                    dispatch({
                        type: 'RECORDLIST',
                        payload: [],
                    });
                });
        };
    } else {
        return (dispatch) => {
            dispatch({
                type: 'RECORDLIST',
                payload: [],
            });
        };
    }

};

export const FHIRBundle = (token, primardyid) => {
    // console.log(token, primardyid, hipid, 'LinkedcareData');
    if (token?.length > 0) {
        return (dispatch) => {
            axios.get(`${URL.cliniapi}Bundle/${primardyid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {
                    //console.log(res, 'RESPONSE-FHIRBUNDLE');
                    dispatch({
                        type: 'FHIRBUNDLE',
                        payload: res.data.entry,
                    });
                }).catch(err => {
                    // console.log(err, 'FHIRBUNDLEERROR');
                    dispatch({
                        type: 'FHIRBUNDLE',
                        payload: [],
                    });
                });
        };
    } else {
        return (dispatch) => {
            dispatch({
                type: 'FHIRBUNDLE',
                payload: [],
            });
        };
    }

};

export const SingleRecord = (token, id) => {
    // console.log(id, 'SingleRecordid');
    if (token?.length > 0) {
        return (dispatch) => {
            axios.get(`${URL.cliniapi}Composition/${id}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {
                    // console.log(res, 'RESPONSE-SINGLERECORD');
                    dispatch({
                        type: 'SINGLERECORD',
                        payload: res.data,
                    });
                }).catch(err => {
                    // console.log(err, 'SINGLERECORDERROR');
                    dispatch({
                        type: 'SINGLERECORD',
                        payload: [],
                    });
                });
        };
    } else {
        return (dispatch) => {
            dispatch({
                type: 'SINGLERECORD',
                payload: [],
            });
        };
    }

};

export const getFavourites = (pid, token) => {
    // console.log(hospitalid, mobiletoken);
    if (pid && token?.length > 0) {
        return (dispatch) => {
            axios.get(`${URL.favourites}${pid}`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            })
                .then(res => {
                    // console.log(res.data, 'inactions');
                    dispatch({
                        type: 'FAVOURITESDATA',
                        payload: res.data,
                    });
                }).catch(err => {
                    //console.log(err.message, 'favouritedata');
                    dispatch({
                        type: 'FAVOURITESDATA',
                        payload: [],
                    });
                });
        };
    } else {
        return (dispatch) => {
            dispatch({
                type: 'FAVOURITESDATA',
                payload: [],
            });
        };
    }

};
export const OrganizationIdentifier = (hipid, token, primardyid) => {

    return async (dispatch) => {
        try {
            if (token && hipid) {
                const orgResponse = await axios.get(`${URL.cliniapi}Organization?identifier=${hipid}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                const organizationId = orgResponse.data.entry[0]?.resource.id;
                const compositionResponse = await axios.get(`${URL.cliniapi}Composition?patient=${primardyid}&attester=${organizationId}`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });

                dispatch({
                    type: 'RECORDLIST_AND_ORGANIZATION',
                    payload: {
                        recordList: compositionResponse.data,

                    },
                });
            } else {
                dispatch({
                    type: 'RECORDLIST_AND_ORGANIZATION',
                    payload: {
                        recordList: [],

                    },
                });
            }
        } catch (error) {
            console.error('Error in OrganizationIdentifier:', error);
            dispatch({
                type: 'RECORDLIST_AND_ORGANIZATION',
                payload: {
                    recordList: [],

                },
            });
        }
    };
};



