import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { useSelector } from 'react-redux';
import { localstore } from '../../../../../localStore/localStore';
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import medxpertsIcon from '../../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import ArticleView from './PostTypes/ArticleView';
import Celebrate from './PostTypes/Celebrate';
import Video from './PostTypes/Video';
import Media from './PostTypes/Media';
import Event from './PostTypes/Event';
import PollPost from './PostTypes/PollPost';
import Postactions from './Postactions';
import PdfView from './PostTypes/PdfFile';
import Text from './PostTypes/Text';


const HomePosts = ({ feedData }) => {
    // console.log(feedData, "feeddddddddd")
    const [expanded, setExpanded] = useState(false);
    const token = localstore.getToken()
    const patientId = localstore.getPatientId()
    const [item, setItem] = useState(feedData);
    let post = useSelector((state) => state.medx.postdata);
    // console.log(post, "post")
    const [readmore, setReadmore] = useState(false);
    const [t, i18n] = useTranslation("global");
    const navigate = useNavigate()
    // const shareUrl = `${process.env.REACT_APP_MEDXPERT_SHAREPOST}/post/${postdata.id}`;
    // console.log(item, "itemmmm")
    useEffect(() => {
        if (item.id === post.id) {
            setItem(post);
        }
    }, [post, item]);


    const handleNavigate = (item) => {
        if (item.pageId == 0) {
            navigate(`/doctor/${item.userId}`);
        } else {
            navigate(`/org/${item.pageId}`);
        }
    }

    function timeSince(date) {
        var ms = new Date().getTime() - new Date(date).getTime();
        var seconds = Math.floor(ms / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);
        var months = Math.floor(days / 30);
        var years = Math.floor(months / 12);
        if (seconds < 1) {
            return 'Just now';
        }
        if (seconds < 60) {
            return seconds + 's';
        }
        if (minutes < 60) {
            return minutes + 'm';
        }
        if (hours < 24) {
            return hours + 'h';
        }
        if (days < 30) {
            return days + 'd';
        }
        if (months < 12) {
            return months + ' month';
        } else {
            return years + 'y';
        }
    }
    return (
        <Box>
            <Card
                sx={{
                    backgroundColor: "white",
                    p: "15px 0px",
                    border: "1px solid #e5e5dc",
                    borderRadius: "10px",
                    pb: "2px",
                }}
            >
                <Box sx={{ display: "flex", gap: 2 }}>
                    {item?.pageData?.profilePic?.length > 0 ||
                        item?.userData?.profilePic?.length > 0 ? (
                        <Avatar
                            src={
                                item.pageData?.profilePic
                                    ? item.pageData.profilePic
                                    : item.userData?.profilePic
                            }
                            sx={{
                                borderRadius: "7px!important",
                                border: "1px solid lightgrey",
                                marginLeft: "10px !important",
                                width: { sm: 55, md: 60 },
                                height: { sm: 55, md: 60 },
                                p: "5px",
                            }}
                        />
                    ) : (
                        <Avatar sx={{
                            borderRadius: "7px!important",
                            // border: "1px solid lightgrey",
                            marginLeft: "10px !important",
                            width: { sm: 55, md: 65 },
                            height: { sm: 55, md: 65 },
                        }} src={medxpertsIcon} />
                    )}

                    <div>
                        {item?.pageData?.fullName?.length > 0 ||
                            item?.userData?.fullName?.length > 0 ? (
                            <Typography onClick={() => handleNavigate(item)}
                                sx={{
                                    fontSize: { xs: 12, sm: 15, md: 14 },
                                    fontWeight: 600, cursor: 'pointer'
                                }}
                            >
                                {item.pageData?.fullName || item.userData?.fullName}{" "}
                            </Typography>
                        ) : (
                            <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
                        )}

                        {item?.pageData?.caption?.length > 0 ||
                            item?.userData?.caption?.length > 0 ? (
                            <Typography
                                sx={{
                                    fontStyle: "italic",
                                    fontSize: { xs: 11, sm: 12, md: 13 },
                                }}
                            >
                                {item.pageData?.caption || item.userData?.caption}
                            </Typography>
                        ) : null}

                        <Typography color="black">
                            <Typography
                                sx={{ fontSize: { xs: 11, sm: 12, md: 13 } }}
                            >{`${timeSince(
                                moment.utc(item.createDate).local()
                            )}`}</Typography>
                            &nbsp;
                        </Typography>
                    </div>
                </Box>

                <CardContent>
                    <Box sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>

                        {
                            item.postType === 'TEXT' ? (
                                <Text docFeed={item} />
                            ) : item.postType === 'ARTICLE' ? (
                                <ArticleView docFeed={item} />
                            ) : item.postType === 'CELEBRATE' ? (
                                <Celebrate docFeed={item} />
                            ) : item.postType === 'FILE' ? (
                                <PdfView docFeed={item} stateview={true} />
                            ) : item.postType === 'VIDEO' ? (
                                <Video docFeed={item} />
                            ) : item.postType === 'IMAGE' ? (
                                <Media docFeed={item} />
                            ) : item.postType === 'EVENT' ? (
                                <Event docFeed={item} />
                                // ) : item.postType === 'POLL' ? (
                                //     <PollPost docFeed={item} />
                            ) : null
                        }
                    </Box>

                    {/* <ExpandMoreIcon /> */}
                </CardContent>



                <Postactions item={item} />

            </Card>

        </Box >
    );
}

export default HomePosts