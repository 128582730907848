
import React, { useEffect } from 'react'
import TextInputField from '../../../atoms/TextFields/TextInputField'
import axios from 'axios';
import { useState } from 'react'
import { Box, Button, Card, CircularProgress, Grid, IconButton, TextField, Typography } from '@mui/material'
import URL, { OtpPublicKey } from '../../../../utility/apiurl';
import { ArrowBackIos } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { localstore } from '../../../../localStore/localStore';
import { getAbhaProfile, getPatientdatafromibm } from '../../../../redux/Actions/Actions';

import { useTranslation } from "react-i18next";
import ResendOtp, { ErrorStatusMsg } from '../../../atoms/ResendOtp';
import { handleErrorResponse } from '../../../atoms/StatusError';
import JSEncrypt from 'jsencrypt';

function AbhaLinking(props) {
    const ibm = useSelector((state) => state.medx.ibmpdata)
    const abhanumberdetails = useSelector((state) => state.abha.sessionIdandMobilenumber)
    const transactionId = abhanumberdetails.transactionId
    const healthIdNumber = abhanumberdetails.number
    const authMethod = props.authMethod
    const HidNumber = props.abhaNum
    const [otp, setOtp] = useState('')
    const [time, setTime] = useState(59);
    const [alert, setAlert] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [loading, setLoading] = useState(false);
    const token = localstore.getToken()
    const primaryId = localstore.getPrimaryId()
    const csrftoken = localstore.getCsrfToken()
    const [t, i18n] = useTranslation("global");
    const dispatch = useDispatch()
    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    }, [time]);


    const handleOnChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 6));
        setAlertmessage('')
    }

    const handleLink = async (transactionId) => {
        // let d = {};
        let d = {
            transactionId: transactionId,
        };
        try {
            const response = await axios.post(
                // `${URL.abha}link/phrAddress/${primaryId}`,
                `${URL.abha}link/hid/phrAddress/${primaryId}`,
                d,
                {
                    headers: {
                        // 'X-Token': `Bearer ${jwtResponsetoken.token}`,
                        gatewayToken: 'medxperts',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );
            // console.log(response.data, 'linking successful');
            // navigation.navigate('AadStep3', { transactionId: res.data.txnId });
            // if (res.data.healthIdNumber !== null || res.data.healthIdNumber !== '' || res.data.healthIdNumber !== undefined) {
            let ibmp = ibm?.identifier
                ? { ...ibm }
                : { ...ibm, identifier: [] };
            let da = {
                ...ibmp,
                identifier: [
                    ...ibmp.identifier,
                    {
                        system: 'https://nrces.in/ndhm/fhir/r4/CodeSystem/ndhm-identifier-type-code',
                        value: healthIdNumber,
                        type: {
                            coding: [
                                {
                                    // code: 'JHN',
                                    code: 'HIN',
                                },
                            ],
                        },
                    },
                ],
            };
            await axios
                .put(`${URL.cliniapi}Patient/${primaryId}`, da, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then(res => {
                    console.log('--------put Api', res);
                    dispatch(
                        getPatientdatafromibm(
                            'Patient',
                            primaryId,
                            token,
                        ),
                    );
                    dispatch(getAbhaProfile(primaryId, token));
                    setAlertmessage(t("dataregsuccessfully"));
                    setAlertstatus('success');
                    props.setShow(3)
                })
                .catch(err => {
                    console.log(err.message, 'error in update');
                });

        } catch (error) {
            console.log(error.message, 'in linking');
            setAlertmessage(error.message);
            setAlertstatus('error');
        }
    };


    const handleVerify = async () => {
        const encrypt = new JSEncrypt()
        encrypt.setPublicKey(OtpPublicKey)
        const encryptOtp = encrypt.encrypt(otp)
        let d = {
            otp: encryptOtp.replace(/\n/g, '').replace(/\r/g, ''),
            transactionId: transactionId,
            // authMethod: authMethod,
        };
        // console.log('-------body1', d);
        await axios
            .post(`${URL.abha}login/hid/confirm-init/${primaryId}`, d, {
                headers: {
                    gatewayToken: 'medxperts',
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(async res => {
                // console.log(res.data, 'kkkkkkkkkkkkk');
                // console.log(res.data.healthIdNumber, 'kkkkkkkkkkkkk');
                const jwtResponsetoken = res.data;
                setAlertmessage(t("otpversuccesfully"));
                setAlertstatus('success');

                handleLink(transactionId);

            })
            .catch(err => {
                // console.log('--------errorinconfirm', err);
                // console.log(err.message);
                if (err.response.status === 400) {
                    setAlertmessage(t("otpenterincrt"));
                    setAlertstatus('error');
                } else {
                    const { alertmessage, alertstatus } = handleErrorResponse(err)
                    setAlertmessage(alertmessage)
                    setAlertstatus(alertstatus)
                }
            })
            .finally(() => {
                setLoading(false);
            });
    };


    const handleResendOtp = async () => {
        setTime(59);
        let data = {
            transactionId: transactionId,
        };

        await axios.post(`${URL.abha}hid/init/resend/otp/${primaryId}`, data, {
            headers: {
                gatewayToken: 'medxperts',
                Authorization: `Bearer ${token}`,
            }
        }).then(res => {


            console.log(res, "abharesendOtppppp")
            setAlert(true);
            setAlertmessage(t("otpsentsuccessfully"));
            setAlertstatus('success');
        })
            .catch(err => {
                console.log(err.message);
                setAlert(true);
                setAlertmessage(t("errormsg"));
                setAlertstatus('error');
            })
            .finally(() => {
                setLoading(false);
            });

        setOtp('')
    }
    return (
        <div>
            <Box sx={{ mt: { xs: 3, sm: 2, md: 1 } }}>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: { xs: 1, sm: 2, md: 2 },
                    }}
                >
                    <IconButton onClick={() => {
                        props.setShow(1)
                        props.setAlertmessage('')
                    }}>
                        <ArrowBackIos sx={{ color: "#007DCD", cursor: "pointer" }} />
                    </IconButton>
                    <Typography
                        sx={{
                            fontSize: { xs: 14, sm: "16px", md: "18px" },
                            fontWeight: "500",
                            color: "#007DCD",
                        }}
                    >
                        {t("changeregisterdetails")}{" "}
                    </Typography>
                </Box>
            </Box>

            <Card sx={{ p: 3, mt: 1, display: "flex", justifyContent: "center" }}>
                <Box>
                    <Box mt={1}>
                        <Typography
                            sx={{
                                fontSize: { xs: 14, md: 16 },
                                textAlign: "center",
                                color: "black",
                                ml: 1,
                                fontWeight: 500,
                            }}
                        >
                            {t("verifyotp")}
                        </Typography>

                        <Grid container mt={2}>
                            <Grid xs={12}>
                                <TextInputField
                                    width={{ sm: "350px", md: "350px" }}
                                    placeholder={t("enterotphere")}
                                    border="none"
                                    name="otp"
                                    value={otp}
                                    onChange={handleOnChangeOtp}
                                />
                            </Grid>
                        </Grid>
                        {/* {alertmessage && (
                        <Typography sx={{ color: alertstatus === 'error' ? 'red' : 'green', ml: 1 }}>{alertmessage}</Typography>

                    )} */}
                        {alertmessage && (
                            <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                        )}

                        {/* <ResendOtp time={time} text="Resend OTP" disabled={time > 0} onClick={handleResendOtp} /> */}

                        <Box sx={{ mt: 2, textAlign: "center", mb: 2 }}>
                            <Button
                                variant="contained"
                                sx={{ borderRadius: 2, width: "150px", mt: 1 }}
                                onClick={handleVerify}
                                disabled={loading || otp?.length < 6}
                                startIcon={
                                    loading ? (
                                        <CircularProgress size={20} color="inherit" />
                                    ) : null
                                }
                            >
                                {/* {loading ? "submit.." : "Submit"} */}
                                {t("credentialsData.signupmobiledata.submitbtn")}
                            </Button>
                        </Box>

                        <Box mt={5}>
                            <Typography sx={{ color: "black", fontSize: 12 }}>
                                {t("enterotpsenttomb")}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Card>
        </div>
    );
}

export default AbhaLinking