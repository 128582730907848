import React from 'react'
import { Avatar, Box, CardMedia, Divider, Menu, MenuItem, Typography } from '@mui/material'
import moment from 'moment'
import likeIcon from '../../../../../assets/home/feed_assets/like-icon.svg'
import commentIcon from '../../../../../assets/home/feed_assets/comment-icon.svg'
import shareIcon from '../../../../../assets/home/feed_assets/share-icon.svg'
import unlikeIcon from '../../../../../assets/home/feed_assets/liked-icon.svg'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import { localstore } from '../../../../../localStore/localStore'
import URL from '../../../../../utility/apiurl'
import { useEffect } from 'react'
import { getpostbyid } from '../../../../../redux/Actions/Actions'
import axios from 'axios'
import TimeLimeAskDoc from './TimelimeAskDoc'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import ShareCopyIcon from '../../../../../assets/home/external-link.svg'
import medxpertsIcon from '../../../../../assets/home/medxpertIcon.svg'



export function timeSince(date) {

    var ms = (new Date()).getTime() - new Date(date).getTime();
    var seconds = Math.floor(ms / 1000);
    var minutes = Math.floor(seconds / 60);
    var hours = Math.floor(minutes / 60);
    var days = Math.floor(hours / 24);
    var months = Math.floor(days / 30);
    var years = Math.floor(months / 12);

    if (seconds < 1) {
        return 'Just now';
    } if (seconds < 60) {
        return seconds + 's';
    } if (minutes < 60) {
        return minutes + 'min';
    } if (hours < 24) {
        return hours + 'h';
    } if (days < 30) {
        return days + 'd';
    } if (months < 12) {
        return months + 'mon';
    } else {
        return years + 'y';
    }
}


function TimelimeData({ orgPage }) {
    // console.log(orgPage, 'posssssssssss')
    const postdata = useSelector((state) => state.medx.postdata)
    // console.log(postdata, "posttttt")
    const [readmore, setReadmore] = useState(false);
    const [pageOrg, setPageOrg] = useState(orgPage)
    // console.log(pageOrg, 'pageorg')
    const [like, setLike] = useState(false)
    const [commentopen, setCommentopen] = useState(false)
    const token = localstore.getToken()
    const patientId = localstore.getPatientId()
    const dispatch = useDispatch()
    const [share, setShare] = useState(false)

    useEffect(() => {
        if (pageOrg.id === postdata.id) {
            setPageOrg(postdata)
        }
    }, [pageOrg, postdata])

    const handleLike = () => {
        setLike(true);
        let data = {
            userData: pageOrg.userData || pageOrg.pageData,
            date: new Date().toISOString(),
            likedBy: patientId,
            metadata: {
                author: patientId,
                dateTime: new Date().toISOString(),
                location: '',
                versionId: 0,
                securityCodes: '',
            },
            postId: pageOrg.id,
        };

        axios
            .post(`${URL.addLikes}`, data, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                // console.log(res, "resssssssss")
                dispatch(getpostbyid(pageOrg.id, token));
            })
            .catch(err => {
                console.log(err.message, 'handleLike');
            });
    };

    const handleUnlike = () => {
        setLike(false);
        axios
            .delete(`${URL.deleteLikes}${pageOrg.likeId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
            .then(res => {
                dispatch(getpostbyid(pageOrg.id, token));
            })
            .catch(err => {
                console.log(err.message, 'handleUnlike');
            });
    };

    const handleClickComment = () => {
        dispatch(getpostbyid(pageOrg.id, token))
        setCommentopen(!commentopen)
    }

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleShare = (e) => {
        console.log('hi share')
        setShare(!share)

        setAnchorEl(e.currentTarget);
    }
    const handleClose = () => {
        setAnchorEl(null);
    };

    const shareUrl = `${'http:/localhost:3500/'}post/${orgPage.id}`
    const handlelinkcopy = () => {
        navigator.clipboard.writeText(`${'http:/localhost:3500/'}post/${orgPage.id}`)
        // console.log(url, "yessss")
    }
    return (
        <>

            <Box mt={2} sx={{
                backgroundColor: "white", p: "15px 0px", border: '1px solid #e5e5dc', borderRadius: " 10px", pb: "4px", mt: "-50px"
            }} >
                <Box sx={{ display: 'flex', gap: 2 }}>
                    {
                        pageOrg?.pageData?.profilePic?.length > 0 || pageOrg?.userData?.profilePic?.length > 0 ?
                            <img src={pageOrg.pageData?.profilePic ? pageOrg.pageData.profilePic : pageOrg.userData?.profilePic} style={{ borderRadius: "10px!important", border: '1px solid lightgrey', marginLeft: "10px !important", width: 60, height: 60, p: "5px", marginLeft: "10px" }} /> : <Avatar sx={{ borderRadius: "7px!important", border: '1px solid lightgrey', marginLeft: "10px !important", width: { sm: 55, md: 60 }, height: { sm: 55, md: 60 }, p: "5px" }} src={medxpertsIcon} variant='rounded' />

                    }
                    <div>
                        {pageOrg?.pageData?.fullName?.length > 0 || pageOrg?.userData?.fullName?.length > 0 ?

                            <Typography sx={{ color: 'black', fontSize: { xs: 12, sm: 15, md: 15 }, fontWeight: 600 }}>{pageOrg.pageData?.fullName || pageOrg.userData?.fullName} </Typography> : <Typography>Not Mentioned</Typography>

                        }

                        {
                            pageOrg?.pageData?.caption?.length > 0 || pageOrg?.userData?.caption?.length > 0 ?
                                <Typography sx={{ fontStyle: 'italic', fontSize: { xs: 11, sm: 12, md: 13 } }}>{pageOrg.pageData?.caption || pageOrg.userData?.caption}</Typography>
                                :
                                null
                        }

                        <Typography color="black" >
                            <Typography sx={{ fontSize: { xs: 11, sm: 12, md: 13 } }}>{`${timeSince(moment.utc(pageOrg.createDate).local())}`}</Typography>&nbsp;
                        </Typography>
                    </div>
                </Box>

                <Box >
                    {
                        pageOrg?.description?.length > 0 ?
                            pageOrg?.description?.length > 80 && readmore === false ?
                                <Typography sx={{ pl: 1 }} gutterBottom>{pageOrg?.description}</Typography>
                                :
                                <Typography sx={{ pl: 1 }}>{pageOrg?.description}</Typography>
                            :
                            null
                    }
                    {
                        pageOrg?.description?.length > 80 ?
                            <Typography sx={{ color: '#0077c4', cursor: 'pointer', pl: 1 }} onClick={() => { setReadmore(!readmore); }}>
                                {
                                    readmore ? <Typography>see less</Typography> : <Typography>see more</Typography>
                                }

                            </Typography>
                            : null
                    }
                </Box>
                <Box>
                    {pageOrg.sourceLink ? <CardMedia
                        component="img"
                        // height="194"
                        sx={{ height: { xs: '100%', sm: '100%', md: '480px' }, }}
                        // image='https://media.istockphoto.22com/id/1341976416/photo/healthy-eating-and-diet-concepts-top-view-of-spring-salad-shot-from-above-on-rustic-wood-table.jpg?b=1&s=170667a&w=0&k=20&c=xYV0gZRXSLeAGJAPaNFaLH1V3VLNLY3KZGVL-neS1js='
                        image={pageOrg.sourceLink}
                        alt="Paella dish"
                    /> : null}
                </Box>

                <Box sx={{ display: 'flex', pl: 1, pr: 1, mt: 4, justifyContent: 'space-between' }}>
                    {pageOrg.likeCount > 0 ? (
                        <Typography sx={{ fontSize: { xs: '7px', sm: '11px', md: '11px' }, margin: '5px' }}>
                            {pageOrg.likeCount} {pageOrg.likeCount === 1 ? 'Person' : 'People'} Liked
                        </Typography>
                    ) : null}
                    {pageOrg.shareCount > 0 ? (
                        <Typography sx={{ fontSize: { xs: '7px', sm: '11px', md: '11px' }, margin: '5px' }}>
                            {pageOrg.shareCount} {pageOrg.shareCount === 1 ? 'Person' : 'People'} Answered
                        </Typography>
                    ) : null}
                </Box>

                <Divider />
                <Box sx={{ display: { xs: 'flex' }, pl: 2, pr: 2, mt: "2px", justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: { md: 'flex' }, textAlign: 'center', gap: 1, cursor: 'pointer' }}>
                        {pageOrg.liked || like === true ?
                            <div onClick={handleUnlike}>
                                <img src={unlikeIcon} alt="unlike" />
                            </div>
                            :
                            <div onClick={handleLike}>
                                <img src={likeIcon} alt="like" />
                            </div>
                        }
                        <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }} onClick={pageOrg.liked || like === true ? handleUnlike : handleLike}>Like</Typography>
                    </Box>
                    <Box sx={{ display: { md: 'flex' }, textAlign: 'center', gap: 1, cursor: 'pointer' }} onClick={handleClickComment}>
                        <img src={commentIcon} alt="comment" />
                        <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}>Ask Doctor</Typography>

                    </Box>

                    <Box sx={{ display: { md: 'flex' }, textAlign: 'center', gap: 1, cursor: 'pointer' }} onClick={handleShare}>
                        <img src={shareIcon} alt="share" />
                        <Typography sx={{ fontSize: { xs: 12, sm: 13, md: 15 } }}>Share</Typography>
                    </Box>

                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        keepMounted
                    >
                        <MenuItem >
                            <FacebookShareButton
                                url={shareUrl}
                                quote={'Title or jo bhi aapko likhna ho'}
                                hashtag={'#portfolio...'}
                            >
                                <FacebookIcon size={20} round='true' /> &nbsp;&nbsp;Facebook
                            </FacebookShareButton>
                        </MenuItem>
                        <MenuItem url={shareUrl}>
                            <TwitterShareButton url={shareUrl}>
                                <TwitterIcon size={20} round='true' /> &nbsp;&nbsp;Twitter
                            </TwitterShareButton>

                        </MenuItem>
                        <MenuItem url={shareUrl} >
                            <WhatsappShareButton
                                url={shareUrl}
                                quote={'Title or jo bhi aapko likhna ho'}
                                hashtag={'#portfolio...'}
                            >
                                <WhatsappIcon size={20} round='true' /> &nbsp;&nbsp;Whatsapp
                            </WhatsappShareButton>

                        </MenuItem>
                        <MenuItem onClick={handleClose}>
                            <span onClick={handlelinkcopy} style={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
                                <img src={ShareCopyIcon} width={18} height={18} />
                                &nbsp;&nbsp;CopyLink</span>
                        </MenuItem>
                    </Menu>
                </Box>
            </Box >
            <TimeLimeAskDoc commentOpen={commentopen} item={pageOrg} />
        </>
    )
}

export default TimelimeData