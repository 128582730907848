import * as React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Collapse from '@mui/material/Collapse';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import LikeIcon from '../../../../../assets/home/feed_assets/like-icon.svg'
import LikedIcon from '../../../../../assets/home/feed_assets/liked-icon.svg'
import ShareIcon from '../../../../../assets/home/feed_assets/share-icon.svg'
import CommentIcon from '../../../../../assets/home/feed_assets/comment-icon.svg'
import ShareCopyIcon from '../../../../../assets/home/feed_assets/open_external.svg'
import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton, WhatsappIcon, WhatsappShareButton } from 'react-share'
import MoreVertIcon from '@mui/icons-material/MoreVert';
import medxpertsIcon from '../../../../../assets-new/medxpedia-icons/medxlovebg.svg'
import youcandoitIcon from '../../../../../assets-new/youcandoit.jpg'
import { Box, Divider, Grid, Menu, MenuItem } from '@mui/material';
import { Comment, Favorite, Share } from '@mui/icons-material';
import { useState } from 'react';
import URL from '../../../../../utility/apiurl';
import axios from 'axios';
import { useEffect } from 'react';
import moment from 'moment';
import Celebrate from '../LiveHealth/PostTypes/Celebrate';
import PdfView from '../LiveHealth/PostTypes/PdfFile';
import Video from '../LiveHealth/PostTypes/Video';
import Media from '../LiveHealth/PostTypes/Media';
import Event from '../LiveHealth/PostTypes/Event';
import PollPost from '../LiveHealth/PostTypes/PollPost';
import AricleView from '../LiveHealth/PostTypes/ArticleView';
import ArticleView from '../LiveHealth/PostTypes/ArticleView';
import { useTranslation } from 'react-i18next';



export default function LiveHealthdata() {
    const [expanded, setExpanded] = useState(false);
    const [likes, setLikes] = useState(0);
    const [comments, setComments] = useState(0);
    const [shares, setShares] = useState(0);
    const [offset, setOffset] = useState(0)
    const [feeddata, setFeeddata] = useState([])
    const [feedcount, setFeedcount] = useState(0)
    const [like, setLike] = useState(false)
    const [t, i18n] = useTranslation("global");
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [loading, setLoading] = useState(false)

    const handleClose = () => {
        setAnchorEl(null);
    };

    // console.log(feeddata, "feeddata")
    const limit = 25
    const handleLike = () => {
        setLikes((prevLikes) => prevLikes + 1);
    };

    const handleComment = () => {
        setComments((prevComments) => prevComments + 1);
    };

    const handleShare = () => {
        setShares((prevShares) => prevShares + 1);
    };
    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    useEffect(() => {
        fetchDataWithOutToken()
    }, [])

    const fetchDataWithOutToken = () => {
        setLoading(false)
        axios.get(`${URL.feed}?limit=${20}&offset=0&organization=373,333&tags=%23disease`).then(res => {
            console.log("withouttoken", res)
            setFeeddata(res?.data?.results)
            setFeedcount(res?.data?.count)
            setOffset(prev => prev + limit)
        }).catch((error) => console.log(error, '6666666666'))
            .finally(() => {
                setLoading(false)
            });
    }
    function timeSince(date) {
        var ms = new Date().getTime() - new Date(date).getTime();
        var seconds = Math.floor(ms / 1000);
        var minutes = Math.floor(seconds / 60);
        var hours = Math.floor(minutes / 60);
        var days = Math.floor(hours / 24);
        var months = Math.floor(days / 30);
        var years = Math.floor(months / 12);
        if (seconds < 1) {
            return 'Just now';
        }
        if (seconds < 60) {
            return seconds + 's';
        }
        if (minutes < 60) {
            return minutes + 'm';
        }
        if (hours < 24) {
            return hours + 'h';
        }
        if (days < 30) {
            return days + 'd';
        }
        if (months < 12) {
            return months + ' month';
        } else {
            return years + 'y';
        }
    }
    return (
        <>
            <Box sx={{ p: "0px 30px" }}>
                <Box sx={{ display: 'flex', justifyContent: 'start', gap: 3 }}>
                    <Typography sx={{ bgcolor: '#F4F2FF', fontSize: '14px', p: '5px', borderRadius: '10px' }}>
                        #MedXperts
                    </Typography>
                    <Typography sx={{ bgcolor: '#F4F2FF', fontSize: '14px', p: '5px', borderRadius: '10px' }}>
                        #FightMisInformation
                    </Typography>
                    <Typography sx={{ bgcolor: '#F4F2FF', fontSize: '14px', p: '5px', borderRadius: '10px' }}>
                        #AskDoctor
                    </Typography>
                </Box>
            </Box>

            {feeddata.map((item, index) => (
                <Card
                    sx={{
                        backgroundColor: "white",
                        p: "15px 0px",
                        border: "1px solid #e5e5dc",
                        borderRadius: " 10px",
                        pb: "2px",
                        mt: 2
                    }}
                    key={index}
                >
                    <Box sx={{ display: "flex", gap: 2 }}>
                        {item?.pageData?.profilePic?.length > 0 ||
                            item?.userData?.profilePic?.length > 0 ? (
                            <Avatar
                                src={
                                    item.pageData?.profilePic
                                        ? item.pageData.profilePic
                                        : item.userData?.profilePic
                                }
                                sx={{
                                    borderRadius: "7px!important",
                                    border: "1px solid lightgrey",
                                    marginLeft: "10px !important",
                                    width: { sm: 55, md: 60 },
                                    height: { sm: 55, md: 60 },
                                    p: "5px",
                                }}
                            />
                        ) : (
                            <Avatar sx={{
                                borderRadius: "7px!important",
                                // border: "1px solid lightgrey",
                                marginLeft: "10px !important",
                                width: { sm: 55, md: 65 },
                                height: { sm: 55, md: 65 },
                            }} src={medxpertsIcon} />
                        )}

                        <div>
                            {item?.pageData?.fullName?.length > 0 ||
                                item?.userData?.fullName?.length > 0 ? (
                                <Typography
                                    sx={{
                                        fontSize: { xs: 12, sm: 15, md: 14 },
                                        fontWeight: 600, cursor: 'pointer'
                                    }}
                                >
                                    {item.pageData?.fullName || item.userData?.fullName}{" "}
                                </Typography>
                            ) : (
                                <Typography sx={{ fontSize: 15 }}>Not Mentioned</Typography>
                            )}

                            {item?.pageData?.caption?.length > 0 ||
                                item?.userData?.caption?.length > 0 ? (
                                <Typography
                                    sx={{
                                        fontStyle: "italic",
                                        fontSize: { xs: 11, sm: 12, md: 13 },
                                    }}
                                >
                                    {item.pageData?.caption || item.userData?.caption}
                                </Typography>
                            ) : null}

                            <Typography color="black">
                                <Typography
                                    sx={{ fontSize: { xs: 11, sm: 12, md: 13 } }}
                                >{`${timeSince(
                                    moment.utc(item.createDate).local()
                                )}`}</Typography>
                                &nbsp;
                            </Typography>
                        </div>
                    </Box>

                    <CardContent sx={{ p: 0 }}>
                        <Grid sx={{ width: { xs: "100%", sm: "100%", md: "100%" } }}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                sx={{
                                    textOverflow: "ellipsis",
                                    wordWrap: "break-word",
                                    pl: 1.6,
                                }}
                            >
                                {expanded
                                    ? item.description
                                    : `${item.description.slice(0, 114)}`}
                            </Typography>
                            {item.description.length > 95 ? (
                                <Typography
                                    onClick={handleExpandClick}
                                    sx={{ cursor: "pointer" }}
                                >
                                    {expanded ? "see less" : "see more"}{" "}
                                </Typography>
                            ) : (
                                ""
                            )}
                            <Box>
                                {
                                    // item.postType === 'TEXT' ?
                                    //     <Texts item={item} />
                                    //     :
                                    item.postType === 'ARTICLE' ? (
                                        <ArticleView docFeed={item} />
                                    ) : item.postType === 'CELEBRATE' ? (
                                        <Celebrate docFeed={item} />
                                    ) : item.postType === 'FILE' ? (
                                        <PdfView docFeed={item} stateview={true} />
                                    ) : item.postType === 'VIDEO' ? (
                                        <Video docFeed={item} />
                                    ) : item.postType === 'IMAGE' ? (
                                        <Media docFeed={item} />
                                    ) : item.postType === 'EVENT' ? (
                                        <Event docFeed={item} />
                                    ) : item.postType === 'POLL' ? (
                                        <PollPost docFeed={item} />
                                    ) : null
                                }
                            </Box>
                        </Grid>

                        {/* <ExpandMoreIcon /> */}
                    </CardContent>

                    <Divider />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', p: "2px 20px" }}>

                        <Box>
                            {item.liked || like === true ? (
                                <IconButton aria-label="unlike" >
                                    <img src={LikedIcon} alt="unlike" />
                                </IconButton>
                            ) : (<IconButton aria-label="like" onClick={handleLike}>
                                <img src={LikeIcon} alt="like" />
                            </IconButton>)}
                            <Typography variant="caption" sx={{ cursor: 'pointer' }} onClick={
                                handleLike
                            }> Like</Typography>
                        </Box>
                        <Box onClick={handleComment}>
                            <IconButton aria-label="comment" >
                                <img src={CommentIcon} alt="comment" />
                            </IconButton>
                            <Typography variant="caption" sx={{ cursor: 'pointer' }}>Ask Doctor</Typography>
                        </Box>
                        <Box onClick={handleShare}>
                            <IconButton aria-label="share" >
                                <img src={ShareIcon} alt="share" />
                            </IconButton>
                            <Typography variant="caption" sx={{ cursor: 'pointer' }}>Share</Typography>
                        </Box>
                        <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            keepMounted
                        >
                            <MenuItem>
                                <FacebookShareButton
                                    quote={"Title or jo bhi aapko likhna ho"}
                                    hashtag={"#portfolio..."}
                                >
                                    <FacebookIcon size={20} round="true" /> &nbsp;&nbsp;
                                    {t("actions.facebook")}
                                </FacebookShareButton>
                            </MenuItem>
                            <MenuItem >
                                <TwitterShareButton >
                                    <TwitterIcon size={20} round="true" /> &nbsp;&nbsp;
                                    {t("actions.twitter")}
                                </TwitterShareButton>
                            </MenuItem>
                            <MenuItem >
                                <WhatsappShareButton
                                    // url={shareUrl}
                                    quote={"Title or jo bhi aapko likhna ho"}
                                    hashtag={"#portfolio..."}
                                >
                                    <WhatsappIcon size={20} round="true" /> &nbsp;&nbsp;
                                    {t("actions.whatsapp")}
                                </WhatsappShareButton>
                            </MenuItem>
                            <MenuItem onClick={handleClose}>
                                <span
                                    // onClick={handlelinkcopy}
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                    }}
                                >
                                    <img src={ShareCopyIcon} />
                                    &nbsp;&nbsp;{t("actions.copylink")}
                                </span>
                            </MenuItem>
                        </Menu>
                    </Box>


                    {/* <Postactions item={item} /> */}

                </Card>
            ))}
        </>
    );
}