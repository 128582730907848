import React, { useEffect, useState } from 'react'
import { AppBar, Avatar, Box, Divider, Grid, Typography, useMediaQuery } from '@mui/material'
import { getAbhaProfile, getPatientdatafromibm } from '../../redux/Actions/Actions'
import { getHealthStatus } from '../../redux/Actions/consentActions'
import { useDispatch, useSelector } from 'react-redux'
import { localstore } from '../../localStore/localStore'
import { VerifiedUser } from '@mui/icons-material'

function MobileAppbar() {
    const ibmpdata = useSelector(state => state.medx.ibmpdata)
    const abhadata = useSelector(state => state.abha.abhaprofile)
    const [age, setAge] = useState('')

    let d = new Date().getFullYear();
    useEffect(() => {
        let birthyear = ibmpdata.birthDate?.split('-')[0];
        setAge(Number(d) - Number(birthyear));
    }, [ibmpdata])
    return (
        <>
            {
                ibmpdata?.name?.length > 0 ?
                    (
                        <>
                            <Grid container columnSpacing={2}>
                                <Grid item xs={1.5} sm={1.8}>
                                    {ibmpdata?.photo && ibmpdata?.photo[0]?.url?.length > 0 ? (
                                        <Avatar
                                            sx={{ bgcolor: "gray", width: 38, height: 38 }}
                                        >
                                            <img
                                                src={ibmpdata?.photo[0].url}
                                                alt="Profile Picture"
                                                style={{
                                                    objectFit: "cover",
                                                    width: "100%",
                                                    height: "100%",
                                                }}
                                            />
                                        </Avatar>
                                    ) : (
                                        <Avatar
                                            sx={{ bgcolor: "gray", width: 38, height: 38 }} variant='rounded'
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={10.5} sm={9} md={9}>
                                    <Typography sx={{ fontSize: { xs: 12, sm: '14px', md: '14px' }, fontWeight: 600, textAlign: 'left', ml: "10px", color: '#3D6596 ' }}>
                                        {ibmpdata?.gender ? (
                                            ibmpdata.gender.toLowerCase() === 'male'
                                                ? 'Mr'
                                                : ibmpdata.gender.toLowerCase() === 'female'
                                                    ? 'Ms'
                                                    : 'Mx' // You can replace 'Mx' with the appropriate title for transgender or other genders
                                        ) : (
                                            'Mr/Ms'
                                        )}{' '}
                                        {`${ibmpdata?.name?.[0]?.given?.[0]} ${ibmpdata?.name?.[0]?.family}`.slice(0, 20)}
                                        {`${ibmpdata?.name?.[0]?.given?.[0]} ${ibmpdata?.name?.[0]?.family}`.length > 20 ? '...' : ''}
                                        ({age}Y/{ibmpdata?.gender && ibmpdata.gender.charAt(0).toUpperCase()})
                                    </Typography>
                                    {abhadata?.AbhaAddress?.length > 0 ? (
                                        <Box sx={{
                                            display: 'flex', ml: "11px", gap: { xs: "6px", sm: "5px", md: "8px" }, alignItems: 'center', textAlign: 'center'
                                        }}>
                                            < VerifiedUser sx={{ fontSize: 13, color: "green" }} />
                                            <Typography sx={{ fontSize: { xs: 13, sm: '14px', md: '14px' }, fontWeight: 500, color: '#5B7DA6' }} >{abhadata.AbhaAddress}</Typography>
                                        </Box>

                                    ) : (
                                        <Typography sx={{
                                            fontSize: "13px", fontWeight: 400,
                                            textAlign: 'left', color: '#5B7DA6', ml: "10px"
                                        }} >
                                            Setup PhrAddress
                                        </Typography>
                                    )}
                                </Grid>
                            </Grid>
                        </>) : null}
        </>
    )
}

export default MobileAppbar