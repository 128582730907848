import { Avatar, Box, Divider, Typography } from '@mui/material'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import URL from '../../../../../utility/apiurl'
import { localstore } from '../../../../../localStore/localStore'
import DocTimelineData from './DocTimelineData'

function DoctorsTimeline({ docId }) {
    // console.log(docId, "orgIddddddd")
    const token = localstore.getToken()
    const [count, setCount] = useState()
    const [userFeed, setUserFeed] = useState()
    // console.log(userFeed, "pageFeed")
    const limit = 10;
    const [offset, setOffset] = useState(() => { return 0; });
    const getPageFeed = () => {
        axios.get(`${URL.userFeed}${docId}?limit=${limit}&offset=${offset}`, {

            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            // console.log(res, "timeline")
            setCount(res.data.count)
            setUserFeed(res.data.results)

        }).catch((err) => console.log(err))
    }

    useEffect(() => {
        getPageFeed()
    }, [])



    return (

        <Box sx={{ mt: 10 }}>
            {
                userFeed?.length > 0
                    ? userFeed.map((user, index) => <DocTimelineData feedData={user} key={index} />)
                    : <Typography sx={{ mt: "-70px" }}>No posts available</Typography>
            }
        </Box>

    )
}

export default DoctorsTimeline