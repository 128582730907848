import React from 'react'
import { Box, Button, DialogActions, DialogContent, DialogTitle, Divider, Typography } from '@mui/material'
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";

function DeleteHealthId5(props) {
    const { setShow } = props
    const result = useSelector((state) => state.abha.getforgotHealthIdnum)

    const [t, i18n] = useTranslation("global");

    return (
        <div>
            <DialogTitle sx={{ p: '8px 20px' }}>
                <Typography sx={{ fontWeight: 600, fontSize: 17, }}>HealthId Number</Typography>
            </DialogTitle>
            <Divider />
            <DialogContent sx={{ textAlign: 'center' }}>
                <Typography sx={{ fontWeight: 500, fontSize: 17 }}>Congratulations</Typography>
                <Typography sx={{ fontSize: 14, mt: 2 }}>your HealthId Number :<Typography sx={{ fontWeight: 600, fontSize: '17px' }} component={'span'}>{result}</Typography></Typography>
            </DialogContent>
            <Divider />

            <DialogActions sx={{ justifyContent: 'center' }}>
                <Box >
                    <Button
                        variant="contained"
                        sx={{ borderRadius: 2, width: "150px", mt: 1, mb: 1 }}
                        onClick={() => setShow(1)}
                    >
                        Done
                    </Button>
                </Box>

            </DialogActions>
        </div>
    )
}

export default DeleteHealthId5