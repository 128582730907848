import React, { useRef } from 'react'
import { Box, Typography } from '@mui/material';
import PinInputField from '../../../atoms/TextFields/PinInputField';

function ConfirmChangePin({ confirmPin, setConfirmPin, setAlertmessage }) {
    const otpFieldsRef = useRef([]);
    const handleInput = (index, value, inputType) => {
        if (/^\d+$/.test(value) && value.length <= 1) {
            const newPin = [...confirmPin];
            newPin[index] = value;
            setConfirmPin(newPin);
            if (value.length === 1 && index < confirmPin.length - 1) {
                otpFieldsRef.current[index + 1].focus();
            }
            setAlertmessage('');
        } else if (value !== '') {
            setAlertmessage('Please enter 4 digits number only');
        }
    };

    const handleBackspace = (index, inputType) => {
        if (confirmPin[index] !== '') {
            const newPin = [...confirmPin];
            newPin[index] = '';
            setConfirmPin(newPin);
        } else if (index > 0) {
            otpFieldsRef.current[index - 1].focus();
        }
        setAlertmessage('')
    };
    return (
        <div> <Box>
            <Typography sx={{ fontWeight: 500 }}>Confirm Consent PIN</Typography>

            <div style={{ display: 'flex', gap: '20px', marginTop: '20px', textAlign: 'center', justifyContent: 'center' }}>
                {confirmPin.map((value, index) => (

                    <PinInputField
                        type="text"
                        maxLength="1"
                        value={value}
                        key={index}
                        onChange={(e) => handleInput(index, e.target.value)}
                        onKeyDown={(e) => {
                            if (e.key === 'Backspace') {
                                handleBackspace(index);
                            }
                        }}
                        ref={(ref) => {
                            otpFieldsRef.current[index] = ref;
                        }} />
                ))}
            </div>

        </Box></div>
    )
}

export default ConfirmChangePin