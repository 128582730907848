import React, { useEffect } from 'react'
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import medxpertIcon from '../../../assets/home/medxpertIcon.svg'
import { CircularProgress, TextField } from '@mui/material';
import URL from '../../../utility/apiurl';
import axios from 'axios';
import CommonButton from '../../atoms/CommonButton';
import { useTranslation } from 'react-i18next';
import ColorTextField from '../../atoms/TextFields/ColorTextField';
import { ErrorStatusMsg } from '../../atoms/ResendOtp';

function ForgotuserOtp(props) {
    // console.log(props, "propssssssssss")
    const [otp, setOtp] = useState('')
    const [alertopen, setAlertopen] = useState(false);
    const [alertmessage, setAlertmessage] = useState('');
    const [alertstatus, setAlertstatus] = useState('');
    const [alert, setAlert] = useState({ open: false, msg: '' })
    const [timer, setTimer] = useState(null);
    const [time, setTime] = useState(59);
    const [loading, setLoading] = useState(false)
    const [t, i18n] = useTranslation("global");

    const handleChangeOtp = (e) => {
        const inputValue = e.target.value.replace(/\D/g, '');
        setOtp(inputValue.slice(0, 5));
    }
    useEffect(() => {
        const script = document.createElement('script')
        script.src = "https://www.google.com/recaptcha/api.js?render=6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9"
        document.body.appendChild(script)
    }, [])

    useEffect(() => {
        setTime(59);

        return () => {
            clearInterval(timer);
        };

    }, [timer]);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (time > 0) {
                setTime(time - 1);
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });
    const handleverifyOtp = () => {
        setLoading(true)
        let data = {
            'phoneNumber': props.phoneNumber,
            'otp': otp,
            'email': '',
        };
        let d = {
            'phoneNumber': props.phoneNumber,
            'messagetype': 'USERNAME',
            'apptype': 'medxperts',
            'email': '',
            'username': props.username,
        };
        axios.post(`${URL.verifyotp}`, data)
            .then(async res => {
                setAlertstatus('success');
                setAlertmessage('OTP verified successfully!');

                await axios.post(`${URL.sendUsername} `, d)
                    .then(res => {
                        // console.log(res.data, 'llllllllllllllllllll');
                        setAlertstatus('success');
                        setAlertmessage('User Name sent to mobile number!');

                        props.setShow(3)
                    })
                    .catch(err => {
                        setAlertstatus('error');
                        setAlertmessage(err.message);

                    });
            })
            .catch(err => {
                setAlertopen(true);
                setAlertstatus('error');
                setAlertmessage('OTP entered is wrong');

            }).finally(() => {
                setLoading(false)
            })


    };

    const handleResendOtp = () => {

        const data = {
            phoneNumber: props.phoneNumber,
            messagetype: 'OTP',
            apptype: 'medxperts',
            email: '',
        }
        // timeinterval();
        window.grecaptcha.ready(_ => {
            window.grecaptcha
                .execute("6Lc8BogeAAAAAKFKQGh53Y3xQ1iPlbsVT6n4vVA9", { action: "submit" })
                .then(token => {
                    axios.post(`${URL.forgotuserOtp} `, data, { headers: { "Authorization": `Bearer ${token} ` } }).then(res => {
                        console.log(res, "sendotp")
                        if (res["status"] === 200) {
                            if (res.data.status === 201) {
                                setAlert({ open: true, msg: `${res.data.message} ` });
                            } else if (res.data.status === 409) {
                                setAlert({ open: true, msg: `${res.data.message} ` })
                            }
                            setTime(59);
                            if (timer) {
                                clearInterval(timer);
                            }
                            setTimer(
                                setInterval(() => {
                                    setTime((prevTime) => (prevTime > 0 ? prevTime - 1 : 0));
                                }, 1000)
                            );
                        } else {
                            setAlert({ open: true, msg: "Internal server error" });
                        }
                    }, error => {
                        setAlert({ open: true, msg: "Error " });
                    })
                })
        })
    }
    return (
        <Box>
            <Box sx={{ mt: 4 }}>
                <ColorTextField label="OTP" placeholder="Enter Received OTP" type="text" name="otp" value={otp} onChange={handleChangeOtp}
                />
            </Box>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', p: "0px 10px", mt: "5px" }}>
                <Typography sx={{ fontSize: "12px" }}>{`00:${time} `}</Typography>
                <Button disabled={time > 0} sx={{ textTransform: "capitalize" }}>
                    <Typography
                        sx={{ fontSize: { xs: 10, md: 12 } }}
                        onClick={handleResendOtp}
                    >
                        {t("credentialsData.signupmobiledata.resendotp")}
                    </Typography>
                </Button>
            </Box>

            <Box sx={{ textAlign: 'center', mt: 4, }}>
                <CommonButton text="Submit" width={150} bgcolor={"#8A2BE2"} disabled={loading || otp?.length < 5}
                    startIcon={
                        loading ? (
                            <CircularProgress size={20} color="inherit" />
                        ) : null
                    } onClick={handleverifyOtp} />
            </Box>
            <Box sx={{ mt: 2, textAlign: 'center' }}>
                {
                    alertmessage && (
                        <ErrorStatusMsg alertmessage={alertmessage} alertstatus={alertstatus} />
                    )
                }
            </Box>

        </Box >
    )
}

export default ForgotuserOtp